import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: {
    padding: '6px',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.2px',
    borderRadius: '3px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '4px 6px',
    },
  },
};
