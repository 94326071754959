import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  actionsWrap: {
    marginTop: '16px',
    marginBottom: '40px',
    display: 'flex',
    justifyContent: 'end',
  },
  cancelButton: {
    padding: '2px',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B50,
    marginRight: '-6px',
  },
};
