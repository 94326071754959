import { useCallback, useMemo } from 'react';

import { useInfiniteQuery } from 'react-query';

import { getNotificationsList } from 'app/api/notifications';
import {
  NOTIFICATIONS_LIMIT,
  NOTIFICATIONS_QUERY_KEY,
  UPDATE_NOTIFICATIONS_INTERVAL,
} from 'layout/Notifications/constants';

interface UseNotificationsQueryProps {
  enabled: boolean;
  isRefetchInterval?: boolean;
}

export const useNotificationsQuery = ({ enabled, isRefetchInterval = true }: UseNotificationsQueryProps) => {
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage: fetchNext,
    hasNextPage,
  } = useInfiniteQuery(
    [NOTIFICATIONS_QUERY_KEY],
    ({ pageParam = 0 }) =>
      getNotificationsList({
        limit: NOTIFICATIONS_LIMIT,
        offset: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
      enabled,
      refetchInterval: enabled && isRefetchInterval ? UPDATE_NOTIFICATIONS_INTERVAL : false,
      getNextPageParam: (lastPage) => {
        return lastPage.next && new URL(lastPage.next).searchParams.get('offset');
      },
    },
  );

  const notifications = useMemo(() => data?.pages?.flatMap((item) => item?.results ?? []) ?? [], [data?.pages]);

  const fetchNextPage = useCallback(async () => {
    if (hasNextPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      await fetchNext();
    }
  }, [hasNextPage, fetchNext]);

  return {
    isLoading,
    notifications,
    hasNextPage: !!hasNextPage,
    isFetchingNextPage,
    isNotificationsList: !!notifications.length,
    fetchNextPage,
  };
};
