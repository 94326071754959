import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  menuWrap: {
    '& .MuiMenu-list': {
      position: 'relative',
      padding: 0,
    },

    '& .MuiPaper-root': {
      minWidth: 'unset',
      width: '100%',
      maxWidth: '100%',
      padding: '24px',
      borderRadius: '3px',
      boxShadow: 'none',
      boxSizing: 'border-box',
      top: 'unset !important',
      bottom: '0 !important',
      left: '0 !important',
      right: '0 !important',
      border: `1px solid ${customTheme.custom.black.B10}`,
    },

    '& .MuiFormGroup-root': {
      marginLeft: '-12px',
      width: 'fit-content',
    },

    '.PrivateSwitchBase-root svg': {
      borderRadius: '50%',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.18)',
    },
  },
  menuButton: {
    minWidth: '64px',
    fontWeight: '400',
    maxWidth: 'unset',
    width: '100%',
    color: customTheme.custom.black.B100,
    backgroundColor: customTheme.custom.black.B5,
    minHeight: '40px',
    height: 'fit-content',

    '&, &.MuiButton-containedPrimary:hover': {
      background: 'transparent',
    },

    svg: {
      width: '26px',
      height: '26px',

      path: {
        fill: customTheme.custom.black.B50,
      },
    },
  },

  menuButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    color: customTheme.custom.black.B100,
  },

  sortTitleWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  sortTitle: {
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      color: customTheme.custom.black.B50,
      fontWeight: 600,
    },
  },
  sortLabel: {
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
    color: customTheme.custom.black.B100,
  },

  closeButton: {
    position: 'absolute',
    top: '-24px',
    right: '-24px',
  },
  confirmButton: {
    width: '100%',
    marginTop: '12px',
  },
  menuItemWrap: {
    margin: 0,
    padding: '6px 18px',

    '& .MuiFormControlLabel-label': {
      marginLeft: '8px',
      fontSize: '14px',
      color: customTheme.custom.black.B100,
    },
  },
};
