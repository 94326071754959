import { SxProps } from '@mui/material';

import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  title: {
    marginBottom: '24px',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,
  },
  actionsWrap: (isFormError: boolean): SxProps => ({
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',

    ...(isFormError && {
      marginTop: '32px',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '4px',
    },
  }),
  button: {
    width: '50%',
    whiteSpace: 'nowrap',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '6px 10px',
      minWidth: 'fit-content',
    },
  },
  currentPasswordError: {
    border: 0,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: '-24px',
  },
};
