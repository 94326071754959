import { FC, MouseEvent } from 'react';

import { Control, useController } from 'react-hook-form';
import { Box, IconButton, InputAdornment } from '@mui/material';

import { ConversationSearchForm } from 'app/api/SBChat';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/searchClose.svg';
import { TextFormField, CommonTooltip } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { ConversationSearchTypeSelect } from '../ConversationSearchTypeSelect';
import { styles } from './styles';

interface ConversationSearchFieldProps {
  anchorEl: HTMLElement | null;
  control: Control<ConversationSearchForm>;
  open: boolean;
  clearSearchField: () => void;
  handleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
}

export const ConversationSearchField: FC<ConversationSearchFieldProps> = ({
  anchorEl,
  control,
  open,
  clearSearchField,
  handleClick,
  handleClose,
}) => {
  const {
    field: { value },
  } = useController({ control, name: 'query' });

  return (
    <TextFormField
      name="query"
      control={control}
      inputStyles={styles.input as SxPropsTypes}
      wrapStyles={styles.inputWrap as SxPropsTypes}
      placeholder="Search conversations"
      endAdornment={
        <InputAdornment position="end" sx={styles.endAdornment}>
          <Box sx={styles.endAdornmentInner}>
            {!!value && (
              <ConversationSearchTypeSelect
                anchorEl={anchorEl}
                control={control}
                open={open}
                handleClick={handleClick}
                handleClose={handleClose}
              />
            )}
            {value ? (
              <CommonTooltip title="Clear">
                <IconButton sx={styles.closeButton} onClick={clearSearchField}>
                  <CloseIcon />
                </IconButton>
              </CommonTooltip>
            ) : (
              <Box sx={styles.searchIcon}>
                <SearchIcon />
              </Box>
            )}
          </Box>
        </InputAdornment>
      }
    />
  );
};
