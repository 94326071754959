import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  button: {
    minWidth: '120px',
    height: '40px',
    padding: '6px 16px',
    borderRadius: '25px',
    textTransform: 'none',
    boxShadow: 'none',

    ':hover': {
      boxShadow: 'none',
    },

    '&.MuiButton-containedPrimary': {
      ':hover': {
        backgroundColor: customTheme.palette.primary.P70,
      },

      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        ':hover': {
          backgroundColor: customTheme.palette.primary.P100,
        },
      },
    },

    '&.MuiButton-text': {
      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        ':hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
};
