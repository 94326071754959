import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  content: {
    maxWidth: '370px',
    marginBottom: '28px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
      width: 'fit-content',
    },
  },
  subTitle: {
    margin: '20px 0 16px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },

  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -14px -8px 0',
  },
  amountField: {
    margin: 0,
  },
  amountFieldInput: {
    height: '21px',
  },
  closeButton: {
    minWidth: '80px',
    marginRight: '8px',
    color: customTheme.custom.black.B70,
  },
  removeButton: {
    minWidth: '80px',
    color: customTheme.custom.error,
  },
  confirmButton: {
    minWidth: '90px',
    color: customTheme.custom.success,
  },
};
