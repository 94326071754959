import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  controllerWrap: {
    marginBottom: '24px',
  },
  headerWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    fontWeight: '600',
    fontFamily: 'Poppins',
    lineHeight: '21px',
    color: customTheme.custom.black.B100,
  },
  arrow: {
    color: customTheme.custom.neutralLight,
  },
  arrowLeft: {
    marginBottom: '1px',
    transform: 'rotate(-180deg)',
  },

  inputWrap: (isError: boolean) => ({
    height: '40px',
    width: '290px',
    border: `1px solid ${isError ? customTheme.custom.error : customTheme.custom.black.B10}`,
    borderRadius: '25px',
    fontSize: '14px',
    color: customTheme.custom.black.B100,
    cursor: 'pointer',
  }),
  input: {
    padding: '5px 8px',
    borderRadius: '25px',
    cursor: 'pointer',

    '&::placeholder': {
      color: customTheme.custom.black.B50,
      opacity: 1,
    },
  },
  calendarIcon: {
    width: '24px',
    height: '24px',
    marginLeft: '16px',
  },
};
