import { useCallback, useState, MouseEvent } from 'react';

import { UseFormSetValue } from 'react-hook-form';

import { QuoteProductStatus } from 'app/api/quotes';
import { QuoteTableFormData } from '../types';

interface UseSellersActionsProps {
  isConfirm: number | undefined;
  index: number;
  setValue: UseFormSetValue<QuoteTableFormData>;
  handleUndo: () => void;
}

export const useSellersActions = ({ isConfirm, index, setValue, handleUndo }: UseSellersActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMarkNotAvailable = useCallback(() => {
    if (isConfirm) {
      handleUndo();
    }

    setValue(`quotes.${index}.status`, QuoteProductStatus.NOT_AVAILABLE);
    handleClose();
  }, [handleClose, handleUndo, index, isConfirm, setValue]);

  const handleRemove = useCallback(() => {
    if (isConfirm) {
      handleUndo();
    }

    setValue(`quotes.${index}.status`, QuoteProductStatus.REMOVED);
    handleClose();
  }, [handleClose, handleUndo, index, isConfirm, setValue]);

  const handleStatusUndo = useCallback(() => {
    setValue(`quotes.${index}.status`, QuoteProductStatus.AVAILABLE);
  }, [index, setValue]);

  return {
    open,
    anchorEl,
    handleClick,
    handleClose,
    handleRemove,
    handleMarkNotAvailable,
    handleStatusUndo,
  };
};
