import { useForm } from 'react-hook-form';

import { ResetPasswordFormValues } from '../../../types';

const inititalValues = {
  email: '',
};

export const useResetPasswordForm = () => {
  const { control, handleSubmit } = useForm<ResetPasswordFormValues>({
    defaultValues: inititalValues,
  });

  return { control, handleSubmit };
};
