import { FC } from 'react';

import { Box, Drawer, IconButton } from '@mui/material';
import { Member } from '@sendbird/chat/lib/__definition';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrowRight.svg';
import { SBLoader } from 'components';
import { ConversationInfoInner } from '../ConversationInfoInner';
import { styles } from './styles';

interface ConversationInfoPannelProps {
  collapsed: boolean;
  currentChannelId: string;
  currentCompanyMembers: Member[];
  isLoading: boolean;
  membersCount: number;
  otherCompanyName: string;
  otherCompanyMembers: Member[];
  currentCompanyName?: string;
  handleToggle: () => void;
  hasQuotePanel: boolean;
}

export const ConversationInfoPannel: FC<ConversationInfoPannelProps> = ({
  collapsed,
  currentChannelId,
  currentCompanyMembers,
  isLoading,
  membersCount,
  otherCompanyName,
  otherCompanyMembers,
  currentCompanyName = '',
  handleToggle,
  hasQuotePanel,
}) => {
  return (
    <Box sx={styles.wrap}>
      <IconButton sx={styles.arrowButton} onClick={handleToggle}>
        <ArrowIcon />
      </IconButton>

      <Drawer sx={styles.drawer(hasQuotePanel)} variant="persistent" anchor="right" open={!collapsed}>
        <Box sx={styles.drawerInner}>
          {isLoading ? (
            <SBLoader isFullHeight />
          ) : (
            <ConversationInfoInner
              collapsed={collapsed}
              currentCompanyMembers={currentCompanyMembers}
              currentCompanyName={currentCompanyName}
              membersCount={membersCount}
              otherCompanyName={otherCompanyName}
              otherCompanyMembers={otherCompanyMembers}
              currentChannelId={currentChannelId}
              handleToggle={handleToggle}
            />
          )}
        </Box>
      </Drawer>
    </Box>
  );
};
