import { FC, useCallback, MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, ButtonProps } from '@mui/material';

import { styles } from './styles';

interface LinkButtonProps extends ButtonProps {
  to: string;
}

export const LinkButton: FC<LinkButtonProps> = ({ children, variant = 'text', onClick, to, sx, ...props }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      navigate(to);
    },
    [navigate, onClick, to],
  );

  return (
    <Button
      onClick={handleClick}
      disableRipple
      variant={variant}
      sx={{ ...styles.button, ...(sx as ButtonProps) }}
      {...props}
    >
      {children}
    </Button>
  );
};
