import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/circledArrowUp.svg';
import { OnDragFileLabel } from '../OnDragFileLabel';
import { styles } from './styles';

interface OnDragPlaceholderProps {
  folderName?: string;
  isDocument?: boolean;
}

export const OnDragPlaceholder: FC<OnDragPlaceholderProps> = ({ folderName, isDocument = false }) => {
  return (
    <Box sx={{ ...styles.wrap(isDocument) }}>
      {folderName && <Typography sx={{ ...styles.name }}>{folderName}</Typography>}

      <ArrowUpIcon />
      <OnDragFileLabel />
    </Box>
  );
};
