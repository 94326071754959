import { FC } from 'react';

import { styles } from './styles';
import { CommonButton } from '../../../../components/CommonButton';

interface BuyerActionsProps {
  isEmptyBuyer: boolean;
  handleView: () => void;
}

export const BuyerActions: FC<BuyerActionsProps> = ({ isEmptyBuyer, handleView }): JSX.Element => {
  if (isEmptyBuyer) {
    return <></>;
  }

  return (
    <CommonButton variant="outlined" color="inherit" sx={{ ...styles.viewButton }} onClick={handleView}>
      View
    </CommonButton>
  );
};
