import { FC } from 'react';

import { Box } from '@mui/material';

import { ProductDetail } from 'app/api/products/types';
import { PageLoader } from 'components';
import { ProductDetailsDescription } from '../ProductDetailsDescription';
import { ProductDetailsStats } from '../ProductDetailsStats';
import { styles } from './styles';

export interface ProductDetailsProps {
  data: ProductDetail | undefined;
  isLoading: boolean;
  isSeller?: boolean;
}

export const ProductDetails: FC<ProductDetailsProps> = ({ data, isLoading, isSeller = false }): JSX.Element => {
  return (
    <>
      {isLoading || !data ? (
        <Box sx={{ ...styles.loaderWrap }} data-testid="loader">
          <PageLoader />
        </Box>
      ) : (
        <Box sx={{ ...styles.detailsWrap }} data-testid="details">
          <ProductDetailsDescription data={data} />
          <ProductDetailsStats data={data} isSeller={isSeller} />
        </Box>
      )}
    </>
  );
};
