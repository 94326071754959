import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '24px',
    background: customTheme.custom.white,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
    },
  },
  inner: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '7px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '10px 14px',
      gap: '10px',
    },
  },
  title: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.custom.black.B100,
  },
  subTitle: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,
    letterSpacing: '0.2px',
    whiteSpace: 'nowrap',
  },
  list: {
    display: 'flex',
    gap: '20px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '12px',
      flexWrap: 'wrap',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: 'fit-content',
    minWidth: '80px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: '80px',
    },
  },
  value: {
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,
    letterSpacing: '0.2px',
  },
  trackingButtonWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: '14px',
      marginBottom: '10px',
    },
  },
};
