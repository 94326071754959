import { useCallback, useMemo, useState } from 'react';
import { useWatch, Control } from 'react-hook-form';
import { RadioGroupItem } from '../../RadioGroupController/types';

export const useSortFilter = (control: Control, controlName: string, data: RadioGroupItem[]) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const currentVal = useWatch({
    control,
    name: controlName,
  });

  const filterTitle = useMemo(() => {
    const title = data.find((item) => item.value === currentVal);
    return title ? title.label : '';
  }, [data, currentVal]);

  return {
    anchorEl,
    open,
    filterTitle,
    handleClick,
    handleClose,
  };
};
