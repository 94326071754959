const names = [
  'Acsen Agriscience Pvt Ltd',
  'Adaglio Sementi S.R.L',
  'Agp Sac',
  'Agrality',
  'Agrimatco',
  'Agris Sa',
  'Agrofinal',
  'Agrofors',
  'Alfa Seeds Icsa',
  'Allied Seed',
  'Antufen Seeds',
  'Aphysem',
  'Asgen Tarim Ticaret',
  'Bakker Brothers',
  'Bayram',
  'Enza Zaden',
  'Hazera Seeds',
  'Hazera Seeds BV',
  'Landmark Seed Company',
  'Limagrain',
  'Rijk Zwaan',
  'Sakata',
  'Takii',
  'Tokita',
  'Bejo Zaden',
  'Bhn Seed',
  'Biscayart Semillas',
  'BrettYoung',
  'Burlingham Seeds',
  'Chaccour Agricultural Company',
  'Chakra Seeds',
  'CN Seeds',
  'De Corato Sementi',
  'DELTAGee',
  'Ducrettet',
  'Eurofins',
  'Feldsaaten Freudenberger',
  'fenaco Genossenschaft',
  'Uniseeds Select BV',
  'United Genetics Italia',
  'United Seeds',
  'UPOV',
  'Upper Murray Seeds',
  'URSUS Beratung',
  'Valle Verde & Serkan Semillas',
  'Van de Bilt Zaden en Vlas BV',
  'Vandinter Semo',
  'Verim Ziraat',
  'Vilmorin Mikado',
  'Virö Semillas',
  'Welcome Crop Science',
  'Westyard B.V.',
  'Wiersum Plantbreeding',
  'Winall Hi-Tech Seed',
  'Xinjiang Mag-Era Seed Co',
  'YAE NOGEI CO',
  'Yuksel Seeds',
  'ZFW Hortiservice',
  'Zinma Argentina',
  'Ad Terram BV',
  'Afson Seed Corporation',
  'Agrimedica',
  'Agrin Maroc',
  'Agripack Seeds',
  'Agroas',
  'Agrofuturo',
  'agroGene Inc.',
  'Alliance Seeds',
  'Alp Tarim',
  'Amerland Company',
  'Anamas Seed',
  'Anhui Huida Agro',
  'Ankur Seeds',
  'Anove',
  'Apricus Seeds',
  'Apsovsementi Spa',
  'Argenta Seeds BV',
  'Arysta Sas Part Of Upl',
  'Ashoka Farm Aids',
  'Ashoka Seeds',
  'Asia & Pacific Seed Association',
  'Assosementi',
  'Ball Horticultural',
  'Bayer',
  'Bc Institut',
  'Beauscape Farms',
  'Beijing Rytway Agriculture',
  'Bejo',
  'Berger International',
  'Better-Soli Seeds',
  'Brard Graines Sas',
  'Brettyoung',
  'Bspb Ltd',
  'Bursa Tohumculuk Zi̇raat Ve Ti̇caret A.ş',
  'C&M Seeds',
  'Cámara de Semilleristas de la Bolsa de Cereales',
  'Cates Grain and Seed Ltd',
  'Centor Europe',
  'Ceres Greenhouse Solutions',
  'Chalasem',
  'Chilean Seed Association ANPROS',
  'China Vegetable Seed Technology Co',
  'CIOPORA',
  'Clearwater Seed',
  'Cn Seeds Ltd',
  'Coker Tohumculuk',
  'Comagricola Green Europe',
  'Context Network',
  'Cora Seeds',
  'Corteva Agriscience',
  'Covestro',
  'CPVO',
  'CropLife International',
  'Cropmark Seeds Ltd',
  'Cullen Brokerage',
  'Dalian Shenghong Seed',
  'Danish Seed Association',
  'Deltagee',
  'Desert Sun Marketing Company',
  'Durga Seed Farm',
  'Euroseeds',
  'Euzaden B.v.',
  'Fairbanks Seeds',
  'Farm Semillas Production Company',
  'FarmHannong',
  'Farmson Bitoech',
  'Ferri Luigi Sementi',
  'Fertiprado - Sementes e Nutrientes',
  'Flecsa Holding',
  'Fleurantalya',
  'Flli. Zagaria Sementi',
  'FN GRANUM',
  'Foragen Seeds Private Limited',
  'Frasem',
  'GAAM Meijerink Consultancy',
  'Gadot Agro',
  'Gafta',
  'Galassi Sementi Srl Con Socio Unico',
  'Gene-G',
  'Geoponiki Sa',
  'Germains',
  'Germinal',
  'Glenn Seed Ltd.',
  'Global Seeds',
  'Globalgrass',
  'GMAX SEEDS SRL',
  'Gold Coast Agribusiness Pty. Ltd.',
  'Goldcrop',
  'Golden Field',
  'Granada Coating',
  'Green 2000- Agricultural Equipment & Know-How Ltd.',
  'Green Ara Vark - Association of Improved Seed Importers Of Iran',
  'Green Gold For Agriculture Offshore',
  'Groupe Limagrain Holding',
  'Gsn Semences',
  'Guangdong T Strong Agricultural Technology C',
  'Hahn und Karl Saatenhandel GmbH',
  'Haji Sons',
  'Harmoniz',
  'Hem Zaden B.V.',
  'Henan Huayi Seed Lndustry',
  'Hiphen',
  'Hm.clause',
  'Holland Select',
  'Hoopman Group',
  'Hortisem',
  'Huasheng Seed Group Co.,ltd.',
  'Hygrotech Sa (pty) Ltd',
  'ICRISAT',
  'IFSI',
  'Igna For Agriculture',
  'İkli̇m Bahçe Seeds Company',
  'ILVO',
  'Importadora Alaska',
  'Impulsores Internacionales',
  'Inari Agriculture',
  'Innovate to Grow',
  'Intersemillas S.a.',
  'Intertek',
  'Iran Bazr Co',
  'ISF',
  'Isi Sementi Spa',
  'Isla Sementes',
  'Italian Seed Association - Assosementi',
  'ITPGRFA - MLS Enhancement Working Group',
  'Ittehad Seed Corporation',
  'J Keeys Consultancy Ltd',
  'Japan Seed Trade Association',
  'Jensen Seed',
  'JiuQuan A&D.F Seed company',
  'Joeun Seeds Co',
  'Joordens Zaden',
  'Jubaili Seeds',
  'JW SEEDS',
  'K&n Efthymiadis Single Member Sa',
  'Kanda Seed Co',
  'Kaneka Corporation',
  'Kaneko Seeds Co',
  'Keithly-Williams Seeds',
  'Kenya Seed Company',
  'KeyGene',
  'Kincannon & Reed',
  'Klein Karoo Seed Production',
  'Kucuk Ciftlik Tohumculuk',
  'Kwonnong Seed Co',
  'KWS Cereals',
  'KWS Vegetables',
  'L. Stroetmann Saat GmbH & Co. KG',
  'L.H.J. Barenbrug B.V. Seedbrokers',
  'LA GUIA SA',
  'Lantmännen ek för',
  'Laxmi inputs India',
  'Lefroy Valley',
  'Legacy Agripartners',
  'Legume Technology',
  'LIDEA France',
  'M/s Harit',
  'Magnus Kahl Seeds BV',
  'Magrovet Group Kft.',
  'Mahyco Private Limited',
  'Mais Company',
  'Maison Florimond Desprez',
  'Makrogen',
  'Malav Seeds',
  'Maraldi Sementi Srl',
  'Maro Tarim A.s.',
  'Mas Seeds',
  'Matsunaga Seed Co.,ltd.',
  'Mediterranea Sementi Spa',
  'Meijer Potato',
  'Menaco',
  'Middle East Aftab Seeds',
  'Midlands Seed',
  'MILLENNIUM GREEN SRL',
  'Miyoshi Co.,ltd',
  'Miyoshi Group',
  'Mtn Seed Co.',
  'Naktuinbouw',
  'Nanto Seed',
  'Net Seeds Tarim San Ve Tic Ltd Sti',
  'Nethra Crop Sciences Pvt Ltd',
  'New Zealand Grain & Seed Trade Association',
  'Nippon Norin Seed Co.',
  'Norddeutsche Pflanzenzucht Hans-Georg Lembke KG',
  'Nordic Seed',
  'North American Export Grain Association',
  'Novisem BV',
  'Obs (organisation Bretonne de Sélection)',
  'OECD',
  'Omya (Schweiz) AG / Otto Hauenstein Samen',
  'Organisation for Economic Co-operation and Development (OECD)',
  'Oriental Seeds BV',
  'OriGene Seeds',
  'Oseva Pro S.r.o.',
  'Oseva Uni, A.s.',
  'Pacific International Seed Company, Inc.',
  'Paliz',
  'Panam Sas',
  'Pannar Seed',
  'Pasture Genetics Uruguay',
  'Pavlos Agrafiotis Sa',
  'Pennington Seed',
  'Pennington Seed - Seeds West',
  'Philippine Seed Industry Association',
  'Picasso Sa',
  'Pieterpikzonen bv',
  'Pinto Piga Seeds S.a.',
  'Pishgaman Sanat Va Bazr',
  'PJ Button Consultancy',
  'Plantanova Ltd.',
  'Plantseed (Private) Limited',
  'Poltar Tarim Urunleri San. Tic. Ltd. Sti.',
  'Poznańska Hodowla Roślin Sp. z o.o.',
  'PPH Agronas Sp. z o.o.',
  'PRASIDHI SEEDS',
  'Probstdorfer Saatzucht',
  'Progen Seed Inc.',
  'Prosementi Srl',
  'Protealis nv',
  'PZO Pflanzenzucht Oberlimpurg',
  'Ragt Semences',
  'Rizwan Seed Company',
  'Rolimpex SA',
  'Royal Sluis Magrovet Kft.',
  'Rudloff GmbH',
  'Rv Venturoli Srl',
  'S&W Seed Company',
  'Saa & Urupov',
  'SAATEN-UNION GmbH',
  'Saatzucht Steinach GmbH & Co KG',
  'Saenz Fety Sas',
  'Sais Spa',
  'Samen Steffen AG',
  'Sanatech',
  'Sanpa Semillas Europa',
  'SANSOR',
  'Sante Miatello & C. Srl',
  'SC AGROSEL SRL',
  'Scandinavian Seed AB',
  'Sd By Dalmau',
  'Seed Processing Holland',
  'Sgaravatti',
  'Shandong Degao Seed',
  'Shanghai Agriculture Information',
  'Shanghai Kunhe Seed Company Limited',
  'Shanghai Shengeng Agricultural Development',
  'Shenyang Zhongnong Kunchuang seed',
  'Shipra Vegetables And Grains',
  'Sicasov',
  'Sike Usa Inc',
  'Simlaw Seeds Company Ltd',
  'SIS Società Italiana Sementi SpA',
  'Skånefrö',
  'SLA - Semillas Latinoamericanas S.A.',
  'Smith Seeds Ltd',
  'Società Agricola fratelli DEugenio di Clsudia DEugenio & C. s.r.l.',
  'Solynta',
  'South African National Seed Organisation',
  'South Australian Seed Marketers',
  'South Pacific Seed Sales (New Zealand) Ltd',
  'Spirou House of Agriculture SA',
  'Staphyt',
  'Strube D&S GmbH',
  'Sub Union Of Seed Industrialists And Producers - Tsuab',
  'SUET Saat- und Erntetechnik GmbH',
  'Sumika Agreotech',
  'SW South West SA',
  'Swedish Seed Trade Association',
  'Synergy Seeds',
  'Tajuri Agricultural Development company',
  'Takada Seed Co.,ltd',
  'Tara Crop Sciences',
  'Tasaco Tarim Sanayi̇ Ti̇caret A.ş',
  'Team Seeds Pvt Ltd',
  'Technisem',
  'Teta Tohumculuk Ltd Sti.',
  'The Kiban',
  'The Musashino Seed Co Ltd',
  'The Rootstock Company',
  'Tianjin Tianlong Zaitian Science And Technology',
  'Top Farms Agro Sp. Z O.o.',
  'Topsource Seed Company',
  'Townsend Seeds International Ltd',
  'Tozer Seeds Ltd',
  'Traitomic A/S / Carlsberg Group',
  'Troya Seeds',
  'Trust Seed Corporation',
  'TSUAB',
  'Turkish Seed Industries Association Türkted',
  'Uniagro Exports Co Llp',
  'UNIFERT',
  'Unigen Seeds Spain / Akira Seeds',
  'Feldsaaten Freudenberger GmbH & Co.KG',
  'Germinal GB Ltd',
  'Germisem-sementes lda.',
  'Hahn&Karl Saatenhandel GmbH',
  'Henan Luyin Seed Technology Co.,ltd.',
  'Henan Luyin Seed Technology Co.,Ltd.',
  'Jensen Seeds',
  'JiuQuan A&D.F Seed Company',
  'KANEKA Corporation',
  'KWS SAAT SE & CO',
  'Kws Vegetables B.v.',
  'L.H.J. Barenbrug BV Seedbrokers',
  'La Guia Sa',
  'Lefroy Valley NZ',
  'Lidea France',
  'Mediterranea Sementi Srl',
  'Nordsaat Saatzucht GmbH',
  'P. H. Petersen Saatzucht Lundsgaard GmbH',
  'P.H.Petersen Saatzucht Lundsgaard GmbH',
  'Pinto Piga Seeds S.A.',
  'Saaten-Union GmbH',
  'Seed Processing Holland BV',
  'SG2000 SA',
  'Simlaw Seeds Companyltd',
  'South Pacific Seeds',
  'Strube Research GmbH & Co. KG',
  'Tajuri Agricultural Development Company',
  'Tara Crop Sciences (pvt.) Ltd.',
  'Tozer Seeds Netherlands BV',
  'United Genetics Seeds',
  'Vandinter Semo BV',
  'Yae Nogei Co., Ltd.',
  'Yuksel Tohum A.S.',
  'Bayer AG',
  'Bayer Seeds Sas',
  'AGF Seeds',
  'Agrarius',
  'Agri Information Partners',
  'Agristar',
  'Agrolitpa UAB',
  'Agronomix Software Inc.',
  'Agropick S.a.',
  'AGT Foods Africa (Pty) Ltd',
  'Anseme Spa',
  'Anti-Infringement Bureau (AIB)',
  'Apollo seeds',
  'Apollo Seeds USA Inc',
  'Asia Seed',
  'Australian Seed Federation',
  'Axia Vegetable Seeds',
  'Barenbrug',
  'BASF',
  'BASF Nunhems',
  'Bayer Crop Science',
  'Condor Seed',
  'Condor Seed Production Inc',
  'Crystal Crop Protection Limited',
  'Crystal Crop Sciences Ltd',
  'Curimapu',
  'Dalsace Freres S.a.s.',
  'De Bolster Organic Seeds',
  'De Groot en Slot',
  'de Groot En Slot',
  'Deutsche Saatveredelung AG',
  'Deutsche Saatveredelung AG (DSV)',
  'Diamond Seeds',
  'Diamond Seeds, S.l.',
  'Dingxi Potato Research Institute',
  'DLF',
  'DSV AG',
  'Dutch Agro Services B.V.',
  'East-West International B.V.',
  'East West Seed',
  'East-West Seed',
  'EconSeeds',
  'Eric Schweizer',
  'Eurofins Agroscience',
  'Flora Lusitana, Lda.',
  'G. Weishut Seedbrokers',
  'G.Weishut',
  'Gaia Seeds S.a.',
  'Germains Seed Technolgy',
  'Germains Seed Technology, Inc.',
  'German Plant Breeders Association (BDP)',
  'Graines Voltz',
  'Green Gold Agri Seeds',
  'Grupo ADK',
  'Hefei Hefeng Seed Co.,Ltd',
  'HeinzSeed',
  'Hektar Seeds Srl',
  'Hollar Seeds',
  'Honorary Life Member',
  'Hungarian Seed Association',
  'Illinois Foundation Seeds, Inc.',
  'Incotec America do Sul Ltd.',
  'Incotec Europe BV',
  'Incotec Europe Bv',
  'Indo American Hybrid Seeds',
  'International Seed Testing Association (ISTA)',
  'Kobayashi Seed Co., Ltd.',
  'Kohenoor International',
  'Kulcsar Vetomag Kft.',
  'Luisetti Seeds Limited',
  'Manna Irrigation',
  'May Seed',
  'Moravoseed Cz A.s.',
  'Mountain View Seeds',
  'Namdhari Seeds',
  'Net Seeds Tarim Sanayi̇ Ti̇caret Ltd Şti̇',
  'Norseco',
  'Nunhems Netherlands BV',
  'Pairwise',
  'PGG Wrightson Seeds',
  'PGG Wrightson Seeds Limited',
  'PGG Wrightson Seeds South America',
  'Phenome Networks',
  'Phenome-Networks',
  'Piotr Szyld Seed Brokers and Consultants',
  'Plantgene Seeds Private Limited',
  'Pure Seed | Pure-Seed Testing',
  'Pure-Seed Testing',
  'Ramiro Arnedo Sa',
  'Rhino Research Europe',
  'Rossen Seeds',
  'Safal Seeds',
  'Sanya Dabeinong Genseed Genetics Co. Ltd',
  'Semillas Fitó',
  'Smith Seed Exports',
  'Smith Seed Services Exports',
  'Snow Brand Seed Co',
  'Starke Ayres',
  'Suba Seeds',
  'Syngenta',
  'Teague Australia',
  'Terranova Seeds',
  'Verdant Partners',
  'Verisem',
  'Vikima Seed',
  'Vokkal',
  'Legutko',
  'Wageningen Plant Research',
  'Wageningen University & Research',
  'Winseed International Ltd',
];

const filterNames = (names: string[]) => {
  return names.reduce((acc, name) => {
    const parts = name.split(' ');
    // eslint-disable-next-line no-restricted-syntax
    for (const p of parts) {
      const parsed = p.toLocaleLowerCase().replace(/[^a-z]/gi, '');
      if (parsed.length > 2 && parsed !== 'seed' && parsed !== 'seeds') {
        acc.add(parsed);
      }
    }
    return acc;
  }, new Set<string>());
};
export const companyNames = filterNames(names);
