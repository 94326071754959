import { FC, useCallback } from 'react';

import { Control, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { CommonButton, CommonModal, RadioGroupController } from 'components';
import { styles } from './styles';

interface CancelPopupProps {
  isOpen: boolean;
  isBuyer: boolean;
  sellerCompanyName: string;
  buyerCompanyName: string;
  handleClose: () => void;
  handleCancel: (reason: string) => void;
}

const messages = {
  decline: 'Decline Quote',
  submit: 'Decline',
  cancelRequest: 'Cancel Request',
  buyerSubmit: 'Cancel',
  outOfStock: 'Out of Stock',
  insufficientStock: 'Insufficient Stock',
  geographicReasons: 'Geographic Reasons',
  other: 'Other',
  goBack: 'Go back',
  warningTitle: 'Before you go –',
  warning:
    'The Sproutzo quote systems do allow you to suggest other quantities. ' +
    'If you’d like to offer another amount, select “Go back”, then click the pen tool next to the quantity',
};

const choices = [
  { value: 'outOfStock', label: messages.outOfStock },
  { value: 'insufficientStock', label: messages.insufficientStock },
  { value: 'geographicReasons', label: messages.geographicReasons },
  { value: 'other', label: messages.other },
];

export const CancelPopup: FC<CancelPopupProps> = ({
  isBuyer,
  sellerCompanyName,
  handleCancel,
  buyerCompanyName: buyer,
  handleClose,
  isOpen = false,
}) => {
  const { control, watch } = useForm<{ reason: string }>({});
  const reason = watch('reason');
  const cancel = useCallback(() => {
    handleClose();
    handleCancel(reason);
  }, [handleClose, handleCancel, reason]);

  return (
    <CommonModal title={isBuyer ? messages.cancelRequest : messages.decline} isOpen={isOpen} handleClose={handleClose}>
      {isBuyer ? (
        <>
          <Typography sx={{ ...styles.content }}>
            {`This will archive this request. ${sellerCompanyName} will no longer be able to respond.`}
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={{ ...styles.content }}>
            {`Are you sure you want to decline this quote request from ${buyer} If so, please share a reason.`}
          </Typography>
          <Box>
            <RadioGroupController controlName="reason" control={control as unknown as Control} data={choices} />
          </Box>
        </>
      )}
      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          {messages.goBack}
        </CommonButton>
        <CommonButton
          variant="text"
          disabled={!isBuyer && !reason}
          color="error"
          sx={{ ...styles.closeButton }}
          onClick={cancel}
        >
          {isBuyer ? messages.buyerSubmit : messages.submit}
        </CommonButton>
      </Box>
      {!isBuyer && reason === 'insufficientStock' && (
        <Box sx={styles.warningContainer}>
          <Box sx={styles.warningTextContainer}>
            <Typography component="span" sx={[styles.warningTitle, styles.warningText]}>
              {messages.warningTitle}
            </Typography>
            <Typography component="span" sx={styles.warningText}>
              {messages.warning}
            </Typography>
          </Box>
        </Box>
      )}
    </CommonModal>
  );
};
