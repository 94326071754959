import { useCallback, useMemo, useState } from 'react';

import { Control, UseFormGetValues, UseFormReset } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { useModal } from 'hooks';
import { useGeneticTypeFilter } from '../../../../GeneticTypeFilter/hooks';

interface UseTypeFilterProps {
  control: Control<ProductsFiltersForm>;
  controlName: string;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
}

export const useTypeFilter = ({ control, controlName, getValues, reset }: UseTypeFilterProps) => {
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const [previousValues, setPreviousValues] = useState<ProductsFiltersForm | null>(null);

  const { currentValue, filterTitle, isSelected } = useGeneticTypeFilter(
    control as unknown as Control,
    controlName,
    true,
  );

  const isFilterChanged = useMemo(
    () => previousValues?.geneticType !== currentValue,
    [currentValue, previousValues?.geneticType],
  );

  const handleOpen = useCallback(() => {
    setPreviousValues(getValues());
    reset();
    handleOpenModal();
  }, [getValues, handleOpenModal, reset]);

  const handleResetSelected = useCallback(() => {
    if (previousValues) {
      reset(previousValues);
    }
  }, [previousValues, reset]);

  const handleClose = useCallback(() => {
    handleResetSelected();
    handleCloseModal();
  }, [handleCloseModal, handleResetSelected]);

  const handleConfirm = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  return {
    counter: isSelected ? 1 : 0,
    isFilterChanged,
    filterTitle,
    open,
    handleCloseModal: handleClose,
    handleOpenModal: handleOpen,
    handleConfirm,
  };
};
