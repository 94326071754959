import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  viewButton: {
    height: '14px',
    width: '64px',
    minWidth: '64px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: customTheme.custom.white,
    border: `1px solid ${customTheme.custom.white}`,
    borderRadius: '25px',
    textAlign: 'center',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.25)',
  },
  wrap: {
    height: '52px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.12)',
    overflow: 'hidden',
    cursor: 'pointer',

    '&:hover': {
      borderColor: customTheme.custom.white,
      backgroundColor: 'transparent',
      textShadow: '1px 1px 3px rgba(0, 0, 0, 0.18)',
      boxShadow: '2px 2px 7px 0px rgba(0, 0, 0, 0.18)',
    },
  },
  header: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: customTheme.custom.black.B30,
  },

  footer: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: customTheme.custom.gray.G200,
  },
  name: {
    paddingLeft: '6px',
    paddingRight: '6px',
    width: '100%',
    fontSize: '8px',
    lineHeight: '18px',
    fontWeight: 400,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: customTheme.custom.gray.G700,
  },
};
