import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import { Box } from '@mui/material';

import { CropFamiliesData } from 'app/api/crops/types';
import { SideCategoriesBar } from 'components';
import { styles } from './styles';

interface SideCategoriesPermanentBarProps {
  data: CropFamiliesData;
  handleScrollState: Dispatch<SetStateAction<boolean>>;
  scrollTop: number | undefined;
}

export const SideCategoriesPermanentBar: FC<SideCategoriesPermanentBarProps> = ({
  data,
  handleScrollState,
  scrollTop,
}) => {
  const handleClose = useCallback(() => {
    handleScrollState(true);
  }, [handleScrollState]);

  const handleOpen = useCallback(() => {
    handleScrollState(false);
  }, [handleScrollState]);

  return (
    <Box sx={{ ...styles.wrap }}>
      <SideCategoriesBar
        scrollTop={scrollTop}
        isPermanent
        top="90px"
        data={data}
        onClose={handleClose}
        onOpen={handleOpen}
      />
    </Box>
  );
};
