import { ShipmentType, ShipmentInfo } from 'app/api/orders/types';
import { PalletInfo } from './PalletInfo';
import { BoxInfo } from './BoxInfo';
import { ContainerInfo } from './ContainerInfo';

export const getMainShipmentInfo = (data: ShipmentInfo): JSX.Element => {
  switch (data.shipmentType) {
    case ShipmentType.PALLETS:
      return <PalletInfo {...data} />;

    case ShipmentType.BOXES:
      return <BoxInfo {...data} />;

    case ShipmentType.CONTAINERS:
      return data.containerType ? <ContainerInfo {...data} /> : <></>;

    default:
      return <></>;
  }
};
