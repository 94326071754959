import { customTheme } from './theme';

export const scrollMixin = {
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: customTheme.custom.black.B30,
    borderRadius: '8px',
  },
};
