import { customTheme } from '../../theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '210px',
    backgroundColor: customTheme.custom.white,
    height: 'calc(100% + 76px)',
    zIndex: '1',
  },
};
