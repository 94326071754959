import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { Control, FieldValues, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { useCheckboxFilter } from 'components/CheckboxFilter/hooks';
import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import { useModal } from 'hooks';
import { getIsArraysWithSameElements } from 'utils/getIsArraysWithSameElements';
import { colorFilterAll } from '../../../constants';

interface UseCheckboxFilterPopupProps {
  control: Control<ProductsFiltersForm>;
  controlName: string;
  data: {
    value: string;
    label: string;
  }[];
  defaultFilter: CheckboxFilterItem;
  defaultTitle: string;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
  setValue: UseFormSetValue<ProductsFiltersForm>;
  handleApplyFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const useCheckboxFilterPopup = ({
  control,
  controlName,
  data,
  defaultFilter,
  defaultTitle,
  getValues,
  reset,
  setValue,
  handleApplyFilterChange,
}: UseCheckboxFilterPopupProps) => {
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const [previousValues, setPreviousValues] = useState<ProductsFiltersForm | null>(null);

  const previosFiledValues = useMemo(
    () => ((previousValues?.[controlName as keyof ProductsFiltersForm] as string[]) ?? []).filter((value) => !!value),
    [controlName, previousValues],
  );

  const { currentValues, resetFilters } = useCheckboxFilter(
    data,
    control as unknown as Control,
    controlName,
    defaultFilter,
    defaultTitle,
    setValue as unknown as UseFormSetValue<FieldValues>,
    handleApplyFilterChange,
  );

  const isFilterChanged = useMemo(
    () =>
      !getIsArraysWithSameElements(previosFiledValues, currentValues) &&
      !(previosFiledValues.length === 1 && previosFiledValues?.[0] === colorFilterAll.value && !currentValues.length),
    [currentValues, previosFiledValues],
  );

  const counter = useMemo(() => {
    return currentValues.length >= 1 && currentValues[0].valueOf() !== colorFilterAll.value ? currentValues.length : 0;
  }, [currentValues]);

  const handleOpen = useCallback(() => {
    setPreviousValues(getValues());
    reset();
    handleOpenModal();
  }, [getValues, handleOpenModal, reset]);

  const handleResetSelected = useCallback(() => {
    if (previousValues) {
      reset(previousValues);
    }
  }, [previousValues, reset]);

  const handleClose = useCallback(() => {
    handleResetSelected();
    handleCloseModal();
  }, [handleCloseModal, handleResetSelected]);

  const handleConfirm = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  return {
    counter,
    filterTitle: defaultTitle,
    isFilterChanged,
    open,
    handleCloseModal: handleClose,
    handleOpenModal: handleOpen,
    handleConfirm,
    resetFilters,
  };
};
