import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  input: {
    ':hover': {
      border: `1px solid ${customTheme.custom.black.B10}`,
      backgroundColor: customTheme.custom.black.B5,

      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        backgroundColor: customTheme.custom.white,
      },
    },

    '.MuiInputAdornment-positionEnd': {
      color: customTheme.custom.black.B100,

      [`@media (min-width: ${breakpoints.tablet}px)`]: {
        ':hover': {
          color: customTheme.custom.black.B50,
        },
      },
    },
    '.MuiInputBase-inputMultiline': {
      ...scrollMixin,
    },
  },
};
