import { FC } from 'react';

import { Menu } from '@mui/material';

import { LoadingButton } from 'components';
import { useNotificationsSettings } from 'hooks';
import { MuteNotificationsSwitch } from '../MuteNotificationsSwitch';
import { styles } from './styles';

interface NotificationsSettingsProps {
  channelUrl: string;
  companyName: string;
}

export const NotificationsSettings: FC<NotificationsSettingsProps> = ({ channelUrl, companyName }) => {
  const { anchorEl, initialSettingsValues, isLoading, open, handleCloseMemu, handleSettingsClick } =
    useNotificationsSettings({
      channelUrl,
    });

  return (
    <>
      <LoadingButton loading={isLoading ?? true} sx={styles.button} onClick={handleSettingsClick} variant="text">
        Settings
      </LoadingButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
          sx: { ...styles.menu },
        }}
        sx={styles.menuWrap}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleCloseMemu}
      >
        <MuteNotificationsSwitch
          channelUrl={channelUrl}
          companyName={companyName}
          initialValues={initialSettingsValues}
        />
      </Menu>
    </>
  );
};
