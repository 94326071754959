import { FC, useCallback } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { OnNotificationClickProps } from 'app/api/notifications';
import { styles } from './styles';

interface NotificationItemProps {
  id: number;
  message: string;
  isNew: boolean;
  date: string;
  link: string;
  isLast: boolean;
  handleItemClick: ({ link, isNew, id }: OnNotificationClickProps) => void;
}

export const NotificationItem: FC<NotificationItemProps> = ({
  id,
  message,
  isNew,
  date,
  link,
  isLast,
  handleItemClick,
}) => {
  const handleClick = useCallback(() => {
    handleItemClick({ link, isNew, id });
  }, [handleItemClick, link, isNew, id]);

  return (
    <>
      <Box sx={{ ...styles.wrap }} onClick={handleClick}>
        <Box sx={{ ...styles.inner }}>
          <Typography sx={{ ...styles.message(isNew) }}>{message}</Typography>
          <Typography component="span" sx={{ ...styles.date }}>
            {date}
          </Typography>
        </Box>
        {isNew && <Box component="span" sx={{ ...styles.indicator }} />}
      </Box>
      {!isLast && <Divider />}
    </>
  );
};
