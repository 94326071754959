import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface CellHeaderProps {
  title: string;
  subtitle?: string;
  isInline?: boolean;
}

export const CellHeader: FC<CellHeaderProps> = ({ title, subtitle, isInline = false }) => {
  return (
    <Box sx={styles.wrap(isInline)}>
      <Typography component="span" sx={styles.title}>
        {title}{' '}
      </Typography>
      {subtitle && (
        <Typography component="span" sx={styles.subtitle}>
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};
