import React, { FC, useCallback } from 'react';

import { NavLink, generatePath } from 'react-router-dom';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { Product } from 'app/api/products/types';
import { CompanyDetail } from 'app/api/company/types';
import { ROUTES } from 'app/routes/constants';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { ProductListAccordion } from '../ProductListAccordion';
import { ProductRow } from '../ProductRow';
import { tableHeaderColumns } from './constants';
import { styles } from './styles';

export interface CompanyProductsTableProps {
  data: Product[];
  company: CompanyDetail | undefined;
  choosedProductsKeys: Set<number>;
  isSampleRequest: boolean;
  setChoosedProducts: (value: Product) => void;
}

export const CompanyProductsTable: FC<CompanyProductsTableProps> = ({
  data,
  company,
  choosedProductsKeys,
  isSampleRequest,
  setChoosedProducts,
}) => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const path = company ? generatePath(ROUTES.common.company, { displayId: company.displayId }) : '';

  const handleCompanyLinkClick = useCallback(() => {
    handleTrackLinkClickEvent({ type: AnalyticsLinkType.LINK, path, text: company?.name ?? '' });
  }, [company?.name, handleTrackLinkClickEvent, path]);

  return (
    <>
      <Typography sx={{ ...styles.title }} data-testid="request-quote-title">
        Add more seeds from{' '}
        <NavLink to={path} style={{ textDecoration: 'none' }} target="_blank" onClick={handleCompanyLinkClick}>
          <Typography sx={{ ...styles.link }} component="span">
            {company?.name ?? 'NaN'}
          </Typography>
        </NavLink>{' '}
        to your quote?
      </Typography>

      <Table sx={{ ...styles.table }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ ...styles.headerRow }}>
            {tableHeaderColumns.map((label) => (
              <TableCell key={label} sx={{ ...styles.cell, ...styles.headerCell }} align="left">
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ ...styles.tableBody }}>
          {data
            .filter((item) => item.inStock)
            .map((item) => (
              <React.Fragment key={item.id}>
                <ProductRow
                  data={item}
                  choosedProductsKeys={choosedProductsKeys}
                  setChoosedProducts={setChoosedProducts}
                  isSampleRequest={isSampleRequest}
                />
              </React.Fragment>
            ))}
          {data
            .filter((item) => !item.inStock)
            .map((item) => (
              <React.Fragment key={item.id}>
                <ProductRow
                  data={item}
                  choosedProductsKeys={choosedProductsKeys}
                  setChoosedProducts={setChoosedProducts}
                  isSampleRequest={isSampleRequest}
                />
              </React.Fragment>
            ))}
        </TableBody>
      </Table>

      <Box sx={styles.accordionWrap}>
        <ProductListAccordion
          data={data}
          choosedProductsKeys={choosedProductsKeys}
          setChoosedProducts={setChoosedProducts}
          isSampleRequest={isSampleRequest}
        />
      </Box>
    </>
  );
};
