import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { TextFormField, TextFormFieldProps } from '../TextFormField';

import { ReactComponent as Eye } from '../../assets/icons/eyeOpen.svg';
import { ReactComponent as EyeSlash } from '../../assets/icons/eyeClose.svg';
import { styles } from './styles';

export const MaskedTextFormField: FC<TextFormFieldProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const maskedIcon = () => (
    <Box sx={{ ...styles.iconContainer }} onClick={() => setShowPassword((value) => !value)}>
      {showPassword ? <EyeSlash /> : <Eye />}
    </Box>
  );

  return <TextFormField endAdornment={maskedIcon()} type={showPassword ? 'text' : 'password'} {...props} />;
};
