import { customTheme } from 'theme/MuiThemeProvider/theme';

export const customColorSet = {
  '--sendbird-light-primary-500': customTheme.palette.primary.P100,
  '--sendbird-light-primary-400': customTheme.palette.primary.P100,
  '--sendbird-light-primary-300': customTheme.palette.primary.P70,
  '--sendbird-light-primary-200': customTheme.palette.primary.P50,
  '--sendbird-light-primary-100': customTheme.palette.primary.P30,
  '--sendbird-light-onlight-01': customTheme.custom.black.B70,
  '--sendbird-light-onlight-02': customTheme.custom.black.B50,
  '--sendbird-light-onlight-03': customTheme.custom.black.B30,
  '--sendbird-light-onlight-04': customTheme.custom.black.B20,
};
