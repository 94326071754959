import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: (columnCount?: number) => ({
    display: 'grid',
    gap: '8px',
    gridTemplateColumns: '1fr 1fr',
    height: '100%',
    padding: '0 14px 24px 14px',
    overflowX: 'visible',

    ...(columnCount && {
      gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: columnCount === 1 ? '16px' : '10px',
      paddingTop: '4px',
    },
  }),
  loaderWrap: (hasHeight: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0',
    height: `${hasHeight ? '36px' : 'auto'}`,
  }),
};
