import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  date: {
    display: 'block',
    marginTop: '2px',
    fontSize: '8px',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '0.2px',
    color: customTheme.custom.black.B70,
    textAlign: 'right',
  },
};
