import { AxiosResponse } from 'axios';
import { RequestProductsForm } from 'components/RequestProductModal/components/form';
import { contentApi, customerApi } from '..';
import { apiRoutes } from '../apiRoutes';
import { PublicFormData } from './types';

export const postPublicForm = async (data: PublicFormData) => {
  const response = await contentApi.post<PublicFormData, AxiosResponse<{ id: number }>>(apiRoutes.publicForm._, data);
  return response?.data;
};

export const patchPublicForm = async (id: number, data: PublicFormData) => {
  const response = await contentApi.patch<PublicFormData, AxiosResponse<{ id: number }>>(
    apiRoutes.publicForm.byId(id),
    data,
  );
  return response?.data;
};

export const postRequestProductsForm = async (data: RequestProductsForm, isUserSignedIn: boolean) => {
  const api = isUserSignedIn ? customerApi : contentApi;

  const response = await api.post<RequestProductsForm, AxiosResponse<{ id: string }>>(
    apiRoutes.requestProductsForm._,
    data,
  );
  return response?.data;
};

export const patchRequestProductsForm = async (
  id: string,
  updateFields: RequestProductsForm,
  isUserSignedIn: boolean,
) => {
  const api = isUserSignedIn ? customerApi : contentApi;
  // eslint-disable-next-line
  return api.patch<RequestProductsForm>(apiRoutes.requestProductsForm.byId(id), updateFields);
};
