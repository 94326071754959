import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import logo from 'assets/images/headerLogo.svg';
import { ReactComponent as UserIcon } from 'assets/icons/userSmall.svg';
import { CommonButton } from 'components';
import { styles } from './styles';

interface MobileSidebarHeaderProps {
  onAccountClick?: () => void;
}

export const MobileSidebarHeader: FC<MobileSidebarHeaderProps> = ({ onAccountClick }) => {
  return (
    <Box sx={styles.header}>
      <Box sx={styles.logo}>
        <Box component="img" src={logo} />
      </Box>
      {!!onAccountClick && (
        <CommonButton variant="text" sx={styles.accountButton} onClick={onAccountClick}>
          <UserIcon />
          <Typography component="span" sx={styles.accountButtonText}>
            My Account
          </Typography>
        </CommonButton>
      )}
    </Box>
  );
};
