import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonModal } from '../../../../components/CommonPopup';
import { CommonButton } from '../../../../components/CommonButton';
import { LoadingButton } from '../../../../components/LoaderButton';
import { styles } from './styles';

interface CancelOrderModalProps {
  isOpen: boolean;
  isLoading: boolean;
  handleClose: () => void;
  handleCancel: () => void;
}

export const CancelOrderModal: FC<CancelOrderModalProps> = ({ isOpen, isLoading, handleClose, handleCancel }) => {
  return (
    <CommonModal title="Cancel order?" isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>Are you sure you want to cancel this order?</Typography>
      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Go back
        </CommonButton>
        <LoadingButton
          loading={isLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.logoutButton }}
          onClick={handleCancel}
        >
          Cancel order
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
