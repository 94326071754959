import { useMemo } from 'react';

import { useQuery } from 'react-query';

import { getOrdersData } from 'app/api/orders/service';
import { OrderStatus, OrdersListData } from 'app/api/orders/types';
import { SortTypes } from '../types';
import { sortOrders } from '../utils';

interface OrdersQueryI {
  status: OrderStatus[];
  enabled: boolean;
  sortValue: SortTypes;
  requestSample?: boolean;
  buyerCompanyId?: number;
  sellerCompanyId?: number;
}

interface OrdersQueryReturn {
  isLoading: boolean;
  data: {
    list: OrdersListData;
    canceledList: OrdersListData;
  };
}

export const useOrdersQuery = ({
  enabled,
  sortValue,
  status,
  requestSample,
  buyerCompanyId,
  sellerCompanyId,
}: OrdersQueryI): OrdersQueryReturn => {
  const { isLoading, isFetching, data } = useQuery(
    ['orders', status, requestSample, buyerCompanyId, sellerCompanyId],
    () => getOrdersData({ status, requestSample, buyerCompanyId, sellerCompanyId }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    },
  );

  const sortedData = useMemo(() => (data ? sortOrders([...data], sortValue) : []), [data, sortValue]);

  const list = useMemo(
    () => sortedData?.filter(({ status }) => status !== OrderStatus.CANCELED) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedData, sortValue],
  );

  const canceledList = useMemo(
    () => sortedData?.filter(({ status }) => status === OrderStatus.CANCELED) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortedData, sortValue],
  );

  return {
    isLoading: isLoading || isFetching,
    data: {
      list,
      canceledList,
    },
  };
};
