export const getFileExtension = (src: string | null | undefined) => {
  let result = '';

  try {
    result = src?.split('.')?.pop()?.toLowerCase() ?? '';
  } catch (e) {
    /* empty */
  }
  return result.toLowerCase();
};
