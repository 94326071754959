import { customTheme } from 'theme/MuiThemeProvider/theme';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { ColorType } from './index';

export const styles = {
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '12px',
      minHeight: '24px',
    },
  },
  label: (color?: ColorType) => ({
    fontSize: '12px',
    lineHeight: '20px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,

    ...(color && {
      color: color === 'secondary' ? customTheme.palette.secondary.S100 : customTheme.palette.primary.P100,
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'fit-content',
      textAlign: 'left',
      lineHeight: '16px',
    },
  }),
  value: (color?: ColorType) => ({
    fontSize: '11px',
    lineHeight: '20px',
    fontWeight: 500,
    color: customTheme.custom.gray.G600,

    ...(color && {
      color: color === 'secondary' ? customTheme.palette.secondary.S100 : customTheme.palette.primary.P100,
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      textAlign: 'right',
      fontSize: '12px',
      lineHeight: '16px',
      flexGrow: 1,
    },
  }),
};
