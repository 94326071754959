import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';

interface SBMessageContainerProps {
  children: ReactNode;
  isCurrentUser?: boolean;
}

export const SBMessageContainer: FC<SBMessageContainerProps> = ({ children, isCurrentUser = false }) => {
  return <Box sx={styles.wrap({ isCurrentUser })}>{children}</Box>;
};
