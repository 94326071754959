import { FC } from 'react';

import { Control } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';

import { NotificationsSettingsForm } from 'app/api/SBChat';
import { MuteSwitchControl } from '../MuteSwitchControl';
import { styles } from './styles';

interface MuteNotificationsFormControlLabelProps {
  control: Control<NotificationsSettingsForm>;
  isLabelDark: boolean;
  label: string;
  name: keyof NotificationsSettingsForm;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const MuteNotificationsFormControlLabel: FC<MuteNotificationsFormControlLabelProps> = ({
  control,
  label,
  name,
  value,
  isLabelDark,
  onChange,
}) => {
  return (
    <FormControlLabel
      labelPlacement="end"
      sx={styles.controlLabel}
      componentsProps={{
        typography: {
          sx: styles.label(isLabelDark),
        },
      }}
      control={<MuteSwitchControl control={control} name={name} value={value} onChange={onChange} />}
      label={label}
    />
  );
};
