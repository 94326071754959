export const capitalizeFirstLetter = (value: string): string => {
  let result = '';

  try {
    result = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  } catch (e) {
    /* empty */
  }

  return result;
};

// NOTE: This does not work
export const capitalizeAll = (value: string): string => {
  let result = '';

  try {
    const arr = value.split(' ');
    arr.forEach((word) => word.charAt(0).toUpperCase() + word.slice(1));
    result = arr.join(' ');
  } catch (e) {
    /* empty */
  }

  return result;
};

// NOTE: This is a duplicate of capitalizeAll, but functional
export const capitalizeFirstLetters = (value: string): string => {
  let result = '';

  try {
    const arr = value.split(' ');
    result = arr.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  } catch (e) {
    /* empty */
  }
  return result;
};

export const formatCamelCaseToTitleText = (value: string) => {
  let result = '';

  try {
    const replaced = value.replace(/([A-Z])/g, ' $1');
    result = replaced.charAt(0).toUpperCase() + replaced.slice(1);
  } catch (e) {
    /* empty */
  }

  return result;
};

export const removeExtraSpaces = (value: string): string => value.replace(/\s+/g, ' ');

export const capitalizeOnlyFirstLetter = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

export const getSeparator = (separatorType: 'group' | 'decimal', locale: string = navigator.language) => {
  const numberWithGroupAndDecimalSeparator = 1000.1;
  return Intl.NumberFormat(locale)
    .formatToParts(numberWithGroupAndDecimalSeparator)
    .find((part) => part.type === separatorType)?.value;
};

export const formatNumber = (
  num: number,
  options?: Intl.NumberFormatOptions,
  locale: Intl.LocalesArgument = navigator.language,
) => {
  return num.toLocaleString(locale, options);
};

export const formatQuantityAvailableUnits = (units: string | undefined) => {
  if (units === 'THOUSAND_SEEDS') {
    return 'Thousand Seeds';
  }
  if (units !== undefined) {
    return units;
  }
  return 'KG';
};
