import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (hasSubtext: boolean, isSmallTitle: boolean) => ({
    position: 'relative',
    padding: '0 0 16px 88px',
    margin: '0 -88px 16px',
    overflow: 'hidden',
    [`@media (min-width: 1900px)`]: {
      ':before': {
        position: 'absolute',
        zIndex: 1,
        content: '""',
        height: '100%',
        width: '32px',
        left: 0,
        background: 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
      },
      ':after': {
        position: 'absolute',
        zIndex: 2,
        content: '""',
        height: '100%',
        width: '32px',
        right: 0,
        top: 0,
        background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
      },
    },
    '& .slick-slider:not([dir])': {
      marginLeft: '-12px',
    },
    '& .slick-track': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& .slick-arrow': {
      position: 'absolute',
      top: 0,
      ...(isSmallTitle && { top: 0 }),
      ...(hasSubtext && { top: '16px' }),
      '&.slick-next': {
        right: '88px',
      },
      '&.slick-prev': {
        right: '128px',
      },
    },
    '& .slick-list': {
      margin: '0 -12px',
      paddingLeft: '12px',
    },
    '& .slick-slide': {
      margin: '0 12px',
      height: 'inherit',
      '& > div': {
        height: '100%',
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 2,
      '& .slick-slider:not([dir])': {
        marginLeft: '-6px',
      },

      '& .slick-list': {
        margin: '0 -6px',
        paddingLeft: '6px',
      },
      '& .slick-slide': {
        margin: '0 6px',
      },
      '& .slick-arrow': {
        top: '0px',
      },
      '& .slick-arrow.slick-prev ': {
        right: '128px',
      },
    },
  }),
  subText: {
    display: 'inline-flex',
    alignItems: 'center',
    color: '#71717A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 'normal',
    margin: '0px 0px 16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      fontWeight: 600,
      margin: '4px 0px 16px',
    },
  },
  slideWrap: {
    height: '100%',
  },
  title: (hasSubtext: boolean) => ({
    userSelect: 'none',
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    lineHeight: 1.2,
    marginBottom: '16px',
    ...(hasSubtext && { marginBottom: '0px' }),
  }),
  button: {
    minWidth: '32px',
    width: '32px',
    height: '32px',
    padding: '3px',
    borderRadius: '50%',
    ':hover': {
      backgroundColor: customTheme.palette.primary.P10,
      borderColor: customTheme.palette.primary.P10,
    },
    ':disabled path': {
      fill: customTheme.custom.black.B30,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: '28px',
      width: '28px',
      height: '28px',
      padding: '1px',

      ':hover': {
        backgroundColor: 'transparent',

        ':not(:disabled)': {
          borderColor: '#278B9F80',
        },

        ':disabled': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
        },
      },

      svg: {
        position: 'relative',
        right: '-1px',
      },
    },
  },
  arrowLeft: {
    transform: 'rotate(-180deg)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      right: 'unset',
      left: '-1px',
    },
  },
};
