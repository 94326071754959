import { UseFormSetValue } from 'react-hook-form';

import { MuteStatus, NotificationsSettingsForm } from 'app/api/SBChat';

interface GetMuteStatusProps {
  checked: boolean;
  isAll: boolean;
  isAllMuted: boolean;
  isMessages: boolean;
  isMessagesMuted: boolean;
  setValue: UseFormSetValue<NotificationsSettingsForm>;
}

export const getMuteStatus = ({
  checked,
  isAll,
  isAllMuted,
  isMessages,
  isMessagesMuted,
  setValue,
}: GetMuteStatusProps) => {
  let muteStatus = MuteStatus.NO_MUTE;

  if (isAll && checked) {
    setValue('messages', true);
    muteStatus = MuteStatus.MUTE_ALL;
  }

  if (isAll && !checked) {
    muteStatus = isMessagesMuted ? MuteStatus.MUTE_MESSAGES : MuteStatus.NO_MUTE;
  }

  if (isMessages && checked) {
    muteStatus = isAllMuted ? MuteStatus.MUTE_ALL : MuteStatus.MUTE_MESSAGES;
  }

  if (isMessages && !checked) {
    muteStatus = isAllMuted ? MuteStatus.MUTE_ALL_EXCEPT_MESSAGES : MuteStatus.NO_MUTE;
  }

  return muteStatus;
};
