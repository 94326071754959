import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '38px',
  },
  moreButton: {
    '& > svg': {
      transform: 'rotate(90deg)',
    },
  },
  menuItem: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B100,
  },
  removeItem: {
    color: customTheme.custom.error,
  },
};
