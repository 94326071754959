import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  tabsWrap: {
    marginBottom: '12px',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTabs-flexContainer': {
      gap: '20px',
    },
    minHeight: '20px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,

      '.MuiTabs-flexContainer': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(auto, 1fr))',
        gridTemplateRows: '1fr 1fr',
        gap: '12px 6px',
        padding: '12px 18px',
      },
    },
    [`@media (max-width: 396px)`]: {
      '.MuiTabs-flexContainer': {
        gridTemplateColumns: 'repeat(2, auto)',
        columnGap: '2px',
      },
    },
  },
  tabWrap: {
    width: 'fit-content',
    padding: 0,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
    whiteSpace: 'nowrap',
    textTransform: 'inherit',
    color: customTheme.custom.black.B70,
    minHeight: 'fit-content',
    minWidth: 'fit-content',

    '&.Mui-selected': {
      color: customTheme.custom.black.B100,
      textDecoration: 'underline',
      textDecorationSkipInk: 'none',
      textDecorationThickness: '2px',
      textDecorationColor: customTheme.palette.secondary.S100,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
};
