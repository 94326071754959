import { FC } from 'react';

import { format } from 'date-fns';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, CircularProgress, Typography } from '@mui/material';

import { InAppNotificationsList, OnNotificationClickProps } from 'app/api/notifications';
import { NotificationItem } from '../NotificationItem';
import { styles } from './styles';

const SCROLLABLE_CONTAINER_ID = 'scrollableContainer';

const dateFormat = 'dd.MM.yyyy';

interface NotificationsListProps {
  data: InAppNotificationsList;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<void>;
  handleItemClick: ({ link, isNew, id }: OnNotificationClickProps) => void;
}

export const NotificationsList: FC<NotificationsListProps> = ({
  data,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  handleItemClick,
}) => {
  return (
    <Box id={SCROLLABLE_CONTAINER_ID} sx={{ ...styles.wrap }}>
      <InfiniteScroll
        dataLength={data.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={
          <Box sx={{ ...styles.loaderWrap(hasNextPage) }}>{isFetchingNextPage && <CircularProgress size={24} />}</Box>
        }
        scrollableTarget={SCROLLABLE_CONTAINER_ID}
      >
        <Typography component="h3" sx={styles.title}>
          Notifications
        </Typography>
        {data.map(({ id, inAppCreated, inAppIsRead, inAppText, viewObjectUrl }, index) => (
          <NotificationItem
            key={id}
            id={id}
            message={inAppText}
            isNew={!inAppIsRead}
            date={format(new Date(inAppCreated), dateFormat)}
            link={viewObjectUrl}
            isLast={index === data.length - 1}
            handleItemClick={handleItemClick}
          />
        ))}
      </InfiniteScroll>
    </Box>
  );
};
