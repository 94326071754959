import { FC } from 'react';

import { FormControlLabel } from '@mui/material';

import { CommonRadioButton } from '../CommonRadioButton';
import { styles } from './styles';

interface ControllerItemProps {
  value: string;
  label: string;
  handleClose?: () => void;
}

export const ControllerItem: FC<ControllerItemProps> = ({ label, value, handleClose }) => {
  return (
    <FormControlLabel
      sx={{ ...styles.menuItemWrap }}
      value={value}
      control={<CommonRadioButton />}
      label={label}
      onChange={handleClose}
    />
  );
};
