import { FC, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { DocumentFolder, OrderDocument } from 'app/api/orders/types';
import { useModal } from 'hooks';
import { UserRole } from 'app/api/auth/types';
import { useFilesDropzone } from 'pages/OrderDetailsPage/hooks';
import { OrderDocumentCardMenu } from '../OrderDocumentCardMenu';
import { BuyerActions } from '../OrderDocumentCardActions/BuyerActions';
import { SellerActions } from '../OrderDocumentCardActions/SellerActions';
import { ViewDocumentsPopup } from '../ViewDocumentsPopup';
import { useOrderDocumentCard, useOrderDocumentsCardData, useFileUpload } from './hooks';
import { UploadDocumentsPopup } from '../UploadDocumentsPopup';
import { OnDragPlaceholder } from '../OnDragPlaceholder';
import { styles } from './styles';

interface OrderDocumentCardProps {
  isBuyerActionsAllowed: boolean;
  isSellerActionsAllowed: boolean;
  document: DocumentFolder;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  handleOpenDocumentView: (file: OrderDocument) => void;
}

export const OrderDocumentCard: FC<OrderDocumentCardProps> = ({
  isBuyerActionsAllowed,
  isSellerActionsAllowed,
  document,
  orderId,
  quoteId,
  isSampleRequest,
  handleOpenDocumentView,
}): JSX.Element => {
  const {
    open: isViewModalOpen,
    handleOpenModal: handleOpenViewModal,
    handleCloseModal: handleCloseViewModal,
  } = useModal();
  const {
    open: isUploadFilesPopupOpen,
    handleCloseModal: handleCloseUploadFilesPopup,
    handleOpenModal: handleOpenUploadFilesPopup,
  } = useModal();

  const folderId = Number(document.id);

  const { data, isLoading, folderData } = useOrderDocumentsCardData(folderId, isViewModalOpen);
  const { cardColor, isEmpty, isBuyer, isCompleted, subTitle } = useOrderDocumentCard(document);

  const {
    isLoading: isFileUploadLoading,
    fileError,
    isFileError,
    handleFileUpload,
    handleClearErrors,
  } = useFileUpload({
    kind: document.kind,
    folderId,
    orderId,
    quoteId,
    isSampleRequest,
    handleCloseUploadFilesPopup,
  });

  const isAllDropzoneDisabled = useMemo(
    () => isBuyer || isCompleted || !isSellerActionsAllowed || isFileUploadLoading,
    [isBuyer, isCompleted, isFileUploadLoading, isSellerActionsAllowed],
  );

  const { isDragActive, getDropzoneRootProps } = useFilesDropzone({
    disabled: isAllDropzoneDisabled || isViewModalOpen || isUploadFilesPopupOpen,
    isFileError,
    invalidateQueryKey: ['order-documents-folders'],
    onDrop: handleFileUpload,
    handleClearErrors,
  });

  const isEmptyBuyer = isEmpty && isBuyer;
  const isFolderMenuAllowed =
    (isBuyer && isBuyerActionsAllowed) ||
    (!isBuyer && isSellerActionsAllowed && document.ownerType === UserRole.SELLER);

  return (
    <Box {...getDropzoneRootProps()}>
      {isDragActive ? (
        <OnDragPlaceholder folderName={document.kind} />
      ) : (
        <Box sx={{ ...styles.cardWrap }}>
          <Box sx={{ ...styles.wrap(cardColor) }}>
            <Box sx={{ ...styles.titleWrap }}>
              <Box>
                <Typography sx={{ ...styles.title(isEmptyBuyer) }}>{document.kind}</Typography>
                <Typography sx={{ ...styles.subTitle(isEmptyBuyer) }}>{subTitle}</Typography>
              </Box>

              {isFolderMenuAllowed && (
                <OrderDocumentCardMenu
                  isEmptyBuyer={isEmptyBuyer}
                  documentName={document.kind}
                  documentId={document.id}
                />
              )}
            </Box>

            <Box sx={{ ...styles.actions }}>
              {isBuyer ? (
                <BuyerActions isEmptyBuyer={isEmptyBuyer} handleView={handleOpenViewModal} />
              ) : (
                <SellerActions
                  isEmpty={isEmpty}
                  isCompleted={isCompleted}
                  handleView={handleOpenViewModal}
                  isLoading={isFileUploadLoading}
                  handleClearError={handleClearErrors}
                  isSellerActionsAllowed={isSellerActionsAllowed}
                  handleOpenUploadFilesPopup={handleOpenUploadFilesPopup}
                />
              )}
            </Box>

            {isViewModalOpen && (
              <ViewDocumentsPopup
                isBuyer={isBuyer}
                name={document.kind}
                isLoading={isLoading || isFileUploadLoading}
                files={data}
                isOpen={isViewModalOpen}
                folderId={folderId}
                orderId={orderId}
                quoteId={quoteId}
                isSampleRequest={isSampleRequest}
                completeDate={folderData?.updatedAt || document.updatedAt}
                status={folderData?.status || document.status}
                isBuyerActionsAllowed={isBuyerActionsAllowed}
                isSellerActionsAllowed={isSellerActionsAllowed}
                isDropzoneDisabled={isAllDropzoneDisabled}
                isFileError={isFileError}
                handleClose={handleCloseViewModal}
                handleOpenDocumentView={handleOpenDocumentView}
                handleFileUpload={handleFileUpload}
                handleClearErrors={handleClearErrors}
              />
            )}

            {isUploadFilesPopupOpen && (
              <UploadDocumentsPopup
                isOpen={isUploadFilesPopupOpen}
                isDropzoneDisabled={isAllDropzoneDisabled}
                isFileError={isFileError}
                handleClose={handleCloseUploadFilesPopup}
                handleClearErrors={handleClearErrors}
                handleFileUpload={handleFileUpload}
              />
            )}
          </Box>

          {!!fileError && <Box sx={{ ...styles.errorBox }}>{fileError}</Box>}
        </Box>
      )}
    </Box>
  );
};
