import { FC } from 'react';

import { Control, UseFormGetValues, UseFormReset } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import {
  AdditionalFiltersControlNames,
  INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY_LABEL,
  MATURITY_UNIT,
} from '../../../../constants';
import { useSliderFilterPopup } from '../../hooks';
import { CommonFiltersDrawer } from '../CommonFiltersDrawer';
import { FilterListItem } from '../FilterListItem';
import { SliderInner } from '../SliderInner';

interface MaturityFilterProps {
  control: Control<ProductsFiltersForm>;
  isMaturityRange: boolean;
  maxMaturityLimit: number;
  minMaturityLimit: number;
  maturityStep: number;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  handleChangeMaturityCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeMaturitySlider: (newValue: number[]) => void;
  reset: UseFormReset<ProductsFiltersForm>;
}

export const MaturityFilter: FC<MaturityFilterProps> = ({
  control,
  isMaturityRange,
  maxMaturityLimit,
  minMaturityLimit,
  maturityStep,
  getValues,
  handleChangeMaturityCheckBox,
  handleChangeMaturitySlider,
  reset,
}) => {
  const defaultTitle = 'Maturity';
  const controlName = AdditionalFiltersControlNames.MATURITY;
  const checkBoxControlName = AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY;

  const { isFilterChanged, rangeLabel, filterTitle, open, handleCloseModal, handleOpenModal, handleConfirm } =
    useSliderFilterPopup({
      defaultTitle,
      getValues,
      reset,
      checkBoxControlName,
      control,
      controlName,
      max: maxMaturityLimit,
      min: minMaturityLimit,
      unit: MATURITY_UNIT,
      handleChangeCheckBox: handleChangeMaturityCheckBox,
    });

  return (
    <>
      <FilterListItem filterValue={rangeLabel} label={filterTitle} onClick={handleOpenModal} />
      <CommonFiltersDrawer
        disableConfirmButton={!isFilterChanged}
        isOpen={open}
        title={defaultTitle}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
      >
        <SliderInner
          control={control}
          controlName={controlName}
          defaultTitle={defaultTitle}
          isRange={isMaturityRange}
          max={maxMaturityLimit}
          min={minMaturityLimit}
          step={maturityStep}
          unit={MATURITY_UNIT}
          handleChangeSlider={handleChangeMaturitySlider}
          handleChangeCheckBox={handleChangeMaturityCheckBox}
          checkBoxControlName={checkBoxControlName}
          checkboxLabel={INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY_LABEL}
        />
      </CommonFiltersDrawer>
    </>
  );
};
