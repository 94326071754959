import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  listWrap: (isOrderPage: boolean, minHeight?: string) => ({
    maxHeight: isOrderPage ? 'calc(100% - 296px)' : '330px',
    minHeight,
    overflowX: 'hidden',
    overflowY: 'auto',
    ...scrollMixin,
  }),
};
