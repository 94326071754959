import { ReactElement } from 'react';

import { Box, Typography } from '@mui/material';
import { useDeviceType } from 'hooks';

import { customTheme } from 'theme/MuiThemeProvider/theme';
import { LandingPageVariation } from 'pages/BuyerHomePage/hooks/useBuyerHomePageHandlers';

import { styles } from './styles';

const messages = {
  main: 'A simpler way to source ',
  seedGrain: 'seed & grain',
  wholesale: 'Wholesale Seed',
  financing: 'Apply for financing today',
};

export const Pill = ({ text, color }: { text: string; color: string }): ReactElement => {
  return (
    <Box component="span" sx={styles.pill(color)}>
      {text}
    </Box>
  );
};

export const ControlBanner = (): ReactElement => {
  return (
    <>
      <Typography component="h1" sx={[styles.title, styles.control]}>
        {messages.main}
        <Typography component="span" sx={styles.seedGrain}>
          {messages.seedGrain}
        </Typography>
      </Typography>

      <Typography sx={[styles.description, styles.controlDescription]}>
        Effortlessly <Pill text="source" color={customTheme.palette.primary.main} />{' '}
        <Pill text="quote" color={customTheme.palette.secondary.main} />
        {' and '} <Pill text="procure" color="#FF9737" /> from leading companies across the globe.
      </Typography>
    </>
  );
};

export const V1Banner = ({ country }: { country: { name: string; adj: string } | null }): ReactElement => {
  const countryName = country?.name;
  const { isMobileSize } = useDeviceType();
  const component = isMobileSize ? 'span' : 'h1';
  return (
    <>
      <Typography component="h1" sx={[styles.title]}>
        {messages.main}
        <Typography component={component} sx={styles.subTitle}>
          <Typography component="span" sx={styles.seedGrain}>
            {messages.seedGrain}
          </Typography>
          {countryName && (
            <Typography component="span" sx={styles.forCountry}>
              {` for ${country?.name}`}
            </Typography>
          )}
        </Typography>
      </Typography>
      <Typography sx={styles.description}>
        Effortlessly{' '}
        <Box component="span" sx={styles.primary}>
          source
        </Box>{' '}
        <Box component="span" sx={styles.secondary}>
          quote
        </Box>
        {' and '}{' '}
        <Box component="span" sx={styles.orange}>
          procure
        </Box>{' '}
        from leading companies across the globe.
      </Typography>
    </>
  );
};

export const V2Banner = ({ country }: { country: { name: string; adj: string } }): ReactElement => {
  return (
    <>
      <Typography component="h1" sx={[styles.title, styles.titleSpaceAbove]}>
        {messages.wholesale}
        <Typography component="span" sx={[styles.forCountry, styles.v2]}>
          {` for ${country.name}`}
        </Typography>
      </Typography>
      <Typography sx={[styles.description, styles.v2description]}>
        Effortlessly{' '}
        <Box component="span" sx={styles.primary}>
          source
        </Box>{' '}
        <Box component="span" sx={styles.secondary}>
          quote
        </Box>
        {' and '}{' '}
        <Box component="span" sx={styles.orange}>
          procure
        </Box>{' '}
        from top suppliers.
      </Typography>
    </>
  );
};

export const V3Banner = ({ country }: { country: { name: string; adj: string } }): ReactElement => {
  const { isMobileSize } = useDeviceType();
  return (
    <>
      <Typography component="h1" sx={[styles.title, styles.titleSpaceAbove]}>
        {messages.wholesale}
        <Typography component="span" sx={[styles.forCountry, styles.v2]}>
          {` for ${country.adj} Importers`}
        </Typography>
      </Typography>
      {isMobileSize ? (
        <Typography sx={[styles.description, styles.financing]}>{messages.financing}</Typography>
      ) : (
        <Typography sx={[styles.description, styles.v2description]}>
          Effortlessly{' '}
          <Box component="span" sx={styles.primary}>
            source
          </Box>{' '}
          <Box component="span" sx={styles.secondary}>
            quote
          </Box>
          {' and '}{' '}
          <Box component="span" sx={styles.orange}>
            procure
          </Box>{' '}
          from top suppliers.
        </Typography>
      )}
    </>
  );
};

// Wholesale Seed for Tanzania
export type HomePageBannerProps = {
  country: { name: string; adj: string } | undefined;
  variation: LandingPageVariation;
};
export const HomePageBanner = ({ country, variation }: HomePageBannerProps): ReactElement => {
  return (
    <Box sx={styles.container}>
      {(variation === 'control' || !country) && <ControlBanner />}
      {variation === 'v1' && !!country && <V1Banner country={country} />}
      {variation === 'v2' && !!country && country && <V2Banner country={country} />}
      {variation === 'v3' && !!country && <V3Banner country={country} />}
    </Box>
  );
};
