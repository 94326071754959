import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  notFoundContainer: {
    display: 'flex',
    gap: '24px',
    svg: {
      height: '100px',
      width: '100px',
      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        height: '88px',
        width: '88px',
      },
    },
  },
  description: (isClose: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    ...(isClose && { justifyContent: 'center' }),
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      justifyContent: 'space-around',
    },
  }),
  title: {
    margin: '0px',
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '28px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0px',
      fontSize: '24px',
    },
  },
  text: {
    margin: '8px 0px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
    },
  },
  bottomSection: {
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      alignItems: 'center',
    },

    maxWidth: '680px',
    width: '100%',
    gap: '4px',
  },

  cantFind: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#868585',
  },
  requestProductBtn: {
    width: 'fit-content',
    marginBottom: '16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },

    backgroundColor: '#c67edf',
    ':hover': {
      backgroundColor: '#c67edf !important',
    },
    ':active': {
      backgroundColor: '#c67edf !important',
    },
  },
};
