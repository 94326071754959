import { FC } from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { snakeCase, startCase } from 'lodash';

import { ShipmentStatus } from 'app/api/orders';
import { EMPTY_CELL, shipmentStatusLabel } from '../../constants';
import { getShipmentDetailsDate } from '../../utils';
import { EVENTS_TABLE_COLUMNS } from './config';
import { styles } from './styles';

interface TrackEventsTableProps {
  data: ShipmentStatus[];
}

export const TrackEventsTable: FC<TrackEventsTableProps> = ({ data }) => {
  return (
    <TableContainer component={Paper} sx={styles.container}>
      <Table>
        <TableHead sx={styles.head}>
          <TableRow>
            {EVENTS_TABLE_COLUMNS.map((title) => (
              <TableCell key={title} align="left" sx={styles.headCell}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((row) => (
            <TableRow key={row.status} sx={styles.row}>
              <TableCell align="left" sx={styles.cell}>
                {shipmentStatusLabel?.[row.status] ?? startCase(snakeCase(row.status))}
              </TableCell>
              <TableCell align="left" sx={styles.cell}>
                {row.statusLocation ?? EMPTY_CELL}
              </TableCell>
              <TableCell align="left" sx={styles.cell}>
                {getShipmentDetailsDate(row.statusDate) ?? EMPTY_CELL}
              </TableCell>
              <TableCell align="left" sx={styles.cell}>
                {row.statusVessel ?? EMPTY_CELL}
              </TableCell>
              <TableCell align="left" sx={styles.cell}>
                {row.statusRemarks}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
