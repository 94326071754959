import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  container: {
    margin: '16px 0px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '36px',
    },
  },
};
