import { Currency } from 'utils/currencyFormat';
import { UserRole } from '../auth/types';
import { CompanyDetail } from '../company/types';
import { ProductNested, QuoteProductStatus, QuoteRequestStatus, QuoteTotalQuantity, UnitsType } from '../quotes/types';

export interface OrderPaymentInfo {
  companyName: string;
  bankName: string;
  bankAddress: string;
  companyRegistrationAddress: string;
  accountNumber: string;
  swiftBic: string;
  routingCode: string;
}

export interface OrderCrop {
  id: number;
  name?: string;
  variety?: string;
  quantity: number;
  price: number;
}

export enum OrderStatus {
  AWAITING_FREIGHT_QUOTE = 'AWAITING_FREIGHT_QUOTE',
  DOCUMENTS_IN_PROGRESS = 'DOCUMENTS_IN_PROGRESS',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
  READY_TO_SHIP = 'READY_TO_SHIP',
  EN_ROUTE = 'EN_ROUTE',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
}

export interface OrderQuoteProduct {
  id: number;
  amount: number;
  amountType: UnitsType;
  acceptedPrice?: number | null;
  acceptedPriceUnits?: Currency | null;
  product: ProductNested;
  status?: QuoteProductStatus;
}

export interface QuoteOrder {
  id: number;
  totalAmount?: string;
  totalPrice?: string;
  quoteproducts: OrderQuoteProduct[];
  buyer: number;
  sellerCompany: CompanyDetail;
  buyerCompany: CompanyDetail;
  highlight: boolean;
  unitsType: UnitsType;
  isSampleType: boolean;
  chatId: string;
  status: QuoteRequestStatus;
  totalQuantity: QuoteTotalQuantity;
  waitAnswer: UserRole;
}

export interface Order extends ShipmentInfo {
  id: number;
  number?: string;
  status: OrderStatus;
  quote: QuoteOrder;
  estimatedDelivery?: string | null;
  sentAt?: string | null;
  deliveredAt?: string | null;
  createdAt?: string;
  orderShipmentId?: number;
}

export type OrdersListData = Order[];

export interface OrdersListRequest {
  status: string[];
  requestSample?: boolean;
  buyerCompanyId?: number;
  sellerCompanyId?: number;
}

export interface PatchOrder {
  status?: OrderStatus;
  number?: string;
  idealDelivery?: string;
  estimatedDelivery?: string;
  sentAt?: string;
  deliveredAt?: string;
}

export enum ShipmentType {
  PALLETS = 'PALLETS',
  BOXES = 'BOXES',
  CONTAINERS = 'CONTAINERS',
}

export enum PalletType {
  CUSTOM = 'CUSTOM',
  GMA = `48'' x 40'' (GMA)`,
  EUR1 = '120 x 80 cm (EUR1)',
  EUR2 = '120 x 100 cm (EUR2)',
}

export interface ShipmentInfo {
  shipmentType: ShipmentType;
  freightPrice: number;
  palletType?: PalletType | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  weight?: number | null;
  numberOfUnits?: number | null;
  containerType?: string | null;
}

export interface CreateOrderRequest {
  quote: number;
  idealDelivery: [string, string];
}

export interface CreateOrderResponse {
  id: number;
  quote: number;
}

export enum DocumentFolderStatus {
  NO_DOCUMENT_UPLOADED = 'NO_DOCUMENT_UPLOADED',
  AT_LEAST_1_DOCUMENT_UPLOADED = 'AT_LEAST_1_DOCUMENT_UPLOADED',
  AT_LEAST_1_DOCUMENT_REJECTED = 'AT_LEAST_1_DOCUMENT_REJECTED',
  ALL_DOCUMENT_ACCEPTED = 'ALL_DOCUMENT_ACCEPTED',
}

export interface CreateDocumentFolderRequest {
  kind: string;
  order: number;
}

export interface DocumentFolder {
  id: number;
  kind: string;
  order: number;
  status: DocumentFolderStatus;
  updatedAt: string;
  ownerType: UserRole;
}

export interface DocumentFoldersRequest {
  order: number;
}

export enum OrderDocumentStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface OrderDocument {
  id: number;
  status: OrderDocumentStatus;
  firstClick: string | null;
  reason: string | null;
  document: string | null;
  folder: number;
}

export interface PatchOrderDocument {
  status?: OrderDocumentStatus;
  firstClick?: string | null;
  reason?: string | null;
}

export interface Counter {
  quotes: number;
  orders: number;
  enRoute: number;
  sampleRequestsQuotes: number;
}

export interface CreateOrderDocument {
  folder: number;
  document: File;
}

export enum OrderShipmentStatus {
  BOOKING_CONFIRMED = 'BOOKING CONFIRMED',
  LOADED_ON_A_TRANSPORT = 'LOADED ON A TRANSPORT',
  VESSEL_DEPARTED = 'VESSEL DEPARTED',
  VESSEL_ARRIVES = 'VESSEL ARRIVES',
}

export interface ShipmentStatus {
  status: OrderShipmentStatus;
  statusDate: string;
  statusLocation?: string | null;
  statusVessel: string | null;
  statusRemarks?: string | null;
}

export interface OrdersShipmentDetail {
  lastUpdate: string;
  iframeImg?: string | null;
  origin: string;
  destination: string;
  transportMode: string;
  departure: string;
  estimatedArrival: string;
  estimatedTimeToDestination: string;
  departurePort: string;
  receivingPort: string;
  vesselName?: string;
  containerNumber?: string;
  shipmentStatuses: ShipmentStatus[];
}
