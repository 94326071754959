import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    padding: '20px 36px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 700,
    color: customTheme.custom.black.B100,
  },
  subtitle: {
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
  },
};
