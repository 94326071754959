import { FC, ReactNode } from 'react';

import { Dialog } from '@mui/material';

import { styles } from './styles';

interface BackdropContainerProps {
  open: boolean;
  isPermanent: boolean;
  children: ReactNode;
  top: string;
  onClose: () => void;
}

export const BackdropContainer: FC<BackdropContainerProps> = ({ open, children, top, isPermanent, onClose }) => {
  return (
    <Dialog
      sx={{
        ...styles.dialog(top, isPermanent),
      }}
      BackdropProps={{
        sx: {
          ...styles.backDrop(top, isPermanent),
        },
      }}
      PaperProps={{
        sx: { ...styles.paper },
      }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
      transitionDuration={200}
    >
      {children}
    </Dialog>
  );
};
