import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  menuWrap: {
    '& .MuiPaper-root': {
      minWidth: 'unset',
      padding: 0,
      borderRadius: '3px',
      boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    },

    '& .MuiMenu-list': {
      padding: '2px 0',
    },

    '& .MuiFormControlLabel-root': {
      padding: '4px 10px 4px 4px',

      '& .MuiFormControlLabel-label': {
        marginLeft: '2px',
        fontSize: '12px',
      },
    },
  },
  menuButton: {
    minWidth: 'fit-content',
    fontSize: '10px',
    lineHeight: '10px',
    height: '18px',
    width: '82px',
    fontWeight: 400,
    color: customTheme.custom.black.B70,
    backgroundColor: customTheme.custom.black.B5,
    padding: '2px 2px 2px 6px',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',

    '&.MuiButton-containedPrimary': {
      ':hover': {
        backgroundColor: customTheme.palette.primary.P10,
      },
    },
  },
  menuButtonOpened: {
    color: customTheme.palette.primary.P100,
    backgroundColor: customTheme.palette.primary.P10,
  },

  menuButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    color: customTheme.custom.black.B100,

    '& svg': {
      width: '14px',
    },
  },
  menuButtonIconopened: {
    color: customTheme.palette.primary.P100,
  },
};
