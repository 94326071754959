import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';
import { styles } from './styles';

interface PageLoaderProps {
  size?: number;
}

export const PageLoader: FC<PageLoaderProps> = ({ size = 96 }): JSX.Element => (
  <Box sx={{ ...styles.wrap }}>
    <CircularProgress color="primary" size={size} />
  </Box>
);
