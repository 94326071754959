import { IconButton, Menu } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { ReactComponent as MoreIcon } from '../../../../assets/icons/more.svg';
import { CardMenuItem } from './CardMenuItem';
import { useModal } from '../../../../hooks/useModal';
import { RemoveFolderPopup } from '../RemoveFolderPopup';

import { styles } from './styles';
import { useOrderDocumentCardMenu } from './hooks/useOrderDocumentCardMenu';

interface OrderDocumentCardMenuProps {
  isEmptyBuyer: boolean;
  documentName: string;
  documentId: number;
}

export const OrderDocumentCardMenu: FC<OrderDocumentCardMenuProps> = ({
  isEmptyBuyer,
  documentName,
  documentId,
}): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();

  const { isLoading, handleRemove } = useOrderDocumentCardMenu(documentId, handleCloseModal);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton disableRipple sx={{ ...styles.moreButton(isEmptyBuyer) }} onClick={handleClick}>
        <MoreIcon />
      </IconButton>

      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <CardMenuItem
          text="Remove"
          menuAction={() => {
            handleClose();
            handleOpenModal();
          }}
        />
      </Menu>

      {isModalOpen && (
        <RemoveFolderPopup
          documentName={documentName}
          handleClose={handleCloseModal}
          isOpen={isModalOpen}
          isLoading={isLoading}
          handleRemove={handleRemove}
        />
      )}
    </>
  );
};
