import { Box, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { CommonModal } from '../../../../components/CommonPopup';
import { CommonButton } from '../../../../components/CommonButton';
import { LoadingButton } from '../../../../components/LoaderButton';
import { useMarkFolderAsIncompleteMutation } from '../../hooks';
import { styles } from './styles';

interface UndoCompleteAllPopupProps {
  folderId: number;
  handleClose: () => void;
  isOpen: boolean;
  setDocumentAsUpdated: () => void;
}

export const UndoCompleteAllPopup: FC<UndoCompleteAllPopupProps> = ({
  folderId,
  handleClose,
  isOpen = false,
  setDocumentAsUpdated,
}) => {
  const { handleMarkFolderIncomplete, isMarkFolderIncompleteLoading } = useMarkFolderAsIncompleteMutation({
    id: folderId,
    setDocumentAsUpdated,
    onSuccessAction: handleClose,
  });

  const onConfirm = useCallback(() => {
    handleMarkFolderIncomplete();
  }, [handleMarkFolderIncomplete]);

  return (
    <CommonModal title="Cancel status Completed?" isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        Are you sure you want to review all documents and redefine their status?
      </Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton
          disabled={isMarkFolderIncompleteLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.closeButton }}
          onClick={handleClose}
        >
          Cancel
        </CommonButton>
        <LoadingButton
          loading={isMarkFolderIncompleteLoading}
          onClick={onConfirm}
          variant="text"
          color="inherit"
          sx={{ ...styles.confirmButton }}
        >
          Confirm
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
