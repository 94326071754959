import { useCallback, useMemo, useState } from 'react';

import { useWatch, Control } from 'react-hook-form';

import { OrganicType } from 'app/api/products';
import { producttFilterAllValue } from '../../../constants';

export const useOrganicFilter = (control: Control, controlName: string) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const currentValue = useWatch({
    control,
    name: controlName,
  });

  const filterTitle = useMemo(() => {
    return currentValue === OrganicType.organicSeed ? 'Organic Only' : 'Organic';
  }, [currentValue]);

  const isSelected = currentValue !== producttFilterAllValue;

  return {
    anchorEl,
    currentValue,
    open,
    filterTitle,
    isSelected,
    handleClick,
    handleClose,
  };
};
