import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ShipmentStatus } from 'app/api/orders';
import { EventCard } from '../EventCard';
import { styles } from './styles';

interface TrackEventsListProps {
  data: ShipmentStatus[];
}

export const TrackEventsList: FC<TrackEventsListProps> = ({ data }) => {
  return (
    <Box sx={styles.wrap}>
      <Typography sx={styles.title}>Events</Typography>
      <Box sx={styles.inner}>
        {data.map((event) => (
          <EventCard key={event.status} {...event} />
        ))}
      </Box>
    </Box>
  );
};
