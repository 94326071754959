import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    height: '56px',
    minHeight: '56px',
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '16px',
    background: customTheme.custom.black.B10,
    borderBottom: `1px solid ${customTheme.custom.darkGrey}`,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingLeft: '14px',
      paddingRight: '14px',
      gap: '4px',
    },
  },
  infoWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '8px',
    },
  },
  logo: {
    height: '28px',
    maxHeight: '28px',
    width: 'auto',
    borderRadius: '6px',
    objectFit: 'contain',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: 'auto',
      maxWidth: '40px',
      maxHeight: '36px',
    },
  },
  name: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 600,
      display: '-webkit-box',
      WebkitLineClamp: '3',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  searchButton: {
    display: 'flex',

    ':hover': {
      color: customTheme.custom.black.B50,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '4px',
    },
  },
  backButton: {
    display: 'none',

    ':hover': {
      color: customTheme.custom.black.B50,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      padding: '4px',
      marginLeft: '-4px',
    },
  },
  infoButton: {
    display: 'none',

    ':hover': {
      color: customTheme.custom.black.B50,
    },

    '& path': {
      fill: customTheme.custom.black.B70,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      padding: '4px',
      marginRight: '-4px',
    },
  },
  actionsWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
};
