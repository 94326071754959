import { SxPropsTypes } from '../../theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  wrap: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
