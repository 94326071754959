import { FC, useCallback, useState } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { Control } from 'react-hook-form';
import { ShipmentMethod, ShipmentMethodLabels } from 'app/api/company';
import { Quote, QuoteRequestStatus, UnitsType } from 'app/api/quotes/types';
import { ROUTES } from 'app/routes/constants';
import { UserRole } from 'app/api/auth';
import { CommonButton, RadioGroupItem } from 'components';
import { useAuth, useModal } from 'hooks';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { SelectInput } from 'components/SelectInput';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';

import { SendQuoteRequestPopup } from '../SendQuoteRequestPopup';
import { QuoteTable } from '../SellerInitialTable/QuoteTable';
import { CreateOrderWrapper } from '../CreateOrderWrapper';
import { PriceHistoryPopup } from '../PriceHistoryPopup';
import { useQuoteRequestDetailsForm } from './hooks';
import { styles } from './styles';
import { CancelPopup } from '../CancelPopup';
import { ReviseQuotePopup } from '../ReviseQuotePopup';

export interface QuoteRequestDetailsProps {
  quoteData: Quote | undefined;
  quoteId: number;
  subTitle: string;
  isChatOpen: boolean;
  closeDetails: () => void;
}

const incotermOrder = [
  ShipmentMethod.exw,
  ShipmentMethod.fob,
  ShipmentMethod.cif,
  ShipmentMethod.cpt,
  ShipmentMethod.ddu,
  ShipmentMethod.ddp,
  ShipmentMethod.other,
];
export const incotermOptions = incotermOrder.map((k) => ({
  label: ShipmentMethodLabels[k],
  value: k,
})) as RadioGroupItem[];

export const QuoteRequestDetailsForm: FC<QuoteRequestDetailsProps> = ({
  quoteData,
  quoteId,
  subTitle,
  isChatOpen,
  closeDetails,
}) => {
  const navigate = useNavigate();
  const { isBuyer } = useAuth();
  const {
    open: isSendQuoteModalOpen,
    handleOpenModal: handleOpenSendQuoteModal,
    handleCloseModal: handleCloseSendQuoteModal,
  } = useModal();

  const {
    open: isPriceHistoryModalOpen,
    handleOpenModal: handleOpenPriceHistoryModal,
    handleCloseModal: handleClosePriceHistoryModal,
  } = useModal();

  const {
    open: isCancelModalOpen,
    handleOpenModal: handleOpenCancelModal,
    handleCloseModal: handleCloseCancelModal,
  } = useModal();

  const {
    open: isReviseModalOpen,
    handleOpenModal: handleOpenReviseModal,
    handleCloseModal: handleCloseReviseModal,
  } = useModal();

  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const isSampleRequest = !!quoteData?.isSampleType;

  const {
    watch,
    control,
    setValue,
    onSubmit,
    handleSubmit,
    handleCancel,
    isAllProductsNotAvailable,
    isAllConfirmedOrNotAvailable,
    buyerCanAccept,
    isAllReadyToBeConfirmedOrNotAvailable,
  } = useQuoteRequestDetailsForm({
    data: quoteData?.quoteproducts || [],
    quoteId,
    quoteData,
    handleOpenSendQuoteModal,
    handleClose: closeDetails,
    waitAnswer: quoteData?.waitAnswer,
    isSampleRequest,
  });

  const addProducts = useCallback(() => {
    const path = generatePath(isSampleRequest ? ROUTES.buyer.sampleRequestAdd : ROUTES.buyer.quoteRequestAdd, {
      companyDisplayId: quoteData?.seller.displayId,
      quoteId,
    });

    navigate(path);
    handleTrackLinkClickEvent({ text: 'Add Product', path, type: AnalyticsLinkType.BUTTON });
  }, [isSampleRequest, quoteData?.seller.displayId, quoteId, navigate, handleTrackLinkClickEvent]);

  const isCanceledQuote = quoteData?.status === QuoteRequestStatus.CANCELED;
  const hasCounterOffer =
    quoteData?.quoteproducts.some((product) =>
      isBuyer ? !!product.latestSellerPrice?.price : !!product.latestBuyerPrice?.price,
    ) ?? false;

  // waitAnswer === BUYER means that the buyer is waiting for the seller to make an offer
  const isSellerWithOffer =
    quoteData?.waitAnswer === UserRole.BUYER ||
    (quoteData?.quoteproducts.some((qp) => !qp.latestSellerPrice?.price) ?? false);

  const [isEditMode, setIsEditMode] = useState(isBuyer ? false : isSellerWithOffer);

  // const buyerAcceptQuote = !isAllReadyToBeConfirmedOrNotAvailable && isBuyer && !isEditMode;
  if (!quoteData) {
    return null;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ ...styles.subTitle }}>{subTitle}</Typography>
      <QuoteTable
        data={quoteData.quoteproducts || []}
        watch={watch}
        control={control}
        isBuyer={isBuyer}
        isEditMode={isEditMode}
        quoteStatus={quoteData.status}
        isChatOpen={isChatOpen}
        setValue={setValue}
      />
      <Box sx={{ ...styles.incotermsContainer }}>
        <Typography component="span" sx={{ ...styles.incotermLabel }}>
          Incoterms
        </Typography>
        {isBuyer ? (
          <Typography component="span" sx={{ ...styles.incotermBuyerVal }}>
            {quoteData?.incoterms ?? ShipmentMethod.exw}
          </Typography>
        ) : (
          <SelectInput data={incotermOptions} control={control as unknown as Control} controlName="incoterms" />
        )}
      </Box>
      <Box sx={{ ...styles.actionsWrap }}>
        <Box>
          <Box
            component="span"
            onClick={isCanceledQuote ? undefined : handleOpenCancelModal}
            sx={{ ...styles.cancelButton, ...(isCanceledQuote ? styles.cancelButtonDisabled : {}) }}
          >
            {isBuyer ? 'Cancel request' : 'Decline Request'}
          </Box>
          <CommonButton
            disabled={!hasCounterOffer}
            variant="text"
            color="primary"
            disableFocusRipple
            sx={{ ...styles.button, ...styles.priceHistory(!hasCounterOffer) }}
            onClick={handleOpenPriceHistoryModal}
          >
            <ClockIcon />
            View Full Price History
          </CommonButton>
        </Box>

        <Box sx={{ ...styles.mainActions }}>
          {isBuyer && (
            <CommonButton
              disabled={isCanceledQuote}
              variant="outlined"
              color="primary"
              sx={{ ...styles.button }}
              onClick={addProducts}
            >
              Add product
            </CommonButton>
          )}
          {isBuyer && hasCounterOffer && (
            <CommonButton
              disabled={isCanceledQuote}
              variant="outlined"
              color="primary"
              sx={{ ...styles.button }}
              onClick={() => setIsEditMode((prev) => !prev)}
            >
              {!isEditMode ? 'Counter Offer' : 'Undo'}
            </CommonButton>
          )}
          {!isBuyer && !isSellerWithOffer && !isEditMode && (
            <CommonButton
              disabled={isCanceledQuote}
              variant="outlined"
              color="primary"
              sx={{ ...styles.button }}
              onClick={handleOpenReviseModal}
            >
              Revise Quote
            </CommonButton>
          )}

          {!isBuyer && !isSellerWithOffer && isEditMode && (
            <CommonButton
              disabled={isCanceledQuote}
              variant="outlined"
              color="primary"
              sx={{ ...styles.button }}
              onClick={() => setIsEditMode((prev) => !prev)}
            >
              Undo
            </CommonButton>
          )}

          {((buyerCanAccept && !isEditMode) || isAllConfirmedOrNotAvailable) && isBuyer && (
            <CreateOrderWrapper
              data={quoteData?.quoteproducts || []}
              unitsType={quoteData?.unitsType ?? UnitsType.KG}
              control={control}
              quoteId={quoteId}
              isDisabled={isCanceledQuote || isAllProductsNotAvailable}
              seller={quoteData?.seller}
              currentCycle={quoteData?.dealInteractionsCounter ?? 0}
              isSampleRequest={!!quoteData?.isSampleType}
            />
          )}

          {isEditMode && (!isAllConfirmedOrNotAvailable || !isBuyer) && (
            <CommonButton
              color="primary"
              type="submit"
              sx={{ ...styles.button }}
              disabled={isCanceledQuote || !isAllReadyToBeConfirmedOrNotAvailable}
            >
              Send
            </CommonButton>
          )}
        </Box>
      </Box>
      {isSendQuoteModalOpen && (
        <SendQuoteRequestPopup
          title="Send Quote Request?"
          handleClose={handleCloseSendQuoteModal}
          closeDetails={closeDetails}
          isOpen={isSendQuoteModalOpen}
          data={quoteData?.quoteproducts || []}
          unitsType={quoteData?.unitsType ?? UnitsType.KG}
          quoteId={quoteId}
          control={control}
          seller={quoteData?.seller}
          currentCycle={quoteData?.dealInteractionsCounter ?? 0}
          isSampleRequest={!!quoteData?.isSampleType}
        />
      )}
      {isPriceHistoryModalOpen && quoteData && (
        <PriceHistoryPopup
          handleClose={handleClosePriceHistoryModal}
          isOpen={isPriceHistoryModalOpen}
          quoteId={quoteId}
          quote={quoteData}
          isBuyer={isBuyer}
        />
      )}
      {isReviseModalOpen && (
        <ReviseQuotePopup
          handleClose={handleCloseReviseModal}
          isOpen={isReviseModalOpen}
          onConfirm={() => setIsEditMode((prev) => !prev)}
        />
      )}
      {isCancelModalOpen && (
        <CancelPopup
          handleCancel={handleCancel}
          handleClose={handleCloseCancelModal}
          isOpen={isCancelModalOpen}
          isBuyer={isBuyer}
          sellerCompanyName={quoteData?.seller.name || ''}
          buyerCompanyName={quoteData?.buyer.name || ''}
        />
      )}
    </form>
  );
};
