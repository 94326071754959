interface UserNameData {
  firstName: string;
  lastName: string;
}

export const getInitials = (name: string): string => {
  const splitted = name.split(' ').filter((str) => !!str);
  const result = `${splitted[0]?.[0] || ''}${splitted[1]?.[0] || ''}`;

  return result.toUpperCase();
};

export const getFullName = ({ firstName, lastName }: UserNameData) => {
  return `${firstName} ${lastName}`;
};
