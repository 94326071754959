import { useMemo } from 'react';

import { generatePath, matchRoutes, useLocation, useSearchParams } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { useDeviceType } from 'hooks';

const CHAT_DETAILS_ROUTES = [{ path: ROUTES.common.messages }];

export const useChatDetailsRouteHandlers = () => {
  const { isMobileSize } = useDeviceType();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const chatIdParam = searchParams.get('id') ?? '';

  const matchMessagesRoutes = matchRoutes(CHAT_DETAILS_ROUTES, pathname);

  const shouldNavigateChatDetail = useMemo(
    () => isMobileSize && !!matchMessagesRoutes?.length && !!chatIdParam,
    [isMobileSize, matchMessagesRoutes?.length, chatIdParam],
  );

  const mobileChatDetailPath = generatePath(ROUTES.common.chat, { id: chatIdParam });

  return {
    mobileChatDetailPath,
    shouldNavigateChatDetail,
  };
};
