import zIndex from '@mui/material/styles/zIndex';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '24px',
  },

  control: {
    width: '100%',
    borderRadius: '25px',
    // overflow: 'hidden',
    '& .iti': {
      width: '100%',
    },
    '& .iti__selected-country-primary': {
      // overflow: 'hidden',
      borderRadius: '25px 0px 0px 25px',
    },
    '& .iti--container': {
      zIndex: zIndex.tooltip,
    },
    '& input': {
      border: `1px solid ${customTheme.custom.black.B10}`,
      borderRadius: '25px',
      fontSize: '14px',
      color: customTheme.custom.black.B100,
      height: '40px',
      width: '100%',
      padding: '5px 16px 4px',

      '&::placeholder': {
        color: customTheme.custom.black.B50,
        opacity: 1,
      },

      ':hover': {
        border: `1px solid ${customTheme.custom.black.B10}`,
        backgroundColor: customTheme.custom.black.B5,

        [`@media (max-width: ${breakpoints.tablet}px)`]: {
          backgroundColor: customTheme.custom.white,
        },
      },
    },
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1px',
  },
  errorText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.custom.error,
  },
  errorIcon: {
    display: 'flex',
    width: '16px',
    height: '16px',
  },
};
