import { useCallback, useEffect } from 'react';

import { useDropzone } from 'react-dropzone';

interface UseFilesDropzoneProps {
  disabled: boolean;
  isFileError: boolean;
  invalidateQueryKey: string[];
  onDrop: (files: File[], queryKey: string[]) => void;
  handleClearErrors: () => void;
  onSelectedFileError?: () => void;
  onDragEnter?: () => void;
}

export const useFilesDropzone = ({
  disabled,
  isFileError,
  invalidateQueryKey,
  onDrop,
  handleClearErrors,
  onSelectedFileError,
  onDragEnter,
}: UseFilesDropzoneProps) => {
  const handleDrop = useCallback(
    (files: File[]) => {
      onDrop(files, invalidateQueryKey);
    },
    [invalidateQueryKey, onDrop],
  );

  const { getRootProps: getDropzoneRootProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    onDragOver: onDragEnter,
    disabled,
    multiple: true,
    noClick: true,
  });

  useEffect(() => {
    if (isFileError && onSelectedFileError) onSelectedFileError();
  }, [isFileError, onSelectedFileError]);

  useEffect(() => {
    if (isDragActive && isFileError) handleClearErrors();
  }, [handleClearErrors, isDragActive, isFileError]);

  return {
    isDragActive,
    getDropzoneRootProps,
  };
};
