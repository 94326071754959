import { FC } from 'react';

import { Box, CircularProgress, Divider } from '@mui/material';
import { useChannelContext } from '@sendbird/uikit-react/Channel/context';
import { FileMessage } from '@sendbird/chat/lib/__definition';

import { FilesList } from '../FilesList';
import { styles } from './styles';

export const FilesListSection: FC = () => {
  const channelContext = useChannelContext();

  const attachments = (channelContext.allMessages || []) as FileMessage[];

  return (
    <Box sx={styles.wrap}>
      <Divider />
      {channelContext.loading ? (
        <Box sx={styles.loaderWrap}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <FilesList attachments={attachments} />
      )}
    </Box>
  );
};
