import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wrap: (isPermanent: boolean) => ({
    height: 'calc(100%)',
    padding: '16px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.white,
    overflowY: 'auto',
    ...scrollMixin,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '28px 36px',
    },
  }),
  navContainer: (rows: number | null, columns: number) => ({
    display: 'grid',
    ...(rows && {
      gridTemplateRows: `repeat(${rows}, auto)`,
    }),
    gridAutoColumns: 'minmax(144px, 1fr)',
    gridAutoFlow: 'column',
    gap: '4px 8px',
    overflowX: 'hidden',

    ...(!rows && {
      gridTemplateColumns: 'repeat(auto-fill, minmax(144px, 1fr))',
      gridAutoColumns: 'unset',
      gridAutoFlow: 'unset',
      gridTemplateRows: 'auto',
      minWidth: `calc(144px * ${columns} + 2 * 24px)`,
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridTemplateRows: '1fr',
      gridAutoFlow: 'row',
      gap: '12px',
    },
  }),
  navItem: (isActive: boolean) => ({
    minWidth: '120px',
    cursor: isActive ? 'default' : 'pointer',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: isActive ? customTheme.custom.black.B100 : customTheme.custom.black.B70,

    padding: '4px 8px',
    borderRadius: '4px',
    transition: 'all 0.3s ease-out',
    ':hover': {
      backgroundColor: customTheme.custom.black.B5,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '24px',
      padding: 0,
      display: 'block',
    },
  }),
};
