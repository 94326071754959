import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isCurrentUser: boolean) => ({
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '70%',
      display: 'grid',
      justifyItems: isCurrentUser ? 'end' : 'start',

      '.sendbird-file-message-item-body__file-name__text': {
        lineBreak: 'anywhere',
      },
    },
  }),
  bodyWrap: (isPrimary: boolean, isHighlighted: boolean) => ({
    '& .sendbird-file-message-item-body.incoming': {
      display: 'flex',
      alignItems: 'baseline',
      padding: '8px 10px 8px 8px',
      borderRadius: '10px',
      backgroundColor: isHighlighted ? customTheme.custom.sweetCorn : customTheme.custom.black.B5,

      '&:hover': {
        backgroundColor: customTheme.custom.black.B5,

        '& .sendbird-textbutton, & span': {
          color: isPrimary
            ? `${customTheme.palette.primary.P100} !important`
            : `${customTheme.palette.secondary.S100} !important`,
        },

        '& path': {
          fill: isPrimary
            ? `${customTheme.palette.primary.P100} !important`
            : `${customTheme.palette.secondary.S100} !important`,
        },
      },
    },

    '& .sendbird-textbutton, & span': {
      display: 'block',
      color: `${customTheme.custom.black.B100} !important`,
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '12px',
      letterSpacing: '0.2px',
    },

    '& .sendbird-textbutton': {
      textDecoration: 'none',
    },

    '& span': {
      textDecoration: 'underline',
      textDecorationThickness: '0.2px',
    },

    '& .sendbird-file-message-item-body .sendbird-file-message-item-body__file-name ': {
      top: '-4px',
    },

    '& path': {
      fill: `${customTheme.custom.black.B50} !important`,
    },

    '& .sendbird-file-message-item-body__file-icon': {
      width: 'fit-content !important',
      minWidth: 'unset !important',
      height: 'fit-content !important',
      minHeight: 'unset !important',
      backgroundColor: 'unset !important',
      marginRight: '4px',
    },

    '& .sendbird-file-message-item-body__file-icon__icon': {
      width: '16px !important',
      minWidth: '12px !important',
      height: '16px !important',
      minHeight: '12px !important',
    },

    '& .sendbird-thumbnail-message-item-body': {
      height: '120px',
      minWidth: 'unset',
      borderRadius: '10px',
      backgroundColor: 'unset',

      '&:hover': {
        backgroundColor: 'transparent',
        cursor: 'default',
      },

      '& .sendbird-thumbnail-message-item-body__image-cover': {
        display: 'unset',
      },
    },

    '& .sendbird-image-renderer': {
      height: '120px !important',
      minWidth: 'unset !important',
    },

    '& .sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__thumbnail': {
      position: 'relative !important',
      display: 'block !important',
    },

    '& .sendbird-image-renderer__image, & .sendbird-thumbnail-message-item-body__image-cover': {
      display: 'none !important',
    },

    '& .sendbird-image-renderer__hidden-image-loader': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      maxHeight: '100%',
      borderRadius: '10px',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      '& .sendbird-file-message-item-body.incoming': {
        '&:hover': {
          backgroundColor: isHighlighted ? customTheme.custom.sweetCorn : customTheme.custom.black.B5,

          '& .sendbird-textbutton, & span': {
            color: `${customTheme.custom.black.B100} !important`,
          },

          '& path': {
            fill: `${customTheme.custom.black.B50} !important`,
          },
        },

        '&:active': {
          backgroundColor: customTheme.custom.black.B5,

          '& .sendbird-textbutton, & span': {
            color: isPrimary
              ? `${customTheme.palette.primary.P100} !important`
              : `${customTheme.palette.secondary.S100} !important`,
          },

          '& path': {
            fill: isPrimary
              ? `${customTheme.palette.primary.P100} !important`
              : `${customTheme.palette.secondary.S100} !important`,
          },
        },
      },
    },
  }),
};
