import { Box, IconButton } from '@mui/material';
import { Control, FieldArrayWithId, UseFieldArrayRemove, UseFieldArrayUpdate, UseFormWatch } from 'react-hook-form';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { ReactComponent as CloseIcon } from 'assets/icons/closeSmall.svg';

import { useDeviceType } from 'hooks';
import { NumberFormField } from 'components/NumberFormField';
import { TextFormField } from 'components/TextFormField';
import { SelectInput } from 'components/SelectInput';
import { UnitsMenu } from 'components/UnitsMenu';

import { RequestProductsForm } from './form';
import { styles } from './productRowStyles';

const geneticOptions = ['Conventional', 'Hybrid', 'Open Pollinated', 'Non-GMO', 'Organic', 'Other'].map((v) => ({
  label: v,
  value: v,
}));

const seedType = [
  'Seed for planting',
  'Seed for human consumption',
  'Seed for animal feed',
  'Grain for human consumption',
  'Grain for animal feed',
  'Other',
].map((v) => ({
  label: v,
  value: v,
}));

type ProductRowProps = {
  index: number;
  product: FieldArrayWithId<RequestProductsForm, 'products', 'listId'>;
  control: Control<RequestProductsForm>;
  watch: UseFormWatch<RequestProductsForm>;
  remove: UseFieldArrayRemove;
  updateProducts: UseFieldArrayUpdate<RequestProductsForm, 'products'>;
  withRemoveButton: boolean;
  sx?: SxPropsTypes;
};
export const ProductRow = ({
  index,
  product,
  control,
  remove,
  watch,
  updateProducts,
  withRemoveButton,
  sx = {},
}: ProductRowProps) => {
  const { isMobileSize } = useDeviceType();
  const watchProduct = watch(`products.${index}`);
  return (
    <Box key={product.listId} sx={[styles.productContainer, sx]}>
      <Box sx={styles.topRow}>
        <Box sx={styles.productNameContainer}>
          <TextFormField
            control={control}
            wrapStyles={styles.productItem as SxPropsTypes}
            name={`products.${index}.name`}
            placeholder="Product Name"
            autoComplete="on"
          />
          {isMobileSize && withRemoveButton && (
            <IconButton onClick={() => remove(index)}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>

        <Box sx={styles.productInputContainer}>
          <NumberFormField
            wrapStyles={styles.numberWraper as SxPropsTypes}
            inputWrapStyles={styles.quantityAvailableInputWrap as SxPropsTypes}
            inputStyles={styles.quantityAvailableInput as SxPropsTypes}
            placeholder="Order Quantity"
            decimalScale={2}
            name={`products.${index}.quantity`}
            control={control}
          />
          <UnitsMenu
            sxContainer={styles.unitsMenu}
            units={product.units}
            onChange={(value) => updateProducts(index, { ...watchProduct, units: value })}
          />
          {!isMobileSize && withRemoveButton && (
            <IconButton onClick={() => remove(index)}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box sx={styles.selectContainer}>
        <SelectInput
          data={geneticOptions}
          control={control as unknown as Control}
          controlName={`products.${index}.genetics`}
          sx={{ ...styles.rowItem, ...styles.selectInput } as SxPropsTypes}
          placeHolder="Type"
        />
        <SelectInput
          data={seedType}
          control={control as unknown as Control}
          controlName={`products.${index}.productUse`}
          sx={{ ...styles.rowItem, ...styles.selectInput } as SxPropsTypes}
          placeHolder="Product Use"
        />
      </Box>
    </Box>
  );
};
