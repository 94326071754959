import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageWrap: {
    background: customTheme.custom.white,
    height: '100%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingTop: '98px',
    },
  },
  mobileHeaderWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
    },
  },
  headerWrap: {
    display: 'block',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  mobileLogo: {
    marginBottom: '-44px',

    svg: {
      width: '176px',
      height: 'auto',
    },
  },
  innerWrap: {
    display: 'grid',
    justifyItems: 'center',
    paddingTop: '136px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingTop: '84px',
    },
  },
  image: {
    height: '422px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '180px',
    },
  },
  contentWrap: {
    marginTop: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '40px',
    },
  },
  title: {
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: 500,
    color: customTheme.palette.primary.P100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
    },
  },
  description: {
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 400,
    color: customTheme.palette.primary.P100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
    },
  },
};
