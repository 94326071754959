import { Product } from '../app/api/products/types';
import { capitalizeFirstLetter } from './formatText';

export const getVarietyValue = ({ crop, variety, name }: Pick<Product, 'crop' | 'variety' | 'name'>): string => {
  if (variety) return variety;

  if (name) return '';

  return capitalizeFirstLetter(crop);
};
