import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  dropDownInput: {
    borderRadius: '25px',
    border: '1px solid #ced4da',
    fontSize: '14px',
    height: '40px',
    padding: '8px 26px 8px 12px',
    backgroundColor: customTheme.palette.primary.P10,
  },
  menuItem: {
    fontSize: '14px',
    padding: '4px 8px',
    minHeight: '32px',
  },
};
