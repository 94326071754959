import { FC, ReactNode } from 'react';

import { Box, Divider, Drawer, IconButton, SxProps, Typography } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrowLeftSmall.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { CommonButton } from 'components';
import { styles } from './styles';

interface CommonFiltersDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  title: string;
  closeButtonType?: 'arrow' | 'cross';
  disableConfirmButton?: boolean;
  isClearButton?: boolean;
  drawerSx?: SxProps;
  paperSx?: SxProps;
  handleClose: () => void;
  handleClear?: () => void;
  handleConfirm?: () => void;
}

export const CommonFiltersDrawer: FC<CommonFiltersDrawerProps> = ({
  children,
  isOpen,
  title,
  closeButtonType = 'arrow',
  disableConfirmButton = false,
  isClearButton = false,
  drawerSx,
  paperSx,
  handleClose,
  handleClear,
  handleConfirm,
}) => {
  const container = window !== undefined ? () => window.document.body : undefined;
  const isArrowButton = closeButtonType === 'arrow';

  return (
    <Drawer
      anchor="left"
      container={container}
      variant="temporary"
      open={isOpen}
      onClose={handleClose}
      sx={{ ...styles.drawer, ...drawerSx } as SxProps}
      ModalProps={{ sx: styles.modal }}
      PaperProps={{ sx: { ...styles.paper, ...paperSx } as SxProps }}
      disableEnforceFocus
    >
      <Divider />
      <Box sx={styles.inner}>
        <Box sx={styles.header(isClearButton)}>
          <IconButton sx={styles.closeButton} onClick={handleClose}>
            {isArrowButton ? <ArrowIcon /> : <CloseIcon />}
          </IconButton>
          <Typography sx={styles.title(isClearButton)}>{title}</Typography>
          {!!handleClear && (
            <CommonButton sx={styles.clearButton} onClick={handleClear}>
              Clear
            </CommonButton>
          )}
        </Box>
        <Box sx={styles.filtersWrap(!handleConfirm)}>{children}</Box>
        {!!handleConfirm && (
          <CommonButton sx={styles.confirmButton} disabled={disableConfirmButton} onClick={handleConfirm}>
            Confirm
          </CommonButton>
        )}
      </Box>
    </Drawer>
  );
};
