import { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import * as tracker from 'analytics/tracker';

import { AnalyticsName } from 'analytics';
import { OrderDocumentStatus } from 'app/api/orders/types';
import { useUpdateDocumentMutation } from '../../../hooks';

interface AddRejectReasonPopupI {
  id: number;
  kind: string;
  fileName: string;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  setDocumentAsUpdated: () => void;
  handleClose: () => void;
}

export const useAddRejectReasonPopup = ({
  id,
  kind,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  setDocumentAsUpdated,
  handleClose,
}: AddRejectReasonPopupI) => {
  const { control, watch, handleSubmit } = useForm<{ reason: string }>();

  const reason = watch('reason');

  const { mutate: handleRejectDocument, isLoading: isRejectDocumentLoading } = useUpdateDocumentMutation({
    id,
    folder: folderId,
    data: { status: OrderDocumentStatus.REJECTED, reason },
    setDocumentAsUpdated,
    onSuccessAction: () => {
      const analyticsOptions = {
        document_id: folderId,
        document_type: kind,
        order_id: orderId,
        quote_id: quoteId,
        accepted: false,
        is_sample_request: isSampleRequest,
      };

      tracker.track(AnalyticsName.DOCUMENT_REVIEWED, analyticsOptions);
      handleClose();
    },
  });

  const onSubmit = useCallback(() => {
    handleRejectDocument();
  }, [handleRejectDocument]);

  return {
    isRejectDocumentLoading,
    onSubmit,
    control,
    handleSubmit,
  };
};
