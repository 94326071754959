import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useProductsListPageGuard } from './hooks/useProductsListPageGuard';
import { ROUTES } from '../../../../app/routes/constants';

export const ProductsListPageGuard: FC = (): JSX.Element => {
  const { isAllowed } = useProductsListPageGuard();

  if (!isAllowed) {
    return <Navigate to={ROUTES.buyer._} />;
  }

  return <Outlet />;
};
