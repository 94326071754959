import { QuoteProduct, QuoteProductStatus } from 'app/api/quotes/types';
import { AdditionalQuoteProductFormProps } from '../types';

type QuoteFormProduct = QuoteProduct & AdditionalQuoteProductFormProps;

interface UseQuoteResponseTotalsProps {
  quotes: QuoteFormProduct[];
}

export const useQuoteResponseTotals = ({ quotes }: UseQuoteResponseTotalsProps) => {
  const totalAmount: number = quotes.reduce((acc: number, { amount, status }) => {
    const currentAmount = Number(status === QuoteProductStatus.AVAILABLE ? amount : 0);

    return acc + currentAmount;
  }, 0);

  const totalPrice =
    quotes.reduce((acc, val) => {
      const price = val?.acceptedPrice ? Number(val?.acceptedPrice) : Number(val?.price);
      const enteredPrice = Number(val.value);

      const singlePrice = price || enteredPrice;

      if (!Number.isNaN(singlePrice)) {
        const currentCount = val?.status === QuoteProductStatus.AVAILABLE ? val?.amount : 0;

        return acc + singlePrice * Number(currentCount);
      }

      return acc;
    }, 0) || 0;

  return { totalPrice, totalAmount };
};
