import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    borderRadius: '8px',
    boxShadow: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  head: {
    background: customTheme.custom.black.B10,
  },
  headCell: {
    width: '18%',
    padding: '22px 12px',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 700,
    color: customTheme.custom.black.B100,
    textTransform: 'uppercase',
    borderRight: `1px solid rgba(161, 161, 170, 0.3)`,

    ':last-of-type': {
      borderRight: 'none',
      width: 'auto',
    },
  },
  row: {
    background: customTheme.custom.black.B5,

    '&:last-child td, &:last-child th': {
      borderBottom: 0,
    },
  },
  cell: {
    padding: '22px 12px',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 500,
    color: customTheme.custom.black.B100,
    borderRight: `1px solid rgba(161, 161, 170, 0.3)`,

    ':last-of-type': {
      borderRight: 'none',
    },
  },
};
