import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'fixed',
    bottom: '24px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    zIndex: '5000',
    display: 'flex',
    width: '100%',
    maxWidth: '315px',
    borderRadius: '8px',
    margin: '0 auto',
    padding: '20px 24px 22px',
    backgroundColor: customTheme.custom.notificationBg,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: 'calc(100% - 28px)',
      boxSizing: 'border-box',
      padding: '12px 14px',
    },
  },
  wrapWarning: {
    border: `1px solid ${customTheme.custom.warning}`,
  },
  wrapError: {
    border: `1px solid ${customTheme.custom.error}`,
    backgroundColor: customTheme.custom.errorLight,
  },
  wrapSuccess: {
    border: `1px solid ${customTheme.custom.success}`,
  },
  wrapInfo: {
    border: `1px solid ${customTheme.custom.info}`,
  },
  textWrap: {
    flex: '1',
    margin: '0 16px',
    paddingTop: '3px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0 4px 0 12px',
    },
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  subTitle: {
    fontSize: '14px',
    lineHeight: '22px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  closeButton: {
    margin: '-4px -4px 0 0',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginRight: '-8px',
    },
  },
};
