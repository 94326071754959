import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paper: {
    maxWidth: '410px',
  },
  title: {},
  description: {
    marginBottom: '16px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -14px -8px 0',
  },
  amountField: {
    margin: 0,
  },
  amountFieldInput: {
    height: '21px',
  },
  closeButton: {
    minWidth: '80px',
    marginRight: '8px',
  },
  confirmButton: {
    minWidth: '90px',
  },
};
