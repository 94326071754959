import { FC } from 'react';

import { DashboardTabs } from 'app/api/quotes/types';
import { OrdersListData } from 'app/api/orders/types';
import { OrdersList } from '../OrdersList';
import { CanceledSection } from '../CanceledSection';
import { TabEmptyState } from '../TabEmptyState';

interface OrdersTabInnerProps {
  isBuyer: boolean;
  tab?: DashboardTabs;
  data: {
    list: OrdersListData;
    canceledList: OrdersListData;
  };
}

export const OrdersTabInner: FC<OrdersTabInnerProps> = ({ isBuyer, tab, data }) => {
  return (
    <>
      {data.list.length ? (
        <OrdersList data={data.list} tab={tab} isBuyer={isBuyer} isCanceledSection={false} />
      ) : (
        <TabEmptyState tab={tab} />
      )}
      {!!data.canceledList.length && (
        <CanceledSection>
          <OrdersList data={data.canceledList} tab={tab} isBuyer={isBuyer} isCanceledSection />
        </CanceledSection>
      )}
    </>
  );
};
