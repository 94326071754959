import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Box } from '@mui/material';

import { ConversationSearchForm } from 'app/api/SBChat';
import { ConversationSearchField } from '../ConversationSearchField';
import { useSBChannelListHeader } from './hooks';
import { styles } from './styles';

interface SBChannelListHeaderProps {
  control: Control<ConversationSearchForm>;
  clearSearchField: () => void;
}

export const SBChannelListHeader: FC<SBChannelListHeaderProps> = ({ control, clearSearchField }) => {
  const { anchorEl, open, handleClick, handleClose } = useSBChannelListHeader();

  return (
    <Box sx={styles.wrap}>
      <ConversationSearchField
        control={control}
        clearSearchField={clearSearchField}
        anchorEl={anchorEl}
        open={open}
        handleClick={handleClick}
        handleClose={handleClose}
      />
    </Box>
  );
};
