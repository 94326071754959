import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: '1',
  },
  text: {
    padding: '100px 0',
    fontSize: '14px',
    lineHeight: '21px',
    textAlign: 'center',
    color: customTheme.custom.black.B70,
  },
};
