import { Category } from 'app/api/company/types';

export const CategoryTags: Record<Category, string> = {
  [Category.breeder]: 'Breeder',
  [Category.producer]: 'Producer',
  [Category.exporter]: 'Exporter',
  [Category.importer]: 'Importer',
  [Category.packetAndGarden]: 'Packet & Garden',
  [Category.foodProduction]: 'Food Production Company',
  [Category.distributor]: 'Distributor',
  [Category.multiplier]: 'Multiplier',
  [Category.wholesaler]: 'Wholesaler',
  [Category.processor]: 'Processor',
  [Category.farmerCoop]: 'Farmer Co-op',
  [Category.seedBuyer]: 'Seed buyer',
  [Category.other]: 'Other',
};
