import { FC } from 'react';

import { Box, Divider } from '@mui/material';

import { CropFamily } from 'app/api/crops';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowLeftSmall.svg';
import { CommonButton, SubCategoriesNavList } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { CommonDrawer } from '../CommonDrawer';
import { styles } from './styles';

interface CropsDrawerProps {
  currentCategory: CropFamily | null;
  isOpen: boolean;
  subType: string | undefined;
  handleClose: () => void;
  handleNavigateBack: () => void;
}

export const CropsDrawer: FC<CropsDrawerProps> = ({
  currentCategory,
  isOpen,
  subType,
  handleClose,
  handleNavigateBack,
}) => {
  return (
    <CommonDrawer isOpen={isOpen} handleClose={handleClose} sx={styles.productsDrawer as SxPropsTypes}>
      <Box sx={styles.buttonWrap}>
        <CommonButton variant="text" sx={styles.backButton} onClick={handleNavigateBack}>
          <ArrowIcon /> Main Menu
        </CommonButton>{' '}
      </Box>
      <Divider sx={styles.divider} />
      <SubCategoriesNavList
        categoryName={currentCategory?.name ?? ''}
        data={currentCategory?.crops ?? []}
        activeItem={subType}
      />
    </CommonDrawer>
  );
};
