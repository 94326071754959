import { FC } from 'react';

import { Box, Dialog, IconButton, SxProps } from '@mui/material';
import DocViewer, { DocViewerRenderers, IDocument } from '@cyntler/react-doc-viewer';

import { OrderDocument } from 'app/api/orders/types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useDeviceType } from 'hooks';
import { PreviewHeader } from './PreviewHeader';
import { styles } from './styles';

interface DocumentsPreviewProps {
  file: Partial<OrderDocument>;
  isOpen: boolean;
  handleClose: () => void;
}

export const DocumentsPreview: FC<DocumentsPreviewProps> = ({ file, isOpen, handleClose }) => {
  const { isMobileSize } = useDeviceType();

  const fileName = file?.document?.split('/').at(-1) ?? '';
  const fileData: IDocument = { uri: file.document ?? '', fileName };

  return (
    <Dialog
      onClose={handleClose}
      open={isOpen}
      PaperProps={{
        sx: { ...(isMobileSize ? styles.paperMobile : styles.paper) },
      }}
    >
      <Box sx={styles.viewerWrap as SxProps}>
        <IconButton sx={{ ...styles.closeButton }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <DocViewer
          config={{
            header: {
              overrideComponent: PreviewHeader,
            },
            pdfZoom: {
              defaultZoom: isMobileSize ? 1.1 : 0.6,
              zoomJump: 0.2,
            },
            pdfVerticalScrollByDefault: true,
          }}
          className="viewer"
          documents={[fileData]}
          pluginRenderers={DocViewerRenderers}
        />
      </Box>
    </Dialog>
  );
};
