import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    overflow: 'hidden',
  },
  name: {
    display: 'block',
    fontSize: '11px',
    lineHeight: '14px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    flexGrow: 1,
    color: customTheme.custom.black.B70,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dateWrap: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    minWidth: 'fit-content',
  },
  date: {
    fontSize: '10px',
    lineHeight: '10px',
    color: customTheme.custom.black.B50,
    whiteSpace: 'nowrap',
  },
  day: {
    fontWeight: 700,
  },
  time: {
    fontWeight: 400,
  },
};
