import { FC, ReactNode } from 'react';

import { Drawer, DrawerProps } from '@mui/material';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { styles } from './styles';

interface CommonDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  anchor?: DrawerProps['anchor'];
  sx?: SxPropsTypes;
  handleClose: () => void;
}

export const CommonDrawer: FC<CommonDrawerProps> = ({ children, isOpen, anchor, sx, handleClose }) => {
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Drawer
      anchor={anchor}
      container={container}
      variant="temporary"
      open={isOpen}
      onClose={handleClose}
      sx={{ ...styles.drawer, ...sx }}
    >
      {children}
    </Drawer>
  );
};
