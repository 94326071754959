import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paper: {
    minWidth: '862px',
    maxWidth: '2000px',
    minHeight: '236px',
    background: 'transparent',
    overflow: 'visible',
    display: 'grid',
    gap: '24px',
  },
  wrap: {
    minWidth: '370px',
    maxWidth: '600px',
    padding: '14px 20px',
    borderRadius: '10px',
    background: customTheme.custom.white,
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '18px',
  },
  closeButton: {
    width: '24px',
    height: '24px',
    marginRight: '-2px',
    padding: '0',
  },
};
