import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  mainLoaderWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '381px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  title: {
    paddingLeft: '8px',
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
