import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import image from 'assets/images/maintenanceImg.png';
import { Header } from 'layout/Header';
import { MobileHeader } from 'layout/MobilePageLayout/components';
import { styles } from './styles';

export const MaintenancePage: FC = () => {
  return (
    <Box sx={styles.pageWrap}>
      <Box sx={styles.mobileHeaderWrap}>
        <MobileHeader isShadow isActionsEnabled={false} minHeight="98px" logoSx={styles.mobileLogo} />
      </Box>
      <Box sx={styles.headerWrap}>
        <Header isShadow isActionsEnabled={false} />
      </Box>
      <Box sx={styles.innerWrap}>
        <Box component="img" src={image} sx={styles.image} />
        <Box sx={styles.contentWrap}>
          <Typography sx={styles.title}>Sproutzo is down for Maintenance</Typography>
          <Typography sx={styles.description}>
            We{'\u2019'}ll be up again soon! Please check back in a few hours.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MaintenancePage;
