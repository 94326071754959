import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  pageWrap: {
    minHeight: 'calc(100vh - 240px)',
    display: 'flex',
    flexDirection: 'column',
  },
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
