import { FC, useEffect } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonButton, CommonModal, LoadingButton } from 'components';
import { useAuth } from 'hooks';
import { styles } from './styles';

interface LogOutModalProps {
  title: string;
  handleClose: () => void;
  isOpen: boolean;
}

export const LogOutModal: FC<LogOutModalProps> = ({ title, handleClose, isOpen = false }) => {
  const { logout, signOut } = useAuth();
  const { isLoading, isSuccess } = signOut;
  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess, handleClose]);

  return (
    <CommonModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>Are you sure you want to Log Out?</Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <LoadingButton
          loading={isLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.logoutButton }}
          onClick={logout}
        >
          Log Out
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
