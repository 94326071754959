import { FC } from 'react';
import { Box } from '@mui/material';
import { Control } from 'react-hook-form';

import { DocumentItem } from '../DocumentItem';
import { styles } from './styles';
import { AddMoreDocumentsWrapper } from '../AddMoreDocumentsWrapper';
import { CheckboxFilterItem } from '../../../../components/CheckboxFilter/types';

interface DocumentsListProps {
  control: Control;
  documents: CheckboxFilterItem[];
  isListPadding: boolean;
}

export const DocumentsList: FC<DocumentsListProps> = ({ control, documents, isListPadding }): JSX.Element => {
  return (
    <Box sx={styles.wrap(isListPadding)}>
      <Box sx={styles.documentsWrap} data-testid="list">
        {documents.map((item, index) => (
          <DocumentItem
            key={item.label}
            control={control as unknown as Control}
            controlName={`documents.${index}`}
            label={item.label}
            value={item.value}
          />
        ))}
      </Box>

      <AddMoreDocumentsWrapper control={control} />
    </Box>
  );
};
