import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  itemWrap: {
    display: 'grid',
  },
  label: {
    color: customTheme.palette.primary.P100,
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  value: {
    color: customTheme.custom.black.B100,
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 500,
  },
};
