import { FC } from 'react';

import { Box, Divider, Typography } from '@mui/material';

import { styles } from './styles';

interface ChannelListEmptyStateProps {
  isSearchByChannelName: boolean;
  isQueryString: boolean;
}

export const ChannelListEmptyState: FC<ChannelListEmptyStateProps> = ({ isSearchByChannelName, isQueryString }) => {
  const searchTypeTitle = isSearchByChannelName ? 'company name' : 'user name';
  const title = isQueryString ? 'Channel not found' : 'No chats yet';
  const subTitle = isQueryString
    ? `Please make sure the ${searchTypeTitle} is correct`
    : 'Future conversations will appear here ';

  return (
    <>
      <Box sx={styles.wrap}>
        <Typography sx={styles.title}>{title}</Typography>
        <Typography sx={styles.subtitle}>{subTitle}</Typography>
      </Box>
      <Divider />
    </>
  );
};
