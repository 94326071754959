import { FC } from 'react';

import { Typography } from '@mui/material';

import { QuoteCardStatusesType } from 'app/api/quotes/types';
import { getStatusTagStyles } from './utils';
import { quoteStatusLabel } from './constants';
import { styles } from './styles';

interface QuoteStatusTagProps {
  status: QuoteCardStatusesType;
}

export const QuoteStatusTag: FC<QuoteStatusTagProps> = ({ status }) => {
  return (
    <Typography sx={{ ...styles.wrap, ...getStatusTagStyles(status) }} component="span">
      {quoteStatusLabel[status]}
    </Typography>
  );
};
