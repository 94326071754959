import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isReversed: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    ...(isReversed && {
      flexDirection: 'row-reverse',
    }),
  }),
  name: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    color: customTheme.custom.black.B70,
  },
};
