import { useCallback, useEffect, useMemo } from 'react';

import { useMatch, useSearchParams } from 'react-router-dom';

import {
  DashboardMainTabs,
  QuoteRequestsListData,
  QUOTE_REQUEST_NOT_FOUND_ERROR,
  SAMPLE_REQUEST_NOT_FOUND_ERROR,
} from 'app/api/quotes';
import { ROUTES } from 'app/routes/constants';
import { useFetchSystemMessages } from 'hooks';
import { storage } from 'utils/storage';
import { notificationObserver } from 'utils/observer';
import { IS_UPDATE_WAIT_ANSWER_KEY, OPEN_CHAT_SEARCH_PARAM_KEY, QUOTE_ID_SEARCH_PARAM_KEY } from '../../../constants';

const clearWaitAnswerValue = () => {
  storage.deleteItem(IS_UPDATE_WAIT_ANSWER_KEY);
};

interface UseQuoteRequestsTabInnerProps {
  quotes: QuoteRequestsListData;
}

export const useQuoteRequestsTabInner = ({ quotes }: UseQuoteRequestsTabInnerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const quoteIdParam = searchParams.get(QUOTE_ID_SEARCH_PARAM_KEY) ?? '';
  const isQuoteExisting = useMemo(
    () => quotes.some(({ id }) => id.toString() === quoteIdParam),
    [quoteIdParam, quotes],
  );

  const handleRemoveQuoteIdParam = useCallback(() => {
    searchParams.delete(QUOTE_ID_SEARCH_PARAM_KEY);
    searchParams.delete(OPEN_CHAT_SEARCH_PARAM_KEY);
    setSearchParams(searchParams);
    clearWaitAnswerValue();
  }, [searchParams, setSearchParams]);

  const handleSetQuoteIdParam = useCallback(
    (id: string) => {
      searchParams.set(QUOTE_ID_SEARCH_PARAM_KEY, id);
      setSearchParams(searchParams);
      clearWaitAnswerValue();
    },
    [searchParams, setSearchParams],
  );

  const isBuyerSampleRequest = Boolean(useMatch(`${ROUTES.buyer.dashboard._}/${DashboardMainTabs.SAMPLE_REQUESTS}`));
  const isSellerSampleRequest = Boolean(useMatch(`${ROUTES.seller.dashboard._}/${DashboardMainTabs.SAMPLE_REQUESTS}`));
  const isSampleRequest = isBuyerSampleRequest || isSellerSampleRequest;

  useEffect(() => {
    if (!!quoteIdParam && !isQuoteExisting) {
      handleRemoveQuoteIdParam();
      const errorMessage = isSampleRequest ? SAMPLE_REQUEST_NOT_FOUND_ERROR : QUOTE_REQUEST_NOT_FOUND_ERROR;

      notificationObserver.publish({
        type: 'error',
        title: errorMessage,
      });
    }
  }, [handleRemoveQuoteIdParam, isQuoteExisting, isSampleRequest, quoteIdParam]);

  useFetchSystemMessages({
    queryKey: 'quote-system-messages',
    quoteId: Number(quoteIdParam),
    enabled: !!quoteIdParam,
  });

  return {
    quoteIdParam,
    handleRemoveQuoteIdParam,
    handleSetQuoteIdParam,
  };
};
