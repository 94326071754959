import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  cardWrap: {
    position: 'relative',
  },
  wrap: (cardColor: string) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '185px',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: cardColor,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '144px',
    },
  }),
  titleWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: (isBuyerEmpty: boolean) => ({
    marginBottom: '4px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    color: isBuyerEmpty ? customTheme.custom.black.B100 : customTheme.palette.primary.P5,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
  }),
  subTitle: (isBuyerEmpty: boolean) => ({
    fontSize: '10px',
    lineHeight: '12px',
    color: isBuyerEmpty ? customTheme.custom.black.B70 : customTheme.custom.black.B10,
  }),
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: '0 0 50%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '-12px',
    },
  },
  viewButton: {
    height: '32px',
    fontSize: '10px',
    lineHeight: '12px',
    borderColor: customTheme.custom.white,
    color: customTheme.custom.white,

    '&:hover': {
      borderColor: customTheme.custom.white,
      backgroundColor: 'transparent',
    },
  },
  viewBorderlessButton: {
    height: '32px',
    marginTop: '6px',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.white,
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  errorBox: {
    position: 'absolute',
    fontSize: '12px',
    lineHeight: '12px',
    color: customTheme.custom.error,
  },
};
