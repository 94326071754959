import { FC } from 'react';

import { Badge } from '@mui/material';

import { styles } from './styles';

interface TabLabelProps {
  label: string;
  isActive: boolean;
  count?: number;
}

export const TabLabel: FC<TabLabelProps> = ({ label, isActive, count = 0 }) => {
  return (
    <Badge
      badgeContent={count}
      color="primary"
      sx={{
        ...styles.badge(isActive),
      }}
    >
      {label}
    </Badge>
  );
};
