import { FC, ChangeEvent } from 'react';

import { Control, Controller, FieldValues, UseFormSetValue } from 'react-hook-form';
import { Box, Menu, Typography } from '@mui/material';

import { CommonCheckbox, CommonButton } from 'components';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { FilterItem } from './FilterItem';

import { useCheckboxFilter } from './hooks';
import { CheckboxFilterItem } from './types';
import { styles } from './styles';

interface CheckboxFilterProps {
  data: CheckboxFilterItem[];
  defaultFilter: CheckboxFilterItem;
  control: Control;
  controlName: string;
  defaultTitle: string;
  applyFilterControlName?: string;
  applyFilterLabel?: string;
  isApplyFilterCheckBox?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  handleApplyFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxFilter: FC<CheckboxFilterProps> = ({
  data,
  defaultFilter,
  control,
  controlName,
  defaultTitle,
  applyFilterControlName,
  applyFilterLabel,
  isApplyFilterCheckBox,
  setValue,
  handleApplyFilterChange,
}) => {
  const {
    anchorEl,
    open,
    filterTitle,
    isSelected,
    counterTitle,
    handleClick,
    handleClose,
    handleApplyFilterCheckboxChange,
    resetFilters,
  } = useCheckboxFilter(data, control, controlName, defaultFilter, defaultTitle, setValue, handleApplyFilterChange);

  return (
    <>
      <CommonButton
        sx={{
          ...styles.menuButton,
          ...(isSelected && styles.selected),
          ...(open && styles.menuButtonOpened),
        }}
        onClick={handleClick}
        disableRipple
      >
        {filterTitle}
        {counterTitle && <Box sx={{ ...styles.counter }}>{counterTitle}</Box>}
        <Box
          sx={{
            ...styles.menuButtonIcon,
            ...(open && styles.menuButtonIconopened),
          }}
        >
          <ArrowDownIcon />
        </Box>
      </CommonButton>
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {data.map((item, index) => (
          <FilterItem
            key={item.label}
            control={control}
            controlName={`${controlName}[${index}]`}
            label={item.label}
            value={item.value}
            resetFilters={resetFilters}
            handleClose={handleClose}
            defaultValue={defaultFilter.value}
          />
        ))}
        {isApplyFilterCheckBox && applyFilterControlName && (
          <Controller
            name={applyFilterControlName}
            control={control}
            render={({ field: props }) => (
              <Box sx={styles.applyFilterWrap}>
                <CommonCheckbox
                  {...props}
                  ref={null}
                  checked={!!props.value}
                  onChange={handleApplyFilterCheckboxChange}
                />
                <Typography component="span" sx={styles.applyFilterLabel}>
                  {applyFilterLabel}
                </Typography>
              </Box>
            )}
          />
        )}
      </Menu>
    </>
  );
};
