import { useMutation, useQueryClient } from 'react-query';

import { PatchInAppNotification, updateNotification } from 'app/api/notifications';
import { NOTIFICATIONS_COUNT_QUERY_KEY, NOTIFICATIONS_QUERY_KEY } from 'layout/Notifications/constants';

interface UpdateNotificationProps {
  id: number;
  data: PatchInAppNotification;
  link: string;
}

interface UseUpdateNotificationMutationProps {
  onSuccessAction?: () => void;
}

export const useUpdateNotificationMutation = ({ onSuccessAction }: UseUpdateNotificationMutationProps) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    ({ id, data }: UpdateNotificationProps) => updateNotification(id, { ...data }),
    {
      onSuccess: () => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_QUERY_KEY] });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_COUNT_QUERY_KEY] });
        onSuccessAction?.();
      },
    },
  );

  return {
    isLoading,
    mutate,
  };
};
