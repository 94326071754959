import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  wrap: {
    width: '180px',
  },
  text: {
    fontSize: '12px',
    color: customTheme.custom.black.B70,
  },
  list: {
    padding: '2px 0 0 7px',
  },
  listItem: {
    padding: '0',
    fontSize: '12px',
    color: customTheme.custom.black.B70,
  },
  listBullet: {
    width: '3px',
    height: '3px',
    marginRight: '6px',
    borderRadius: '50%',
    backgroundColor: customTheme.custom.black.B70,
  },
};
