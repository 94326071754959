import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  mainWrap: {
    width: '512px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      boxSizing: 'border-box',
      margin: 0,
    },
  },
  wrap: (minHeight: string) => ({
    width: '100%',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 496px)',
    minHeight,
    boxSizing: 'border-box',
    padding: '16px',
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    background: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxHeight: 'unset',
      minHeight: 'fit-content',
      boxShadow: 'none',
      padding: '14px',
    },
  }),
  title: {
    marginBottom: '16px',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '14px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
  },
  totalText: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '16px',
    },
  },
  totalTitle: {
    color: customTheme.custom.black.B100,
  },
  totalAmount: {
    color: customTheme.custom.info,
  },
  totalPrice: {
    color: customTheme.palette.secondary.S100,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '12px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      justifyContent: 'flex-end',
    },
  },
  cancelButton: {
    minWidth: '96px',
    padding: 0,
    marginLeft: '-8px',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B50,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0 -8px 0 0',
    },
  },
};
