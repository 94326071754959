import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { DashboardMainTabs, DashboardTabs, SampleRequestsTabs, UnitsType } from 'app/api/quotes/types';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { formatNumber } from 'utils/formatText';
import { QuoteCardProps } from './index';
import { QuoteDates } from './QuoteDates';
import { styles } from './styles';

interface QuoteTotalProps extends Pick<QuoteCardProps, 'totalQuantity'> {
  tab: DashboardTabs | undefined;
  createdAt?: string | null;
  estimatedDeliveryDate?: string | null;
  deliveredAt?: string | null;
  orderNumber?: string;
}

export const QuoteTotal: FC<QuoteTotalProps> = ({
  totalQuantity = [],
  tab,
  createdAt = '',
  estimatedDeliveryDate = '',
  deliveredAt = '',
  orderNumber = '',
}) => {
  const isQuoteRequest = tab === DashboardMainTabs.QUOTE_REQUESTS || tab === SampleRequestsTabs.SAMPLE_REQUESTS;
  return (
    <Box sx={styles.totalWrap}>
      <Box sx={styles.totalInnerWrap}>
        <Box>
          <Typography sx={{ ...styles.totalTitle }}>Total Amount {isQuoteRequest && 'Requested'}</Typography>
          <Box sx={styles.amountWrap}>
            {totalQuantity.map(({ unit, quantity }, index) => (
              <Box sx={styles.amount} key={unit}>
                <Typography component="span" sx={{ ...styles.totalAmount }}>
                  {formatNumber(quantity)}
                </Typography>
                <Typography component="span" sx={{ ...styles.totalUnits(unit === UnitsType.THOUSAND_SEEDS) }}>
                  {getUnitDisplayValue(unit, true)}
                </Typography>
                {index !== totalQuantity.length - 1 && <Box sx={{ ...styles.plus }}>+</Box>}
              </Box>
            ))}
          </Box>
        </Box>

        <Box sx={styles.mobileDatesWrap}>
          <QuoteDates
            createdAt={createdAt}
            deliveredAt={deliveredAt}
            estimatedDeliveryDate={estimatedDeliveryDate}
            tab={tab}
            orderNumber={orderNumber}
          />
        </Box>
      </Box>
    </Box>
  );
};
