import {
  INVALID_ATTACHMENT_FORMAT_ERROR,
  INVALID_ATTACHMENT_NAME_LENGTH_ERROR,
  INVALID_ATTACHMENT_SIZE_ERROR,
} from '../validation';

export const getValidationErrorMessage = (
  isMaxSizeError: boolean,
  isMaxNameLengthError: boolean,
  isInvalidFormatError: boolean,
) => {
  if (isMaxSizeError) {
    return INVALID_ATTACHMENT_SIZE_ERROR;
  }

  if (isMaxNameLengthError) {
    return INVALID_ATTACHMENT_NAME_LENGTH_ERROR;
  }

  if (isInvalidFormatError) {
    return INVALID_ATTACHMENT_FORMAT_ERROR;
  }

  return '';
};
