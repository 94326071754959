import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageWrap: {
    padding: '0 76px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 0,
      marginTop: '-14px',
    },
  },
  headerContainer: {
    marginBottom: '48px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '24px',
    },
  },
  title: {
    color: customTheme.palette.primary.main,
    fontSize: '24px',
    fontWeight: '600',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '24px',
    },
  },
  sub: {
    color: customTheme.custom.black.B70,
    fontSize: '60px',
    fontWeight: '600',
    lineHeight: 1,
    marginBottom: '8px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '48px',
    },
  },
  description: {
    color: customTheme.custom.black.B50,
    fontSize: '20px',
    fontWeight: 400,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
    },
  },
  email: {
    color: customTheme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 500,
  },
  // Body section
  bodyContainer: {
    backgroundColor: customTheme.custom.white,
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px 0px',
    marginBottom: '32px',
    boxShadow: customTheme.shadow.boxShadow.standard,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '32px 0px',
      flexDirection: 'column',
    },
  },
  bodySection: (withRightBorder: boolean) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '64px 24px',
    textAlign: 'center',
    ...(withRightBorder && {
      borderRight: `1px solid ${customTheme.custom.black.B10}`,
    }),
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '32px 16px',
      ...(withRightBorder && {
        borderRight: 'none',
        borderBottom: `1px solid ${customTheme.custom.black.B10}`,
      }),
    },
  }),
  joinHeader: {
    fontSize: '36px',
    fontWeight: 600,
    color: customTheme.palette.secondary.main,
  },
  helpHeader: {
    fontSize: '36px',
    fontWeight: 600,
    color: customTheme.palette.primary.main,
  },
  bodyText: {
    color: customTheme.custom.black.B50,
    marginBottom: '16px',
  },
  joinBtn: {
    color: customTheme.custom.white,
  },
  // pageContentWrap: {
  //   display: 'flex',
  //   gap: '40px',
  //   margin: '40px 0 16px',

  //   [`@media (max-width: ${breakpoints.tablet}px)`]: {
  //     display: 'grid',
  //     gap: '16px',
  //     margin: '16px 0 0 0',
  //   },
  // },
  // column: {
  //   flex: '1',
  // },
  // sectionTitle: {
  //   marginBottom: '24px',
  //   fontSize: '20px',
  //   lineHeight: '28px',
  //   fontWeight: 600,
  //   color: customTheme.custom.black,

  //   [`@media (max-width: ${breakpoints.tablet}px)`]: {
  //     fontSize: '16px',
  //     lineHeight: '24px',
  //     marginBottom: '16px',
  //   },
  // },
};
