import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '60px',
    marginBottom: '24px',
    padding: '40px',
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '40px',
      padding: '12px 16px',
      display: 'grid',
      justifyItems: 'center',
      gap: '8px',
    },
  },
  title: {
    marginBottom: '12px',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '8px',
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  description: {
    maxWidth: '605px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '17px',
    },
  },
  imageWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      svg: {
        width: '80px',
        height: '80px',
      },
    },
  },
};
