import { FC, MouseEvent } from 'react';

import { Badge, IconButton } from '@mui/material';

import { ReactComponent as NotificationsIcon } from 'assets/icons/notifications.svg';
import { styles } from './styles';

interface NotificationsButtonProps {
  count: number;
  handleClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const NotificationsButton: FC<NotificationsButtonProps> = ({ count, handleClick }) => {
  return (
    <IconButton sx={{ ...styles.button }} onClick={handleClick}>
      <Badge
        badgeContent={count}
        color="primary"
        sx={{
          ...styles.badge,
        }}
      >
        <NotificationsIcon />
      </Badge>
    </IconButton>
  );
};
