import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  pageWrap: {
    display: 'flex',
    gap: '24px',
    paddingBottom: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: 0,
    },
  },
  mainInfo: {
    flex: 1,
  },
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  titleWrap: {
    marginBottom: '12px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '20px',
      gridRow: 6,
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  subTitle: {
    marginTop: '2px',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
  },
  loaderBox: {
    padding: '32px 0',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridRow: 7,
    },
  },
};
