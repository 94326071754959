import { FC } from 'react';

import { Box } from '@mui/material';

import { OceanFreightDataType, SummaryDataType, TimingDataType } from '../../types';
import { SummarySection } from '../SummarySection';
import { TimingSection } from '../TimingSection';
import { OceanFreightSection } from '../OceanFreightSection';
import { styles } from './styles';

interface TrackingInfoSectionProps {
  summary: SummaryDataType | null;
  timing: TimingDataType | null;
  oceanFreight: OceanFreightDataType | null;
}

export const TrackingInfoSection: FC<TrackingInfoSectionProps> = ({ summary, timing, oceanFreight }) => {
  return (
    <Box sx={styles.wrap}>
      {summary && <SummarySection {...summary} />}
      {timing && <TimingSection {...timing} />}
      {oceanFreight && <OceanFreightSection {...oceanFreight} />}
    </Box>
  );
};
