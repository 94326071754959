import { useCallback, useEffect, useState } from 'react';

import { sendBirdSelectors } from '@sendbird/uikit-react';
import { useSendbirdStateContext } from '@sendbird/uikit-react/SendbirdProvider';

const UPDATE_COUNTER_INTERVAL = 10 * 1000;

interface UseUnreadChannelMessagesCountProps {
  channelUrl: string;
}

export const useUnreadChannelMessagesCount = ({ channelUrl }: UseUnreadChannelMessagesCountProps) => {
  const [newMessagesCount, setNewMessagesCount] = useState(0);

  const state = useSendbirdStateContext();
  const getGroupChannel = sendBirdSelectors.getGetGroupChannel(state);

  const updateUnreadCount = useCallback(() => {
    getGroupChannel(channelUrl)
      .then((data) => {
        setNewMessagesCount(data?.unreadMessageCount ?? 0);
      })
      .catch(() => {
        /* empty */
      });
  }, [channelUrl, getGroupChannel]);

  useEffect(() => {
    const interval = setInterval(updateUnreadCount, UPDATE_COUNTER_INTERVAL);
    updateUnreadCount();

    return () => {
      clearInterval(interval);
    };
  }, [updateUnreadCount]);

  return {
    newMessagesCount,
    isNewMessages: !!newMessagesCount,
  };
};
