import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    padding: '24px 16px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    textAlign: 'center',
    color: customTheme.custom.black.B50,
  },
  name: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: customTheme.palette.primary.P70,
  },
};
