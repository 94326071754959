import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import { colorFilterAll, continentFilterAll, resistancesFilterAll } from '../constants';

interface GetDefaultFilterValuesParams {
  geneticTypeValue: string;
  colorValues: CheckboxFilterItem[] | undefined;
  continentValues: CheckboxFilterItem[] | undefined;
  resistancesValues: CheckboxFilterItem[] | undefined;
  sortValue: string;
  minWeight: number;
  maxWeight: number;
  minMaturity: number;
  maxMaturity: number;
  organicValue: string;
}

export const getDefaultFilterValues = ({
  geneticTypeValue,
  colorValues = [],
  continentValues = [],
  resistancesValues = [],
  sortValue,
  minWeight,
  maxWeight,
  minMaturity,
  maxMaturity,
  organicValue,
}: GetDefaultFilterValuesParams) => {
  const defaultColorTypeFilterVal = colorValues.map(({ value }) => (value !== colorFilterAll.value ? false : value));
  const defaultContinentFilterVal = continentValues.map(({ value }) =>
    value !== continentFilterAll.value ? false : value,
  );
  const defaultResistancesFilterVal = resistancesValues.map(({ value }) =>
    value !== resistancesFilterAll.value ? false : value,
  );

  return {
    geneticType: geneticTypeValue,
    color: defaultColorTypeFilterVal,
    continent: defaultContinentFilterVal,
    sort: sortValue,
    weight: [minWeight, maxWeight],
    includeProductsWithIncompleteWeight: true,
    maturity: [minMaturity, maxMaturity],
    includeProductsWithIncompleteMaturity: true,
    resistances: defaultResistancesFilterVal,
    includeProductsWithIncompleteResistances: true,
    organic: organicValue,
  };
};
