import { Member } from '@sendbird/chat/lib/__definition';

export const getSortedCurrentCompanyMembers = (currentCompanyMembers: Member[], sendbirdUserId: string) => {
  const currentUserMemberIndex = currentCompanyMembers.findIndex((user) => user.userId === sendbirdUserId);

  if (currentUserMemberIndex !== -1) {
    const currentUserMember = currentCompanyMembers.splice(currentUserMemberIndex, 1)?.[0];
    currentCompanyMembers.unshift(currentUserMember);
  }

  return currentCompanyMembers;
};
