import { FC, Dispatch, SetStateAction } from 'react';

import { Box } from '@mui/material';

import { CropFamiliesData } from 'app/api/crops/types';
import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { SideCategoriesBar } from 'components';
import { reorderCategories } from 'hooks/api/useCropFamiliesQuery';
import { CategoryNavItem } from './CategoryNavItem';
import { useTopCategoriesBar } from './hooks';
import { styles } from './styles';

interface TopCategoriesBarProps {
  visible: boolean;
  data: CropFamiliesData;
  handleScrollState: Dispatch<SetStateAction<boolean>>;
}

export const TopCategoriesBar: FC<TopCategoriesBarProps> = ({ data, visible, handleScrollState }) => {
  const { isAllItemsOpened, handleActionClick, handleMouseMove, handleCloseMenu } = useTopCategoriesBar({
    handleScrollState,
  });

  const displayCategories = reorderCategories(data);

  return (
    <>
      <Box sx={{ ...styles.wrap(visible || isAllItemsOpened) }}>
        <Box sx={{ ...styles.toolBar }}>
          <Box sx={{ ...styles.buttonWrap }} onMouseOut={isAllItemsOpened ? handleMouseMove : undefined}>
            <Box sx={{ ...styles.allItemsButton }} onClick={handleActionClick}>
              {isAllItemsOpened ? <CloseIcon /> : <MenuIcon />} All items
            </Box>
          </Box>

          {displayCategories.map((cropFamily) => (
            <CategoryNavItem key={cropFamily.id} isClosed={!visible} disableHover={isAllItemsOpened} {...cropFamily} />
          ))}
        </Box>
      </Box>
      {isAllItemsOpened && <SideCategoriesBar isOverlay top="132px" data={data} onClose={handleCloseMenu} />}
    </>
  );
};
