import { FC } from 'react';

import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

// import { PageLoader } from 'components';
import { useDeviceType } from 'hooks';
import { SideCategoriesPermanentBar } from 'layout/SideCategoriesPermanentBar';
import { usePageLayout } from 'layout/PageLayout/hooks';
import { TopCategoriesBar } from 'layout/TopCategoriesBar';

import { MobilePageLayout } from '../MobilePageLayout';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { styles } from './styles';

export const PagelayoutCorrected: FC = () => {
  const { isMobileSize } = useDeviceType();

  const {
    isSubHeader,
    isSubHeaderVisible,
    isContentWithMenu,
    onScroll,
    handleScrollState,
    cropFamiliesData,
    containerRef,
  } = usePageLayout();

  if (isMobileSize) {
    return <MobilePageLayout isLoading={false} />;
  }

  return (
    <Box ref={containerRef} onScroll={onScroll}>
      <Header isShadow />
      {isSubHeader && (
        <TopCategoriesBar data={cropFamiliesData} visible={isSubHeaderVisible} handleScrollState={handleScrollState} />
      )}
      <Box sx={{ ...styles.contentWrap }}>
        {isContentWithMenu && (
          <SideCategoriesPermanentBar
            scrollTop={containerRef.current?.scrollTop}
            data={cropFamiliesData}
            handleScrollState={handleScrollState}
          />
        )}
        <Box sx={{ ...styles.content }}>
          <Outlet />
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};
