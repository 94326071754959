import { FC, ReactElement } from 'react';

import { Tooltip, TooltipProps } from '@mui/material';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { styles } from './styles';

interface CommonTooltipProps extends TooltipProps {
  title: string;
  children: ReactElement;
  placement?: TooltipProps['placement'];
  sx?: TooltipProps['sx'];
}

export const CommonTooltip: FC<CommonTooltipProps> = ({
  title,
  children,
  sx,
  placement = 'top-start',
  ...otherProps
}): JSX.Element => {
  return (
    <Tooltip
      placement={placement}
      title={title}
      componentsProps={{
        tooltip: {
          sx: {
            ...styles.tooltip,
            ...(sx as SxPropsTypes),
          },
        },
        transition: {
          timeout: 0,
        },
      }}
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
};
