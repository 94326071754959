import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { DashboardTabs } from 'app/api/quotes/types';
import { getTabEmptyStateLabel } from '../../utils';
import { styles } from './styles';

interface TabEmptyStateProps {
  tab?: DashboardTabs;
}

export const TabEmptyState: FC<TabEmptyStateProps> = ({ tab }) => {
  const label = getTabEmptyStateLabel(tab);

  return (
    <Box sx={{ ...styles.wrap }}>
      <Typography sx={{ ...styles.text }}>{label} will appear here.</Typography>
    </Box>
  );
};
