import { FC } from 'react';
import { Box } from '@mui/material';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { CommonButton, CommonModal } from 'components';

import { styles } from './styles';

interface ConfirmModalProps {
  onConfirm: () => void;
  onClose: () => void;
  isOpen: boolean;
}
const messages = {
  confirm: 'Leave',
  cancel: 'Go Back',
  main: 'Leave without saving?',
};

export const ConfirmModal: FC<ConfirmModalProps> = ({ isOpen, onClose, onConfirm }) => {
  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={onClose}
      withCloseButton={false}
      disableEscapeKeyDown
      paperStyles={styles.paperStyles as SxPropsTypes}
    >
      <Box sx={styles.text}>{messages.main}</Box>
      <Box sx={styles.btns}>
        <CommonButton variant="outlined" color="info" onClick={onClose}>
          {messages.cancel}
        </CommonButton>
        <CommonButton variant="outlined" color="error" sx={styles.confirm} onClick={onConfirm}>
          {messages.confirm}
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
