import { FC, ReactNode } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as InfoIcon } from '../../../../assets/icons/info.svg';
import { styles } from './styles';

interface InfoMessageProps {
  children: ReactNode;
}

export const InfoMessage: FC<InfoMessageProps> = ({ children }) => {
  return (
    <Box sx={{ ...styles.wrap }}>
      <InfoIcon />
      <Typography sx={{ ...styles.message }}>{children}</Typography>
    </Box>
  );
};
