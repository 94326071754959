import { FC } from 'react';

import { Box } from '@mui/material';

import { PageLoader } from 'components';
import { QuoteRequestFormWrapper } from './components';
import { useQuoteRequestProducts } from './hooks';
import { styles } from './styles';

export const QuoteRequestPage: FC = (): JSX.Element => {
  const { data, company, isAddPage, isLoading, defaultPriceUnits, defaultUnit, isRequestSamplePage } =
    useQuoteRequestProducts();

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loadingWrap }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <QuoteRequestFormWrapper
      data={data || []}
      company={company}
      defaultUnit={defaultUnit}
      isAddPage={isAddPage}
      defaultPriceUnits={defaultPriceUnits}
      isRequestSamplePage={isRequestSamplePage}
    />
  );
};
