import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

interface UsePathnameChangeProps {
  handleAction: () => void;
}

export const usePathnameChange = ({ handleAction }: UsePathnameChangeProps) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname) {
      handleAction();
    }
  }, [handleAction, pathname]);
};
