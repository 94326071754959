import { ProductEdit } from 'app/api/products';

export const sections: {
  title: string;
  fields: (keyof ProductEdit)[];
}[] = [
  {
    title: 'Basic Information',
    fields: ['color', 'color_other', 'inner_flesh_color', 'shape', 'weight', 'length', 'width'],
  },
  {
    title: 'Growth Characteristics',
    fields: [
      'maturity',
      'plant_shape',
      'plant_height',
      'relative_plant_height',
      'determinate',
      'disease_pest_tolerance_specialties',
      'environmental_tolerance_specialties',
      'ideal_growing_temperature',
      'adaptive_climate',
      'tillers_per_plant',
    ],
  },
  {
    title: 'Fruit Characteristics',
    fields: [
      'surface',
      'sugar_content',
      'brix_sugar_content',
      'pungency',
      'firmness',
      'flowering_habit',
      'warts',
      'prickles',
      'fruits_per_plant',
      'fruits_per_cluster',
    ],
  },
  {
    title: 'Planting and Harvesting',
    fields: [
      'optimal_field_type',
      // 'intended_market', // what is this?
      'potential_yield',
      'seed_rate_per_acre',
      'planting_interval',
      'keeping_days',
      'approx_seeds_per_gram',
      'number_of_cuttings',
    ],
  },
  {
    title: 'Leafy Greens',
    fields: ['head_type'],
  },
  {
    title: 'Peppers',
    fields: ['number_of_lobes'],
  },
  {
    title: 'Rice',
    fields: ['milling_recovery', 'amylose_content', 'grain_length_to_width_ratio'],
  },
  {
    title: 'Okra',
    fields: ['number_of_ridges'],
  },
  {
    title: 'Cotton',
    fields: ['boll_size', 'boll_shape', 'g_tex', 'micronaire', 'sci', 'number_of_monopodia'],
  },
  {
    title: 'Peas and Beans',
    fields: [
      'thickness',
      'pods_per_plant',
      'pods_per_cluster',
      'seeds_per_pod',
      'shelling_percentage',
      'kernels_grains_per_row',
      'kernel_rows_per_ear',
      'first_ear_height',
      'average_grain_per_ear',
    ],
  },
];
