import { useQuery } from 'react-query';

import { getOrderDocumentFolders } from 'app/api/orders';

export const useDocumentsQuery = (order: number, key = 'order-documents-folders', enabled = true) => {
  const { isLoading, isFetching, data } = useQuery([key, order], () => getOrderDocumentFolders({ order }), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });

  return {
    isLoading,
    isFetching,
    data,
  };
};
