import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  drawer: {
    '& .MuiPaper-root': {
      paddingTop: '4px',
      boxShadow: 'none',
      height: '100%',
      width: '100%',
    },
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  titleContainer: {
    position: 'relative',
    margin: '64px 48px',
  },
  title: {
    color: customTheme.palette.info.main,
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    left: '-16px',
    top: '0px',
    bottom: '0px',
    margin: '0px',
    padding: '0px',
    minWidth: '32px',
    height: '32px',
    svg: {
      path: { fill: customTheme.palette.info.main },
    },
  },
  currentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
    marginBottom: '32px',
  },
  current: {
    backgroundColor: '#97EBC8',
    padding: '4px 8px',
    borderRadius: '4px',
  },
  currentMain: {
    color: '#358B67',
    fontSize: '16px',
    fontWeight: 500,
    marginRight: '2px',
  },
  currentSub: {
    color: '#878787',
    fontSize: '16px',
    fontWeight: 500,
  },
  change: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
  },
  labelText: {
    color: customTheme.palette.info.main,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
  },
  bottom: {
    position: 'fixed',
    backgroundColor: '#FFFFFF',
    bottom: 0,
    width: '100%',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  confirm: {
    margin: '32px',
  },
  optionsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '80px',
  },
  value: {
    boxSizing: 'border-box',
    width: '100%',
  },
  innerValue: {
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 24px',
    padding: '16px 0px',
    borderBottom: '1px solid #DBDBDB',
  },
  selected: {
    backgroundColor: '#97EBC8',
  },
  optionLabel: {
    color: customTheme.custom.black.B100,
    fontSize: '16px',
    fontWeight: 500,
  },
  optionSub: {
    color: customTheme.palette.info.main,
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '8px',
  },
  description: {
    margin: '16px 32px',
  },
};
