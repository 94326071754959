import { FC } from 'react';
import { Helmet } from 'react-helmet';

import { Box, Typography } from '@mui/material';

import { CommonButton } from 'components';
import { useAuth, useModal } from 'hooks';
import { useAppDispatch } from 'state/hooks';
import { setSignupModal } from 'state/slices/account';
import { siteMessages } from 'utils/siteMessages';

import { styles } from './styles';
import { GetHelpModal } from './components/GetHelpModal';

const headeMessages = {
  title: 'Contact us',
  sub: 'Get in touch',
  description:
    'We’d love to hear from you. Please reach out with any questions via the form to the right, or by emailing us at ',
  descriptionWithUser:
    'We’d love to hear from you. Please reach out with any questions via the form below, or by emailing us at ',
  email: 'hello@sproutzo.com',
};
const Header = ({ hasActiveUser }: { hasActiveUser: boolean }) => {
  return (
    <Box sx={styles.headerContainer}>
      <Typography component="h2" sx={styles.title}>
        {headeMessages.title}
      </Typography>
      <Typography component="h1" sx={styles.sub}>
        {headeMessages.sub}
      </Typography>
      <Typography component="p" sx={styles.description}>
        {hasActiveUser ? headeMessages.descriptionWithUser : headeMessages.description}
        <Box component="a" href={`mailto:${headeMessages.email}`} sx={styles.email}>
          {headeMessages.email}
        </Box>
      </Typography>
    </Box>
  );
};

const bodyMessages = {
  joinTitle: 'Ready to join?',
  joinBody: 'Request free access to the Sproutzo platform today.',
  joinCTA: 'Request Free Access',
  helpTitle: 'Get help',
  helpBody: 'Have a question or concern? Contact support.',
  helpCTA: 'Request Help',
};
const Body = ({ hasActiveUser }: { hasActiveUser: boolean }) => {
  const dispatch = useAppDispatch();

  const onClickRequestAccess = () =>
    dispatch(setSignupModal({ isOpen: true, state: 'requestAccess', source: 'contact' }));
  const { open, handleCloseModal, handleOpenModal } = useModal();
  return (
    <Box sx={styles.bodyContainer}>
      {!hasActiveUser && (
        <Box sx={styles.bodySection(true)}>
          <Typography component="h3" sx={styles.joinHeader}>
            {bodyMessages.joinTitle}
          </Typography>
          <Typography component="p" sx={styles.bodyText}>
            {bodyMessages.joinBody}
          </Typography>
          <CommonButton color="secondary" sx={styles.joinBtn} onClick={onClickRequestAccess}>
            {bodyMessages.joinCTA}
          </CommonButton>
        </Box>
      )}
      <Box sx={styles.bodySection(false)}>
        <Typography component="h3" sx={styles.helpHeader}>
          {bodyMessages.helpTitle}
        </Typography>
        <Typography component="p" sx={styles.bodyText}>
          {bodyMessages.helpBody}
        </Typography>
        <CommonButton color="primary" onClick={handleOpenModal}>
          {bodyMessages.helpCTA}
        </CommonButton>
        <GetHelpModal isOpen={open} handleClose={handleCloseModal} />
      </Box>
    </Box>
  );
};
export const ContactUsPage: FC = (): JSX.Element => {
  const { activeUser } = useAuth();
  const hasActiveUser = !!activeUser;
  return (
    <Box sx={{ ...styles.pageWrap }}>
      <Helmet>
        <title>Contact Us | {siteMessages.title}</title>
        <meta name="description" content={siteMessages.description} />
      </Helmet>
      <Header hasActiveUser={hasActiveUser} />
      <Body hasActiveUser={hasActiveUser} />
    </Box>
  );
};
