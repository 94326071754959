import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import { DocumentFolder, Order, OrderDocument, OrderPaymentInfo, OrderStatus } from 'app/api/orders';
import { Address } from 'app/api/company/types';
import { useFetchSystemMessages, useAuth, useModal } from 'hooks';
import { usePaymentInfoQuery } from './usePaymentInfoQuery';
import { useOrderQuery } from './useOrderQuery';
import { useDeliveryAddressQuery } from './useDeliveryAddressQuery';
import { useDocumentsQuery } from './useDocumentsQuery';

const STATUSES_TO_SHOW_PAYMENT_INFO = [
  OrderStatus.PAYMENT_IN_PROGRESS,
  OrderStatus.READY_TO_SHIP,
  OrderStatus.EN_ROUTE,
  OrderStatus.DELIVERED,
];

const STATUSES_TO_HANDLE_DOCUMENTS_FOR_BUYER = [
  OrderStatus.AWAITING_FREIGHT_QUOTE,
  OrderStatus.DOCUMENTS_IN_PROGRESS,
  OrderStatus.PAYMENT_IN_PROGRESS,
];

const STATUSES_TO_DISPLAY_SHIPMENT_TRACKING = [OrderStatus.EN_ROUTE, OrderStatus.DELIVERED];

interface OrderDetailsPageHanlers {
  id: string;
  isBuyer: boolean;
  isChatModalOpen: boolean;
  isLoading: boolean;
  isDocumentsFetching: boolean;
  orderData?: Order;
  showPaymentInfo: boolean;
  paymentInfoData?: OrderPaymentInfo;
  deliveryAddress?: Address;
  isBuyerActionsWithDocumentsAllowed: boolean;
  isSellerActionsWithDocumentsAllowed: boolean;
  isViewShipmentTrackingButton: boolean;
  documentsData?: DocumentFolder[];
  currentDocument: OrderDocument | null;
  isDocumentOpen: boolean;
  handleOpenDocumentView: (file: OrderDocument) => void;
  handleCloseDocumentView: () => void;
  handleCloseChatModal: () => void;
  handleOpenChatModal: () => void;
}

export const useOrderDetailsPageHanlers = (): OrderDetailsPageHanlers => {
  const [currentDocument, setCurrentDocument] = useState<OrderDocument | null>(null);

  const {
    open: isChatModalOpen,
    handleCloseModal: handleCloseChatModal,
    handleOpenModal: handleOpenChatModal,
  } = useModal();

  const { isBuyer, activeUser } = useAuth();
  const { id = '' } = useParams<{ id: string }>();
  const {
    open: isDocumentOpen,
    handleCloseModal: handleCloseDocument,
    handleOpenModal: handleOpenDocument,
  } = useModal();

  const { data: orderData, isLoading: isOrderDetailsLoading } = useOrderQuery({
    id: Number(id),
    userId: activeUser?.id,
    isBuyer,
  });

  const showPaymentInfo = !!orderData?.status && STATUSES_TO_SHOW_PAYMENT_INFO.includes(orderData.status);
  const isBuyerActionsWithDocumentsAllowed =
    isBuyer && !!orderData?.status && STATUSES_TO_HANDLE_DOCUMENTS_FOR_BUYER.includes(orderData.status);
  const isSellerActionsWithDocumentsAllowed =
    !isBuyer && !!orderData?.status && STATUSES_TO_HANDLE_DOCUMENTS_FOR_BUYER.includes(orderData.status);
  const isViewShipmentTrackingButton =
    !!orderData?.orderShipmentId && STATUSES_TO_DISPLAY_SHIPMENT_TRACKING.includes(orderData.status);

  const { data: paymentInfoData, isLoading: isPaymentInfoLoading } = usePaymentInfoQuery({
    id: Number(id),
    userId: activeUser?.id,
    enabled: isBuyer && showPaymentInfo,
  });

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isFetching: isDocumentsFetching,
  } = useDocumentsQuery(Number(id));

  useFetchSystemMessages({
    queryKey: 'order-system-messages',
    quoteId: orderData?.quote.id,
    enabled: !!orderData?.quote.id || false,
  });

  const buyerCustomerId = orderData?.quote.buyer;

  const { deliveryAddress, isLoading: isDeliveryAddressLoading } = useDeliveryAddressQuery({ id: buyerCustomerId });

  const handleOpenDocumentView = useCallback(
    (file: OrderDocument) => {
      setCurrentDocument(file);
      handleOpenDocument();
    },
    [handleOpenDocument],
  );

  const handleCloseDocumentView = useCallback(() => {
    handleCloseDocument();
    setCurrentDocument(null);
  }, [handleCloseDocument]);

  return {
    id,
    isBuyer,
    isChatModalOpen,
    isLoading: isOrderDetailsLoading || isPaymentInfoLoading || isDeliveryAddressLoading || isDocumentsLoading,
    orderData,
    showPaymentInfo,
    paymentInfoData,
    deliveryAddress,
    documentsData,
    isBuyerActionsWithDocumentsAllowed,
    isSellerActionsWithDocumentsAllowed,
    isViewShipmentTrackingButton,
    isDocumentsFetching,
    currentDocument,
    isDocumentOpen,
    handleOpenDocumentView,
    handleCloseDocumentView,
    handleCloseChatModal,
    handleOpenChatModal,
  };
};
