import { FC, ReactNode, useCallback, useState } from 'react';

import { Box, Collapse } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { styles } from './styles';

interface CollapseWrapProps {
  children: ReactNode;
  arrowTopPosition?: string;
  collapsedSize?: string;
  defaultCollapsed?: boolean;
  gridRow?: number;
}

export const CollapseWrap: FC<CollapseWrapProps> = ({
  children,
  arrowTopPosition,
  collapsedSize = '36px',
  defaultCollapsed = true,
  gridRow,
}) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const handleCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <Collapse sx={styles.wrap(gridRow)} in={collapsed} collapsedSize={collapsedSize}>
      <Box sx={styles.arrowWrap(!collapsed, arrowTopPosition)} onClick={handleCollapse}>
        <ArrowIcon />
      </Box>
      {children}
    </Collapse>
  );
};
