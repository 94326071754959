import { FC, ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { CommonTooltip } from 'components';
import { styles } from './styles';

export const BaseInput: FC<{ sx?: SxProps; label: string; description?: string; children: ReactNode }> = ({
  label,
  description,
  children,
  sx,
}) => {
  return (
    <Box sx={{ ...(sx || {}), ...styles.inputContainer }}>
      <Box sx={styles.textInputLabel} component="p">
        {label}
        {description && (
          <CommonTooltip placement="right" sx={styles.description} title={description}>
            <InfoIcon />
          </CommonTooltip>
        )}
      </Box>
      {children}
    </Box>
  );
};
