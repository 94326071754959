import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    '.sendbird-channel-list': {
      width: '100%',
      background: customTheme.custom.mainBg,
    },

    '.sendbird-channel-list__header': {
      display: 'none',
    },

    '.sendbird-channel-preview': {
      width: '100%',
    },
  },
};
