import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CompanyDetail } from 'app/api/company';
import { useAuth } from 'hooks';
import { styles } from './styles';

interface AddressInfoProps {
  seller: CompanyDetail | undefined;
}

export const AddressInfo: FC<AddressInfoProps> = ({ seller }): JSX.Element => {
  const { activeUser } = useAuth();

  return (
    <Box sx={{ ...styles.wrap }}>
      <Box sx={{ ...styles.addressWrap }}>
        <Typography sx={{ ...styles.addressTitle }}>{seller?.name}</Typography>
        <Typography sx={{ ...styles.address }}>
          {seller?.address.city}, {seller?.address.country}
        </Typography>

        {seller?.address.address && (
          <Typography sx={{ ...styles.addressDescription }} data-testid="adress-description">
            {seller.address.address}
          </Typography>
        )}
      </Box>

      {activeUser?.deliveryAddress && (
        <Box>
          <Typography sx={{ ...styles.addressTitle }}>Delivery Address</Typography>
          <Typography sx={{ ...styles.address }}>
            {activeUser?.deliveryAddress.city}, {activeUser?.deliveryAddress.country}
          </Typography>

          {activeUser?.deliveryAddress.address && (
            <Typography sx={{ ...styles.addressDescription }}>{activeUser?.deliveryAddress.address}</Typography>
          )}
        </Box>
      )}
    </Box>
  );
};
