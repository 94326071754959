import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  pageWrap: {
    marginTop: '18px',
    padding: '0px 64px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '-14px',
      padding: '0px',
    },
  },
  requestProductContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  requestProduct: {
    margin: '0px auto 24px',
    height: '48px',
    width: '100%',
    maxWidth: '360px',
    fontWeight: 600,
    boxShadow: customTheme.shadow.boxShadow.standard,
    ':hover': {
      boxShadow: customTheme.shadow.boxShadow.large,
    },
    backgroundColor: customTheme.custom.bannerBlue,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0px 0px 24px',
    },
  },
};
