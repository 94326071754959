import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isShowMoreBottom: boolean) => ({
    position: 'relative',
    padding: '28px 16px 24px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    flexGrow: 1,
    overflow: 'hidden',

    ...(isShowMoreBottom && {
      paddingBottom: '12px',
    }),
  }),
  header: {
    position: 'absolute',
    top: '4px',
    left: '16px',
    right: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
  },
  showButton: {
    fontSize: '10px',
    lineHeight: '21px',
    fontWeight: 400,
    color: customTheme.palette.primary.P100,
    transition: 'all 0.3s ease-out',

    ':hover': {
      cursor: 'pointer',
      color: customTheme.palette.primary.P70,
      textDecoration: 'underline',
    },
  },
  listWrap: {
    padding: '4px 0',
    overflowY: 'scroll',
    paddingRight: '2px',
    marginRight: '-2px',
    ...scrollMixin,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '8px 6px',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
