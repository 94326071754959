import { FC, ReactNode } from 'react';

import { Badge, SxProps } from '@mui/material';

import { styles } from './styles';

interface CommonBadgeProps {
  badgeContent: ReactNode;
  children: ReactNode;
  sx?: SxProps;
}

export const CommonBadge: FC<CommonBadgeProps> = ({ badgeContent, children, sx }) => {
  return (
    <Badge badgeContent={badgeContent} color="secondary" sx={styles.wrap(sx) as SxProps}>
      {children}
    </Badge>
  );
};
