import { useCallback, useEffect, useState } from 'react';

import { UseFormWatch } from 'react-hook-form';

import { QuoteProduct, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { Currency, currencyFormat } from 'utils/currencyFormat';
import { AdditionalQuoteProductFormProps, QuoteTableFormData } from '../types';

export const useQuoteSellerTotal = (watch: UseFormWatch<QuoteTableFormData>) => {
  const quoteProducts = watch('quotes');
  const priceUnits = watch('priceUnits');
  const productCount = quoteProducts.length;
  const isScrolledList = productCount > 4;

  const totalAmount: { [unit in UnitsType]?: number } = quoteProducts.reduce(
    (acc: { [unit in UnitsType]?: number }, { amount, amountType, status }) => {
      const currentAmount: number = status === QuoteProductStatus.AVAILABLE ? amount : 0;
      if (status === QuoteProductStatus.AVAILABLE) {
        return {
          ...acc,
          [amountType]: (acc[amountType] || 0) + currentAmount,
        };
      }
      return acc;
    },
    {},
  );
  const totalAmounts = Object.entries(totalAmount).map(([unit, amount]) => ({
    unit: unit as UnitsType,
    amount,
  }));

  const getTotalPrice = useCallback(
    (quoteProducts: (QuoteProduct & AdditionalQuoteProductFormProps)[], priceUnits: Currency) => {
      const price = quoteProducts?.reduce((acc, val) => {
        const singlePrice = Number(val?.acceptedPrice || val?.value);

        if (!Number.isNaN(singlePrice)) {
          const currentCount = val?.status === QuoteProductStatus.AVAILABLE ? val?.amount : 0;
          return acc + singlePrice * Number(currentCount);
        }

        return acc;
      }, 0);
      return currencyFormat(price || 0, priceUnits);
    },
    [],
  );

  const [totalPrice, setTotalPrice] = useState<string>(getTotalPrice(quoteProducts, priceUnits));
  useEffect(() => {
    const subscription = watch((value) => {
      if (value.quotes && value.priceUnits)
        setTotalPrice(
          getTotalPrice(value.quotes as (QuoteProduct & AdditionalQuoteProductFormProps)[], value.priceUnits),
        );
    });
    return () => subscription.unsubscribe();
  }, [watch, priceUnits, setTotalPrice, getTotalPrice]);

  return {
    totalPrice,
    totalAmounts,
    isScrolledList,
  };
};
