export interface ResetPasswordFormValues {
  email: string;
}

export const ResetPasswordSteps = {
  resetPasswordForm: 'resetPasswordForm',
  infoMessage: 'infoMessage',
} as const;

export type ResetPasswordStepType = (typeof ResetPasswordSteps)[keyof typeof ResetPasswordSteps];
