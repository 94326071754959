import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface SearchSystemMessageItemProps {
  body: string;
}

export const SearchSystemMessageItem: FC<SearchSystemMessageItemProps> = ({ body }) => {
  const splittedMessage = body.split('\n');
  const joinedMessage = splittedMessage.splice(1).join(' ').trim();

  return (
    <Box sx={styles.message}>
      <Typography component="span" sx={styles.userName}>
        {splittedMessage[0]}
      </Typography>{' '}
      {joinedMessage}
    </Box>
  );
};
