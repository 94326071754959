import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '.sendbird-avatar': {
      minWidth: '18px',
    },
  },
  name: (isCurrentCompany: boolean) => ({
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    wordBreak: 'break-word',
    color: isCurrentCompany ? customTheme.palette.primary.P100 : customTheme.palette.secondary.S100,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
};
