import { OrdersListData } from 'app/api/orders/types';
import { SortTypes } from '../types';

interface CommonSortParams {
  data: OrdersListData;
  sortDirection: 'asc' | 'desc';
}

interface SortOrdersByNestedPropertyParams extends CommonSortParams {
  key1: 'quote';
  key2: 'totalPrice';
}

interface SortOrdersByDateParams extends CommonSortParams {
  key: 'createdAt' | 'estimatedDelivery' | 'deliveredAt' | 'sentAt';
}

const sortOrdersByNestedProperty = ({ data, key1, key2, sortDirection }: SortOrdersByNestedPropertyParams) => {
  return data.sort((q1, q2) => {
    const val1 = q1?.[key1]?.[key2];
    const val2 = q2?.[key1]?.[key2];
    const dif = Number(val2) - Number(val1);

    return sortDirection === 'asc' ? -dif : dif;
  });
};

const sortOrdersByDate = ({ data, key, sortDirection }: SortOrdersByDateParams) => {
  return data.sort((q1, q2) => {
    let dif;
    if (!!q1[key] && !q2[key]) {
      dif = -1;
    } else if (!q1[key] && !!q2[key]) {
      dif = 1;
    } else {
      dif = new Date(q2[key] ?? 0).valueOf() - new Date(q1[key] ?? 0).valueOf();
    }

    return sortDirection === 'asc' ? -dif : dif;
  });
};

const sortOrdersByDateWithEmptyLast = ({ data, key, sortDirection }: SortOrdersByDateParams) => {
  return data.sort((q1, q2) => {
    if (q1[key] === null) {
      return 1;
    }

    if (q2[key] === null) {
      return -1;
    }

    const dif = new Date(q2[key] ?? 0).valueOf() - new Date(q1[key] ?? 0).valueOf();
    return sortDirection === 'asc' ? -dif : dif;
  });
};

export const sortOrders = (data: OrdersListData, sortValue: SortTypes) => {
  switch (sortValue) {
    case SortTypes.DATE_CREATED_NEWEST:
      return sortOrdersByDate({ data, key: 'createdAt', sortDirection: 'desc' });

    case SortTypes.DATE_CREATED_OLDEST:
      return sortOrdersByDate({ data, key: 'createdAt', sortDirection: 'asc' });

    case SortTypes.DATE_SENT_NEWEST:
      return sortOrdersByDate({ data, key: 'sentAt', sortDirection: 'desc' });

    case SortTypes.DELIVERY_DATE_NEWEST:
      return sortOrdersByDate({ data, key: 'deliveredAt', sortDirection: 'desc' });

    case SortTypes.ESTIMATE_DELIVERY_DATE_NEWEST:
      return sortOrdersByDateWithEmptyLast({ data, key: 'estimatedDelivery', sortDirection: 'asc' });

    case SortTypes.ESTIMATE_DELIVERY_DATE_OLDEST:
      return sortOrdersByDateWithEmptyLast({ data, key: 'estimatedDelivery', sortDirection: 'desc' });

    case SortTypes.TOTAL_PRICE_HIGHEST:
      return sortOrdersByNestedProperty({ data, key1: 'quote', key2: 'totalPrice', sortDirection: 'desc' });

    case SortTypes.TOTAL_PRICE_LOWEST:
      return sortOrdersByNestedProperty({ data, key1: 'quote', key2: 'totalPrice', sortDirection: 'asc' });

    default:
      return data;
  }
};
