import { ChangeEvent, FC } from 'react';
import { Box, NativeSelect, Typography } from '@mui/material';
import { NumericFormat } from 'react-number-format';

import { MinMaxNumber } from 'app/api/products';
import { CommonInput } from 'components';
import { getSeparator } from 'utils/formatText';
import { FieldUnits } from 'utils/productSurvey';
import { styles } from './styles';
import { BaseInput } from '../BaseInput';

export interface MinMaxNumberInputProps {
  label: string;
  field: string;
  value?: MinMaxNumber;
  units: FieldUnits;
  description?: string;
  onChange: (field: string, value: MinMaxNumber) => void;
}

type UnitOptionsProps = {
  value?: string;
  options: string[];
  onUnitChange: (e: ChangeEvent<HTMLSelectElement>) => void;
};
const UnitOptions = ({ value, options, onUnitChange }: UnitOptionsProps) => {
  return (
    <NativeSelect variant="standard" onChange={onUnitChange} value={value} sx={styles.nativeInput} disableUnderline>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </NativeSelect>
  );
};

export const MinMaxNumberInput: FC<MinMaxNumberInputProps> = ({
  label,
  field,
  value = {},
  units,
  description,
  onChange,
}) => {
  const onChangeMin = (num: number) => onChange(field, { ...value, min: num });
  const onChangeMax = (num: number) => onChange(field, { ...value, max: num });
  const onChangeUnits = (e: ChangeEvent<HTMLSelectElement>) => onChange(field, { ...value, units: e.target.value });
  return (
    <BaseInput label={label} description={description}>
      <Box sx={styles.container}>
        <Box sx={styles.inputContainer}>
          <NumericFormat
            customInput={CommonInput}
            decimalSeparator={getSeparator('decimal')}
            thousandSeparator={getSeparator('group')}
            sx={{ ...styles.input }}
            value={value?.min}
            decimalScale={0}
            onValueChange={(value) => {
              if (value.floatValue !== undefined) {
                onChangeMin(value.floatValue);
              }
            }}
          />
          <Typography sx={styles.label}>Min </Typography>
        </Box>
        <Box sx={styles.inputContainer}>
          <NumericFormat
            customInput={CommonInput}
            decimalSeparator={getSeparator('decimal')}
            thousandSeparator={getSeparator('group')}
            sx={{ ...styles.input }}
            value={value?.max}
            onValueChange={(value) => {
              if (value.floatValue !== undefined) {
                onChangeMax(value.floatValue);
              }
            }}
          />
          <Typography sx={styles.label}>Max </Typography>
        </Box>
        {units && (
          <Box sx={styles.unitContainer}>
            {Object.keys(units).length > 1 ? (
              <UnitOptions options={Object.values(units)} value={value?.units} onUnitChange={onChangeUnits} />
            ) : (
              <Box>{Object.values(units)[0]}</Box>
            )}
          </Box>
        )}
      </Box>
    </BaseInput>
  );
};
