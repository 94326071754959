import { FC } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { useAuth } from 'hooks';
import { styles } from './styles';

interface SBLoaderProps {
  isFullHeight?: boolean;
}

export const SBLoader: FC<SBLoaderProps> = ({ isFullHeight = false }) => {
  const { isBuyer } = useAuth();

  return (
    <Box sx={styles.wrap(isFullHeight, isBuyer)}>
      <CircularProgress size={64} />
    </Box>
  );
};
