import { FC } from 'react';

import { Checkbox, CheckboxProps } from '@mui/material';
import { ReactComponent as RadioChecked } from '../../assets/icons/checkbox.svg';
import { ReactComponent as RadioUnchecked } from '../../assets/icons/checkboxUnchecked.svg';
import { ReactComponent as RadioCheckedBig } from '../../assets/icons/checkboxBig.svg';
import { ReactComponent as RadioUncheckedBig } from '../../assets/icons/checkboxUncheckedBig.svg';

import { styles } from './styles';

export const CommonCheckbox: FC<CheckboxProps & { isBigIcon?: boolean }> = ({ isBigIcon, ...props }) => {
  return (
    <Checkbox
      sx={{ ...styles.checkbox }}
      color="primary"
      icon={isBigIcon ? <RadioUncheckedBig /> : <RadioUnchecked />}
      checkedIcon={isBigIcon ? <RadioCheckedBig /> : <RadioChecked />}
      {...props}
    />
  );
};
