import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { useChannelContext } from '@sendbird/uikit-react/Channel/context';
import { FileMessage } from '@sendbird/chat/lib/__definition';

import { FileItem } from '../FileItem';
import { useFilesList } from './hooks';
import { styles } from './styles';
import { FilesListEmptyState } from '../FilesListEmptyState';

interface FilesListProps {
  attachments: FileMessage[];
}

export const FilesList: FC<FilesListProps> = () => {
  const channelContext = useChannelContext();

  const attachments = (channelContext.allMessages || []) as FileMessage[];

  const {
    isHideButton,
    isShowMoreBottomButton,
    isShowMoreTopButton,
    visibleAttachments,
    handleAttachmentsView,
    handleShowMore,
  } = useFilesList({
    attachments,
  });

  return (
    <Box sx={styles.wrap(isShowMoreBottomButton)}>
      <Box sx={styles.header}>
        <Typography sx={styles.title}>Files</Typography>
        {isShowMoreTopButton && (
          <Typography component="span" sx={styles.showButton} onClick={handleAttachmentsView}>
            {isHideButton ? 'Hide' : 'Show more'}
          </Typography>
        )}
      </Box>
      {attachments.length ? (
        <>
          <Box sx={styles.listWrap}>
            <Box sx={styles.list}>
              {visibleAttachments.map((message) => (
                <FileItem key={message.messageId} name={message.name} url={message.plainUrl} />
              ))}
            </Box>
          </Box>
          {isShowMoreBottomButton && (
            <Box sx={styles.footer}>
              <Typography component="span" sx={styles.showButton} onClick={handleShowMore}>
                Show more
              </Typography>
            </Box>
          )}
        </>
      ) : (
        <FilesListEmptyState />
      )}
    </Box>
  );
};
