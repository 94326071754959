import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { CommonButton } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { CommonDrawer } from 'layout/MobilePageLayout/components/CommonDrawer';
import { QuoteProduct, UnitsType } from 'app/api/quotes';

import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { NumberFormField } from 'components/NumberFormField';
import { useForm } from 'react-hook-form';
import { formatNumber } from 'utils/formatText';
import { ProductVariety } from 'pages/DashboardPage/components';

import { styles } from './styles';

interface ChangeUnitTypeProps {
  isOpen: boolean;
  handleClose: () => void;
  onChange: (value: number) => void;
  amount: number;
  data: QuoteProduct;
  currentUnitType: UnitsType;
  updatedUnitType: UnitsType;
}
const messages = {
  title: 'Change Incoterms',
  change:
    'If you change this product’s unit to thousand seeds, you must suggest a new quantity. ' +
    'You can clarify the equivalent weight in the chat.',
};

export const ChangeUnitType: FC<ChangeUnitTypeProps> = ({
  isOpen,
  handleClose,
  onChange,
  amount,
  data,
  currentUnitType,
  updatedUnitType,
}) => {
  const { control, watch } = useForm<{ quantity: undefined | number }>({
    defaultValues: {
      quantity: undefined,
    },
  });

  const quantity = watch('quantity');

  const onConfirm = () => {
    if (quantity) onChange(quantity);
    handleClose();
  };
  return (
    <CommonDrawer isOpen={isOpen} handleClose={handleClose} sx={styles.drawer as SxPropsTypes}>
      <Box sx={styles.container}>
        <Box sx={styles.titleContainer}>
          <CommonButton variant="text" sx={styles.close} onClick={handleClose}>
            <CloseIcon />
          </CommonButton>
          <Typography variant="h4" sx={styles.title}>
            {`Change to ${getUnitDisplayValue(updatedUnitType)}?`}
          </Typography>
        </Box>
        <ProductVariety data={data} fullWidth />
        <Typography sx={styles.orginalText}>
          {`Original Request – ${formatNumber(amount)} ${getUnitDisplayValue(currentUnitType)}`}
        </Typography>

        <Box sx={styles.change}>
          <Typography sx={styles.labelText}>{messages.change}</Typography>
        </Box>
        <NumberFormField
          wrapStyles={{ ...styles.amountField } as SxPropsTypes}
          control={control}
          name="quantity"
          placeholder="Suggest new quantity"
          decimalScale={4}
          withoutHighlights
        />
      </Box>

      <Box sx={styles.bottom}>
        <CommonButton variant="contained" sx={styles.confirm} fullWidth onClick={onConfirm} disabled={!quantity}>
          Confirm
        </CommonButton>
      </Box>
    </CommonDrawer>
  );
};
