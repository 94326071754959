import { useCallback, useMemo, useState } from 'react';

import { useWatch, Control } from 'react-hook-form';

import { GeneticType } from 'app/api/products';
import { producttFilterAllValue } from '../../../constants';

export const useGeneticTypeFilter = (control: Control, controlName: string, isFullTitle?: boolean) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const currentVal = useWatch({
    control,
    name: controlName,
  });

  const filterTitle = useMemo(() => {
    switch (currentVal) {
      case GeneticType.hybrid:
        return 'Hybrid';
      case GeneticType.openPollinated:
        return isFullTitle ? 'Open-Pollinated' : 'OP';
      default:
        return 'Hybrid/OP';
    }
  }, [currentVal, isFullTitle]);

  const isSelected = currentVal !== producttFilterAllValue;

  return {
    anchorEl,
    currentValue: currentVal,
    open,
    filterTitle,
    isSelected,
    handleClick,
    handleClose,
  };
};
