import { FC, useCallback } from 'react';

import { Control } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { CompanyDetail } from 'app/api/company/types';
import { QuoteProduct, UnitsType } from 'app/api/quotes/types';
import { CommonButton, CommonModal, LoadingButton } from 'components';
import { useSendQuoteRequest } from '../../hooks/useSendQuoteRequest';
import { QuoteTableFormData } from '../../types';
import { styles } from './styles';

interface SendQuoteRequestPopupProps {
  title: string;
  handleClose: () => void;
  closeDetails: () => void;
  isOpen: boolean;
  data: QuoteProduct[];
  unitsType: UnitsType;
  quoteId: number;
  control: Control<QuoteTableFormData>;
  seller: CompanyDetail | undefined;
  currentCycle: number;
  isSampleRequest: boolean;
}

export const SendQuoteRequestPopup: FC<SendQuoteRequestPopupProps> = ({
  title,
  handleClose,
  closeDetails,
  isOpen = false,
  data,
  unitsType,
  quoteId,
  control,
  seller,
  currentCycle,
  isSampleRequest,
}) => {
  const handleAction = useCallback(() => {
    handleClose();
    closeDetails();
  }, [handleClose, closeDetails]);

  const { isLoading, handleConfirm } = useSendQuoteRequest({
    control,
    currentCycle,
    data,
    isCreateOrder: false,
    isSampleRequest,
    quoteId,
    seller,
    unitsType,
    handleAction,
  });

  return (
    <CommonModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>Are you sure you want to send this quote request?</Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <LoadingButton
          loading={isLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.confirmButton }}
          onClick={handleConfirm}
        >
          Confirm
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
