import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  infoWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '44%',
    padding: '16px',
    color: customTheme.custom.white,
    backgroundColor: customTheme.palette.primary.P100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      boxSizing: 'border-box',
      paddingRight: '8px',
      paddingBottom: '4px',
      overflow: 'hidden',
    },
  },
  infoTitle: {
    marginBottom: '10px',
    fontWeight: '600',
    lineHeight: '22px',
  },
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: '1',
  },
};
