import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  detailsWrap: {
    height: '200px',
    display: 'flex',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      height: 'fit-content',
      overflow: 'hidden',
    },
  },
  loaderWrap: {
    padding: '36px 0',
  },
};
