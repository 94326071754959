import { DashboardMainTabs, DashboardTabs, SampleRequestsTabs } from 'app/api/quotes/types';

export const getTabEmptyStateLabel = (tab?: DashboardTabs) => {
  switch (tab) {
    case DashboardMainTabs.QUOTE_REQUESTS:
      return 'Future Quote Requests';

    case DashboardMainTabs.OPEN_ORDERS:
      return 'Future Open Orders';

    case DashboardMainTabs.EN_ROUTE:
      return 'En Route Orders';

    case DashboardMainTabs.PAST_ORDERS:
      return 'Past Orders';

    case SampleRequestsTabs.SAMPLE_REQUESTS:
      return 'Future Sample Requests';

    case SampleRequestsTabs.SAMPLE_SEND_IN_PROGRESS:
      return 'Samples in Progress';

    case SampleRequestsTabs.SAMPLE_EN_ROUTE:
      return 'En Route Samples';

    case SampleRequestsTabs.PAST:
      return 'Past Samples';

    default:
      return '';
  }
};
