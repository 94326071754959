import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface EventItemProps {
  label: string;
  value: string;
}

export const EventItem: FC<EventItemProps> = ({ label, value }) => {
  return (
    <Box key={value} sx={styles.itemWrap}>
      <Typography sx={styles.label}>{label}</Typography>
      <Typography sx={styles.value}>{value}</Typography>
    </Box>
  );
};
