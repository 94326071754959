import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface StatItemProps {
  label: string;
  value: string;
}

export const StatItem: FC<StatItemProps> = ({ label, value }): JSX.Element => {
  return (
    <Box sx={{ ...styles.itemWrap }}>
      <Typography sx={{ ...styles.label }}>{label}</Typography>
      <Typography sx={{ ...styles.value }}>{value}</Typography>
    </Box>
  );
};
