import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  subTitle: {
    margin: '-18px 0 8px',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
  },
  actionsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  mainActions: {
    display: 'flex',
    gap: '12px',
  },
  cancelButton: {
    cursor: 'pointer',
    padding: '2px',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B50,
  },
  cancelButtonDisabled: {
    cursor: 'default',
    color: 'rgba(0, 0, 0, 0.26)',
  },
  button: {
    minWidth: '140px',
  },
  priceHistory: (isDisabled: boolean) => ({
    '& svg': {
      marginRight: '4px',
      '& path': isDisabled
        ? {
            fill: 'rgba(0, 0, 0, 0.26)',
          }
        : {},
    },
  }),
  createButton: {
    minWidth: '140px',
    color: customTheme.custom.white,
  },
  chatButton: {
    display: 'flex',
    gap: '4px',
    minWidth: 'fit-content',
  },
  incoterms: {},
  incotermsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '16px',
  },
  incotermLabel: {
    fontSize: '16px',
    fontWeight: 500,
  },
  incotermBuyerVal: {
    fontSize: '16px',
    fontWeight: 500,
    color: customTheme.palette.primary.main,
  },
};
