import { FC, useMemo } from 'react';

import { Box, Dialog, Typography } from '@mui/material';
import { usePriceHistory } from 'hooks/api/usePriceHistoryQuery';
import { PriceHistory, Quote, QuoteProduct, UnitsType } from 'app/api/quotes';
import { ReactComponent as PinIcon } from 'assets/icons/pin.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as BoxIcon } from 'assets/icons/box.svg';
import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { currencyFormat } from 'utils/currencyFormat';
import { CloseButton } from 'components/CloseButton';

import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { styles } from './styles';

interface PriceHistoryProps {
  quote: Quote;
  handleClose: () => void;
  isOpen: boolean;
  isBuyer: boolean;
  quoteId: number;
}

type UnitAmount = { [key in UnitsType]: number };

export const IterationRow = ({
  quote,
  iteration,
  quoteProducts,
}: {
  quote: Quote;
  iteration: { [product: string]: PriceHistory };
  quoteProducts: string[];
}) => {
  const getValidPriceHistory = (iteration: { [quoteProduct: string]: PriceHistory }) => {
    const prices = Object.values(iteration);
    if (prices.length > 0) {
      return prices[0];
    }
    return null;
  };
  const priceHistory = getValidPriceHistory(iteration);
  const isBuyer = !!priceHistory?.isBuyer;
  const createdAt = priceHistory?.createdAt ? new Date(priceHistory.createdAt).toLocaleString() : '';
  const actorName = isBuyer ? quote.buyer.name : quote.seller.name;
  const amounts = quoteProducts.reduce((amounts: UnitAmount, qp) => {
    if (iteration[qp]) {
      const type = iteration[qp].amountType;
      return {
        ...amounts,
        // ts-ignore-next-line
        [type]: (amounts[type] ?? 0) + iteration[qp].amount,
      };
    }
    return amounts;
  }, {} as UnitAmount);
  const amount = Object.entries(amounts)
    .map(([unit, amount]) => `${amount} ${getUnitDisplayValue(unit as UnitsType, true)}`, '')
    .join(' + ');

  return (
    <Box sx={styles.iteration}>
      <Box sx={styles.iterationActor(isBuyer)}>
        <Typography sx={styles.actorProposal}>Proposal By</Typography>
        <Typography sx={styles.actorName}>{actorName}</Typography>
        <Typography sx={styles.actorDate}>{createdAt}</Typography>
      </Box>
      <Box>
        <Box sx={styles.iterationRows}>
          <Box sx={styles.rowlabels(isBuyer)}>Quantity</Box>
          {quoteProducts.map((qp: string, index) => (
            <Box key={qp} sx={styles.iterationRowItem(index === 0)}>
              {iteration[qp] && (
                <Typography>
                  {iteration[qp].amount} {getUnitDisplayValue(iteration[qp].amountType, true)}
                </Typography>
              )}
            </Box>
          ))}
          <Box sx={styles.iterationTotals}>{amount}</Box>
        </Box>
        <Box sx={styles.iterationRows}>
          <Box sx={styles.rowlabels(isBuyer)}>Offered Price per unit</Box>
          {quoteProducts.map((qp: string, index) => (
            <Box key={qp} sx={styles.iterationRowItem(index === 0)}>
              {iteration[qp] && (
                <Typography>{currencyFormat(iteration[qp].price, iteration[qp].priceUnits)}</Typography>
              )}
            </Box>
          ))}
          <Box sx={styles.iterationTotals} />
        </Box>
        <Box sx={styles.iterationRows}>
          <Box sx={styles.rowlabels(isBuyer)}>Subtotal Price</Box>
          {quoteProducts.map((qp: string, index) => (
            <Box key={qp} sx={styles.iterationRowItem(index === 0)}>
              {iteration[qp] && (
                <Box sx={styles.rowCurrencyContainer}>
                  <Typography sx={styles.rowCurrency(iteration[qp].isAccepted)}>
                    {currencyFormat(iteration[qp].price * iteration[qp].amount, iteration[qp].priceUnits)}
                  </Typography>
                  <Typography sx={styles.rowCurrencyAccepted}>
                    {iteration[qp].isAccepted && 'Price Accepted'}
                  </Typography>
                </Box>
              )}
            </Box>
          ))}
          <Box sx={styles.iterationTotals}>
            {currencyFormat(
              quoteProducts.reduce(
                (total: number, qp: string) => total + (iteration[qp] ? iteration[qp].price * iteration[qp].amount : 0),
                0,
              ),
              priceHistory?.priceUnits,
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const PriceHistoryPopup: FC<PriceHistoryProps> = ({ isBuyer, quote, handleClose, isOpen = false, quoteId }) => {
  const { isLoading, data } = usePriceHistory(quoteId);
  const { iterations, quoteProducts } = useMemo(() => {
    if (data) {
      const groupedIteration: { [iteration: string]: { [quoteProductId: string]: PriceHistory } } = data.reduce(
        (acc: { [iteration: string]: { [quoteProductId: string]: PriceHistory } }, item: PriceHistory) => ({
          ...acc,
          [item.iteration]: {
            ...(acc[item.iteration] || {}),
            [item.quoteProduct]: item,
          },
        }),
        {},
      );
      const keys = Object.keys(groupedIteration);
      keys.sort();
      const iterations: { i: number; products: { [quoteProductId: string]: PriceHistory } }[] = keys
        .map((key, index) => ({
          i: index,
          products: groupedIteration[key] as { [quoteProductId: string]: PriceHistory },
        }))
        .reverse();
      const quoteProducts = [...new Set(iterations.map((i) => Object.keys(i.products)).flat())];
      return { iterations, quoteProducts };
    }
    return { iterations: [], quoteProducts: [] };
  }, [data]);
  const products = quoteProducts
    .map((qp) => quote.quoteproducts.find((p) => p.id === parseInt(qp, 10)))
    .filter(Boolean) as QuoteProduct[];
  if (isLoading) {
    return null;
  }
  const tradingPartner = isBuyer ? quote.buyer.name : quote.seller.name;
  const tradingPartnerCountry = isBuyer ? quote.buyer.address.country : quote.seller.address.country;
  const units = [...new Set(quote.quoteproducts.map((qp) => qp.amountType))];

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          ...styles.paper,
        },
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Box sx={styles.basicInfo}>
        <Box sx={styles.basicInfoCol}>
          <Typography sx={styles.basicInfoSummary}>Summary</Typography>
          <Box sx={styles.basicInfoRow}>
            <Typography sx={styles.basicInfoLabel}>Trading Partner: </Typography>
            <Typography sx={styles.basicInfoValue}>{tradingPartner}</Typography>
          </Box>
          <Box sx={styles.basicInfoRow}>
            <Typography sx={styles.basicInfoLabel}>
              <PinIcon />
              Country:
            </Typography>
            <Typography sx={styles.basicInfoValue}>{tradingPartnerCountry}</Typography>
          </Box>
          <Box sx={styles.basicInfoRow}>
            <Typography sx={[styles.basicInfoLabel, styles.fillIcon]}>
              <CalendarIcon />
              Quote Created:
            </Typography>
            <Typography sx={styles.basicInfoValue}>
              {quote.createdAt && new Date(quote.createdAt).toLocaleDateString()}
            </Typography>
          </Box>
          <Box sx={styles.basicInfoRow}>
            <Typography sx={styles.basicInfoLabel}>
              <BoxIcon />
              Volume Units:
            </Typography>
            <Typography sx={styles.basicInfoValue}>
              {units.map((u) => (
                <Box key={u} sx={[styles.pill, styles.units]}>
                  {getUnitDisplayValue(u)}
                </Box>
              ))}
            </Typography>
          </Box>
          <Box sx={styles.basicInfoRow}>
            <Typography sx={styles.basicInfoLabel}>
              <CurrencyIcon />
              Currency:
            </Typography>
            <Typography sx={[styles.basicInfoValue, styles.pill, styles.currency]}>{quote.priceUnits}</Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={styles.iterationsContainer}>
        <Box sx={styles.header}>
          {products.map((qp: QuoteProduct) => (
            <Box sx={styles.headerItem} key={qp.id}>
              <Typography sx={styles.headerCrop}>{qp.product.crop}</Typography>
              <Box sx={styles.headerGroup}>
                <Typography component="span" sx={styles.headerVariety}>
                  {qp.product.variety}{' '}
                </Typography>
                <Typography component="span" sx={styles.headerName}>
                  {qp.product.name}
                </Typography>
              </Box>
            </Box>
          ))}
          <Box sx={[styles.iterationTotals, styles.iterationTotalHeader]}>Totals</Box>
        </Box>
        <CloseButton sx={styles.close} onClick={handleClose} />
        <Box sx={styles.rowsContainer}>
          {iterations.map((iteration) => (
            <IterationRow
              quote={quote}
              key={iteration.i}
              quoteProducts={quoteProducts}
              iteration={iteration.products}
            />
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};
