import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageWrap: {
    boxSizing: 'border-box',
    margin: '0px -24px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 0,
      marginTop: '-14px',
      margin: '-14px -14px 0px',
    },
  },
  pageContentWrap: {
    display: 'flex',
    gap: '40px',
    margin: '40px 0 16px',
    padding: '0 76px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: '16px',
      margin: '16px 0 0 0',
      padding: 0,
    },
  },
  column: {
    flex: '1',
  },
  // Features Section
  featuresContainer: {
    position: 'relative',
    display: 'flex',
    backgroundColor: customTheme.palette.secondary.S10,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  featuresInnerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxWidth: breakpoints.desktop,
  },
  featuresLeft: {
    flex: 1,
  },
  featuresText: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    boxSizing: 'border-box',
    minWidth: '550px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '24px',
      minWidth: 'inherit',
    },
  },
  featuresImg: {
    position: 'absolute',
    right: 0,
    top: 0,
    flex: 1,
    maxWidth: '720px',
    maxHeight: '470px',
    minWidth: '350px',
    objectFit: 'cover',
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      display: 'none',
    },
  },
  featuresTitle: {
    color: customTheme.custom.black.B1000,
    fontSize: '32px',
    fontWeight: 600,
    marginBottom: '16px',
    [`@media (max-width: ${breakpoints.desktop}px)`]: {
      fontSize: '24px',
    },
  },
  features: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '16px',
    },
  },
  feature: {
    display: 'flex',
    alignItems: 'center',
    textWrap: 'wrap',
    gap: '8px',
    fontSize: '20px',
    svg: {
      border: '2px solid',
      borderColor: customTheme.palette.primary.main,
      borderRadius: '100%',
      width: '24px',
      minWidth: '24px',
      height: '24px',
      path: {
        fill: customTheme.palette.primary.main,
      },
    },
    [`@media (max-width: ${breakpoints.desktop}px)`]: {
      gap: '4px',
      fontSize: '16px',
      svg: {
        width: '16px',
        minWidth: '16px',
        height: '16px',
      },
    },
  },
  // NetworkSection
  networkContainer: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '48px',
    maxWidth: breakpoints.desktop,
    margin: '0px auto',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '24px',
    },
  },
  networkTitle: {
    fontSize: '32px',
    fontWeight: 600,
    marginBottom: '32px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      lineHeight: 1.1,
      marginBottom: '16px',
    },
  },
  networkCards: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridTemplateColumns: '1fr',
    },
    flexWrap: 'wrap',
    gap: '24px 64px',
    justifyContent: 'space-between',
  },
  networkCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '16px',
    backgroundColor: customTheme.custom.white,
    padding: '24px 32px',
    minWidth: '300px',
    maxWidth: '650px',
    height: '200px',
    boxSizing: 'border-box',
    boxShadow: customTheme.shadow.boxShadow.standard,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '16px',
      height: '120px',
    },
  },
  networkCardPre: {
    position: 'absolute',
    top: '24px',
    left: '32px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      top: '8px',
      left: '16px',
    },
  },
  networkCardMetric: {
    fontSize: '72px',
    lineHeight: '1.2',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '36px',
    },
  },
  networkCardLabel: {
    fontSize: '24px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
    },
  },

  // Trust Section
  trustContainer: {
    display: 'flex',
    backgroundColor: customTheme.palette.secondary.S10,
    justifyContent: 'center',
  },
  trustInnerContainer: {
    display: 'flex',
    gap: '40px',
    flexWrap: 'wrap',
    maxWidth: breakpoints.desktop,
  },
  trustText: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    padding: '48px',
    minWidth: '540px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '32px',
      minWidth: 'inherit',
    },
  },
  trustTitle: {
    color: customTheme.custom.black.B1000,
    fontSize: '32px',
    fontWeight: 600,
    marginBottom: '16px',
  },
  trustDescription: {
    maxWidth: '650px',
  },
  trustImg: {
    flex: 1,
    maxWidth: '720px',
    maxHeight: '380px',
    minWidth: '320px',
    objectFit: 'cover',
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      display: 'none',
    },
  },

  // Testimonial Section
  testimonialContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  testimonialContainerInner: {
    maxWidth: breakpoints.desktop,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: '64px',
    boxSizing: 'border-box',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '32px',
    },
  },
  testimonialTitle: {
    color: customTheme.custom.black.B1000,
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  testimonialSubtitle: {
    color: customTheme.custom.black.B70,
    fontWeight: 400,
    marginBottom: '24px',
  },
  testimonialTextBody: {
    fontWeight: 400,
    color: customTheme.custom.black.B70,
  },
  testimonialTextBold: {
    fontWeight: 500,
    color: customTheme.custom.black.B1000,
  },
  testimonialQuote: {
    display: 'inline-flex',
    gap: '86px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '32px',
    },
  },
  testimonialQuoteText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'flex-end',
  },
  testimonialImg: {
    borderRadius: '100%',
    width: '260px',
    height: '260px',
  },
  testimonialAuthorSection: {
    maxWidth: '500px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '16px',
    },
  },
  testimonialAuthor: {
    fontStyle: 'italic',
    color: customTheme.custom.black.B70,
  },
  testimonialAuthorName: {
    fontWeight: 600,
    color: customTheme.palette.primary.main,
    fontStyle: 'normal',
  },
  testimonialAuthorLabel: {
    color: customTheme.palette.primary.main,
  },
};
