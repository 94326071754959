import { customTheme } from '../../theme/MuiThemeProvider/theme';
import { SxPropsTypes } from '../../theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  button: {
    padding: '2px 0',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,
    background: 'none',
    textTransform: 'none',
    boxShadow: 'none',
    ':hover': {
      color: customTheme.palette.info.main,
      background: 'none',
      textDecoration: 'underline',
    },
  },
};
