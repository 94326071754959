import { useMemo } from 'react';

import { generatePath, matchRoutes, useLocation, useSearchParams } from 'react-router-dom';

import { SampleRequestsTabs } from 'app/api/quotes';
import { ROUTES } from 'app/routes/constants';
import { useDeviceType } from 'hooks';

const QUOTE_DETAILS_ROUTES = [
  { path: ROUTES.buyer.dashboard._ },
  { path: ROUTES.buyer.dashboard.tab },
  { path: ROUTES.seller.dashboard._ },
  { path: ROUTES.seller.dashboard.tab },
];

export const useQuoteRequestDetailsRouteHandlers = () => {
  const { isMobileSize } = useDeviceType();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const quoteIdParam = searchParams.get('quoteId') ?? '';

  const matchDasboardRoutes = matchRoutes(QUOTE_DETAILS_ROUTES, pathname);

  const shouldNavigateQuoteDetail = useMemo(
    () => isMobileSize && !!matchDasboardRoutes?.length && !!quoteIdParam,
    [isMobileSize, matchDasboardRoutes?.length, quoteIdParam],
  );

  const isSampleRequest = pathname.includes(SampleRequestsTabs.SAMPLE_REQUESTS);

  const mobileQuoteDetailPath = generatePath(
    isSampleRequest ? ROUTES.common.sampleRequestDetails : ROUTES.common.quoteRequestDetails,
    { id: quoteIdParam },
  );

  return {
    shouldNavigateQuoteDetail,
    mobileQuoteDetailPath,
  };
};
