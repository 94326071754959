import { FC, useCallback } from 'react';

import { CommonButton, LoadingButton } from 'components';
import { ViewButtonOutlined } from './ViewButtonOutlined';
import { styles } from './styles';

interface BuyerActionsProps {
  isCompleted: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  isSellerActionsAllowed: boolean;
  handleView: () => void;
  handleClearError: () => void;
  handleOpenUploadFilesPopup: () => void;
}

export const SellerActions: FC<BuyerActionsProps> = ({
  isCompleted,
  isEmpty,
  handleView,
  isLoading,
  isSellerActionsAllowed,
  handleClearError,
  handleOpenUploadFilesPopup,
}): JSX.Element => {
  const onHandleView = useCallback(() => {
    handleClearError();
    handleView();
  }, [handleClearError, handleView]);

  const handleUploadButtonClick = useCallback(() => {
    handleClearError();
    handleOpenUploadFilesPopup();
  }, [handleClearError, handleOpenUploadFilesPopup]);

  const uploadContent = isSellerActionsAllowed ? (
    <LoadingButton
      loading={isLoading}
      variant="outlined"
      color="secondary"
      sx={{ ...styles.viewButton }}
      onClick={handleUploadButtonClick}
    >
      Upload
    </LoadingButton>
  ) : (
    <></>
  );

  if (isEmpty) {
    return uploadContent;
  }

  if (isCompleted) {
    return <ViewButtonOutlined handleClick={handleView} />;
  }

  return (
    <>
      {uploadContent}
      {isSellerActionsAllowed ? (
        <CommonButton
          disableRipple
          variant="text"
          color="inherit"
          sx={{ ...styles.viewBorderlessButton }}
          onClick={onHandleView}
        >
          View
        </CommonButton>
      ) : (
        <ViewButtonOutlined handleClick={handleView} />
      )}
    </>
  );
};
