import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useLocation, generatePath } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { SampleRequestsTabs } from 'app/api/quotes/types';
import { ROUTES } from 'app/routes/constants';
import { useAuth } from 'hooks';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { dasboardTabs, sampleRequestsTabs } from '../constants';
import { DashboardTab } from '../types';

interface SampleRequestsTabReturn {
  isBuyer: boolean;
  activeTab: DashboardTab;
  handleTabChange: (event: SyntheticEvent, newTab: number) => void;
}

const getValidTabs = (role: 'buyer' | 'seller') => {
  return Object.values(SampleRequestsTabs).map((tab) => {
    return generatePath(ROUTES[role].dashboard.tab, { tab });
  });
};

interface UseSampleRequestsTabProps {
  enabled?: boolean;
}

export const useSampleRequestsTab = ({ enabled = true }: UseSampleRequestsTabProps): SampleRequestsTabReturn => {
  const { isBuyer } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const role = isBuyer ? 'buyer' : 'seller';
  const mainSampleRequestsRoute = ROUTES[role].dashboard._;
  const validTabs = useMemo(() => [...getValidTabs(role), mainSampleRequestsRoute], [mainSampleRequestsRoute, role]);
  const isValidTab = useMemo(() => validTabs.includes(pathname), [pathname, validTabs]);

  const activeTab = useMemo(
    () => sampleRequestsTabs.find((tab) => pathname.includes(tab.path)) as DashboardTab,
    [pathname],
  );

  useEffect(() => {
    if (!enabled) return;

    if (!isValidTab) {
      navigate(mainSampleRequestsRoute);
    }
  }, [enabled, isValidTab, mainSampleRequestsRoute, navigate]);

  useEffect(() => {
    if (!enabled) return;

    const activeTabFromUrl = sampleRequestsTabs.find((tab) => pathname.includes(tab.path))?.value ?? 0;

    if (activeTab?.value !== activeTabFromUrl) {
      handleTabChange(null, activeTabFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = useCallback(
    (event: SyntheticEvent | null, newTab: number) => {
      event?.preventDefault();
      navigate(dasboardTabs[newTab].path);
      handleTrackLinkClickEvent({
        path: dasboardTabs[newTab].path,
        type: AnalyticsLinkType.BUTTON,
        text: dasboardTabs[newTab].label,
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['orders-counter'] });
    },
    [handleTrackLinkClickEvent, navigate, queryClient],
  );

  return {
    isBuyer,
    activeTab,
    handleTabChange,
  };
};
