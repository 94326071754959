import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageWrap: {
    flexGrow: 1,
    padding: '0 0 6px',
    margin: '-14px -14px 0 -14px',
    display: 'grid',
    background: customTheme.custom.white,

    '.sendbird-channel-list': {
      width: '100%',
    },

    '.sendbird-conversation__messages .sendbird-conversation__messages-padding': {
      padding: '6px 4px 0 0',
    },
  },
};
