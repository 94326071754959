import { MouseEvent, useCallback, useMemo } from 'react';

import { useQuery, useQueryClient } from 'react-query';

import { getQuoteData } from 'app/api/quotes/service';
import { QuoteProductStatus } from 'app/api/quotes';

export const useQuoteRequestDetails = (quoteId: number, handleClose: (e?: MouseEvent) => void) => {
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(['quote-data', quoteId], () => getQuoteData(quoteId), {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
  });
  const quoteData = useMemo(() => {
    // products without a seller price, products not accepted, the products accepted, then products cancelled
    data?.quoteproducts.sort((a, b) => {
      if (a.latestSellerPrice?.price && !b.latestSellerPrice?.price) return 1;
      if (!a.latestSellerPrice?.price && b.latestSellerPrice?.price) return -1;
      if (a.status !== QuoteProductStatus.AVAILABLE && b.status === QuoteProductStatus.AVAILABLE) return 1;
      if (a.status === QuoteProductStatus.AVAILABLE && b.status !== QuoteProductStatus.AVAILABLE) return -1;
      if (a.acceptedPrice && !b.acceptedPrice) return 1;
      if (!a.acceptedPrice && b.acceptedPrice) return -1;
      return 0;
    });
    return data;
  }, [data]);

  const closeDetails = useCallback(
    (e?: MouseEvent) => {
      handleClose(e);

      void Promise.all([
        queryClient.invalidateQueries({ queryKey: ['quote-requests'] }, { cancelRefetch: true }),
        queryClient.invalidateQueries({ queryKey: ['sample-requests'] }, { cancelRefetch: true }),
      ]);
    },
    [handleClose, queryClient],
  );

  return {
    isLoading,
    quoteData,
    closeDetails,
  };
};
