import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  footerWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      margin: '0 -14px 76px -14px',
    },
  },
  stickyBarWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
    },
  },
};
