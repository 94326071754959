import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { CommonModal } from '../../../../components/CommonPopup';
import { CommonButton } from '../../../../components/CommonButton';
import { LoadingButton } from '../../../../components/LoaderButton';

import { styles } from './styles';

interface RemoveFolderPopupProps {
  documentName: string;
  handleClose: () => void;
  isOpen: boolean;
  handleRemove: () => void;
  isLoading: boolean;
}

export const RemoveFolderPopup: FC<RemoveFolderPopupProps> = ({
  documentName,
  handleClose,
  handleRemove,
  isOpen = false,
  isLoading,
}) => {
  return (
    <CommonModal title={`Remove ${documentName}`} isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        {`Are you sure you want to remove ${documentName} as a required document?`}
      </Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <LoadingButton
          loading={isLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.removeButton }}
          onClick={handleRemove}
        >
          Confirm
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
