import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  menuWrap: {
    paddingTop: '12px',
  },
  divider: {
    borderColor: customTheme.custom.black.B10,
  },
};
