export const styles = {
  wrap: (isBuyer: boolean) => ({
    height: `calc(100dvh - ${isBuyer ? '102px' : '54px'})`,
    boxSizing: 'border-box',
  }),
  loaderWrap: {
    height: 'calc(100svh - 120px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
