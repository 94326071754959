import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  deletButton: {
    height: '18px',
    minWidth: 'none',
    padding: 0,
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    color: customTheme.custom.error,
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
};
