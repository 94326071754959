import { useCallback } from 'react';

import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { NOT_FOUND_ERROR_CODE } from 'app/api/errors';
import { deleteDocumentFolder } from 'app/api/orders/service';
import { FOLDER_NOT_FOUND_ERROR } from 'pages/OrderDetailsPage/constants';
import { notificationObserver } from 'utils/observer';

export const useOrderDocumentCardMenu = (id: number, handleCloseModal: () => void) => {
  const queryClient = useQueryClient();

  const { mutate: removeFolder, isLoading } = useMutation(() => deleteDocumentFolder(id), {
    onSuccess: () => {
      handleCloseModal();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['order-documents-folders'] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['order'] });
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === NOT_FOUND_ERROR_CODE) {
        notificationObserver.publish({
          type: 'error',
          title: FOLDER_NOT_FOUND_ERROR,
        });
      }
    },
  });

  const handleRemove = useCallback(() => {
    removeFolder();
  }, [removeFolder]);

  return { isLoading, handleRemove };
};
