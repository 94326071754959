import { FC } from 'react';

import { Box } from '@mui/material';

import { CommonButton, LoadingButton } from 'components';
import { styles } from './styles';

interface CreateOrderActionsProps {
  isLoading: boolean;
  handleCancel: () => void;
}

export const CreateOrderActions: FC<CreateOrderActionsProps> = ({ isLoading, handleCancel }) => {
  return (
    <Box sx={styles.actions}>
      <CommonButton disableRipple variant="text" color="inherit" sx={styles.cancelButton} onClick={handleCancel}>
        Cancel
      </CommonButton>
      <LoadingButton
        color="secondary"
        type="submit"
        loading={isLoading}
        sx={styles.submitButton}
        data-testid={isLoading ? 'loading' : 'submit'}
      >
        Submit order
      </LoadingButton>
    </Box>
  );
};
