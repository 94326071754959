import { FC, useCallback } from 'react';

import { Control } from 'react-hook-form';
import { generatePath, useNavigate } from 'react-router-dom';
import { SxProps } from '@mui/material';

import { CompanyDetail } from 'app/api/company/types';
import { QuoteProduct, UnitsType } from 'app/api/quotes/types';
import { ROUTES } from 'app/routes/constants';
import { LoadingButton } from 'components';
import { useSendQuoteRequest } from '../../hooks/useSendQuoteRequest';
import { QuoteTableFormData } from '../../types';
import { styles } from './styles';

export interface CreateOrderWrapperProps {
  data: QuoteProduct[];
  unitsType: UnitsType;
  quoteId: number;
  control: Control<QuoteTableFormData>;
  isDisabled: boolean;
  seller: CompanyDetail | undefined;
  currentCycle: number;
  isSampleRequest: boolean;
  sx?: SxProps;
}

export const CreateOrderWrapper: FC<CreateOrderWrapperProps> = ({
  data,
  unitsType,
  quoteId,
  seller,
  currentCycle,
  control,
  isDisabled,
  isSampleRequest,
  sx = {},
}) => {
  const navigate = useNavigate();

  const handleAction = useCallback(() => {
    navigate(
      generatePath(ROUTES.buyer.placeOrder, {
        quoteId,
      }),
    );
  }, [navigate, quoteId]);

  const { isLoading, handleConfirm } = useSendQuoteRequest({
    control,
    currentCycle,
    data,
    isCreateOrder: true,
    isSampleRequest,
    quoteId,
    seller,
    unitsType,
    handleAction,
  });

  return (
    <LoadingButton
      loading={isLoading}
      disabled={isDisabled}
      color="secondary"
      sx={{ ...styles.createButton, ...sx }}
      onClick={handleConfirm}
    >
      Accept Quote
    </LoadingButton>
  );
};
