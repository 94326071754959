import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: {
    width: '41%',
    minWidth: '41%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
};
