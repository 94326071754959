import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  wrap: {
    maxHeight: '330px',
    overflowX: 'hidden',
    overflowY: 'auto',
    ...scrollMixin,
  },
};
