import { ChangeEvent, useCallback, useState } from 'react';

import { useWatch, Control } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { AdditionalFiltersControlNames } from '../../../constants';

interface UseSliderFilterProps {
  checkBoxControlName:
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY;
  control: Control<ProductsFiltersForm>;
  controlName: AdditionalFiltersControlNames.WEIGHT | AdditionalFiltersControlNames.MATURITY;
  defaultTitle: string;
  max: number;
  min: number;
  unit: string;
  handleChangeCheckBox: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const useSliderFilter = ({
  checkBoxControlName,
  control,
  controlName,
  defaultTitle,
  max,
  min,
  unit,
  handleChangeCheckBox,
}: UseSliderFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleIncompleteChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleChangeCheckBox(e);
      handleClose();
    },
    [handleChangeCheckBox, handleClose],
  );

  const currentVal: number[] = useWatch({
    control,
    name: controlName,
  });

  const includeProductsWithIncompleteDataValue = useWatch({ control, name: checkBoxControlName });

  const isDefaultValuesSelected = currentVal?.[0] === min && currentVal?.[1] === max;

  const filterTitle = () => {
    if (isDefaultValuesSelected && includeProductsWithIncompleteDataValue) {
      return defaultTitle;
    }

    return `${currentVal?.[0]} - ${currentVal?.[1]} ${unit}`;
  };

  return {
    anchorEl,
    currentValue: currentVal,
    filterTitle: typeof filterTitle === 'function' ? filterTitle() : filterTitle,
    includeProductsWithIncompleteDataValue,
    isSelected: !isDefaultValuesSelected,
    open,
    handleClick,
    handleClose,
    handleIncompleteChange,
  };
};
