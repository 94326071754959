import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import logoUrl from 'assets/images/logo.svg';
import { styles } from './styles';

interface AuthMainUiProps {
  children: ReactNode;
  isSmallMobPaddings?: boolean;
}

export const AuthMainUi: FC<AuthMainUiProps> = ({ children, isSmallMobPaddings = false }) => {
  return (
    <Box sx={{ ...styles.wrap }}>
      <Box sx={{ ...styles.logo }} component="img" src={logoUrl} />
      <Box sx={{ ...styles.formWrap(isSmallMobPaddings) }}>{children}</Box>
    </Box>
  );
};
