type ArrayElementType = string | number | boolean;

const compareFunction = (a: ArrayElementType, b: ArrayElementType) => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b);
  }

  return 0;
};

export const getIsArraysWithSameElements = (arr1: ArrayElementType[], arr2: ArrayElementType[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort(compareFunction);
  const sortedArr2 = arr2.slice().sort(compareFunction);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
