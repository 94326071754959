import { customerApi } from '../index';
import { apiRoutes } from '../apiRoutes';
import {
  Channel,
  CreateChannel,
  CreateChatTokenResponse,
  GetChatInfoDataRequest,
  GetChatsListRequest,
  MuteChannelRequest,
} from './types';

export const createChatToken = async () => {
  const response = await customerApi.post<CreateChatTokenResponse>(apiRoutes.chat.token);

  return response?.data;
};

export const getChatInfoData = async ({ id }: GetChatInfoDataRequest) => {
  const response = await customerApi.get<Channel>(apiRoutes.chat.byId(id)._);

  return response?.data;
};

export const muteChannel = async ({ params, id }: MuteChannelRequest) => {
  await customerApi.patch(apiRoutes.chat.byId(id).memberMuteStatus, { ...params });
};

export const getChatsList = async ({ params }: GetChatsListRequest) => {
  const response = await customerApi.get<Channel[]>(apiRoutes.chat._, { params });

  return response?.data;
};

export const createChannel = async (data: CreateChannel) => {
  await customerApi.post(apiRoutes.chat._, data);
};
