import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { layoutWidth, layoutMinWidth, layoutMaxWidth } from '../constants/styleConstants';

export const styles = {
  wrap: (visible: boolean) => ({
    position: 'sticky',
    top: '90px',
    right: 0,
    left: 0,
    width: layoutWidth,
    minWidth: layoutMinWidth,
    padding: '0 24px',
    visibility: visible ? 'visible' : 'hidden',
    transform: visible ? 'translateY(0)' : `translateY(-100%)`,
    backgroundColor: customTheme.custom.blue.B100,
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    zIndex: 3,
    transition: 'all 0.3s ease-out',
  }),

  toolBar: {
    height: '42px',
    width: '100%',
    maxWidth: layoutMaxWidth,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: '40px',
  },
  allItemsButton: {
    padding: '4px 0',
    minWidth: '92px',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '4px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: customTheme.custom.black.B70,
    textDecoration: 'none',
    cursor: 'pointer',
    '& path, & rect': {
      fill: customTheme.custom.black.B70,
    },
    ':hover': {
      color: customTheme.custom.black.B100,
      background: 'transparent',
      '& path, & rect': {
        fill: customTheme.custom.black.B100,
      },
    },
  },
  navItem: {
    a: {
      padding: '4px 8px',
      height: 'fit-content',
      gap: '4px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: customTheme.custom.black.B70,
      textDecoration: 'none',
      ':hover': {
        color: customTheme.custom.black.B100,
        background: 'transparent',
      },
    },
  },
  tooltip: {
    width: 'fit-content',
    maxHeight: 'calc(100vh - 200px)',
    margin: '8px 0 0 !important',
    padding: 0,
    fontFamily: 'inherit',
    backgroundColor: customTheme.custom.white,
    overflowY: 'auto',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
    ...scrollMixin,
  },
  buttonWrap: {
    padding: '14px 40px 14px 14px',
    marginLeft: '-14px',
  },
};
