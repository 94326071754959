import { customTheme } from '../../theme/MuiThemeProvider/theme';

export const styles = {
  tooltip: {
    padding: '3px 6px',
    borderRadius: '3px',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.white,
    backgroundColor: customTheme.custom.blackWithOpacity,
  },
};
