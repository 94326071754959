import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  drawer: {
    '& .MuiPaper-root': {
      boxShadow: 'none',
      height: '100%',
      width: '100%',
    },
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  container: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
  },
  titleContainer: {
    position: 'relative',
    margin: '64px 32px 32px',
    width: '100%',
    boxSizing: 'border-box',
  },
  title: {
    color: customTheme.palette.info.main,
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'center',
  },
  close: {
    position: 'absolute',
    left: '-8px',
    top: '0px',
    bottom: '0px',
    margin: '0px',
    padding: '0px',
    minWidth: '32px',
    height: '32px',
    svg: {
      path: { fill: customTheme.palette.info.main },
    },
  },
  orginalText: {
    backgroundColor: customTheme.palette.primary.P50,
    padding: '8px',
    borderRadius: '4px',
    color: customTheme.custom.white,
    width: 'fit-content',
    fontSize: '12px',
    fontWeight: 500,
  },
  current: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: '8px',
    marginBottom: '32px',
  },
  selected: {
    backgroundColor: '#97EBC8',
    padding: '4px 8px',
    borderRadius: '4px',
  },
  selectedMain: {
    color: '#358B67',
    fontSize: '16px',
    fontWeight: 500,
    marginRight: '2px',
  },
  selectedSub: {
    color: '#878787',
    fontSize: '16px',
    fontWeight: 500,
  },
  change: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  labelText: {
    color: customTheme.palette.info.main,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
  },
  bottom: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  confirm: {
    margin: '32px',
  },
  amountField: {
    width: '100%',
  },
};
