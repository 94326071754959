import { FC } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import { Product } from 'app/api/products';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { CompanyProductCard } from '../CompanyProductCard';
import { styles } from './styles';

interface ProductListAccordionProps {
  data: Product[];
  choosedProductsKeys: Set<number>;
  isSampleRequest: boolean;
  setChoosedProducts: (value: Product) => void;
}

export const ProductListAccordion: FC<ProductListAccordionProps> = ({
  data,
  choosedProductsKeys,
  isSampleRequest,
  setChoosedProducts,
}) => {
  return (
    <Accordion sx={styles.wrap}>
      <AccordionSummary
        expandIcon={<ArrowIcon />}
        data-testid="full-inventory-accordion"
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Full Inventory
      </AccordionSummary>
      <AccordionDetails sx={styles.details} data-testid="accordion-details">
        {data
          .filter((item) => item.inStock)
          .map((product, index) => (
            <CompanyProductCard
              key={product.id}
              data={product}
              choosedProductsKeys={choosedProductsKeys}
              isLast={index === data.length - 1}
              isSampleRequest={isSampleRequest}
              setChoosedProducts={setChoosedProducts}
            />
          ))}
        {data
          .filter((item) => !item.inStock)
          .map((product, index) => (
            <CompanyProductCard
              key={product.id}
              data={product}
              choosedProductsKeys={choosedProductsKeys}
              isLast={index === data.length - 1}
              isSampleRequest={isSampleRequest}
              setChoosedProducts={setChoosedProducts}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};
