import { FC, ReactNode } from 'react';

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { styles } from './styles';

interface CanceledSectionProps {
  children: ReactNode;
}

export const CanceledSection: FC<CanceledSectionProps> = ({ children }) => {
  return (
    <Accordion sx={{ ...styles.wrap }}>
      <AccordionSummary expandIcon={<ArrowDownIcon />} sx={{ ...styles.summary }}>
        Canceled
      </AccordionSummary>
      <AccordionDetails sx={{ ...styles.details }}>{children}</AccordionDetails>
    </Accordion>
  );
};
