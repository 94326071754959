import { CSSProperties, FC } from 'react';
import { Link, generatePath } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { QuoteProduct } from 'app/api/quotes';
import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import { ROUTES } from 'app/routes/constants';
import { useImageHandlers } from 'hooks';
import { getVarietyValue } from 'utils/getVarietyValue';
import { styles } from './styles';

interface ProductVarietyProps {
  isThousandSeedsUnit?: boolean;
  data?: QuoteProduct;
  fullWidth?: boolean;
}

export const ProductVariety: FC<ProductVarietyProps> = ({
  data,
  isThousandSeedsUnit = false,
  fullWidth = false,
}): JSX.Element => {
  const { handleImageError } = useImageHandlers();

  if (!data) return <Box>no data</Box>;

  const varietyValue = getVarietyValue({
    crop: data.product.crop,
    variety: data.product.variety,
    name: data.product.name,
  });

  const imageSrc = data.product.thumbnail || data.product.image || imagePlaceholder;
  const productPath = generatePath(ROUTES.common.product, { id: data.product.id });

  return (
    <Link to={productPath} target="_blank" style={styles.link as CSSProperties}>
      <Box sx={{ ...styles.varietyWrap(fullWidth) }}>
        <Box
          component="img"
          loading="lazy"
          src={imageSrc}
          sx={{ ...styles.productImage }}
          onError={handleImageError(imagePlaceholder)}
        />

        <Box sx={styles.dataWrap}>
          <Typography sx={{ ...styles.crop }}>{data.product.crop}</Typography>
          <Typography sx={{ ...styles.variety(fullWidth, isThousandSeedsUnit) }}>
            {varietyValue}
            {data.product.variety && '\u00A0'}
            {data.product.name ?? ''}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};
