import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { SBChatMode } from 'app/api/SBChat';
import { ReactComponent as CloseIcon } from 'assets/icons/closeSmall.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowDown.svg';
import { NotificationsSettings } from '../NotificationsSettings';
import { styles } from './styles';

interface SBChannelHeaderPlaceholderProps {
  channelUrl: string;
  companyName: string;
  mode: SBChatMode;
  expanded?: boolean;
  handleClose?: () => void;
}

export const SBChannelHeaderPlaceholder: FC<SBChannelHeaderPlaceholderProps> = ({
  channelUrl,
  companyName,
  mode,
  expanded,
  handleClose,
}) => {
  const isOrderChatMode = mode === SBChatMode.ORDER_DETAILS;

  return (
    <Box sx={styles.wrap(isOrderChatMode)}>
      <Typography sx={styles.title(isOrderChatMode)}>Chat</Typography>

      <Box sx={styles.actionsWrap}>
        <NotificationsSettings companyName={companyName} channelUrl={channelUrl} />
        {handleClose && (
          <Box sx={styles.iconWrap}>
            {isOrderChatMode ? (
              <Box sx={styles.arrowIconWrap(!!expanded)}>
                <ArrowIcon onClick={handleClose} />
              </Box>
            ) : (
              <CloseIcon onClick={handleClose} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
