import { FC } from 'react';

import { Product } from 'app/api/products/types';
import { useDeviceType } from 'hooks';
import { ProductCard } from '../ProductCard';
import { BaseListWrapper, InfinityListWrapper } from './components';
import { styles } from './styles';

interface ProductsListProps {
  data: Product[];
  isLoading: boolean;
  cardWidth?: string;
  columnCount?: number;
  hasNextPage?: boolean;
  hideProductCardFooter?: boolean;
  mobileCardOrientation?: 'vertical' | 'horizontal';
  loadMore: () => void;
}

export const ProductsList: FC<ProductsListProps> = ({
  data,
  isLoading,
  cardWidth = '100%',
  columnCount,
  hasNextPage = true,
  hideProductCardFooter = false,
  mobileCardOrientation = 'vertical',
  loadMore,
}): JSX.Element => {
  const { isMobileSize } = useDeviceType();

  const ListWrapper = isMobileSize ? InfinityListWrapper : BaseListWrapper;

  return (
    <ListWrapper
      columnCount={columnCount}
      dataLength={data.length}
      hasNextPage={hasNextPage}
      isLoading={isLoading}
      isButton={hasNextPage}
      onLoadMore={loadMore}
    >
      {data.map((product) => (
        <ProductCard
          key={product.id}
          sx={{ ...styles.cardWrap(cardWidth) }}
          {...product}
          mobileCardOrientation={mobileCardOrientation}
          hideMobileFooter={hideProductCardFooter}
        />
      ))}
    </ListWrapper>
  );
};
