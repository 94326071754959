import { FC, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { useMutation } from 'react-query';

import { CommonModal } from 'components/CommonPopup';
import { TextFormField } from 'components/TextFormField';
import { CommonButton } from 'components/CommonButton';
import { requiredFieldValidation } from 'app/validation/validation';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { postPublicForm } from 'app/api/publicForm';
import { notificationObserver } from 'utils/observer';
import { globalMessages } from 'utils/messages';

import { styles } from './styles';

const messages = {
  title: 'Get Help',
  description: 'Have a question or concern? Contact support.',
};
type GetHelpModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

type HelpForm = {
  name: string;
  email: string;
  companyName: string;
  whatsapp?: string;
  body: string;
};

const validationRules = {
  name: { required: requiredFieldValidation },
  email: { required: requiredFieldValidation },
  companyName: { required: requiredFieldValidation },
  body: { required: requiredFieldValidation },
};

export const GetHelpModal: FC<GetHelpModalProps> = ({ isOpen, handleClose }) => {
  const [formError, setFormError] = useState('');

  const { mutate: handlePostForm } = useMutation(async (data: HelpForm) => {
    try {
      await postPublicForm({ title: 'Get Help Form', ...data });
      notificationObserver.publish({
        type: 'success',
        title: globalMessages.thankYouForm,
      });
    } catch (e) {
      notificationObserver.publish({
        type: 'error',
        title: `Unable to send message`,
      });
    }
  }, {});

  const onSubmit = (data: HelpForm) => {
    handlePostForm(data);
    handleClose();
  };

  const onChange = () => {
    if (formError) {
      setFormError('');
    }
  };

  const { control, handleSubmit } = useForm<HelpForm>({
    defaultValues: {
      name: '',
      email: '',
      companyName: '',
      whatsapp: '',
      body: '',
    },
  });
  const isFormError = !!formError;

  if (!isOpen) return null;
  return (
    <CommonModal
      paperStyles={styles.modalPaper as SxPropsTypes}
      title={messages.title}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Typography sx={styles.description}>{messages.description}</Typography>
      <Box sx={styles.form}>
        <form onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
          <TextFormField
            isFormError={isFormError}
            control={control}
            name="name"
            rules={validationRules.name}
            placeholder="Name"
            autoComplete="on"
          />
          <TextFormField
            isFormError={isFormError}
            control={control}
            name="email"
            rules={validationRules.email}
            placeholder="Email"
            autoComplete="on"
          />
          <TextFormField
            isFormError={isFormError}
            control={control}
            name="companyName"
            rules={validationRules.companyName}
            placeholder="Company Name"
            autoComplete="on"
          />
          <TextFormField
            isFormError={isFormError}
            control={control}
            name="whatsapp"
            placeholder="WhatsApp (Optional)"
            autoComplete="on"
          />
          <TextFormField
            isFormError={isFormError}
            control={control}
            name="body"
            rules={validationRules.body}
            placeholder="What's on your mind?"
            autoComplete="on"
            rows={5}
            multiline
            inputWrapStyles={styles.body as SxPropsTypes}
          />
          <CommonButton variant="contained" color="primary" type="submit" fullWidth sx={styles.confirm}>
            Send
          </CommonButton>
        </form>
      </Box>
    </CommonModal>
  );
};
