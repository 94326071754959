import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { CommonButton } from '../../../../components/CommonButton';
import { CommonModal } from '../../../../components/CommonPopup';
import { LoadingButton } from '../../../../components/LoaderButton';
import { styles } from './styles';

interface RemoveProductPopupProps {
  title: string;
  handleClose: () => void;
  isOpen: boolean;
  handleRemove: () => void;
  isLoading: boolean;
}

export const RemoveProductPopup: FC<RemoveProductPopupProps> = ({
  title,
  handleClose,
  handleRemove,
  isOpen = false,
  isLoading,
}) => {
  return (
    <CommonModal title={title} isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        Are you sure you want to remove the selected product from the Quote?
      </Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <LoadingButton
          loading={isLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.removeButton }}
          onClick={handleRemove}
        >
          Remove
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
