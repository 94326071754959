import { useQuery } from 'react-query';

import { defaultQueryStaleTime } from 'app/api';
import { getPriceHistory } from 'app/api/quotes';

export const usePriceHistory = (quoteId: number) => {
  const { data, isLoading } = useQuery(['price-history'], () => getPriceHistory({ quoteId }), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: true,
    staleTime: defaultQueryStaleTime,
  });

  return {
    data,
    isLoading,
  };
};
