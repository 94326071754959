import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '24px',
    padding: '16px',
    background: customTheme.palette.secondary.S100,
    borderRadius: '10px',
    overflow: 'hidden',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '16px',
      gridRow: 3,
    },
  },
  title: {
    marginBottom: '2px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.custom.white,
  },
  subtitle: {
    marginBottom: '4px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.white,
  },
  description: (isMarginBottom: boolean) => ({
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.palette.secondary.S20,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
      lineHeight: '12px',

      ...(!!isMarginBottom && {
        marginBottom: '24px',
      }),
    },
  }),
  didvider: {
    margin: '12px 0',
    borderColor: customTheme.palette.secondary.S70,
  },
  subheader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      alignItems: 'start',
    },
  },
  copyButton: {
    padding: '0 8px',
    marginRight: '-8px',
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    color: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'unset',
      width: '32px',
      height: '32px',
      padding: '6px',
      marginTop: '-8px',
      marginRight: '-6px',

      svg: {
        width: '20px',
        height: '20px',
      },
    },
  },
  copyText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.white,
    letterSpacing: '0.2px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  list: {
    marginTop: '12px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      background: customTheme.palette.secondary.S80,
      gridTemplateColumns: '1fr ',
      margin: '-16px',
      padding: '16px',
    },
  },
  label: {
    marginBottom: '2px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.palette.primary.P5,
    whiteSpace: 'nowrap',
  },
  value: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.palette.secondary.S20,
    letterSpacing: '0.15px',
  },
};
