import { FC } from 'react';

import { Badge, Box, Divider, Typography } from '@mui/material';
import { GroupChannel } from '@sendbird/chat/lib/__definition';

import { ReactComponent as MutedIcon } from 'assets/icons/muted.svg';
import { Channel } from 'app/api/SBChat';
import { useSBChannelPreview } from './hooks';
import { styles } from './styles';

interface SBChannelPreviewProps {
  channel: GroupChannel;
  isSelected: boolean;
  channelData?: Channel;
  handleSelectChannel: (id: string) => void;
}

export const SBChannelPreview: FC<SBChannelPreviewProps> = ({
  channel,
  isSelected,
  channelData,
  handleSelectChannel,
}) => {
  const {
    channelLogo,
    channelName,
    counter,
    isLogoPlaceholder,
    isMuted,
    lastMessageBody,
    lastMessageDate,
    lastMessageTime,
    handleClick,
  } = useSBChannelPreview({
    channel,
    channelData,
    handleSelectChannel,
  });

  return (
    <>
      <Box sx={styles.wrap(isSelected)} onClick={handleClick}>
        <Box sx={styles.logoWrap}>
          {isLogoPlaceholder ? (
            <Box sx={styles.logoPlaceholder}>
              <Box component="img" loading="lazy" src={channelLogo} sx={styles.logo} />
            </Box>
          ) : (
            <Box component="img" loading="lazy" src={channelLogo} sx={styles.logo} />
          )}
        </Box>
        <Box sx={styles.content}>
          <Box sx={styles.header}>
            <Typography sx={styles.name(isMuted)}>{channelName}</Typography>
            <Box sx={styles.dateWrap}>
              <Typography component="span" sx={{ ...styles.date(!!counter && !isMuted), ...styles.time }}>
                {lastMessageTime}
              </Typography>
              <Typography component="span" sx={{ ...styles.date(!!counter && !isMuted), ...styles.day }}>
                {lastMessageDate}
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.contentInner}>
            <Typography sx={styles.message(!!counter, isMuted)}>{lastMessageBody}</Typography>

            <Box sx={styles.iconsWrap}>
              {isMuted && <MutedIcon />}
              {!!counter && (
                <Box sx={styles.badgeWrap}>
                  <Badge
                    badgeContent={counter}
                    color="primary"
                    max={9}
                    sx={{
                      ...styles.badge(isMuted),
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};
