import { FC, useCallback } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { ReactComponent as MapIcon } from 'assets/icons/map.svg';
import { CommonButton } from 'components';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { styles } from './styles';

const VIEW_SHIPMENT_TRACKING = 'View Shipment Tracking';

interface ViewShipmentButtonProps {
  orderShipmentId: string | number;
}

export const ViewShipmentButton: FC<ViewShipmentButtonProps> = ({ orderShipmentId }) => {
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const path = generatePath(ROUTES.common.shipmentTracking, { id: orderShipmentId });

  const handleShipmentTrackingClick = useCallback(() => {
    handleTrackLinkClickEvent({ path, text: VIEW_SHIPMENT_TRACKING, type: AnalyticsLinkType.BUTTON });

    navigate(path);
  }, [handleTrackLinkClickEvent, path, navigate]);

  return (
    <CommonButton sx={styles.button} onClick={handleShipmentTrackingClick}>
      <MapIcon />
      {VIEW_SHIPMENT_TRACKING}
    </CommonButton>
  );
};
