import { UnitsType } from 'app/api/quotes/types';

const getUnitValue = (value: UnitsType) => {
  switch (value) {
    case UnitsType.KG:
      return 'KG';
    case UnitsType.LB:
      return 'LB';
    case UnitsType.MT:
      return 'MT';
    default:
      return 'Thousand Seeds';
  }
};

export const getUnitDisplayValue = (value: UnitsType, isLowerCase?: boolean) => {
  return isLowerCase ? getUnitValue(value).toLocaleLowerCase() : getUnitValue(value);
};
