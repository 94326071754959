import { FC, Fragment } from 'react';

import { NavLink } from 'react-router-dom';
import { Box } from '@mui/material';

import { ROUTES } from 'app/routes/constants';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { styles } from './styles';

const links = [
  { to: ROUTES.common.about, text: 'About' },
  { to: ROUTES.common.contact, text: 'Contact us' },
  { to: ROUTES.common.faq, text: 'FAQ' },
  { to: ROUTES.common.privacyPolicy, text: 'Privacy Policy' },
  { to: ROUTES.common.termsOfService, text: 'Terms of Service' },
];

export const FooterLinks: FC = () => {
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  return (
    <Box component="nav" sx={{ ...styles.navWrap }}>
      {links.map((link, index) => (
        <Fragment key={link.to}>
          <Box sx={{ ...styles.navItem }}>
            <NavLink
              to={link.to}
              onClick={() => handleTrackLinkClickEvent({ path: link.to, text: link.text, type: AnalyticsLinkType.NAV })}
            >
              {link.text}
            </NavLink>
          </Box>
          {index !== links.length - 1 && <Box sx={{ ...styles.divider }} />}
        </Fragment>
      ))}
    </Box>
  );
};
