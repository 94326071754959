import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { Member } from '@sendbird/chat/lib/__definition';

import { InfoMembersList } from '../InfoMembersList';
import { styles } from './styles';

interface ConversationInfoMembersSectionProps {
  currentCompanyName: string;
  currentCompanyMembers: Member[];
  membersCount: number;
  otherCompanyName: string;
  otherCompanyMembers: Member[];
}

export const ConversationInfoMembersSection: FC<ConversationInfoMembersSectionProps> = ({
  currentCompanyName,
  currentCompanyMembers,
  membersCount,
  otherCompanyName,
  otherCompanyMembers,
}) => {
  return (
    <Box sx={styles.memberswrap}>
      <Typography sx={styles.title}>{membersCount} Members</Typography>
      <InfoMembersList
        currentCompanyName={currentCompanyName}
        currentCompanyMembers={currentCompanyMembers}
        otherCompanyName={otherCompanyName}
        otherCompanyMembers={otherCompanyMembers}
      />
    </Box>
  );
};
