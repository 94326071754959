import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  inputContainer: {
    position: 'relative',
    zIndex: 1,
    '.base-Input-input': {
      fontFamily: customTheme.typography.allVariants,
      boxSizing: 'border-box',
      outline: 'unset',
      paddingBlock: 'unset',
      paddingInline: 'unset',

      padding: '5px 72px 5px 16px',
      borderRadius: '25px',

      height: '40px',
      width: '320px',
      border: `1px solid ${customTheme.custom.black.B10}`,
      fontSize: '14px',
      color: customTheme.custom.black.B100,

      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        width: '100%',
      },

      '::placeholder': {
        color: customTheme.custom.black.B50,
        opacity: 1,
      },
    },
  },
  endAdornment: {
    margin: '0 16px 0 0',
    cursor: 'pointer',

    '&.MuiInputAdornment-positionEnd': {
      ':hover': {
        color: customTheme.custom.black.B100,
      },
    },
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '16px',
    top: 0,
    bottom: 0,
    color: 'rgb(37, 39, 40)',
    [`@media (min-width: ${breakpoints.tablet}px)`]: {
      ':hover': {
        color: customTheme.custom.black.B50,
      },
    },
  },
  closeButton: {
    position: 'absolute',
    right: '40px',
    top: 0,
    bottom: 0,
    color: customTheme.custom.neutralLight,
  },
  container: {
    position: 'relative',
  },
  popper: {
    width: '100%',
    animation: `${customTheme.animations.fadeIn} 0.1s ease-in-out`,
  },
  resultsList: {
    animation: `${customTheme.animations.height} 0.1s ease-in-out`,
    listStyleType: 'none',
    margin: 0,
    marginTop: '-20px',
    padding: '28px 8px 8px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.white,
    border: `1px solid ${customTheme.custom.black.B5}`,
    borderRadius: '4px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
  },
  result: {
    padding: '4px 8px',
    borderRadius: '4px',
    transition: 'all 0.3s ease-in-out',
    fontWeight: 500,
    fontSize: '14px',
    color: customTheme.custom.black.B70,

    ':hover': {
      backgroundColor: customTheme.custom.black.B10,
      cursor: 'pointer',
    },

    '&[aria-selected=true]': {
      backgroundColor: customTheme.custom.black.B10,
    },
    '&.Mui-focused, &.Mui-focusVisible': {
      backgroundColor: customTheme.custom.black.B10,
    },
  },
};
