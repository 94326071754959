import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  wrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  filtersWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  filters: {
    display: 'flex',
    gap: '12px',

    [`@media (max-width: 1320px)`]: {
      maxWidth: 'fit-content',
      gap: '10px',
    },

    '.MuiButtonBase-root.MuiButton-root': {
      padding: '6px 10px 6px 12px',
      boxSizing: 'border-box',
    },
  },
  clearAllButton: {
    marginLeft: '2px',
    padding: '6px 12px',
    lineHeight: '14px',
    textAlign: 'left',
    width: 'fit-content',
    minWidth: '92px',

    '.MuiTouchRipple-root': {
      minWidth: 'unset',
    },

    [`@media (max-width: 1320px)`]: {
      padding: '6px',
      minWidth: '78px',
    },
  },
  requestContainer: {
    [`@media (max-width: 1320px)`]: {
      display: 'none',
    },
    display: 'flex',
    position: 'relative',
  },
  cantFind: {
    position: 'absolute',
    top: '-20px',
    right: '8px',
    fontSize: '12px',
    fontWeight: 400,
    color: '#868585',
  },
  requestBtn: {
    backgroundColor: '#c67edf',
    ':hover': {
      backgroundColor: '#c67edf !important',
    },
    ':active': {
      backgroundColor: '#c67edf !important',
    },
  },
};
