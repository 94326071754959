import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CustomQuoteCardStatus, DashboardTabs, QuoteCardStatuses } from 'app/api/quotes/types';
import { UserRole } from 'app/api/auth';
import { CompanyDetail } from 'app/api/company';
import { QuoteStatusTag } from '../QuoteStatusTag';
import { QuoteCardProps } from './index';
import { QuoteProductsList } from './QuoteProductsList';
import { QuoteTotal } from './QuoteTotal';
import { QuoteCompany } from './QuoteCompany';
import { styles } from './styles';

interface QuoteCardContentProps
  extends Pick<QuoteCardProps, 'products' | 'isNew' | 'status' | 'totalQuantity' | 'isBuyer' | 'quote'> {
  company: Pick<CompanyDetail, 'id' | 'name' | 'address' | 'displayId'>;
  tab: DashboardTabs;
}

export const QuoteCardContent: FC<QuoteCardContentProps> = ({
  company,
  isNew,
  products,
  status,
  tab,
  isBuyer,
  quote,
  totalQuantity,
}) => {
  let quoteTagStatus = status;

  // waitAnswer === BUYER means that the buyer is waiting for the seller to make an offer
  if (status === QuoteCardStatuses.OPEN && !isNew) {
    if ((isBuyer && quote?.waitAnswer === UserRole.BUYER) || (!isBuyer && quote?.waitAnswer === UserRole.SELLER)) {
      quoteTagStatus = CustomQuoteCardStatus.WAITING_RESPONSE;
    } else if (isBuyer) {
      quoteTagStatus = CustomQuoteCardStatus.NEW_RESPONSE;
    } else {
      quoteTagStatus = CustomQuoteCardStatus.NEW_REQUEST;
    }
  }

  return (
    <Box sx={{ ...styles.content }}>
      <Box sx={{ ...styles.header }}>
        <Typography component="h3" sx={{ ...styles.title }}>
          Seed Types
        </Typography>
        <Box sx={{ ...styles.tagsWrap }}>
          {!!quoteTagStatus && <QuoteStatusTag status={quoteTagStatus} />}
          {isNew && <QuoteStatusTag status={QuoteCardStatuses.NEW} />}
        </Box>
      </Box>

      <Box sx={styles.companyMobileWrap}>
        <QuoteCompany company={company} />
      </Box>

      <QuoteProductsList products={products} />

      <Box sx={styles.quoteTotalWrap}>
        <QuoteTotal totalQuantity={totalQuantity} tab={tab} />
      </Box>
    </Box>
  );
};
