import { FC, useState } from 'react';

import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { ReactComponent as ExpandMoreIcon } from 'assets/icons/expandMore.svg';

import { styles } from './styles';

const messages = {
  title: 'We’re here to help',
  items: [
    {
      title: 'What is Sproutzo?',
      text:
        'Sproutzo is a trading hub for the global commercial agriculture industry. ' +
        'We specialize in international trade of bulk agricultural products and operate one ' +
        'of the largest independent databases of commercially available crop ' +
        'varieties around the world. Within agriculture, we specialize in crop ' +
        'seeds, but can and often do help with other commodity trades.',
    },
    {
      title: 'Is Sproutzo built for business-to-business trade?',
      text:
        'Sproutzo is designed for professionals within the commercial agriculture industry. ' +
        'We welcome everyone involved in large-scale agriculture trade, including from producers, ' +
        'breeders, distributors, wholesalers, to breeders and importers, food production companies, ' +
        'seed packet companies, farmer co-ops, distributors, and more.',
    },
    {
      title: 'How do I register to join Sproutzo?',
      text:
        'Registering for Sproutzo is easy! Simply sign up here, and we’ll get back to you ASAP. ' +
        'We strive to answer every join request within 48-hours, but access may at times take longer, pending demand.',
    },
    {
      title: 'What products can I trade on Sproutzo?',
      text:
        'Sproutzo currently specializes in bulk seeds, but we will soon be expanding to other ' +
        'agri-food related categories. Keep an eye out for new products on the platform!',
    },
    {
      title: 'How does Sproutzo ensure quality on the platform?',
      text:
        'We take quality seriously at Sproutzo. Each supplier joins from an invite-only waitlist, ' +
        'most coming from pre-existing relationships we have from years of working in the seed industry. ' +
        'We also strive to visit as many suppliers in person as possible.',
    },

    {
      title: "What if I can't find the product I'm looking for?",
      text:
        "If you can't find the product you need on Sproutzo, just let us know! Our team will work hard to " +
        "find the product you're looking for and bring it onto the platform. Simply reach out to us at " +
        'hello@sproutzo.com to request any product you can’t find.',
    },

    {
      title: 'How does Sproutzo make money?',
      text:
        'Sproutzo takes a small commission on new sales made through our platform. This commission is ' +
        'charged only to the seller and ensures that we can continue to improve our services and support ' +
        'our users in the best way possible.',
    },
    {
      title: 'Where is the Sproutzo team based?',
      text:
        'Our headquarters are located in the heart of New York City. However, we have team members all ' +
        'around the world, working hard to provide the best service to our users.',
    },
    {
      title: 'What payment terms does Sproutzo offer?',
      text:
        'To ensure secure transactions and prompt business dealings, our standard payment terms involve ' +
        'upfront escrow payments from the buyer, released to the supplier at agreed-upon intervals as they ' +
        "ship and deliver the product. However, we understand that every deal is unique, and we're always " +
        'willing to make exceptions when necessary. We also offer financing credit on a case-by-case basis. ' +
        'Get in touch with us at hello@sproutzo.com to ask about financing options.',
    },

    {
      title: 'Who handles freight for Sproutzo?',
      text:
        'At Sproutzo, we work closely with our partner freight forwarders to ensure safe and timely ' +
        'delivery of your cargo. We act as your point of contact throughout the process, handling delivery ' +
        'in a white-glove fashion and providing continuous updates as your products move across the world.',
    },

    {
      title: 'How do I sign up to sell on Sproutzo?',
      text:
        'Though we currently have a waitlist to join as a supplier, we’re always looking for exciting new ' +
        'seller partner brands to collaborate with. If you’d like to offer your products on Sproutzo, get ' +
        'in touch at hello@sproutzo.com',
    },
  ],
};
export const QuestionAnswer = ({ title, text }: { title: string; text: string }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={styles.questionAnswer}>
      <Box sx={styles.questionAnswerTitleContainer} onClick={handleExpandClick}>
        <Typography component="h3" sx={styles.questionAnswerTitle}>
          {title}
        </Typography>
        <IconButton aria-label="expand" sx={styles.expandIcon(expanded)}>
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Typography component="p" sx={styles.questionAnswerText}>
          {text}
        </Typography>
      </Collapse>
    </Box>
  );
};
export const FAQPage: FC = (): JSX.Element => {
  return (
    <Box sx={styles.pageWrap}>
      <Typography sx={styles.title}>{messages.title}</Typography>
      <Box sx={styles.questionContainer}>
        {messages.items.map((item) => (
          <QuestionAnswer {...item} key={item.title} />
        ))}
      </Box>
    </Box>
  );
};
