export const styles = {
  paper: {
    minWidth: '862px',
    padding: 0,
  },
  mobilePaper: {
    margin: 0,
    padding: 0,
    minWidth: 'unset',
    width: 'calc(100% - 28px)',
    maxWidth: 'calc(100% - 28px)',
  },
};
