import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import { OrderStatus } from 'app/api/orders/types';
import { CompanyDetail } from 'app/api/company/types';
import { ChatFormData } from 'app/api/SBChat';
import { useModal } from 'hooks';
import { useCancelOrderMutation, useSendMessage } from '../../hooks';

const ALLOWED_STATUSES_TO_CANCEL = [
  OrderStatus.AWAITING_FREIGHT_QUOTE,
  OrderStatus.DOCUMENTS_IN_PROGRESS,
  OrderStatus.PAYMENT_IN_PROGRESS,
];

interface OrderInfoI {
  channel: string;
  status: OrderStatus;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  seller: CompanyDetail;
}

export const useOrderInfo = ({ channel, status, orderId, quoteId, isSampleRequest, seller }: OrderInfoI) => {
  const queryClient = useQueryClient();
  const {
    open: isCancelModalOpen,
    handleOpenModal: handleOpenCancelModal,
    handleCloseModal: handleCloseCancelModal,
  } = useModal();

  const { handleCancelOrder, isCancelOrderLoading } = useCancelOrderMutation({
    id: orderId,
    onSuccess: handleCloseCancelModal,
  });

  const { control, handleSubmit, watch, setValue } = useForm<ChatFormData>();

  const isCancelButton = ALLOWED_STATUSES_TO_CANCEL.includes(status);

  const message = watch('message');
  const files = watch('files');

  const isSendButtonDisabled = !message && !files?.length;

  const { handleConfirm, isSendMessageLoading, isMessageSuccessfullySent } = useSendMessage({
    channel,
    quoteId,
    orderId,
    isSampleRequest,
    control,
    seller,
    handleAction: () => {
      setValue('message', '');
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.resetQueries(['order-messages']);
    },
    handleClearMessage: () => {
      setValue('message', '');
    },
    handleUpdateFiles: (files: { file: File }[]) => {
      setValue('files', files);
    },
  });

  return {
    isCancelModalOpen,
    handleOpenCancelModal,
    handleCloseCancelModal,
    isCancelButton,
    isSendButtonDisabled,
    control,
    handleCancelOrder,
    isCancelOrderLoading,
    handleConfirm,
    isSendMessageLoading,
    isMessageSuccessfullySent,
    handleSubmit,
  };
};
