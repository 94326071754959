import { FC } from 'react';

import { Control, UseFormGetValues, UseFormReset, UseFormSetValue } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { FiltersNames, colorFilterAll } from '../../../../constants';
import { useCheckboxFilterPopup } from '../../hooks';
import { CheckboxOptionsWrap } from '../CheckboxOptionsWrap';
import { CommonFiltersDrawer } from '../CommonFiltersDrawer';
import { FilterListItem } from '../FilterListItem';

interface ColorFilterProps {
  control: Control<ProductsFiltersForm>;
  data: {
    value: string;
    label: string;
  }[];
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
  setValue: UseFormSetValue<ProductsFiltersForm>;
}

export const ColorFilter: FC<ColorFilterProps> = ({ control, data, reset, setValue, getValues }) => {
  const defaultTitle = 'Color';
  const controlName = FiltersNames.COLOR;
  const defaultFilter = colorFilterAll;

  const {
    counter,
    filterTitle,
    isFilterChanged,
    open,
    handleCloseModal,
    handleOpenModal,
    handleConfirm,
    resetFilters,
  } = useCheckboxFilterPopup({
    control,
    controlName,
    data,
    defaultFilter,
    defaultTitle,
    setValue,
    getValues,
    reset,
  });

  return (
    <>
      <FilterListItem filterValue={counter} label={filterTitle} onClick={handleOpenModal} />
      <CommonFiltersDrawer
        isOpen={open}
        disableConfirmButton={!isFilterChanged}
        title={defaultTitle}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
      >
        <CheckboxOptionsWrap
          data={data}
          defaultFilter={defaultFilter}
          control={control as unknown as Control}
          controlName={controlName}
          resetFilters={resetFilters}
        />
      </CommonFiltersDrawer>
    </>
  );
};
