export const numberExp = /^[0-9]{0,9}(\.[0-9]{0,2})?$/;
export const amountNumberExp = /^(0|[1-9]\d*)$/;
export const bigNumberExp = /^[0-9]{1,9}$/;

export const validationRules = {
  price: {
    required: true,
    validate: {
      isAboveZero: (value: string) => Number(value) > 0,
      isNumber: (value: string) => numberExp.test(value),
    },
  },
  amount: {
    required: true,
    validate: {
      isPositive: (value: string) =>
        Number(value) < 0 ? 'Please enter whole positive number greater than or equal to 0' : true,
      isNumber: (value: string) => (!amountNumberExp.test(value) ? 'Please use only numbers.' : true),
      isBigNumber: (value: string) => (!bigNumberExp.test(value) ? 'The number is too big' : true),
    },
  },
};
