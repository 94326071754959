import { FC, Fragment } from 'react';

import { Box, CircularProgress, Divider } from '@mui/material';

import { CropFamiliesData, CropFamily } from 'app/api/crops';
import { CategoryItem } from 'components';
import { SideBarSectionTag } from '../SideBarSectionTag';
import { styles } from './styles';

interface MobileProductsMenuProps {
  data: CropFamiliesData;
  isLoading: boolean;
  type: string | undefined;
  onMenuItemClick: (category: CropFamily) => () => void;
}

export const MobileProductsMenu: FC<MobileProductsMenuProps> = ({ data, isLoading, type, onMenuItemClick }) => {
  return (
    <>
      <SideBarSectionTag title="Products" />
      {isLoading ? (
        <Box sx={styles.loaderWrap}>
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Box sx={styles.list}>
          {data.map((category, index) => (
            <Fragment key={category.id}>
              <CategoryItem {...category} isActive={type === category.name} handleClick={onMenuItemClick(category)} />
              {index !== data.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Box>
      )}
    </>
  );
};
