import { FC, useCallback, useMemo, useState } from 'react';

import { Control, Controller, useWatch } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { CommonSlider } from 'components';
import { styles } from './styles';

interface ControlledSliderProps {
  control: Control;
  controlName: string;
  isRange: boolean;
  max: number;
  min: number;
  step: number;
  unit: string;
  handleChange: (newValue: number[]) => void;
  onChangeAction?: () => void;
}

export const ControlledSlider: FC<ControlledSliderProps> = ({
  control,
  controlName,
  isRange,
  max,
  min,
  step,
  unit,
  handleChange,
  onChangeAction,
}) => {
  const currentValues = useWatch({ control, name: controlName });
  const [displayRange, setDisplayRange] = useState(currentValues as number[]);

  const minDisplayValue = useMemo(() => displayRange?.[0] ?? min, [displayRange, min]);
  const maxDisplayValue = useMemo(() => displayRange?.[1] ?? max, [displayRange, max]);

  const handleDisplayRangeChange = useCallback((value: number[]) => {
    setDisplayRange(value);
  }, []);

  return (
    <Controller
      control={control}
      name={controlName}
      render={({ field: { value, ...restField } }) => {
        const values = value as number[];

        return (
          <>
            <Box sx={styles.header}>
              <Box sx={styles.valueWrap}>
                <Typography component="span" sx={styles.label}>
                  Minimum
                </Typography>
                <Typography component="span" sx={styles.value}>
                  {minDisplayValue}{' '}
                  <Typography component="span" sx={styles.unit}>
                    {unit}
                  </Typography>
                </Typography>
              </Box>
              <Box sx={{ ...styles.valueWrap, ...styles.valueWrapRight }}>
                <Typography component="span" sx={styles.label}>
                  Maximum
                </Typography>
                <Typography component="span" sx={styles.value}>
                  {maxDisplayValue}{' '}
                  <Typography component="span" sx={styles.unit}>
                    {unit}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            {isRange && (
              <Box sx={styles.sliderWrap}>
                <CommonSlider
                  {...restField}
                  max={max}
                  min={min}
                  step={step}
                  value={values}
                  onChange={handleChange}
                  onChangeCompleteAction={onChangeAction}
                  onDisplayRangeChange={handleDisplayRangeChange}
                />
              </Box>
            )}
          </>
        );
      }}
    />
  );
};
