import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './app/App';

// Add Object.hasOwn polyfill for react markdown to work with old browsers
if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
