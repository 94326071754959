import { ChangeEvent, FC } from 'react';

import { Control, Controller } from 'react-hook-form';
import { Switch } from '@mui/material';

import { NotificationsSettingsForm } from 'app/api/SBChat';

interface MuteSwitchControlProps {
  control: Control<NotificationsSettingsForm>;
  name: keyof NotificationsSettingsForm;
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const MuteSwitchControl: FC<MuteSwitchControlProps> = ({ control, name, value, onChange }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={() => <Switch size="small" checked={!!value} onChange={onChange} />}
    />
  );
};
