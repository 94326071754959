import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import logo from 'assets/images/headerLogo.svg';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { CommonModal } from 'components/CommonPopup';
import { useAuth } from 'hooks';

import { ProductRequest } from './components/productRequest';
import { styles } from './styles';
import { SecondPage } from './components/secondPage';
import { RequestProductsForm, useRequestProductsForm } from './components/form';

const messages = {
  welcome: 'Welcome to',
  description: 'Please login or request free access to view suppliers or request products.',
  requestAccess: 'Request Free Access',
  login: 'Login',
  note: 'Let us know what you need',
  terms: 'By requesting access you agree to our privacy policy.',
};

type RequestProductModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const RequestProductModal = ({ isOpen, handleClose }: RequestProductModalProps) => {
  const { activeUser } = useAuth();
  const [page, setPage] = useState<number>(1);
  const { form, onSubmit, handlePatchForm } = useRequestProductsForm();
  const onSubmitFirstPage = (data: RequestProductsForm) => {
    setPage((pg) => pg + 1);
    onSubmit(data);
  };
  const onSubmitSecondPage = (data: RequestProductsForm) => {
    handlePatchForm(data);
  };

  useEffect(() => {
    if (!isOpen) {
      setPage(1);
    }
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <CommonModal
      paperStyles={styles.modalPaper as SxPropsTypes}
      titleContainerStyles={styles.closeBtn as SxPropsTypes}
      isOpen={isOpen}
      handleClose={handleClose}
      withCloseButton
    >
      {page === 1 && (
        <>
          <Box sx={styles.topWelcome}>
            {!activeUser && <Typography sx={styles.welcome}>{messages.welcome}</Typography>}
            <Box component="img" src={logo} sx={styles.welcomeLogo} />
          </Box>
          <ProductRequest onSubmit={onSubmitFirstPage} form={form} />
        </>
      )}
      {page === 2 && <SecondPage handleClose={handleClose} form={form} onSubmit={onSubmitSecondPage} />}
    </CommonModal>
  );
};
