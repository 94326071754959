import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  header: {
    height: '64px',
    padding: '16px 24px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    background: customTheme.custom.white,
  },
  title: {
    width: 'fit-content',
    maxWidth: 'calc(100% - 160px)',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    color: customTheme.custom.black.B100,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: 'calc(100% - 72px)',
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  paper: {
    width: 'calc(100% - 160px)',
    height: 'calc(100% - 80px)',
    maxWidth: '1080px',
    minHeight: '680px',
    margin: '32px 80px',
    background: customTheme.custom.white,
    borderRadius: '10px',
  },
  paperMobile: {
    width: 'calc(100% - 48px)',
    height: 'fit-content',
    minHeight: '360px',
    maxHeight: '75%',
    margin: 0,
    borderRadius: '10px',
    background: customTheme.custom.white,
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  viewerWrap: {
    position: 'relative',
    width: '100%',
    height: '100%',
    color: customTheme.custom.black.B20,
    '& .viewer': {
      boxSizing: 'border-box',
      margin: 0,
      '& #pdf-download': {
        display: 'none',
      },
      '& #react-pdf__Document': {
        marginBottom: '64px',
      },
      '& #pdf-page-wrapper': {
        margin: 0,
      },

      '& #pdf-controls': {
        height: '48px',
        boxSizing: 'border-box',
        position: 'absolute',
        top: 'unset',
        bottom: 0,
        right: 0,
        left: 0,
        gap: '2px',
        alignItems: 'center',
        background: customTheme.custom.white,
        color: customTheme.custom.black.B100,
        order: 2,

        '& button': {
          width: '36px',
          height: '36px',
          padding: 0,
          background: 'transparent',
          boxShadow: 'none',
          cursor: 'pointer',
          borderRadius: '50%',

          ':hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },

        '& path': {
          color: customTheme.custom.black.B100,
          fill: 'currentColor',
        },

        '& #pdf-pagination': {
          '& #pdf-pagination-info': {
            fontWeight: '400',
            fontSize: '16px',
            lineHeight: '24px',
            color: customTheme.custom.black.B100,
          },

          '& #pdf-pagination-next': {
            marginRight: '16px',
          },

          '& svg': {
            width: '24px',
            height: '24px',
          },
        },

        '& #pdf-zoom-out, & #pdf-zoom-in': {
          '& svg, & g, & path': {
            width: '32px',
            height: '32px',
          },
        },
      },

      '& #image-renderer': {
        position: 'relative',
        width: '100%',
        height: 'fit-content',
        padding: '30px 60px',
        boxSizing: 'border-box',
        background: 'none',
        overflow: 'hidden',
        overflowY: 'auto',

        '& img': {
          minWidth: '100%',
          height: 'auto',
          objectFit: 'contain',
        },
      },

      '& #loading-renderer': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: 'fit-content',
      minHeight: '320px',

      '& #loading-renderer': {
        minHeight: '200px',
      },

      '& #react-pdf__Document': {
        marginLeft: '14px',
        marginRight: '14px',
      },

      '#image-renderer': {
        padding: '20px !important',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    width: '32px',
    height: '32px',
    padding: 0,

    '& svg': {
      width: '100%',
      height: '100%',

      '& path': {
        fill: customTheme.custom.black.B100,
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '24px',
      height: '24px',
    },
  },
  downloadButton: {
    minWidth: 'unset',
    marginLeft: '8px',
    padding: 0,
    width: '36px',
    height: '36px',
    color: customTheme.custom.black.B100,
    borderRadius: '50%',
    boxSizing: 'border-box',

    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },

    '& svg': {
      width: '100%',
      height: '100%',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '28px',
      height: '28px',
    },
  },
};
