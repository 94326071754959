import { Currency } from 'utils/currencyFormat';
import { UserRole } from '../auth/types';
import { CompanyDetail, ShipmentMethod } from '../company/types';
import { OrderStatus } from '../orders/types';
import { GeneticType } from '../products/types';

export interface UpdateQuoteData {
  status?: string;
  waitAnswer?: UserRole;
  incoterms?: ShipmentMethod;
  priceUnits?: string;
  metadata?: object;
}

export interface UpdateQuoteProduct {
  amount?: number;
  acceptedPrice?: number;
  status?: QuoteProductStatus;
}

export interface AddQuoteProduct {
  amount: number;
  amountType: UnitsType;
  product: number;
  quote: number;
}

export interface QuoteRequestData {
  sellerCompany?: number;
  buyerCompany?: number;
  products: {
    product: number;
    amount: number;
    amountType: UnitsType;
  }[];
  prices?: {
    productId: number;
    price: number;
  }[];
  unitsType?: UnitsType;
  isSampleType: boolean;
  incoterms?: ShipmentMethod;
  priceUnits: Currency;
}

export enum QuoteRequestStatus {
  OPEN = 'OPEN',
  CANCELED = 'CANCELED',
  ACCEPTED = 'ACCEPTED',
}

export enum QuoteProductStatus {
  AVAILABLE = 'AVAILABLE',
  REMOVED = 'REMOVED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  RESTRICTED = 'RESTRICTED',
}

export interface ProductNested {
  id: number;
  sku: string;
  image?: string | null;
  variety?: string;
  crop: string;
  name?: string | null;
  geneticType: GeneticType;
  color?: string | null;
  thumbnail?: string | null;
}

export interface NestedHistoryPrice {
  price: number;
  priceUnits: Currency;
  amount: number;
  amountType: UnitsType;
  status: QuoteProductStatus;
  is_buyer: boolean;
}

export interface QuoteProduct {
  id: number;
  amount: number;
  amountType: UnitsType;
  acceptedPrice?: number | null;
  acceptedPriceUnits?: Currency | null;
  product: ProductNested;
  counterOffer?: number;
  price?: number;
  totalCounterOffer?: number;
  totalPrice?: number;
  status?: QuoteProductStatus;
  latestBuyerPrice?: NestedHistoryPrice;
  latestSellerPrice?: NestedHistoryPrice;
}

export type QuoteTotalQuantity = {
  unit: UnitsType;
  quantity: number;
}[];

export type QuoteMetadata = {
  cancelActor: 'buyer' | 'seller';
};

export interface Quote {
  id: number;
  totalAmount: number;
  unitsType: UnitsType;
  totalPrice: number;
  buyer: CompanyDetail;
  seller: CompanyDetail;
  status: QuoteRequestStatus;
  quoteproducts: QuoteProduct[];
  waitAnswer: UserRole;
  priceUnits: Currency;
  isSampleType: boolean;
  createdAt?: string;
  highlight: boolean;
  chatId: string;
  incoterms?: ShipmentMethod;
  dealInteractionsCounter?: number;
  totalAttachmentsNumber?: number;
  totalMessagesNumber?: number;
  totalQuantity: QuoteTotalQuantity;
  metadata?: QuoteMetadata;
}

export interface PriceHistory {
  id: number;
  quoteProduct: number;
  price: number;
  priceUnits: Currency;
  amount: number;
  amountType: UnitsType;
  counterOffer: boolean;
  actor: number;
  status: QuoteProductStatus;
  isBuyer: boolean;
  iteration: number;
  createdAt: string;
  isAccepted: boolean;
}

export interface HistoryPrice {
  quoteProduct: number;
  price: number;
  priceUnits: string;
  amount: number;
  amountType: string;
  status: QuoteProductStatus;
  counterOffer: boolean;
  isAccepted: boolean;
  actor?: number;
}

export type PriceHistoryListData = PriceHistory[];

export type QuoteRequestsListData = Quote[];

export enum CustomQuoteCardStatus {
  NEW = 'new',
  NEW_REQUEST = 'new-request',
  NEW_RESPONSE = 'new-response',
  WAITING_RESPONSE = 'waiting-response',
}

export const QuoteCardStatuses = {
  ...QuoteRequestStatus,
  ...OrderStatus,
  ...CustomQuoteCardStatus,
};

export type QuoteCardStatusesType = QuoteRequestStatus | OrderStatus | CustomQuoteCardStatus;

export enum DashboardMainTabs {
  QUOTE_REQUESTS = 'quote-requests',
  OPEN_ORDERS = 'open-orders',
  EN_ROUTE = 'en-route',
  PAST_ORDERS = 'past-orders',
  SAMPLE_REQUESTS = 'sample-requests',
}

export enum SampleRequestsTabs {
  SAMPLE_REQUESTS = 'sample-requests',
  SAMPLE_SEND_IN_PROGRESS = 'sample-send-in-progress',
  SAMPLE_EN_ROUTE = 'sample-en-route',
  PAST = 'past',
}

export type DashboardTabs = DashboardMainTabs | SampleRequestsTabs;

export interface CreateQuoteResponse {
  seller: number;
  id: number;
}

export enum UnitsType {
  KG = 'KG',
  LB = 'LB',
  THOUSAND_SEEDS = 'THOUSAND_SEEDS',
  MT = 'MT',
}

export interface QuotesListRequest {
  requestSample?: boolean;
  status: string[];
  buyerCompanyId?: number;
  sellerCompanyId?: number;
}

export interface PriceHistoryListRequest {
  quoteId: number;
}
