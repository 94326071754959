import { useCallback } from 'react';

import { useModal } from 'hooks';

interface UseStickyFiltersBarProps {
  clearFilters: () => void;
}

export const useStickyFiltersBar = ({ clearFilters }: UseStickyFiltersBarProps) => {
  const {
    open: isFilterListOpen,
    handleCloseModal: handleCloseFilterList,
    handleOpenModal: handleOpenFilterList,
  } = useModal();

  const handleClearFilters = useCallback(() => {
    clearFilters();
  }, [clearFilters]);

  return {
    isFilterListOpen,
    handleOpenFilterList,
    handleCloseFilterList,
    handleClearFilters,
  };
};
