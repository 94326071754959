import { FC } from 'react';

import { Box } from '@mui/material';

import { OrdersListData } from 'app/api/orders/types';
import { QuoteRequestsListData, SampleRequestsTabs } from 'app/api/quotes/types';
import { useSampleRequestsTab } from '../../hooks';
import { SampleRequestsPageHeader } from '../SampleRequestsPageHeader';
import { QuoteRequestsTabInner } from '../QuoteRequestsTabInner';
import { OrdersTabInner } from '../OrdersTabInner';
import { styles } from './styles';

interface SampleRequestsPageInnerProps {
  isBuyer: boolean;
  quoteRequestsData: {
    list: QuoteRequestsListData;
    canceledList: QuoteRequestsListData;
  };
  ordersData: {
    list: OrdersListData;
    canceledList: OrdersListData;
  };
}

export const SampleRequestsPageInner: FC<SampleRequestsPageInnerProps> = ({
  isBuyer,
  quoteRequestsData,
  ordersData,
}) => {
  const { activeTab, handleTabChange } = useSampleRequestsTab({});

  return (
    <>
      <Box sx={styles.tabs}>
        <SampleRequestsPageHeader activeTabId={activeTab.value} onTabChange={handleTabChange} />
      </Box>
      {activeTab.path === SampleRequestsTabs.SAMPLE_REQUESTS ? (
        <QuoteRequestsTabInner isBuyer={isBuyer} tab={activeTab.path} data={quoteRequestsData} />
      ) : (
        <OrdersTabInner isBuyer={isBuyer} tab={activeTab.path} data={ordersData} />
      )}
    </>
  );
};
