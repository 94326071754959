import { customTheme } from 'theme/MuiThemeProvider/theme';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  button: {
    minWidth: '76px',
    padding: 0,
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B50,
  },
  menu: {
    display: 'flex',
    padding: '8px 4px 8px 0',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '3px',
    background: customTheme.custom.white,
    boxShadow: '0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 3px 5px 0px rgba(9, 30, 66, 0.20)',
  },
  menuWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: 'calc(100% - 56px)',
      left: '28px',
    },
  },
};
