import { FC } from 'react';

import { Typography } from '@mui/material';

import { styles } from './styles';

interface SBMessageDateProps {
  date: string;
}

export const SBMessageDate: FC<SBMessageDateProps> = ({ date }) => {
  return (
    <Typography component="span" sx={styles.date}>
      {date}
    </Typography>
  );
};
