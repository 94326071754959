import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  drawer: {
    display: 'block',
    position: 'relative',
  },
  modal: (isBuyer: boolean) => ({
    top: isBuyer ? '96px' : '48px',
    overflowY: 'auto',
    ...scrollMixin,

    '& .MuiBackdrop-root.MuiModal-backdrop': {
      background: 'transparent',
      top: isBuyer ? '96px' : '48px',
    },
  }),
  paper: (isBuyer: boolean) => ({
    paddingBottom: '12px',
    top: isBuyer ? '96px' : '48px',
    height: `calc(100% - ${isBuyer ? '96px' : '48px'})`,
    width: '100%',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    boxShadow: 0,
    ...scrollMixin,
  }),
};
