import { FC } from 'react';

import { Control } from 'react-hook-form';

import { ChatFormData, SBChatMode } from 'app/api/SBChat';
import { CommonModal } from 'components';
import { useDeviceType } from 'hooks';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { SBConversation } from '../SBConversation';
import { styles } from './styles';

interface CompanyChatModalProps {
  channelUrl: string;
  control: Control<ChatFormData>;
  disableSendButton: boolean;
  isOpen: boolean;
  isSendMessageLoading: boolean;
  otherCompanyName: string;
  handleClose: () => void;
  handleSendMessage: () => void;
}

export const CompanyChatModal: FC<CompanyChatModalProps> = ({
  channelUrl,
  control,
  disableSendButton,
  isOpen,
  isSendMessageLoading,
  otherCompanyName,
  handleClose,
  handleSendMessage,
}) => {
  const { isMobileSize } = useDeviceType();

  return (
    <CommonModal
      isOpen={isOpen}
      handleClose={handleClose}
      paperStyles={(isMobileSize ? styles.mobilePaper : styles.paper) as SxPropsTypes}
      withCloseButton={false}
    >
      <SBConversation
        otherCompanyName={otherCompanyName}
        mode={SBChatMode.COMPANY_MODAL}
        channelUrl={channelUrl}
        control={control}
        disableSendButton={disableSendButton}
        isSendMessageLoading={isSendMessageLoading}
        handleClose={handleClose}
        handleSendMessage={handleSendMessage}
      />
    </CommonModal>
  );
};
