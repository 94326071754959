import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { CommonButton } from 'components/CommonButton';
import { CommonModal } from 'components/CommonPopup';

import { styles } from './styles';

interface ReviseModalProps {
  handleClose: () => void;
  isOpen: boolean;
  handleConfirm: () => void;
}

export const ReviseModal: FC<ReviseModalProps> = ({ handleClose, handleConfirm, isOpen = false }) => {
  return (
    <CommonModal title="Revise Quote" isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        Would you like to revise this quote? If the buyer has already viewed your initial offer, this will send them a
        new notification with updated terms.{' '}
      </Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <CommonButton variant="text" color="primary" sx={{ ...styles.confirmButton }} onClick={handleConfirm}>
          Confirm
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
