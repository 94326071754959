import { useCallback, useState } from 'react';

import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import {
  ChangePasswordRequest,
  changePassword,
  ChangePasswordFormData,
  CurrentPasswordError,
  INVALID_OLD_PASSWORD,
} from 'app/api/auth';
import { useAuth } from 'hooks';
import { notificationObserver } from 'utils/observer';
import { inititalValues } from './initialValues';

const CURRENT_PASSWORD_IS_INVALID = 'Сurrent password is invalid.';

export const useChangePasswordForm = () => {
  const navigate = useNavigate();
  const { logout, signOut } = useAuth();
  const [matchPasswordError, setMatchPasswordError] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');

  const { isLoading: isLogOutLoading } = signOut;

  const { control, handleSubmit } = useForm<ChangePasswordFormData>({
    defaultValues: inititalValues,
  });

  const isMatchPasswordError = !!matchPasswordError;
  const isCurrentPasswordError = !!currentPasswordError;

  const { mutate: changePasswordMutation, isLoading } = useMutation(
    (data: ChangePasswordRequest) => changePassword(data),
    {
      onSuccess: () => {
        logout();

        notificationObserver.publish({
          type: 'success',
          title: 'Password changed successfully',
        });
      },
      onError: (error: AxiosError) => {
        const isInvalidCurrentPassword =
          (error?.response?.data as CurrentPasswordError)?.password?.code === INVALID_OLD_PASSWORD;

        if (isInvalidCurrentPassword) {
          setCurrentPasswordError(CURRENT_PASSWORD_IS_INVALID);
        } else {
          notificationObserver.publish({
            type: 'error',
            title: 'Something went wrong',
          });
        }
      },
    },
  );

  const handleConfirmChangePassword = useCallback(
    (values: ChangePasswordFormData) => {
      const { oldPassword, newPassword, confirmPassword } = values;

      if (newPassword !== confirmPassword) {
        setMatchPasswordError('Password do not match. Please try again.');
        return;
      }

      changePasswordMutation({
        oldPassword,
        newPassword,
      });
    },
    [changePasswordMutation],
  );

  const handleFormChange = useCallback(() => {
    if (isMatchPasswordError) {
      setMatchPasswordError('');
    }
    if (isCurrentPasswordError) {
      setCurrentPasswordError('');
    }
  }, [isCurrentPasswordError, isMatchPasswordError]);

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return {
    control,
    isLoading: isLoading || isLogOutLoading,
    isMatchPasswordError,
    isCurrentPasswordError,
    matchPasswordError,
    currentPasswordError,
    handleConfirmChangePassword,
    handleFormChange,
    handleNavigateBack,
    handleSubmit,
  };
};
