import { ProductNested, Quote, QuoteProductStatus } from 'app/api/quotes/types';
import { QuoteOrder } from 'app/api/orders/types';

interface NestedProductWithStatus extends ProductNested {
  satus?: QuoteProductStatus;
}

const getUniqueItems = (data: NestedProductWithStatus[]) =>
  data.filter((value, index, self) => self.findIndex((v) => v.id === value.id && v.name === value.name) === index);

export const getQuoteUniqueProductsArray = (
  { quoteproducts }: Quote | QuoteOrder,
  isOrder: boolean,
): ProductNested[] => {
  let result: ProductNested[] = [];

  try {
    const nestedProducts = quoteproducts.map((item) => ({ ...item.product, status: item.status }));

    if (!isOrder) {
      result = getUniqueItems(nestedProducts);
    } else {
      const availableProducts = nestedProducts.filter(({ status }) => status === QuoteProductStatus.AVAILABLE);
      result = getUniqueItems(availableProducts);
    }
  } catch (e) {
    /* empty */
  }

  return result;
};
