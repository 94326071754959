import { useCallback, useMemo, useState } from 'react';

import { useQueryClient } from 'react-query';
import { format } from 'date-fns';

import { customTheme } from 'theme/MuiThemeProvider/theme';
import { DocumentFolderStatus } from 'app/api/orders/types';

const dateFormat = 'dd.MM.yyyy';

interface UseViewDocumentsPopupProps {
  completeDate: string;
  status: DocumentFolderStatus;
  isFileError: boolean;
  handleClose: () => void;
}

export const useViewDocumentsPopup = ({
  completeDate,
  status,
  isFileError,
  handleClose,
}: UseViewDocumentsPopupProps) => {
  const queryClient = useQueryClient();

  const [documentUpdated, setDocumentUpdated] = useState(false);

  const date = completeDate ? format(new Date(completeDate), dateFormat) : format(new Date(), dateFormat);

  const isCompleted = useMemo(() => {
    return status === DocumentFolderStatus.ALL_DOCUMENT_ACCEPTED;
  }, [status]);

  const isRejected = useMemo(() => {
    return status === DocumentFolderStatus.AT_LEAST_1_DOCUMENT_REJECTED;
  }, [status]);

  const cardColor = useMemo(() => {
    if (isCompleted) {
      return customTheme.custom.success;
    }

    if (isRejected) {
      return customTheme.custom.error;
    }

    return customTheme.custom.awaiting;
  }, [isCompleted, isRejected]);

  const subTitle = useMemo(() => {
    if (isCompleted) {
      return `Completed - ${date}`;
    }

    if (isRejected) {
      return 'Rejected';
    }

    return 'Awaiting Confirmation';
  }, [date, isCompleted, isRejected]);

  const setDocumentAsUpdated = useCallback(() => {
    setDocumentUpdated(true);
  }, []);

  const handleCloseViewModal = useCallback(() => {
    if (documentUpdated && !isFileError) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['order-documents-folders'] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['order'] });
    }

    handleClose();
  }, [documentUpdated, isFileError, handleClose, queryClient]);

  return { cardColor, isCompleted, subTitle, handleCloseViewModal, setDocumentAsUpdated };
};
