import { FC } from 'react';

import { useAuth } from 'hooks';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { CommonDrawer } from '../CommonDrawer';
import { CropsDrawer } from '../CropsDrawer';
import { MobileSidebarHeader } from '../MobileSidebarHeader';
import { MobileMainMenu } from '../MobileMainMenu';
import { MobileProductsMenu } from '../MobileProductsMenu';
import { UserAccountDrawer } from '../UserAccountDrawer';
import { CloseDrawerButton } from '../CloseDrawerButton';
import { useMobileSideBar } from './hooks';
import { styles } from './styles';

interface MobileSideBarProps {
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => void;
}

export const MobileSideBar: FC<MobileSideBarProps> = ({ isOpen, onClose, onToggle }) => {
  const { isBuyer } = useAuth();
  const {
    currentCategory,
    data,
    isAccountOpen,
    isLoading,
    isProductsOpen,
    subType,
    type,
    handleClose,
    handleCloseAccount,
    handleOpenAccount,
    handleCloseProducts,
    handleOpenProducts,
  } = useMobileSideBar({
    isOpen,
    onClose,
    onToggle,
  });

  return (
    <>
      <CommonDrawer isOpen={isOpen} sx={styles.wrap as SxPropsTypes} handleClose={handleClose}>
        <MobileSidebarHeader onAccountClick={handleOpenAccount} />
        <MobileMainMenu onItemClick={handleClose} />
        {isBuyer && (
          <MobileProductsMenu data={data} isLoading={isLoading} type={type} onMenuItemClick={handleOpenProducts} />
        )}
      </CommonDrawer>

      <CropsDrawer
        currentCategory={currentCategory}
        isOpen={isProductsOpen}
        subType={subType}
        handleClose={handleClose}
        handleNavigateBack={handleCloseProducts}
      />

      <UserAccountDrawer isOpen={isAccountOpen} handleClose={handleCloseAccount} />

      {(isOpen || isProductsOpen) && <CloseDrawerButton handleClose={handleClose} />}
    </>
  );
};
