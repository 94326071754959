import { FC, MouseEvent } from 'react';

import { Control, useWatch } from 'react-hook-form';
import { Box, Menu } from '@mui/material';

import { ConversationSearchForm, ConversationSearchType } from 'app/api/SBChat';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { CommonButton, RadioGroupController } from 'components';
import { styles } from './styles';

const data = [
  { value: ConversationSearchType.CHANNEL_NAME, label: 'Company Name' },
  { value: ConversationSearchType.USER_NICKNAME, label: 'User Name' },
];

interface ConversationSearchTypeSelectProps {
  anchorEl: HTMLElement | null;
  control: Control<ConversationSearchForm>;
  open: boolean;
  handleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
}

export const ConversationSearchTypeSelect: FC<ConversationSearchTypeSelectProps> = ({
  anchorEl,
  control,
  open,
  handleClick,
  handleClose,
}) => {
  const searchType = useWatch({ control, name: 'searchType' });
  const searchTypeTitle = searchType === ConversationSearchType.CHANNEL_NAME ? 'by Company' : 'by User';

  return (
    <>
      <CommonButton
        sx={{
          ...styles.menuButton,
          ...(open && styles.menuButtonOpened),
        }}
        onClick={handleClick}
        disableRipple
      >
        {searchTypeTitle}
        <Box
          sx={{
            ...styles.menuButtonIcon,
            ...(open && styles.menuButtonIconopened),
          }}
        >
          <ArrowDownIcon />
        </Box>
      </CommonButton>
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RadioGroupController
          controlName="searchType"
          control={control as unknown as Control}
          data={data}
          handleClose={handleClose}
        />
      </Menu>
    </>
  );
};
