import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  section: {
    border: `1px solid #E8E8E8`,
    borderRadius: '10px',
    padding: '8px 16px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    '& .focus-input': {
      '&:focus': {
        border: `1px solid ${customTheme.palette.primary.main}`,
      },
    },
  },
  collapse: {
    '& .MuiCollapse-wrapperInner': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: '0px 26px',
    },
  },
  sectionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  expandIcon: (expanded: boolean) => ({
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s',
  }),
  sectionTitle: {
    fontWeight: '700',
    fontSize: '16px',
  },
};
