import { useQuery } from 'react-query';

import { Product, getSimilarProducts } from 'app/api/products';
import { useState } from 'react';
import { notificationObserver } from 'utils/observer';

export const useSimilarProducts = ({ cropId }: { cropId: number }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const { isLoading } = useQuery(['simlar-products', cropId], () => getSimilarProducts(cropId), {
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      setProducts(data.results);
    },
    onError: () => {
      notificationObserver.publish({
        type: 'error',
        title: 'Something went fetching similar products',
      });
    },
  });

  return {
    products,
    isLoading,
  };
};
