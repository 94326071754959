import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isInline: boolean) => ({
    display: isInline ? 'block' : 'grid',
    height: 'fit-content',
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B50,
  }),
  title: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B50,
  },
  subtitle: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 400,
    color: customTheme.custom.black.B50,
  },
};
