import { FC, Fragment } from 'react';

import { Box, Divider } from '@mui/material';

import { CropFamiliesData } from 'app/api/crops/types';
import { SubCategoriesNavList } from '../SubCategoriesNavList';
import { BackdropContainer } from './BackdropContainer';
import { CategoryItem } from './CategoryItem';
import { useSideCategoriesBar } from './hooks';
import { styles } from './styles';

interface SideCategoriesBarProps {
  data: CropFamiliesData;
  isOverlay?: boolean;
  isPermanent?: boolean;
  top?: string;
  onOpen?: () => void;
  onClose?: () => void;
  scrollTop?: number;
}

export const SideCategoriesBar: FC<SideCategoriesBarProps> = ({
  data,
  isOverlay = false,
  isPermanent = false,
  top = '90px',
  scrollTop = 0,
  onOpen,
  onClose,
}) => {
  const { isBackdropShown, currentCategory, type, subType, handleOpenCategory, handleClose } = useSideCategoriesBar({
    isOverlay,
    isPermanent,
    onOpen,
    onClose,
  });

  const permanentTop = scrollTop < 600 ? 90 - scrollTop : 0;

  return (
    <Box sx={{ ...styles.wrap(top, isPermanent, !!currentCategory, permanentTop) }} onMouseLeave={handleClose}>
      <Box sx={{ ...styles.categoriesWrap(isPermanent) }}>
        {data.map((category, index) => (
          <Fragment key={category.id}>
            <CategoryItem
              {...category}
              isOpened={currentCategory?.name === category.name}
              isActive={type === category.name}
              isClickable={!subType}
              handleMouseEnter={handleOpenCategory(category)}
            />
            {index !== data.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Box>

      <BackdropContainer top={top} isPermanent={isPermanent} open={isBackdropShown} onClose={handleClose}>
        {currentCategory && (
          <Box sx={{ ...styles.subCategoriesWrap }}>
            <Divider orientation="vertical" sx={{ ...styles.divider(isPermanent) }} />
            <SubCategoriesNavList
              categoryName={currentCategory.name}
              data={currentCategory.crops}
              activeItem={subType}
              isPermanent={isPermanent}
            />
          </Box>
        )}
        <Box onMouseEnter={handleClose} sx={{ ...styles.emptyBlock }} />
      </BackdropContainer>
    </Box>
  );
};
