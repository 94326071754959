import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  cardWrap: (width: string) => ({
    width,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  }),
};
