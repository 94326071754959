import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isSelected: boolean) => ({
    padding: '12px',
    display: 'flex',
    alignItems: 'stretch',
    gap: '8px',
    borderBottom: `1px solid ${customTheme.custom.black.B20}`,
    background: customTheme.custom.white,

    ...(isSelected && {
      background: `${customTheme.palette.primary.P20}`,
      borderLeft: `4px solid ${customTheme.palette.primary.P70}`,
      paddingLeft: '8px',
    }),

    ':hover': {
      ...(!isSelected && {
        cursor: 'pointer',
        background: customTheme.custom.hover,
        transition: 'all 0.2s linear',
      }),
    },
  }),
  inner: {
    width: 'calc(100% - 52px)',
    height: '100%',
    display: 'grid',
    gap: '4px',
  },
};
