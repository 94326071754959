import { useCallback, useEffect, useMemo, useState, ChangeEvent } from 'react';

import { useWatch, Control, UseFormSetValue, FieldValues } from 'react-hook-form';

import { CheckboxFilterItem } from '../types';

export const useCheckboxFilter = (
  data: CheckboxFilterItem[],
  control: Control,
  controlName: string,
  defaultFilter: CheckboxFilterItem,
  defaultTitle: string,
  setValue: UseFormSetValue<FieldValues>,
  handleApplyFilterChange?: (e: ChangeEvent<HTMLInputElement>) => void,
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const currentVal: (boolean | string)[] = useWatch({
    control,
    name: controlName,
  });

  const currentValues = useMemo(
    () => currentVal.filter((val) => !!val && val !== defaultFilter.value),
    [currentVal, defaultFilter],
  );

  const filterTitle = useMemo(() => {
    if (!currentValues.length) {
      return defaultTitle;
    }

    const firstVal = data.find((color) => color.value === currentValues[0]);
    return firstVal?.label;
  }, [data, currentValues, defaultTitle]);

  const counterTitle = useMemo(() => {
    return currentValues.length > 1 ? `+${currentValues.length - 1}` : '';
  }, [currentValues]);

  const isSelected = currentValues.length;

  const resetFilters = useCallback(
    (isAll: boolean) => {
      if (isAll || currentVal[0]) {
        setValue(
          controlName,
          currentVal.map(() => false),
        );
      }
    },
    [setValue, currentVal, controlName],
  );

  const handleApplyFilterCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      handleApplyFilterChange?.(e);
      handleClose();
    },
    [handleApplyFilterChange, handleClose],
  );

  useEffect(() => {
    if (currentVal.every((val) => val === false)) {
      setValue(controlName, [defaultFilter.value, ...currentVal.slice(1).map(() => false)]);
    }
  }, [defaultFilter, currentVal, setValue, controlName]);

  return {
    anchorEl,
    currentValues,
    open,
    filterTitle,
    isSelected,
    counterTitle,
    handleClick,
    handleClose,
    handleApplyFilterCheckboxChange,
    resetFilters,
  };
};
