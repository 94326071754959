import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import pageBannerUrl from 'assets/images/globe.png';
import { CommonButton } from 'components';
import { useAuth } from 'hooks';
import { useAppDispatch } from 'state/hooks';
import { setSignupModal } from 'state/slices/account';

import { styles } from './styles';

export const AboutPageBanner: FC = () => {
  const { activeUser } = useAuth();
  const dispatch = useAppDispatch();

  const onRequestJoin = () => {
    dispatch(setSignupModal({ isOpen: true, state: 'requestAccess', source: 'about' }));
  };
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title} component="h1">
        Welcome to{' '}
        <Box component="span" sx={styles.companyName}>
          Sproutzo
        </Box>
      </Typography>

      <Typography component="h2" sx={styles.subtitle}>
        Agriculture’s digital hub.{' '}
        <Typography component="span" sx={styles.description}>
          Effortlessly source products from leading suppliers.
        </Typography>
      </Typography>
      {!activeUser && (
        <CommonButton variant="contained" sx={styles.button} onClick={onRequestJoin}>
          Join now for free
        </CommonButton>
      )}
      <Box component="img" src={pageBannerUrl} sx={styles.laptopBanner} />
    </Box>
  );
};
