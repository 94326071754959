import { FC } from 'react';

import { generatePath } from 'react-router-dom';
import { Box } from '@mui/material';

import { Crop } from 'app/api/crops/types';
import { ROUTES } from 'app/routes/constants';
import { capitalizeFirstLetter } from 'utils/formatText';
import { Link } from 'components/Link';
import { styles } from './styles';

interface SubCategoriesNavListProps {
  data: Crop[];
  categoryName: string;
  oneColumn?: boolean;
  activeItem?: string;
  isPermanent?: boolean;
}

const getPath = (name: string, categoryName: string) =>
  generatePath(ROUTES.buyer.productsExtended, { type: categoryName, subType: name });

export const SubCategoriesNavList: FC<SubCategoriesNavListProps> = ({
  data,
  categoryName,
  oneColumn = false,
  activeItem = '',
  isPermanent,
}) => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const columnLength = (windowHeight <= 900 && data.length > 75) || windowWidth >= 1440 ? 20 : 15;
  const dataRows = !oneColumn && data.length <= 120 ? columnLength : null;
  const rowsCount = oneColumn || data.length < 15 ? data.length : dataRows;

  const columns = (windowWidth * 0.7) / (144 + 48);
  const columnsCount = oneColumn ? 1 : Math.round(columns);

  return (
    <Box
      sx={{
        ...styles.wrap(!!isPermanent),
      }}
    >
      <Box sx={{ ...styles.navContainer(rowsCount, columnsCount) }}>
        {data.map(({ id, name }) => {
          const path = getPath(name, categoryName);

          return (
            <Link
              key={id}
              path={path}
              text={capitalizeFirstLetter(name)}
              sx={{ ...styles.navItem(name === activeItem) }}
            >
              {capitalizeFirstLetter(name)}
            </Link>
          );
        })}
      </Box>
    </Box>
  );
};
