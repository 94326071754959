import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  button: {
    position: 'relative',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '32px',
      height: '32px',
      padding: '4px',

      svg: {
        width: '32px',
        height: '32px',
      },

      ':hover': {
        backgroundColor: 'unset',
      },
    },
  },
  badge: {
    '& .MuiBadge-badge': {
      minWidth: '12px',
      height: '16px',
      position: 'absolute',
      transform: 'translateY(50%)',
      top: 'calc(14px - 50%)',
      right: 'unset',
      left: '17px',
      padding: '0 2px',
      boxSizing: 'content-box',
      fontFamily: 'inherit',
      fontWeight: 600,
      fontSize: '10px',
      color: customTheme.custom.white,
      border: `1.5px solid ${customTheme.custom.white}`,
      backgroundColor: customTheme.palette.secondary.S100,

      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        top: '-9px',
        left: '18px',
        fontSize: '8px',
        fontWeight: 500,

        '& svg': {
          width: '36px',
          height: '36px',
        },
      },
    },
    '& .MuiBadge-invisible': {
      display: 'none',
    },
  },
};
