import { useMutation, useQueryClient } from 'react-query';

import { patchOrder, OrderStatus } from 'app/api/orders';

interface CancelOrderQueryI {
  id: number;
  onSuccess: () => void;
}

export const useCancelOrderMutation = ({ id, onSuccess }: CancelOrderQueryI) => {
  const queryClient = useQueryClient();

  const { mutate: handleCancelOrder, isLoading: isCancelOrderLoading } = useMutation(
    () => patchOrder(id, { status: OrderStatus.CANCELED }),
    {
      onSuccess: () => {
        onSuccess();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['order', id] });
      },
    },
  );

  return { handleCancelOrder, isCancelOrderLoading };
};
