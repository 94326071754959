import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import '@sendbird/uikit-react/dist/index.css';
import 'intl-tel-input/build/css/intlTelInput.css';
import TagManager from 'react-gtm-module';
import { PostHogProvider } from 'posthog-js/react';

import { initializeSentry } from './initializeSentry';
import { routes } from './routes/routes';
import { AppWrapper } from './AppWrapper';
import { SendBirdWrapProvider } from './providers';
import { store } from '../state/store';
import { initPostHog } from './providers/PostHogWrapProvider';

const createdRoutes = createRoutesFromElements(routes);
const router = createBrowserRouter(createdRoutes);

initializeSentry();
const posthogClient = initPostHog();

const GTM_KEY = process.env.REACT_APP_GTM_KEY;
if (GTM_KEY) {
  const tagManagerArgs = {
    gtmId: GTM_KEY,
  };
  TagManager.initialize(tagManagerArgs);
}

export const App = () => {
  const app = (
    <Provider store={store}>
      <AppWrapper>
        <SendBirdWrapProvider>
          <RouterProvider router={router} />
        </SendBirdWrapProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </AppWrapper>
    </Provider>
  );
  if (posthogClient) {
    return <PostHogProvider client={posthogClient}>{app}</PostHogProvider>;
  }
  return app;
};
