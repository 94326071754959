import { FC, ReactNode } from 'react';

import { Badge } from '@mui/material';

import { styles } from './styles';

interface UnreadMessagesIndicatorProps {
  invisible: boolean;
  children: ReactNode;
  isTransparent?: boolean;
}

export const UnreadMessagesIndicator: FC<UnreadMessagesIndicatorProps> = ({
  invisible,
  children,
  isTransparent = false,
}) => {
  return (
    <Badge
      invisible={invisible}
      color="secondary"
      overlap="circular"
      variant="dot"
      badgeContent=""
      sx={{ ...styles.badge(isTransparent) }}
    >
      {children}
    </Badge>
  );
};
