import { ChangeEvent, FC } from 'react';
import { Box } from '@mui/material';
import { CommonInput } from 'components';
import { BaseInput } from '../BaseInput';
import { styles } from './styles';

export interface InputProps {
  label: string;
  field: string;
  value: string | number | undefined;
  description?: string;
  placeholder?: string;
  active?: string;
  setActive: (name: string) => void;
  full?: boolean;
  onChange: (field: string, value: string) => void;
}

export const Input: FC<InputProps> = ({
  label,
  field,
  value,
  description,
  active,
  setActive,
  placeholder,
  onChange,
  full = false,
}) => {
  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(field, e.target.value);
  };
  const onBlur = () => {
    if (active === field) {
      setActive('');
    }
  };
  const isActive = field === active;
  return (
    <BaseInput label={label} description={description}>
      <CommonInput
        id={field}
        sx={styles.textInput(full, isActive)}
        value={value}
        onChange={onInputChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
    </BaseInput>
  );
};

export const LongFormInput: FC<Omit<InputProps, 'active' | 'setActive'> & { rows?: number }> = ({
  label,
  field,
  value,
  placeholder,
  onChange,
  description,
  rows = 7,
  full = true,
}) => {
  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(field, e.target.value);
  };
  return (
    <BaseInput sx={styles.longFormInputContainer(full)} label={label} description={description}>
      <Box
        component="textarea"
        rows={rows}
        sx={styles.longFormInput(full)}
        value={value || ''}
        onChange={onInputChange}
        placeholder={placeholder}
      />
    </BaseInput>
  );
};
