import { OrderPaymentInfo } from 'app/api/orders';
import { paymentInfoLabels } from '../constants';
import { PaymentInfoItem } from '../types';

export const getPaymentInfoDisplayValues = (info: OrderPaymentInfo): PaymentInfoItem[] => {
  let data: PaymentInfoItem[] = [];

  try {
    data = paymentInfoLabels
      .map(({ key, label }) => {
        const { [key as keyof typeof info]: value } = info;
        return {
          label,
          value,
        };
      })
      .filter(({ value }) => !!value);
  } catch (e) {
    /* empty */
  }

  return data;
};
