import { FC } from 'react';

import { Box, Divider } from '@mui/material';

import { SBChannellList, SBLoader } from 'components';

import { ConversationInfoPannel } from '../ConversationInfoPannel';
import { MessagesPageChat } from '../MessagesPageChat';
import { SearchMessagesWrap } from '../SearchMessagesWrap';
import { QuotePanel } from '../QuotePanel';
import { useMessagesPageHandlers } from './hooks';
import { ChatContent } from './styled';
import { styles } from './styles';

export const MessagesPageInner: FC = () => {
  const {
    currentChannelId,
    currentChannelLogo,
    currentChannelName,
    currentCompanyName,
    currentCompanyMembers,
    isBuyer,
    isChannelExisting,
    isChannelListLoading,
    isConversationInfoPannel,
    isLogoPlaceholder,
    isSearchMessagesPannel,
    membersCount,
    otherCompanyMembers,
    selectedMessage,
    startingPoint,
    handleCurrentChannelChange,
    handleResetSelectedMessage,
    handleToggleConversationInfo,
    handleToggleMessagesSearch,
    handleSearchResultClick,
    quotes,
    isQuoteSuccess,
    orders,
    isOrdersLoading,
  } = useMessagesPageHandlers();
  const hasQuotePanel = !!(
    isQuoteSuccess &&
    !isOrdersLoading &&
    // eslint-disable-next-line no-mixed-operators
    (quotes?.length > 0 || orders?.list.length > 0 || orders?.canceledList.length > 0)
  );
  return (
    <>
      <Box sx={styles.leftWrap}>
        <Divider orientation="vertical" sx={styles.divider} />
        <Box sx={{ ...styles.selectorWrap(isBuyer) }}>
          <Box sx={styles.listWrap}>
            <SBChannellList
              currentChannelId={currentChannelId}
              handleCurrentChannelChange={handleCurrentChannelChange}
            />
          </Box>
        </Box>
      </Box>
      <ChatContent
        open={isConversationInfoPannel || isSearchMessagesPannel}
        hasQuotePanel={hasQuotePanel}
        sx={{ ...styles.chatWrap }}
      >
        {isChannelListLoading || (isChannelExisting && !currentChannelId) ? (
          <SBLoader isFullHeight />
        ) : (
          <MessagesPageChat
            channelUrl={currentChannelId}
            channelLogo={currentChannelLogo}
            channelName={currentChannelName}
            isChannelExisting={isChannelExisting}
            isLogoPlaceholder={isLogoPlaceholder}
            isSearchMessagesPannel={isSearchMessagesPannel}
            selectedMessage={selectedMessage}
            startingPoint={startingPoint}
            handleResetSelectedMessage={handleResetSelectedMessage}
            handleSearchButtonClick={handleToggleMessagesSearch}
          />
        )}
      </ChatContent>
      <QuotePanel isBuyer={isBuyer} quotes={quotes} orders={orders} isOpen={!!currentChannelId && hasQuotePanel} />

      {!!currentChannelId && !isChannelListLoading && (
        <SearchMessagesWrap
          channelUrl={currentChannelId}
          collapsed={!isSearchMessagesPannel}
          isLoading={isChannelListLoading}
          channelName={currentChannelName}
          selectedMessageId={selectedMessage?.messageId}
          handleToggleSearchPannel={handleToggleMessagesSearch}
          handleResultClick={handleSearchResultClick}
          hasQuotePanel={hasQuotePanel}
        />
      )}

      {!!currentChannelId && !isChannelListLoading && (
        <ConversationInfoPannel
          collapsed={!isConversationInfoPannel}
          currentChannelId={currentChannelId}
          currentCompanyName={currentCompanyName}
          currentCompanyMembers={currentCompanyMembers}
          isLoading={isChannelListLoading}
          membersCount={membersCount}
          hasQuotePanel={hasQuotePanel}
          otherCompanyName={currentChannelName}
          otherCompanyMembers={otherCompanyMembers}
          handleToggle={handleToggleConversationInfo}
        />
      )}
    </>
  );
};
