import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  badge: (isTransparent: boolean) => ({
    '.MuiBadge-badge': {
      width: '10px',
      height: '10px',
      marginTop: '2.5px',
      marginRight: '2px',
      border: `1px solid ${customTheme.custom.white}`,
      boxSizing: 'border-box',
      borderRadius: '50%',
      opacity: isTransparent ? 0 : 1,
    },
  }),
};
