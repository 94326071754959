import { apiRoutes } from '../apiRoutes';
import { customerApi } from '../index';
import { SearchAutocompleteResults } from './types';

export const getAutocompleteSearch = async (params: { query: string }) => {
  if (params.query === '') {
    return { crops: [] };
  }
  const response = await customerApi.get<SearchAutocompleteResults>(apiRoutes.search.autocomplete, { params });

  return response?.data;
};
