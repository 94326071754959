export const styles = {
  wrap: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '.sendbird-message-search-pannel__header': {
      display: 'none',
    },
  },
};
