import { DashboardTabs } from 'app/api/quotes/types';
import {
  enRouteOrdersSortValues,
  openOrdersSortValues,
  pastOrdersSortValues,
  qouteRequestsSortValues,
} from '../constants';
import { SortValue } from '../types';
import { getCurrentTabStatus } from './getCurrentTabStatus';

export const getSortOptions = (tab?: DashboardTabs): SortValue[] => {
  const { isQuoteRequestsTab, isOpenOrdersTab, isEnRouteOrdersTab, isPastOrdersTab } = getCurrentTabStatus(tab);

  switch (true) {
    case isQuoteRequestsTab:
      return qouteRequestsSortValues;

    case isOpenOrdersTab:
      return openOrdersSortValues;

    case isEnRouteOrdersTab:
      return enRouteOrdersSortValues;

    case isPastOrdersTab:
      return pastOrdersSortValues;

    default:
      return qouteRequestsSortValues;
  }
};
