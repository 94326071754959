import { useForm } from 'react-hook-form';
import posthog from 'posthog-js';

import { requiredFieldValidation } from 'app/validation/validation';

import { patchRequestProductsForm, postRequestProductsForm } from 'app/api/publicForm';
import { useMutation } from 'react-query';
import { notificationObserver } from 'utils/observer';
import { AnalyticsName, rudderInitialize } from 'analytics';
import { getRequestAccessData, getUserBusinessData, setRequestAccessData, setUserBusinessData } from 'utils/storage';
import * as tracker from 'analytics/tracker';

import { UnitsType } from 'app/api/quotes';
import { getIsTrackingAccepted } from 'components/CookieBanner';
import { globalMessages } from 'utils/messages';
import { useAuth } from 'hooks';
import { useState } from 'react';
import { isCommercial, isFarmer } from 'components/PublicSiteModal';

export type RequestProductsForm = {
  // metadata props
  title?: string;

  email: string;
  companyName: string;
  name: string;
  country: string;
  hear: string;
  phone: string;
  businessType: Set<string> | string;
  posthogUser: string;
  route: string;
  date: string;
  productId?: number;
  quantity?: number;
  units?: UnitsType;
  products: { name: string; quantity: number; units: UnitsType; productUse: string; genetics: string }[];
} & ExtendedRequestFields;

export type ExtendedRequestFields = {
  // extended fields
  businessAddress: string;
  links: string;
  hasLicense: boolean;
  purchaseVolume: string;
  preferredPayment: string;

  deliveryLocation: string;
  deliveryDate: string;
  packagingType: string;
};

export const RequestAccessValidationRules = {
  email: { required: requiredFieldValidation },
  companyName: { required: requiredFieldValidation },
  name: { required: requiredFieldValidation },
  country: { required: requiredFieldValidation },
};

export const hearOptions = [
  'Search Engine',
  'Industry Trade Show / Conference',
  'Social Media',
  'Referral from Another Business',
  'Advertisement',
  'Other',
].map((v) => ({ label: v, value: v }));

export const businesses = [
  { id: 'Importer', label: 'Importer' },
  { id: 'Seed_Company', label: 'Seed Company' },
  { id: 'Farm_Supply', label: 'Farm Supply' },
  { id: 'Distribution', label: 'Distribution' },
  { id: 'Farmer', label: 'Farmer' },
  { id: 'Gardener', label: 'Gardener/Hobbyist' },
  { id: 'Commercial_Breeder', label: 'Commercial Breeder' },
  { id: 'Other', label: 'Other' },
];

export const purchaseVolumeOptions = [
  'Less than 10 Metric Tons',
  '10 to 50 Metric Tons',
  '50 to 100 Metric Tons',
  '100 to 500 Metric Tons',
  'More than 500 Metric Tons',
].map((v) => ({ label: v, value: v }));

export const useRequestProductsForm = () => {
  const storedValues = getRequestAccessData();
  const userBusinessData = getUserBusinessData();
  const posthogDistinctId = posthog.__loaded ? posthog.get_distinct_id() : 'unknown';
  const { activeUser } = useAuth();
  const isSignedIn = !!activeUser;
  const [formId, setFormId] = useState<string | null>(null);
  const form = useForm<RequestProductsForm>({
    defaultValues: {
      products: [{ name: '', quantity: 0, units: UnitsType.KG }],
      email: '',
      companyName: '',
      name: '',
      phone: '',
      hear: '',
      country: '',
      ...storedValues,
      ...userBusinessData,
      businessType: new Set((storedValues as { businessType: string[] }).businessType ?? []),
      route: window.location.pathname,
      date: new Date().toDateString(),
      posthogUser: posthogDistinctId,
    },
  });

  const { mutate: handlePostForm } = useMutation(async (data: RequestProductsForm) => {
    try {
      const parsedData = {
        ...data,
        businessType: Array.from(data.businessType).join(', '),
      };
      const response = await postRequestProductsForm(
        {
          title: 'Request Access Form',
          ...parsedData,
        },
        isSignedIn,
      );
      setFormId(response?.id);
      notificationObserver.publish({
        type: 'success',
        title: globalMessages.thankYouForm,
      });
      tracker.track(AnalyticsName.REQUEST_ACCESS);
      if (isCommercial(data.businessType as Set<string>)) {
        tracker.track(`${AnalyticsName.REQUEST_ACCESS} Commercial`);
      }
      if (isFarmer(data.businessType as Set<string>)) {
        tracker.track(`${AnalyticsName.REQUEST_ACCESS} Farmer`);
      }
    } catch (err) {
      notificationObserver.publish({
        type: 'warning',
        title: 'Unable to submit form',
      });
    }
  }, {});

  const { mutate: handlePatchForm } = useMutation(async (data: RequestProductsForm) => {
    try {
      if (!formId) {
        return;
      }
      const parsedData = {
        ...data,
        businessType: Array.from(data.businessType).join(', '),
      };
      setUserBusinessData(parsedData);
      await patchRequestProductsForm(
        formId,
        {
          title: 'Request Access Form',
          ...parsedData,
        },
        isSignedIn,
      );

      notificationObserver.publish({
        type: 'success',
        title: globalMessages.thankYouForm,
      });
    } catch (err) {
      notificationObserver.publish({
        type: 'warning',
        title: 'Unable to submit form',
      });
    }
  }, {});

  const isTrackingAccepted = getIsTrackingAccepted();
  const onSubmitRequest = (data: RequestProductsForm) => {
    if (!isTrackingAccepted) {
      rudderInitialize();
      localStorage.setItem('cookiePermissions', 'accepted');
    }
    handlePostForm(data);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { businessType, productId, units, products, quantity, ...rest } = data;
    setRequestAccessData({ ...rest, businessType: Array.from(businessType) });
  };
  return { form, onSubmit: onSubmitRequest, handlePostForm, handlePatchForm };
};
