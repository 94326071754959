export const siteMessages = {
  title: 'Sproutzo – Agriculture’s Global Trade Hub',
  description:
    'Bulk seed, grain, and commodities from leading global suppliers. ' +
    'Easily find new trading partners, source new varieties, and import from around the world.',
  productListPageTitle: (title: string) => {
    return `${title} - Sproutzo - Agriculture’s Global Trade Hub`;
  },
  productListPageDescription: (title: string) => {
    return (
      `${title} from leading global suppliers. ` +
      'Easily find new trading partners, source new varieties, and import from around the world.'
    );
  },
};
