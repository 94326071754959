import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  memberswrap: {
    padding: '4px 16px 12px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingLeft: '14px',
      paddingRight: '14px',
    },
  },
  title: {
    fontSize: '12px',
    lineHeight: '21px',
    fontWeight: 400,
    color: customTheme.custom.gray.G700,
  },
};
