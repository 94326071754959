import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface LegendSectionProps {
  src: string;
}

export const LegendSection: FC<LegendSectionProps> = ({ src }) => {
  return (
    <Box sx={styles.legend}>
      <Typography component="h3" sx={styles.title}>
        Legend
      </Typography>
      <Box component="img" sx={styles.image} src={src} />
    </Box>
  );
};
