import { useQuery } from 'react-query';

import { getOrdersCounter } from 'app/api/orders';

export const useOrdersCounterQuery = () => {
  const {
    isLoading,
    isFetching,
    data: ordersCounter,
  } = useQuery(['orders-counter'], () => getOrdersCounter(), {
    refetchOnWindowFocus: false,
    retry: false,
  });

  return {
    ordersCounter,
    isOrdersCounterLoading: isLoading || isFetching,
  };
};
