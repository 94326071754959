import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface SearchMessageItemHeaderProps {
  date: string;
  isCurrentUser: boolean;
  isSystem: boolean;
  time: string;
  userName?: string;
}

export const SearchMessageItemHeader: FC<SearchMessageItemHeaderProps> = ({
  date,
  isCurrentUser,
  isSystem,
  userName,
  time,
}) => {
  const name = isCurrentUser ? 'You' : userName;
  const displayName = isSystem ? 'System message' : name;

  return (
    <Box sx={styles.wrap}>
      <Typography component="span" sx={styles.name}>
        {displayName}
      </Typography>
      <Box sx={styles.dateWrap}>
        <Typography component="span" sx={{ ...styles.date, ...styles.time }}>
          {time}
        </Typography>
        <Typography component="span" sx={{ ...styles.date, ...styles.day }}>
          {date}
        </Typography>
      </Box>
    </Box>
  );
};
