import { FC, useState } from 'react';

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet';

import { reorderCategories } from 'hooks/api/useCropFamiliesQuery';
import { siteMessages } from 'utils/siteMessages';
import { CommonButton } from 'components';

import { PageLoader } from 'components/PageLoader';
import { ProductsSlider } from 'components/ProductsSlider';
import { CompanySlider } from 'components/CompanySlider';
import { RequestProductModal } from 'components/RequestProductModal';

import { HomePageBanner } from './components';
import { LeadingSuppliers } from './components/LeadingSuppliers';
import { Categories } from './components/Categories';
import { SimpleTradeBanner } from './components/SimpleTadeBanner';
import { AllInOnePlaceBanner } from './components/AllInOnePlaceBanner';
import { ChoiceCard } from './components/ChoiceCard';

import { useBuyerHomePageHandlers } from './hooks';
import { styles } from './styles';

export const BuyerHomePage: FC = () => {
  const {
    isLoading,
    homePageData,
    activeUser,
    newProducts,
    featuredProducts,
    featuredSuppliers,
    featuredCrop,
    cropData,
    country,
    variation,
  } = useBuyerHomePageHandlers();

  const displayCropFamilies = reorderCategories(homePageData, true);
  const [isRequestProductModalOpen, setIsRequestProductModalOpen] = useState(false);
  const handleCloseRequestProductModal = () => setIsRequestProductModalOpen(false);
  const onClickRequestProduct = () => {
    setIsRequestProductModalOpen(true);
  };

  if (isLoading) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.pageWrap }}>
      <Helmet>
        <title>{siteMessages.title}</title>
        <meta name="description" content={siteMessages.description} />
      </Helmet>

      <HomePageBanner country={country} variation={variation} />
      <Box sx={styles.requestProductContainer}>
        <CommonButton variant="contained" sx={styles.requestProduct} size="large" onClick={onClickRequestProduct}>
          Request any product & receive a quote
        </CommonButton>
      </Box>
      <RequestProductModal isOpen={isRequestProductModalOpen} handleClose={handleCloseRequestProductModal} />
      {activeUser && <LeadingSuppliers />}
      <Categories />
      {featuredCrop && cropData.length > 0 ? (
        <ProductsSlider
          id="featured-products"
          name={`Featured ${featuredCrop} seeds`}
          size="large"
          subText="Instant access to thousands of varieties."
          products={cropData}
        />
      ) : (
        <ProductsSlider
          id="featured-products"
          name="Featured products"
          size="large"
          subText="Instant access to thousands of varieties."
          products={featuredProducts}
        />
      )}

      <ProductsSlider
        id="new-arrivals"
        name="New arrivals"
        isLink={false}
        products={newProducts}
        slide={<ChoiceCard />}
        slidePosition={3}
      />
      {activeUser && (
        <CompanySlider companies={featuredSuppliers} name="Featured suppliers" pathName="Featured sellers" />
      )}
      <SimpleTradeBanner />
      {displayCropFamilies.slice(0, 2).map((section) => (
        <ProductsSlider key={section.id || section.name} {...section} isSmallTitle />
      ))}

      <AllInOnePlaceBanner />
      {displayCropFamilies.slice(2).map((section) => (
        <ProductsSlider key={section.id || section.name} {...section} isSmallTitle />
      ))}
    </Box>
  );
};
