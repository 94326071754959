import { useCallback } from 'react';
import { storage } from '../../../utils/storage';
import { IS_UPDATE_WAIT_ANSWER_KEY } from '../constants';

export const useHandleWaitAnswerStatus = () => {
  const setIsUpdWaitAnswer = () => {
    storage.setItem(IS_UPDATE_WAIT_ANSWER_KEY, true);
  };

  const isUpdWaitAnswer = useCallback(() => storage.getItem(IS_UPDATE_WAIT_ANSWER_KEY), []);

  return { setIsUpdWaitAnswer, isUpdWaitAnswer };
};
