import { FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { OnNotificationClickProps } from 'app/api/notifications';
import { useNotificationsQuery } from 'hooks';
import { NotificationsList } from '../NotificationsList';
import { NotificationsEmptyState } from '../NotificationsEmptyState';
import { styles } from './styles';

interface NotificationsContentProps {
  isNotificationsQueryEnabled: boolean;
  isRefetchInterval?: boolean;
  loaderSize?: number;
  handleItemClick: ({ link, isNew, id }: OnNotificationClickProps) => void;
}

export const NotificationsContent: FC<NotificationsContentProps> = ({
  isNotificationsQueryEnabled,
  isRefetchInterval = true,
  loaderSize = 64,
  handleItemClick,
}) => {
  const { notifications, isLoading, isFetchingNextPage, isNotificationsList, hasNextPage, fetchNextPage } =
    useNotificationsQuery({
      enabled: isNotificationsQueryEnabled,
      isRefetchInterval,
    });

  if (isLoading) {
    return (
      <Box sx={{ ...styles.mainLoaderWrap }}>
        <CircularProgress size={loaderSize} />
      </Box>
    );
  }

  return (
    <>
      <Typography component="h3" sx={{ ...styles.title }}>
        Notifications
      </Typography>
      {isNotificationsList ? (
        <NotificationsList
          data={notifications}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          handleItemClick={handleItemClick}
        />
      ) : (
        <NotificationsEmptyState />
      )}
    </>
  );
};
