import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'none',
    height: '76px',
    padding: '4px 14px',
    boxSizing: 'border-box',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    border: `1px solid ${customTheme.custom.black.B10}`,
    background: customTheme.custom.white,
    boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.1)',
    zIndex: 11,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      justifyItems: 'center',
      alignItems: 'center',
    },
  },
  filtersButton: {
    width: '136px',
    color: customTheme.custom.black.B50,
    gap: '16px',

    '&, &.MuiButton-containedPrimary:hover': {
      background: 'transparent',
    },
  },
  buttonLabel: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 600,
  },
};
