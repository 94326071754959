import { useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';

import { SBChannelMetaData } from 'app/api/SBChat';
import { ROUTES } from 'app/routes/constants';
import { getCurrentChannel, getIsChannelExisting } from 'utils/chat';

interface UseCurrentChannelHandlersProps {
  currentChannelId: string | null;
  idSearchParam: string | null;
  isChatPage?: boolean;
}

export const useCurrentChannelHandlers = ({
  currentChannelId,
  idSearchParam,
  isChatPage = false,
}: UseCurrentChannelHandlersProps) => {
  const navigate = useNavigate();
  const channelListState = useChannelListContext();

  const isChannelExisting = useMemo(
    () => getIsChannelExisting(channelListState, idSearchParam),
    [channelListState, idSearchParam],
  );

  const currentChannel = getCurrentChannel(channelListState, currentChannelId);

  const currentChannelMetaData = currentChannel?.cachedMetaData as SBChannelMetaData;

  useEffect(() => {
    if (currentChannelId && !isChatPage) {
      const search = currentChannelId ? `?id=${currentChannelId}` : '';
      navigate({ pathname: `${ROUTES.common.messages}/`, search });
    }
  }, [currentChannelId, isChatPage, navigate]);

  return {
    channelSBName: channelListState?.currentChannel?.name,
    currentChannel,
    currentChannelMetaData,
    isChannelExisting,
    isChannelListLoading: channelListState?.loading,
  };
};
