import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: customTheme.custom.white,
    zIndex: 110,
    borderTop: `1px solid ${customTheme.custom.black.B10}`,
  },
  sendButtons: {
    padding: '18px 14px 10px',
    gap: '0 12px',
  },
  buttons: (isBuyer: boolean) => ({
    height: '80px',
    padding: '8px 14px 18px',
    ...(!isBuyer && {
      padding: '18px 14px',
    }),
    boxSizing: 'border-box',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    gridTemplateRows: '1fr',
    gap: '0 12px',
  }),
  buttonCommon: {
    width: 'unset',
    minWidth: 'fit-content',
    padding: '6px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 500,
    borderRadius: '12px',
  },
  chatButton: {
    display: 'flex',
    gap: '4px',
  },
  addButton: {
    color: customTheme.custom.white,
  },
  buyerSendButton: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
    },
  },
};
