import { FC } from 'react';

import { Box, CircularProgress } from '@mui/material';

import { MuteNotificationsSwitch } from 'components';
import { useNotificationsSettings } from 'hooks';
import { styles } from './styles';

interface ConversationMuteSettingsProps {
  channelUrl: string;
  otherCompanyName: string;
}

export const ConversationMuteSettings: FC<ConversationMuteSettingsProps> = ({ channelUrl, otherCompanyName }) => {
  const { initialSettingsValues: initialNotificationsSettingsValues, isLoading: isNotificationsSettingsLoading } =
    useNotificationsSettings({
      channelUrl,
    });

  return (
    <>
      {isNotificationsSettingsLoading ? (
        <Box sx={styles.loaderWrap}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <MuteNotificationsSwitch
          channelUrl={channelUrl}
          companyName={otherCompanyName}
          initialValues={initialNotificationsSettingsValues}
          isLabelDark
        />
      )}
    </>
  );
};
