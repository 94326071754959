import { FC, useCallback } from 'react';

import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { Box, IconButton, Typography } from '@mui/material';

import { Product } from 'app/api/products/types';
import { UnitsType } from 'app/api/quotes';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import { TextFormField } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { getVarietyValue } from 'utils/getVarietyValue';
import { UnitsMenu } from 'components/UnitsMenu';
import { QuoteFormData } from '../../hooks/types';
import { validationRules } from '../../hooks';
import { styles } from './styles';

interface ChoosedProductRowProps {
  setValue: UseFormSetValue<QuoteFormData>;
  control: Control<QuoteFormData>;
  index: number;
  removeChoosedProducts: (id: number, index: number) => void;
  data: Product;
  formError: string;
}

export const ChoosedProductRow: FC<ChoosedProductRowProps> = ({
  data,
  index,
  control,
  setValue,
  formError,
  removeChoosedProducts,
}): JSX.Element => {
  const currentVal = useWatch({
    control,
    name: `quotes.${index}.value`,
  });

  const unitsType = useWatch({
    control,
    name: `quotes.${index}.unitsType`,
  });

  const onChangeQuantityUnits = useCallback(
    (value: UnitsType) => {
      setValue(`quotes.${index}.unitsType`, value);
    },
    [index, setValue],
  );

  const removeProduct = useCallback(() => {
    removeChoosedProducts(data.id, index);
  }, [data, index, removeChoosedProducts]);

  const varietyValue = getVarietyValue({
    crop: data.crop,
    variety: data.variety,
    name: data.name,
  });

  const imageSrc = data.thumbnail || data.image || imagePlaceholder;

  return (
    <Box data-testid="product-row" sx={{ ...styles.productRow }}>
      <Box sx={{ ...styles.productInfo }}>
        <Box component="img" loading="lazy" src={imageSrc} sx={{ ...styles.productImage }} />

        <Box sx={styles.titlesWrap}>
          <Typography sx={{ ...styles.title }}>{data.crop}</Typography>
          <Typography sx={{ ...styles.nameWrap }}>
            <Typography component="span" sx={{ ...styles.name }}>
              {varietyValue}
            </Typography>
            {data.variety && '\u00A0'}
            {data.name}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ ...styles.countInfo }}>
        <Typography sx={styles.neededLabel}>Amount Needed?</Typography>
        <Box sx={{ ...styles.textFieldWrap }}>
          <TextFormField
            wrapStyles={{ ...styles.countField } as SxPropsTypes}
            control={control}
            name={`quotes.${index}.value`}
            isFormError={!!formError && !currentVal}
            withoutHighlights
            placeholder="Enter Amount"
            rules={validationRules.amount}
          />
          <UnitsMenu
            units={unitsType}
            onChange={onChangeQuantityUnits}
            sxLabel={styles.editQuantityUnitsLabel}
            isEditable
          />
        </Box>

        <IconButton aria-label="remove" sx={{ ...styles.removeButton }} onClick={removeProduct}>
          <RemoveIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
