import { FC, MouseEvent, ReactNode, useState } from 'react';
import { Box, Collapse, IconButton, SxProps, Typography } from '@mui/material';

import { ReactComponent as ExpandMoreIcon } from 'assets/icons/expandMore.svg';
import { styles } from './styles';

export const Section: FC<{
  title?: string;
  children: ReactNode;
  isCollapsible?: boolean;
  defaultCollpase?: boolean;
  sx?: SxProps;
}> = ({ title, children, sx, isCollapsible = false, defaultCollpase = false }) => {
  const [expanded, setExpanded] = useState(!defaultCollpase);

  const handleExpandClick = (e: MouseEvent) => {
    if (isCollapsible) {
      e.preventDefault();
      e.stopPropagation();
      setExpanded(!expanded);
    }
  };

  return (
    <Box sx={{ ...(sx || {}), ...styles.section }}>
      {(title || isCollapsible) && (
        <Box sx={styles.sectionHeader} component="div" onClick={handleExpandClick}>
          {title && (
            <Typography variant="h5" sx={styles.sectionTitle}>
              {title}
            </Typography>
          )}
          {isCollapsible && (
            <IconButton onClick={handleExpandClick} aria-label="expand" sx={styles.expandIcon(expanded)}>
              <ExpandMoreIcon />
            </IconButton>
          )}
        </Box>
      )}
      {isCollapsible ? (
        <Collapse sx={styles.collapse} in={expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      ) : (
        children
      )}
    </Box>
  );
};
