import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  navWrap: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
      alignItems: 'start',
      height: 'fit-content',
      gap: '30px',
    },
  },
  navItem: {
    position: 'relative',
    padding: '0 8px',
    margin: '0 8px',
    a: {
      color: customTheme.custom.black.B70,
      fontSize: '14px',
      fontWeight: '500',
      textDecoration: 'none',

      '&.active': {
        color: customTheme.custom.black.B100,

        '.MuiBox-root': {
          visibility: 'visible',
        },
      },

      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        '&.active': {
          '.MuiBox-root': {
            visibility: 'hidden',
          },
        },
      },
    },
    ':hover': {
      a: {
        color: customTheme.custom.black.B100,

        '.MuiBox-root': {
          visibility: 'visible',
        },
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
      padding: 0,
      a: {
        display: 'block',
        fontSize: '16px',
        lineHeight: '20px',
      },
    },
  },
  activeItem: {
    position: 'absolute',
    bottom: '-4px',
    left: '50%',
    transform: 'translate(-50%, 0)',
    visibility: 'hidden',
    width: '28px',
    height: '3px',
    borderRadius: '25px',
    backgroundColor: customTheme.palette.primary.P100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
