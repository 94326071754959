import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '16px',
      gap: '4px',
    },
  },
  infoWrap: (isVerticalAlignment: boolean) => ({
    flex: '1',
    ...(isVerticalAlignment && {
      height: 'min-content',
      alignSelf: 'center',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '50%',
      minWidth: 'fit-content',
    },
  }),
  buttonWrap: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flex: '1',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flex: 'unset',
    },
  },
  detailsButton: {
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    padding: '0',
    color: customTheme.palette.primary.P5,
    textDecoration: 'none',

    ':hover': {
      backgroundColor: 'inherit',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },

    [`@media (max-width: 359px)`]: {
      fontSize: '12px',
      whiteSpace: 'nowrap',
    },
  },
  totalLabel: {
    fontSize: '12px',
    lineHeight: '16px',
    marginRight: '6px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
      lineHeight: '12px',
      whiteSpace: 'nowrap',
    },
  },
  totalValue: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
      lineHeight: '12px',
      whiteSpace: 'nowrap',
    },
  },
  quantityValue: {
    fontWeight: '700',
  },
  units: {
    marginLeft: '2px',
  },
  listWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  itemWrap: (isMarginBottom: boolean) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    ...(isMarginBottom && {
      marginBottom: '8px',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,
      width: 'calc(100% - 12px)',
      gap: '4px',
    },

    [`@media (max-width: 359px)`]: {
      width: 'fit-content',
      minWidth: 'calc(100% - 12px)',
    },
  }),
  menuButtonIcon: {
    display: 'flex',
    alignItems: 'center',
  },
};
