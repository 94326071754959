export const styles = {
  modalPaper: {},
  top: {},
  description: {
    fontSize: '12px',
    fontWeight: 400,
    marginBottom: '16px',
  },
  form: {},
  confirm: {},
  body: {
    padding: '16px',
  },
};
