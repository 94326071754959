import { SyntheticEvent, useCallback, useEffect, useMemo } from 'react';

import { useNavigate, useLocation, generatePath } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { DashboardMainTabs, SampleRequestsTabs } from 'app/api/quotes/types';
import { ROUTES } from 'app/routes/constants';
import { useAuth } from 'hooks';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { DashboardTab } from '../types';
import { dasboardTabs, sampleRequestsTabs } from '../constants';

interface DashboardTabsReturn {
  isBuyer: boolean;
  activeMainTab: DashboardTab | undefined;
  activeTab: DashboardTab | undefined;
  isSampleRequestsTab: boolean;
  handleTabChange: (event: SyntheticEvent, newTab: number) => void;
}

const getValidTabs = (role: 'buyer' | 'seller') => {
  const validDashboardTabs = Object.values(DashboardMainTabs).map((tab) =>
    generatePath(ROUTES[role].dashboard.tab, { tab }),
  );
  const validSampleRequestsTabs = Object.values(SampleRequestsTabs).map((tab) =>
    generatePath(ROUTES[role].dashboard.tab, { tab }),
  );

  return [...validDashboardTabs, ...validSampleRequestsTabs];
};

export const useDashboardTabs = (): DashboardTabsReturn => {
  const { isBuyer } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const role = isBuyer ? 'buyer' : 'seller';
  const mainDashboardRoute = ROUTES[role].dashboard._;
  const validTabs = useMemo(() => [...getValidTabs(role), mainDashboardRoute], [mainDashboardRoute, role]);
  const isValidTab = useMemo(() => validTabs.includes(pathname), [pathname, validTabs]);

  const isSampleRequestsTab = sampleRequestsTabs.some(({ path }) => {
    return pathname === `${mainDashboardRoute}/${path}`;
  });

  const activeMainTab = isSampleRequestsTab
    ? dasboardTabs[4]
    : dasboardTabs.find((tab) => pathname === `${mainDashboardRoute}/${tab.path}`) ?? dasboardTabs[0];

  const activeTab = dasboardTabs.find((tab) => pathname === `${mainDashboardRoute}/${tab.path}`) ?? dasboardTabs[0];

  useEffect(() => {
    if (!isValidTab) {
      navigate(mainDashboardRoute);
    }
  }, [activeMainTab, isValidTab, mainDashboardRoute, navigate, pathname]);

  useEffect(() => {
    const activeTabFromUrl = (
      isSampleRequestsTab
        ? dasboardTabs[4]
        : dasboardTabs.find((tab) => pathname === `${mainDashboardRoute}/${tab.path}`) ?? dasboardTabs[0]
    ).value;

    if (activeMainTab?.value !== activeTabFromUrl) {
      handleTabChange(null, activeTabFromUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = useCallback(
    (event: SyntheticEvent | null, newTab: number) => {
      event?.preventDefault();
      navigate(dasboardTabs[newTab].path);
      handleTrackLinkClickEvent({
        path: dasboardTabs[newTab].path,
        type: AnalyticsLinkType.BUTTON,
        text: dasboardTabs[newTab].label,
      });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['orders-counter'] });
    },
    [handleTrackLinkClickEvent, navigate, queryClient],
  );

  return {
    isBuyer,
    activeMainTab,
    activeTab,
    isSampleRequestsTab,
    handleTabChange,
  };
};
