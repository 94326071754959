import { SxProps } from '@mui/material';

import { customTheme } from 'theme/MuiThemeProvider/theme';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  chatWrap: (styles: SxProps) => ({
    overflow: 'hidden',
    background: customTheme.custom.white,

    '& *': {
      fontFamily: customTheme.typography.allVariants.fontFamily,
    },

    '& .sendbird-conversation__messages-padding': {
      paddingTop: '8px',
      ...scrollMixin,
    },

    '& .sendbird-conversation__messages.sendbird-conversation__message-list': {
      paddingTop: '8px',
    },

    '& .sendbird-separator': {
      padding: 0,

      '& .sendbird-label': {
        fontSize: '10px',
        fontWeight: 500,
        color: customTheme.custom.black.B50,
      },

      '& .sendbird-color--onbackground-4--background-color': {
        backgroundColor: customTheme.custom.black.B20,
      },
    },

    '& .sendbird-notification': {
      background: customTheme.palette.primary.P100,
      margin: '4px 6px auto 4px',
      width: 'calc(100% - 12px)',

      '&:hover': {
        background: customTheme.palette.primary.P70,
      },
    },

    '& .sendbird-conversation__scroll-bottom-button': {
      bottom: '12px',
      left: 'calc(100% - 52px)',

      svg: {
        marginTop: '1px',
      },

      path: {
        fill: `${customTheme.palette.primary.P100} !important`,
      },
    },

    '& .sendbird-conversation__footer': {
      paddingBottom: 0,
    },

    '& .sendbird-connection-status': {
      display: 'none',
    },

    ...styles,
  }),
  sendButtonWrap: (disabled: boolean) => ({
    width: '48px',
    height: '56px',
    position: 'absolute',
    right: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    borderBottomRightRadius: '4px',
    backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : customTheme.palette.primary.P100,
  }),
  sendButton: {
    height: 'fit-content',
    marginTop: '8px',
    '& svg': {
      width: '24px',
      height: '24px',
      fill: customTheme.custom.white,
    },

    ':disabled svg': {
      fill: 'rgba(0, 0, 0, 0.26)',
    },
  },
  loadingButton: {
    padding: 0,
    marginTop: '8px',
    height: 'fit-content',
    minWidth: 'fit-content',
    cursor: 'default',
    pointerEvents: 'none',

    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
  },
};
