import { useCallback } from 'react';

import * as tracker from 'analytics/tracker';

import { OrderDocumentStatus } from 'app/api/orders/types';
import { AnalyticsName } from 'analytics';
import { useUpdateDocumentMutation } from '../../hooks';

export interface ViewFileActionsI {
  id: number;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  kind: string;
  fileName: string;
  setDocumentAsUpdated: () => void;
}

export const useViewFileActions = ({
  id,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  kind,
  setDocumentAsUpdated,
}: ViewFileActionsI) => {
  const params = { id, folder: folderId, setDocumentAsUpdated };

  const { mutate: handleAcceptDocument, isLoading: isAcceptDocumentLoading } = useUpdateDocumentMutation({
    ...params,
    data: { status: OrderDocumentStatus.ACCEPTED },
    onSuccessAction: () => {
      const analyticsOptions = {
        document_id: folderId,
        document_type: kind,
        order_id: orderId,
        quote_id: quoteId,
        accepted: true,
        is_sample_request: isSampleRequest,
      };

      tracker.track(AnalyticsName.DOCUMENT_REVIEWED, analyticsOptions);
    },
  });

  const { mutate: handleUndoDocument, isLoading: isUndoDocumentLoading } = useUpdateDocumentMutation({
    ...params,
    data: { status: OrderDocumentStatus.NEW },
  });

  const onAcceptClick = useCallback(() => {
    handleAcceptDocument();
  }, [handleAcceptDocument]);

  const onUndoClick = useCallback(() => {
    handleUndoDocument();
  }, [handleUndoDocument]);

  return { isAcceptDocumentLoading, isUndoDocumentLoading, onAcceptClick, onUndoClick };
};
