import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '24px',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    background: customTheme.palette.secondary.S5,
    border: `1px solid ${customTheme.custom.success}`,
    borderRadius: '8px',
    '& path': {
      fill: customTheme.custom.success,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '20px',
      padding: '8px',
      gap: '8px',
      gridRow: 2,
    },
  },
  message: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
};
