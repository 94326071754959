import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const messagesPageChatStyles = {
  padding: '0 16px 12px 16px',
  height: 'calc(100% - 44px)',

  '& .sendbird-conversation__messages': {
    height: '100%',
    maxHeight: '100%',
  },

  '& .sendbird-conversation': {
    overflow: 'hidden',
    border: 'none',
  },

  '& .sendbird-conversation__messages.sendbird-conversation__message-list': {
    paddingTop: 0,
  },

  [`@media (max-width: ${breakpoints.tablet}px)`]: {
    paddingLeft: '14px',
    paddingRight: '14px',
  },
};
