import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  paper: {
    minWidth: '370px',
    padding: '14px 20px',
    borderRadius: '10px',
  },
  mobilePaper: {
    minWidth: '292px',
    maxWidth: 'calc(100% - 28px)',
    padding: '14px 20px',
    borderRadius: '10px',
    margin: 0,
    boxSizing: 'border-box',
  },
  titleWrap: (isMarginBottom: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(isMarginBottom && {
      marginBottom: '18px',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      ...(isMarginBottom && {
        marginBottom: '16px',
      }),
    },
  }),
  title: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  closeButton: {
    width: '24px',
    height: '24px',
    marginRight: '-2px',
    padding: '0',
  },
};
