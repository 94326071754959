import { ReactElement } from 'react';

import textDocument from 'assets/images/text_document.png';
import cargoContainer from 'assets/images/cargo_container.png';
import moneyPlant from 'assets/images/money_plant.png';
import shippingBoat from 'assets/images/shipping_boat.png';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

const messages = {
  main: 'The simplest trade & shipping process',
  reasons: [
    { icon: moneyPlant, text: 'Flexible payment terms.' },
    { icon: cargoContainer, text: 'Logistics facilitation on request.' },
    { icon: textDocument, text: 'Trade documentation support.' },
  ],
};

export const SimpleTradeBanner = (): ReactElement => {
  return (
    <Box sx={{ ...styles.banner }}>
      <Box component="img" src={shippingBoat} sx={{ ...styles.boatImg }} />
      <Typography sx={{ ...styles.contentTitle }}>{messages.main}</Typography>
      <Box sx={{ ...styles.reasons }}>
        {messages.reasons.map((reason) => (
          <Box key={reason.text} sx={{ ...styles.reason }}>
            <Box component="img" src={reason.icon} sx={styles.icon} />
            <Typography sx={{ ...styles.reasonText }}>{reason.text}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
