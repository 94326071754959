import { customerApi, fileUploadApi } from '../index';
import { apiRoutes } from '../apiRoutes';
import {
  OrderPaymentInfo,
  OrdersListData,
  OrdersListRequest,
  Order,
  CreateOrderRequest,
  CreateOrderResponse,
  CreateDocumentFolderRequest,
  PatchOrder,
  DocumentFolder,
  OrderDocument,
  PatchOrderDocument,
  Counter,
  DocumentFoldersRequest,
  CreateOrderDocument,
  OrdersShipmentDetail,
} from './types';

export const getPaymentInfoData = async (id: number) => {
  const response = await customerApi.get<OrderPaymentInfo>(apiRoutes.orders.paymentInfo(id));

  return response?.data;
};

export const getOrdersData = async (params: OrdersListRequest) => {
  const response = await customerApi.get<OrdersListData>(apiRoutes.orders._, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });

  return response?.data;
};

export const getOrderData = async (id: number) => {
  const response = await customerApi.get<Order>(apiRoutes.orders.byId(id));

  return response?.data;
};

export const createOrder = async (data: CreateOrderRequest) => {
  const response = await customerApi.post<CreateOrderResponse>(apiRoutes.orders._, data);

  return response?.data;
};

export const createDocumentFolder = async (data: CreateDocumentFolderRequest) => {
  await customerApi.post(apiRoutes.documents.folders, data);
};

export const deleteDocumentFolder = async (id: number) => {
  await customerApi.delete(apiRoutes.documents.folder(id));
};

export const getOrderDocumentFolders = async (params: DocumentFoldersRequest) => {
  const response = await customerApi.get<DocumentFolder[]>(apiRoutes.documents.folders, { params });

  return response?.data;
};

export const patchOrder = async (id: number, data: PatchOrder) => {
  await customerApi.patch(apiRoutes.orders.byId(id), data);
};

export const patchDocumentFolderAsComplete = async (id: number) => {
  await customerApi.patch(apiRoutes.documents.markAsComplete(id));
};

export const patchDocumentFolderAsIncomplete = async (id: number) => {
  await customerApi.patch(apiRoutes.documents.markAsIncomplete(id));
};

export const getOrderDocuments = async (folder: number) => {
  const response = await customerApi.get<OrderDocument[]>(apiRoutes.orderDocuments._, { params: { folder } });

  return response?.data;
};

export const patchOrderDocuments = async (id: number, data: PatchOrderDocument) => {
  await customerApi.patch(apiRoutes.orderDocuments.byId(id), data);
};

export const getOrdersCounter = async () => {
  const response = await customerApi.get<Counter>(apiRoutes.orders.counter);

  return response?.data;
};

export const createOrderDocument = async (data: CreateOrderDocument) => {
  const response = await fileUploadApi.post<OrderDocument>(apiRoutes.orderDocuments._, data);

  return response?.data;
};

export const deleteOrderDocument = async (id: number) => {
  await customerApi.delete(apiRoutes.orderDocuments.byId(id));
};

export const getOrderShipmentData = async (id: number) => {
  const response = await customerApi.get<OrdersShipmentDetail>(apiRoutes.shipment.ordersTracking(id));

  return response?.data;
};
