import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (color?: 'primary' | 'secondary') => ({
    position: 'absolute',
    left: 0,
    top: 0,
    padding: '2px 3px',
    fontSize: '9px',
    fontWeight: 600,
    borderRadius: '0px 0px 3px 0px',
    color: customTheme.custom.white,
    background: customTheme.custom.black.B70,

    ...(color && {
      background: color === 'primary' ? customTheme.palette.primary.P100 : customTheme.palette.secondary.S100,
    }),
  }),
};
