import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { Control, UseFormGetValues, UseFormReset } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { useModal } from 'hooks';
import { AdditionalFiltersControlNames } from '../../../constants';
import { useSliderFilter } from '../../SliderFilter/hooks';

interface UseSliderFilterPopupProps {
  defaultTitle: string;
  checkBoxControlName:
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY;
  control: Control<ProductsFiltersForm>;
  controlName: AdditionalFiltersControlNames.WEIGHT | AdditionalFiltersControlNames.MATURITY;
  max: number;
  min: number;
  unit: string;
  handleChangeCheckBox: (e: ChangeEvent<HTMLInputElement>) => void;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
}

export const useSliderFilterPopup = ({
  defaultTitle,
  checkBoxControlName,
  control,
  controlName,
  max,
  min,
  unit,
  getValues,
  handleChangeCheckBox,
  reset,
}: UseSliderFilterPopupProps) => {
  const { open, handleCloseModal, handleOpenModal } = useModal();
  const [previousValues, setPreviousValues] = useState<ProductsFiltersForm | null>(null);

  const { currentValue, includeProductsWithIncompleteDataValue, filterTitle } = useSliderFilter({
    checkBoxControlName,
    control,
    controlName,
    defaultTitle,
    max,
    min,
    unit,
    handleChangeCheckBox,
  });

  const rangeLabel = filterTitle === defaultTitle ? '' : filterTitle;

  const previousRange = previousValues?.[controlName];
  const previousCheckbox = previousValues?.[checkBoxControlName];

  const isRangeChanged = useMemo(
    () => previousRange?.[0] !== currentValue?.[0] || previousRange?.[1] !== currentValue?.[1],
    [currentValue, previousRange],
  );

  const isCheckboxChanged = useMemo(
    () => previousCheckbox !== includeProductsWithIncompleteDataValue,
    [includeProductsWithIncompleteDataValue, previousCheckbox],
  );

  const handleOpen = useCallback(() => {
    setPreviousValues(getValues());
    reset();
    handleOpenModal();
  }, [getValues, handleOpenModal, reset]);

  const handleResetSelected = useCallback(() => {
    if (previousValues) {
      reset(previousValues);
    }
  }, [previousValues, reset]);

  const handleClose = useCallback(() => {
    handleResetSelected();
    handleCloseModal();
  }, [handleCloseModal, handleResetSelected]);

  const handleConfirm = useCallback(() => {
    handleCloseModal();
  }, [handleCloseModal]);

  return {
    isFilterChanged: isRangeChanged || isCheckboxChanged,
    rangeLabel,
    filterTitle: defaultTitle,
    open,
    handleCloseModal: handleClose,
    handleOpenModal: handleOpen,
    handleConfirm,
  };
};
