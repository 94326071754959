import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  contentText: {
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '32px',
    },
  },

  container: {
    boxSizing: 'border-box',
    padding: '48px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '24px 0px 16px',
    },
  },
  title: {
    margin: '0px',
    fontSize: '64px',
    fontWeight: '600',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
      fontSize: '32px',
      lineHeight: '28px',
      whiteSpace: 'pre-wrap',
    },
  },
  companyName: {
    color: customTheme.palette.secondary.main,
  },
  subtitle: {
    fontSize: '20px',
    fontWeight: 600,
    marginBottom: '32px',
    color: customTheme.custom.black.B70,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      textAlign: 'center',
    },
  },
  description: {
    fontWeight: 400,
    color: customTheme.custom.black.B50,
  },
  button: {},
  laptopBanner: {
    width: '100%',
    maxWidth: '720px',
  },
};
