import { useEffect, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { CHAT_NOT_FOUND_ERROR } from 'app/api/SBChat';
import { useChatsListQuery } from 'hooks';
import { notificationObserver } from 'utils/observer';

export const useChatPageHandlers = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams();

  const { chatsList, isChatsListLoading: isLoading } = useChatsListQuery({
    enabled: true,
  });

  const isChatExisting = useMemo(() => chatsList?.some(({ chatId }) => chatId === id), [chatsList, id]);

  useEffect(() => {
    if (!!chatsList && !isChatExisting) {
      notificationObserver.publish({
        type: 'error',
        title: CHAT_NOT_FOUND_ERROR,
      });

      navigate(ROUTES.common.messages);
    }
  }, [chatsList, isChatExisting, navigate]);

  return {
    channelUrl: id,
    isChatExisting,
    isLoading,
  };
};
