import { UnitsType } from 'app/api/quotes/types';

export const unitDropdownValues = [
  {
    value: UnitsType.KG,
    label: 'Kilograms (kg)',
  },
  {
    value: UnitsType.LB,
    label: 'Pounds (lb)',
  },
  {
    value: UnitsType.MT,
    label: 'Metric Ton (mt)',
  },
  {
    value: UnitsType.THOUSAND_SEEDS,
    label: 'Thousand Seeds',
  },
];

export const NO_PRODUCTS_AVAILABLE_TO_REQUEST_ERROR = 'There are no products available to request';
