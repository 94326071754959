import { customTheme } from 'theme/MuiThemeProvider/theme';

export const orderDetailsChatStyles = {
  width: '100%',
  padding: 0,
  borderRadius: ' 0 0 10px 10px',
  minHeight: '292px',
  height: '292px',

  '& .sendbird-conversation__messages': {
    height: '220px',
    maxHeight: '220px',
  },

  '& .sendbird-conversation': {
    borderRadius: 0,
    overflow: 'hidden',
    border: `1px solid ${customTheme.custom.black.B10}`,
  },
};
