import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  listItemWrap: {
    marginBottom: '12px',
    ':last-of-type': {
      marginBottom: 0,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '8px',
      ':last-of-type': {
        marginBottom: 0,
      },
    },
  },
  listItemRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '26px',
  },
  info: {
    fontSize: '12px',
    lineHeight: '12px',
    color: customTheme.custom.black.B50,
    whiteSpace: 'nowrap',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
      lineHeight: '16px',
    },
  },
  product: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  variety: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '19px',
    },
  },
  name: {
    color: customTheme.custom.black.B50,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rightColumn: (isThousandSeedsUnit: boolean) => ({
    minWidth: isThousandSeedsUnit ? '176px' : '100px',
    maxWidth: isThousandSeedsUnit ? '176px' : '100px',
  }),
  unit: (isSmall: boolean) => ({
    ...(isSmall && {
      fontSize: '10px',
    }),
  }),
};
