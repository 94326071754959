import { FC } from 'react';

import { Box } from '@mui/material';

import { PageLoader } from 'components';
import { Footer } from 'layout/Footer';
import { PageInner } from './components';
import { useQuoteDetailsPage } from './hooks';
import { styles } from './styles';

export const QuoteDetailsPage: FC = () => {
  const { data, isBuyer, isLoading } = useQuoteDetailsPage();

  if (isLoading || !data) {
    return (
      <Box sx={styles.loaderWrap(isBuyer)}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={styles.pageWrap(isBuyer)}>
      <PageInner data={data} />
      <Box sx={styles.footerWrap}>
        <Footer />
      </Box>
    </Box>
  );
};
