import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${customTheme.custom.gray.G200}`,

    path: {
      fill: customTheme.custom.black.B70,
    },
  },
  label: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    color: customTheme.custom.black.B70,
  },
  value: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: customTheme.custom.black.B70,
  },
};
