import { UseMutationResult, useMutation, useQuery, useQueryClient } from 'react-query';

import { Product, getProductsListData, patchProduct, postProductImage } from 'app/api/products';
import { useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

const PAGE_SIZE = 50;

export type MutationResult = UseMutationResult<
  AxiosResponse<Product>,
  unknown,
  {
    id: number;
    properties: object;
  }
>;

export type ImageMutationResult = UseMutationResult<
  AxiosResponse,
  unknown,
  {
    id: number;
    image: File;
  }
>;

export const useEditProducts = () => {
  const { companyDisplayId = '' } = useParams();
  const [products, setProducts] = useState<Product[]>([]);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [pagination, setPagination] = useState<{ limit: number; offset: number }>({ limit: PAGE_SIZE, offset: 0 });
  const queryClient = useQueryClient();

  const { isLoading, data: productsData } = useQuery(
    ['products-list', companyDisplayId, pagination],
    () =>
      getProductsListData({
        company: companyDisplayId,
        ...pagination,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setTotal(data.count);
        if (products.length < data.count) {
          setProducts((prev) => [...prev, ...data.results]);
        }
      },
    },
  );
  const loadMore = useCallback(() => {
    if (
      !isLoading &&
      productsData &&
      products.length < productsData?.count &&
      products.length === pagination.limit + pagination.offset &&
      (total === undefined || products.length < total)
    ) {
      setPagination((prev) => ({ ...prev, offset: prev.offset + prev.limit }));
    }
  }, [isLoading, products, productsData, pagination, setPagination, total]);

  const mutationFn = async (data: { id: number; properties: object }) => {
    return patchProduct(data.id, data.properties);
  };

  const updateProduct = useMutation({
    mutationFn,
    onSuccess: (response, edited) => {
      setProducts((prev) => {
        const index = prev.findIndex((product) => product.id === edited.id);
        if (index !== -1) {
          return prev.map((product, idx) => {
            if (idx === index) {
              return {
                ...prev[index],
                ...edited.properties,
                ...response.data,
              };
            }
            return product;
          });
        }
        return prev;
      });
      queryClient.removeQueries(['company', edited.id]);
    },
  });

  const updateProductImage = useMutation({
    mutationFn: async (data: { id: number; image: File }) => {
      return postProductImage(data.id, data.image);
    },
  });

  return {
    updateProduct,
    updateProductImage,
    isLoading,
    total,
    data: productsData,
    products,
    loadMore,
  };
};
