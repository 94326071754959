import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    gap: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '4px',
      flexDirection: 'column',
      gap: '16px',
    },
  },
  notes: {},
  title: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '28px',
    },
  },
  content: {
    color: customTheme.custom.gray.G600,
    marginBottom: '24px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,
    },

    '& > p': {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',

      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        marginBottom: 0,
        fontSize: '12px',
      },
    },
  },
  legendWrap: {
    flexGrow: 1,
    maxWidth: '52%',
    minWidth: '40%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
