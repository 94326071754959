import { Box, Typography } from '@mui/material';
import { Control, useFieldArray, UseFormReturn } from 'react-hook-form';

import { UnitsType } from 'app/api/quotes';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { SelectInput } from 'components/SelectInput';
import { TextFormField } from 'components/TextFormField';
import { CommonButton } from 'components/CommonButton';
import { PhoneFormField } from 'components/PhoneFormField';
import { getIsTrackingAccepted } from 'components/CookieBanner';
import { useAuth } from 'hooks';

import { RequestProductsForm, RequestAccessValidationRules, hearOptions, businesses } from './form';
import { ProductRow } from './productRow';
import { MultiDropdownSelect } from './multiselectDropdown';
import { styles } from '../styles';

const messages = {
  requestQuote: 'Request quote',
  login: 'Login',
  note: "Let us know what products you need, and we'll get back to you with a detailed quote.",
  terms: 'By requesting access you agree to our privacy policy.',
};

export const ProductRequest = ({
  onSubmit,
  form,
  withUserEmailField = false,
  withFullUserFields = true,
}: {
  onSubmit: (data: RequestProductsForm) => void;
  form: UseFormReturn<RequestProductsForm>;
  withUserEmailField?: boolean;
  withFullUserFields?: boolean;
}) => {
  const { activeUser } = useAuth();
  const { setValue, control, handleSubmit, watch } = form;
  const isTrackingAccepted = getIsTrackingAccepted();
  const {
    fields: products,
    append,
    remove,
    update: updateProducts,
  } = useFieldArray<RequestProductsForm, 'products', 'listId'>({
    control,
    name: 'products',
    keyName: 'listId',
  });

  const onAddProduct = () => {
    append({ name: '', quantity: 0, units: UnitsType.KG, genetics: '', productUse: '' });
  };

  const onChangeBusinessType = (value: string[]) => {
    setValue('businessType', new Set(value));
  };
  const onChangePhoneNumber = (value: string) => {
    setValue('phone', value);
  };
  const initialPhone = form.getValues('phone');

  const showAllUserFields = !activeUser && withFullUserFields && !withUserEmailField;
  form.register('businessType', {
    validate: (value) => (showAllUserFields ? !!(value as Set<string>).size || 'This is required.' : true),
  });
  return (
    <Box sx={styles.requestContainer}>
      <Typography sx={styles.note}>{messages.note}</Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={styles.form}>
        <Box sx={styles.productList}>
          {products.map((p, index) => (
            <ProductRow
              key={p.listId}
              index={index}
              product={p}
              sx={styles.productRow(products.length > 0 && index !== products.length - 1) as SxPropsTypes}
              control={control}
              remove={remove}
              watch={watch}
              updateProducts={updateProducts}
              withRemoveButton={products.length > 1}
            />
          ))}
        </Box>
        <Box sx={styles.addProductBtnContainer}>
          <CommonButton sx={styles.addProductBtn} onClick={onAddProduct}>
            Add Product
          </CommonButton>
        </Box>
        {!activeUser && withUserEmailField && (
          <TextFormField
            control={control}
            name="email"
            type="email"
            rules={RequestAccessValidationRules.email}
            wrapStyles={styles.emailInput as SxPropsTypes}
            placeholder="Email"
            autoComplete="on"
          />
        )}
        {!activeUser && withFullUserFields && !withUserEmailField && (
          <>
            <Box sx={styles.rowContainer}>
              <TextFormField
                control={control}
                wrapStyles={styles.rowItem as SxPropsTypes}
                name="name"
                rules={RequestAccessValidationRules.name}
                placeholder="Name"
                autoComplete="on"
              />
              <TextFormField
                control={control}
                name="email"
                type="email"
                rules={RequestAccessValidationRules.email}
                wrapStyles={styles.rowItem as SxPropsTypes}
                placeholder="Email"
                autoComplete="on"
              />
            </Box>
            <Box sx={styles.rowContainer}>
              <TextFormField
                control={control}
                name="companyName"
                rules={RequestAccessValidationRules.companyName}
                wrapStyles={styles.rowItem as SxPropsTypes}
                placeholder="Company Name"
                autoComplete="on"
              />
              <PhoneFormField
                control={control}
                setValue={onChangePhoneNumber}
                initialValue={initialPhone}
                placeholder="Phone Number"
                wrapStyles={styles.rowItem as SxPropsTypes}
                autoComplete="on"
              />
            </Box>
            <Box sx={styles.rowContainer}>
              <TextFormField
                control={control}
                name="country"
                placeholder="Country"
                wrapStyles={styles.rowItem as SxPropsTypes}
                rules={RequestAccessValidationRules.country}
                autoComplete="on"
              />
              <SelectInput
                data={hearOptions}
                control={control as unknown as Control}
                controlName="hear"
                sx={{ ...styles.rowItem, ...styles.selectInput } as SxPropsTypes}
                placeHolder="How did you hear about us?"
              />
            </Box>
            <Box sx={styles.businessContainer}>
              <MultiDropdownSelect
                onChange={onChangeBusinessType}
                value={watch('businessType') as Set<string>}
                options={businesses}
                label="Business Type – select all that apply"
                isRequired
              />
            </Box>
          </>
        )}

        <TextFormField
          control={control}
          name="additionalInfo"
          placeholder="Do you have any other product requirements to share?  
* Grain specs?
* Destination?"
          multiline
          minRows={4}
          inputWrapStyles={styles.additionalInfoInput as SxPropsTypes}
          wrapStyles={styles.additionalInfo as SxPropsTypes}
        />
        <CommonButton variant="contained" color="primary" type="submit" fullWidth sx={styles.requestAccessConfirmBtn}>
          {messages.requestQuote}
        </CommonButton>
        {!isTrackingAccepted && <Box sx={styles.terms}>{messages.terms}</Box>}
      </Box>
    </Box>
  );
};
