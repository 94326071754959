import { FC, useCallback, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { OrderDocument, OrderDocumentStatus } from 'app/api/orders/types';
import { CommonButton } from 'components';
import { useAuth, useModal } from 'hooks';
import { getFileNameBySourceUrl } from 'utils/getFileNameBySourceUrl';
import { useUpdateBuyerFirstClick } from '../../hooks/useUpdateBuyerFirstClick';
import { DeclineReasonPopup } from '../DeclineReasonPopup';
import { ViewFileActions } from '../ViewFileActions';
import { ViewFileSellerActions } from '../ViewFileSellerActions';
import { useViewFile } from './hooks/useViewFile';
import { styles } from './styles';

export interface ViewDocumentsPopupProps {
  file: OrderDocument;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  kind: string;
  isCompleted: boolean;
  isBuyerActionsAllowed: boolean;
  isSellerActionsAllowed: boolean;
  setDocumentAsUpdated: () => void;
  handleOpenDocumentView: (file: OrderDocument) => void;
}

export const ViewFile: FC<ViewDocumentsPopupProps> = ({
  file,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  kind,
  isCompleted,
  isBuyerActionsAllowed,
  isSellerActionsAllowed,
  setDocumentAsUpdated,
  handleOpenDocumentView,
}) => {
  const { isBuyer } = useAuth();
  const [isFirstClick, setIsFirstClick] = useState(file.firstClick);

  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();

  const { fileColor, fileStatus } = useViewFile(file);

  const showNoteIcon = file.status === OrderDocumentStatus.REJECTED && !!file.reason;
  const name = getFileNameBySourceUrl(file?.document ?? '');

  const { mutate: handleUpdateDocument } = useUpdateBuyerFirstClick({
    id: file.id,
  });

  const openFile = useCallback(() => {
    if (!isFirstClick && isBuyer) {
      const formattedDate = new Date().toISOString();
      handleUpdateDocument({ firstClick: formattedDate });
      setIsFirstClick(formattedDate);
    }

    handleOpenDocumentView(file);
  }, [isFirstClick, isBuyer, handleOpenDocumentView, file, handleUpdateDocument]);

  return (
    <Box data-testid="file-item">
      <Box sx={{ ...styles.header(isBuyer) }}>
        <Box sx={{ ...styles.fileNameWrap }}>
          <Typography sx={{ ...styles.fileName }}>{name}</Typography>
        </Box>
        {!isBuyer && <Typography sx={{ ...styles.status(fileColor) }}>{fileStatus}</Typography>}
      </Box>

      <Box sx={{ ...styles.fileWrap }}>
        <CommonButton variant="outlined" color="inherit" sx={{ ...styles.viewButton }} onClick={openFile}>
          View
        </CommonButton>

        {showNoteIcon && (
          <CommonButton
            disableRipple
            variant="text"
            color="inherit"
            sx={{ ...styles.viewReasonButton }}
            onClick={handleOpenModal}
          >
            View Feedback
          </CommonButton>
        )}

        {isModalOpen && (
          <DeclineReasonPopup handleClose={handleCloseModal} isOpen={isModalOpen} declineReason={file.reason ?? ''} />
        )}
      </Box>

      {isBuyer && isBuyerActionsAllowed && (
        <ViewFileActions
          status={file.status}
          id={file.id}
          folderId={file.folder}
          orderId={orderId}
          quoteId={quoteId}
          isSampleRequest={isSampleRequest}
          fileName={name}
          kind={kind}
          isCompleted={isCompleted}
          setDocumentAsUpdated={setDocumentAsUpdated}
        />
      )}
      {!isBuyer && isSellerActionsAllowed && (
        <ViewFileSellerActions fileId={file.id} folderId={folderId} setDocumentAsUpdated={setDocumentAsUpdated} />
      )}
    </Box>
  );
};
