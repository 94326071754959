import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  textInput: (full: boolean, active: boolean) => ({
    fontWeight: '500',
    fontSize: '14px',
    height: '32px',
    borderRadius: '8px',
    border: '1px solid var(--Black-10, #E8E8E8)',
    background: 'white',
    padding: '4px 6px',
    textAlign: 'left',
    width: '250px',
    ...(full && { width: '100%' }),
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.black.B5,
    ...(active && { border: `1px solid ${customTheme.palette.primary.main}` }),
  }),
  longFormInput: (full: boolean) => ({
    fontWeight: '500',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid var(--Black-10, #E8E8E8)',
    background: 'white',
    ...(full && { width: '100%' }),
    textAlign: 'left',
    padding: '8px',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.black.B5,
    fontFamily: customTheme.typography.allVariants.fontFamily,
    resize: 'vertical',
  }),
  longFormInputContainer: (full: boolean) => ({
    ...(full && { width: '100%' }),
  }),
};
