import { FC } from 'react';

import { CompanyDetail } from 'app/api/company';
import { ChatFormData, SBChatMode } from 'app/api/SBChat';
import { SBConversation } from 'components';
import { useQuoteChatModal } from '../../hooks';

interface QuoteChatModalProps {
  quoteId: number;
  channelUrl: string;
  isSampleRequest: boolean;
  otherCompanyName: string;
  seller?: CompanyDetail;
  defaultValues: ChatFormData;
  handleClose?: () => void;
  handleMessageChange: (v: string) => void;
  handleFilesChange: (files: ChatFormData['files']) => void;
}

export const QuoteChatModal: FC<QuoteChatModalProps> = ({
  quoteId,
  channelUrl,
  isSampleRequest,
  seller,
  defaultValues,
  otherCompanyName,
  handleClose,
  handleMessageChange,
  handleFilesChange,
}) => {
  const { control, isLoading, isMessageData, handleConfirm, handleSubmit } = useQuoteChatModal({
    channel: channelUrl,
    quoteId,
    defaultValues,
    seller,
    isSampleRequest,
    handleMessageChange,
    handleFilesChange,
  });

  return (
    <SBConversation
      otherCompanyName={otherCompanyName}
      mode={SBChatMode.QUOTE_DETAILS}
      channelUrl={channelUrl}
      control={control}
      disableSendButton={!isMessageData}
      isSendMessageLoading={isLoading}
      handleClose={handleClose}
      handleSendMessage={handleSubmit(handleConfirm)}
    />
  );
};
