import { useMutation } from 'react-query';

import { UpdateQuoteData, updateQuote } from 'app/api/quotes';
import { notificationObserver } from 'utils/observer';

interface UseCancelQuoteRequestMutationProps {
  isSampleRequest: boolean;
  quoteId: number;
  onSuccessAction?: () => void;
}

export const useCancelQuoteRequestMutation = ({
  isSampleRequest,
  quoteId,
  onSuccessAction,
}: UseCancelQuoteRequestMutationProps) => {
  const {
    mutate: handleCancelRequest,
    isLoading: isLoadingCancel,
    isSuccess: isCancelSuccess,
  } = useMutation(
    (data: UpdateQuoteData) => {
      return updateQuote(quoteId, data);
    },
    {
      onSuccess: () => {
        notificationObserver.publish({
          type: 'success',
          title: `${isSampleRequest ? 'Sample' : 'Quote'} request has been canceled`,
        });
        onSuccessAction?.();
      },
    },
  );

  return {
    isLoadingCancel,
    isCancelSuccess,
    handleCancelRequest,
  };
};
