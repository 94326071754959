import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paper: {
    width: '404px',
    height: '464px',
    padding: '24px 4px 24px 16px',
    boxSizing: 'border-box',
    display: 'grid',
    gap: '8px',
    gridAutoRows: 'min-content',
    overflow: 'hidden',
    borderRadius: '4px',
    background: customTheme.custom.white,
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
  },
};
