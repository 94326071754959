import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface SearchNoStringPlaceholderProps {
  channelName: string;
}

export const SearchNoStringPlaceholder: FC<SearchNoStringPlaceholderProps> = ({ channelName }) => {
  return (
    <Box sx={styles.wrap}>
      Search messages in{' '}
      <Typography component="span" sx={styles.name}>
        {channelName}
      </Typography>
    </Box>
  );
};
