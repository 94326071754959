import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  message: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.2px',
    color: customTheme.custom.black.B100,
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  userName: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '13px',
    color: customTheme.custom.black.B100,
  },
};
