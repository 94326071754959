export interface CreatePasswordData {
  password: string;
  newPassword: string;
}

export interface LoginData {
  username: string;
  password: string;
}

export interface LoginResponse {
  expiry: string;
  token: string;
}

export interface LogOutRequest {
  token: string;
}

export enum UserRole {
  BUYER = 'BUYER',
  SELLER = 'SELLER',
}

interface BackendError {
  message: string;
  code: string;
}

export interface LogInError {
  user: BackendError;
}

export interface CreatePasswordRequest {
  token: string;
  password: string;
}

export interface CreatePasswordError {
  token?: BackendError;
  password?: BackendError;
}

export interface ResetPasswordStartRequest {
  email: string;
}

export interface ChangePasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface CurrentPasswordError {
  password?: {
    code?: string;
    message: string;
  };
}
