import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { format } from 'date-fns';
import { ReactComponent as ArrowIcon } from '../../../../assets/icons/arrow.svg';
import { styles } from './styles';

export const CustomHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}): JSX.Element => {
  return (
    <Box sx={{ ...styles.headerWrap }}>
      <IconButton
        onClick={decreaseMonth}
        sx={{ ...styles.arrow, ...styles.arrowLeft }}
        disabled={prevMonthButtonDisabled}
      >
        <ArrowIcon />
      </IconButton>
      {format(date, 'MMMM yyyy')}
      <IconButton onClick={increaseMonth} sx={{ ...styles.arrow }} disabled={nextMonthButtonDisabled}>
        <ArrowIcon />
      </IconButton>
    </Box>
  );
};
