import { FC } from 'react';

import { AuthMainUi } from 'components';
import { ChangePasswordForm } from './components';

export const ChangePasswordPage: FC = () => {
  return (
    <AuthMainUi isSmallMobPaddings>
      <ChangePasswordForm />
    </AuthMainUi>
  );
};

export default ChangePasswordPage;
