export const styles = {
  loaderWrap: {
    padding: '36px 24px',
    display: 'flex',
    justifyContent: 'center',
  },
  list: {
    padding: '8px 0',
  },
};
