import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { getOrderDocuments } from 'app/api/orders/service';
import { notificationObserver } from 'utils/observer';
import { FOLDER_NOT_FOUND_ERROR } from '../constants';

interface OrderDocumentsQueryI {
  folder: number;
  enabled: boolean;
}

export const useOrderDocumentsQuery = ({ folder, enabled }: OrderDocumentsQueryI) => {
  const { isLoading, isFetching, data } = useQuery(['order-documents', folder], () => getOrderDocuments(folder), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
    onError: (error: AxiosError) => {
      if (error.response?.status === 400) {
        notificationObserver.publish({
          type: 'error',
          title: FOLDER_NOT_FOUND_ERROR,
        });
      }
    },
  });

  return {
    isLoading,
    isFetching,
    data: data ?? [],
  };
};
