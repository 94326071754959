import React, { FC } from 'react';

import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { QuoteProduct, QuoteRequestStatus } from 'app/api/quotes/types';
import { Currency, getCurrency } from 'utils/currencyFormat';
import { CurrencyBadge } from 'components/CurrencyBadge';

import { QuoteTableFormData } from '../../../types';
import { QuoteRow } from '../QuoteRow';
import { QuoteSellerTotal } from '../QuoteSellerTotal';
import { QuoteColumn, tableHeaderColumns } from './constants';
import { styles } from './styles';

interface QuoteTableProps {
  data: QuoteProduct[];
  control: Control<QuoteTableFormData>;
  quoteStatus: QuoteRequestStatus;
  // isCanceledQuote: boolean;
  isChatOpen: boolean;
  watch: UseFormWatch<QuoteTableFormData>;
  setValue: UseFormSetValue<QuoteTableFormData>;
  isBuyer: boolean;
  isEditMode: boolean;
}

export const headerStyleMap = {
  [QuoteColumn.Variety]: styles.headerVariety,
  [QuoteColumn.Quantity]: styles.headerQuantity,
  [QuoteColumn.Units]: styles.headerUnits,
  [QuoteColumn.PriceperUnit]: styles.headerPricePerUnit,
  [QuoteColumn.Quote]: styles.headerQuote,
  [QuoteColumn.LastestOffer]: styles.headerLastestOffer,
  [QuoteColumn.RequestedPrice]: styles.headerRequestedPrice,
  [QuoteColumn.Subtotals]: styles.headerSubtotals,
  [QuoteColumn.CounterOffer]: styles.headerCounterOffer,
  [QuoteColumn.AcceptPrice]: styles.headerAcceptPrice,
  [QuoteColumn.NotAvailable]: styles.headerNotAvailable,
  [QuoteColumn.BuyerRemove]: styles.headerBuyerRemove,
};
export const QuoteTable: FC<QuoteTableProps> = ({
  data,
  control,
  // isCanceledQuote,
  quoteStatus,
  isChatOpen,
  watch,
  setValue,
  isBuyer,
  isEditMode,
}) => {
  const hasMadeOffer = data.some((product) =>
    isBuyer ? !!product.latestBuyerPrice?.price : !!product.latestSellerPrice?.price,
  );
  const hasCounterOffer = data.some((product) =>
    isBuyer ? !!product.latestSellerPrice?.price : !!product.latestBuyerPrice?.price,
  );

  const updateCurrency = (currency: Currency) => {
    setValue('priceUnits', currency);
    // remove all accepted prices and reset prices
    data.forEach((_, index) => {
      setValue(`quotes.${index}.acceptedPrice`, null);
      setValue(`quotes.${index}.value`, undefined);
    });
  };
  const currency = watch('priceUnits');
  const currencyDetails = getCurrency(currency);
  const columns = tableHeaderColumns(
    isBuyer,
    isEditMode,
    hasMadeOffer,
    hasCounterOffer,
    currencyDetails.symbol ?? 'USD',
  );

  return (
    <Box sx={{ ...styles.tableWrap }}>
      <TableContainer sx={{ ...styles.tableContainer(isChatOpen) }}>
        <Table stickyHeader sx={{ ...styles.table }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map(({ title, subTitle = '', col }) => {
                return (
                  <TableCell
                    key={title + subTitle}
                    sx={{ ...styles.cell, ...styles.headerCell, ...headerStyleMap[col] }}
                    align="left"
                  >
                    <Box sx={[styles.cellHeaderContainer]}>
                      <Box sx={[styles.cellHeaderText]}>
                        {title}
                        <Typography
                          sx={{
                            ...styles.headerSubTitle,
                          }}
                        >
                          {subTitle}
                        </Typography>
                      </Box>
                      {(col === QuoteColumn.Quote || col === QuoteColumn.CounterOffer) &&
                        !isBuyer &&
                        isEditMode &&
                        quoteStatus === QuoteRequestStatus.OPEN && (
                          <CurrencyBadge currency={currency} editable updateCurrency={updateCurrency} />
                        )}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody sx={{ ...styles.tableBody }}>
            {data.map((data, index) => (
              <React.Fragment key={data.id}>
                <QuoteRow
                  columns={columns}
                  isBuyer={isBuyer}
                  isEditMode={isEditMode}
                  // hasCounterOffer={hasCounterOffer}
                  data={data}
                  control={control}
                  index={index}
                  quoteStatus={quoteStatus}
                  // isDisabled={isCanceledQuote}
                  setValue={setValue}
                />
              </React.Fragment>
            ))}
          </TableBody>
          <TableFooter>
            <QuoteSellerTotal columns={columns} watch={watch} />
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};
