import { ChangeEvent, FC } from 'react';

import { Control, Controller } from 'react-hook-form';
import { Box, Menu, Typography } from '@mui/material';

import { ProductsFiltersForm } from 'app/api/products';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrowDown.svg';
import { CommonButton, CommonCheckbox, ControlledSlider } from 'components';
import { AdditionalFiltersControlNames } from '../../constants';
import { useSliderFilter } from './hooks';
import { styles } from './styles';

interface SliderFilterProps {
  defaultTitle: string;
  checkBoxControlName:
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY;
  checkboxLabel: string;
  control: Control<ProductsFiltersForm>;
  controlName: AdditionalFiltersControlNames.WEIGHT | AdditionalFiltersControlNames.MATURITY;
  isRange: boolean;
  max: number;
  min: number;
  step: number;
  unit: string;
  handleChangeCheckBox: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeSlider: (newValue: number[]) => void;
}

export const SliderFilter: FC<SliderFilterProps> = ({
  defaultTitle,
  control,
  checkBoxControlName,
  checkboxLabel,
  controlName,
  isRange,
  max,
  min,
  step,
  unit,
  handleChangeCheckBox,
  handleChangeSlider,
}) => {
  const { anchorEl, open, filterTitle, isSelected, handleClick, handleClose, handleIncompleteChange } = useSliderFilter(
    { checkBoxControlName, control, controlName, defaultTitle, max, min, unit, handleChangeCheckBox },
  );

  return (
    <>
      <CommonButton
        sx={{
          ...styles.menuButton,
          ...(isSelected && styles.selected),
          ...(open && styles.menuButtonOpened),
        }}
        onClick={handleClick}
        disableRipple
      >
        {filterTitle}
        <Box
          sx={{
            ...styles.menuButtonIcon,
            ...(open && styles.menuButtonIconopened),
          }}
        >
          <ArrowDownIcon />
        </Box>
      </CommonButton>
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ControlledSlider
          control={control as unknown as Control}
          controlName={controlName}
          isRange={isRange}
          max={max}
          min={min}
          step={step}
          unit={unit}
          handleChange={handleChangeSlider}
          onChangeAction={handleClose}
        />
        <Controller
          name={checkBoxControlName}
          control={control}
          render={({ field: props }) => (
            <Box sx={styles.checkboxWrap}>
              <CommonCheckbox {...props} ref={null} checked={!!props.value} onChange={handleIncompleteChange} />
              <Typography component="span" sx={styles.checkboxLabel}>
                {checkboxLabel}
              </Typography>
            </Box>
          )}
        />
      </Menu>
    </>
  );
};
