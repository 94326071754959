export enum AnalyticsName {
  SEARCH_PERFORMED = 'Search Performed',
  PRODUCT_DROPDOWN_VIEWED = 'Product Dropdown Viewed',
  PRODUCT_ADDED_TO_QUOTE_FROM_DROPDOWN = 'Product Added to Quote from Dropdown',
  QUOTE_REQUESTED = 'Quote Requested',
  QUOTE_REVIEWED = 'Quote Reviewed',
  QUOTE_RESPONDED_TO = 'Quote Responded To',
  USER_LOGGED_IN = 'User Logged In',
  USER_LOGGED_OUT = 'User Logged Out',
  MESSAGE_SENT = 'Message Sent',
  ATTACHMENT_UPLOADED = 'Attachment Uploaded',
  ORDER_SUBMITTED = 'Order Submitted',
  DOCUMENT_UPLOADED = 'Document Uploaded',
  DOCUMENT_REVIEWED = 'Document Reviewed',
  ALL_REQUIRED_DOCUMENTS_COMPLETED = 'All Required Documents Completed',
  LINK_CLICKED = 'Link Clicked',

  QUOTE_PRODUCT_REQUEST = 'Quote Product Request',
  QUOTE_PRODUCT_DELETE = 'Quote Product Delete',
  QUOTE_PRODUCT_UPDATE = 'Quote Product Update',
  CREATE_ORDER = 'Create Order',
  SEARCH_PRODUCT = 'Search Product',

  SEND_MESSAGE = 'Send Message',

  REQUEST_ACCESS_OPEN = 'Request Access Open',
  REQUEST_ACCESS = 'Request Access',
}

export enum AnalyticsLinkType {
  BUTTON = 'button',
  LINK = 'link',
  NAV = 'nav',
  NOTIFICATION = 'notification',
  DROPDOWN = 'dropdown',
  CARD = 'card',
}
