import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonButton } from 'components';
import { useModal } from 'hooks';
import { CompleteAllPopup } from '../CompleteAllPopup';
import { UndoCompleteAllPopup } from '../UndoCompleteAllPopup';
import { styles } from './styles';

interface AllCompleteBoxProps {
  isAllCompleted: boolean;
  isBuyerActionsAllowed: boolean;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  setDocumentAsUpdated: () => void;
}

export const AllCompleteBox: FC<AllCompleteBoxProps> = ({
  isAllCompleted,
  isBuyerActionsAllowed,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  setDocumentAsUpdated,
}) => {
  const {
    open: isAllCompleteOpen,
    handleOpenModal: handleAllCompleteOpen,
    handleCloseModal: handleAllCompleteClose,
  } = useModal();

  const { open: isUndoOpen, handleOpenModal: handleUndoOpen, handleCloseModal: handleUndoClose } = useModal();

  return (
    <Box sx={{ ...styles.wrap }} data-testid="all-complete-wrapper">
      {isAllCompleted ? (
        <>
          <Typography sx={{ ...styles.completedLabel }}>Completed</Typography>
          {isBuyerActionsAllowed && (
            <CommonButton
              disableRipple
              variant="text"
              color="inherit"
              sx={{ ...styles.undoButton }}
              onClick={handleUndoOpen}
            >
              Undo
            </CommonButton>
          )}
        </>
      ) : (
        isBuyerActionsAllowed && (
          <CommonButton
            variant="outlined"
            color="secondary"
            sx={styles.allCompleteButton}
            onClick={handleAllCompleteOpen}
          >
            All Complete
          </CommonButton>
        )
      )}

      {isAllCompleteOpen && (
        <CompleteAllPopup
          folderId={folderId}
          orderId={orderId}
          quoteId={quoteId}
          isSampleRequest={isSampleRequest}
          isOpen={isAllCompleteOpen}
          handleClose={handleAllCompleteClose}
          setDocumentAsUpdated={setDocumentAsUpdated}
        />
      )}
      {isUndoOpen && (
        <UndoCompleteAllPopup
          handleClose={handleUndoClose}
          isOpen={isUndoOpen}
          folderId={folderId}
          setDocumentAsUpdated={setDocumentAsUpdated}
        />
      )}
    </Box>
  );
};
