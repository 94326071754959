import { FC } from 'react';

import { Box, Divider } from '@mui/material';
import { ChannelProvider } from '@sendbird/uikit-react/Channel/context';
import { Member, MessageTypeFilter } from '@sendbird/chat/lib/__definition';

import { ConversationInfoHeader } from '../ConversationInfoHeader';
import { ConversationInfoMembersSection } from '../ConversationInfoMembersSection';
import { ConversationMuteSettings } from '../ConversationMuteSettings';
import { FilesListSection } from '../FilesListSection';
import { styles } from './styles';

interface ConversationInfoInnerProps {
  collapsed: boolean;
  currentChannelId: string;
  currentCompanyMembers: Member[];
  membersCount: number;
  otherCompanyName: string;
  otherCompanyMembers: Member[];
  currentCompanyName?: string;
  handleToggle: () => void;
}

export const ConversationInfoInner: FC<ConversationInfoInnerProps> = ({
  collapsed,
  currentChannelId,
  currentCompanyMembers,
  membersCount,
  otherCompanyName,
  otherCompanyMembers,
  currentCompanyName = '',
  handleToggle,
}) => {
  return (
    <>
      <ConversationInfoHeader handleToggle={handleToggle} />
      <ConversationInfoMembersSection
        currentCompanyMembers={currentCompanyMembers}
        currentCompanyName={currentCompanyName}
        membersCount={membersCount}
        otherCompanyName={otherCompanyName}
        otherCompanyMembers={otherCompanyMembers}
      />
      {!collapsed && (
        <ChannelProvider
          channelUrl={currentChannelId}
          disableMarkAsRead
          queries={{
            messageListParams: {
              messageTypeFilter: 'FILE' as MessageTypeFilter,
              prevResultSize: 200,
              nextResultSize: 200,
              reverse: true,
            },
          }}
        >
          <FilesListSection />
        </ChannelProvider>
      )}

      <Divider />
      <Box sx={styles.muteWrap}>
        <ConversationMuteSettings channelUrl={currentChannelId} otherCompanyName={otherCompanyName} />
      </Box>
      <Divider />
    </>
  );
};
