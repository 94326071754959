import { useQuery } from 'react-query';

import { getMessagesData } from 'app/api/messages/service';
import { MessageType } from 'app/api/messages/types';

interface UseFetchSystemMessagesProps {
  queryKey: string;
  enabled: boolean;
  quoteId?: number;
}

export const useFetchSystemMessages = ({ queryKey, quoteId, enabled }: UseFetchSystemMessagesProps) => {
  useQuery(
    [queryKey, quoteId],
    () =>
      getMessagesData({
        quote: quoteId,
        msgType: MessageType.SYSTEM,
      }),
    { enabled },
  );
};
