import { FC } from 'react';

import { Box, ListItem, Typography } from '@mui/material';

import { styles } from './styles';

interface TooltipListItemProps {
  label: string;
}

export const TooltipListItem: FC<TooltipListItemProps> = ({ label }): JSX.Element => (
  <ListItem sx={styles.listItem}>
    <Box sx={styles.listBullet} />
    <Typography sx={styles.text}>{label}</Typography>
  </ListItem>
);
