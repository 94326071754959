import { SearchAutocompleteResults, getAutocompleteSearch } from 'app/api/search';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppSelector } from 'state/hooks';
import { selectCrops } from 'state/slices/cropFamilies';

const DEBOUNCE_QUERY_MS = 120;
export const useSearchAutocomplete = () => {
  const [query, setQuery] = useState<string>('');
  const [debouncedQuery, setDebouncedQuery] = useState<string>('');
  const [results, setResults] = useState<SearchAutocompleteResults | undefined>(undefined);

  useEffect(() => {
    if (query === '') setResults(undefined);
    const timeout = setTimeout(() => {
      setDebouncedQuery(query);
    }, DEBOUNCE_QUERY_MS);
    return () => clearTimeout(timeout);
  }, [query]);
  const { data, isLoading } = useQuery(
    ['search/autocomplete', debouncedQuery],
    () => getAutocompleteSearch({ query: debouncedQuery }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
    },
  );
  useEffect(() => setResults(data), [data]);

  const crops = useAppSelector(selectCrops);

  return {
    crops,
    query,
    setQuery,
    isLoading,
    setResults,
    autocompleteResults: results || ({ crops: [] } as SearchAutocompleteResults),
  };
};
