import { MouseEvent, useCallback, useEffect } from 'react';

import { generatePath, useNavigate } from 'react-router-dom';
import * as tracker from 'analytics/tracker';

import { CompanyDetail } from 'app/api/company/types';
import { ROUTES } from 'app/routes/constants';
import { useModal, useImageHandlers, useAuth, useDeviceType } from 'hooks';
import { AnalyticsLinkType, AnalyticsName, getAnalyticsUserType, useLinkClickedEvent } from 'analytics';
import { QuoteRequestStatus, UpdateQuoteData, updateQuote } from 'app/api/quotes';
import { useMutation, useQueryClient } from 'react-query';

interface UseQuoteCardProps {
  id: number;
  isQuote: boolean;
  isSampleRequest: boolean;
  isDetailsOpened?: boolean;
  currentCycle: number;
  seller?: CompanyDetail;
  onCloseDetails?: () => void;
  onOpenDetails?: (id: string) => void;
}

export const useQuoteCard = ({
  id,
  isQuote,
  isSampleRequest,
  isDetailsOpened,
  currentCycle,
  seller,
  onCloseDetails,
  onOpenDetails,
}: UseQuoteCardProps) => {
  const { isMobileSize } = useDeviceType();
  const navigate = useNavigate();
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();
  const { handleImageError } = useImageHandlers();
  const { isBuyer } = useAuth();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  useEffect(() => {
    if (isModalOpen) {
      const analyticsOptions = {
        quote_id: id,
        user_type: getAnalyticsUserType(isBuyer),
        cycle_number: currentCycle,
        supplier_name: seller?.name ?? '',
        supplier_id: seller?.id ?? '',
        is_sample_request: isSampleRequest,
      };

      tracker.track(AnalyticsName.QUOTE_REVIEWED, analyticsOptions);
    }
  }, [currentCycle, id, isBuyer, isModalOpen, isSampleRequest, seller?.id, seller?.name]);

  useEffect(() => {
    if (isQuote && isDetailsOpened) handleOpenModal();
  }, [handleOpenModal, isDetailsOpened, isQuote]);

  const handleOpenDetails = useCallback(() => {
    handleCloseModal();
    onOpenDetails?.(id.toString());
  }, [handleCloseModal, id, onOpenDetails]);

  const handleCloseDetails = useCallback(() => {
    handleCloseModal();
    onCloseDetails?.();
  }, [handleCloseModal, onCloseDetails]);

  const handleClick = useCallback(() => {
    if (isQuote) {
      handleOpenDetails();
    } else {
      const path = generatePath(ROUTES.common.order, { id });
      navigate(path);
      handleTrackLinkClickEvent({ path, text: 'View Details', type: AnalyticsLinkType.BUTTON });
    }
  }, [isQuote, handleOpenDetails, id, navigate, handleTrackLinkClickEvent]);

  const handleCardClick = useCallback(
    (e?: MouseEvent) => {
      e?.stopPropagation();
      if (isMobileSize) {
        const quotePath = generatePath(
          isSampleRequest ? ROUTES.common.sampleRequestDetails : ROUTES.common.quoteRequestDetails,
          { id },
        );
        const orderPath = generatePath(ROUTES.common.order, { id });

        navigate(isQuote ? quotePath : orderPath);
      } else {
        handleClick();
      }
    },
    [id, isMobileSize, isQuote, isSampleRequest, navigate, handleClick],
  );

  const { mutate: handleUpdateQuote } = useMutation(async (data: UpdateQuoteData) => {
    await updateQuote(id, data);
  }, {});
  const queryClient = useQueryClient();

  const handleReopen = () => {
    handleUpdateQuote({ status: QuoteRequestStatus.OPEN });
    void Promise.all([
      queryClient.invalidateQueries({ queryKey: ['quote-requests'] }, { cancelRefetch: true }),
      queryClient.invalidateQueries({ queryKey: ['sample-requests'] }, { cancelRefetch: true }),
    ]);
  };

  return {
    isMobileSize,
    isModalOpen,
    handleImageError,
    handleClick,
    handleCloseDetails,
    handleCardClick,
    handleReopen,
  };
};
