import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { PasswordTooltip, CommonButton, FormError, LoadingButton, MaskedTextFormField } from 'components';
import { useChangePasswordForm } from './hooks';
import { validationRules } from './validation';
import { styles } from './styles';

export const ChangePasswordForm: FC = () => {
  const {
    control,
    isLoading,
    isMatchPasswordError,
    isCurrentPasswordError,
    matchPasswordError,
    currentPasswordError,
    handleConfirmChangePassword,
    handleFormChange,
    handleNavigateBack,
    handleSubmit,
  } = useChangePasswordForm();

  return (
    <form onSubmit={handleSubmit(handleConfirmChangePassword)} onChange={handleFormChange}>
      <Typography sx={styles.title}>Create New Password</Typography>

      <MaskedTextFormField
        control={control}
        name="oldPassword"
        rules={validationRules.required}
        placeholder="Current Password"
        isFormError={isCurrentPasswordError}
      />

      {isCurrentPasswordError && <FormError sx={styles.currentPasswordError} text={currentPasswordError} />}

      <MaskedTextFormField
        control={control}
        name="newPassword"
        rules={validationRules.password}
        tooltipElem={<PasswordTooltip />}
        isFormError={isMatchPasswordError}
        placeholder="New Password"
      />

      <MaskedTextFormField
        control={control}
        name="confirmPassword"
        rules={validationRules.required}
        tooltipElem={<PasswordTooltip />}
        isFormError={isMatchPasswordError}
        placeholder="Confirm New Password"
      />

      {isMatchPasswordError && <FormError text={matchPasswordError} />}

      <Box sx={styles.actionsWrap(isMatchPasswordError)}>
        <LoadingButton type="submit" loading={isLoading} sx={styles.button}>
          Change password
        </LoadingButton>

        <CommonButton variant="outlined" sx={styles.button} onClick={handleNavigateBack}>
          Cancel
        </CommonButton>
      </Box>
    </form>
  );
};
