import { FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { MapWrapper } from '../MapWrapper';
import { useTrackingMapSourceHandlers } from './hooks';
import { styles } from './styles';

const LOADING_ERROR_MESSAGE = `Sorry, the content couldn't be loaded.`;

interface MapSeactionProps {
  src: string;
}

export const MapSection: FC<MapSeactionProps> = ({ src }) => {
  const { isLoading, isError, isImage, handleLoadError } = useTrackingMapSourceHandlers({ src });

  return (
    <Box sx={styles.wrap}>
      {isLoading ? (
        <Box sx={styles.centerWrap}>
          <CircularProgress size={64} />
        </Box>
      ) : (
        <>
          {isError || !src.length ? (
            <Box sx={styles.centerWrap}>
              <Typography sx={styles.errorText}>{LOADING_ERROR_MESSAGE}</Typography>
            </Box>
          ) : (
            <MapWrapper isImage={isImage} src={src} handleImageError={handleLoadError} />
          )}
        </>
      )}
    </Box>
  );
};
