import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isOrderChatMode: boolean) => ({
    maxWidth: isOrderChatMode ? '70%' : '50%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '70%',
    },
  }),
  message: (isHighlighted: boolean) => ({
    display: 'inline-block',
    padding: '10px',
    fontSize: '10px',
    lineHeight: '12px',
    borderRadius: '10px',
    backgroundColor: isHighlighted ? customTheme.custom.sweetCorn : customTheme.custom.black.B10,
  }),
  userName: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,
  },
};
