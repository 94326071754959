import { useMutation, useQueryClient } from 'react-query';

import { createChannel } from 'app/api/SBChat';
import { notificationObserver } from 'utils/observer';

interface UseCreateChannelMutationProps {
  buyerCompany: number;
  sellerCompany: number;
  onSuccess?: () => void;
}

export const useCreateChannelMutation = ({ buyerCompany, sellerCompany, onSuccess }: UseCreateChannelMutationProps) => {
  const queryClient = useQueryClient();

  const { mutate: handleCreateChannel, isLoading: isCreateChannelLoading } = useMutation(
    () => createChannel({ buyerCompany, sellerCompany }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: ['channel-list'] }).then(() => {
          onSuccess?.();
        });
      },
      onError: () => {
        notificationObserver.publish({
          type: 'error',
          title: 'Something went wrong',
        });
      },
    },
  );

  return {
    isCreateChannelLoading,
    handleCreateChannel,
  };
};
