import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  drawer: {
    display: 'block',
    position: 'relative',
  },
  modal: {
    top: '96px',
    overflowY: 'auto',
    ...scrollMixin,

    '& .MuiBackdrop-root.MuiModal-backdrop': {
      background: 'transparent',
      top: '96px',
    },
  },
  paper: {
    top: '96px',
    height: `calc(100% - 96px)`,
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    boxShadow: 0,
  },
  inner: {
    padding: '4px 20px 28px',
    overflow: 'hidden',
    height: '100%',
  },
  filtersWrap: (isFullHeight: boolean) => ({
    maxHeight: `calc(100% - ${isFullHeight ? '50px' : '96px'})`,
    overflowY: 'auto',
    ...scrollMixin,
  }),
  header: (isPaddingRight: boolean) => ({
    height: '48px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    ...(isPaddingRight && {
      paddingRight: '56px',
    }),
  }),
  title: (isMarginRight: boolean) => ({
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 500,
    color: customTheme.custom.black.B70,
    flexGrow: 1,
    textAlign: 'center',
    marginLeft: '-32px',

    ...(isMarginRight && {
      marginRight: '-56px',
    }),
  }),
  closeButton: {
    padding: '4px',
    marginLeft: '-8px',

    path: {
      fill: customTheme.custom.black.B70,
    },
  },
  clearButton: {
    position: 'absolute',
    right: 0,
    top: '12px',
    height: '30px',
    minWidth: 'unset',
    fontSize: '12px',
    lineHeight: '20px',
    padding: '0 12px',
    background: customTheme.palette.primary.P70,
  },
  confirmButton: {
    width: '100%',
    marginTop: '8px',
  },
};
