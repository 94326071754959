import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  inputContainer: {
    marginBottom: '8px',
  },
  textInputLabel: {
    margin: '0px 0px 4px 0px',
    fontWeight: '400',
    fontSize: '14px',
    marginBottom: '2px',
    color: customTheme.custom.black.B70,
    '& svg': {
      marginLeft: '4px',
      width: '16px',
      height: '16px',
      path: {
        fill: customTheme.custom.black.B100,
      },
    },
  },
  description: {
    '&.MuiTooltip-tooltip': {
      fontSize: '12px',
      marginLeft: '8px !important',
    },
  },
};
