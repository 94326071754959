export const styles = {
  drawer: {
    display: 'block',
    position: 'relative',

    '& .MuiDrawer-paper': {
      boxSizing: 'border-box',
      width: '286px',
    },

    '& .MuiBackdrop-root': {
      background: 'rgba(29, 29, 29, 0.90)',
    },
  },
};
