import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isDocument: boolean) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    height: isDocument ? '206px' : '201px',
    borderRadius: '6px',
    border: `2px solid ${customTheme.palette.primary.P100}`,
    ...(isDocument && {
      marginTop: '58px',
    }),
  }),
  name: {
    position: 'absolute',
    top: '10px',
    right: '8px',
    left: '8px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    color: customTheme.palette.primary.P100,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
