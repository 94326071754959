import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  divider: (fullWidth: boolean) => ({
    borderColor: customTheme.custom.black.B10,

    ...(!fullWidth && {
      marginLeft: '50px',
      marginRight: '14px',
    }),
  }),
  mainWrap: {
    overflow: 'hidden',
  },
  wrap: (inStock: boolean) => ({
    padding: '16px 20px 6px 14px',
    display: 'flex',
    gap: '8px',
    minHeight: '40px',
    ...(!inStock && {
      backgroundColor: customTheme.custom.gray.G50,
    }),

    ':hover': {
      backgroundColor: customTheme.palette.primary.P5,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      ':hover': {
        backgroundColor: customTheme.custom.white,
      },
    },
  }),
  contentWrap: {
    display: 'grid',
    flex: 1,
  },
  crop: {
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 600,
    color: customTheme.custom.black.B50,
  },
  outOfStock: {
    fontSize: '12px',
    lineHeight: '17px',
    fontWeight: 600,
    color: customTheme.custom.black.B1000,
  },
  nameWrap: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 600,
    color: customTheme.custom.black.B50,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  name: {
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },
  description: {
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    display: '-webkit-box',
    WebkitLineClamp: 3.5,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: customTheme.custom.black.B70,
  },
  type: {
    marginTop: '2px',
    width: 'fit-content',
    height: '20px',
    boxSizing: 'border-box',
    justifySelf: 'end',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 6px',
    fontWeight: 600,
    fontSize: '8px',
    lineHeight: '12px',
    letterSpacing: '0.2px',
    borderRadius: '3px',
    color: customTheme.palette.secondary.S100,
    backgroundColor: customTheme.palette.secondary.S20,
  },
};
