import { Box, Typography } from '@mui/material';
import { FC, useCallback } from 'react';
import { CommonButton } from '../../../../components/CommonButton';
import { CommonModal } from '../../../../components/CommonPopup';
import { styles } from './styles';

interface ReviseQuotePopupProps {
  handleClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
}

export const ReviseQuotePopup: FC<ReviseQuotePopupProps> = ({ handleClose, onConfirm, isOpen = false }) => {
  const onClickConfirm = useCallback(() => {
    onConfirm();
    handleClose();
  }, [onConfirm, handleClose]);
  return (
    <CommonModal title="Revise Quote" isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        Would you like to revise this quote? If the buyer has already viewed your initial offer, this will send them a
        new notification with updated terms.{' '}
      </Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.close }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <CommonButton variant="text" color="primary" sx={{ ...styles.confirm }} onClick={onClickConfirm}>
          Confirm
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
