import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    padding: '12px 8px 12px',
    overflow: 'hidden',
  },
  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-4px',
  },
  checkboxLabel: {
    fontSize: '10px',
    lineHeight: '21px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
  },
};
