import { FC, MouseEvent } from 'react';

import { Box } from '@mui/material';

import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import {
  DashboardTabs,
  ProductNested,
  Quote,
  QuoteCardStatusesType,
  DashboardMainTabs,
  QuoteTotalQuantity,
} from 'app/api/quotes/types';
import { useModal } from 'hooks';
import { CompanyDetail } from 'app/api/company/types';
import { QuoteRequestDetails } from 'pages/DashboardPage/components/QuoteRequestDetails';
import { QuoteCardContent } from './QuoteCardContent';
import { QuoteCardMeta } from './QuoteCardMeta';
import { QuoteTotal } from './QuoteTotal';
import { useQuoteCard } from './hooks';
import { styles } from './styles';
import { ReopenQuoteModal } from './ReopenQuoteModal';

export interface QuoteCardProps {
  tab?: DashboardTabs;
  id: number;
  products: ProductNested[];
  company: Pick<CompanyDetail, 'id' | 'name' | 'address' | 'displayId'>;
  isSampleRequest: boolean;
  seller?: CompanyDetail;
  currentCycle?: number;
  status?: QuoteCardStatusesType;
  isCanceled?: boolean;
  isNew?: boolean;
  isHighlighted?: boolean;
  createdAt?: string | null;
  estimatedDeliveryDate?: string | null;
  deliveredAt?: string | null;
  isQuote?: boolean;
  isBuyer: boolean;
  quote?: Pick<Quote, 'status' | 'waitAnswer' | 'metadata'>;
  orderNumber?: string;
  isDetailsOpened?: boolean;
  onCloseDetails?: () => void;
  onOpenDetails?: (id: string) => void;
  totalQuantity?: QuoteTotalQuantity;
}

export const QuoteCard: FC<QuoteCardProps> = ({
  id,
  products,
  company,
  isSampleRequest,
  status,
  seller,
  currentCycle = 0,
  isCanceled = false,
  isNew = false,
  isHighlighted = false,
  createdAt = '',
  estimatedDeliveryDate = '',
  deliveredAt = '',
  tab = DashboardMainTabs.QUOTE_REQUESTS,
  orderNumber,
  isQuote = false,
  isDetailsOpened = false,
  onCloseDetails,
  isBuyer,
  quote,
  onOpenDetails,
  totalQuantity,
}) => {
  const {
    isMobileSize,
    handleReopen,
    isModalOpen,
    handleClick,
    handleCloseDetails,
    handleImageError,
    handleCardClick,
  } = useQuoteCard({
    id,
    isQuote,
    isSampleRequest,
    isDetailsOpened,
    currentCycle,
    seller,
    onCloseDetails,
    onOpenDetails,
  });

  const image = products.length ? products[0].thumbnail || products[0].image : null;
  const {
    open: isReopenQuoteModalOpen,
    handleOpenModal: handleOpenReopenQuoteModal,
    handleCloseModal: handleCloseReopenQuoteModal,
  } = useModal();

  const handleConfirmReopen = (e: MouseEvent) => {
    e.stopPropagation();
    handleCloseReopenQuoteModal();
    handleReopen();
  };
  return (
    <>
      <Box
        sx={{
          ...styles.wrap,
          ...(isCanceled && { ...styles.overlay }),
          ...(isHighlighted && !isCanceled && { ...styles.wrapHighlighted }),
        }}
        onClick={handleCardClick}
      >
        <Box sx={styles.innerWrap}>
          <Box
            component="img"
            loading="lazy"
            src={image || imagePlaceholder}
            alt="Quote Request"
            sx={{ ...styles.image }}
            onError={handleImageError(imagePlaceholder)}
          />
          <QuoteCardContent
            company={company}
            tab={tab}
            totalQuantity={totalQuantity}
            products={products}
            isNew={isNew}
            isBuyer={isBuyer}
            quote={quote}
            status={status}
          />
          {!isMobileSize && (
            <Box sx={styles.metaWrap}>
              <QuoteCardMeta
                quote={quote}
                status={status}
                onClickReopen={handleOpenReopenQuoteModal}
                isBuyer={isBuyer}
                company={company}
                isHighlighted={isHighlighted}
                createdAt={createdAt}
                estimatedDeliveryDate={estimatedDeliveryDate}
                deliveredAt={deliveredAt}
                onClick={handleClick}
                tab={tab}
                orderNumber={orderNumber}
              />
            </Box>
          )}
        </Box>
        <Box sx={styles.mobileFooterWrap}>
          <QuoteTotal
            totalQuantity={totalQuantity}
            tab={tab}
            createdAt={createdAt}
            estimatedDeliveryDate={estimatedDeliveryDate}
            deliveredAt={deliveredAt}
            orderNumber={orderNumber}
          />
        </Box>
        {isModalOpen && (
          <QuoteRequestDetails
            company={company}
            subTitle={`${company.address.city}, ${company.address.country}`}
            handleClose={handleCloseDetails}
            isOpen={isModalOpen}
            quoteId={id}
            isSampleRequest={isSampleRequest}
          />
        )}
      </Box>
      {isReopenQuoteModalOpen && (
        <ReopenQuoteModal
          isOpen={isReopenQuoteModalOpen}
          handleClose={handleCloseReopenQuoteModal}
          handleConfirm={handleConfirmReopen}
          isBuyer={isBuyer}
        />
      )}
    </>
  );
};
