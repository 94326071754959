import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  badge: (isActive: boolean) => ({
    '& .MuiBadge-badge': {
      minWidth: '16px',
      height: '16px',
      position: 'relative',
      marginLeft: '4px',
      padding: '0 4px',
      fontFamily: 'inherit',
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '14px',
      transform: 'translateY(50%)',
      top: 'calc(4px - 50%)',
      color: isActive ? customTheme.palette.secondary.S100 : customTheme.custom.white,
      backgroundColor: isActive ? customTheme.custom.white : customTheme.palette.secondary.S100,

      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        top: '-7px',
      },
    },
    '& .MuiBadge-invisible': {
      display: 'none',
    },
  }),
};
