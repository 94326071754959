import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: (isPadding: boolean) => ({
    marginLeft: '-4px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0 0 8px 0',

      ...(isPadding && {
        margin: '24px 0',
        padding: '0 28px',
      }),
    },
  }),
  documentsWrap: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridTemplateColumns: '1fr',
    },
  },
};
