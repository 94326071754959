import { FC } from 'react';

import { useMatch } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { ROUTES } from 'app/routes/constants';
import { FooterLinks } from '../FooterLinks';
import { styles } from './styles';

export const Footer: FC = () => {
  const isShadow = Boolean(useMatch(ROUTES.common.messages));

  return (
    <Box sx={{ ...styles.mainWrap(isShadow) }}>
      <Box sx={{ ...styles.wrap }}>
        <FooterLinks />
        <Typography sx={{ ...styles.companyInfo }}>Sproutzo Inc.</Typography>
      </Box>
    </Box>
  );
};
