import { customTheme } from '../../theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '24px',
  },
  control: {
    width: '100%',
    borderRadius: '25px',
  },
  inputWrap: (withoutHighlights?: boolean) => ({
    border: `1px solid ${customTheme.custom.black.B10}`,
    borderRadius: '25px',
    fontSize: '14px',
    color: customTheme.custom.black.B100,
    ...(!withoutHighlights && {
      '&.Mui-focused': {
        border: `1px solid ${customTheme.palette.primary.main}`,
      },
    }),
    '&.Mui-disabled': {
      background: customTheme.custom.black.B5,
      color: customTheme.custom.black.B50,
      border: 'none',
    },
  }),
  inputWrapError: {
    border: `1px solid ${customTheme.custom.error}`,

    '&.Mui-focused': {
      border: `1px solid ${customTheme.custom.error}`,
    },

    ':hover': {
      border: `1px solid ${customTheme.custom.error}`,
      backgroundColor: customTheme.custom.black.B5,
    },
  },
  inputWrapFormError: {
    border: `2px solid ${customTheme.custom.error}`,

    '&.Mui-focused': {
      border: `2px solid ${customTheme.custom.error}`,
    },

    ':hover': {
      border: `2px solid ${customTheme.custom.error}`,
      backgroundColor: customTheme.custom.black.B5,
    },
  },
  input: (hasStartAdornment?: boolean) => ({
    height: '31px',
    padding: '5px 16px 4px',
    ...(hasStartAdornment && {
      padding: '5px 16px 4px 4px',
    }),
    borderRadius: '25px',

    '&::placeholder': {
      color: customTheme.custom.black.B50,
      opacity: 1,
    },
  }),
  startAdornment: {
    margin: '0 0 0 8px',
  },

  endAdornment: {
    margin: '0 16px 0 0',
  },
  errorBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1px',
  },
  errorText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.custom.error,
  },
  tooltip: {
    margin: '0',
    padding: '16px',
    borderRadius: '12px',
    backgroundColor: customTheme.custom.white,
    color: customTheme.custom.black.B70,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  tooltipHelper: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorIcon: {
    display: 'flex',
    width: '16px',
    height: '16px',
  },
};
