export const orderDocuments = [
  {
    value: 'Phytosanitary Certificates',
    label: 'Phytosanitary Certificates',
  },
  {
    value: 'Import Permits',
    label: 'Import Permits',
  },
  {
    value: 'Quality Certificates',
    label: 'Quality Certificates',
  },
  {
    value: 'ISTA Certificates',
    label: 'ISTA Certificates',
  },
  {
    value: 'Treatment Certificates',
    label: 'Treatment Certificates',
  },
  {
    value: 'Certificates of Origin',
    label: 'Certificates of Origin',
  },
  {
    value: 'Non-GMO Certificates',
    label: 'Non-GMO Certificates',
  },
  {
    value: 'Purchase order',
    label: 'Purchase order',
  },
  {
    value: 'Proforma Invoice',
    label: 'Proforma Invoice',
  },
  {
    value: 'Commercial invoice',
    label: 'Commercial invoice',
  },
];
