import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paper: {
    margin: 0,
    width: '100%',
    padding: 0,
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    border: `1px solid ${customTheme.custom.black.B10}`,

    '&.MuiAccordion-root, & .Mui-expanded': {
      margin: 0,
    },
    '&.MuiAccordion-root.MuiAccordion-gutters': {
      overflow: 'hidden',
      borderRadius: '10px',
    },
  },
  accordionSummary: {
    padding: 0,
    borderRadius: '10px 10px 0 0',
    backgroundColor: customTheme.palette.primary.P5,

    '&.Mui-expanded': {
      minHeight: 'unset',
      borderRadius: '10px 10px 0 0',
    },

    '&.MuiAccordionSummary-root.MuiButtonBase-root': {
      cursor: 'default',
    },

    '& .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters': {
      margin: 0,
    },

    '& .MuiAccordionSummary-content': {
      padding: 0,
      width: '100%',
    },
  },
};
