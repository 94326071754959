import { FC } from 'react';

import { Box } from '@mui/material';

import { DashboardTabs, QuoteRequestsListData } from 'app/api/quotes/types';
import { QuoteCard } from 'components';
import { getQuoteUniqueProductsArray } from '../../utils';
import { styles } from './styles';

interface QuoteRequestsListProps {
  isBuyer: boolean;
  isCanceledSection: boolean;
  data: QuoteRequestsListData;
  tab?: DashboardTabs;
  openedQuoteId?: string;
  onCloseDetails?: () => void;
  onOpenDetails?: (id: string) => void;
}

export const QuoteRequestsList: FC<QuoteRequestsListProps> = ({
  isBuyer,
  isCanceledSection,
  data,
  tab,
  openedQuoteId,
  onCloseDetails,
  onOpenDetails,
}) => {
  return (
    <Box sx={{ ...styles.listWrap }}>
      {data.map((quote) => (
        <QuoteCard
          key={quote.id}
          id={quote.id}
          products={getQuoteUniqueProductsArray(quote, false)}
          company={isBuyer ? quote.seller : quote.buyer}
          totalQuantity={quote.totalQuantity}
          status={quote.status}
          isCanceled={isCanceledSection}
          createdAt={quote.createdAt}
          seller={quote.seller}
          tab={tab}
          isBuyer={isBuyer}
          quote={quote}
          isNew={false}
          isHighlighted={quote.highlight}
          isQuote
          isDetailsOpened={openedQuoteId === quote.id.toString()}
          currentCycle={quote.dealInteractionsCounter ?? 0}
          isSampleRequest={quote.isSampleType}
          onCloseDetails={onCloseDetails}
          onOpenDetails={onOpenDetails}
        />
      ))}
    </Box>
  );
};
