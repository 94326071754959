import { customTheme } from 'theme/MuiThemeProvider/theme';

export const quoteDetailsChatStyles = {
  padding: '20px',
  borderRadius: '10px',
  height: '320px',

  '& .sendbird-conversation__messages': {
    height: '200px',
    maxHeight: '200px',
  },

  '& .sendbird-conversation': {
    borderRadius: '10px',
    overflow: 'hidden',
    border: `1px solid ${customTheme.custom.black.B10}`,
  },
};
