import { InfiniteData, InfiniteQueryObserverResult, useInfiniteQuery } from 'react-query';

import { ProductsListData, ProductsListRequest } from 'app/api/products/types';
import { getProductsListData } from 'app/api/products/service';

interface UseProductsListQueryProps extends ProductsListRequest {
  cacheTime?: number;
  enabled?: boolean;
  onSuccessAction?: (data?: InfiniteData<ProductsListData>) => void;
}

interface ProductsListQuery {
  data: InfiniteData<ProductsListData> | undefined;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => Promise<InfiniteQueryObserverResult<ProductsListData>>;
}

export const useProductsListQuery = ({
  limit,
  company,
  crop,
  cropFamily,
  search,
  geneticType,
  color,
  continent,
  sort,
  featured,
  featuredSeller,
  minWeight,
  maxWeight,
  applyWeightFilter,
  maturityMin,
  maturityMax,
  applyMaturityFilter,
  // SPZ-1550
  // resistances,
  applyResistancesFilter,
  organic,
  cacheTime,
  enabled = true,
  onSuccessAction,
}: UseProductsListQueryProps): ProductsListQuery => {
  const { data, isLoading, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery(
    [
      'products-list',
      limit,
      company,
      search,
      geneticType,
      color,
      continent,
      sort,
      crop,
      cropFamily,
      featured,
      featuredSeller,
      minWeight,
      maxWeight,
      applyWeightFilter,
      maturityMin,
      maturityMax,
      applyMaturityFilter,
      // SPZ-1550
      // resistances,
      applyResistancesFilter,
      organic,
    ],
    ({ pageParam = 0 }) =>
      getProductsListData({
        limit,
        offset: pageParam,
        company,
        search,
        crop,
        cropFamily,
        geneticType,
        color,
        continent,
        sort,
        featured,
        featuredSeller,
        minWeight,
        maxWeight,
        applyWeightFilter,
        maturityMin,
        maturityMax,
        applyMaturityFilter,
        // SPZ-1550
        // resistances,
        applyResistancesFilter,
        organic,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
      cacheTime,
      getNextPageParam: (lastPage) => {
        return lastPage.next && new URL(lastPage.next).searchParams.get('offset');
      },
      onSuccess: (data) => {
        onSuccessAction?.(data);
      },
    },
  );

  return {
    data,
    isLoading,
    isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    fetchNextPage,
  };
};
