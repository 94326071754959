import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import { resetPasswordStart } from 'app/api/auth/service';
import { ResetPasswordStartRequest } from 'app/api/auth/types';
import { ROUTES } from 'app/routes/constants';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { ResetPasswordFormValues, ResetPasswordStepType, ResetPasswordSteps } from '../types';

interface ResetPasswordPageHandlers {
  isLoading: boolean;
  currentStep: ResetPasswordStepType;
  handleSubmitResetPassword: (values: ResetPasswordFormValues) => void;
  handleBackButtonClick: () => void;
}

export const useResetPasswordPageHandlers = (): ResetPasswordPageHandlers => {
  const [currentStep, setCurrentStep] = useState<ResetPasswordStepType>(ResetPasswordSteps.resetPasswordForm);
  const navigate = useNavigate();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const { mutate: resetPasswordStartMutation, isLoading } = useMutation(
    (data: ResetPasswordStartRequest) => resetPasswordStart(data),
    {
      onSettled: () => {
        setCurrentStep(ResetPasswordSteps.infoMessage);
      },
    },
  );

  const handleBackButtonClick = useCallback(() => {
    navigate(ROUTES.buyer._);
    handleTrackLinkClickEvent({ path: ROUTES.buyer._, type: AnalyticsLinkType.BUTTON, text: 'Cancel' });
  }, [handleTrackLinkClickEvent, navigate]);

  const handleSubmitResetPassword = useCallback(
    ({ email }: ResetPasswordFormValues) => {
      resetPasswordStartMutation({
        email: email.toLowerCase(),
      });
    },
    [resetPasswordStartMutation],
  );

  return { isLoading, currentStep, handleSubmitResetPassword, handleBackButtonClick };
};
