import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { Control } from 'react-hook-form';
import { useDeviceType } from 'hooks';
import { CommonModal } from '../../../../components/CommonPopup';
import { CommonButton } from '../../../../components/CommonButton';
import { LoadingButton } from '../../../../components/LoaderButton';

import { styles } from './styles';
import { DocumentsList } from '../../../PlaceOrderPage/components/DocumentsList';
import { SxPropsTypes } from '../../../../theme/MuiThemeProvider/types';
import { CheckboxFilterItem } from '../../../../components/CheckboxFilter/types';
import { usePlaceOrderPage } from './hooks/useAddDocumentsPopup';

interface AddDocumentsPopupProps {
  handleClose: () => void;
  isOpen: boolean;
  documents: CheckboxFilterItem[];
}

export const AddDocumentsPopup: FC<AddDocumentsPopupProps> = ({ handleClose, documents, isOpen = false }) => {
  const { isMobileSize } = useDeviceType();
  const { control, handleSubmit, onSubmit, isDocumentsLoading } = usePlaceOrderPage(handleClose);

  return (
    <CommonModal
      title="Choose documents"
      isOpen={isOpen}
      handleClose={handleClose}
      paperStyles={(isMobileSize ? styles.mobilePaperStyles : styles.paperStyles) as SxPropsTypes}
      data-testid="add-documents-popup"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ ...styles.content }}>Which document types would you like to add to this order?</Typography>

        <DocumentsList control={control as unknown as Control} documents={documents} isListPadding={false} />

        <Box sx={{ ...styles.actions }}>
          <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
            Cancel
          </CommonButton>
          <LoadingButton
            loading={isDocumentsLoading}
            type="submit"
            variant="text"
            color="inherit"
            sx={{ ...styles.confirmButton }}
            onClick={() => false}
          >
            Confirm
          </LoadingButton>
        </Box>
      </form>
    </CommonModal>
  );
};
