import { ChangeEvent, FC } from 'react';

import { NativeSelect } from '@mui/material';
import { styles } from './styles';
import { BaseInput } from '../BaseInput';

export interface SelectInputProps {
  label: string;
  field: string;
  value?: number;
  description?: string;
  full?: boolean;
  choices: ({ id: number; name: string } | string)[];
  onChange: (field: string, value: string) => void;
}

export const SelectInput: FC<SelectInputProps> = ({
  label,
  field,
  value,
  choices,
  full = false,
  onChange,
  description,
}) => {
  const onInputChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(field, e.target.value);
  };
  const options = choices.map((choice) => {
    if (typeof choice === 'string') {
      return { id: choice, name: choice };
    }
    return choice;
  });
  return (
    <BaseInput label={label} description={description}>
      <NativeSelect
        variant="standard"
        onChange={onInputChange}
        value={value}
        sx={styles.nativeInput(full)}
        disableUnderline
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </NativeSelect>
    </BaseInput>
  );
};
