import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as PlusIcon } from 'assets/icons/plusBig.svg';
import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import { useModal } from 'hooks';
import { AddDocumentsPopup } from '../AddDocumentsPopup';
import { styles } from './styles';

interface AddDocumentsProps {
  documents: CheckboxFilterItem[];
}

export const AddDocuments: FC<AddDocumentsProps> = ({ documents }): JSX.Element => {
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();

  return (
    <>
      <Box sx={{ ...styles.wrap }} onClick={handleOpenModal} data-testid="add-documents-btn">
        <Box sx={{ ...styles.plusIconBox }} data-testid="plus-button">
          <PlusIcon />
        </Box>

        <Typography sx={{ ...styles.addText }}>Add document</Typography>
      </Box>

      {isModalOpen && <AddDocumentsPopup handleClose={handleCloseModal} isOpen={isModalOpen} documents={documents} />}
    </>
  );
};
