import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

interface SideBarSectionTagProps {
  title: string;
}

export const SideBarSectionTag: FC<SideBarSectionTagProps> = ({ title }) => {
  return (
    <Box sx={styles.wrap}>
      <Typography sx={styles.label} component="span">
        {title}
      </Typography>
    </Box>
  );
};
