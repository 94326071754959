import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    flexGrow: 1,
    width: '100%',
    background: customTheme.custom.black.B70,
    overflow: 'hidden',
  },
  centerWrap: {
    height: '100%',
    maxHeight: '100%',
    minHeight: '200px',
    padding: '24px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: '232 / 198',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '14px',
    },
  },
  errorText: {
    fontSize: '20px',
    fontWeight: 500,
    color: customTheme.custom.white,
    textAlign: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      fontWeight: 500,
    },
  },
};
