import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { FormError, PageLoader } from 'components';
import { DeliveryDates, DocumentsList, CreateOrderWrapper, CreateOrderActions } from './components';
import { orderDocuments } from './components/DocumentsList/constants';
import { usePlaceOrderPage } from './hooks';
import { styles } from './styles';

export const PlaceOrderPage: FC = (): JSX.Element => {
  const { control, handleCancel, handleSubmit, onSubmit, errors, formError, isLoading, quoteData, isCreateLoading } =
    usePlaceOrderPage();

  if (isLoading) {
    return (
      <Box sx={styles.loaderWrap}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.wrap }} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={styles.pageTitle}>Confirm Order</Typography>

      <Box sx={styles.documentsSection}>
        <Typography sx={{ ...styles.title }}>Document Request</Typography>
        <Typography sx={{ ...styles.description }}>
          Please choose the documents you need from the seller and a few dates for your ideal delivery timeline. A
          member of the Sproutzo team will be in touch to discuss delivery specifics!
        </Typography>

        <Typography sx={{ ...styles.subTitle }}>Ideal delivery dates</Typography>

        <DeliveryDates control={control} errors={errors} />

        <Typography sx={{ ...styles.subTitle, ...styles.listTitle }}>Select needed documents</Typography>
        {!!formError && <Typography sx={{ ...styles.subTitleError }}>Choose at least 1 option.</Typography>}

        <DocumentsList control={control as unknown as Control} documents={orderDocuments} isListPadding />

        {!!formError && (
          <Box sx={styles.formErrorWrap}>
            <FormError text={formError} />
          </Box>
        )}
      </Box>

      <CreateOrderWrapper quoteData={quoteData} isLoading={isCreateLoading} handleCancel={handleCancel} />

      <Box sx={styles.actionsWrap}>
        <CreateOrderActions isLoading={isLoading} handleCancel={handleCancel} />
      </Box>
    </Box>
  );
};
