import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '100%',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  title: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    maxWidth: '160px',
    flex: '160px 1 0',
  },
  value: {
    flex: '1 0',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'left',
    color: customTheme.custom.gray.G600,
    wordBreak: 'break-all',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  unlock: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'underline',
    svg: {
      height: '16px',
      width: '16px',
      path: {
        fill: customTheme.custom.white,
      },
    },
  },
  tooltipWrap: {
    padding: '8px',
    fontSize: '12px',
    backgroundColor: 'rgba(39, 139, 159, 0.9)',
  },
};
