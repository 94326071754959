import { Dispatch, FC, ReactNode, SetStateAction, createContext, useCallback, useMemo, useState } from 'react';

import { useQuery } from 'react-query';
import SessionHandler from '@sendbird/uikit-react/handlers/SessionHandler';
import { SendbirdChat } from '@sendbird/chat/lib/__definition';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';

import { createChatToken } from 'app/api/SBChat';
import { clearSendbirdConfig, setSendbirdToken, storage } from 'utils/storage';
import { customColorSet } from './themeConfig';

interface ProviderProps {
  children: ReactNode;
}

interface ISbWrap {
  sbToken: string | null;
  sbUserId: string | null;
  setSbToken: Dispatch<SetStateAction<string | null>>;
  setSbUserId: Dispatch<SetStateAction<string | null>>;
  configureSession: (sdk: SendbirdChat) => SessionHandler;
}

export const SbWrapContext = createContext<ISbWrap>({} as ISbWrap);

export const SendBirdWrapProvider: FC<ProviderProps> = ({ children }) => {
  const [sbToken, setSbToken] = useState<string | null>(storage.getItem('sendbirdToken'));
  const [sbUserId, setSbUserId] = useState<string | null>(storage.getItem('sendbirdUserId'));

  const activeUserId = storage.getItem('userId', 'sessionStorage');

  const appId = process.env.REACT_APP_SENDBIRD_APP_ID ?? '';

  const getChatToken = useQuery(['sendbird-token'], () => createChatToken(), {
    enabled: false,
  });

  const handleSessionClosed = useCallback(
    (sdk: SendbirdChat) => {
      if (!activeUserId) {
        clearSendbirdConfig();
        sdk
          .disconnect()
          .then(() => {
            /* empty */
          })
          .catch(() => {
            /* empty */
          });
      } else {
        getChatToken
          .refetch()
          .then(({ data }) => {
            const token = data?.token ?? '';

            if (token.length) {
              setSendbirdToken(token);
              setSbToken(token);
            }
          })
          .catch(() => {
            /* empty */
          });
      }
    },
    [activeUserId, getChatToken],
  );

  const issueSessionToken = useCallback(async () => {
    const newToken = getChatToken
      .refetch()
      .then(({ data }) => {
        const token = data?.token ?? '';
        return token;
      })
      .catch(() => {
        /* empty */
      });

    return newToken;
  }, [getChatToken]);

  const configureSession = useCallback(
    (sdk: SendbirdChat) => {
      const sessionHandler = new SessionHandler();

      sessionHandler.onSessionTokenRequired = () => {
        issueSessionToken()
          .then((token) => {
            setSbToken(token ?? '');
            setSendbirdToken(token ?? '');
          })
          .catch(() => {
            /* empty */
          });
      };

      sessionHandler.onSessionClosed = () => {
        handleSessionClosed(sdk);
      };

      return sessionHandler;
    },
    [handleSessionClosed, issueSessionToken],
  );

  const value = useMemo(
    () => ({
      sbToken,
      setSbToken,
      sbUserId,
      setSbUserId,
      configureSession,
    }),
    [sbToken, setSbToken, sbUserId, setSbUserId, configureSession],
  );

  return (
    <SbWrapContext.Provider value={value}>
      <SendbirdProvider
        appId={appId}
        userId={sbUserId ?? ''}
        accessToken={sbToken ?? ''}
        configureSession={configureSession}
        colorSet={customColorSet}
        disableMarkAsDelivered
      >
        <>{children}</>
      </SendbirdProvider>
    </SbWrapContext.Provider>
  );
};
