import { FC } from 'react';

import { CommonButton } from 'components';
import { styles } from './styles';

interface ViewButtonOutlinedProps {
  handleClick: () => void;
}

export const ViewButtonOutlined: FC<ViewButtonOutlinedProps> = ({ handleClick }) => {
  return (
    <CommonButton variant="outlined" color="inherit" sx={styles.viewButton} onClick={handleClick}>
      View
    </CommonButton>
  );
};
