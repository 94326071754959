import * as Sentry from '@sentry/react';

const DSN = 'https://3a963472b893462c0e96337463f8e31f@o4504775776534528.ingest.sentry.io/4505901246119936';

const isIgnored = (exception: unknown) =>
  exception && typeof exception === 'string' && exception.includes('SendbirdProvider');

export const initializeSentry = () => {
  const environment = process.env.REACT_APP_ENV;

  if (environment === 'test' || environment === 'production') {
    Sentry.init({
      dsn: DSN,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: ['https://.*.sproutzo.com/.*'],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment,
      beforeSend: (event, hint) => {
        const exception = hint?.originalException;

        if (isIgnored(exception)) {
          return null;
        }

        return event;
      },
    });
  }
};
