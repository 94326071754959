import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    marginTop: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',
  },
  completedLabel: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
    color: customTheme.palette.secondary.S100,
  },
  undoButton: {
    height: '18px',
    minWidth: '32px',
    padding: 0,
    fontSize: '12px',
    fontWeiht: '400',
    lineHeight: '18px',
    color: customTheme.custom.black.B70,
    textDecoration: 'underline',

    ':hover': {
      color: customTheme.custom.black.B70,
      background: 'transparent',
      textDecoration: 'underline',
    },
  },
  allCompleteButton: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '36px',
      minWidth: 'fit-content',
      padding: '6px 10px',
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
};
