import { SxProps } from '@mui/material';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  switchArrow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    color: customTheme.custom.black.B100,
  },
  divider: {
    borderColor: customTheme.custom.black.B10,
  },
  menuItemWrap: {
    padding: '8px 24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      '&.MuiButtonBase-root.MuiMenuItem-root': {
        padding: '6px 24px',
        minHeight: '40px',
      },

      ':hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  menuItemText: {
    marginLeft: '8px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export const logoutItemStyles: SxProps = {
  color: customTheme.custom.error,
};
