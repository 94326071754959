import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    padding: '32px 0px 24px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100vw',
      minHeight: '164px',
      marginLeft: '-14px',
      marginRight: '-14px',
      padding: '16px 16px',
      borderRadius: 0,
      overflow: 'visible',
    },
  },
  control: {
    maxWidth: '590px',
  },
  title: {
    textAlign: 'center',
    color: customTheme.custom.black.B1000,
    fontFamily: 'Poppins',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: 1.3,
    marginBottom: '4px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      marginBottom: '8px',
      fontSize: '24px',
      lineHeight: '28px',
      maxWidth: '300px',
    },
  },
  titleSpaceAbove: {
    paddingTop: '40px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingTop: '0px',
    },
  },
  subTitle: {
    textAlign: 'center',
  },
  v1Container: {
    // maxWidth: '600px',
  },
  primary: {
    color: customTheme.palette.primary.main,
  },
  secondary: {
    color: customTheme.palette.secondary.main,
  },
  orange: {
    color: '#FF9737',
  },
  financing: {
    color: '#727272',
  },
  seedGrain: {
    textAlign: 'center',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: 1.3,
    fontStyle: 'italic',
    textDecoration: 'underline',
    color: customTheme.palette.secondary.main,
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      fontSize: '24px',
    },
  },
  forCountry: {
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: 1.3,
    textDecoration: 'none',
    color: '#B4B4B4',
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      fontSize: '24px',
      display: 'block',
    },
  },
  v2: {
    color: customTheme.palette.secondary.main,
  },
  v2description: {
    lineHeight: 0.3,
    marginBottom: '32px',
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      lineHeight: 1,
      marginBottom: '0px',
    },
  },
  sub: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    color: customTheme.custom.black.B70,
    fontWeight: '600',
    marginBottom: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '18px',
      maxWidth: '100%',
    },
  },
  controlDescription: { maxWidth: '460px' },
  description: {
    textAlign: 'center',
    lineHeight: 2,
    fontSize: '18px',
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      fontSize: '14px',
    },
  },
  pill: (color: string) => ({
    border: `1px solid ${color}`,
    color,
    padding: '0px 8px',
    borderRadius: '16px',
  }),
};
