import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { CommonModal } from '../../../../components/CommonPopup';
import { CommonButton } from '../../../../components/CommonButton';

import { styles } from './styles';

interface AddRejectReasonPopupProps {
  handleClose: () => void;
  isOpen: boolean;
  declineReason: string;
}

export const DeclineReasonPopup: FC<AddRejectReasonPopupProps> = ({ handleClose, isOpen = false, declineReason }) => {
  return (
    <CommonModal title="Decline reason" isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>{declineReason}</Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Go Back
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
