import { useState, useLayoutEffect, useMemo } from 'react';

import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useLayoutEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktopSize = useMemo(() => windowSize.width >= breakpoints.laptop, [windowSize.width]);
  const isMobileSize = useMemo(() => windowSize.width <= breakpoints.tablet, [windowSize.width]);

  return {
    isDesktopSize,
    isMobileSize,
  };
};
