import { FC } from 'react';

import { SvgIcon } from '@mui/material';

import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow.svg';
import { CommonButton } from '../CommonButton';
import { styles } from './styles';

interface CustomArrowButtonProps {
  direction: 'next' | 'prev';
  className?: string;
  style?: { [key: string]: string };
  onClick?: () => void;
}

export const CustomArrowButton: FC<CustomArrowButtonProps> = ({ direction = 'next', className, style, onClick }) => {
  const isPrev = direction === 'prev';
  const isDisabled = className?.includes('slick-disabled');

  return (
    <CommonButton
      variant="outlined"
      disabled={isDisabled}
      sx={{ ...styles.button }}
      style={{ ...style }}
      className={className}
      onClick={onClick}
    >
      <SvgIcon sx={{ ...(isPrev && styles.arrowLeft) }}>
        <ArrowRightIcon />
      </SvgIcon>
    </CommonButton>
  );
};
