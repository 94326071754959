import { FC } from 'react';

import { MenuItem, Typography } from '@mui/material';

import { styles } from './styles';

interface CardMenuItemProps {
  text: string;
  menuAction: () => void;
}

export const CardMenuItem: FC<CardMenuItemProps> = ({ text, menuAction }) => {
  return (
    <MenuItem sx={{ ...styles.menuItemWrap }} onClick={menuAction}>
      <Typography sx={{ ...styles.menuItemText }}>{text}</Typography>
    </MenuItem>
  );
};
