import { useMemo } from 'react';

import { SBChannelMetaData } from 'app/api/SBChat';

interface UseChannelMetaDataProps {
  isBuyer: boolean;
  channelSBName?: string;
  metaData?: SBChannelMetaData;
}

export const useChannelMetaData = ({ isBuyer, channelSBName = '', metaData = {} }: UseChannelMetaDataProps) => {
  const channelName = useMemo(
    () => (isBuyer ? metaData?.seller_company_name : metaData?.buyer_company_name) ?? channelSBName,
    [channelSBName, isBuyer, metaData?.buyer_company_name, metaData?.seller_company_name],
  );

  const channelLogo = useMemo(
    () => (isBuyer ? metaData?.seller_company_logo : metaData?.buyer_company_logo),
    [isBuyer, metaData?.buyer_company_logo, metaData?.seller_company_logo],
  );

  return {
    channelLogo,
    channelName,
  };
};
