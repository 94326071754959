import { FC } from 'react';

import { SummaryDataType } from '../../types';
import { InfoItem } from '../InfoItem';
import { SectionItemWrap } from '../SectionItemWrap';
import { SectionTag } from '../SectionTag';

type SummarySectionProps = SummaryDataType;

export const SummarySection: FC<SummarySectionProps> = ({ origin, destination, transportMode }) => {
  return (
    <SectionItemWrap isBorderBottom>
      <SectionTag label="Summary" />
      <InfoItem label="Origin" value={origin} />
      <InfoItem label="Desitination" value={destination} />
      <InfoItem label="Transport Modes" value={transportMode} />
    </SectionItemWrap>
  );
};
