import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '40px',
    height: '40px',
    color: customTheme.palette.secondary.S50,

    ':hover': {
      color: customTheme.palette.secondary.S70,
      cursor: 'pointer',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '32px',
      height: '32px',

      svg: {
        width: '32px',
        height: '32px',
      },
    },
  },
};
