import { useCallback, useEffect, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { CropFamily } from 'app/api/crops/types';

interface UseSideCategoriesBarProps {
  isOverlay?: boolean;
  isPermanent?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export const useSideCategoriesBar = ({ isOverlay, isPermanent, onOpen, onClose }: UseSideCategoriesBarProps) => {
  const [currentCategory, setCurrentCategory] = useState<null | CropFamily>(null);
  const { pathname } = useLocation();
  const { type, subType } = useParams();

  const isBackdropShown = isOverlay || !!currentCategory;

  const handleOpenCategory = useCallback(
    (category: CropFamily) => () => {
      setCurrentCategory(category);

      if (isPermanent) {
        onOpen?.();
      }
    },
    [onOpen, isPermanent],
  );

  const handleClose = useCallback(() => {
    setCurrentCategory(null);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    if (isPermanent) handleClose();
  }, [onClose, pathname, isPermanent, handleClose]);

  return { currentCategory, isBackdropShown, type, subType, handleOpenCategory, handleClose };
};
