import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '100%',
    height: '68px',
    padding: '8px 16px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: customTheme.palette.secondary.S20,
  },
  totals: {
    fontSize: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },
  items: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '16px',
  },
  itemWrap: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  title: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B70,
    whiteSpace: 'nowrap',
  },
  amount: {
    fontSize: '12px',
    lineHight: '16px',
    fontWeight: 600,
    color: customTheme.custom.info,
    whiteSpace: 'pre-wrap',
  },
  price: {
    fontSize: '12px',
    lineHight: '16px',
    fontWeight: 600,
    color: customTheme.palette.secondary.S100,
    whiteSpace: 'nowrap',
  },
};
