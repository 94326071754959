import { useQuery } from 'react-query';

import { getPaymentInfoData } from 'app/api/orders';

interface PaymentInfoQueryI {
  id: number;
  enabled: boolean;
  userId?: number;
}

export const usePaymentInfoQuery = ({ id, enabled, userId }: PaymentInfoQueryI) => {
  const { isLoading, isFetching, data } = useQuery(['payment-info', id, userId], () => getPaymentInfoData(id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled,
  });

  return {
    isLoading: isLoading || isFetching,
    data,
  };
};
