import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { styles } from './styles';

interface SectionItemWrapProps {
  children: ReactNode;
  isBorderBottom?: boolean;
}

export const SectionItemWrap: FC<SectionItemWrapProps> = ({ children, isBorderBottom = false }) => {
  return <Box sx={styles.wrap(isBorderBottom)}>{children}</Box>;
};
