import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '395px',
    minWidth: '395px',
    height: 'fit-content',
    padding: '16px',
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      minWidth: 'unset',
      marginBottom: '24px',
      padding: '14px',
      gridRow: 2,
      overflowX: 'hidden',
      boxSizing: 'border-box',
    },
  },
  title: {
    marginBottom: '16px',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '14px',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  total: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',
    marginBottom: '8px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
  },
  totalText: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '15px',
    },
  },
  totalTitle: {
    color: customTheme.custom.black.B100,
  },
  totalAmount: {
    color: customTheme.custom.info,
  },
  totalPrice: {
    color: customTheme.palette.secondary.S100,
  },
  actionsWrap: {
    marginTop: '16px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
