import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { CompanyDetail } from 'app/api/company/types';
import { Product } from 'app/api/products/types';
import { PageLoader } from 'components';
import { CurrencyBadge } from 'components/CurrencyBadge';
import { UnitsType } from 'app/api/quotes/types';
import { Currency } from 'utils/currencyFormat';
import { EmptyQuotesState } from '../EmptyQuotesState';
import { ChoosedProducts } from '../ChoosedProducts';
import { CompanyProductsTable } from '../CompanyProductsTable';
import { useQuoteRequestFormWrapper } from './hooks';
import { styles } from './styles';

export interface QuoteRequestFormWrapperProps {
  data: Product[];
  company: CompanyDetail | undefined;
  defaultUnit: UnitsType;
  defaultPriceUnits?: Currency;
  isRequestSamplePage?: boolean;
  isAddPage: boolean;
}

export const QuoteRequestFormWrapper: FC<QuoteRequestFormWrapperProps> = ({
  data,
  company,
  defaultUnit,
  defaultPriceUnits,
  isAddPage,
  isRequestSamplePage = false,
}): JSX.Element => {
  const {
    watch,
    setValue,
    control,
    isDirty,
    dirtyFields,
    choosedProducts,
    choosedProductsKeys,
    productId,
    handleSubmit,
    setChoosedProducts,
    removeChoosedProducts,
    allowToNavigate,
  } = useQuoteRequestFormWrapper({ data, defaultUnit, defaultPriceUnits, companyDisplayId: company?.displayId });

  const updateCurrency = (currency: Currency) => {
    setValue('priceUnits', currency);
  };
  const currency = watch('priceUnits');

  if (!isDirty && productId && !choosedProducts.length) {
    return (
      <Box sx={styles.loaderWrap} data-testid="loader">
        <PageLoader />
      </Box>
    );
  }

  return (
    <form>
      <Box sx={{ ...styles.headerWrap }}>
        <Typography sx={{ ...styles.title(isRequestSamplePage) }}>
          Request {isRequestSamplePage ? 'Sample' : 'Quote'}
        </Typography>
        <Box sx={{ ...styles.currencyContainer }}>
          <CurrencyIcon />
          <Typography sx={{ ...styles.currency }}>Currency:</Typography>
          <CurrencyBadge
            currency={currency}
            editable={!isAddPage}
            updateCurrency={updateCurrency}
            displayConfirmation={false}
          />
        </Box>
      </Box>

      {choosedProducts.length ? (
        <ChoosedProducts
          watch={watch}
          setValue={setValue}
          control={control}
          choosedProducts={choosedProducts}
          handleSubmit={handleSubmit}
          removeChoosedProducts={removeChoosedProducts}
          isFormDirty={isDirty || !!dirtyFields?.quotes}
          allowToNavigate={allowToNavigate}
          seller={company}
          isSampleRequest={isRequestSamplePage}
        />
      ) : (
        <EmptyQuotesState isRequestSample={isRequestSamplePage} />
      )}

      {data.length && (
        <Box data-testid="company-products">
          <CompanyProductsTable
            data={data || []}
            company={company}
            choosedProductsKeys={choosedProductsKeys}
            setChoosedProducts={setChoosedProducts}
            isSampleRequest={isRequestSamplePage}
          />
        </Box>
      )}
    </form>
  );
};
