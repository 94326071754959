import { Fragment, ReactElement } from 'react';

import groupCropsUrl from 'assets/images/groupCrops.png';
import { Box, Typography } from '@mui/material';
import { styles } from './styles';

const messages = {
  num: '1,000s',
  main: ' of products to choose from',
  subText: ['Quote', 'Sample', 'Purchase'],
};

export const ChoiceCard = (): ReactElement => {
  return (
    <Box sx={{ ...styles.container }}>
      <Box sx={{ ...styles.mainContainer }}>
        <Typography component="span" sx={{ ...styles.num }}>
          {messages.num}
        </Typography>
        <Typography component="span" sx={{ ...styles.main }}>
          {messages.main}
        </Typography>
      </Box>
      <Box sx={{ ...styles.subTextContainer }}>
        {messages.subText.map((text, ind) => (
          <Fragment key={text}>
            <Typography sx={{ ...styles.subText }}>{text}</Typography>
            {ind !== messages.subText.length - 1 && <Box sx={styles.subTextDivider} />}
          </Fragment>
        ))}
      </Box>
      <Box component="img" src={groupCropsUrl} alt="Group crops" sx={styles.img} />
    </Box>
  );
};
