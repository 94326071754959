import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  accordionWrap: {
    margin: '0 0 12px',
    boxShadow: 'none',
    border: `1px solid ${customTheme.custom.black.B10}`,
    borderRadius: '10px',
    cursor: 'default',

    '::before': {
      display: 'none',
    },
  },
  accordionSummary: {
    padding: '0 24px',
    borderRadius: '10px 10px 0 0',
    backgroundColor: customTheme.palette.primary.P5,
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,

    '&.Mui-expanded': {
      minHeight: 'unset',
      borderRadius: '10px 10px 0 0',
    },

    '&.MuiAccordionSummary-root.MuiButtonBase-root': {
      cursor: 'default',
    },
  },
  accordionDetails: {
    padding: '0',
  },
  iconWrap: {
    width: '24px',
    height: '24px',
    color: customTheme.custom.black.B70,
    cursor: 'pointer',
  },
  title: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    color: customTheme.custom.black.B100,
  },
};
