import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  loaderWrap: {
    minHeight: '50svh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrap: {
    display: 'flex',
    gap: '24px',
    marginBottom: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: 0,
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  description: {
    marginBottom: '24px',
    fontSize: '12px',
    lineHeight: '16px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 0,
      gridRow: 4,
    },
  },
  subTitle: {
    marginBottom: '12px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: customTheme.custom.black.B100,
  },
  listTitle: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '8px',
      fontSize: '14px',
      lineHeight: '17px',
      gridRow: 3,
    },
  },
  subTitleError: {
    margin: '-12px 0 12px',
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.error,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '4px 0 -18px',
    },
  },
  documentsSection: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gridRow: 3,
    },
  },
  pageTitle: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      marginBottom: '10px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      color: customTheme.custom.black.B100,
    },
  },
  actionsWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
    },
  },
  formErrorWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '-12px 0 24px',
    },
  },
};
