import { UnitsType, DashboardMainTabs, SampleRequestsTabs, QuoteProductStatus } from 'app/api/quotes/types';
import { SortTypes, SortValue, DashboardTab, TabLevel } from '../types';

export const dashboardMainTabs: DashboardTab[] = [
  {
    label: 'Quote Requests',
    value: 0,
    path: DashboardMainTabs.QUOTE_REQUESTS,
    counterKey: 'quotes',
    level: TabLevel.MAIN,
  },
  {
    label: 'Open Orders',
    value: 1,
    path: DashboardMainTabs.OPEN_ORDERS,
    counterKey: 'orders',
    level: TabLevel.MAIN,
  },
  {
    label: 'En Route',
    value: 2,
    path: DashboardMainTabs.EN_ROUTE,
    counterKey: 'enRoute',
    level: TabLevel.MAIN,
  },
  {
    label: 'Past Orders',
    value: 3,
    path: DashboardMainTabs.PAST_ORDERS,
    level: TabLevel.MAIN,
  },
  {
    label: 'Sample Requests',
    value: 4,
    path: DashboardMainTabs.SAMPLE_REQUESTS,
    counterKey: 'sampleRequestsQuotes',
    level: TabLevel.MAIN,
  },
];

export const sampleRequestsTabs: DashboardTab[] = [
  {
    label: 'Sample Requests',
    value: 5,
    path: DashboardMainTabs.SAMPLE_REQUESTS,
    level: TabLevel.INNER,
  },
  {
    label: 'Sample Send in Progress',
    value: 6,
    path: SampleRequestsTabs.SAMPLE_SEND_IN_PROGRESS,
    level: TabLevel.INNER,
  },
  {
    label: 'Sample En Route',
    value: 7,
    path: SampleRequestsTabs.SAMPLE_EN_ROUTE,
    level: TabLevel.INNER,
  },
  {
    label: 'Past',
    value: 8,
    path: SampleRequestsTabs.PAST,
    level: TabLevel.INNER,
  },
];

export const dasboardTabs = [...dashboardMainTabs, ...sampleRequestsTabs];

const deliveryDateOptions: SortValue[] = [
  {
    value: SortTypes.ESTIMATE_DELIVERY_DATE_NEWEST,
    label: 'Estimate Delivery Date (Newest First)',
  },
  {
    value: SortTypes.ESTIMATE_DELIVERY_DATE_OLDEST,
    label: 'Estimate Delivery Date (Oldest First)',
  },
];

const dateCreatedOptions: SortValue[] = [
  {
    value: SortTypes.DATE_CREATED_NEWEST,
    label: 'Date Created (Newest First)',
  },
  {
    value: SortTypes.DATE_CREATED_OLDEST,
    label: 'Date Created (Oldest First)',
  },
];

const totalWeightOptions: SortValue[] = [
  {
    value: SortTypes.TOTAL_PRICE_HIGHEST,
    label: 'Order Value (Highest To Lowest)',
  },
  {
    value: SortTypes.TOTAL_PRICE_LOWEST,
    label: 'Order Value (Lowest To Highest)',
  },
];

export const qouteRequestsSortValues: SortValue[] = [
  {
    value: SortTypes.DEFAULT,
    label: 'Recently Updated',
  },
  ...dateCreatedOptions,
];

export const openOrdersSortValues: SortValue[] = [...dateCreatedOptions, ...deliveryDateOptions, ...totalWeightOptions];

export const enRouteOrdersSortValues: SortValue[] = [
  {
    value: SortTypes.DATE_SENT_NEWEST,
    label: 'By Date Sent (The Most Recent First)',
  },
  ...dateCreatedOptions,
  ...deliveryDateOptions,
  ...totalWeightOptions,
];

export const pastOrdersSortValues: SortValue[] = [
  {
    value: SortTypes.DELIVERY_DATE_NEWEST,
    label: 'By Delivery Date (The Most Recent First)',
  },
  ...dateCreatedOptions,
  ...totalWeightOptions,
];

export const sortElementTransform = {
  [DashboardMainTabs.QUOTE_REQUESTS]: 110,
  [DashboardMainTabs.OPEN_ORDERS]: 110,
  [DashboardMainTabs.EN_ROUTE]: 50,
  [DashboardMainTabs.PAST_ORDERS]: 48,
};

export const PRODUCT_REMOVED = 'Product removed';
export const QUANTITY_NOT_AVAILABLE = 'Quantity not available';
export const PRODUCT_IS_NO_LONGER_AVAILABLE = 'Product is no longer available';

export const quotePoductStatusLabel: Record<QuoteProductStatus, string> = {
  [QuoteProductStatus.REMOVED]: PRODUCT_REMOVED,
  [QuoteProductStatus.NOT_AVAILABLE]: QUANTITY_NOT_AVAILABLE,
  [QuoteProductStatus.RESTRICTED]: PRODUCT_IS_NO_LONGER_AVAILABLE,
  [QuoteProductStatus.AVAILABLE]: '',
};

export const IS_UPDATE_WAIT_ANSWER_KEY = 'isUpdWaitAnswer';

export const OPEN_CHAT_SEARCH_PARAM_KEY = 'chat';
export const QUOTE_ID_SEARCH_PARAM_KEY = 'quoteId';

export const amountSubtitleUnits: Record<UnitsType, string> = {
  [UnitsType.KG]: 'kg',
  [UnitsType.LB]: 'lb',
  [UnitsType.MT]: 'mt',
  [UnitsType.THOUSAND_SEEDS]: 'thousands of seeds',
};
