import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    minHeight: '100%',
    maxWidth: '1280px',
    paddingBottom: '24px',
  },
  loaderWrap: {
    height: '80dvh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    width: 'fit-content',
    marginBottom: '25px',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,

    ':hover': {
      cursor: 'pointer',
      color: customTheme.custom.black.B70,
      textDecoration: 'underline',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '8px',
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
  mainInfoWrap: {
    display: 'flex',
    background: customTheme.custom.black.B10,
    borderRadius: '8px',
    overflow: 'hidden',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.12)',
    },
  },
  dateWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: '4px',
    marginBottom: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '20px',
    },
  },
  date: {
    fontSize: '10px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },
};
