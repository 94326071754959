import { forwardRef } from 'react';
import { Box, InputBase, InputBaseProps } from '@mui/material';

import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { styles } from './styles';

export type Ref = HTMLButtonElement;

export const CustomInput = forwardRef<Ref, InputBaseProps & { isError: boolean }>(
  ({ value, onClick, isError }, ref) => (
    <InputBase
      sx={{
        ...styles.inputWrap(!!isError),
        input: { ...styles.input },
      }}
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Ideal delivery dates"
      readOnly
      startAdornment={
        <Box sx={{ ...styles.calendarIcon }}>
          <CalendarIcon />
        </Box>
      }
      data-testid="custom-input"
    />
  ),
);
