import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  menuWrap: {
    '& .MuiPaper-root': {
      minWidth: '256px',
      padding: '10px 12px',
      borderRadius: '3px',
      boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    },
    '& .MuiMenu-list': {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
  },
  menuButton: {
    minWidth: '80px',
    fontWeight: '400',
    color: customTheme.custom.black.B70,
    backgroundColor: customTheme.custom.black.B5,
    whiteSpace: 'nowrap',

    '&.MuiButton-containedPrimary': {
      ':hover': {
        backgroundColor: customTheme.palette.primary.P10,
      },
    },
  },
  menuButtonOpened: {
    color: customTheme.palette.primary.P100,
    backgroundColor: customTheme.palette.primary.P10,
  },
  selected: {
    color: customTheme.custom.black.B100,
  },
  menuButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '3px',
    color: customTheme.custom.black.B100,
  },
  menuButtonIconopened: {
    color: customTheme.palette.primary.P100,
  },
  menuItemWrap: {
    margin: 0,
    padding: '6px 12px',

    '& .MuiFormControlLabel-label': {
      marginLeft: '8px',
      fontSize: '14px',
      color: customTheme.custom.black.B100,
    },
  },
  checkboxWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  checkboxLabel: {
    fontSize: '10px',
    lineHeight: '21px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
  },
};
