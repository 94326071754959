import { ChangeEvent, useCallback } from 'react';

import { UseMutateFunction } from 'react-query';
import { UseFormSetValue } from 'react-hook-form';

import { MuteStatus, NotificationsSettingsForm } from 'app/api/SBChat';
import { getMuteStatus } from '../utils';

interface UseMuteStatusHandlersProps {
  isAllMuted: boolean;
  isMessagesMuted: boolean;
  muteStatusMutation: UseMutateFunction<void, unknown, { muteStatus: MuteStatus }>;
  setValue: UseFormSetValue<NotificationsSettingsForm>;
}

export const useMuteStatusHandlers = ({
  isAllMuted,
  isMessagesMuted,
  muteStatusMutation,
  setValue,
}: UseMuteStatusHandlersProps) => {
  const onChange = useCallback(
    (name: keyof NotificationsSettingsForm) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setValue(name, checked);

      const isAll = name === 'all';
      const isMessages = name === 'messages';

      muteStatusMutation({
        muteStatus: getMuteStatus({ checked, isAll, isAllMuted, isMessages, isMessagesMuted, setValue }),
      });
    },
    [isAllMuted, isMessagesMuted, muteStatusMutation, setValue],
  );

  return { onChange };
};
