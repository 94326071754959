import { FC, ReactNode, useCallback } from 'react';

import { generatePath } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { Box, SxProps, Typography } from '@mui/material';

import { HomePageSection } from 'app/api/products/types';
import { ROUTES } from 'app/routes/constants';
import { capitalizeAll, capitalizeFirstLetters } from 'utils/formatText';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useDeviceType } from 'hooks';
import { ProductCard } from '../ProductCard';
import { LinkButton } from '../LinkButton';
import { CustomArrowButton } from './CustomArrowButton';
import { styles } from './styles';

interface ProductsSliderProps extends HomePageSection {
  size?: 'small' | 'large';
  isSmallTitle?: boolean;
  subText?: string;
  isLink?: boolean;
  slide?: ReactNode;
  slidePosition?: number;
  sx?: SxProps;
}

const settings: Settings = {
  infinite: false,
  slidesToScroll: 4,
  slidesToShow: 6,
  nextArrow: <CustomArrowButton direction="next" />,
  prevArrow: <CustomArrowButton direction="prev" />,
  variableWidth: true,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1690,
      settings: { slidesToShow: 5, slidesToScroll: 4 },
    },
    {
      breakpoint: 1426,
      settings: { slidesToShow: 4, slidesToScroll: 4 },
    },
    {
      breakpoint: 1150,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 768,
      settings: { slidesToShow: 4, slidesToScroll: 4 },
    },
    {
      breakpoint: 680,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 512,
      settings: { slidesToShow: 2, slidesToScroll: 2 },
    },
  ],
};

const settingsLarge: Settings = {
  slidesToScroll: 3,
  slidesToShow: 4,
  responsive: [
    {
      breakpoint: 1600,
      settings: { slidesToShow: 4, slidesToScroll: 4 },
    },
    {
      breakpoint: 1310,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 997,
      settings: { slidesToShow: 2, slidesToScroll: 2 },
    },
    {
      breakpoint: 768,
      settings: { slidesToShow: 4, slidesToScroll: 4 },
    },
    {
      breakpoint: 768,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 680,
      settings: { slidesToShow: 2, slidesToScroll: 2 },
    },
  ],
};

const getCardWidth = (isMobileSize: boolean, size: 'small' | 'large') => {
  if (isMobileSize) return 160;
  if (size === 'large') return 279;
  return 244;
};

export const ProductsSlider: FC<ProductsSliderProps> = ({
  name,
  subText,
  products,
  isLink = true,
  isSmallTitle = false,
  size = 'small',
  slide,
  slidePosition,
  sx = {},
}) => {
  const { isMobileSize } = useDeviceType();
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const path = generatePath(ROUTES.buyer.products, {
    type: capitalizeFirstLetters(name),
  });

  const slideIndex = slide ? slidePosition || products.length : products.length;
  const cardWidth = getCardWidth(isMobileSize, size);

  const handleTitleClick = useCallback(() => {
    handleTrackLinkClickEvent({ path, text: capitalizeAll(name), type: AnalyticsLinkType.BUTTON });
  }, [handleTrackLinkClickEvent, name, path]);
  const sliderSettings = { ...settings, ...(size === 'large' && !isMobileSize ? settingsLarge : {}) };
  return (
    <Box
      id="product-slider"
      component="section"
      sx={{ ...(styles.wrap(!!subText, isSmallTitle) as SxPropsTypes), ...sx }}
    >
      <Box>
        {isLink ? (
          <LinkButton to={path} onClick={handleTitleClick}>
            <Typography component="h3" sx={{ ...styles.title(!!subText) }}>
              {capitalizeAll(name)}
            </Typography>
          </LinkButton>
        ) : (
          <Box onClick={handleTitleClick}>
            <Typography component="h3" sx={{ ...styles.title(!!subText) }}>
              {capitalizeAll(name)}
            </Typography>
          </Box>
        )}
      </Box>
      <Box>{subText && <Typography sx={styles.subText}>{subText}</Typography>}</Box>
      <Slider {...sliderSettings}>
        {products.slice(0, slideIndex).map((product) => (
          <Box key={product.id} style={{ width: cardWidth }} sx={{ ...styles.slideWrap, minWidth: cardWidth }}>
            <ProductCard {...product} isSliderCard size={size} />
          </Box>
        ))}
        {slide && Number.isInteger(slideIndex) && (
          <Box key="custom" style={{ width: cardWidth }} sx={{ ...styles.slideWrap, minWidth: cardWidth }}>
            {slide}
          </Box>
        )}
        {slide &&
          Number.isInteger(slideIndex) &&
          products.slice(slideIndex).map((product) => (
            <Box key={product.id} style={{ width: cardWidth }} sx={{ ...styles.slideWrap, minWidth: cardWidth }}>
              <ProductCard {...product} isSliderCard size={size} />
            </Box>
          ))}
      </Slider>
    </Box>
  );
};
