import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Box, Menu } from '@mui/material';
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrowDown.svg';
import { CommonButton } from '../../../../components/CommonButton';
import { RadioGroupController } from '../../../../components/RadioGroupController';
import { RadioGroupItem } from '../../../../components/RadioGroupController/types';
import { useGeneticTypeFilter } from './hooks/useGeneticTypeFilter';

import { styles } from './styles';

interface GeneticTypeFilterProps {
  data: RadioGroupItem[];
  control: Control;
  controlName: string;
}

export const GeneticTypeFilter: FC<GeneticTypeFilterProps> = ({ data, control, controlName }) => {
  const { anchorEl, open, filterTitle, isSelected, handleClick, handleClose } = useGeneticTypeFilter(
    control,
    controlName,
  );

  return (
    <>
      <CommonButton
        sx={{
          ...styles.menuButton,
          ...(isSelected && styles.selected),
          ...(open && styles.menuButtonOpened),
        }}
        onClick={handleClick}
        disableRipple
      >
        {filterTitle}
        <Box
          sx={{
            ...styles.menuButtonIcon,
            ...(open && styles.menuButtonIconopened),
          }}
        >
          <ArrowDownIcon />
        </Box>
      </CommonButton>
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RadioGroupController controlName={controlName} control={control} data={data} handleClose={handleClose} />
      </Menu>
    </>
  );
};
