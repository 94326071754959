import { FC, useCallback } from 'react';

import { CommonButton } from 'components';
import { useModal } from 'hooks';
import { useDeleteDocumentMutation } from '../../hooks';
import { DeleteDocumentPopup } from '../DeleteDocumentPopup';
import { styles } from './styles';

interface ViewFileSellerActionsProps {
  fileId: number;
  folderId: number;
  setDocumentAsUpdated: () => void;
}

export const ViewFileSellerActions: FC<ViewFileSellerActionsProps> = ({ fileId, folderId, setDocumentAsUpdated }) => {
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();

  const { mutate: handleDeleteDocument, isLoading: isDeleteDocumentLoading } = useDeleteDocumentMutation({
    id: fileId,
    folderId,
    setDocumentAsUpdated,
    onSuccessAction: handleCloseModal,
  });

  const handleRemove = useCallback(() => {
    handleDeleteDocument();
  }, [handleDeleteDocument]);

  return (
    <>
      <CommonButton
        disableRipple
        variant="text"
        color="inherit"
        sx={{ ...styles.deletButton }}
        onClick={handleOpenModal}
        data-testid="seller-actions"
      >
        Delete document
      </CommonButton>

      {isModalOpen && (
        <DeleteDocumentPopup
          handleClose={handleCloseModal}
          isOpen={isModalOpen}
          handleRemove={handleRemove}
          isLoading={isDeleteDocumentLoading}
        />
      )}
    </>
  );
};
