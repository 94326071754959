import { FC } from 'react';

import { truncate } from 'lodash';
import { Box, Collapse, Divider, Typography } from '@mui/material';

import { Product } from 'app/api/products/types';
import { geneticTypeLabel } from 'components/ProductCard/constants';
import { capitalizeFirstLetter } from 'utils/formatText';
import { getVarietyValue } from 'utils/getVarietyValue';
import { useProductRow } from '../../hooks';
import { ProductDetails } from '../ProductDetails';
import { AddProductButton } from '../AddProductButton';
import { styles } from './styles';

export interface CompanyProductCardProps {
  data: Product;
  choosedProductsKeys: Set<number>;
  isLast: boolean;
  isSampleRequest: boolean;
  setChoosedProducts: (value: Product) => void;
}

export const CompanyProductCard: FC<CompanyProductCardProps> = ({
  data,
  choosedProductsKeys,
  isLast,
  isSampleRequest,
  setChoosedProducts,
}) => {
  const { detailsData, isChoosed, isLoading, open, handleAddProduct, handleViewDetails } = useProductRow({
    data,
    choosedProductsKeys,
    isSampleRequest,
    setChoosedProducts,
  });

  const varietyValue = getVarietyValue({
    crop: data.crop,
    variety: data.variety,
    name: data.name,
  });

  return (
    <Box sx={styles.mainWrap}>
      <Box data-testid="company-product-card" sx={styles.wrap(data.inStock)} onClick={handleViewDetails}>
        {data.inStock && <AddProductButton isChoosed={isChoosed} handleAddProduct={handleAddProduct} />}
        <Box sx={styles.contentWrap}>
          <Typography sx={styles.crop}>
            {capitalizeFirstLetter(data.crop)}{' '}
            {!data.inStock && (
              <Typography component="span" sx={styles.outOfStock}>
                {' - Out of stock'}
              </Typography>
            )}
          </Typography>
          <Typography sx={styles.nameWrap}>
            <Typography component="span" sx={styles.name}>
              {varietyValue}
            </Typography>
            {data.variety && '\u00A0'}
            {data.name}
          </Typography>
          {!!data.description && (
            <Typography sx={styles.description}>
              {truncate(data.description, { length: 120, omission: '...' })}
            </Typography>
          )}
          <Typography sx={styles.type}>{geneticTypeLabel[data.geneticType]}</Typography>
        </Box>
      </Box>
      <Collapse data-testid="product-details" in={open} timeout="auto" unmountOnExit>
        <ProductDetails data={detailsData} isLoading={isLoading || !detailsData} />
      </Collapse>
      {!isLast && <Divider data-testid="divider" sx={styles.divider(open)} />}
    </Box>
  );
};
