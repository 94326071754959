import { sidebarBackdropLeftPosition } from 'layout/constants/styleConstants';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (top: string, isPermanent: boolean, isCategoryOpen: boolean, permanentTop: number) => ({
    backgroundColor: customTheme.custom.white,
    ...(!isPermanent && {
      position: 'fixed',
      top,
      bottom: 0,
      left: 0,
      zIndex: 5,
    }),
    ...(isPermanent &&
      isCategoryOpen && {
        height: 'calc(100% + 60px)',
        zIndex: '1',
        position: 'fixed',
        top: permanentTop,
      }),
  }),
  categoriesWrap: (isPermanent: boolean) => ({
    width: '210px',
    height: '100%',
    padding: '12px 0',
    ...(isPermanent && {
      padding: '12px 0 12px',
    }),
  }),
  backDrop: (top: string, isPermanent: boolean) => ({
    top,
    left: '209px',
    background: 'rgba(25, 28, 30, 0.6)',
    ...(isPermanent && {
      '@media (min-width: 1921px)': {
        left: sidebarBackdropLeftPosition,
      },
    }),
  }),
  dialog: (top: string, isPermanent: boolean) => ({
    top,
    left: '209px',
    '.MuiDialog-container': {
      justifyContent: 'left',
      flexDirection: 'row',
    },
    zIndex: isPermanent ? 5 : 20,
    ...(isPermanent && {
      zIndex: 11,

      '@media (min-width: 1921px)': {
        left: sidebarBackdropLeftPosition,
      },
    }),
  }),
  paper: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    maxHeight: '100%',
    margin: 0,
    boxShadow: 'none',
    borderRadius: 0,
    overflowY: 'unset',
    backgroundColor: 'transparent',
  },
  navItem: {
    padding: '12px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    a: {
      height: 'fit-content',
      gap: '4px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: customTheme.custom.black.B70,
      textDecoration: 'none',
    },
    path: {
      fill: customTheme.custom.black.B70,
    },
    ':hover': {
      a: {
        color: customTheme.custom.black.B100,
      },
      path: {
        fill: customTheme.custom.black.B100,
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '16px 42px 16px 24px',
      a: {
        display: 'block',
        fontSize: '16px',
      },
    },
  },
  activeNavItem: {
    a: {
      height: 'fit-content',
      gap: '4px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: customTheme.custom.black.B100,
      textDecoration: 'none',
    },
    path: {
      fill: customTheme.custom.black.B100,
    },
  },
  currentNavItem: {
    cursor: 'default',
    a: {
      height: 'fit-content',
      gap: '4px',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      color: customTheme.custom.black.B100,
      textDecoration: 'none',
      cursor: 'default',
    },
  },
  isOpenedNavItem: {
    backgroundColor: customTheme.custom.black.B10,
  },
  subCategoriesWrap: {
    display: 'flex',
    height: '100%',
    maxWidth: 'calc(0.7 * (100% + 210px))',
  },
  closeButton: {
    position: 'absolute',
    top: '12px',
    right: '-44px',
    background: 'rgba(255, 255, 255, 0.2)',
    path: { fill: customTheme.custom.white },
    ':hover': {
      background: 'rgba(255, 255, 255, 0.35)',
    },
  },
  divider: (isPermanent: boolean) => ({
    borderWidth: isPermanent ? '1px' : '0.5px',
    borderColor: customTheme.custom.black.B10,
  }),
  emptyBlock: {
    backgroundColor: 'transparent',
    flex: 1,
  },
};
