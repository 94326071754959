import { useEffect, useState } from 'react';

import { Control, useForm } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import posthog from 'posthog-js';

import * as tracker from 'analytics/tracker';

import logo from 'assets/images/headerLogo.svg';

import { CommonModal } from 'components/CommonPopup';
import { useAuth } from 'hooks';
import { DEACTIVATED_USER, LogInError, LoginData } from 'app/api/auth';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/routes/constants';
import { AxiosError } from 'axios';
import { TextFormField } from 'components/TextFormField';
import { MaskedTextFormField } from 'components/MaskedTextFormField';
import { FormError } from 'components/FormError';
import { LoadingButton } from 'components/LoaderButton';
import { CommonButton } from 'components/CommonButton';
import { requiredFieldValidation } from 'app/validation/validation';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorSmall.svg';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectSignupModal, setSignupModal } from 'state/slices/account';
import { SelectInput } from 'components/SelectInput';
import { patchPublicForm, postPublicForm } from 'app/api/publicForm';
import { useMutation } from 'react-query';
import { notificationObserver } from 'utils/observer';
import { AnalyticsName, rudderInitialize } from 'analytics';
import { getRequestAccessData, getUserBusinessData, setRequestAccessData, setUserBusinessData } from 'utils/storage';

import { UnitsType } from 'app/api/quotes';
import { NumberFormField } from 'components/NumberFormField';
import { UnitsMenu } from 'components/UnitsMenu';
import { Product } from 'app/api/products';
import { getIsTrackingAccepted } from 'components/CookieBanner';
import { globalMessages } from 'utils/messages';
import { SecondPage } from 'components/RequestProductModal/components/secondPage';
import { ExtendedRequestFields } from 'components/RequestProductModal/components/form';
import { PhoneFormField } from 'components/PhoneFormField';

import { styles } from './styles';

const messages = {
  welcome: 'Welcome to',
  description: 'Please login or request free access to view suppliers or request products.',
  requestAccess: 'Request Free Access',
  login: 'Login',
  note:
    'Please note – Sproutzo is for the bulk commercial agriculture industry. ' +
    'We can’t supply gardeners or hobbyists.',
  terms: 'By requesting access you agree to our privacy policy.',
};

type RequestAccessForm = {
  email: string;
  companyName: string;
  name: string;
  country: string;
  hear: string;
  phone: string;
  businessType: Set<string>;
  posthogUser: string;
  route: string;
  date: string;
  productId?: number;
  quantity?: number;
  units?: UnitsType;
} & ExtendedRequestFields;

const RequestAccessValidationRules = {
  email: { required: requiredFieldValidation },
  companyName: { required: requiredFieldValidation },
  name: { required: requiredFieldValidation },
  country: { required: requiredFieldValidation },
  productQuantity: {
    required: requiredFieldValidation,
    min: { value: 1, message: 'Quantity must be greater than 0' },
  },
};

const hearOptions = [
  'Search Engine',
  'Industry Trade Show / Conference',
  'Social Media',
  'Referral from Another Business',
  'Advertisement',
  'Other',
].map((v) => ({ label: v, value: v }));

const commercialTypes = ['Importer', 'Seed_Company', 'Farm_Supply', 'Distribution', 'Commercial_Breeder', 'Other'];
export const isCommercial = (businessType: Set<string>) => {
  if (businessType.has('Gardener')) return false;
  if (commercialTypes.some((t) => businessType.has(t))) {
    return true;
  }
  return false;
};

export const isFarmer = (businessType: Set<string>) => {
  if (
    (businessType.has('Farmer') || businessType.has('Gardener')) &&
    !commercialTypes.some((t) => businessType.has(t))
  ) {
    return true;
  }
  return false;
};

const RequestAccess = ({ metadata }: { metadata?: { quantity?: number; units?: UnitsType; product?: Product } }) => {
  const [formError, setFormError] = useState('');
  const dispatch = useAppDispatch();
  const closeModal = () => dispatch(setSignupModal({ isOpen: false, state: undefined }));
  const userBusinessData = getUserBusinessData();
  const { mutate: handlePostForm } = useMutation(async (data: RequestAccessForm) => {
    try {
      const { quantity, units, ...restData } = data;
      const parsedData = {
        ...(quantity && units && { quantity, units }),
        ...restData,
        businessType: Array.from(data.businessType).join(', '),
      };
      const res = await postPublicForm({
        title: 'Request Access Form',
        ...parsedData,
        ...(metadata?.product && {
          crop: metadata.product.crop,
          variety: metadata.product.variety,
          productName: metadata.product.name,
          geneticType: metadata.product.geneticType,
        }),
      });
      setFormId(res.id);
      tracker.track(AnalyticsName.REQUEST_ACCESS);
      if (isCommercial(data.businessType)) {
        tracker.track(`${AnalyticsName.REQUEST_ACCESS} Commercial`);
      }
      if (isFarmer(data.businessType)) {
        tracker.track(`${AnalyticsName.REQUEST_ACCESS} Farmer`);
      }

      notificationObserver.publish({
        type: 'success',
        title: globalMessages.thankYouForm,
      });
    } catch (err) {
      notificationObserver.publish({
        type: 'warning',
        title: 'Unable to submit form',
      });
    }
  }, {});

  const { mutate: handleUpdateForm } = useMutation(async (data: RequestAccessForm) => {
    try {
      const { quantity, units, ...restData } = data;
      const parsedData = {
        ...(quantity && units && { quantity, units }),
        ...restData,
        businessType: Array.from(data.businessType).join(', '),
      };
      if (!formId) return;
      await patchPublicForm(formId, {
        title: 'Request Access Form',
        ...parsedData,
        ...(metadata?.product && {
          crop: metadata.product.crop,
          variety: metadata.product.variety,
          productName: metadata.product.name,
          geneticType: metadata.product.geneticType,
        }),
      });
      setUserBusinessData(parsedData);
      notificationObserver.publish({
        type: 'success',
        title: globalMessages.thankYouForm,
      });
    } catch (err) {
      notificationObserver.publish({
        type: 'warning',
        title: 'Unable to submit form',
      });
    }
  }, {});

  const [page, setPage] = useState<number>(1);
  const [formId, setFormId] = useState<number | null>(null);
  const isTrackingAccepted = getIsTrackingAccepted();
  const onSubmit = (data: RequestAccessForm) => {
    if (!isTrackingAccepted) {
      rudderInitialize();
      localStorage.setItem('cookiePermissions', 'accepted');
    }
    handlePostForm(data);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { businessType, productId, units, quantity, ...rest } = data;
    setRequestAccessData({ ...rest, businessType: Array.from(businessType) });
    if (data.productId) {
      setPage(2);
    } else {
      closeModal();
    }
  };

  const onSubmitSecondPage = (data: RequestAccessForm) => {
    handleUpdateForm(data);
    closeModal();
  };
  const onChange = () => {
    if (formError) {
      setFormError('');
    }
  };

  const businesses = [
    { id: 'Importer', label: 'Importer' },
    { id: 'Seed_Company', label: 'Seed Company' },
    { id: 'Farm_Supply', label: 'Farm Supply' },
    { id: 'Distribution', label: 'Distribution' },
    { id: 'Farmer', label: 'Farmer' },
    { id: 'Gardener', label: 'Gardener/Hobbyist' },
    { id: 'Commercial_Breeder', label: 'Commercial Breeder' },
    { id: 'Other', label: 'Other' },
  ];
  const posthogDistinctId = posthog.__loaded ? posthog.get_distinct_id() : 'unknown';
  const storedValues = getRequestAccessData();

  const form = useForm<RequestAccessForm>({
    defaultValues: {
      email: '',
      companyName: '',
      name: '',
      phone: '',
      hear: '',
      country: '',
      productId: metadata?.product?.id,
      quantity: metadata?.quantity,
      units: metadata?.units || UnitsType.KG,
      ...storedValues,
      ...userBusinessData,
      businessType: new Set((storedValues as { businessType: string[] }).businessType ?? []),
      route: window.location.pathname,
      date: new Date().toDateString(),
      posthogUser: posthogDistinctId,
    },
  });
  const { control, watch, handleSubmit, setValue, formState } = form;
  form.register('businessType', {
    validate: (value) => !!value.size || 'This is required.',
  });

  const businessType = watch('businessType');
  const units = watch('units');
  const toggleClickBusiness = (id: string) => {
    if (businessType.has(id)) {
      businessType.delete(id);
    } else {
      businessType.add(id);
    }
    setValue('businessType', new Set(businessType));
  };
  const onChangePhoneNumber = (value: string) => {
    setValue('phone', value);
  };
  const initialPhone = form.getValues('phone');

  if (page === 2) {
    return <SecondPage handleClose={closeModal} form={form} onSubmit={onSubmitSecondPage} />;
  }
  return (
    <Box sx={styles.requestContainer}>
      <Typography sx={styles.note}>{messages.note}</Typography>

      <Box component="form" onSubmit={handleSubmit(onSubmit)} onChange={onChange} sx={styles.form}>
        {Boolean(metadata?.product) && (
          <Box sx={styles.productContainer}>
            <Box sx={styles.productName}>{`${metadata?.product?.crop ?? ''}${
              metadata?.product?.variety ? ` - ${metadata?.product?.variety}` : ''
            }`}</Box>
            <Box sx={styles.productInputContainer}>
              <NumberFormField
                wrapStyles={styles.numberWraper as SxPropsTypes}
                inputWrapStyles={styles.quantityAvailableInputWrap as SxPropsTypes}
                inputStyles={styles.quantityAvailableInput as SxPropsTypes}
                rules={RequestAccessValidationRules.productQuantity}
                placeholder="Order Quantity"
                decimalScale={2}
                name="quantity"
                control={control}
              />
              <UnitsMenu sxContainer={styles.unitsMenu} units={units} onChange={(value) => setValue('units', value)} />
            </Box>
          </Box>
        )}
        <Box sx={styles.rowContainer}>
          <TextFormField
            control={control}
            wrapStyles={styles.rowItem as SxPropsTypes}
            name="name"
            rules={RequestAccessValidationRules.name}
            placeholder="Name"
            autoComplete="on"
          />
          <TextFormField
            control={control}
            name="email"
            type="email"
            rules={RequestAccessValidationRules.email}
            wrapStyles={styles.rowItem as SxPropsTypes}
            placeholder="Email"
            autoComplete="on"
          />
        </Box>
        <Box sx={styles.rowContainer}>
          <TextFormField
            control={control}
            name="companyName"
            rules={RequestAccessValidationRules.companyName}
            wrapStyles={styles.rowItem as SxPropsTypes}
            placeholder="Company Name"
            autoComplete="on"
          />
          <PhoneFormField
            control={control}
            setValue={onChangePhoneNumber}
            initialValue={initialPhone}
            placeholder="Phone Number"
            wrapStyles={styles.rowItem as SxPropsTypes}
            autoComplete="on"
          />
        </Box>
        <Box sx={styles.rowContainer}>
          <TextFormField
            control={control}
            name="country"
            placeholder="Country"
            wrapStyles={styles.rowItem as SxPropsTypes}
            rules={RequestAccessValidationRules.country}
            autoComplete="on"
          />
          <SelectInput
            data={hearOptions}
            control={control as unknown as Control}
            controlName="hear"
            sx={{ ...styles.rowItem, ...styles.selectInput } as SxPropsTypes}
            placeHolder="How did you hear about us?"
          />
        </Box>

        <Box sx={styles.businessContainer}>
          <Typography>Business Type – select all that apply (Optional)</Typography>
          {formState.errors.businessType && (
            <Box sx={styles.errorBox}>
              <Box sx={styles.errorIcon}>
                <ErrorIcon />
              </Box>
              <Typography sx={styles.errorText}>{formState.errors.businessType?.message}</Typography>
            </Box>
          )}
          <Box sx={styles.businesses}>
            {businesses.map((b) => (
              <Box
                component="div"
                key={b.id}
                sx={styles.business(businessType.has(b.id))}
                onClick={() => toggleClickBusiness(b.id)}
              >
                {b.label}
              </Box>
            ))}
          </Box>
        </Box>
        <CommonButton variant="contained" color="primary" type="submit" fullWidth sx={styles.requestAccessConfirmBtn}>
          {messages.requestAccess}
        </CommonButton>
        {!isTrackingAccepted && <Box sx={styles.terms}>{messages.terms}</Box>}
      </Box>
    </Box>
  );
};

const validationRules = {
  username: {
    required: requiredFieldValidation,
  },
  password: {
    required: requiredFieldValidation,
  },
};

const Login = () => {
  const navigate = useNavigate();
  const [formError, setFormError] = useState('');
  const [isDeactivatedError, setIsDeactivatedError] = useState(false);

  const { login, isLoginLoading: isLoading } = useAuth();

  const { mutate: loginHandler, error, reset, isSuccess: loginSuccess } = login;

  const { control, handleSubmit } = useForm<LoginData>({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = ({ username, ...values }: LoginData) => {
    loginHandler({ username: username.toLowerCase(), ...values });
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (loginSuccess) {
      dispatch(setSignupModal({ isOpen: false, state: undefined }));
    }
  }, [loginSuccess, dispatch]);

  const onChange = () => {
    if (formError) {
      setFormError('');
      setIsDeactivatedError(false);
    }
  };

  const onForgotPassword = () => {
    navigate(ROUTES.auth.resetPassword);
    dispatch(setSignupModal({ isOpen: false, state: undefined }));
    reset();
  };

  useEffect(() => {
    if (error) {
      const errData = (error as AxiosError)?.response?.data as LogInError;

      if (
        errData?.user?.code === DEACTIVATED_USER &&
        errData?.user?.message.startsWith('Seller accounts are currently down for renovation.')
      ) {
        setFormError(errData?.user?.message);
        setIsDeactivatedError(true);
      } else if (errData?.user?.code === DEACTIVATED_USER) {
        setFormError(`This account has been deactivated. 
          For any questions please reach out 
          to contact@sproutzo.com`);
      } else {
        setFormError('Email or password is incorrect. Please try again.');
      }
    }
  }, [error]);

  const isFormError = !!formError;
  const { token, isBuyer } = useAuth();

  useEffect(() => {
    // NOTE: This should only run once on component load
    if (token) {
      const page = isBuyer ? ROUTES.buyer._ : ROUTES.seller.dashboard._;
      navigate(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.loginContainer}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
        <Typography sx={styles.loginText}>Log In</Typography>

        <TextFormField
          isFormError={isFormError}
          control={control}
          name="username"
          rules={validationRules.username}
          placeholder="Email"
          autoComplete="on"
        />

        <MaskedTextFormField
          isFormError={isFormError}
          control={control}
          name="password"
          rules={validationRules.password}
          placeholder="Password"
        />

        {isFormError && (
          <FormError
            text={formError}
            sx={styles.loginError(isDeactivatedError)}
            textSx={styles.loginErrorText(isDeactivatedError)}
          />
        )}

        <Box sx={styles.loginBtns}>
          <LoadingButton type="submit" loading={isLoading} sx={styles.loginButton} disabled={isFormError}>
            Log In
          </LoadingButton>

          <CommonButton variant="text" color="inherit" sx={styles.forgotPasswordButton} onClick={onForgotPassword}>
            Forgot Password?
          </CommonButton>
        </Box>
      </Box>
    </Box>
  );
};

const Welcome = ({ setStage }: { setStage: (stage: 'welcome' | 'login' | 'requestAccess') => void }) => {
  return (
    <Box sx={styles.welcomeContainer}>
      <Typography sx={styles.welcomeNote}>{messages.description}</Typography>
      <CommonButton
        variant="contained"
        color="primary"
        sx={{ ...styles.requestAccessBtn, ...styles.welcomeBtn }}
        onClick={() => setStage('requestAccess')}
      >
        {messages.requestAccess}
      </CommonButton>
      <CommonButton variant="contained" color="inherit" sx={{ ...styles.welcomeBtn }} onClick={() => setStage('login')}>
        {messages.login}
      </CommonButton>
    </Box>
  );
};

export const PublicSiteModal = () => {
  const dispatch = useAppDispatch();
  const handleClose = () => dispatch(setSignupModal({ isOpen: false }));
  const setStage = (stage: 'welcome' | 'login' | 'requestAccess') => dispatch(setSignupModal({ state: stage }));
  const { isOpen, state, metadata } = useAppSelector(selectSignupModal);
  if (!isOpen) return null;
  return (
    <CommonModal
      paperStyles={styles.modalPaper as SxPropsTypes}
      titleContainerStyles={styles.closeBtn as SxPropsTypes}
      isOpen={isOpen}
      handleClose={handleClose}
      withCloseButton
    >
      <Box sx={styles.topWelcome}>
        <Typography sx={styles.welcome}>{messages.welcome}</Typography>
        <Box component="img" src={logo} sx={styles.welcomeLogo} />
      </Box>

      {state === 'welcome' && <Welcome setStage={setStage} />}
      {state === 'login' && <Login />}
      {state === 'requestAccess' && <RequestAccess metadata={metadata} />}
    </CommonModal>
  );
};
