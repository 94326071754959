import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import theme, { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  wrap: {
    position: 'relative',
    padding: '0 0 16px 88px',
    margin: '0 -88px',
    marginBottom: '16px',
    overflow: 'hidden',
    [`@media (min-width: 1900px)`]: {
      ':before': {
        position: 'absolute',
        zIndex: 1,
        content: '""',
        height: '100%',
        width: '32px',
        left: 0,
        background: 'linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
      },
      ':after': {
        position: 'absolute',
        zIndex: 2,
        content: '""',
        height: '100%',
        width: '32px',
        right: 0,
        top: 0,
        background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))',
      },
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: 2,
    },
    '& .slick-slider:not([dir])': {
      marginLeft: '-12px',
    },
    '& .slick-track': {
      display: 'flex',
      flexDirection: 'row',
    },
    '& .slick-arrow': {
      position: 'absolute',
      top: 0,
      '&.slick-next': {
        right: '88px',
      },
      '&.slick-prev': {
        right: '128px',
      },
    },
    '& .slick-list': {
      margin: '0 -12px',
      paddingLeft: '12px',
    },
    '& .slick-slide': {
      margin: '0 12px',
      height: 'inherit',
      '& > div': {
        height: '100%',
        width: '100%',
      },
    },
    [theme.breakpoints.down('tablet')]: {
      '& .slick-slider:not([dir])': {
        marginLeft: '-6px',
      },

      '& .slick-list': {
        margin: '0 -6px',
        paddingLeft: '6px',
      },
      '& .slick-slide': {
        margin: '0 6px',
      },
      '& .slick-arrow': {
        top: '0px',
      },

      '& .slick-arrow.slick-prev ': {
        right: '128px',
      },
    },
  },
  slideWrap: {
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    backgroundColor: customTheme.custom.white,
    boxSizing: 'border-box',
    ':hover': {
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
      transition: 'all 0.3s ease-out',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 3,
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 700,
    fontFamily: 'Poppins',
    lineHeight: 1.2,

    marginBottom: '16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '24px',
      marginBottom: '16px',
    },
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
};
