import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { ROUTES } from 'app/routes/constants';
import { Link } from 'components/Link';
import { styles } from './styles';

interface PageHeaderProps {
  isSampleRequest: boolean;
  isWaiting: boolean;
  isBuyer: boolean;
  subtitle: string;
  title: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ isBuyer, isSampleRequest, isWaiting, subtitle, title }) => {
  const dashboardPath = isBuyer ? ROUTES.buyer.dashboard._ : ROUTES.seller.dashboard._;

  return (
    <Box sx={styles.container}>
      <Link path={dashboardPath} sx={styles.link}>
        {'<  All Quotes'}
      </Link>
      <Box sx={styles.wrap}>
        <Box>
          {isSampleRequest && (
            <Typography component="span" sx={{ ...styles.sampleLabel }}>
              Sample Request
            </Typography>
          )}
          <Box sx={styles.title}>
            <Typography sx={styles.title}>{title}</Typography>
          </Box>
          <Typography sx={styles.subTitle}>{subtitle}</Typography>
        </Box>

        {isWaiting && <Typography sx={styles.tag}>Waiting for an answer</Typography>}
      </Box>
    </Box>
  );
};
