import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';

import { LegendSection } from '../LegendSection';
import { styles } from './styles';

interface DescriptionProps {
  content?: string | null;
  legendImage?: string | null;
}

export const Description: FC<DescriptionProps> = ({ content, legendImage }) => {
  const isLegend = !!legendImage;

  return (
    <Box sx={styles.wrap}>
      {!!content && (
        <Box sx={styles.notes}>
          <Typography component="h3" sx={{ ...styles.title }}>
            Notes
          </Typography>
          <Box sx={styles.content}>
            <Markdown>{content}</Markdown>
          </Box>
        </Box>
      )}

      {isLegend && (
        <Box sx={styles.legendWrap}>
          <LegendSection src={legendImage} />
        </Box>
      )}
    </Box>
  );
};
