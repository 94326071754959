import { useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { GroupChannelListQueryParams, GroupChannel, GroupChannelSearchField } from '@sendbird/chat/lib/__definition';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';

import { ConversationSearchForm, ConversationSearchType, SBChannelMetaData } from 'app/api/SBChat';
import { useAuth, useChatsListQuery } from 'hooks';

const CHAT_LIST_REFETCH_INTERVAL = 10 * 1000;

export const useSBChannellList = () => {
  const [debouncedQuery, setDebouncedQuery] = useState<string>('');
  const { isBuyer } = useAuth();
  const { control, setValue, watch } = useForm<ConversationSearchForm>({
    defaultValues: { query: '', searchType: ConversationSearchType.CHANNEL_NAME },
  });

  const queryString = watch('query');
  const searchType = watch('searchType');
  const isSearchByChannelName = searchType === ConversationSearchType.CHANNEL_NAME;

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedQuery(queryString);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [queryString]);

  const { chatsList, isChatsListLoading } = useChatsListQuery({
    enabled: true,
    refetchInterval: CHAT_LIST_REFETCH_INTERVAL,
    userMessages: true,
    isSelectEnabled: true,
  });

  const channelUrls = useMemo(() => chatsList?.map(({ chatId }) => chatId) ?? [], [chatsList]);
  const isEmptyList = channelUrls.length === 1 && channelUrls[0] === 'NaN';

  const clearSearchField = useCallback(() => {
    setValue('query', '');
  }, [setValue]);

  const handleSortChannels = useCallback((channels: GroupChannel[]) => {
    return channels.sort((a, b) => {
      const dateA = a?.lastMessage?.createdAt || a?.createdAt;
      const dateB = b?.lastMessage?.createdAt || b?.createdAt;

      return dateB - dateA;
    });
  }, []);

  const context = useChannelListContext();

  const allChannels = useMemo(
    () => context?.allChannels.filter((channel) => channelUrls.includes(channel.url)) ?? [],
    [channelUrls, context?.allChannels],
  );

  const allChannelsIds = useMemo(() => allChannels.map((channel) => channel.url) ?? [], [allChannels]);

  const chatNames = useMemo(
    () =>
      allChannels
        .map((channel) => {
          const metaData = channel.cachedMetaData as SBChannelMetaData;
          const name = isBuyer ? metaData?.seller_company_name : metaData?.buyer_company_name;

          return {
            chatId: channel?.url ?? '',
            name: name?.toLowerCase() ?? '',
          };
        })
        .filter(({ chatId }) => channelUrls.includes(chatId)),
    [allChannels, channelUrls, isBuyer],
  );

  const filteredChannelUrls = useMemo(
    () =>
      chatNames.filter(({ name }) => name?.includes(debouncedQuery.toLowerCase().trim())).map(({ chatId }) => chatId),
    [chatNames, debouncedQuery],
  );

  const filteredChannelsQueryParam = useMemo(
    () => (filteredChannelUrls.length ? filteredChannelUrls : ['NaN']),
    [filteredChannelUrls],
  );

  const channelUrlsFilterByChannelName = useMemo(
    () => (!debouncedQuery.length ? channelUrls : filteredChannelsQueryParam),
    [debouncedQuery.length, channelUrls, filteredChannelsQueryParam],
  );

  const listQuery: GroupChannelListQueryParams = useMemo(
    () => ({
      channelUrlsFilter: isSearchByChannelName ? channelUrlsFilterByChannelName : allChannelsIds,
      includeEmpty: false,
      ...(!isSearchByChannelName && {
        searchFilter: {
          query: debouncedQuery.trim(),
          fields: ['member_nickname'] as GroupChannelSearchField[],
        },
      }),
    }),
    [allChannelsIds, channelUrlsFilterByChannelName, isSearchByChannelName, debouncedQuery],
  );

  return {
    chatsList,
    control,
    isChatsListLoading,
    isSearchByChannelName,
    isQueryString: !!queryString.length,
    listQuery,
    isEmptyList,
    clearSearchField,
    handleSortChannels,
  };
};
