import { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { CommonButton } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { CommonDrawer } from 'layout/MobilePageLayout/components/CommonDrawer';
import { NumberFormField } from 'components/NumberFormField';
import { useForm } from 'react-hook-form';

import { styles } from './styles';

interface OutOfStockProps {
  isOpen: boolean;
  handleClose: () => void;
  onUpdateQuantity: (value: number) => void;
  onSetOutOfStock: () => void;
}

export const OutOfStock: FC<OutOfStockProps> = ({ isOpen, onSetOutOfStock, handleClose, onUpdateQuantity }) => {
  const [displayDifferentQuantity, setDisplayDifferentQuantity] = useState<boolean>(false);
  const { control, watch } = useForm<{ quantity: undefined | number }>({
    defaultValues: {
      quantity: undefined,
    },
  });

  const quantity = watch('quantity');

  const onRemove = () => {
    onSetOutOfStock();
    handleClose();
  };

  const onSubmitDifferentQuantity = () => {
    if (quantity) onUpdateQuantity(quantity);
    handleClose();
  };
  return (
    <>
      <CommonDrawer isOpen={isOpen} handleClose={handleClose} sx={styles.drawer as SxPropsTypes}>
        <Box sx={styles.container}>
          <Box sx={styles.titleContainer}>
            <CommonButton variant="text" sx={styles.close} onClick={handleClose}>
              <CloseIcon />
            </CommonButton>
            <Typography variant="h4" sx={styles.title}>
              Out of stock?
            </Typography>
          </Box>
          <Box>
            If the product is in stock in lesser quantity, you can choose to offer the buyer a different quantity.
          </Box>
          {displayDifferentQuantity && (
            <NumberFormField
              wrapStyles={{ ...styles.amountField } as SxPropsTypes}
              control={control}
              name="quantity"
              placeholder="Suggest new quantity"
              decimalScale={4}
              withoutHighlights
            />
          )}

          <Box sx={styles.btnContainer}>
            {displayDifferentQuantity ? (
              <CommonButton
                variant="contained"
                sx={styles.confirm}
                // fullWidth
                onClick={onSubmitDifferentQuantity}
                disabled={!quantity}
              >
                Submit different quantity
              </CommonButton>
            ) : (
              <CommonButton
                variant="contained"
                sx={styles.confirm}
                fullWidth
                onClick={() => setDisplayDifferentQuantity(true)}
              >
                Offer different quantity
              </CommonButton>
            )}
            <CommonButton variant="contained" color="error" sx={styles.confirm} fullWidth onClick={onRemove}>
              Out of stock
            </CommonButton>
          </Box>
        </Box>
      </CommonDrawer>
    </>
  );
};
