import { FC, MouseEventHandler } from 'react';

import { Box } from '@mui/material';

import { ReactComponent as AddProductIcon } from 'assets/icons/addProduct.svg';
import { ReactComponent as RemoveProducIcon } from 'assets/icons/removeProduct.svg';
import { styles } from './styles';

export interface AddProductButtonProps {
  isChoosed: boolean;
  handleAddProduct: MouseEventHandler<SVGElement>;
}

export const AddProductButton: FC<AddProductButtonProps> = ({ isChoosed, handleAddProduct }) => {
  return (
    <Box aria-label="actions" sx={styles.wrap}>
      {!isChoosed ? (
        <AddProductIcon aria-label="add" onClick={handleAddProduct} />
      ) : (
        <RemoveProducIcon aria-label="remove" />
      )}
    </Box>
  );
};
