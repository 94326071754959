import { SxPropsTypes } from '../../theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  loadingWrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
};
