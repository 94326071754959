import { FC } from 'react';

import { Box } from '@mui/material';

import { DocumentFolder, OrderDocument } from 'app/api/orders/types';
import { orderDocuments } from 'pages/PlaceOrderPage/components/DocumentsList/constants';
import { OrderDocumentCard } from '../OrderDocumentCard';
import { AddDocuments } from '../AddDocuments';
import { styles } from './styles';

interface OrderDocumentsListProps {
  isBuyerActionsAllowed: boolean;
  isSellerActionsAllowed: boolean;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  documents?: DocumentFolder[];
  handleOpenDocumentView: (file: OrderDocument) => void;
}

export const OrderDocumentsList: FC<OrderDocumentsListProps> = ({
  isBuyerActionsAllowed,
  isSellerActionsAllowed,
  orderId,
  quoteId,
  isSampleRequest,
  documents = [],
  handleOpenDocumentView,
}): JSX.Element => {
  const otherDocuments = orderDocuments.filter(({ label }) => !documents.find(({ kind }) => kind === label));

  return (
    <Box sx={{ ...styles.wrap }}>
      {documents.map((document) => (
        <OrderDocumentCard
          key={document.id}
          isBuyerActionsAllowed={isBuyerActionsAllowed}
          isSellerActionsAllowed={isSellerActionsAllowed}
          orderId={orderId}
          quoteId={quoteId}
          isSampleRequest={isSampleRequest}
          document={document}
          handleOpenDocumentView={handleOpenDocumentView}
        />
      ))}
      {(isBuyerActionsAllowed || isSellerActionsAllowed) && <AddDocuments documents={otherDocuments} />}
    </Box>
  );
};
