import { useState, useCallback, UIEvent, useMemo, useEffect, useRef } from 'react';

import { useLocation, matchPath } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getUserData } from 'app/api/user/service';
import { ROUTES } from 'app/routes/constants';
import { useAuth, useCropFamiliesQuery } from 'hooks';
import { useAppSelector } from 'state/hooks';
import { getAccessInfo } from 'utils/storage';
import { ROUTES_WITH_SIDE_MENU } from './constants';

export const usePageLayout = () => {
  const { users, setUserData, isBuyer, isLoginLoading, activeUser } = useAuth();
  const { pathname } = useLocation();

  const { isLoading: isUserLoading } = useQuery(['user-data'], () => getUserData(), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: !users.length && !!getAccessInfo('token'),
    onSuccess: (data) => {
      setUserData(data);
    },
  });

  const [isScrollable, setIsScrollable] = useState(true);
  const [isSubHeaderVisible, setIsSubHeaderVisible] = useState(true);
  const [position, setPosition] = useState(window.pageYOffset);

  const containerRef = useRef<HTMLElement>(null);
  const scrollTopRef = useRef(0);
  const isSignedIn = useAppSelector((state) => state.account.isSignedIn);
  useEffect(() => {
    if (pathname) {
      setIsScrollable(true);
      setIsSubHeaderVisible(isBuyer || (!isLoginLoading && !isSignedIn));
    }
  }, [isBuyer, pathname, isLoginLoading, isSignedIn]);

  const SubheaderRoutes = [ROUTES.buyer._, ROUTES.common.product];
  const isSubHeader = Boolean(SubheaderRoutes.find((r) => matchPath(r, pathname)));
  const isContentWithMenu =
    useMemo(() => ROUTES_WITH_SIDE_MENU.some((path) => matchPath(path, pathname)), [pathname]) &&
    (isBuyer || !activeUser);

  const { cropFamiliesData, isCropFamiliesLoading, isCropFamiliesFetching } = useCropFamiliesQuery({
    enabled: true,
  });

  const handleScroll = useCallback(
    (e: UIEvent) => {
      const moving = e.currentTarget.scrollTop;
      setIsSubHeaderVisible(position <= 0 || position > moving);
      setPosition(moving);
    },
    [position, setPosition],
  );
  const location = useLocation();
  const isLoading = isUserLoading || isCropFamiliesLoading || isCropFamiliesFetching;
  useEffect(() => {
    const element = containerRef.current;
    if (isContentWithMenu && element) {
      const top = element.scrollTop;

      if (top < 600) scrollTopRef.current = top;

      element.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [isContentWithMenu, isScrollable, location, isLoading]);

  return {
    isLoading,
    isScrollable,
    isSubHeader,
    isSubHeaderVisible: isSubHeader && isSubHeaderVisible,
    cropFamiliesData,
    isContentWithMenu,
    containerRef,
    onScroll: isSubHeader ? handleScroll : undefined,
    handleScrollState: setIsScrollable,
  };
};
