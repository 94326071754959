export const NOTIFICATIONS_LIMIT = 20;
export const UPDATE_NOTIFICATIONS_INTERVAL = 20 * 1000;

export const NOTIFICATIONS_QUERY_KEY = 'notifications';
export const NOTIFICATIONS_COUNT_QUERY_KEY = 'notifications-count';

export const INVALIDATE_QUERY_KEYS_ON_QUOTE_NOTIFICATION_CLICK = ['quote-requests', 'orders-counter'];

export const INVALIDATE_QUERY_KEYS_ON_ORDER_NOTIFICATION_CLICK = ['order', 'order-documents-folders'];

export const INVALIDATE_QUERY_KEYS_ON_SAMPLE_NOTIFICATION_CLICK = ['sample-requests', 'orders-counter'];
