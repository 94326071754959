import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
  },
  cancelButton: {
    minWidth: '70px',
    padding: 0,
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B50,

    '&:hover': {
      backgroundColor: 'transparent',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: '34px',
      height: '34px',
    },
  },
  submitButton: {
    color: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: '34px',
      height: '34px',
      padding: '6px 12px',
    },
  },
};
