import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    gap: '16px',
    padding: '8px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',

    ':hover': {
      background: customTheme.custom.hover,
      borderRadius: '4px',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '8px',
      justifyContent: 'space-between',
    },
  },
  inner: {
    width: 'calc(100% - 24px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '4px',
  },
  message: (isBold: boolean) => ({
    maxWidth: '332px',
    fontSize: '14px',
    fontWeight: isBold ? 600 : 400,
    lineHeight: '21px',
    color: customTheme.custom.black.B100,
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  indicator: {
    display: 'block',
    marginTop: '7px',
    minWidth: '8px',
    width: '8px',
    height: '8px',
    background: customTheme.palette.secondary.S100,
    borderRadius: '50%',
  },
  date: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.15px',
    color: customTheme.custom.black.B50,
  },
};
