export const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  listWrap: (isBuyer: boolean) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
    gap: '16px',

    '@media (max-width: 1439px)': {
      ...(isBuyer && {
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
      }),
    },
  }),
  button: {
    margin: '40px auto 0',
  },
};
