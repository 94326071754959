import { useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';

import { AddMessageData } from 'app/api/messages/types';
import { addMessage } from 'app/api/messages/service';
import { ChatFormData } from 'app/api/SBChat';
import { useAuth } from 'hooks';
import { notificationObserver } from 'utils/observer';

interface AttachmentError {
  response: {
    data: {
      image: string[];
    };
  };
}

interface UseCompanyChatModalHandlersProps {
  channel: string;
  startMessageString?: string;
}

export const useCompanyChatHandlers = ({ channel, startMessageString = '' }: UseCompanyChatModalHandlersProps) => {
  const { activeUser } = useAuth();
  const { control, handleSubmit, watch, setValue } = useForm<ChatFormData>({
    defaultValues: { message: '', files: [] },
  });

  const { mutate: handleAddMessages, isLoading } = useMutation(
    (data: AddMessageData[]) => {
      let sendErrorFiles: { file: File }[] = [];
      data.forEach((msg) => !!msg.image && sendErrorFiles.push({ file: msg.image }));

      return Promise.all(
        data.map(
          (dataItem) =>
            (!!dataItem.image || !!dataItem.text?.trim()) &&
            addMessage(dataItem)
              .then(() => {
                if (dataItem?.image) {
                  sendErrorFiles = [...sendErrorFiles].filter((file) => {
                    return file.file.name !== dataItem.image?.name;
                  });
                } else {
                  setValue('message', '');
                }
              })
              .catch((err: AttachmentError) => {
                if (dataItem?.image) {
                  const errorMessage = err?.response?.data?.image?.[0];

                  if (errorMessage.length)
                    notificationObserver.publish({
                      type: 'error',
                      title: errorMessage,
                    });
                }
              })
              .finally(() => {
                setValue('files', sendErrorFiles);
              }),
        ),
      );
    },
    {
      onSuccess: () => {
        setValue('message', '');
      },
    },
  );

  const message = watch('message');
  const files = watch('files');
  const isMessageData = !!message || !!files.length;

  const fileMessages = useMemo(() => {
    return files?.map((file) => ({
      channel,
      image: file.file,
      author: activeUser?.user.id,
    }));
  }, [channel, activeUser, files]);

  const handleConfirm = useCallback(() => {
    const messages: AddMessageData[] = [];

    if (message) {
      const text = [startMessageString, message].join('');

      messages.push({
        channel,
        text,
        author: activeUser?.user.id,
      });
    }

    if (fileMessages) {
      messages.push(...fileMessages);
    }

    if (messages.length) {
      handleAddMessages(messages);
    }
  }, [message, fileMessages, channel, startMessageString, activeUser?.user.id, handleAddMessages]);

  const handleClearMessageInput = useCallback(() => {
    setValue('message', '');
    setValue('files', []);
  }, [setValue]);

  return {
    isLoading,
    control,
    isMessageData,
    handleClearMessageInput,
    handleConfirm,
    handleSubmit,
  };
};
