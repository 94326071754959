import { FC, useCallback, useState } from 'react';
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { QuoteProduct, QuoteProductStatus, QuoteRequestStatus, UnitsType } from 'app/api/quotes/types';
import { CommonTooltip, TextFormField } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { currencyFormat, getCurrency } from 'utils/currencyFormat';
import { formatNumber } from 'utils/formatText';
import { useModal } from 'hooks';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { UnitsMenu } from 'components/UnitsMenu';
import { NumberFormField } from 'components/NumberFormField';
import { QuoteTableFormData } from '../../../types';
import { validationRules } from '../../../utils/validationRules';
import { ProductVariety } from '../../ProductVariety';
import { SellerRemoveProductPopup } from '../../SellerRemoveProductPopup';
import { QuoteColumn, QuoteColumns } from '../QuoteTable/constants';
import { EditQuantityPopup } from '../../EditQuantityPopup';
import { styles } from './styles';
import { ConfirmActions } from '../../ConfirmActions';
import { RemoveProductPopup } from '../../RemoveProductPopup';
import { EditQuantityUnitsPopup } from '../../EditQuantityUnitsPopup';

type QuoteRowInfoProps = {
  data: QuoteProduct;
  isBuyer: boolean;
  isEditMode: boolean;
  control: Control<QuoteTableFormData>;
  index: number;
  quoteStatus: QuoteRequestStatus;
  setValue: UseFormSetValue<QuoteTableFormData>;
};

type QuoteRowColumnProps = {
  column: QuoteColumns;
} & QuoteRowInfoProps;

export const SellerRemoveProductCell = ({
  control,
  index,
  setValue,
  quoteStatus,
}: {
  index: number;
  quoteStatus: QuoteRequestStatus;
  control: Control<QuoteTableFormData>;
  setValue: UseFormSetValue<QuoteTableFormData>;
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleConfirm = () => setIsPopupOpen(true);
  const toggleIsRemovePoductOpen = () => setIsPopupOpen((open) => !open);
  const handleUpdateQuantity = (quantity: number) => {
    setValue(`quotes.${index}.amount`, quantity);
    setIsPopupOpen(false);
  };
  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });

  const handleRemove = () => {
    setValue(`quotes.${index}.status`, QuoteProductStatus.NOT_AVAILABLE);
    setIsPopupOpen(false);
  };

  const markAvailable = () => {
    setValue(`quotes.${index}.status`, QuoteProductStatus.AVAILABLE);
    setIsPopupOpen(false);
  };
  if (quoteStatus !== QuoteRequestStatus.OPEN) {
    return <TableCell sx={{ ...styles.cell, ...styles.notAvailableCell }} align="center" />;
  }
  return (
    <TableCell sx={{ ...styles.cell, ...styles.notAvailableCell }} align="center">
      {quoteProductStatus === QuoteProductStatus.AVAILABLE && (
        <>
          <IconButton aria-label="out of stock" sx={{ ...styles.outOfStockIcon }} onClick={handleConfirm}>
            <CloseIcon />
          </IconButton>
          <SellerRemoveProductPopup
            handleClose={toggleIsRemovePoductOpen}
            isOpen={isPopupOpen}
            handleRemove={handleRemove}
            handleUpdateQuantity={handleUpdateQuantity}
          />
        </>
      )}
      {quoteProductStatus === QuoteProductStatus.NOT_AVAILABLE && (
        <>
          <Box sx={styles.undo} onClick={markAvailable}>
            undo
          </Box>
        </>
      )}
    </TableCell>
  );
};

export const EditQuantityCell = ({
  data,
  control,
  index,
  isBuyer,
  setValue,
  isEditMode,
}: {
  isBuyer: boolean;
  data: QuoteProduct;
  control: Control<QuoteTableFormData>;
  index: number;
  setValue: UseFormSetValue<QuoteTableFormData>;
  isEditMode: boolean;
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handleConfirm = () => setIsPopupOpen(true);
  const toggleIsPopupOpen = () => setIsPopupOpen((open) => !open);
  const handleUpdateQuantity = (quantity: number) => {
    setValue(`quotes.${index}.amount`, quantity);
    setIsPopupOpen(false);
  };

  const quoteAmount = useWatch({
    control,
    name: `quotes.${index}.amount`,
  });

  const quoteAmountType = useWatch({
    control,
    name: `quotes.${index}.amountType`,
  });

  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });
  const isAvailable = quoteProductStatus === QuoteProductStatus.AVAILABLE;

  const acceptedPrice = useWatch({
    control,
    name: `quotes.${index}.acceptedPrice`,
  });

  const isEdited = quoteAmount !== data.amount;

  return (
    <TableCell
      sx={{ ...styles.cell, ...styles.quantityCell, ...(!isAvailable && styles.cellNotAvailable) }}
      align="left"
    >
      <Box>
        <Box component="span" sx={styles.amountWeight}>
          {formatNumber(quoteAmount)}
          {(isEditMode || isBuyer) && isAvailable && !acceptedPrice && (
            <>
              <CommonTooltip title="Click to change quantity">
                <IconButton aria-label="Edit Quantity" sx={{ ...styles.editQuantityBtn }} onClick={handleConfirm}>
                  <EditIcon />
                </IconButton>
              </CommonTooltip>

              <EditQuantityPopup
                handleClose={toggleIsPopupOpen}
                isOpen={isPopupOpen}
                data={data}
                isBuyer={isBuyer}
                unitsType={quoteAmountType}
                handleUpdateQuantity={handleUpdateQuantity}
              />
            </>
          )}
        </Box>
        {isEdited && <Typography sx={styles.editedText}>(edited)</Typography>}
      </Box>
    </TableCell>
  );
};

export const EditQuantityUnitsCell = ({
  index,
  control,
  setValue,
  data,
  isEditMode,
  isBuyer,
}: {
  isBuyer: boolean;
  data: QuoteProduct;
  index: number;
  control: Control<QuoteTableFormData>;
  setValue: UseFormSetValue<QuoteTableFormData>;
  isEditMode: boolean;
}) => {
  const quoteAmountType = useWatch({
    control,
    name: `quotes.${index}.amountType`,
  });
  const quoteAmount = useWatch({
    control,
    name: `quotes.${index}.amount`,
  });

  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();
  const [updatedUnits, setUpdatedUnits] = useState<UnitsType>(quoteAmountType);
  const onChangeQuantityUnits = useCallback(
    (quantityUnits: UnitsType) => {
      if ([quoteAmountType, quantityUnits].some((t) => t === UnitsType.THOUSAND_SEEDS)) {
        setUpdatedUnits(quantityUnits);
        handleOpenModal();
      } else {
        const multiplier = quoteAmountType === UnitsType.KG ? 2.20462 : 0.453592;
        setValue(`quotes.${index}.amount`, Math.round(quoteAmount * multiplier));
        setValue(`quotes.${index}.amountType`, quantityUnits);
      }
    },
    [quoteAmount, quoteAmountType, setValue, index, handleOpenModal],
  );

  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });
  const isAvailable = quoteProductStatus === QuoteProductStatus.AVAILABLE;

  const acceptedPrice = useWatch({
    control,
    name: `quotes.${index}.acceptedPrice`,
  });

  const handleUpdateQuantity = (quantityAmount: number, quantityUnits: UnitsType) => {
    setValue(`quotes.${index}.amountType`, quantityUnits);
    setValue(`quotes.${index}.amount`, quantityAmount);
  };
  const isEditable = isEditMode && !isBuyer && isAvailable && !acceptedPrice;
  const isEdited = quoteAmountType !== data.amountType;
  return (
    <TableCell
      sx={{ ...styles.cell, ...styles.quantityUnitsCell, ...(!isAvailable && styles.cellNotAvailable) }}
      align="left"
    >
      <UnitsMenu
        units={quoteAmountType}
        onChange={onChangeQuantityUnits}
        sxContainer={styles.editQuantityUnitsContainer}
        sxLabel={styles.editQuantityUnitsLabel}
        isEditable={isEditable}
      />
      {isEdited && <Typography sx={styles.editedText}>(edited)</Typography>}

      {isModalOpen && (
        <EditQuantityUnitsPopup
          isOpen={isModalOpen}
          data={data}
          handleClose={handleCloseModal}
          currentAmount={quoteAmount}
          currentUnits={quoteAmountType}
          updatedUnits={updatedUnits}
          update={handleUpdateQuantity}
        />
      )}
    </TableCell>
  );
};

export const SubtotalCell = ({
  data,
  control,
  index,
  isEditMode,
}: {
  data: QuoteProduct;
  control: Control<QuoteTableFormData>;
  index: number;
  isEditMode: boolean;
}) => {
  // This columns is only NOT displayed to seller on initial quote to view subtotal
  // Check if price accepted and use that price.
  // Else, use the opposing user's latest offer price
  // multiply by amount

  const price = useWatch({
    control,
    name: `quotes.${index}.value`,
  });

  const amount = useWatch({
    control,
    name: `quotes.${index}.amount`,
  });

  const currency = useWatch({
    control,
    name: `priceUnits`,
  });

  const acceptedPrice = useWatch({
    control,
    name: `quotes.${index}.acceptedPrice`,
  });

  const status = useWatch({
    control,
    name: `quotes.${index}.status`,
  });

  const isAvailable = status === QuoteProductStatus.AVAILABLE;

  let subtotal = '-';
  if (acceptedPrice) {
    subtotal = currencyFormat(amount * acceptedPrice, currency);
  } else if (!isEditMode) {
    const price = data.latestSellerPrice?.price;
    if (price) {
      subtotal = currencyFormat(amount * price, currency);
    }
  } else if (price) {
    subtotal = currencyFormat(amount * parseFloat(price), currency);
  }

  return (
    <TableCell
      sx={{
        ...styles.cell,
        ...styles.quoteSubtotal,
        ...(!isAvailable && styles.cellNotAvailable),
        ...(acceptedPrice && styles.cellAcceptedSubtotal),
      }}
      align="left"
    >
      {isAvailable && <Box>{subtotal}</Box>}
      {!isEditMode && !isAvailable && (
        <Box sx={styles.quoteNotAvailableText}>
          {status === QuoteProductStatus.REMOVED && 'Removed'}
          {status === QuoteProductStatus.NOT_AVAILABLE && 'Not Available'}
        </Box>
      )}
    </TableCell>
  );
};

export const BuyerRemoveProductCell = ({
  isBuyer,
  data,
  control,
  index,
  setValue,
}: {
  isBuyer: boolean;
  data: QuoteProduct;
  control: Control<QuoteTableFormData>;
  index: number;
  setValue: UseFormSetValue<QuoteTableFormData>;
}) => {
  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();
  const status = useWatch({
    control,
    name: `quotes.${index}.status`,
  });

  const handleRemove = () => {
    handleCloseModal();
    setValue(`quotes.${index}.status`, QuoteProductStatus.REMOVED);
  };
  const undoRemove = () => {
    setValue(`quotes.${index}.status`, QuoteProductStatus.AVAILABLE);
  };

  const acceptedPrice = useWatch({
    control,
    name: `quotes.${index}.acceptedPrice`,
  });
  const isWaitingFirstResponse = isBuyer && !data.latestSellerPrice?.price;
  if (acceptedPrice || isWaitingFirstResponse) {
    return <TableCell sx={{ ...styles.cell, ...styles.buyerRemoveCell }} align="left" />;
  }

  return (
    <TableCell sx={{ ...styles.cell, ...styles.buyerRemoveCell }} align="center">
      {status === QuoteProductStatus.REMOVED && (
        <Typography sx={styles.undoRemove} onClick={undoRemove}>
          undo
        </Typography>
      )}
      {status === QuoteProductStatus.AVAILABLE && (
        <CommonTooltip title="Delete">
          <IconButton
            disabled={false}
            disableRipple
            sx={{ ...styles.closeIcon }}
            onClick={handleOpenModal}
            data-testid="remove-product-button"
          >
            <CloseIcon />
          </IconButton>
        </CommonTooltip>
      )}
      {isModalOpen && (
        <RemoveProductPopup
          title="Remove product?"
          handleClose={handleCloseModal}
          isOpen={isModalOpen}
          handleRemove={handleRemove}
          isLoading={false}
        />
      )}
    </TableCell>
  );
};

export const CounterOfferCell = ({
  data,
  control,
  index,
  isBuyer,
  isEditMode,
}: {
  data: QuoteProduct;
  control: Control<QuoteTableFormData>;
  index: number;
  isBuyer: boolean;
  isEditMode: boolean;
}) => {
  const acceptedPrice = useWatch({
    control,
    name: `quotes.${index}.acceptedPrice`,
  });

  const currency = useWatch({
    control,
    name: `priceUnits`,
  });
  const currencyDetails = getCurrency(currency);
  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });

  if (quoteProductStatus !== QuoteProductStatus.AVAILABLE) {
    return (
      <TableCell sx={{ ...styles.cell, ...styles.pricePerUnitCell, ...styles.cellNotAvailable }} align="left">
        {isEditMode && (
          <Typography sx={styles.quoteNotAvailableText}>
            {quoteProductStatus === QuoteProductStatus.REMOVED && 'Removed'}
            {quoteProductStatus === QuoteProductStatus.NOT_AVAILABLE && 'Not Available'}
          </Typography>
        )}
      </TableCell>
    );
  }
  if (!isEditMode) {
    return (
      <TableCell sx={{ ...styles.cell, ...styles.pricePerUnitCell }} align="left">
        <Typography sx={styles.quoteNotAvailableText}>
          {isBuyer ? currencyFormat(data.latestBuyerPrice?.price) : currencyFormat(data.latestSellerPrice?.price)}
        </Typography>
      </TableCell>
    );
  }
  const isSellerOrHasCounter = !isBuyer || !!data.latestSellerPrice?.price;

  return (
    <TableCell sx={{ ...styles.cell, ...styles.pricePerUnitCell }} align="left">
      {isSellerOrHasCounter && (
        <>
          {acceptedPrice ? (
            <Typography sx={styles.priceAccepted}>Price Accepted</Typography>
          ) : (
            <NumberFormField
              wrapStyles={{ ...styles.amountField } as SxPropsTypes}
              inputStyles={{ ...styles.amountFieldInput } as SxPropsTypes}
              control={control}
              name={`quotes.${index}.value`}
              placeholder="Enter Price"
              startAdornment={currencyDetails.symbol}
              rules={{ ...validationRules.price }}
              decimalScale={2}
              withoutHighlights
              disabled={false}
            />
          )}
        </>
      )}
    </TableCell>
  );
};

export const AcceptedPriceCell = ({
  isBuyer,
  data,
  control,
  index,
  setValue,
}: {
  isBuyer: boolean;
  data: QuoteProduct;
  control: Control<QuoteTableFormData>;
  index: number;
  setValue: UseFormSetValue<QuoteTableFormData>;
}) => {
  const hasOffer = isBuyer ? !!data.latestSellerPrice?.price : !!data.latestBuyerPrice?.price;
  const otherOffer = isBuyer ? data.latestSellerPrice?.price : data.latestBuyerPrice?.price;
  const acceptedPrice = useWatch({
    control,
    name: `quotes.${index}.acceptedPrice`,
  });
  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });

  const currentInputValue = useWatch({
    control,
    name: `quotes.${index}.value`,
  });

  return (
    <TableCell sx={{ ...styles.cell, ...styles.acceptPriceCell }} align="center">
      {quoteProductStatus === QuoteProductStatus.AVAILABLE && hasOffer && !currentInputValue && (
        <ConfirmActions
          isConfirm={!!acceptedPrice}
          isDisabled={false}
          isThousandSeeds={false}
          handleConfirm={() => setValue(`quotes.${index}.acceptedPrice`, otherOffer)}
          handleUndo={() => setValue(`quotes.${index}.acceptedPrice`, undefined)}
        />
      )}
    </TableCell>
  );
};

export const QuoteRowColumn: FC<QuoteRowColumnProps> = ({
  setValue,
  index,
  control,
  isBuyer,
  column,
  isEditMode,
  data,
  quoteStatus,
}) => {
  const currency = useWatch({
    control,
    name: `priceUnits`,
  });
  const currencyDetails = getCurrency(currency);
  const quoteProductStatus = useWatch({
    control,
    name: `quotes.${index}.status`,
  });
  const isAvailable = quoteProductStatus === QuoteProductStatus.AVAILABLE;
  if (column.col === QuoteColumn.Variety) {
    return (
      <TableCell
        sx={{ ...styles.cell, ...styles.varietyCell, ...(!isAvailable && styles.cellNotAvailable) }}
        align="left"
      >
        <ProductVariety data={data} />
      </TableCell>
    );
  }
  if (column.col === QuoteColumn.Quantity) {
    return (
      <EditQuantityCell
        data={data}
        index={index}
        control={control}
        setValue={setValue}
        isEditMode={isEditMode}
        isBuyer={isBuyer}
      />
    );
  }
  if (column.col === QuoteColumn.Units) {
    return (
      <EditQuantityUnitsCell
        data={data}
        isBuyer={isBuyer}
        index={index}
        control={control}
        setValue={setValue}
        isEditMode={isEditMode}
      />
    );
  }
  if (column.col === QuoteColumn.PriceperUnit) {
    return (
      <TableCell sx={{ ...styles.cell, ...styles.pricePerUnitCell }} align="left">
        {quoteStatus === QuoteRequestStatus.OPEN && (
          <TextFormField
            wrapStyles={{ ...styles.amountField } as SxPropsTypes}
            inputStyles={{ ...styles.amountFieldInput } as SxPropsTypes}
            control={control}
            name={`quotes.${index}.value`}
            placeholder="Enter Amount"
            startAdornment={currencyDetails.symbol}
            rules={{ ...validationRules.price }}
            withoutHighlights
            disabled={false}
          />
        )}
      </TableCell>
    );
  }
  if (column.col === QuoteColumn.LastestOffer) {
    if (quoteProductStatus !== QuoteProductStatus.AVAILABLE) {
      return (
        <TableCell
          sx={{
            ...styles.cell,
            ...styles.cellNotAvailable,
            ...styles.unitsCell,
            ...styles.latestOfferCell(isEditMode),
          }}
          align="left"
        />
      );
    }

    return (
      <TableCell sx={{ ...styles.cell, ...styles.unitsCell, ...styles.latestOfferCell(isEditMode) }} align="left">
        <Box component="span" sx={styles.amountWeight}>
          <Box>
            {isBuyer
              ? currencyFormat(data.latestBuyerPrice?.price, data.latestBuyerPrice?.priceUnits)
              : currencyFormat(data.latestSellerPrice?.price, data.latestSellerPrice?.priceUnits)}
          </Box>
        </Box>
      </TableCell>
    );
  }
  if (column.col === QuoteColumn.RequestedPrice) {
    // This is what the OPPOSING user has last requested
    const hasOffer = isBuyer ? !!data.latestSellerPrice?.price : !!data.latestBuyerPrice?.price;
    if (quoteProductStatus !== QuoteProductStatus.AVAILABLE) {
      return (
        <TableCell
          sx={{ ...styles.cell, ...styles.cellNotAvailable, ...styles.requestedPriceCell(isEditMode) }}
          align="left"
        />
      );
    }
    return (
      <TableCell sx={{ ...styles.cell, ...styles.requestedPriceCell(isEditMode) }} align="left">
        <Box component="span" sx={styles.amountWeight}>
          {!hasOffer ? (
            <Box>
              <Typography sx={{ ...styles.statusLabel }}>
                {isBuyer
                  ? 'Seller Input Needed'
                  : currencyFormat(data.latestSellerPrice?.price, data.latestSellerPrice?.priceUnits)}
              </Typography>
            </Box>
          ) : (
            <Box>
              {isBuyer
                ? currencyFormat(data.latestSellerPrice?.price, data.latestSellerPrice?.priceUnits)
                : currencyFormat(data.latestBuyerPrice?.price, data.latestBuyerPrice?.priceUnits)}
            </Box>
          )}
        </Box>
      </TableCell>
    );
  }
  if (column.col === QuoteColumn.CounterOffer) {
    // IF NOT AVAILABLE, display nothing
    return <CounterOfferCell data={data} isBuyer={isBuyer} control={control} index={index} isEditMode={isEditMode} />;
  }
  if (column.col === QuoteColumn.Subtotals) {
    return <SubtotalCell data={data} index={index} control={control} isEditMode={isEditMode} />;
  }
  if (column.col === QuoteColumn.AcceptPrice) {
    // IF NOT AVAILABLE, display NOT AVAILABLE with undo button - two col width
    return <AcceptedPriceCell isBuyer={isBuyer} data={data} control={control} index={index} setValue={setValue} />;
  }
  if (column.col === QuoteColumn.BuyerRemove) {
    // IF NOT AVAILABLE, display NOTHING
    return <BuyerRemoveProductCell isBuyer={isBuyer} data={data} index={index} control={control} setValue={setValue} />;
  }
  if (column.col === QuoteColumn.NotAvailable) {
    return <SellerRemoveProductCell quoteStatus={quoteStatus} index={index} control={control} setValue={setValue} />;
  }
  return null;
};
type QuoteRowProps = {
  columns: QuoteColumns[];
  // hasCounterOffer: boolean;
} & QuoteRowInfoProps;

export const QuoteRow: FC<QuoteRowProps> = ({
  isBuyer,
  columns,
  data,
  control,
  index,
  isEditMode,
  quoteStatus,
  // isDisabled,
  setValue,
}): JSX.Element => {
  return (
    <TableRow sx={{ ...styles.tableRow }}>
      {columns.map((column) => (
        <QuoteRowColumn
          quoteStatus={quoteStatus}
          key={column.col}
          column={column}
          isBuyer={isBuyer}
          data={data}
          control={control}
          index={index}
          isEditMode={isEditMode && quoteStatus === QuoteRequestStatus.OPEN}
          // isDisabled={isDisabled}
          setValue={setValue}
        />
      ))}
    </TableRow>
  );
};
