import { FC, Fragment } from 'react';

import { UseFormWatch } from 'react-hook-form';
import { Box, TableCell, TableRow } from '@mui/material';
import { formatNumber } from 'utils/formatText';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';

import { useQuoteSellerTotal } from '../../../hooks';
import { QuoteTableFormData } from '../../../types';
import { styles } from './styles';
import { QuoteColumns, QuoteColumn } from '../QuoteTable/constants';
import { headerStyleMap } from '../QuoteTable';

interface QuoteSellerTotalProps {
  columns: QuoteColumns[];
  watch: UseFormWatch<QuoteTableFormData>;
}

const Column = ({ id, watch }: { id: QuoteColumn; watch: UseFormWatch<QuoteTableFormData> }) => {
  const { totalPrice, totalAmounts } = useQuoteSellerTotal(watch);
  const headerStyles = headerStyleMap[id];

  if (id === QuoteColumn.Quantity) {
    return (
      <TableCell colSpan={2} sx={{ ...styles.cell, ...headerStyles, ...styles.footerCell }}>
        <Box sx={styles.quantity}>
          {totalAmounts.map(({ unit, amount }, index) => (
            <Fragment key={unit}>
              <Box component="span" sx={styles.quantityLabel}>
                {formatNumber(amount) || '-'} {getUnitDisplayValue(unit, true)}
              </Box>
              {index !== totalAmounts.length - 1 && <Box component="span">+</Box>}
            </Fragment>
          ))}
        </Box>
      </TableCell>
    );
  }
  if (id === QuoteColumn.Units) {
    // NOTE: must return null as quantity column will take 2 column widths
    return null;
  }

  if (id === QuoteColumn.Subtotals) {
    return <TableCell sx={{ ...styles.cell, ...headerStyles, ...styles.footerCell }}>{totalPrice}</TableCell>;
  }
  return <TableCell sx={{ ...styles.cell, ...headerStyles, ...styles.footerCell }} />;
};

export const QuoteSellerTotal: FC<QuoteSellerTotalProps> = ({ columns, watch }) => {
  return (
    <TableRow sx={{ ...styles.footer }}>
      <TableCell sx={{ ...styles.cell, ...styles.footerCell, ...styles.totalCell('230px') }}>Total</TableCell>

      {columns.slice(1).map((column) => (
        <Column key={column.col} id={column.col} watch={watch} />
      ))}
    </TableRow>
  );
};
