import { FC, ChangeEvent } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'assets/icons/circledArrowUpBold.svg';
import { CommonModal } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useDeviceType } from 'hooks';
import { useFilesDropzone } from '../../hooks';
import { OnDragFileLabel } from '../OnDragFileLabel';
import { styles } from './styles';
import { useUploadDocumentsPopup } from './hooks';

interface UploadDocumentsPopupProps {
  isOpen: boolean;
  isDropzoneDisabled: boolean;
  isFileError: boolean;
  handleFileUpload: (e: ChangeEvent<HTMLInputElement> | File[], queryKey: string[]) => void;
  handleClose: () => void;
  handleClearErrors: () => void;
}

export const UploadDocumentsPopup: FC<UploadDocumentsPopupProps> = ({
  isOpen,
  isDropzoneDisabled,
  isFileError,
  handleFileUpload,
  handleClose,
  handleClearErrors,
}) => {
  const { isMobileSize } = useDeviceType();

  const { fileInputRef, onInputFile, onClearInput, handleInputChange } = useUploadDocumentsPopup({
    handleFileUpload,
    handleClearErrors,
  });

  const { getDropzoneRootProps, isDragActive } = useFilesDropzone({
    disabled: isDropzoneDisabled,
    isFileError,
    invalidateQueryKey: ['order-documents-folders'],
    onDrop: handleFileUpload,
    handleClearErrors,
    onSelectedFileError: handleClose,
  });

  return (
    <CommonModal
      isOpen={isOpen}
      withCloseButton={isMobileSize}
      handleClose={handleClose}
      paperStyles={(isMobileSize ? styles.mobilePaper : styles.paper) as SxPropsTypes}
    >
      <Box sx={{ ...styles.wrap }} {...getDropzoneRootProps()}>
        {isDragActive ? (
          <Box sx={{ ...styles.draggableWrap }}>
            <ArrowIcon />
            <OnDragFileLabel />
          </Box>
        ) : (
          <Box>
            <Typography sx={{ ...styles.title, ...styles.titleMain }}>
              Drop files here or{' '}
              <Typography component="span" sx={{ ...styles.title, ...styles.link }} onClick={onInputFile}>
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  accept=".png, .jpg, .pdf"
                  style={{ ...styles.fileInput }}
                  onChange={handleInputChange}
                  onClick={onClearInput}
                />
                <span>browse files</span>
              </Typography>
            </Typography>
            <Typography sx={{ ...styles.title, ...styles.titleSmall }}>
              <Typography component="span" sx={{ ...styles.title, ...styles.link }} onClick={onInputFile}>
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  accept=".png, .jpg, .pdf"
                  style={{ ...styles.fileInput }}
                  onChange={handleInputChange}
                  onClick={onClearInput}
                />
                <span>Browse files</span>
              </Typography>
            </Typography>
            <Typography sx={{ ...styles.subTitle }}>We support JPG, PNG, and PDF files up to 30MB</Typography>
          </Box>
        )}
      </Box>
    </CommonModal>
  );
};
