import React, { FC } from 'react';

import { Box } from '@mui/material';
import { ProductsSlider } from 'components/ProductsSlider';

import { PageLoader } from 'components';
import { useSimilarProducts } from '../../hooks/useSimilarProducst';
import { styles } from './styles';

type SimilarProductsProps = {
  cropId: number;
  containerRef: React.RefObject<HTMLDivElement>;
};

export const SimilarProducts: FC<SimilarProductsProps> = ({ containerRef, cropId }) => {
  const { products, isLoading } = useSimilarProducts({ cropId });

  return (
    <Box component="div" ref={containerRef} sx={{ ...styles.container }}>
      <>
        {isLoading || (!products && <PageLoader />)}
        {!isLoading && products.length > 0 ? (
          <ProductsSlider id="similar-products" name="Similar products" isLink={false} products={products} />
        ) : null}
      </>
    </Box>
  );
};
