import { FC, useCallback } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonModal, CommonButton, LoadingButton } from 'components';
import { useMarkFolderAsCompleteMutation } from '../../hooks';
import { styles } from './styles';

interface CompleteAllPopupProps {
  isOpen: boolean;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  handleClose: () => void;
  setDocumentAsUpdated: () => void;
}

export const CompleteAllPopup: FC<CompleteAllPopupProps> = ({
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  isOpen = false,
  handleClose,
  setDocumentAsUpdated,
}) => {
  const { handleMarkFolderComplete, isMarkFolderCompleteLoading } = useMarkFolderAsCompleteMutation({
    id: folderId,
    orderId,
    quoteId,
    isSampleRequest,
    setDocumentAsUpdated,
    onSuccessAction: handleClose,
  });

  const onAllComplete = useCallback(() => {
    handleMarkFolderComplete();
  }, [handleMarkFolderComplete]);

  return (
    <CommonModal title="Finalize reviewing?" isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        All documents of this type will be considered as &quot;Completed&quot; regardless of their current status. Are
        you sure you want to change the document type status to &quot;Completed&quot;?
      </Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton
          disabled={isMarkFolderCompleteLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.closeButton }}
          onClick={handleClose}
        >
          Cancel
        </CommonButton>
        <LoadingButton
          loading={isMarkFolderCompleteLoading}
          onClick={onAllComplete}
          variant="text"
          color="inherit"
          sx={{ ...styles.confirmButton }}
        >
          All complete
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
