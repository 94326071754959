import { FC } from 'react';

import { Box } from '@mui/material';

import { PageLoader } from 'components';
import { MessagesPageChat } from 'pages/MessagesPage/components';
import { InfoPannel } from '../InfoPannel';
import { SearchPanel } from '../SearchPanel';
import { useChatMessageSearch, useChatPageInner, usePannelHandlers } from './hooks';
import { styles } from './styles';

interface ChatPageInnerProps {
  channelUrl: string;
  isListLoading: boolean;
}

export const ChatPageInner: FC<ChatPageInnerProps> = ({ channelUrl, isListLoading }) => {
  const {
    currentChannelLogo,
    currentChannelName,
    isLogoPlaceholder,
    isBuyer,
    isLoading,
    currentCompanyMembers,
    membersCount,
    otherCompanyMembers,
    currentCompanyName,
    handleNavigateToMessagesPage,
  } = useChatPageInner({
    channelUrl,
  });

  const {
    isInfoPannel,
    isSearchPanel,
    handleCloseInfoPannel,
    handleOpenInfoPannel,
    handleCloseSearchPanel,
    handleOpenSearchPanel,
  } = usePannelHandlers();

  const { selectedMessage, startingPoint, handleResetSelectedMessage, handleSearchResultClick } = useChatMessageSearch({
    handleCloseSearchPanel,
  });

  return (
    <>
      <Box sx={styles.wrap(isBuyer)}>
        {isLoading || isListLoading ? (
          <Box sx={styles.loaderWrap}>
            <PageLoader />
          </Box>
        ) : (
          <MessagesPageChat
            channelUrl={channelUrl}
            channelLogo={currentChannelLogo}
            channelName={currentChannelName}
            isChannelExisting
            isLogoPlaceholder={isLogoPlaceholder}
            isSearchMessagesPannel
            selectedMessage={selectedMessage}
            startingPoint={startingPoint}
            handleBackButtonClick={handleNavigateToMessagesPage}
            handleResetSelectedMessage={handleResetSelectedMessage}
            handleSearchButtonClick={handleOpenSearchPanel}
            handleInfoButtonClick={handleOpenInfoPannel}
          />
        )}
      </Box>

      <InfoPannel
        isOpen={isInfoPannel}
        currentCompanyMembers={currentCompanyMembers}
        currentCompanyName={currentCompanyName}
        membersCount={membersCount}
        otherCompanyName={currentChannelName}
        otherCompanyMembers={otherCompanyMembers}
        currentChannelId={channelUrl}
        handleClose={handleCloseInfoPannel}
      />

      <SearchPanel
        channelName={currentChannelName}
        channelUrl={channelUrl}
        isOpen={isSearchPanel}
        selectedMessageId={selectedMessage?.messageId}
        handleResultClick={handleSearchResultClick}
        handleClose={handleCloseSearchPanel}
      />
    </>
  );
};
