import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { styles } from './styles';

interface FilterListItemProps {
  label: string;
  filterValue?: number | string;
  onClick: () => void;
}

export const FilterListItem: FC<FilterListItemProps> = ({ filterValue, label, onClick }) => {
  return (
    <Box sx={styles.item} onClick={onClick}>
      <Typography sx={styles.label}>
        {label}{' '}
        {!!filterValue && (
          <Typography component="span" sx={styles.value}>
            ({filterValue})
          </Typography>
        )}
      </Typography>
      <ArrowIcon />
    </Box>
  );
};
