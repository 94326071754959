import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  message: {
    paddingLeft: '8px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '21px',
    color: customTheme.palette.primary.P100,
  },
};
