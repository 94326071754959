import { customTheme } from 'theme/MuiThemeProvider/theme';
import { layoutMinWidth, layoutMaxWidth } from '../constants/styleConstants';

export const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: customTheme.custom.mainBg,
  },
  contentWrap: {
    flex: '1',
    width: '100%',
    minWidth: layoutMinWidth,
    maxWidth: layoutMaxWidth,
    margin: `0 auto 0`,
    display: 'flex',
  },
  content: {
    flex: '1',
    padding: '24px 48px 0px',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
};
