import { useCallback, useRef, MouseEvent, ChangeEvent } from 'react';

interface UseUploadDocumentsPopupProps {
  handleFileUpload: (e: ChangeEvent<HTMLInputElement> | File[], queryKey: string[]) => void;
  handleClearErrors: () => void;
}

export const useUploadDocumentsPopup = ({ handleFileUpload, handleClearErrors }: UseUploadDocumentsPopupProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const onInputFile = useCallback(() => {
    handleClearErrors();
    fileInputRef.current?.click();
  }, [handleClearErrors]);

  const onClearInput = useCallback((e: MouseEvent<HTMLInputElement>) => {
    // eslint-disable-next-line no-param-reassign
    e.currentTarget.value = '';
  }, []);

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | File[]) => {
      handleFileUpload(e, ['order-documents-folders']);
    },
    [handleFileUpload],
  );

  return {
    fileInputRef,
    onInputFile,
    onClearInput,
    handleInputChange,
  };
};
