import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'relative',
    padding: '16px 8px 16px 20px',
    overflow: 'hidden',

    ':not(:last-of-type)': {
      borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    },
  },
  opacityWrap: (isOpacity: boolean) => ({
    opacity: isOpacity ? 0.4 : 1,
  }),
  varietyWrap: (isFullWidth: boolean) => ({
    width: isFullWidth ? '100%' : 'calc(100% - 36px)',
    marginBottom: '14px',
  }),
  actionsWrap: {
    position: 'absolute',
    top: '12px',
    right: '4px',
  },
  closeIcon: {
    color: customTheme.custom.black.B70,

    svg: {
      width: '24px',
      height: '24px',
    },
  },
  row: {
    display: 'grid',
    gap: '12px',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridAutoRows: 'auto',
    alignItems: 'start',
  },
  amountWeight: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: customTheme.custom.black.B70,
  },
  weightInner: {
    maxHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    alignSelf: 'end',
  },
  latestOfferPrice: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: customTheme.custom.black.B70,
    alignSelf: 'end',
  },
  lastOfferSubtotal: (isColor: boolean) => ({
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    color: isColor ? customTheme.palette.secondary.S100 : customTheme.custom.black.B70,
    alignSelf: 'end',
  }),
  gridItemWrap: {
    display: 'grid',
    height: '100%',
  },
  statusLabel: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    color: customTheme.custom.black.B100,
  },
  bottomActionsWrap: {
    marginTop: '14px',
    display: 'flex',
    alignItems: 'stretch',
    gap: '10px',
  },
  counterOfferCell: (isLimitedWidth: boolean) => ({
    width: isLimitedWidth ? '50%' : 'fit-content',
  }),
  actions: (isAlignEnd: boolean) => ({
    display: 'flex',
    alignItems: isAlignEnd ? 'end' : 'center',
    justifyContent: 'space-between',
    height: '100%',
  }),
  actionsCell: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'end',
  },
  undoLabel: {
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B70,
    textDecoration: 'underline',
    position: 'relative',
    zIndex: 5,

    ':hover': {
      color: customTheme.custom.black.B50,
      cursor: 'pointer',
    },
  },
  outOfStockContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    flex: 1,
  },
  outOfStock: {
    color: customTheme.custom.red.light,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
};
