import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { NOT_FOUND_ERROR_CODE } from 'app/api/errors';
import { patchOrderDocuments, PatchOrderDocument } from 'app/api/orders';
import { notificationObserver } from 'utils/observer';
import { DOCUMENT_NOT_FOUND_ERROR } from '../constants';

interface UpdateDocumentMutationI {
  id: number;
  folder: number;
  data: PatchOrderDocument;
  setDocumentAsUpdated: () => void;
  onSuccessAction?: () => void;
}

export const useUpdateDocumentMutation = ({
  id,
  folder,
  data,
  setDocumentAsUpdated,
  onSuccessAction,
}: UpdateDocumentMutationI) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(() => patchOrderDocuments(id, { ...data }), {
    onSuccess: () => {
      setDocumentAsUpdated();
      onSuccessAction?.();
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['order-documents', folder] });
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey: ['folder-details'] });
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === NOT_FOUND_ERROR_CODE) {
        notificationObserver.publish({
          type: 'error',
          title: DOCUMENT_NOT_FOUND_ERROR,
        });
      }
    },
  });

  return { mutate, isLoading };
};
