import { customTheme } from 'theme/MuiThemeProvider/theme';

interface WrapStylesProps {
  isCurrentUser: boolean;
  isCurrentCompany: boolean;
  isHighlighted: boolean;
}

export const styles = {
  wrap: ({ isCurrentUser, isCurrentCompany, isHighlighted }: WrapStylesProps) => ({
    width: 'fit-content',
    padding: '10px',
    background: customTheme.palette.secondary.S50,
    borderRadius: '0 10px 10px 10px',

    ...((isCurrentUser || isCurrentCompany) && {
      background: customTheme.custom.blue.B100,
    }),

    ...(isCurrentUser && {
      borderRadius: '10px 0 10px 10px',
    }),

    ...(isHighlighted && {
      background: customTheme.custom.sweetCorn,
    }),
  }),
  textLine: {
    display: 'block',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: ' 0.2px',
    color: customTheme.custom.black.B100,
    wordBreak: 'break-word',
  },
  link: {
    display: 'inline',
    wordBreak: 'break-all',
  },
};
