import { customTheme } from '../../theme/MuiThemeProvider/theme';

export const styles = {
  menuItemWrap: {
    margin: 0,
    padding: '6px 18px',

    '& .MuiFormControlLabel-label': {
      marginLeft: '8px',
      fontSize: '14px',
      color: customTheme.custom.black.B100,
    },
  },
};
