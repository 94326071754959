import { useMemo } from 'react';

import { isMobile, isTablet } from 'react-device-detect';

import { useWindowSize } from './useWindowSize';

export const useDeviceType = () => {
  const { isDesktopSize, isMobileSize } = useWindowSize();

  const isMobileDevice = useMemo(() => !isDesktopSize || isMobile || isTablet, [isDesktopSize]);

  return {
    isMobileDevice,
    isMobile: isMobile || isMobileSize,
    isMobileSize,
  };
};
