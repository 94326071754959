import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles: SxPropsTypes = {
  content: {
    width: '412px',
    marginBottom: '28px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
      width: 'fit-content',
    },
  },
  subTitle: {
    margin: '20px 0 16px',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },
  amountField: {
    margin: 0,
  },
  amountFieldInput: {
    height: '21px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -14px -8px 0',
  },
  closeButton: {
    minWidth: '80px',
    marginRight: '8px',
    color: customTheme.custom.black.B70,
  },
  confirmButton: {
    minWidth: '90px',
    color: customTheme.custom.success,
  },
};
