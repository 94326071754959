import { SxProps } from '@mui/material';
import { customTheme } from '../../theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (additionalStyles?: SxProps) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '13px 12px',
    border: `1px solid ${customTheme.custom.error}`,
    borderRadius: '10px',
    ...(additionalStyles && {
      ...additionalStyles,
    }),
  }),
  errorIcon: {
    height: '24px',
    flex: '0 0 24px',
  },
  errorText: {
    marginLeft: '8px',
    fontSize: '12px',
    color: customTheme.custom.error,
  },
};
