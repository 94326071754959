import { useMemo } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import { CropFamiliesData } from 'app/api/crops';
import { FEATURED_SELLERS_TYPE, FEATURED_TYPE, SEARCH_TYPE } from '../../../constants';

export const useProductsListPageGuard = () => {
  const { type, subType } = useParams();
  const [searchParams] = useSearchParams();

  const client = useQueryClient();
  const cropFamiliesData = client.getQueryState(['crop-families']);
  const cropFamilies: CropFamiliesData = cropFamiliesData?.data as CropFamiliesData;

  const isAllowed = useMemo(() => {
    const searchParam = searchParams.get(type || '') || '';

    if (type === SEARCH_TYPE && searchParam) {
      return true;
    }

    if ((type === FEATURED_TYPE || type === FEATURED_SELLERS_TYPE) && !subType) {
      return true;
    }

    if (!cropFamiliesData) {
      return false;
    }

    const cropFamily = cropFamilies?.find((item) => item.name === type);
    const isTypeExist = !!cropFamily;

    if (!isTypeExist) {
      return false;
    }

    const isSubTypeExist = !subType || !!cropFamily?.crops?.find((item) => item.name === subType);

    if (!isSubTypeExist) {
      return false;
    }

    return true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isAllowed };
};
