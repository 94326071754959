import { FC } from 'react';

import { ResetPasswordSteps } from '../../types';
import { ResetPasswordForm } from '../ResetPasswordForm';
import { InfoMessage } from '../InfoMessage';
import { useResetPasswordPageHandlers } from '../../hooks';

export const ResetPasswordPageInner: FC = () => {
  const { currentStep, isLoading, handleSubmitResetPassword, handleBackButtonClick } = useResetPasswordPageHandlers();

  return currentStep === ResetPasswordSteps.resetPasswordForm ? (
    <ResetPasswordForm
      isLoading={isLoading}
      onSubmit={handleSubmitResetPassword}
      onCancelButtonClick={handleBackButtonClick}
    />
  ) : (
    <InfoMessage onBackButtonClick={handleBackButtonClick} />
  );
};
