import { FC, ReactNode } from 'react';

import { Box, Dialog, IconButton } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/closeSmall.svg';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { styles } from './styles';

export interface MultiplePopupsProps {
  title: ReactNode;
  isOpen: boolean;
  isChatOpen: boolean;
  children: ReactNode;
  paperStyles?: SxPropsTypes;
  scroll?: 'body' | 'paper';
  chat: () => JSX.Element;
  handleClose: () => void;
}

export const MultiplePopups: FC<MultiplePopupsProps> = ({
  title,
  children,
  isChatOpen,
  paperStyles,
  scroll,
  isOpen = false,
  chat,
  handleClose,
}) => (
  <Dialog
    open={isOpen}
    onClose={handleClose}
    scroll={scroll}
    PaperProps={{
      sx: {
        ...styles.paper,
      },
    }}
    data-testid="quote-details"
    onClick={(e) => e.stopPropagation()}
  >
    <Box sx={{ ...styles.wrap, ...paperStyles }}>
      <Box sx={{ ...styles.titleWrap }}>
        {title}

        <IconButton sx={{ ...styles.closeButton }} onClick={handleClose} data-testid="close-modal-button">
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Box>

    {isChatOpen && <>{chat()}</>}
  </Dialog>
);
