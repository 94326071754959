import { FC, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Markdown from 'markdown-to-jsx';

import { ProductDetail } from 'app/api/products/types';
import imagePlaceholder from 'assets/images/imagePlaceholderLarge.png';
import { useImageHandlers } from 'hooks/useImageHandlers';

import { styles } from './styles';

interface ProductDetailsDescriptionProps {
  data: ProductDetail;
}

export const ProductDetailsDescription: FC<ProductDetailsDescriptionProps> = ({ data }): JSX.Element => {
  const { handleImageError } = useImageHandlers();

  const productImage = data.thumbnail || data.image || '';

  const [isImage, setIsImage] = useState(!!productImage.length);

  const handleImage = useCallback(() => {
    setIsImage(false);
  }, []);

  return (
    <Box sx={{ ...styles.imageContentWrap }}>
      <Box sx={{ ...styles.contentWrap, ...styles.imageWrap }}>
        <Box
          component="img"
          loading="lazy"
          src={productImage}
          sx={{ ...styles.image }}
          onError={handleImageError(imagePlaceholder)}
        />
      </Box>

      <Box sx={{ ...styles.contentWrap }}>
        {isImage && (
          <Box
            component="img"
            loading="lazy"
            src={productImage || imagePlaceholder}
            sx={{ ...styles.imageBg }}
            onError={handleImage}
            data-testid="product-image"
          />
        )}

        <Box sx={{ ...styles.content(isImage) }}>
          <Typography sx={{ ...styles.name }}>
            {data.crop}
            {data.variety && ` - ${data.variety}`}
          </Typography>
          <Typography sx={{ ...styles.place }}>
            {data.company.city}, {data.company.country}
          </Typography>

          {!!data?.description && (
            <>
              <Typography sx={{ ...styles.descriptionTitle }}>Notes</Typography>
              <Box sx={{ ...styles.description }}>
                <Markdown>{data.description}</Markdown>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
