import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  iframe: (isMobile: boolean) => ({
    width: '100%',
    maxHeight: '100%',
    aspectRatio: '232 / 198',
    overflow: 'hidden',

    ...(isMobile && {
      minHeight: '100%',
      maxHeight: 'unset',
      marginBottom: '16px',
    }),
  }),

  image: {
    width: 'auto',
    minWidth: '100%',
    minHeight: '100%',
    maxHeight: '640px',
    objectFit: 'cover',
    aspectRatio: '232 / 198',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      heght: 'auto',
    },
  },
};
