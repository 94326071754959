import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  loaderWrap: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: 'calc(100svh - 220px)',
    },
  },
  emptyWrap: {
    marginTop: '18px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: 'calc(100svh - 292px)',
    },
  },
  listWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0 -14px',
      minHeight: 'calc(100svh - 276px)',
    },
  },
};
