import { FC } from 'react';
import { ButtonProps, CircularProgress } from '@mui/material';

import { CommonButton } from '../CommonButton';

interface LoadingButtonProps {
  loading: boolean;
  size?: number;
}

export const LoadingButton: FC<ButtonProps & LoadingButtonProps> = ({ children, sx, loading, size = 24, ...props }) => (
  <CommonButton variant="contained" sx={{ ...(sx as ButtonProps) }} {...props}>
    {loading ? <CircularProgress color="inherit" size={size} /> : children}
  </CommonButton>
);
