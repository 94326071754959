import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 24px',
    height: '368px',
    position: 'relative',
    borderRadius: '10px',
    backgroundColor: customTheme.custom.brightGreen,
    overflow: 'hidden',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '316px',
      borderRadius: '10px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
  },
  mainContainer: {
    marginBottom: '30px',
    textAlign: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
    },
  },
  subTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: 1,
      flexDirection: 'column',
    },
  },
  subText: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 600,
  },
  subTextDivider: {
    width: '4px',
    height: '4px',
    backgroundColor: '#FFF',
    borderRadius: '50%',
    margin: '0 8px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  num: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '36px',
    fontWeight: 800,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '18px',
    },
  },
  main: {
    color: '#FFF',
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 800,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
    },
  },
  img: {
    position: 'absolute',
    left: 'auto',
    right: 'auto',
    bottom: 0,
    width: '200px',
  },
};
