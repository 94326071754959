import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isBorder: boolean, isWarning: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '12px',
    borderTop: `1px solid ${customTheme.custom.black.B10}`,
    borderRadius: '0px 0px 10px 10px',

    ...(isBorder && {
      borderRadius: '5px',
      border: `1px solid ${customTheme.custom.black.B10}`,
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      '.MuiButtonBase-root.MuiIconButton-root:hover': {
        background: 'transparent',
      },
    },
    ...(isWarning && {
      border: `1px solid ${customTheme.custom.error}`,
    }),
  }),
  popperContainer: {
    zIndex: 10000,
  },
  invalidText: (isInvalid: boolean) => ({
    maxWidth: '580px',
    color: customTheme.custom.white,
    borderRadius: '4px',
    padding: '8px',
    fontSize: '12px',
    fontWeight: 500,
    backgroundColor: customTheme.custom.red.light,
    ...(isInvalid && {
      backgroundColor: customTheme.custom.red.dark,
    }),
  }),
  invalidTextMain: {
    fontWeight: 600,
  },
  messageWrap: {
    display: 'flex',
  },
  historyIconWrap: {
    cursor: 'pointer',
  },
  historyIcon: (isSystemMessagesHidden: boolean) => ({
    width: '32px',
    height: '32px',
    padding: '4px',
    color: isSystemMessagesHidden ? customTheme.custom.black.B50 : customTheme.palette.primary.P100,
  }),
  divider: {
    marginRight: '16px',
    marginLeft: '6px',
    borderColor: customTheme.custom.black.B10,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginRight: '12px',
      marginLeft: '4px',
    },
  },
  inputWrapStyles: {
    width: '100%',
    border: 'none',

    '&.Mui-disabled': {
      background: 'transparent',
    },

    ':hover': {
      backgroundColor: 'transparent',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '18px',
    },
  },
  inputBoxStyles: {
    width: 'calc(100% - 80px)',
    margin: 0,
  },
  input: {
    width: '100%',
    fontSize: '12px',
    lineHeight: '18px',

    '.MuiInputBase-input': {
      '&::placeholder': {
        color: customTheme.custom.black.B70,
        opacity: 1,
      },
    },
  },
  attachmentIconWrap: {
    cursor: 'pointer',
  },
  attachmentIcon: {
    width: '32px',
    height: '32px',
    padding: '4px',
    color: customTheme.custom.black.B50,

    '&.Mui-disabled': {
      color: customTheme.custom.black.B10,
    },
  },
  fileInput: {
    display: 'none',
  },
  filesWrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
    gap: '0px 8px',
  },
  fileBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: customTheme.custom.black.B5,
  },
  fileName: {
    flex: '1',
    width: '100%',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cleanIcon: {
    display: 'flex',
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
  errorBox: {
    width: 'calc(100% - 44px)',
    fontSize: '12px',
    lineHeight: '12px',
    color: customTheme.custom.error,
  },
  sendButtonWrap: (isMessagesPage: boolean, disabled: boolean) => ({
    width: '48px',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    borderRadius: '0px 0px 9px 0px',
    ...(isMessagesPage && { borderRadius: '0px 4px 4px 0px' }),
    overflow: 'hidden',
    justifyContent: 'center',
    backgroundColor: disabled ? 'transparent' : customTheme.palette.primary.P50,
  }),
  sendButtonInner: (disabled: boolean) => ({
    width: '48px',
    height: '56px',
    backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : customTheme.palette.primary.P100,
  }),
  sendButton: {
    height: 'fit-content',
    marginTop: '8px',
    '& svg': {
      width: '24px',
      height: '24px',
      fill: customTheme.custom.white,
    },

    ':disabled svg': {
      fill: 'rgba(0, 0, 0, 0.26)',
    },
  },
  loadingButton: {
    padding: 0,
    marginTop: '12px',
    width: '100%',
    height: 'fit-content',
    minWidth: 'fit-content',
    cursor: 'default',
    pointerEvents: 'none',

    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
  },
};
