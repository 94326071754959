import { FC } from 'react';

import { Box } from '@mui/material';

import { DashboardTabs } from 'app/api/quotes/types';
import { OrdersListData } from 'app/api/orders/types';
import { QuoteCard } from 'components';
import { getQuoteUniqueProductsArray } from '../../utils';
import { styles } from './styles';

interface OrdersListProps {
  isBuyer: boolean;
  isCanceledSection: boolean;
  data: OrdersListData;
  tab?: DashboardTabs;
}

export const OrdersList: FC<OrdersListProps> = ({ isBuyer, isCanceledSection, data, tab }) => {
  return (
    <Box sx={{ ...styles.listWrap }}>
      {data.map((order) => (
        <QuoteCard
          key={order.id}
          id={order.id}
          isBuyer={isBuyer}
          quote={order.quote}
          products={getQuoteUniqueProductsArray(order.quote, true)}
          company={isBuyer ? order.quote.sellerCompany : order.quote.buyerCompany}
          totalQuantity={order.quote.totalQuantity}
          status={order.status}
          isCanceled={isCanceledSection}
          createdAt={order.createdAt}
          estimatedDeliveryDate={order.estimatedDelivery}
          deliveredAt={order.deliveredAt}
          tab={tab}
          isNew={false}
          isHighlighted={order.quote.highlight}
          orderNumber={order.number}
          isSampleRequest={order.quote.isSampleType}
        />
      ))}
    </Box>
  );
};
