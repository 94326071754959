import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { PalletType, ShipmentInfo } from 'app/api/orders';
import { palletTypeLabel, palletSizeLabel } from '../../constants';
import { styles } from './styles';

type PalletInfoProps = ShipmentInfo;

export const PalletInfo: FC<PalletInfoProps> = ({ palletType, length, width, height, weight }) => {
  return (
    <>
      {!!palletType && (
        <>
          <Box sx={{ ...styles.item }}>
            <Typography sx={{ ...styles.subTitle }}>Pallet type</Typography>
            <Typography sx={{ ...styles.value }}>{palletTypeLabel[palletType]}</Typography>
          </Box>
          <Box sx={{ ...styles.item }}>
            <Typography sx={{ ...styles.subTitle }}>Length/Width</Typography>
            <Typography sx={{ ...styles.value }}>
              {palletType === PalletType.CUSTOM
                ? `${length ?? '-'} cm/${width ?? '-'} cm`
                : palletSizeLabel[palletType]}
            </Typography>
          </Box>
        </>
      )}

      {!!height && (
        <Box sx={{ ...styles.item }}>
          <Typography sx={{ ...styles.subTitle }}>Height</Typography>
          <Typography sx={{ ...styles.value }}>{height} cm</Typography>
        </Box>
      )}

      {!!weight && (
        <Box sx={{ ...styles.item }}>
          <Typography sx={{ ...styles.subTitle }}>Weight</Typography>
          <Typography sx={{ ...styles.value }}>{weight} kg</Typography>
        </Box>
      )}
    </>
  );
};
