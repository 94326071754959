import { useCallback, useMemo, useState } from 'react';

import { matchPath, useLocation } from 'react-router-dom';

import { ROUTES_TO_HIDE_MOBILE_FOOTER } from '../constants';

export const useMobilePageLayout = () => {
  const { pathname } = useLocation();

  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  const isFooterHidden = useMemo(
    () => ROUTES_TO_HIDE_MOBILE_FOOTER.some((path) => matchPath(path, pathname)),
    [pathname],
  );

  const handleSideBarToggle = useCallback(() => {
    setIsSideBarOpen((prevState) => !prevState);
  }, []);

  const handleSideBarClose = useCallback(() => {
    setIsSideBarOpen(false);
  }, []);

  return {
    isFooterVisible: !isFooterHidden,
    isSideBarOpen,
    handleSideBarClose,
    handleSideBarToggle,
  };
};
