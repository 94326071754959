import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  nativeInput: (full: boolean) => ({
    width: '250px',
    ...(full && { width: '100%' }),
    height: '32px',
    borderRadius: '8px',
    border: '1px solid #E8E8E8',
    background: 'white',
    fontWeight: '500',
    fontSize: '16px',
    padding: '4px 24px 4px 8px',
    backgroundColor: customTheme.custom.black.B5,
    '.MuiNativeSelect-select': {
      textAlign: 'left',
      '&:focus': {
        backgroundColor: 'inherit',
      },
    },
  }),
};
