import { FC, useMemo } from 'react';

import { SBChatMode } from 'app/api/SBChat';
import { SBChannelHeaderPlaceholder } from '../SBChannelHeaderPlaceholder';

interface SBChannelHeaderProps {
  channelUrl: string;
  companyName: string;
  mode: SBChatMode;
  handleClose?: () => void;
}

export const SBChannelHeader: FC<SBChannelHeaderProps> = ({ channelUrl, companyName, mode, handleClose }) => {
  const headerComponent = useMemo(() => {
    if (mode === SBChatMode.QUOTE_DETAILS || mode === SBChatMode.COMPANY_MODAL) {
      return (
        <SBChannelHeaderPlaceholder
          channelUrl={channelUrl}
          companyName={companyName}
          mode={mode}
          handleClose={handleClose}
        />
      );
    }

    return <></>;
  }, [handleClose, mode, companyName, channelUrl]);

  return <>{headerComponent}</>;
};
