import { useCallback, useState } from 'react';

interface FileType {
  file: File;
}

export const useQuoteRequestMessagesHandlers = () => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<FileType[]>([]);

  const handleMessageChange = useCallback((value: string) => {
    setMessage(value);
  }, []);

  const handleFilesChange = useCallback((files: FileType[]) => {
    setFiles(files);
  }, []);

  return {
    message,
    files,
    handleMessageChange,
    handleFilesChange,
  };
};
