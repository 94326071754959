import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '80px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '0 24px 0 24px',
    },
  },
  logo: {
    margin: '87px 0 40px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '70px',
    },
  },
  formWrap: (isSmallPaddings: boolean) => ({
    width: '320px',
    padding: '45px',
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'calc(100% - 64px)',
      maxWidth: '320px',
      padding: '44px 32px',

      ...(isSmallPaddings && {
        paddingLeft: '20px',
        paddingRight: '20px',
        width: 'calc(100% - 48px)',
      }),
    },
  }),
};
