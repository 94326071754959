import { FC, useState } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { CommonButton } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { CommonDrawer } from 'layout/MobilePageLayout/components/CommonDrawer';

import { styles } from './styles';

interface MobileSelectProps {
  title: string;
  isOpen: boolean;
  handleClose: () => void;
  value?: string;
  description?: string;
  onChange: (value: string) => void;
  options: { label: string; abbr?: string; value: string }[];
}
const messages = { current: 'Currently Set to', change: 'Change to' };
export const MobileSelect: FC<MobileSelectProps> = ({
  isOpen,
  handleClose,
  title,
  value,
  options,
  onChange,
  description,
}) => {
  const current = options.find((option) => option.value === value);
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const onConfirm = () => {
    if (selected) onChange(selected);
    handleClose();
  };
  return (
    <CommonDrawer isOpen={isOpen} handleClose={handleClose} sx={styles.drawer as SxPropsTypes} anchor="right">
      <Box sx={styles.titleContainer}>
        <CommonButton variant="text" sx={styles.close} onClick={handleClose}>
          <CloseIcon />
        </CommonButton>
        <Typography variant="h4" sx={styles.title}>
          {title}
        </Typography>
      </Box>
      {description && <Box sx={styles.description}>{description}</Box>}
      {current && (
        <Box sx={styles.currentContainer}>
          <Typography variant="h5" sx={styles.labelText}>
            {messages.current}
          </Typography>
          <Box sx={styles.current}>
            <>
              <Typography component="span" sx={styles.currentMain}>
                {current.label}
              </Typography>
              {current.abbr && (
                <Typography component="span" sx={styles.currentSub}>
                  {current.abbr}
                </Typography>
              )}
            </>
          </Box>
        </Box>
      )}
      <Box sx={styles.change}>
        {current && (
          <Typography variant="h5" sx={styles.labelText}>
            {messages.change}
          </Typography>
        )}
        <Box sx={styles.optionsContainer}>
          {options
            .filter((o) => o.value !== value)
            .map((option) => (
              <Box
                key={option.value}
                sx={{ ...styles.value, ...(selected === option.value && styles.selected) }}
                onClick={() => setSelected(option.value)}
              >
                <Box sx={styles.innerValue}>
                  <Typography component="span" sx={styles.optionLabel}>
                    {option.label}
                  </Typography>
                  {option.abbr && (
                    <Typography component="span" sx={styles.optionSub}>
                      {option.abbr}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
        </Box>
      </Box>

      <Box sx={styles.bottom}>
        <CommonButton variant="contained" sx={styles.confirm} fullWidth onClick={onConfirm} disabled={!selected}>
          Confirm
        </CommonButton>
      </Box>
    </CommonDrawer>
  );
};
