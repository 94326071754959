import { FC } from 'react';

import { Box } from '@mui/material';

import { PageLoader } from 'components';
import { PageHeader, DashboardPageInner } from './components';
import { useDashboardPageHandlers, useDashboardTabs } from './hooks';
import { styles } from './styles';

export const DashboardPage: FC = () => {
  const { isBuyer, activeMainTab, activeTab, isSampleRequestsTab, handleTabChange } = useDashboardTabs();
  const { isLoading, quoteRequestsData, ordersData, ordersCounter, control, sortOptions } = useDashboardPageHandlers({
    activeMainTab,
    activeTab,
    isSampleRequestsTab,
  });

  if (isLoading || !activeMainTab) {
    return (
      <Box sx={{ ...styles.loaderWrap }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.pageWrap }}>
      <PageHeader
        control={control}
        activeTabId={activeMainTab.value}
        sortOptions={sortOptions}
        onTabChange={handleTabChange}
        ordersCounter={ordersCounter}
      />
      <DashboardPageInner
        isBuyer={isBuyer}
        activeMainTab={activeMainTab}
        quoteRequestsData={quoteRequestsData}
        ordersData={ordersData}
      />
    </Box>
  );
};
