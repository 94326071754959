import { FC, useCallback } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Typography } from '@mui/material';

import { ReactComponent as CopyIcon } from 'assets/icons/copyIcon.svg';
import { CommonButton } from 'components';
import { notificationObserver } from 'utils/observer';
import { styles } from './styles';

interface CopyButtonProps {
  copyText: string;
}

export const CopyButton: FC<CopyButtonProps> = ({ copyText }) => {
  const handleCopy = useCallback(() => {
    notificationObserver.publish({ type: 'success', title: 'Copied to clipboard!' });
  }, []);

  return (
    <CopyToClipboard text={copyText} onCopy={handleCopy}>
      <CommonButton sx={{ ...styles.copyButton }} variant="text">
        <CopyIcon />
        <Typography sx={{ ...styles.copyText }}>Copy payment info</Typography>
      </CommonButton>
    </CopyToClipboard>
  );
};
