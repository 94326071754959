import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  valueWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  valueWrapRight: {
    textAlign: 'right',
  },
  label: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.palette.primary.P100,
  },
  value: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },
  unit: {
    fontSize: '10px',
    lineHeight: '16px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
  },
  sliderWrap: {
    padding: 0,
  },
};
