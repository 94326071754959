import { useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { getFeaturedSellers, getHomePageData, getProductsListData, HomePageSection, Product } from 'app/api/products';
import { shuffleArray } from 'utils/shuffleArray';
import { Company } from 'app/api/company';
import { useAuth } from 'hooks';
import { UserData } from 'app/api/user/types';
import { usePostHog } from 'posthog-js/react';
import { useSearchParams } from 'react-router-dom';
import { getGoogleCountry } from './getGoogleCountry';

export type LandingPageVariation = 'control' | 'v1' | 'v2' | 'v3';

interface BuyerHomePageHandlers {
  activeUser: UserData | null;
  isLoading: boolean;
  homePageData: HomePageSection[];

  featuredProducts: Product[];
  newProducts: Product[];
  featuredSuppliers: Company[];
  featuredCrop: string;
  cropData: Product[];
  country: { name: string; adj: string } | undefined;
  variation: LandingPageVariation;
}

// NOTE: This is a hard-coded list of featured suppliers
const featuredSuppliersOrder = [
  185, // basf
  245, // syngenta
  222, // east west
  188, // us agriseeds
  191, // fito
  219, // barenburg
  8, // may
  17, // chia thai
  49, // hollar
  104, // graines voltz
  18, // known
  24, // indo
  48, // condor
  146, // feltrin
  135, // seed bound
  16, // ramiro
  25, // terranova
  50, // asia
  26,
  28,
  2,
  30,
  23,
  29,
  66,
  22,
  84,
];
const featuredSuppliersOrderSet = new Set(featuredSuppliersOrder);

export const useBuyerHomePageHandlers = (): BuyerHomePageHandlers => {
  const [data, setData] = useState<HomePageSection[]>([]);
  const [cropData, setCropData] = useState<Product[]>([]);
  const [featuredSuppliers, setFeaturedSuppliers] = useState<Company[]>([]);
  const [featuredProducts, setFeaturedProducts] = useState<Product[]>([]);
  const [newProducts, setNewProducts] = useState<Product[]>([]);
  const { activeUser } = useAuth();

  const { isLoading, isFetching } = useQuery(['home-page'], () => getHomePageData(), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      // to show the Vagetable at the top of the crop families list
      // https://yalantis.atlassian.net/browse/SPZ-1953
      const vegetableIndex = data.cropFamilies.findIndex((family) => family.name.toLowerCase() === 'vegetable');
      const vegetableSection = data.cropFamilies.splice(vegetableIndex, 1)[0];
      data.cropFamilies.unshift(vegetableSection);
      setFeaturedProducts(shuffleArray<Product>(data.featuredProducts));
      setNewProducts(shuffleArray<Product>(data.newProducts));
      setData(data.cropFamilies);
    },
  });

  const { isLoading: isFeaturedSellersLoading, isFetching: isFeaturedSellersFetching } = useQuery(
    ['featured-sellers'],
    () => getFeaturedSellers(),
    {
      refetchOnWindowFocus: false,
      enabled: !!activeUser,
      onSuccess: (data) => {
        const allCompanies = data.filter((supplier) => !!supplier.logo);
        const companyMap = allCompanies.reduce((acc, supplier) => {
          return { ...acc, [supplier.id]: supplier };
        }, {} as { [id: number]: Company });
        const orderedCompanies = featuredSuppliersOrder.map((id) => companyMap[id]).filter(Boolean);
        allCompanies.forEach((company) => {
          if (!featuredSuppliersOrderSet.has(company.id)) {
            orderedCompanies.push(company);
          }
        });
        setFeaturedSuppliers(orderedCompanies);
      },
    },
  );

  const [searchParams] = useSearchParams();
  const posthog = usePostHog();
  const variation: LandingPageVariation =
    (posthog.getFeatureFlag('landing-page-variations') as LandingPageVariation) || 'control';

  const countryIdParam = searchParams.get('country_id') ?? '';
  const country = getGoogleCountry(countryIdParam);
  const cropName = searchParams.get('crop_name') ?? '';
  const isCropEnabled = !!cropName && cropName !== 'Herb' && cropName !== 'Grain';
  const secondCropQueryEnabled = cropName === 'Pepper';
  const cropQuery = useQuery(
    ['home-page-product', cropName],
    () =>
      getProductsListData({
        offset: 0,
        limit: 20,
        crop: cropName === 'Pepper' ? 'Sweet Pepper' : cropName,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: isCropEnabled,
      onSuccess: (data) => {
        setCropData((cropData) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const updated = cropData.concat(data.results as any as Product[]);
          updated.sort((a, b) => (a.rank || 0) - (b.rank || 0));

          return updated;
        });
      },
    },
  );

  const secondCropQuery = useQuery(
    ['home-page-product', cropName, 'secondary'],
    () =>
      getProductsListData({
        offset: 0,
        limit: 20,
        crop: 'Hot Pepper',
      }),
    {
      refetchOnWindowFocus: false,
      enabled: secondCropQueryEnabled,
      onSuccess: (data) => {
        setCropData((cropData) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const updated = cropData.concat(data.results as any as Product[]);
          updated.sort((a, b) => (a.rank || 0) - (b.rank || 0));
          return updated;
        });
      },
    },
  );
  const { isLoading: isCropLoading, isFetching: isCropFetching } = cropQuery;
  const { isLoading: isSecondayCropLoading, isFetching: isSecondaryCropFetching } = secondCropQuery;
  const isFeaturedCropLoading =
    !!cropName && (isCropLoading || isCropFetching) && (isSecondayCropLoading || isSecondaryCropFetching);
  const filteredData = useMemo(() => {
    let filtered = [...data].filter((section) => !!section.products.length);
    if (cropName === 'Herb' || cropName === 'Grain') {
      setCropData(filtered.find((section) => section.name === cropName)?.products || []);
      filtered = filtered.filter((section) => section.name !== cropName);
    }
    return filtered;
  }, [data, cropName]);

  return {
    activeUser,
    isLoading:
      isLoading || isFetching || isFeaturedSellersLoading || isFeaturedSellersFetching || isFeaturedCropLoading,
    homePageData: filteredData,
    featuredCrop: cropName,
    country,
    variation,
    cropData,
    newProducts,
    featuredSuppliers,
    featuredProducts,
  };
};
