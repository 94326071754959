import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { getChatInfoData, MuteStatus, NotificationsSettingsForm } from 'app/api/SBChat';
import { useAuth } from 'hooks';

interface UseNotificationsSettingsProps {
  channelUrl: string;
}

export const useNotificationsSettings = ({ channelUrl }: UseNotificationsSettingsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { sendbirdUserId } = useAuth();

  const handleSettingsClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMemu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const { data, isLoading } = useQuery(
    ['notifications-settings', channelUrl],
    () => getChatInfoData({ id: channelUrl }),
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  );

  const muteStatus = useMemo(
    () => data?.members?.find((member) => member.member.chatId === sendbirdUserId)?.muteStatus,
    [data, sendbirdUserId],
  );

  const isAllMuted = muteStatus === MuteStatus.MUTE_ALL || muteStatus === MuteStatus.MUTE_ALL_EXCEPT_MESSAGES;
  const isMessagesMuted = muteStatus === MuteStatus.MUTE_ALL || muteStatus === MuteStatus.MUTE_MESSAGES;

  const initialSettingsValues: NotificationsSettingsForm = {
    all: isAllMuted,
    messages: isMessagesMuted,
  };

  return {
    anchorEl,
    initialSettingsValues,
    isLoading,
    open,
    handleSettingsClick,
    handleCloseMemu,
  };
};
