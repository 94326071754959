import { FC } from 'react';

import { Control, UseFormGetValues, UseFormReset } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import {
  AdditionalFiltersControlNames,
  INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT_LABEL,
  WEIGHT_UNIT,
} from '../../../../constants';
import { useSliderFilterPopup } from '../../hooks';
import { CommonFiltersDrawer } from '../CommonFiltersDrawer';
import { FilterListItem } from '../FilterListItem';
import { SliderInner } from '../SliderInner';

interface WeightFilterProps {
  control: Control<ProductsFiltersForm>;
  isWeightRange: boolean;
  maxWeightLimit: number;
  minWeightLimit: number;
  weightStep: number;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  handleChangeWeightCheckBox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeWeightSlider: (newValue: number[]) => void;
  reset: UseFormReset<ProductsFiltersForm>;
}

export const WeightFilter: FC<WeightFilterProps> = ({
  control,
  isWeightRange,
  maxWeightLimit,
  minWeightLimit,
  weightStep,
  getValues,
  handleChangeWeightCheckBox,
  handleChangeWeightSlider,
  reset,
}) => {
  const defaultTitle = 'Weight';
  const controlName = AdditionalFiltersControlNames.WEIGHT;
  const checkBoxControlName = AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT;

  const { isFilterChanged, rangeLabel, filterTitle, open, handleCloseModal, handleOpenModal, handleConfirm } =
    useSliderFilterPopup({
      defaultTitle,
      getValues,
      reset,
      checkBoxControlName,
      control,
      controlName,
      max: maxWeightLimit,
      min: minWeightLimit,
      unit: WEIGHT_UNIT,
      handleChangeCheckBox: handleChangeWeightCheckBox,
    });

  return (
    <>
      <FilterListItem filterValue={rangeLabel} label={filterTitle} onClick={handleOpenModal} />
      <CommonFiltersDrawer
        disableConfirmButton={!isFilterChanged}
        isOpen={open}
        title={defaultTitle}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
      >
        <SliderInner
          control={control}
          controlName={controlName}
          defaultTitle={defaultTitle}
          isRange={isWeightRange}
          max={maxWeightLimit}
          min={minWeightLimit}
          step={weightStep}
          unit={WEIGHT_UNIT}
          handleChangeSlider={handleChangeWeightSlider}
          handleChangeCheckBox={handleChangeWeightCheckBox}
          checkBoxControlName={checkBoxControlName}
          checkboxLabel={INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT_LABEL}
        />
      </CommonFiltersDrawer>
    </>
  );
};
