import { FC } from 'react';

import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';

import { DashboardMainTabs, DashboardTabs } from 'app/api/quotes';
import { styles } from './styles';

const dateFormat = 'dd.MM.yyyy';

interface QuoteDatesProps {
  createdAt: string | null;
  estimatedDeliveryDate: string | null;
  deliveredAt: string | null;
  tab: DashboardTabs | undefined;
  orderNumber?: string;
}

export const QuoteDates: FC<QuoteDatesProps> = ({
  createdAt,
  estimatedDeliveryDate,
  deliveredAt,
  tab,
  orderNumber,
}) => {
  const isQuoteRequest = tab === DashboardMainTabs.QUOTE_REQUESTS;
  const isPastOrder = tab === DashboardMainTabs.PAST_ORDERS;

  const createdDate = createdAt ? format(new Date(createdAt), dateFormat) : '';
  const deliveredDate = deliveredAt ? format(new Date(deliveredAt), dateFormat) : '';
  const estimatedDeliveredDate = estimatedDeliveryDate
    ? format(new Date(estimatedDeliveryDate), dateFormat)
    : 'Pending';

  return (
    <Box sx={{ ...styles.datesWrap }}>
      {orderNumber && <Typography sx={{ ...styles.order }}>Order Number: #{orderNumber}</Typography>}
      {createdDate && (
        <Typography sx={{ ...styles.date }}>
          Date {isQuoteRequest && 'Quote '}Created: {createdDate}
        </Typography>
      )}
      {!isQuoteRequest && (
        <Typography sx={{ ...styles.date }}>
          {deliveredDate && isPastOrder ? (
            <>Date Delivered: {deliveredDate}</>
          ) : (
            <>Estimated Delivery Date: {estimatedDeliveredDate}</>
          )}
        </Typography>
      )}
    </Box>
  );
};
