import { useCallback, useMemo } from 'react';

import { useMutation } from 'react-query';

import { addQuoteProduct, deleteQuoteProduct } from 'app/api/quotes/service';
import { UnitsType } from 'app/api/quotes';
import { asyncGenerator } from 'utils/asyncGenerator';
import { QuoteTableFormData } from '../types';

interface ProductWithUpdatedAmount {
  id: number;
  quoteProductId: number;
  updatedAmount?: number;
  amountType?: UnitsType;
}

interface UseChangeProductAmountHandlersProps {
  quoteId: number;
  quotes: QuoteTableFormData['quotes'];
  updateWaitAnswerStatus: () => void;
}

export const useChangeProductAmountHandlers = ({
  quoteId,
  quotes,
  updateWaitAnswerStatus,
}: UseChangeProductAmountHandlersProps) => {
  const productsWithUpdatedAmount = useMemo(() => {
    return quotes
      ?.filter((quote) => !!quote.amountChanged && !!quote.updatedAmount)
      .map(({ id, product, updatedAmount, updatedAmountType }) => ({
        quoteProductId: id,
        id: product.id,
        updatedAmount,
        amountType: updatedAmountType,
      }));
  }, [quotes]);

  const {
    mutate: removeProducts,
    isLoading: isRemoveProductsLoading,
    isSuccess: isRemoveProductsSuccess,
  } = useMutation(
    (data: ProductWithUpdatedAmount[]) => {
      return Promise.all(
        data.map(({ quoteProductId }: ProductWithUpdatedAmount) => deleteQuoteProduct(quoteProductId)),
      );
    },
    {
      onSuccess: () => {
        updateWaitAnswerStatus();
      },
    },
  );

  const {
    mutate: addProductsToQuote,
    isLoading: isAddNewProductsLoading,
    isSuccess: isAddNewProductsSuccess,
  } = useMutation(
    (products: ProductWithUpdatedAmount[]) => {
      const addAllProducts = async () => {
        // eslint-disable-next-line no-restricted-syntax
        for await (const num of asyncGenerator(products.length)) {
          const product = products[num];

          if (product.updatedAmount && product.amountType)
            await addQuoteProduct({
              quote: Number(quoteId),
              amount: product.updatedAmount,
              amountType: product.amountType,
              product: product.id,
            });
        }
      };

      return addAllProducts();
    },
    {
      onSuccess: () => {
        updateWaitAnswerStatus();
      },
    },
  );

  const editProductAmount = useCallback(() => {
    removeProducts(productsWithUpdatedAmount);
    addProductsToQuote(productsWithUpdatedAmount);
  }, [addProductsToQuote, productsWithUpdatedAmount, removeProducts]);

  return {
    productsWithUpdatedAmountLength: productsWithUpdatedAmount.length,
    isEditAmountSuccess: isRemoveProductsSuccess || isAddNewProductsSuccess,
    isEditAmountLoading: isRemoveProductsLoading || isAddNewProductsLoading,
    editProductAmount,
  };
};
