import { FC, MouseEvent } from 'react';

import { Box, IconButton, Menu, MenuItem } from '@mui/material';

import { ReactComponent as MoreIcon } from '../../../../assets/icons/more.svg';
import { styles } from './styles';

export interface SellerActionsMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  isRemoved: boolean;
  isUnavailable: boolean;
  disabled: boolean;
  handleClick: (event: MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  handleMarkNotAvailable: () => void;
  handleRemove: () => void;
}

export const SellerActionsMenu: FC<SellerActionsMenuProps> = ({
  open,
  anchorEl,
  isRemoved,
  isUnavailable,
  disabled,
  handleClick,
  handleClose,
  handleMarkNotAvailable,
  handleRemove,
}: SellerActionsMenuProps) => {
  return (
    <Box sx={styles.wrap}>
      <IconButton
        disabled={disabled}
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ ...styles.moreButton }}
        data-testid="more-button"
      >
        <MoreIcon />
      </IconButton>
      <Menu
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        data-testid="seller-actions-menu"
      >
        {!isUnavailable && <MenuItem onClick={handleMarkNotAvailable}>Quantity not available</MenuItem>}
        {!isRemoved && (
          <MenuItem sx={{ ...styles.removeItem }} onClick={handleRemove}>
            Remove product
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
