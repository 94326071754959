import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  title: {
    marginBottom: '12px',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '36px',
    color: customTheme.custom.black.B100,
  },
  description: {
    marginBottom: '24px',
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,
  },
  buttonsContainer: {
    display: 'grid',
    gap: '12px',
    gridTemplateColumns: 'repeat(2, 1fr)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridTemplateColumns: 'repeat(2,  minmax(auto, 1fr))',
    },
  },
  button: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      whiteSpace: 'nowrap',
      minWidth: 'unset',
      padding: '6px 10px',
    },
  },
};
