import { FC } from 'react';

import { Box } from '@mui/material';

import { OrderDocumentStatus } from 'app/api/orders/types';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as RejectIcon } from 'assets/icons/searchClose.svg';
import { LoadingButton } from 'components';
import { useModal } from 'hooks';
import { AddRejectReasonPopup } from '../AddRejectReasonPopup';
import { useViewFileActions } from './useViewFileActions';
import { styles } from './styles';

export interface ViewFileActionsProps {
  status: OrderDocumentStatus;
  id: number;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  kind: string;
  fileName: string;
  isCompleted: boolean;
  setDocumentAsUpdated: () => void;
}

export const ViewFileActions: FC<ViewFileActionsProps> = ({
  status,
  id,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  kind,
  fileName,
  isCompleted,
  setDocumentAsUpdated,
}) => {
  const isAccepted = status === OrderDocumentStatus.ACCEPTED;

  const { open, handleOpenModal, handleCloseModal } = useModal();

  const { isAcceptDocumentLoading, isUndoDocumentLoading, onAcceptClick, onUndoClick } = useViewFileActions({
    id,
    folderId,
    orderId,
    quoteId,
    isSampleRequest,
    kind,
    fileName,
    setDocumentAsUpdated,
  });

  if (status === OrderDocumentStatus.NEW) {
    return (
      <Box sx={{ ...styles.buttonsWrap }}>
        <LoadingButton
          loading={isAcceptDocumentLoading}
          onClick={onAcceptClick}
          color="inherit"
          sx={{ ...styles.acceptButton }}
        >
          Accept
        </LoadingButton>
        <LoadingButton
          loading={false}
          disabled={isAcceptDocumentLoading}
          color="inherit"
          sx={{ ...styles.rejectButton }}
          onClick={handleOpenModal}
        >
          Reject
        </LoadingButton>

        {open && (
          <AddRejectReasonPopup
            handleClose={handleCloseModal}
            isOpen={open}
            id={id}
            kind={kind}
            fileName={fileName}
            folderId={folderId}
            orderId={orderId}
            quoteId={quoteId}
            isSampleRequest={isSampleRequest}
            setDocumentAsUpdated={setDocumentAsUpdated}
          />
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ ...styles.actionWrap }} data-testid="buyer-actions">
      <Box sx={{ ...styles.statusWrap(isAccepted) }}>
        {isAccepted ? (
          <>
            <CheckIcon />
            Approved
          </>
        ) : (
          <>
            <RejectIcon />
            Rejected
          </>
        )}
      </Box>

      {!isCompleted && (
        <LoadingButton
          loading={isUndoDocumentLoading}
          onClick={onUndoClick}
          disableRipple
          variant="text"
          color="inherit"
          sx={{ ...styles.undoButton }}
        >
          Undo
        </LoadingButton>
      )}
    </Box>
  );
};
