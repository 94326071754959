import { FC } from 'react';

import { IconButtonProps, IconButton } from '@mui/material';

import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { styles } from './styles';

export const EditButton: FC<IconButtonProps> = ({ sx, ...props }) => (
  <IconButton sx={{ ...styles.button, ...(sx as IconButtonProps) }} {...props}>
    <EditIcon />
  </IconButton>
);
