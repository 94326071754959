import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  tableContainer: {
    marginBottom: '10px',
  },
  cell: {
    padding: '8px 16px',
    '&:first-of-type': {
      padding: '8px 24px 8px 32px',
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
    ':last-of-type': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  labelCell: {
    fontSize: '14px',
  },
  crop: {
    fontWeight: '500',
  },
  table: {
    tableLayout: 'fixed',
    borderRadius: '25px',
    borderSpacing: 0,
    borderCollapse: 'separate',
    marginBottom: '80px',
  },
  tableHead: {
    borderRadius: '25px 25px 0px 0px',
  },
  tableHeadCell: {
    padding: '16px 16px 20px',
    backgroundColor: customTheme.palette.primary.P5,
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    '&:first-of-type': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
      // borderRadius: '25px 0px 0px 0px',
      padding: '16px 24px 20px 32px',
    },
    ':last-of-type': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      // borderRadius: '0px 25px 0px 0px',
    },
  },
  headerCellName: {
    fontWeight: '600',
    display: 'flex',
    flexDirection: 'column',
  },
  headerCellSubText: {
    fontWeight: '400',
    fontSize: '10px',
    lineHeight: '20px',
    marginTop: '-6px',
    marginBottom: '-14px',
  },
  headerRow: {
    padding: '0px 24px',
  },
  tableRow: {
    backgroundColor: 'white',
    borderRigh: '1px solid rgba(224, 224, 224, 1)',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  inStock: (inStock: boolean) => ({
    boxSizing: 'border-box',
    width: '100%',
    height: '32px',
    maxWidth: '90px',
    borderRadius: '8px',
    '&& .MuiTouchRipple-rippleVisible': {
      animationDuration: '200ms',
    },
    ...(!inStock && {
      border: '2px solid #C4C4C4',
      color: '#CD9595',
      ':hover': {
        border: '2px solid #C4C4C4',
        color: '#CD9595',
      },
    }),
    ...(inStock && {
      backgroundColor: '#6ED3AD',
      border: '2px solid #6ED3AD',
      color: 'white',
      ':hover': {
        backgroundColor: 'rgba(110, 211, 173, 0.8)',
        border: '2px solid rgba(110, 211, 173, 0.8)',
        color: 'white',
      },
    }),
  }),
  collapse: (isOpen: boolean) => ({
    padding: 0,
    ...(!isOpen && {
      height: '1px',
      border: 'hidden',
    }),
  }),
  quantityAvailable: {
    flex: 1,
    height: '32px',
    padding: '0px',
    ':hover': {
      backgroundColor: 'inherit',
    },
  },
  quantityAdornment: {
    marginLeft: '8px',
    marginRight: '8px',
  },
  quantityAvailableInput: {
    borderRadius: '25px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    textAlign: 'right',
    color: 'black',
    height: '100%',
    padding: '4px 8px',
    '::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },
  quantityCell: {
    display: 'flex',
    alignItems: 'center',
  },
  units: {
    padding: '4px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  unitsTooltip: {
    marginBottom: '0px !important',
  },
  unitsLabel: {
    userSelect: 'none',
    color: customTheme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500',
    minWidth: '18px',
    textAlign: 'center',
  },
  unitsButton: {
    maxHeight: '32px',
    maxWidth: '60px',
    minWidth: '24px',
    textAlign: 'left',
    backgroundColor: 'inherit',
    padding: '0px',
    borderRadius: '8px',
  },
};
