import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  wrap: {
    position: 'sticky',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 48px)',
    height: '90px',
    padding: '0 24px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  },
  menuWrap: {
    '& .MuiPaper-root': {
      borderRadius: 'unset',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    },
  },
  menuButton: {
    minWidth: '80px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '28px',
      padding: '4px 12px',
      fontSize: '12px',
      maxWidth: '120px',

      span: {
        lineHeight: '14px',
        maxHeight: '28px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  menuButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '3px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '14px',
      width: '14px',
    },
  },
  iconButtonWrap: {
    minWidth: '80px',
    display: 'grid',
    justifyItems: 'end',

    '.MuiButtonBase-root': {
      padding: '4px',
    },
  },
};
