import { FC } from 'react';

import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { PageLoader } from 'components';
import { useDeviceType } from 'hooks';
import { Footer } from '../Footer';
import { Header } from '../Header';
import { MobilePageLayout } from '../MobilePageLayout';
import { SideCategoriesPermanentBar } from '../SideCategoriesPermanentBar';
import { TopCategoriesBar } from '../TopCategoriesBar';
import { usePageLayout } from './hooks';
import { styles } from './styles';

export const Pagelayout: FC = () => {
  const { isMobileSize } = useDeviceType();

  const {
    isLoading,
    isScrollable,
    isSubHeader,
    isSubHeaderVisible,
    isContentWithMenu,
    onScroll,
    handleScrollState,
    cropFamiliesData,
    containerRef,
  } = usePageLayout();

  if (isMobileSize) {
    return <MobilePageLayout isLoading={isLoading} />;
  }

  return (
    <Box ref={containerRef} sx={{ ...styles.wrap(isScrollable) }} onScroll={onScroll}>
      {isLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header isShadow={!isSubHeaderVisible} />

          {isSubHeader && (
            <TopCategoriesBar
              data={cropFamiliesData}
              visible={isSubHeaderVisible}
              handleScrollState={handleScrollState}
            />
          )}

          <Box sx={{ ...styles.contentWrap(isSubHeader, isContentWithMenu) }}>
            {isContentWithMenu && (
              <SideCategoriesPermanentBar
                scrollTop={containerRef.current?.scrollTop}
                data={cropFamiliesData}
                handleScrollState={handleScrollState}
              />
            )}

            <Box sx={{ ...styles.content }}>
              <Outlet />
            </Box>
          </Box>

          <Footer />
        </>
      )}
    </Box>
  );
};
