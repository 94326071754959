import { FC } from 'react';

import { Divider, Typography } from '@mui/material';

import { styles } from './styles';

export const PaymentInfoHeader: FC = () => {
  return (
    <>
      <Typography sx={{ ...styles.title }} component="h3">
        Congrats! All forms complete.
      </Typography>
      <Typography sx={{ ...styles.subtitle }}>Let’s get this shipment moving!</Typography>
      <Typography sx={{ ...styles.description(false) }}>
        Sproutzo representative will be in touch shortly to coordinate further shipping details.
      </Typography>
      <Divider sx={{ ...styles.didvider }} />
    </>
  );
};
