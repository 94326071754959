import { SBChatMode } from 'app/api/SBChat';
import { quoteDetailsChatStyles } from './quoteDetailsChatStyles';
import { orderDetailsChatStyles } from './orderDetailsChatStyles';
import { companyChatModalStyles } from './companyChatModalStyles';
import { messagesPageChatStyles } from './messagesPageChatStyles';

export const getConversationStyles = (mode: SBChatMode) => {
  switch (mode) {
    case SBChatMode.QUOTE_DETAILS:
      return quoteDetailsChatStyles;

    case SBChatMode.ORDER_DETAILS:
      return orderDetailsChatStyles;

    case SBChatMode.COMPANY_MODAL:
      return companyChatModalStyles;

    case SBChatMode.MESSAGES_PAGE:
      return messagesPageChatStyles;

    default:
      return quoteDetailsChatStyles;
  }
};
