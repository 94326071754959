import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  createButton: {
    minWidth: '140px',
    color: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'unset',
      minWidth: 'fit-content',
      padding: '6px',
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: 500,
      borderRadius: '12px',
    },
  },
};
