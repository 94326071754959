import { colorFilterAll, continentFilterAll, resistancesFilterAll, producttFilterAllValue } from '../constants';
import { SortTypes } from '../types';

type ParamValueType = string | boolean | undefined;

const getGeneticType = (geneticType: string | undefined) => geneticType !== producttFilterAllValue && geneticType;

const getColorValues = (color: ParamValueType[] | undefined) =>
  color?.[0] !== colorFilterAll.value && color?.filter((val) => val).join(',');

const getContinentValues = (continent: ParamValueType[] | undefined) =>
  continent?.[0] !== continentFilterAll.value && continent?.filter((val) => val).join(',');

const getSort = (sort: string | undefined) => sort !== SortTypes.RECENT && sort;

const getResistancesValues = (resistances: ParamValueType[] | undefined) =>
  resistances?.[0] !== resistancesFilterAll.value && resistances?.filter((val) => val).join(',');

const getMinRangeValue = (range: (number | undefined)[] | undefined, minLimit: number) =>
  !!range?.[0] && range?.[0] !== minLimit ? range[0].toString() : null;

const getMaxRangeValue = (range: (number | undefined)[] | undefined, maxLimit: number) =>
  range?.[1] && range?.[1] !== maxLimit ? range[1].toString() : null;

const getApplyFilterValue = (isIncludeWithIncompleteData: boolean | undefined) =>
  isIncludeWithIncompleteData ? null : 'true';

const getOrganic = (organic: string | undefined) => (organic !== producttFilterAllValue ? 'true' : null);

interface FiltersValues {
  geneticType?: string;
  color?: ParamValueType[];
  continent?: ParamValueType[];
  resistances?: ParamValueType[];
  includeProductsWithIncompleteResistances?: boolean;
  sort?: string;
  weight?: (number | undefined)[];
  includeProductsWithIncompleteWeight?: boolean;
  maturity?: (number | undefined)[];
  includeProductsWithIncompleteMaturity?: boolean;
  organic?: string;
}

export const getUrlFromFilters = (
  value: FiltersValues,
  minWeightLimit: number,
  maxWeightLimit: number,
  minMaturityLimit: number,
  maxMaturityLimit: number,
) => {
  const geneticType = getGeneticType(value.geneticType);
  const colorValues = getColorValues(value.color);
  const continentValues = getContinentValues(value.continent);
  const sort = getSort(value.sort);
  const organic = getOrganic(value.organic);

  const resistancesValues = getResistancesValues(value.resistances);
  const applyResistancesFilter = getApplyFilterValue(value?.includeProductsWithIncompleteResistances);

  const minWeight = getMinRangeValue(value.weight, minWeightLimit);
  const maxWeight = getMaxRangeValue(value.weight, maxWeightLimit);
  const applyWeightFilter = getApplyFilterValue(value?.includeProductsWithIncompleteWeight);

  const minMaturity = getMinRangeValue(value.maturity, minMaturityLimit);
  const maxMaturity = getMaxRangeValue(value.maturity, maxMaturityLimit);
  const applyMaturityFilter = getApplyFilterValue(value?.includeProductsWithIncompleteMaturity);

  return {
    ...(geneticType && { geneticType }),
    ...(colorValues && { color: colorValues }),
    ...(continentValues && { continent: continentValues }),
    ...(organic && { organic }),
    ...(resistancesValues && { resistances: resistancesValues }),
    ...(applyResistancesFilter && { isResistances: applyResistancesFilter }),
    ...(sort && { sort }),
    ...(minWeight && { minWeight }),
    ...(maxWeight && { maxWeight }),
    ...(applyWeightFilter && { isWeight: applyWeightFilter }),
    ...(minMaturity && { minMaturity }),
    ...(maxMaturity && { maxMaturity }),
    ...(applyMaturityFilter && { isMaturity: applyMaturityFilter }),
  };
};
