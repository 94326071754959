import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as EmailIcon } from '../../../../assets/icons/email.svg';
import { CommonButton } from '../../../../components/CommonButton';
import { styles } from './styles';

interface InfoMessageProps {
  onBackButtonClick: () => void;
}

export const InfoMessage: FC<InfoMessageProps> = ({ onBackButtonClick }) => {
  return (
    <>
      <Box sx={{ ...styles.content }}>
        <EmailIcon />
        <Typography sx={{ ...styles.title }}>Thank you!</Typography>
        <Typography sx={{ ...styles.description }}>
          We&apos;ve sent you an email with instructions on how to reset your password. Please check your email.
        </Typography>
      </Box>
      <CommonButton sx={{ ...styles.button }} onClick={onBackButtonClick}>
        Back to Log In
      </CommonButton>
    </>
  );
};
