import { FilterParams, OrganicType } from 'app/api/products';
import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import { producttFilterAllValue } from '../constants';
import { SortTypes } from '../types';

const getGeneticType = (parsedFilters: FilterParams) =>
  parsedFilters.geneticType && parsedFilters.geneticType !== producttFilterAllValue && parsedFilters.geneticType;

const getColor = (parsedFilters: FilterParams, colorValues: CheckboxFilterItem[]) =>
  parsedFilters.color && colorValues.map(({ value }) => (parsedFilters.color?.includes(value) ? value : false));

const getContinent = (parsedFilters: FilterParams, continentValues: CheckboxFilterItem[]) =>
  parsedFilters.continent &&
  continentValues.map(({ value }) => (parsedFilters.continent?.includes(value) ? value : false));

const getResistances = (parsedFilters: FilterParams, resistancesValues: CheckboxFilterItem[]) =>
  parsedFilters.resistances &&
  resistancesValues.map(({ value }) => (parsedFilters.resistances?.includes(value) ? value : false));

const getSort = (parsedFilters: FilterParams) =>
  parsedFilters.sort && parsedFilters.sort !== SortTypes.RECENT && parsedFilters.sort;

const getOrganic = (parsedFilters: FilterParams) =>
  parsedFilters.organic ? OrganicType.organicSeed : producttFilterAllValue;

interface ParseFiltersFromUrlParams {
  parsedFilters: FilterParams | undefined;
  colorValues: CheckboxFilterItem[] | undefined;
  continentValues: CheckboxFilterItem[] | undefined;
  resistancesValues: CheckboxFilterItem[] | undefined;
  minWeightLimit: number;
  maxWeightLimit: number;
  minMaturityLimit: number;
  maxMaturityLimit: number;
}

export const parseFiltersFromUrl = ({
  parsedFilters,
  colorValues = [],
  continentValues = [],
  resistancesValues = [],
  minWeightLimit,
  maxWeightLimit,
  minMaturityLimit,
  maxMaturityLimit,
}: ParseFiltersFromUrlParams) => {
  if (!parsedFilters) {
    return {};
  }

  const geneticType = getGeneticType(parsedFilters);

  const color = getColor(parsedFilters, colorValues);

  const continent = getContinent(parsedFilters, continentValues);

  const resistances = getResistances(parsedFilters, resistancesValues);
  const isApplyResistancesFilter = parsedFilters.applyResistancesFilter === 'true';

  const sort = getSort(parsedFilters);

  const minWeight = parsedFilters.minWeight ? parsedFilters.minWeight : minWeightLimit;
  const maxWeight = parsedFilters.maxWeight ? parsedFilters.maxWeight : maxWeightLimit;
  const isApplyWeightFilter = parsedFilters.applyWeightFilter === 'true';

  const minMaturity = parsedFilters.minMaturity ? parsedFilters.minMaturity : minMaturityLimit;
  const maxMaturity = parsedFilters.maxMaturity ? parsedFilters.maxMaturity : maxMaturityLimit;
  const isApplyMaturityFilter = parsedFilters.applyMaturityFilter === 'true';

  const organic = getOrganic(parsedFilters);

  return {
    ...(geneticType && { geneticType }),
    ...(color && { color }),
    ...(continent && { continent }),
    ...(organic && { organic }),
    ...(resistances && { resistances }),
    ...{ includeProductsWithIncompleteResistances: !isApplyResistancesFilter },
    ...(sort && { sort }),
    ...{ weight: [Number(minWeight), Number(maxWeight)] },
    ...{ includeProductsWithIncompleteWeight: !isApplyWeightFilter },
    ...{ maturity: [Number(minMaturity), Number(maxMaturity)] },
    ...{ includeProductsWithIncompleteMaturity: !isApplyMaturityFilter },
  };
};
