import { MouseEvent, Dispatch, SetStateAction, useCallback, useState } from 'react';

interface UseTopCategoriesBarProps {
  handleScrollState: Dispatch<SetStateAction<boolean>>;
}

export const useTopCategoriesBar = ({ handleScrollState }: UseTopCategoriesBarProps) => {
  const [isAllItemsOpened, setIsAllItemsOpened] = useState(false);

  const handleActionClick = useCallback(() => {
    setIsAllItemsOpened((state) => !state);
    handleScrollState((state) => !state);
  }, [handleScrollState]);

  const handleCloseMenu = useCallback(() => {
    if (isAllItemsOpened) {
      setIsAllItemsOpened(false);
      handleScrollState(true);
    }
  }, [handleScrollState, isAllItemsOpened]);

  const handleMouseMove = (e: MouseEvent) => {
    const el = e?.currentTarget;

    try {
      const { top, left, right } = el.getBoundingClientRect();
      const { clientY, clientX } = e;

      const isRightBorder = right - clientX <= 4;
      const isLeftBorder = clientX - left <= 4;
      const isTopBorder = clientY - top <= 4;

      if (isRightBorder || isLeftBorder || isTopBorder) {
        handleCloseMenu();
      }
    } catch (e) {
      /* empty */
    }
  };

  return { isAllItemsOpened, handleActionClick, handleMouseMove, handleCloseMenu };
};
