import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import farmersImg from 'assets/images/agriculture_farmers.png';
import larryImg from 'assets/images/larry.png';
import trustImg from 'assets/images/trust.png';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';

import { AboutPageBanner } from './components';
import { styles } from './styles';

const featureMessages = {
  title: 'Built for agriculture professionals',
  items: [
    'Chat & negotiate directly with suppliers',
    'View technical details',
    'Request bulk shipments',
    'Trial samples before purchase',
    'Financing Available',
    'Tools for phytosanitary and trade documentation',
  ],
};
export const FeaturesSection: FC = (): JSX.Element => {
  return (
    <Box sx={styles.featuresContainer}>
      <Box sx={styles.featuresInnerContainer}>
        <Box sx={styles.featuresLeft}>
          <Box sx={styles.featuresText}>
            <Typography component="h3" sx={styles.featuresTitle}>
              {featureMessages.title}
            </Typography>
            <Box sx={styles.features}>
              {featureMessages.items.map((item) => (
                <Box key={item} sx={styles.feature}>
                  <CheckIcon />
                  {item}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <Box component="img" src={farmersImg} sx={styles.featuresImg} />
      </Box>
    </Box>
  );
};

const networkMessages = {
  title: 'The Sproutzo Network',
};
const metrics = [
  { metric: '3,500+', label: 'Available Products' },
  { metric: '6', label: 'Continents' },
  { metric: '50+', label: 'Countries ' },
  { pre: 'Up to', metric: '75%', label: 'saved per purchase' },
  { metric: '50%', label: 'of deals financed' },
  { metric: '$0', label: 'cost to join' },
];
export const NetworkSection: FC = (): JSX.Element => {
  return (
    <Box sx={styles.networkContainer}>
      <Box>
        <Typography component="h3" sx={styles.networkTitle}>
          {networkMessages.title}
        </Typography>
        <Box sx={styles.networkCards}>
          {metrics.map((m) => (
            <Box key={m.label} sx={styles.networkCard}>
              {m.pre ? <Typography sx={styles.networkCardPre}>{m.pre}</Typography> : null}
              <Typography sx={styles.networkCardMetric}>{m.metric}</Typography>
              <Typography sx={styles.networkCardLabel}>{m.label}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const trustMessages = {
  title: 'Built for Trust',
  description:
    'Sproutzo offers some of the largest agriculture brands from around the world. ' +
    'We’re committed to vetting all supplier companies prior to onboarding and maintain ' +
    'a zero-tolerance policy for genetic or commercial fraud. At Sproutzo, buyer success ' +
    'and satisfaction is always our top priority.',
};
export const TrustSection: FC = (): JSX.Element => {
  return (
    <Box sx={styles.trustContainer}>
      <Box sx={styles.trustInnerContainer}>
        <Box component="img" src={trustImg} sx={styles.trustImg} />
        <Box sx={styles.trustText}>
          <Typography component="h3" sx={styles.trustTitle}>
            {trustMessages.title}
          </Typography>
          <Typography component="p" sx={styles.trustDescription}>
            {trustMessages.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const testimonialMessages = {
  title: 'Testimonials',
  subtitle: 'Hear how Sproutzo is revolutionizing the industry for businesses worldwide',
  text: {
    quote: '"',
    bold: 'Sproutzo is the best way to find new varieties and trading partners in 2024.',
    body: 'Between the catalogs, easy quote request system, and documentation, my job has never been easier.',
  },
};
export const TestimonialSection = () => {
  return (
    <Box sx={styles.testimonialContainer}>
      <Box sx={styles.testimonialContainerInner}>
        <Typography component="h3" sx={styles.testimonialTitle}>
          {testimonialMessages.title}
        </Typography>
        <Typography component="h4" sx={styles.testimonialSubtitle}>
          {testimonialMessages.subtitle}
        </Typography>
        <Box sx={styles.testimonialQuote}>
          <Box component="img" src={larryImg} sx={styles.testimonialImg} />
          <Box sx={styles.testimonialQuoteText}>
            <Typography component="p" sx={styles.testimonialTextBody}>
              {testimonialMessages.text.quote}
              <Typography component="span" sx={styles.testimonialTextBold}>
                {testimonialMessages.text.bold}{' '}
              </Typography>
              {testimonialMessages.text.body}
              {testimonialMessages.text.quote}
            </Typography>
            <Box sx={styles.testimonialAuthorSection}>
              <Typography sx={styles.testimonialAuthor}>
                <Typography component="span" sx={styles.testimonialAuthorName}>
                  – Larry Kampa{' '}
                </Typography>
                Seed Purchaser
              </Typography>
              <Typography sx={styles.testimonialAuthorLabel}>Owner – LKR Wholesale Vegetable Seeds</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const AboutUsPage: FC = (): JSX.Element => {
  return (
    <Box sx={styles.pageWrap}>
      <AboutPageBanner />
      <FeaturesSection />
      <NetworkSection />
      <TrustSection />
      <TestimonialSection />
    </Box>
  );
};
