import { useQuery } from 'react-query';

import { getCustomerProfile } from '../../../app/api/user/service';

interface DeliveryAddressQueryI {
  id?: number;
}

export const useDeliveryAddressQuery = ({ id }: DeliveryAddressQueryI) => {
  const { isLoading, isFetching, data } = useQuery(['customer-profile', id], () => getCustomerProfile(Number(id)), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
  });

  return {
    isLoading: isLoading || isFetching,
    deliveryAddress: data?.deliveryAddress,
  };
};
