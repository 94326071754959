import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageWrap: (isBuyer: boolean) => ({
    margin: '64px auto 0',
    maxWidth: customTheme.breakpoints.values.laptop,
    boxSizing: 'border-box',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0px auto 0',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      minHeight: `calc(100dvh - ${isBuyer ? '116px' : '64px'})`,
    },
  }),
  loaderWrap: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  legendWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
    },
  },
  footerWrap: (isMarginBottom: boolean) => ({
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '40px',
      flexGrow: 1,
      display: 'flex',
      alignItems: 'end',
      justifyContent: 'stretch',
      width: 'calc(100% + 28px)',
      boxSizing: 'border-box',
      marginLeft: '-14px',
      marginRight: '-14px',
      ...(isMarginBottom && {
        marginBottom: '86px',
      }),

      '& > div': {
        width: '100%',
        minWidth: '100%',
        boxSizing: 'border-box',
      },
    },
  }),
  // CompanySection
  companyContainer: {
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
  },
  companyLeft: (width: number) => ({
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    width,
  }),
  companySection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  companyStats: {
    minWidth: '260px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: 'inherit',
      flex: '1 0 50%',
      height: '96px',
      maxHeight: '96px',
    },
  },
  imgContainer: {
    display: 'flex',
  },
  companyLink: {
    height: '112px',
    maxHeight: '112px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '96px',
      maxHeight: '96px',
    },
  },
  companyNameLink: {
    color: 'inherit',
  },
  companyImage: {
    height: '80px',
    maxHeight: '80px',
    width: '80px',
    maxWidth: '80px',
    objectFit: 'contain',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '64px',
      maxHeight: '64px',
      width: '64px',
      maxWidth: '64px',
    },

    padding: '16px',
    borderRadius: '8px',
    boxShadow: customTheme.shadow.boxShadow.standard,
  },
  blankCompanyImage: {
    filter: 'blur(4px)',
  },
  blankCompanyImageContainer: {
    height: '112px',
    width: '112px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    backgroundColor: customTheme.custom.gray.G200,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '96px',
      width: '96px',
    },

    '& svg': {
      height: '40px',
      width: '40px',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      fill: customTheme.custom.white,
    },
  },
  companyStat: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 'inherit',
    },
  },
  companyValue: {
    fontSize: '16px',
    fontWeight: 600,
    color: customTheme.custom.gray.G600,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      fontWeight: 600,
    },
  },
  location: {},
  companyFeatures: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '& svg': {
      height: '24px',
      width: '24px',
    },
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginLeft: 'inherit',
    },
  },
  companyFeatureIcon: {
    height: '24px',
    width: '24px',
  },
  locked: {
    cursor: 'pointer',
    textDecoration: 'underline',
    ':hover': {
      color: customTheme.custom.darkGrey,
    },
  },
  tooltipWrap: {
    padding: '8px',
    fontSize: '12px',
    backgroundColor: 'rgba(39, 139, 159, 0.9)',
  },
};
