import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  muteWrap: {
    padding: '20px 0',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '12px 0',
    },
  },
};
