import { customTheme } from 'theme/MuiThemeProvider/theme';
import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  filesWrap: (isMultiLines: boolean, isSecondLine: boolean) => ({
    maxWidth: 'calc(100% - 40px)',
    marginTop: '6px',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, minmax(0, 1fr))',
    gap: '0px 8px',

    ...(isMultiLines && {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      gridTemplateRows: isSecondLine ? 'repeat(2, minmax(0, 1fr))' : '1fr',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      gridTemplateRows: isSecondLine ? 'repeat(2, minmax(0, 1fr))' : '1fr',
    },
  }),
  fileBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '8px',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: customTheme.custom.black.B5,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '4px',
      borderRadius: '6px',
      gap: '2px',
    },
  },
  fileName: {
    flex: '1',
    width: '100%',
    fontSize: '10px',
    lineHeight: '14px',
    color: customTheme.custom.black.B100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  cleanIcon: {
    display: 'flex',
    width: '16px',
    height: '16px',
    cursor: 'pointer',
  },
};
