import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  inputWrap: {
    height: '40px',
    width: '320px',
    marginBottom: 0,
    borderRadius: '25px',
    fontSize: '14px',
    color: customTheme.custom.black.B100,
    background: customTheme.custom.white,

    ':hover': {
      '.MuiButtonBase-root.MuiButton-root.MuiButton-contained': {
        background: customTheme.custom.white,
      },
    },
  },
  input: {
    padding: '4px 2px 4px 16px',
    borderRadius: '25px',

    '&::placeholder': {
      color: customTheme.custom.black.B50,
      opacity: 1,
    },
  },
  endAdornment: {
    margin: '0',
    cursor: 'default',

    '&.MuiInputAdornment-positionEnd': {
      ':hover': {
        color: customTheme.custom.black.B100,
      },
    },
  },
  endAdornmentInner: {
    marginRight: '-8px',
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  startAdornment: {
    margin: '0',
  },
  searchIcon: {
    display: 'flex',
  },
  closeButton: {
    padding: '3px',
    color: customTheme.custom.black.B100,
    background: 'transparent',

    ':hover': {
      background: 'transparent',
      color: customTheme.custom.neutralLight,
    },

    svg: {
      width: '20px',
      height: '20px',
    },

    rect: {
      fill: 'transparent',
    },
  },
};
