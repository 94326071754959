import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  title: {
    marginBottom: '16px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '12px',
      fontSize: '14px',
    },
  },
  wrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0 -14px',
    },
  },
};
