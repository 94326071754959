import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  // Quote container
  quoteContainer: (isOpen: boolean) => ({
    position: 'relative',
    width: '350px',
    minWidth: '350px',
    ...(!isOpen && {
      width: '0px',
      minWidth: '0px',
    }),
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    boxShadow: customTheme.shadow.boxShadow.standard,
  }),
  drawer: {
    width: '350px',
    flexShrink: 0,

    '& .MuiDrawer-paper': {
      backgroundColor: customTheme.custom.gray.G10,
      overflow: 'scroll',
      ...scrollMixin,

      position: 'absolute',
      width: '350px',
      padding: 0,
      overflowX: 'hidden',
      marginRight: '1px',
    },
  },
  expandIcon: (isOpen: boolean) => ({
    transition: customTheme.transition.default,
    transform: 'rotate(180deg)',
    ...(isOpen && { transform: 'rotate(0deg)' }),
  }),
  quoteContainerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    padding: '16px 8px',
  },
  pastProducts: {
    marginTop: '16px',
  },
  quoteContainerTitle: {
    fontSize: '16px',
    fontWeight: 500,
  },
  quoteContainerLowerTitle: {
    color: customTheme.custom.black.B70,
  },
  quoteContainerBtn: {
    textWrap: 'nowrap',
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '25px',
    backgroundColor: customTheme.custom.organic,
    color: customTheme.custom.white,
    padding: '4px 8px',
    '&:hover': {
      backgroundColor: customTheme.custom.organic,
      color: customTheme.custom.white,
      textDecoration: 'none',
    },
  },
  quoteProductCards: {
    padding: '0px 8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  // Quote product card
  quoteProductCardContainer: (isTransparent: boolean) => ({
    display: 'flex',
    alignItems: 'stretch',
    gap: '8px',
    padding: '8px',
    height: '88px',
    boxSizing: 'border-box',
    boxShadow: customTheme.shadow.boxShadow.standard,
    borderRadius: '8px',
    cursor: 'pointer',
    transition: customTheme.transition.default,
    '&:hover': {
      boxShadow: customTheme.shadow.boxShadow.hover,
      backgroundColor: customTheme.palette.secondary.S20,
    },
    ...(isTransparent && {
      opacity: 0.5,
    }),
  }),
  productImage: {
    width: '72px',
    minWidth: '72px',
    height: '72px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '60px',
      minWidth: '60px',
      height: '60px',
    },
  },
  productDetails: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    overflow: 'hidden',
  },
  productDetailsTop: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  crop: {
    fontSize: '14px',
    fontWeight: 600,
    color: customTheme.palette.primary.main,
    lineHeight: 1.2,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  amount: {
    fontSize: '14px',
    fontWeight: 500,
    color: customTheme.palette.primary.main,
    lineHeight: 1.2,
    whiteSpace: 'nowrap',
  },
  productDetailsName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    margin: '0px',
  },

  productDetailsVariety: {
    fontSize: '16px',
    fontWeight: 500,
    color: customTheme.custom.black.B1000,
    marginRight: '2px',
    lineHeight: 1,
  },
  productDetailsProductName: {
    color: customTheme.palette.info.main,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 1,
  },
  productOffers: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  myLastOffer: {
    fontSize: '12px',
    fontWeight: 500,
    color: customTheme.palette.info.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  otherLastOffer: {
    fontSize: '12px',
    fontWeight: 500,
    color: customTheme.custom.organic,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
