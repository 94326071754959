import { FC } from 'react';

import { Control, useFieldArray } from 'react-hook-form';
import { Box, IconButton, Typography } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/searchClose.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { TextFormField, CommonButton } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { styles } from './styles';

interface AddMoreDocumentsWrapperProps {
  control: Control;
}

export const AddMoreDocumentsWrapper: FC<AddMoreDocumentsWrapperProps> = ({ control }): JSX.Element => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'additionalDocuments',
  });

  return (
    <Box sx={{ ...styles.wrap }} data-testid="add-more-documents-wrapper">
      <Box sx={{ ...styles.columnWrap }}>
        {fields.map((data, index) => (
          <Box key={data.id} sx={{ ...styles.documentInputWrap }}>
            <TextFormField
              wrapStyles={{ ...styles.documentField } as SxPropsTypes}
              control={control}
              name={`additionalDocuments.${index}.value`}
              placeholder="Enter document name"
              maxLength={255}
              withoutHighlights
            />

            <IconButton sx={{ ...styles.closeButton }} onClick={() => remove(index)} data-testid="close-button">
              <CloseIcon />
            </IconButton>
          </Box>
        ))}

        <CommonButton disableRipple variant="text" sx={{ ...styles.addButton }} onClick={() => append({ value: '' })}>
          <PlusIcon />
          <Typography sx={{ ...styles.addButtonText }}>Custom document</Typography>
        </CommonButton>
      </Box>
    </Box>
  );
};
