import { QuoteCardStatuses, QuoteCardStatusesType } from 'app/api/quotes/types';

export const quoteStatusLabel: Record<QuoteCardStatusesType, string> = {
  [QuoteCardStatuses.NEW]: 'New',
  [QuoteCardStatuses.CANCELED]: 'Canceled',
  [QuoteCardStatuses.AWAITING_FREIGHT_QUOTE]: 'Awaiting freight quote',
  [QuoteCardStatuses.DOCUMENTS_IN_PROGRESS]: 'Documents in progress',
  [QuoteCardStatuses.PAYMENT_IN_PROGRESS]: 'Documents complete',
  [QuoteCardStatuses.READY_TO_SHIP]: 'Ready to ship',
  [QuoteCardStatuses.EN_ROUTE]: 'En route',
  [QuoteCardStatuses.DELIVERED]: 'Delivered',
  [QuoteCardStatuses.OPEN]: 'Open',
  [QuoteCardStatuses.NEW_REQUEST]: 'New Request',
  [QuoteCardStatuses.NEW_RESPONSE]: 'New Response',
  [QuoteCardStatuses.WAITING_RESPONSE]: 'Waiting Response',
  [QuoteCardStatuses.ACCEPTED]: 'Accepted',
};
