import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ProductDetail } from 'app/api/products/types';
import { StatsList } from '../StatsList';
import { StatsTotalInfo } from '../StatsTotalInfo';
import { useProductDetailsStats } from './hooks';
import { styles } from './styles';

interface ProductDetailsStatsProps {
  data: ProductDetail;
  isSeller?: boolean;
}

export const ProductDetailsStats: FC<ProductDetailsStatsProps> = ({ data, isSeller }): JSX.Element => {
  const { keyTechStats } = useProductDetailsStats(data);

  return (
    <Box sx={{ ...styles.infoWrap }}>
      <Typography sx={{ ...styles.infoTitle }}>Key Tech Stats</Typography>

      <Box sx={{ ...styles.wrap }} data-testid="stats-data">
        <StatsList keyTechStats={keyTechStats} />
        <StatsTotalInfo data={data} isSeller={isSeller} />
      </Box>
    </Box>
  );
};
