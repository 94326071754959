import { FC, ReactNode } from 'react';

import { Divider, Drawer, SxProps } from '@mui/material';

import { useAuth } from 'hooks';
import { styles } from './styles';

interface CommonChatDrawerProps {
  children: ReactNode;
  isOpen: boolean;
  drawerSx?: SxProps;
  paperSx?: SxProps;
  handleClose: () => void;
}

export const CommonChatDrawer: FC<CommonChatDrawerProps> = ({ children, isOpen, drawerSx, paperSx, handleClose }) => {
  const { isBuyer } = useAuth();
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Drawer
      anchor="left"
      container={container}
      variant="temporary"
      open={isOpen}
      onClose={handleClose}
      sx={{ ...styles.drawer, ...drawerSx } as SxProps}
      ModalProps={{ sx: styles.modal(isBuyer) }}
      PaperProps={{ sx: { ...styles.paper(isBuyer), ...paperSx } as SxProps }}
      disableEnforceFocus
    >
      <Divider />
      {children}
    </Drawer>
  );
};
