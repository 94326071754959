import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: (rowsCount: number) => ({
    display: 'grid',
    gap: '8px 232px',
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: `repeat(${rowsCount}, 1fr)`,
    gridAutoFlow: 'column',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'min-content',
      gridAutoFlow: 'row',
      gap: '16px',
    },
  }),
  dividerTop: {
    marginTop: '24px',
    marginBottom: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: 0,
      marginBottom: '16px',
    },
  },
  dividerBottom: {
    marginTop: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: '16px',
    },
  },
  title: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      marginTop: '24px',
      marginBottom: '4px',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 600,
    },
  },
};
