import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    padding: '16px',
    display: 'grid',
    gap: '18px',

    ':not(:last-of-type)': {
      borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    },
  },
  inner: {
    display: 'grid',
    gap: '10px 18px',
    gridTemplateColumns: '1fr 1fr',

    '& > :nth-child(even)': {
      justifyItems: 'end',
    },
  },
};
