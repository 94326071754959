import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ShipmentInfo } from 'app/api/orders';
import { useDeviceType } from 'hooks';
import { currencyFormat } from 'utils/currencyFormat';
import { packingTypeLabel } from '../../constants';
import { CollapseWrap } from '../CollapseWrap';
import { ViewShipmentButton } from '../ViewShipmentButton';
import { getMainShipmentInfo } from './getMainShipmentInfo';
import { styles } from './styles';

interface ShipmentInfoProps extends ShipmentInfo {
  orderShipmentId: number | string;
  isViewShipmentTrackingButton: boolean;
}

export const ShipmentInfoCard: FC<ShipmentInfoProps> = ({ orderShipmentId, isViewShipmentTrackingButton, ...data }) => {
  const { isMobileSize } = useDeviceType();
  const { shipmentType, numberOfUnits, freightPrice } = data;

  const Wrap = isMobileSize ? CollapseWrap : Box;

  return (
    <Wrap sx={{ ...styles.wrap }} gridRow={5} arrowTopPosition="6px">
      <Box sx={styles.inner}>
        <Typography component="h6" sx={{ ...styles.title }}>
          Shipment information
        </Typography>
        <Typography sx={{ ...styles.subTitle }}>Type: {packingTypeLabel[shipmentType]}</Typography>

        <Box sx={{ ...styles.list }}>
          {numberOfUnits && (
            <Box sx={{ ...styles.item }}>
              <Typography sx={{ ...styles.subTitle }}>Number of units</Typography>
              <Typography sx={{ ...styles.value }}>{numberOfUnits}</Typography>
            </Box>
          )}

          {getMainShipmentInfo(data)}

          {!!freightPrice && (
            <Box sx={{ ...styles.item }}>
              <Typography sx={{ ...styles.subTitle }}>Freight price</Typography>
              <Typography sx={{ ...styles.value }}>{currencyFormat(freightPrice)}</Typography>
            </Box>
          )}
        </Box>
        {isViewShipmentTrackingButton && (
          <Box sx={styles.trackingButtonWrap}>
            <ViewShipmentButton orderShipmentId={orderShipmentId} />
          </Box>
        )}
      </Box>
    </Wrap>
  );
};
