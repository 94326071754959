import { FC } from 'react';

import { NotificationsButton, NotificationsContent, NotificationsPopover } from 'components';
import { useNotificationsHandlers } from 'hooks';

export const Notifications: FC = () => {
  const { isNotificationsOpen, anchorEl, count, handleClose, handleNotificationsClick, handleItemClick } =
    useNotificationsHandlers();

  return (
    <>
      <NotificationsButton count={count} handleClick={handleNotificationsClick} />
      <NotificationsPopover isOpen={isNotificationsOpen} anchorEl={anchorEl} handleClose={handleClose}>
        <NotificationsContent isNotificationsQueryEnabled={isNotificationsOpen} handleItemClick={handleItemClick} />
      </NotificationsPopover>
    </>
  );
};
