import { DashboardMainTabs, DashboardTabs, SampleRequestsTabs } from 'app/api/quotes/types';

export const getCurrentTabStatus = (tab?: DashboardTabs) => {
  const isQuoteRequestsTab = tab === DashboardMainTabs.QUOTE_REQUESTS || tab === SampleRequestsTabs.SAMPLE_REQUESTS;
  const isOpenOrdersTab = tab === DashboardMainTabs.OPEN_ORDERS || tab === SampleRequestsTabs.SAMPLE_SEND_IN_PROGRESS;
  const isEnRouteOrdersTab = tab === DashboardMainTabs.EN_ROUTE || tab === SampleRequestsTabs.SAMPLE_EN_ROUTE;
  const isPastOrdersTab = tab === DashboardMainTabs.PAST_ORDERS || tab === SampleRequestsTabs.PAST;

  return {
    isQuoteRequestsTab,
    isOpenOrdersTab,
    isEnRouteOrdersTab,
    isPastOrdersTab,
  };
};
