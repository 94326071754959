import { FC, SyntheticEvent } from 'react';

import { Control } from 'react-hook-form';
import { Tabs, Tab, Typography, Box, SxProps } from '@mui/material';

import { Counter } from 'app/api/orders/types';
import { SortFilter } from 'components';
import { dashboardMainTabs } from '../../constants';
import { useSampleRequestsTab } from '../../hooks';
import { SortValue } from '../../types';
import { SampleRequestsPageHeader } from '../SampleRequestsPageHeader';
import { TabLabel } from '../TabLabel';
import { styles } from './styles';

interface PageHeaderProps {
  ordersCounter?: Counter;
  activeTabId: number;
  control: Control;
  sortOptions: SortValue[];
  onTabChange: (event: SyntheticEvent, newValue: number) => void;
}

export const PageHeader: FC<PageHeaderProps> = ({ ordersCounter, control, activeTabId, sortOptions, onTabChange }) => {
  const isSampleRequests = activeTabId === 4;
  const { activeTab: activeInnerTab, handleTabChange: handleInnerTabChange } = useSampleRequestsTab({
    enabled: isSampleRequests,
  });

  return (
    <Box sx={styles.wrap}>
      <Typography component="h2" sx={{ ...styles.title }}>
        Dashboard
      </Typography>
      <Box sx={styles.actionsWrap}>
        <Tabs sx={styles.tabsWrap(isSampleRequests)} value={activeTabId} onChange={onTabChange}>
          {dashboardMainTabs.map(({ label, value, counterKey }) => (
            <Tab
              sx={styles.tabWrap as SxProps}
              key={value}
              value={value}
              label={
                <TabLabel
                  isActive={activeTabId === value}
                  count={ordersCounter?.[counterKey as keyof Counter] ?? 0}
                  label={label}
                />
              }
            />
          ))}
        </Tabs>

        {isSampleRequests && (
          <Box sx={styles.innerTabsWrap}>
            <SampleRequestsPageHeader activeTabId={activeInnerTab.value} onTabChange={handleInnerTabChange} />
          </Box>
        )}
        {!!sortOptions.length && <SortFilter data={sortOptions} control={control} controlName="sort" />}
      </Box>
    </Box>
  );
};
