import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ShipmentInfo } from 'app/api/orders';
import { styles } from './styles';

type BoxInfoProps = ShipmentInfo;

export const BoxInfo: FC<BoxInfoProps> = ({ length, width, height, weight }) => {
  return (
    <>
      {!!length && (
        <Box sx={{ ...styles.item }}>
          <Typography sx={{ ...styles.subTitle }}>Length</Typography>
          <Typography sx={{ ...styles.value }}>{length} cm</Typography>
        </Box>
      )}

      {!!width && (
        <Box sx={{ ...styles.item }}>
          <Typography sx={{ ...styles.subTitle }}>Width</Typography>
          <Typography sx={{ ...styles.value }}>{width} cm</Typography>
        </Box>
      )}

      {!!height && (
        <Box sx={{ ...styles.item }}>
          <Typography sx={{ ...styles.subTitle }}>Height</Typography>
          <Typography sx={{ ...styles.value }}>{height} cm</Typography>
        </Box>
      )}

      {!!weight && (
        <Box sx={{ ...styles.item }}>
          <Typography sx={{ ...styles.subTitle }}>Weight</Typography>
          <Typography sx={{ ...styles.value }}>{weight} kg</Typography>
        </Box>
      )}
    </>
  );
};
