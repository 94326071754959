import { FC } from 'react';

import { OceanFreightDataType } from '../../types';
import { InfoItem } from '../InfoItem';
import { SectionItemWrap } from '../SectionItemWrap';
import { SectionTag } from '../SectionTag';

type OceanFreightSectionProps = OceanFreightDataType;

export const OceanFreightSection: FC<OceanFreightSectionProps> = ({
  departurePort,
  receivingPort,
  vesselName,
  containerNumber,
}) => {
  return (
    <SectionItemWrap>
      <SectionTag label="Ocean Freight" color="primary" />
      <InfoItem label="Departure Port" value={departurePort} color="primary" />
      <InfoItem label="Receiving Port" value={receivingPort} color="primary" />
      {vesselName && <InfoItem label="Vessel Name" value={vesselName} color="primary" />}
      {containerNumber && <InfoItem label="Container Number" value={containerNumber} color="primary" />}
    </SectionItemWrap>
  );
};
