import { FC, MouseEvent } from 'react';

import { Box } from '@mui/material';

import { useAppSelector } from 'state/hooks';
import { selectActiveQuotes } from 'state/slices/quotes';
import { DashboardTabs, QuoteRequestStatus } from 'app/api/quotes/types';
import { useDeviceType } from 'hooks';
import { CommonButton } from '../CommonButton';
import { QuoteCardProps } from './index';
import { QuoteDates } from './QuoteDates';
import { QuoteCompany } from './QuoteCompany';
import { styles } from './styles';

interface QuoteCardMetaProps
  extends Pick<
    QuoteCardProps,
    'quote' | 'company' | 'isHighlighted' | 'createdAt' | 'estimatedDeliveryDate' | 'deliveredAt' | 'status' | 'isBuyer'
  > {
  tab?: DashboardTabs;
  onClick: () => void;
  orderNumber?: string;
  onClickReopen: (e: MouseEvent) => void;
}

export const QuoteCardMeta: FC<QuoteCardMetaProps> = ({
  company,
  isHighlighted,
  createdAt = '',
  estimatedDeliveryDate = '',
  deliveredAt = '',
  tab,
  orderNumber,
  onClick,
  isBuyer,
  quote,
  status,
  onClickReopen,
}) => {
  const { isMobileSize } = useDeviceType();
  const activeQuotes = useAppSelector(selectActiveQuotes);

  const showReopen =
    status === QuoteRequestStatus.CANCELED &&
    quote?.metadata?.cancelActor === (isBuyer ? 'buyer' : 'seller') &&
    !activeQuotes.some((q) => (isBuyer ? q.seller.id === company.id : q.buyer.id === company.id));

  return (
    <Box sx={{ ...styles.meta }}>
      <Box sx={{ ...styles.companyWrap }}>
        <QuoteCompany company={company} />
      </Box>
      <Box sx={{ ...styles.metaFooter }}>
        <QuoteDates
          createdAt={createdAt}
          deliveredAt={deliveredAt}
          estimatedDeliveryDate={estimatedDeliveryDate}
          tab={tab}
          orderNumber={orderNumber}
        />
      </Box>
      <Box sx={styles.buttons}>
        {!isMobileSize && (
          <CommonButton variant={isHighlighted ? 'contained' : 'outlined'} sx={styles.viewButton} onClick={onClick}>
            View Details
          </CommonButton>
        )}
        {showReopen && (
          <CommonButton variant="contained" color="primary" sx={styles.reopenButton} onClick={onClickReopen}>
            Reopen
          </CommonButton>
        )}
      </Box>
    </Box>
  );
};
