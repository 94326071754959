import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  banner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '48px 55px 32px 85px',
    height: '332px',
    boxSizing: 'border-box',
    borderRadius: '16px',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: customTheme.custom.bannerBlue,
    marginBottom: 4,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
      marginLeft: '-14px',
      marginRight: '-14px',
      padding: '24px 16px',
      borderRadius: 0,
      height: 'inherit',
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.18)',
      overflow: 'visible',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    maxWidth: '675px',
    marginRight: '24px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      alignItems: 'center',
      marginRight: 0,
      marginBottom: 2,
    },
  },
  contentTitle: {
    color: customTheme.custom.white,
    fontSize: '40px',
    fontWeight: 700,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '0',
      marginBottom: 1,
      fontSize: '24px',
      lineHeight: '28px',
      maxWidth: '100%',
    },
  },
  sub: {
    color: customTheme.custom.white,
    fontSize: '28px',
    fontWeight: 300,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '18px',
    },
  },
  images: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    gap: 2,
    maxWidth: '470px',
    width: '100%',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: 1,
    },
  },
  imgContainer: {
    backgroundColor: customTheme.custom.white,
    borderRadius: 6,
    padding: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '128px',
    boxSizing: 'border-box',
    width: '100%',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '112px',
    },
  },
  img: {
    width: '100%',
    [`@media (max-width: ${breakpoints.laptop}px)`]: {
      width: '110px',
    },
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '70px',
    },
  },
};
