import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  viewButton: {
    height: '32px',
    fontSize: '12px',
    lineHeight: '12px',
    borderColor: customTheme.custom.white,
    color: customTheme.custom.white,
    textShadow: '1px 1px 3px rgba(0, 0, 0, 0.18)',
    boxShadow: '2px 2px 7px 0px rgba(0, 0, 0, 0.12)',

    '&:hover': {
      borderColor: customTheme.custom.white,
      backgroundColor: 'transparent',
      textShadow: '1px 1px 3px rgba(0, 0, 0, 0.18)',
      boxShadow: '2px 2px 7px 0px rgba(0, 0, 0, 0.12)',
    },
  },
  viewBorderlessButton: {
    height: '32px',
    marginTop: '6px',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.white,
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
};
