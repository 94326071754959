import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    margin: '16px 0',
    padding: '16px 0',
    borderTop: `1px solid ${customTheme.custom.black.B10}`,
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: '14px 0',
      padding: '14px 0',
    },
  },
  addressWrap: {
    marginTop: '16px',
  },
  addressTitle: {
    marginBottom: '2px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: customTheme.custom.black.B100,
  },
  address: {
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,
  },
  addressDescription: {
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
  },
};
