import { configureStore } from '@reduxjs/toolkit';

import quotesReducer from './slices/quotes';
import cropFamiliesReducer from './slices/cropFamilies';
import accountReducer, { accountListenerMiddleware } from './slices/account';

export const store = configureStore({
  reducer: {
    quotes: quotesReducer,
    cropFamilies: cropFamiliesReducer,
    account: accountReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(accountListenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
