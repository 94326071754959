import { requiredFieldValidation } from 'app/validation/validation';

export const passwordRegExp =
  // eslint-disable-next-line no-useless-escape
  /^(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).*$/;

export const validationRules = {
  password: {
    required: requiredFieldValidation,
    maxLength: { value: 255, message: 'Password does not meet all requirements' },
    pattern: { value: passwordRegExp, message: 'Password does not meet all requirements' },
  },
  required: {
    required: requiredFieldValidation,
  },
};
