import { FC, useState } from 'react';

import { Control, UseFormClearErrors, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { ReactComponent as ShipIcon } from 'assets/icons/ship.svg';
import { Quote, QuoteProductStatus, UnitsType } from 'app/api/quotes';
import { QuoteTableFormData } from 'pages/DashboardPage/types';
import { useDiscussionTable, useDiscussionTotal, useEditProductAmount } from 'pages/DashboardPage/hooks';
import { EditProductAmountPopup } from 'pages/DashboardPage/components';
import { useModal } from 'hooks';
import { EditButton } from 'components';
import { MobileSelect } from 'components/MobileSelect';
import { Currency, currencies } from 'utils/currencyFormat';
import { UserRole } from 'app/api/auth';
import { incotermOptions } from 'pages/DashboardPage/components/QuoteRequestDetailsForm';

import { QuoteDiscussionCard } from '../QuoteDiscussionCard';
import { QuoteRequestActions } from '../QuoteRequestActions';
import { styles } from './styles';
// import { incotermOptions } from 'pages/DashboardPage/components/QuoteRequestDetailsForm';

interface QuoteDiscussionListProps {
  control: Control<QuoteTableFormData>;
  isAllConfirmedOrNotAvailable: boolean;
  isAllProductsNotAvailable: boolean;
  isWaiting: boolean;
  isBuyer: boolean;
  isChatOpen: boolean;
  isCanceledQuote: boolean;
  isNewMessages: boolean;
  quoteId: number;
  quoteData: Quote;
  unitsType: UnitsType;
  setValue: UseFormSetValue<QuoteTableFormData>;
  trigger: UseFormTrigger<QuoteTableFormData>;
  clearErrors: UseFormClearErrors<QuoteTableFormData>;
  increaseConfirmedProducsCount: () => void;
  decreaseConfirmedProducsCount: () => void;
  addProducts: () => void;
  closeDetails: () => void;
  watch: UseFormWatch<QuoteTableFormData>;
  handleOpenChat: () => void;
  handleNavigateToDashboard: () => void;
  buyerCanAccept: boolean;
  isAllReadyToBeConfirmedOrNotAvailable: boolean;
}

export const QuoteDiscussionList: FC<QuoteDiscussionListProps> = ({
  control,
  isAllConfirmedOrNotAvailable,
  isAllProductsNotAvailable,
  isBuyer,
  isChatOpen,
  isWaiting,
  isCanceledQuote,
  isNewMessages,
  quoteData,
  quoteId,
  unitsType,
  addProducts,
  setValue,
  trigger,
  clearErrors,
  increaseConfirmedProducsCount,
  decreaseConfirmedProducsCount,
  closeDetails,
  watch,
  handleOpenChat,
  handleNavigateToDashboard,
  buyerCanAccept,
  isAllReadyToBeConfirmedOrNotAvailable,
}) => {
  const [currentEditId, setCurrentEditId] = useState<number | null>(null);
  const [currentRemoveId, setCurrentRemoveId] = useState<number | null>(null);

  const { choosedProducts, removeChoosedProducts, removeQuoteProductRow, appendQuoteProductRow } = useDiscussionTable(
    control,
    decreaseConfirmedProducsCount,
  );

  const {
    index,
    isEditAmountModalOpen,
    editableProductData,
    handleOpenEditAmountModal,
    handleCloseEditAmountModal,
    handleConfirmEditAmount,
    handleChangeAmount,
  } = useEditProductAmount({
    currentEditId,
    choosedProducts,
    control,
    setCurrentEditId,
    trigger,
    clearErrors,
    setValue,
    setCurrentRemoveId,
    appendQuoteProductRow,
    removeQuoteProductRow,
  });

  const { totalPrice, totalAmounts } = useDiscussionTotal({ data: choosedProducts, watch });

  const isSellerInputNeeded =
    isBuyer &&
    isWaiting &&
    choosedProducts.every(
      ({ acceptedPrice, price, status }) => !acceptedPrice && !price && status === QuoteProductStatus.AVAILABLE,
    );

  const { open: isModalOpen, handleOpenModal, handleCloseModal } = useModal();
  const {
    open: isCurrencyModalOpen,
    handleOpenModal: handleOpenCurrencyModal,
    handleCloseModal: handleCloseCurrencyModal,
  } = useModal();
  const incoterms = watch('incoterms');
  const onSelectIncoterms = (value: string) => {
    setValue(`incoterms`, value);
  };
  const currency = watch('priceUnits');
  const onSelectCurrency = (value: string) => {
    setValue(`priceUnits`, value as Currency);
  };

  const isSellerWithOffer =
    quoteData?.waitAnswer === UserRole.BUYER ||
    (quoteData?.quoteproducts.some((qp) => !qp.latestSellerPrice?.price) ?? false);

  const [isEditMode, setIsEditMode] = useState(isBuyer ? false : isSellerWithOffer);

  return (
    <>
      <Box sx={{ ...styles.quoteTerms }}>
        <Box sx={{ ...styles.incotermsContainer }}>
          <CurrencyIcon />
          <Typography component="span" sx={{ ...styles.incotermLabel }}>
            Currency:
          </Typography>
          <Typography component="span" sx={{ ...styles.incotermBuyerVal }}>
            {currency ?? Currency.USD}
          </Typography>
          {!isBuyer && (
            <>
              <EditButton onClick={handleOpenCurrencyModal} color="primary" disableFocusRipple />
              <MobileSelect
                isOpen={isCurrencyModalOpen}
                handleClose={handleCloseCurrencyModal}
                onChange={onSelectCurrency}
                title="Change Currency"
                options={currencies}
                value={currency}
              />
            </>
          )}
        </Box>

        <Box sx={{ ...styles.incotermsContainer }}>
          <ShipIcon />
          <Typography component="span" sx={{ ...styles.incotermLabel }}>
            Incoterms:
          </Typography>
          <Typography component="span" sx={{ ...styles.incotermBuyerVal }}>
            {incoterms}
          </Typography>
          {!isBuyer && (
            <>
              <EditButton onClick={handleOpenModal} color="primary" disableFocusRipple />
              <MobileSelect
                title="Change Incoterms"
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                options={incotermOptions}
                onChange={onSelectIncoterms}
                value={incoterms}
              />
            </>
          )}
        </Box>
      </Box>

      <Box sx={styles.wrap}>
        {choosedProducts.map((data, index) => (
          <QuoteDiscussionCard
            key={data.id}
            data={data}
            unitsType={unitsType}
            control={control}
            index={index}
            isWaiting={isWaiting}
            productsCount={choosedProducts.length}
            quoteId={quoteId}
            isDisabled={isCanceledQuote}
            isEditMode={isEditMode}
            isCurrentProductRemoveModal={data.id === currentRemoveId}
            quoteData={quoteData}
            setValue={setValue}
            removeChoosedProducts={removeChoosedProducts}
            increaseConfirmedProducsCount={increaseConfirmedProducsCount}
            decreaseConfirmedProducsCount={decreaseConfirmedProducsCount}
            handleOpenEditAmountModal={handleOpenEditAmountModal}
            closeDetails={closeDetails}
            handleCloseEditAmountModal={handleCloseEditAmountModal}
            setCurrentRemoveId={setCurrentRemoveId}
            handleNavigateToDashboard={handleNavigateToDashboard}
          />
        ))}
      </Box>
      {isEditAmountModalOpen && (
        <EditProductAmountPopup
          unitsType={unitsType}
          product={editableProductData}
          control={control}
          index={index}
          handleClose={handleCloseEditAmountModal}
          isOpen={isEditAmountModalOpen}
          handleConfirmEditAmount={handleConfirmEditAmount}
          handleChangeAmount={handleChangeAmount}
        />
      )}

      <QuoteRequestActions
        control={control}
        isAllProductsNotAvailable={isAllProductsNotAvailable}
        isAllConfirmedOrNotAvailable={isAllConfirmedOrNotAvailable}
        isAllReadyToBeConfirmedOrNotAvailable={isAllReadyToBeConfirmedOrNotAvailable}
        buyerCanAccept={buyerCanAccept}
        isBuyer={isBuyer}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        isCanceledQuote={isCanceledQuote}
        isChatOpen={isChatOpen}
        isNewMessages={isNewMessages}
        isSellerInputNeeded={isSellerInputNeeded}
        quoteData={quoteData}
        totalAmounts={totalAmounts}
        totalPrice={totalPrice}
        unitsType={unitsType}
        addProducts={addProducts}
        handleOpenChat={handleOpenChat}
      />
    </>
  );
};
