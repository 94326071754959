import { useState } from 'react';
import { Box, Collapse, Drawer, IconButton, Typography } from '@mui/material';

import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import { DashboardMainTabs, Quote, QuoteProduct, QuoteProductStatus, QuoteRequestStatus } from 'app/api/quotes';
import { LinkButton } from 'components/LinkButton';
import { useImageHandlers } from 'hooks';
import { formatNumber } from 'utils/formatText';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { Currency, currencyFormat } from 'utils/currencyFormat';
import { Link } from 'components/Link';
import { createSearchParams, generatePath } from 'react-router-dom';
import { ROUTES } from 'app/routes/constants';
import { OrderQuoteProduct, OrderStatus, OrdersListData } from 'app/api/orders';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowDown.svg';

import { styles } from './styles';

export const QuoteProductCard = ({
  isBuyer,
  quoteProduct,
  isTransparent = false,
}: {
  isBuyer: boolean;
  quoteProduct: QuoteProduct | OrderQuoteProduct;
  isTransparent?: boolean;
}) => {
  const { handleImageError } = useImageHandlers();
  const imageSrc = quoteProduct.product.thumbnail || quoteProduct.product.image || imagePlaceholder;
  let myLastOffer = null;
  let otherUserLastOffer = null;
  if ('latestBuyerPrice' in quoteProduct && 'latestSellerPrice' in quoteProduct) {
    myLastOffer = isBuyer ? quoteProduct?.latestBuyerPrice : quoteProduct?.latestSellerPrice;
    otherUserLastOffer = isBuyer ? quoteProduct?.latestSellerPrice : quoteProduct?.latestBuyerPrice;
  }
  const productPath = generatePath(ROUTES.common.product, { id: quoteProduct.product.id });

  return (
    <Link path={productPath}>
      <Box sx={styles.quoteProductCardContainer(isTransparent)}>
        <Box
          component="img"
          loading="lazy"
          src={imageSrc}
          sx={{ ...styles.productImage }}
          onError={handleImageError(imagePlaceholder)}
        />

        <Box sx={styles.productDetails}>
          <Box sx={styles.productDetailsTop}>
            <Typography sx={styles.crop}>{quoteProduct.product.crop}</Typography>
            <Typography sx={styles.amount}>{`${formatNumber(quoteProduct.amount)} ${getUnitDisplayValue(
              quoteProduct.amountType,
              true,
            )}`}</Typography>
          </Box>
          <Box sx={styles.productDetailsName} component="p">
            <Typography sx={styles.productDetailsVariety} component="span">
              {quoteProduct.product.variety}
            </Typography>
            <Typography sx={styles.productDetailsProductName} component="span">
              {quoteProduct.product.name}
            </Typography>
          </Box>
          <Box sx={styles.productOffers}>
            {myLastOffer?.price && !quoteProduct.acceptedPrice ? (
              <Typography sx={styles.myLastOffer}>
                {`Latest ${isBuyer ? 'Buyer' : 'Seller'} Price - `}
                {`${currencyFormat(myLastOffer.price, myLastOffer.priceUnits)} per unit`}
              </Typography>
            ) : null}
            {otherUserLastOffer?.price && !quoteProduct.acceptedPrice ? (
              <Typography sx={styles.otherLastOffer}>
                {`Latest ${isBuyer ? 'Seller' : 'Buyer'} Price - `}
                {`${currencyFormat(otherUserLastOffer.price, otherUserLastOffer.priceUnits)} per unit`}
              </Typography>
            ) : null}
            {quoteProduct.acceptedPrice ? (
              <Typography sx={styles.otherLastOffer}>
                {`Accepted Price - `}
                {`${currencyFormat(quoteProduct.acceptedPrice, quoteProduct.acceptedPriceUnits as Currency)} per unit`}
              </Typography>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export const QuotePanel = ({
  isBuyer,
  quotes,
  orders,
  isOpen,
}: {
  isOpen: boolean;
  isBuyer: boolean;
  quotes: Quote[];
  orders: { list: OrdersListData; canceledList: OrdersListData };
}) => {
  const path = isBuyer ? ROUTES.buyer.dashboard.tab : ROUTES.seller.dashboard.tab;
  const activeQuote = quotes.find((quote) => quote.status === QuoteRequestStatus.OPEN);
  const previousQuotes: Quote[] = quotes.filter(
    (quote) => quote.id !== activeQuote?.id && quote.status !== QuoteRequestStatus.ACCEPTED,
  );
  const activeOrders = orders.list.filter(
    (order) => order.status !== OrderStatus.DELIVERED && order.status !== OrderStatus.EN_ROUTE,
  );
  const quotePath = `${generatePath(path, { tab: DashboardMainTabs.QUOTE_REQUESTS })}?${createSearchParams({
    quoteId: activeQuote?.id.toString() ?? '',
  }).toString()}`;
  const activeQuoteProducts = (activeQuote?.quoteproducts ?? [])
    .concat(activeOrders.flatMap((order) => order.quote.quoteproducts))
    .filter((quoteProduct) => quoteProduct.status === QuoteProductStatus.AVAILABLE);

  // Get all products from previous orders, cancelled quotes and active quotes, but removed
  const previousQuotesProducts = orders.list
    .filter((order) => order.status === OrderStatus.DELIVERED || order.status === OrderStatus.EN_ROUTE)
    .flatMap((order) => order.quote.quoteproducts)
    .concat(previousQuotes.flatMap((quote: Quote) => quote.quoteproducts))
    .concat(orders.canceledList.flatMap((order) => order.quote.quoteproducts))
    .concat(
      (activeQuote?.quoteproducts ?? [])
        .concat(activeOrders.flatMap((order) => order.quote.quoteproducts))
        .filter((quoteProduct) => quoteProduct.status !== QuoteProductStatus.AVAILABLE),
    );
  const [isPastProductsOpen, setIsPastProductsOpen] = useState(false);

  return (
    <Box sx={styles.quoteContainer(isOpen)}>
      <Drawer sx={styles.drawer} variant="persistent" anchor="right" open={isOpen}>
        {activeQuoteProducts?.length > 0 && (
          <>
            <Box sx={styles.quoteContainerHeader}>
              <Typography sx={styles.quoteContainerTitle} variant="h6">
                Products Under Discussion
              </Typography>
              <LinkButton to={quotePath} sx={styles.quoteContainerBtn} variant="contained" color="primary">
                Open Quote
              </LinkButton>
            </Box>
            <Box sx={styles.quoteProductCards}>
              {activeQuoteProducts.map(
                (quoteProduct) =>
                  <QuoteProductCard key={quoteProduct.id} quoteProduct={quoteProduct} isBuyer={isBuyer} /> ?? null,
              )}
            </Box>
          </>
        )}
        {previousQuotesProducts.length > 0 && (
          <>
            <Box
              sx={[styles.quoteContainerHeader, styles.pastProducts]}
              onClick={() => setIsPastProductsOpen((o) => !o)}
            >
              <Typography sx={[styles.quoteContainerTitle, styles.quoteContainerLowerTitle]} variant="h6">
                Past Products Under Discussion
              </Typography>
              <IconButton sx={styles.expandIcon(isPastProductsOpen)}>
                <ArrowIcon />
              </IconButton>
            </Box>
            <Collapse in={isPastProductsOpen} timeout="auto" unmountOnExit>
              <Box sx={styles.quoteProductCards}>
                {previousQuotesProducts.map((quoteProduct) => (
                  <QuoteProductCard key={quoteProduct.id} quoteProduct={quoteProduct} isBuyer={isBuyer} isTransparent />
                ))}
              </Box>
            </Collapse>
          </>
        )}
      </Drawer>
    </Box>
  );
};
