import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Box, Menu, SxProps, Typography } from '@mui/material';

import { ReactComponent as SortIcon } from 'assets/icons/sort.svg';
import { useAuth } from 'hooks';
import { RadioGroupItem } from '../RadioGroupController/types';
import { CommonButton } from '../CommonButton';
import { RadioGroupController } from '../RadioGroupController';
import { useSortFilter } from './hooks';
import { styles } from './styles';

interface SortFilterProps {
  data: RadioGroupItem[];
  control: Control;
  controlName: string;
  horizontalTransform?: number;
  buttonSx?: SxProps;
  wrapSx?: SxProps;
}

export const SortFilter: FC<SortFilterProps> = ({
  data,
  control,
  controlName,
  horizontalTransform = 0,
  buttonSx,
  wrapSx,
}) => {
  const { isBuyer } = useAuth();
  const { anchorEl, open, filterTitle, handleClick, handleClose } = useSortFilter(control, controlName, data);

  return (
    <Box sx={{ ...styles.wrap, ...wrapSx }}>
      <CommonButton
        sx={{
          ...styles.menuButton(isBuyer),
          ...(open && styles.menuButtonOpened),
          ...buttonSx,
        }}
        onClick={handleClick}
        disableRipple
      >
        <Box sx={{ ...styles.sortTitleWrap }}>
          <Typography sx={{ ...styles.sortTitle }}>Sort by</Typography>
          <Typography
            sx={{
              ...styles.sortLabel,
              ...(open && styles.sortLabelOpened),
            }}
          >
            {filterTitle}
          </Typography>
        </Box>
        <Box
          sx={{
            ...styles.menuButtonIcon,
            ...(open && styles.menuButtonIconopened),
          }}
        >
          <SortIcon />
        </Box>
      </CommonButton>
      <Menu
        sx={{ ...styles.menuWrap }}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: horizontalTransform,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <RadioGroupController controlName={controlName} control={control} data={data} handleClose={handleClose} />
      </Menu>
    </Box>
  );
};
