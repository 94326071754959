import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { ShipmentInfo } from 'app/api/orders/types';
import { styles } from './styles';

type ContainerInfoProps = ShipmentInfo;

export const ContainerInfo: FC<ContainerInfoProps> = ({ containerType }) => {
  return (
    <Box sx={{ ...styles.item }}>
      <Typography sx={{ ...styles.subTitle }}>Container type</Typography>
      <Typography sx={{ ...styles.value }}>{containerType}</Typography>
    </Box>
  );
};
