import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'relative',
    width: 0,
    background: customTheme.custom.mainBg,
    zIndex: 1,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  drawer: (hasQuotePanel: boolean) => ({
    width: '280px',
    ...(hasQuotePanel && {
      width: '350px',
    }),
    padding: '8px 16px',
    flexShrink: 0,

    '& .MuiDrawer-paper': {
      position: 'absolute',
      width: '280px',
      ...(hasQuotePanel && {
        width: '350px',
      }),
      height: '100%',
      padding: 0,
      color: customTheme.custom.neutralLight,
      background: customTheme.custom.mainBg,
      overflow: 'hidden',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
  }),
  drawerInner: {
    padding: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  inner: {
    height: 'calc(100% - 2px)',

    '& *': {
      fontFamily: customTheme.typography.allVariants.fontFamily,
    },

    '.sendbird-message-search-pannel': {
      width: '100%',
      background: customTheme.custom.mainBg,
    },

    '.sendbird-message-search-pannel__header': {
      height: '57px',
      minHeight: '57px',
    },

    '.sendbird-message-search-pannel__message-search': {
      overflow: 'hidden',
    },

    '.sendbird-message-search': {
      height: 'calc(100% - 4px)',
      background: customTheme.custom.mainBg,
      overflowX: 'hidden',
      overflowY: 'auto',

      ...scrollMixin,
    },

    '.sendbird-message-search-pannel__header__close-button': {
      borderRadius: '50%',
    },

    '.sendbird-message-search-pannel__header__title': {
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: 400,
      overflow: 'hidden',
    },

    '.sendbird-message-search-pannel__input__container': {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      borderRadius: '0 !important',
      height: 'fit-content !important',
      padding: 0,
      overflow: 'hidden',
      border: 'none !important',

      '&__search-icon': {
        right: '16px',
        left: 'unset !important',
      },

      '.sendbird-icon-search': {
        top: '8px',

        '& svg': {
          width: '22px',
          height: '22px',
          maxWidth: '22px',
          maxHeight: '22px',
        },

        '& path': {
          fill: customTheme.custom.black.B100,
          width: '18px',
          height: '18px',
          maxWidth: '18px',
          maxHeight: '18px',
        },
      },

      '.sendbird-message-search-pannel__input__container__spinner.sendbird-loader': {
        right: '42px !important',
        top: '11px !important',
      },

      '.sendbird-icon-remove': {
        right: '44px !important',
        background: customTheme.custom.neutralLight,
        borderRadius: '50%',
        width: '16px !important',
        minWidth: '16px !important',
        height: '16px !important',
        minHeight: '16px !important',
        overflow: 'visible',

        '& svg': {
          marginLeft: '-2px',
          marginTop: '-2px',
          width: '20px !important',
          minWidth: '20px !important',
          height: '20px !important',
          minHeight: '20px !important',
        },

        '& path': {
          width: '20px',
          height: '20px',
          fill: customTheme.custom.white,
        },
      },

      '&__input-area': {
        position: 'static !important',
        width: '100%',
        height: '40px !important',
        padding: '8px 56px 8px 16px',
        boxSizing: 'border-box',
        letterSpacing: '0.2px',
        fontSize: '14px',
        lineHeight: '20px',
        border: `1px solid ${customTheme.custom.black.B10} !important`,
        borderRadius: '25px !important',

        '&::placeholder': {
          color: customTheme.custom.black.B50,
          opacity: 1,
        },

        '&:hover, &:focus': {
          background: customTheme.custom.black.B5,
          transition: 'background 0.2s linear',
        },

        '&:hover + .sendbird-icon-remove, &:focus + .sendbird-icon-remove': {
          '& path': {
            fill: customTheme.custom.black.B5,
          },
        },
      },
    },
  },
};
