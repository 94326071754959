import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonModal, CommonButton, LoadingButton, TextFormField } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { validationRules } from './validationRules';
import { useAddRejectReasonPopup } from './hooks/useAddRejectReasonPopup';
import { styles } from './styles';

interface AddRejectReasonPopupProps {
  id: number;
  kind: string;
  fileName: string;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  isOpen: boolean;
  handleClose: () => void;
  setDocumentAsUpdated: () => void;
}

const messageMaxLength = 2000;

export const AddRejectReasonPopup: FC<AddRejectReasonPopupProps> = ({
  id,
  kind,
  fileName,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  isOpen = false,
  handleClose,
  setDocumentAsUpdated,
}) => {
  const { isRejectDocumentLoading, control, handleSubmit, onSubmit } = useAddRejectReasonPopup({
    id,
    kind,
    fileName,
    isSampleRequest,
    folderId,
    orderId,
    quoteId,
    setDocumentAsUpdated,
    handleClose,
  });

  return (
    <CommonModal title="Specify decline reason" isOpen={isOpen} handleClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={{ ...styles.content }}>
          Describe why the document does not fit so that the buyer can resolve the issue.
        </Typography>

        <TextFormField
          control={control}
          name="reason"
          inputWrapStyles={{ ...(styles.inputWrapStyles as SxPropsTypes) }}
          multiline
          rows={5}
          withoutHighlights
          placeholder="Decline reason"
          maxLength={messageMaxLength}
          rules={validationRules.message}
        />

        <Box sx={{ ...styles.actions }}>
          <CommonButton
            disabled={isRejectDocumentLoading}
            variant="text"
            color="inherit"
            sx={{ ...styles.closeButton }}
            onClick={handleClose}
          >
            Cancel
          </CommonButton>
          <LoadingButton
            loading={isRejectDocumentLoading}
            variant="text"
            type="submit"
            color="inherit"
            sx={{ ...styles.confirmButton }}
          >
            Confirm
          </LoadingButton>
        </Box>
      </form>
    </CommonModal>
  );
};
