import { useCallback } from 'react';

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';

import { NOT_FOUND_ERROR_CODE } from 'app/api/errors';
import {
  DashboardMainTabs,
  QUOTE_REQUEST_NOT_FOUND_ERROR,
  QuoteRequestStatus,
  SAMPLE_REQUEST_NOT_FOUND_ERROR,
  getQuoteData,
} from 'app/api/quotes';
import { ROUTES } from 'app/routes/constants';
import { useAuth, useFetchSystemMessages } from 'hooks';
import { notificationObserver } from 'utils/observer';
import { storage } from 'utils/storage';
import { IS_UPDATE_WAIT_ANSWER_KEY } from 'pages/DashboardPage/constants';

export const useQuoteDetailsPage = () => {
  const { isBuyer } = useAuth();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const matchSampleRequestPage = useMatch(ROUTES.common.sampleRequestDetails);

  const isSampleRequestPage = !!matchSampleRequestPage;

  useFetchSystemMessages({
    queryKey: 'quote-system-messages',
    quoteId: Number(id),
    enabled: !!id,
  });

  const handleQuoteNotFound = useCallback(() => {
    const errorMessage = isSampleRequestPage ? SAMPLE_REQUEST_NOT_FOUND_ERROR : QUOTE_REQUEST_NOT_FOUND_ERROR;
    const tab = isSampleRequestPage ? DashboardMainTabs.SAMPLE_REQUESTS : DashboardMainTabs.QUOTE_REQUESTS;
    const path = generatePath(isBuyer ? ROUTES.buyer.dashboard.tab : ROUTES.seller.dashboard.tab, { tab });

    notificationObserver.publish({
      type: 'error',
      title: errorMessage,
    });

    navigate(path);
  }, [isBuyer, isSampleRequestPage, navigate]);

  const { data, isLoading } = useQuery(['quote-data', id], () => getQuoteData(Number(id)), {
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    onSuccess: (data) => {
      storage.deleteItem(IS_UPDATE_WAIT_ANSWER_KEY);

      if (data.status === QuoteRequestStatus.ACCEPTED) {
        handleQuoteNotFound();
      }
    },
    onError: (error: AxiosError) => {
      if (!error || error?.response?.status === NOT_FOUND_ERROR_CODE) {
        handleQuoteNotFound();
      }
    },
  });

  return {
    data,
    isBuyer,
    isLoading,
  };
};
