import { useCallback, useState } from 'react';

import axios from 'axios';
import { useQuery } from 'react-query';

import { getFileExtension } from 'pages/CompanyDetailsPage/utils';

const IMAGES_FORMAT = ['png', 'jpeg', 'jpg'];

interface UseMapSourceHandlers {
  src: string;
}

export const useTrackingMapSourceHandlers = ({ src }: UseMapSourceHandlers) => {
  const [isError, setIsError] = useState(false);
  const mediaExtension = getFileExtension(src);
  const isImage = IMAGES_FORMAT.some((format) => format === mediaExtension);

  const handleLoadError = useCallback(() => {
    setIsError(true);
  }, []);

  const { isLoading } = useQuery(
    ['check-map-src', src],
    async () => {
      await axios.head(src);
    },
    {
      enabled: !isImage && !!src.length,
      retry: false,
      onError: () => {
        handleLoadError();
      },
    },
  );

  return {
    isLoading,
    isError,
    isImage,
    handleLoadError,
  };
};
