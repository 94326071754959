import { useCallback, useEffect, useMemo } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import { FilterParams, ProductsFiltersForm } from 'app/api/products';
import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import {
  AdditionalFiltersControlNames,
  FiltersNames,
  producttFilterAllValue,
  geneticFilterValues,
  sortValues,
  organicFilterValues,
} from '../../../constants';
import { useIsMount } from '../../../hooks';
import {
  getDefaultFilterValues,
  getMaxRangeValue,
  getMinRangeValue,
  getUrlFromFilters,
  parseFiltersFromUrl,
} from '../../../utils';

interface UseProductsFiltersProps {
  parsedFilters: FilterParams | undefined;
  parsedColors: CheckboxFilterItem[] | undefined;
  parsedContinents: CheckboxFilterItem[] | undefined;
  parsedResistances: CheckboxFilterItem[] | undefined;
  searchParam: string | undefined;
  minWeightLimit: number;
  maxWeightLimit: number;
  minMaturityLimit: number;
  maxMaturityLimit: number;
}

export const useProductsFilters = ({
  parsedFilters,
  parsedColors,
  parsedContinents,
  parsedResistances,
  searchParam,
  minWeightLimit,
  maxWeightLimit,
  minMaturityLimit,
  maxMaturityLimit,
}: UseProductsFiltersProps) => {
  const { type, subType } = useParams();
  const [, setSearchParams] = useSearchParams();
  const isMount = useIsMount();

  const defaultFilters = useMemo(
    () =>
      getDefaultFilterValues({
        geneticTypeValue: geneticFilterValues[0].value,
        colorValues: parsedColors,
        continentValues: parsedContinents,
        resistancesValues: parsedResistances,
        sortValue: sortValues[0].value,
        minWeight: minWeightLimit,
        maxWeight: maxWeightLimit,
        minMaturity: minMaturityLimit,
        maxMaturity: maxMaturityLimit,
        organicValue: organicFilterValues[0].value,
      }),
    [
      maxMaturityLimit,
      maxWeightLimit,
      minMaturityLimit,
      minWeightLimit,
      parsedColors,
      parsedContinents,
      parsedResistances,
    ],
  );

  const {
    watch,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ProductsFiltersForm>({
    defaultValues: {
      ...defaultFilters,
      ...{
        ...parseFiltersFromUrl({
          parsedFilters,
          colorValues: parsedColors,
          continentValues: parsedContinents,
          resistancesValues: parsedResistances,
          minWeightLimit,
          maxWeightLimit,
          minMaturityLimit,
          maxMaturityLimit,
        }),
      },
    },
  });

  const geneticTypeValue = useWatch({
    control,
    name: FiltersNames.GENETIC_TYPE,
  });

  const colorValue = useWatch({
    control,
    name: FiltersNames.COLOR,
  });

  const continentValue = useWatch({
    control,
    name: FiltersNames.CONTINENT,
  });

  const resistancesValue = useWatch({
    control,
    name: FiltersNames.RESISTANCES,
  });

  const includeProductsWithIncopmleteResistancesValue = useWatch({
    control,
    name: AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_RESISTANCES,
  });

  const sortValue = useWatch({
    control,
    name: FiltersNames.SORT,
  });

  const weightValue = useWatch({ control, name: AdditionalFiltersControlNames.WEIGHT });
  const maturityValue = useWatch({ control, name: AdditionalFiltersControlNames.MATURITY });

  const includeProductsWithIncopmleteWeightValue = useWatch({
    control,
    name: AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT,
  });

  const includeProductsWithIncopmleteMaturityValue = useWatch({
    control,
    name: AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY,
  });

  const organicValue = useWatch({
    control,
    name: FiltersNames.ORGANIC,
  });

  const minWeightValue = getMinRangeValue(weightValue, minWeightLimit, includeProductsWithIncopmleteWeightValue);
  const maxWeightValue = getMaxRangeValue(weightValue, maxWeightLimit, includeProductsWithIncopmleteWeightValue);

  const minMaturityValue = getMinRangeValue(
    maturityValue,
    minMaturityLimit,
    includeProductsWithIncopmleteMaturityValue,
  );
  const maxMaturityValue = getMaxRangeValue(
    maturityValue,
    maxMaturityLimit,
    includeProductsWithIncopmleteMaturityValue,
  );

  const minWeightParam = minWeightValue ?? (maxWeightValue ? minWeightLimit : undefined);
  const maxWeightParam = maxWeightValue ?? (minWeightValue ? maxWeightLimit : undefined);
  const minMaturityParam = minMaturityValue ?? (maxMaturityValue ? minMaturityLimit : undefined);
  const maxMaturityParam = maxMaturityValue ?? (minMaturityValue ? maxMaturityLimit : undefined);

  const isAnySelected = useMemo(() => {
    return (
      geneticTypeValue !== producttFilterAllValue ||
      !colorValue[0] ||
      !continentValue[0] ||
      !resistancesValue[0] ||
      !includeProductsWithIncopmleteResistancesValue ||
      !includeProductsWithIncopmleteMaturityValue ||
      !includeProductsWithIncopmleteWeightValue ||
      !!minWeightValue ||
      !!maxWeightValue ||
      !!minMaturityValue ||
      !!maxMaturityValue ||
      organicValue !== producttFilterAllValue
    );
  }, [
    geneticTypeValue,
    colorValue,
    continentValue,
    resistancesValue,
    includeProductsWithIncopmleteResistancesValue,
    includeProductsWithIncopmleteMaturityValue,
    includeProductsWithIncopmleteWeightValue,
    minWeightValue,
    maxWeightValue,
    minMaturityValue,
    maxMaturityValue,
    organicValue,
  ]);

  const clearFilters = useCallback(() => {
    reset(defaultFilters);
  }, [reset, defaultFilters]);

  // set url from filters
  useEffect(() => {
    const subscription = watch((value) => {
      const params = {
        ...getUrlFromFilters(value, minWeightLimit, maxWeightLimit, minMaturityLimit, maxMaturityLimit),
        ...(searchParam && { search: searchParam }),
      };
      setSearchParams(params, { replace: true });
    });
    return () => subscription.unsubscribe();
  }, [watch, searchParam, setSearchParams, minWeightLimit, maxWeightLimit, minMaturityLimit, maxMaturityLimit]);

  // to refresh flters after switch crop, crop family or search
  useEffect(() => {
    if (!isMount) {
      reset(defaultFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, searchParam, type, subType]);

  // set filters from url
  useEffect(() => {
    const filters = parseFiltersFromUrl({
      parsedFilters,
      colorValues: parsedColors,
      continentValues: parsedContinents,
      resistancesValues: parsedResistances,
      minWeightLimit,
      maxWeightLimit,
      minMaturityLimit,
      maxMaturityLimit,
    });

    if (!Object.keys(filters).length) {
      return;
    }

    const formValues = getValues();
    reset({ ...formValues, ...filters });
  }, [
    reset,
    getValues,
    parsedFilters,
    parsedColors,
    parsedContinents,
    parsedResistances,
    defaultFilters,
    minWeightLimit,
    maxWeightLimit,
    minMaturityLimit,
    maxMaturityLimit,
  ]);

  const isGeneticTypeSelected = geneticTypeValue !== producttFilterAllValue;
  const isColorSelected = !colorValue[0];
  const isContinentSelected = !continentValue[0];
  const isWeightSelected = !includeProductsWithIncopmleteWeightValue || !!minWeightValue || !!maxWeightValue;
  const isMaturitySelected = !includeProductsWithIncopmleteMaturityValue || !!minMaturityValue || !!maxMaturityValue;
  const isOrganicSelected = organicValue !== producttFilterAllValue;
  const selectedFilters = useMemo(
    () => [
      isGeneticTypeSelected,
      isColorSelected,
      isContinentSelected,
      isWeightSelected,
      isMaturitySelected,
      isOrganicSelected,
    ],
    [
      isColorSelected,
      isContinentSelected,
      isGeneticTypeSelected,
      isWeightSelected,
      isMaturitySelected,
      isOrganicSelected,
    ],
  );
  const selectedFiltersCount = useMemo(
    () => selectedFilters.filter((selected) => !!selected).length ?? 0,
    [selectedFilters],
  );

  return {
    errors,
    control,
    isAnySelected,
    geneticTypeValue,
    colorValue,
    continentValue,
    resistancesValue,
    applyResistancesFilter: !includeProductsWithIncopmleteResistancesValue,
    sortValue,
    minWeightValue: minWeightParam,
    maxWeightValue: maxWeightParam,
    applyWeightFilter: !includeProductsWithIncopmleteWeightValue,
    minMaturityValue: minMaturityParam,
    maxMaturityValue: maxMaturityParam,
    applyMaturityFilter: !includeProductsWithIncopmleteMaturityValue,
    organicValue,
    selectedFiltersCount,
    clearFilters,
    getValues,
    reset,
    setValue,
  };
};
