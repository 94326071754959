import { FC } from 'react';

import { Box } from '@mui/material';

import { CommonButton, LoadingButton } from 'components';
import { styles } from './styles';

interface CompanyActionsButtonsProps {
  isCreateChannelLoading: boolean;
  isRequestButtons: boolean;
  onRequestQuoteClick?: () => void;
  onSendMessageClick?: () => void;
}

export const CompanyActionsButtons: FC<CompanyActionsButtonsProps> = ({
  isCreateChannelLoading,
  isRequestButtons,
  onRequestQuoteClick,
  onSendMessageClick,
}) => {
  return (
    <Box sx={styles.actionsWrap}>
      <LoadingButton
        loading={isCreateChannelLoading}
        sx={{ ...styles.btn, ...styles.messageButton }}
        onClick={onSendMessageClick}
      >
        Send Message
      </LoadingButton>
      {isRequestButtons && (
        <>
          <CommonButton sx={{ ...styles.btn, ...styles.quoteButton }} onClick={onRequestQuoteClick}>
            Request Quote
          </CommonButton>
        </>
      )}
    </Box>
  );
};
