import { useQuery } from 'react-query';

import { useNavigate } from 'react-router-dom';

import { getOrderData } from 'app/api/orders/service';
import { getDefaultUserRoute } from 'app/providers/utils';

interface OrderQueryI {
  id: number;
  isBuyer: boolean;
  userId?: number;
  queryKey?: string;
}

export const useOrderQuery = ({ id, isBuyer, userId, queryKey = 'order' }: OrderQueryI) => {
  const navigate = useNavigate();

  const { isLoading, isFetching, data, refetch } = useQuery([queryKey, id, userId], () => getOrderData(id), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: () => {
      navigate(getDefaultUserRoute(isBuyer));
    },
  });

  return {
    isLoading: isLoading || isFetching,
    data,
    refetch,
  };
};
