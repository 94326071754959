import { styled } from '@mui/material';

export const ChatContent = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  hasQuotePanel: boolean;
}>(({ theme, open, hasQuotePanel }) => ({
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

    marginRight: '270px',
    ...(hasQuotePanel && {
      marginRight: '0',
    }),
  }),
  position: 'relative',
}));
