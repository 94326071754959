import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  colorContainer: {
    height: '32px',
    width: '250px',
    display: 'flex',
    cursor: 'pointer',
    gap: '8px',
    alignItems: 'center',
  },
  colorLabel: {
    fontSize: '16px',
    fontWeight: 500,
  },

  colorBox: (color?: string) => ({
    backgroundColor: 'red',
    ...(color && { background: color, boxShadow: `0px 2px 4px ${color}af` }),
    height: '26px',
    width: '26px',
    borderRadius: '4px',
    cursor: 'pointer',
  }),
  popper: {
    zIndex: 10,
  },
  popperContainer: {
    border: `1px solid ${customTheme.custom.gray.G200}`,
    borderRadius: '4px',
    padding: '8px',
    backgroundColor: '#ECECEC',
    maxWidth: '283px',
    boxSizing: 'border-box',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    margin: '8px 0px',
  },
  header: {
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '8px',
  },
  subHeader: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '8px',
  },
  colorBoxContainer: (toCenter = false, smallerGap = false) => ({
    display: 'flex',
    gap: '8px 16px',
    ...(toCenter && { justifyContent: 'center' }),
    ...(smallerGap && { gap: '8px 12px' }),
    flexWrap: 'wrap',
    marginBottom: '12px',
  }),
  colorChoiceBox: (color: string) => ({
    backgroundColor: color,
    height: '24px',
    width: '24px',
    borderRadius: '4px',
    cursor: 'pointer',
    boxShadow: `0px 2px 4px ${color}af`,
  }),
  changingColorChoiceBox: (color: string) => ({
    background: color,
    padding: '4px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '10px',
    color: '#5F5F5F',
    fontWeight: 600,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  }),
  customIcon: (size: number) => ({
    borderRadius: '4px',
    fontSize: '9px',
    overflow: 'hidden',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    height: `${size}px`,
    width: `${size}px`,
    cursor: 'pointer',
  }),
  otherColors: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#AE3030',
    fontSize: '10px',
    fontWeight: 700,
  },
  tooltip: {
    marginBottom: '-4px !important',
    marginLeft: '12px !important',
  },
};
