import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  closeButton: (isLeftPosition: boolean) => ({
    position: 'fixed',
    top: '20px',
    right: '20px',
    zIndex: 1210,

    '& path': {
      fill: customTheme.custom.white,
    },

    ...(isLeftPosition && {
      right: 'unset',
      left: '20px',
    }),
  }),
};
