import { FC, ReactNode } from 'react';

import { Popover } from '@mui/material';

import { styles } from './styles';

interface NotificationsPopoverProps {
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
  children: ReactNode;
  handleClose: () => void;
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = ({ isOpen, anchorEl, children, handleClose }) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        sx: {
          ...styles.paper,
        },
      }}
    >
      {children}
    </Popover>
  );
};
