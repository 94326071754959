import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  productRow: {
    display: 'flex',
    alignItems: 'center',
    gap: '32px',
    padding: '12px 0',
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '14px',
      display: 'grid',
      gap: '6px',

      ':first-of-type': {
        paddingTop: 0,
      },
      ':last-of-type': {
        borderBottom: 'none',
        paddingBottom: '2px',
      },
    },
  },
  productInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    flex: '1 1 48%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '8px',
    },
  },
  productImage: {
    width: '40px',
    height: '40px',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  countInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 52%',
    gap: '80px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: 0,
    },
  },
  countField: {
    flex: '1',
    margin: 0,
  },
  removeButton: {
    padding: '4px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '32px',
      height: '32px',
      padding: 0,

      svg: {
        width: '32px',
        height: '32px',
      },
    },
  },
  title: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: customTheme.custom.black.B50,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
    },
  },
  nameWrap: {
    fontWeight: '600',
    lineHeight: '22px',
    color: customTheme.custom.black.B50,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '17px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  name: {
    fontWeight: '600',
    lineHeight: '22px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '17px',
    },
  },
  textFieldWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flex: 1,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: '120px',

      '& .MuiInputBase-root': {
        height: '30px',
        boxSizing: 'border-box',
      },

      '& .MuiInputBase-input': {
        paddingLeft: '10px',
        paddingRight: '10px',

        '&::placeholder': {
          fontSize: '14px',
        },
      },
    },
  },
  unitLabel: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  neededLabel: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      marginRight: '4px',
      maxWidth: '50%',
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 600,
      color: customTheme.custom.black.B100,
    },
  },
  titlesWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
    },
  },
  editQuantityUnitsLabel: {},
};
