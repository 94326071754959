const mappings: {
  [key: string]: { name: string; adj: string };
} = {
  '2004': { name: 'Afghanistan', adj: 'Afghan' },
  '2008': { name: 'Albania', adj: 'Albanian' },
  '2010': { name: 'Antarctica', adj: 'Antarctic' },
  '2012': { name: 'Algeria', adj: 'Algerian' },
  '2016': { name: 'American Samoa', adj: 'American Samoan' },
  '2020': { name: 'Andorra', adj: 'Andorran' },
  '2024': { name: 'Angola', adj: 'Angolan' },
  '2028': { name: 'Antigua and Barbuda', adj: 'Antiguan and Barbudan' },
  '2031': { name: 'Azerbaijan', adj: 'Azerbaijani' },
  '2032': { name: 'Argentina', adj: 'Argentinian' },
  '2036': { name: 'Australia', adj: 'Australian' },
  '2040': { name: 'Austria', adj: 'Austrian' },
  '2044': { name: 'The Bahamas', adj: 'Bahamian' },
  '2048': { name: 'Bahrain', adj: 'Bahraini' },
  '2050': { name: 'Bangladesh', adj: 'Bangladeshi' },
  '2051': { name: 'Armenia', adj: 'Armenian' },
  '2052': { name: 'Barbados', adj: 'Barbadian' },
  '2056': { name: 'Belgium', adj: 'Belgian' },
  '2064': { name: 'Bhutan', adj: 'Bhutanese' },
  '2068': { name: 'Bolivia', adj: 'Bolivian' },
  '2070': { name: 'Bosnia and Herzegovina', adj: 'Bosnian and Herzegovinian' },
  '2072': { name: 'Botswana', adj: 'Botswanan' },
  '2076': { name: 'Brazil', adj: 'Brazilian' },
  '2084': { name: 'Belize', adj: 'Belizean' },
  '2090': { name: 'Solomon Islands', adj: 'Solomon Islander' },
  '2096': { name: 'Brunei', adj: 'Bruneian' },
  '2100': { name: 'Bulgaria', adj: 'Bulgarian' },
  '2104': { name: 'Myanmar', adj: 'Burmese' },
  '2108': { name: 'Burundi', adj: 'Burundian' },
  '2112': { name: 'Belarus', adj: 'Belarusian' },
  '2116': { name: 'Cambodia', adj: 'Cambodian' },
  '2120': { name: 'Cameroon', adj: 'Cameroonian' },
  '2124': { name: 'Canada', adj: 'Canadian' },
  '2132': { name: 'Cabo Verde', adj: 'Cabo Verdean' },
  '2140': { name: 'Central African Republic', adj: 'Central African' },
  '2144': { name: 'Sri Lanka', adj: 'Sri Lankan' },
  '2148': { name: 'Chad', adj: 'Chadian' },
  '2152': { name: 'Chile', adj: 'Chilean' },
  '2156': { name: 'China', adj: 'Chinese' },
  '2162': { name: 'Christmas Island', adj: 'Christmas Islander' },
  '2166': { name: 'Cocos Islands', adj: 'Cocos Islander' },
  '2170': { name: 'Colombia', adj: 'Colombian' },
  '2174': { name: 'Comoros', adj: 'Comoran' },
  '2178': { name: 'Republic of the Congo', adj: 'Congolese' },
  '2180': { name: 'Democratic Republic of the Congo', adj: 'Congolese' },
  '2184': { name: 'Cook Islands', adj: 'Cook Islander' },
  '2188': { name: 'Costa Rica', adj: 'Costa Rican' },
  '2191': { name: 'Croatia', adj: 'Croatian' },
  '2196': { name: 'Cyprus', adj: 'Cypriot' },
  '2203': { name: 'Czechia', adj: 'Czech' },
  '2204': { name: 'Benin', adj: 'Beninese' },
  '2208': { name: 'Denmark', adj: 'Danish' },
  '2212': { name: 'Dominica', adj: 'Dominican' },
  '2214': { name: 'Dominican Republic', adj: 'Dominican' },
  '2218': { name: 'Ecuador', adj: 'Ecuadorian' },
  '2222': { name: 'El Salvador', adj: 'Salvadoran' },
  '2226': { name: 'Equatorial Guinea', adj: 'Equatorial Guinean' },
  '2231': { name: 'Ethiopia', adj: 'Ethiopian' },
  '2232': { name: 'Eritrea', adj: 'Eritrean' },
  '2233': { name: 'Estonia', adj: 'Estonian' },
  '2242': { name: 'Fiji', adj: 'Fijian' },
  '2246': { name: 'Finland', adj: 'Finnish' },
  '2250': { name: 'France', adj: 'French' },
  '2258': { name: 'French Polynesia', adj: 'French Polynesian' },
  '2260': { name: 'French Southern and Antarctic Lands', adj: '(No standard adjective)' },
  '2262': { name: 'Djibouti', adj: 'Djiboutian' },
  '2266': { name: 'Gabon', adj: 'Gabonese' },
  '2268': { name: 'Georgia', adj: 'Georgian' },
  '2270': { name: 'The Gambia', adj: 'Gambian' },
  '2276': { name: 'Germany', adj: 'German' },
  '2288': { name: 'Ghana', adj: 'Ghanaian' },
  '2296': { name: 'Kiribati', adj: 'I-Kiribati' },
  '2300': { name: 'Greece', adj: 'Greek' },
  '2308': { name: 'Grenada', adj: 'Grenadian' },
  '2316': { name: 'Guam', adj: 'Guamanian' },
  '2320': { name: 'Guatemala', adj: 'Guatemalan' },
  '2324': { name: 'Guinea', adj: 'Guinean' },
  '2328': { name: 'Guyana', adj: 'Guyanese' },
  '2332': { name: 'Haiti', adj: 'Haitian' },
  '2334': { name: 'Heard Island and McDonald Islands', adj: '(No standard adjective)' },
  '2336': { name: 'Vatican City', adj: 'Vatican' },
  '2340': { name: 'Honduras', adj: 'Honduran' },
  '2348': { name: 'Hungary', adj: 'Hungarian' },
  '2352': { name: 'Iceland', adj: 'Icelandic' },
  '2356': { name: 'India', adj: 'Indian' },
  '2360': { name: 'Indonesia', adj: 'Indonesian' },
  '2368': { name: 'Iraq', adj: 'Iraqi' },
  '2372': { name: 'Ireland', adj: 'Irish' },
  '2376': { name: 'Israel', adj: 'Israeli' },
  '2380': { name: 'Italy', adj: 'Italian' },
  '2384': { name: "Côte d'Ivoire", adj: 'Ivorian' },
  '2388': { name: 'Jamaica', adj: 'Jamaican' },
  '2392': { name: 'Japan', adj: 'Japanese' },
  '2398': { name: 'Kazakhstan', adj: 'Kazakhstani' },
  '2400': { name: 'Jordan', adj: 'Jordanian' },
  '2404': { name: 'Kenya', adj: 'Kenyan' },
  '2410': { name: 'South Korea', adj: 'South Korean' },
  '2414': { name: 'Kuwait', adj: 'Kuwaiti' },
  '2417': { name: 'Kyrgyzstan', adj: 'Kyrgyzstani' },
  '2418': { name: 'Laos', adj: 'Lao' },
  '2422': { name: 'Lebanon', adj: 'Lebanese' },
  '2426': { name: 'Lesotho', adj: 'Basotho' },
  '2428': { name: 'Latvia', adj: 'Latvian' },
  '2430': { name: 'Liberia', adj: 'Liberian' },
  '2434': { name: 'Libya', adj: 'Libyan' },
  '2438': { name: 'Liechtenstein', adj: 'Liechtensteiner' },
  '2440': { name: 'Lithuania', adj: 'Lithuanian' },
  '2442': { name: 'Luxembourg', adj: 'Luxembourger' },
  '2450': { name: 'Madagascar', adj: 'Malagasy' },
  '2454': { name: 'Malawi', adj: 'Malawian' },
  '2458': { name: 'Malaysia', adj: 'Malaysian' },
  '2462': { name: 'Maldives', adj: 'Maldivian' },
  '2466': { name: 'Mali', adj: 'Malian' },
  '2470': { name: 'Malta', adj: 'Maltese' },
  '2478': { name: 'Mauritania', adj: 'Mauritanian' },
  '2480': { name: 'Mauritius', adj: 'Mauritian' },
  '2484': { name: 'Mexico', adj: 'Mexican' },
  '2492': { name: 'Monaco', adj: 'Monégasque' },
  '2496': { name: 'Mongolia', adj: 'Mongolian' },
  '2498': { name: 'Moldova', adj: 'Moldovan' },
  '2499': { name: 'Montenegro', adj: 'Montenegrin' },
  '2504': { name: 'Morocco', adj: 'Moroccan' },
  '2508': { name: 'Mozambique', adj: 'Mozambican' },
  '2512': { name: 'Oman', adj: 'Omani' },
  '2516': { name: 'Namibia', adj: 'Namibian' },
  '2520': { name: 'Nauru', adj: 'Nauruan' },
  '2524': { name: 'Nepal', adj: 'Nepalese' },
  '2528': { name: 'Netherlands', adj: 'Dutch' },
  '2531': { name: 'Curaçao', adj: 'Curaçaoan' },
  '2534': { name: 'Sint Maarten', adj: 'Sint Maartener' },
  '2535': { name: 'Caribbean Netherlands', adj: 'Dutch Caribbean' },
  '2540': { name: 'New Caledonia', adj: 'New Caledonian' },
  '2548': { name: 'Vanuatu', adj: 'Ni-Vanuatu' },
  '2554': { name: 'New Zealand', adj: 'New Zealander' },
  '2558': { name: 'Nicaragua', adj: 'Nicaraguan' },
  '2562': { name: 'Niger', adj: 'Nigerien' },
  '2566': { name: 'Nigeria', adj: 'Nigerian' },
  '2570': { name: 'Niue', adj: 'Niuean' },
  '2574': { name: 'Norfolk Island', adj: 'Norfolk Islander' },
  '2578': { name: 'Norway', adj: 'Norwegian' },
  '2580': { name: 'Northern Mariana Islands', adj: 'Northern Marianan' },
  '2581': { name: 'Outlying Islands', adj: 'American' },
  '2583': { name: 'Micronesia', adj: 'Micronesian' },
  '2584': { name: 'Marshall Islands', adj: 'Marshallese' },
  '2585': { name: 'Palau', adj: 'Palauan' },
  '2586': { name: 'Pakistan', adj: 'Pakistani' },
  '2591': { name: 'Panama', adj: 'Panamanian' },
  '2598': { name: 'Papua New Guinea', adj: 'Papua New Guinean' },
  '2600': { name: 'Paraguay', adj: 'Paraguayan' },
  '2604': { name: 'Peru', adj: 'Peruvian' },
  '2608': { name: 'Philippines', adj: 'Filipino' },
  '2612': { name: 'Pitcairn Islands', adj: 'Pitcairn Islander' },
  '2616': { name: 'Poland', adj: 'Polish' },
  '2620': { name: 'Portugal', adj: 'Portuguese' },
  '2624': { name: 'Guinea-Bissau', adj: 'Bissau-Guinean' },
  '2626': { name: 'Timor-Leste', adj: 'East Timorese' },
  '2634': { name: 'Qatar', adj: 'Qatari' },
  '2642': { name: 'Romania', adj: 'Romanian' },
  '2643': { name: 'Russia', adj: 'Russian' },
  '2646': { name: 'Rwanda', adj: 'Rwandan' },
  '2652': { name: 'Saint Barthélemy', adj: 'Barthélemois' },
  '2654': { name: 'Saint Helena, Ascension and Tristan da Cunha', adj: 'Saint Helenian' },
  '2659': { name: 'Saint Kitts and Nevis', adj: 'Kittitian and Nevisian' },
  '2662': { name: 'Saint Lucia', adj: 'Saint Lucian' },
  '2663': { name: 'Saint Martin', adj: 'Saint-Martinoise' },
  '2666': { name: 'Saint Pierre and Miquelon', adj: 'Saint-Pierrais' },
  '2670': { name: 'Saint Vincent and the Grenadines', adj: 'Vincentian' },
  '2674': { name: 'San Marino', adj: 'Sammarinese' },
  '2678': { name: 'São Tomé and Príncipe', adj: 'São Toméan' },
  '2682': { name: 'Saudi Arabia', adj: 'Saudi' },
  '2686': { name: 'Senegal', adj: 'Senegalese' },
  '2688': { name: 'Serbia', adj: 'Serbian' },
  '2690': { name: 'Seychelles', adj: 'Seychellois' },
  '2694': { name: 'Sierra Leone', adj: 'Sierra Leonean' },
  '2702': { name: 'Singapore', adj: 'Singaporean' },
  '2703': { name: 'Slovakia', adj: 'Slovak' },
  '2704': { name: 'Vietnam', adj: 'Vietnamese' },
  '2705': { name: 'Slovenia', adj: 'Slovenian' },
  '2706': { name: 'Somalia', adj: 'Somali' },
  '2710': { name: 'South Africa', adj: 'South African' },
  '2716': { name: 'Zimbabwe', adj: 'Zimbabwean' },
  '2724': { name: 'Spain', adj: 'Spanish' },
  '2728': { name: 'South Sudan', adj: 'South Sudanese' },
  '2736': { name: 'Sudan', adj: 'Sudanese' },
  '2740': { name: 'Suriname', adj: 'Surinamese' },
  '2748': { name: 'Eswatini', adj: 'Swazi' },
  '2752': { name: 'Sweden', adj: 'Swedish' },
  '2756': { name: 'Switzerland', adj: 'Swiss' },
  '2762': { name: 'Tajikistan', adj: 'Tajikistani' },
  '2764': { name: 'Thailand', adj: 'Thai' },
  '2768': { name: 'Togo', adj: 'Togolese' },
  '2772': { name: 'Tokelau', adj: 'Tokelauan' },
  '2776': { name: 'Tonga', adj: 'Tongan' },
  '2780': { name: 'Trinidad and Tobago', adj: 'Trinidadian and Tobagonian' },
  '2784': { name: 'United Arab Emirates', adj: 'Emirati' },
  '2788': { name: 'Tunisia', adj: 'Tunisian' },
  '2792': { name: 'Türkiye', adj: 'Turkish' },
  '2795': { name: 'Turkmenistan', adj: 'Turkmen' },
  '2798': { name: 'Tuvalu', adj: 'Tuvaluan' },
  '2800': { name: 'Uganda', adj: 'Ugandan' },
  '2804': { name: 'Ukraine', adj: 'Ukrainian' },
  '2807': { name: 'North Macedonia', adj: 'Macedonian' },
  '2818': { name: 'Egypt', adj: 'Egyptian' },
  '2826': { name: 'United Kingdom', adj: 'British' },
  '2831': { name: 'Guernsey', adj: 'Channel Islander' },
  '2832': { name: 'Jersey', adj: 'Channel Islander' },
  '2833': { name: 'Isle of Man', adj: 'Manx' },
  '2834': { name: 'Tanzania', adj: 'Tanzanian' },
  '2840': { name: 'the United States', adj: 'American' },
  '2854': { name: 'Burkina Faso', adj: 'Burkinabé' },
  '2858': { name: 'Uruguay', adj: 'Uruguayan' },
  '2860': { name: 'Uzbekistan', adj: 'Uzbekistani' },
  '2862': { name: 'Venezuela', adj: 'Venezuelan' },
  '2876': { name: 'Wallis and Futuna', adj: 'Wallisian and Futunan' },
  '2882': { name: 'Samoa', adj: 'Samoan' },
  '2887': { name: 'Yemen', adj: 'Yemeni' },
  '2894': { name: 'Zambia', adj: 'Zambian' },
};

export const getGoogleCountry = (id: string): { name: string; adj: string } | undefined => {
  return mappings[id] as { name: string; adj: string } | undefined;
};
