import { FC } from 'react';

import { Box } from '@mui/material';

import { CompanyActionsButtons } from '../CompanyActionsButtons';
import { styles } from './styles';

interface MobileCompanyActionsButtonsProps {
  isCreateChannelLoading: boolean;
  isRequestButtons: boolean;
  onRequestQuoteClick?: () => void;
  onSendMessageClick?: () => void;
}

export const MobileCompanyActionsButtons: FC<MobileCompanyActionsButtonsProps> = ({
  isCreateChannelLoading,
  isRequestButtons,
  onRequestQuoteClick,
  onSendMessageClick,
}) => {
  return (
    <Box sx={styles.wrap}>
      <CompanyActionsButtons
        isCreateChannelLoading={isCreateChannelLoading}
        isRequestButtons={isRequestButtons}
        onRequestQuoteClick={onRequestQuoteClick}
        onSendMessageClick={onSendMessageClick}
      />
    </Box>
  );
};
