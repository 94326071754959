import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: (isFullHeight: boolean, isBuyer: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: isFullHeight ? 'calc(100vh - 160px)' : '227px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      ...(isFullHeight
        ? {
            height: `calc(100svh - ${isBuyer ? '102px' : '54px'})`,
          }
        : {
            height: '400px',
          }),
      boxSizing: 'border-box',
    },
  }),
};
