import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { OrderPaymentInfo } from 'app/api/orders';
import { getPaymentInfoDisplayValues, getPaymentInfoToCopy } from '../../utils';
import { PaymentInfoHeader } from './PaymentInfoHeader';
import { CopyButton } from './CopyButton';
import { InfoList } from './InfoList';
import { styles } from './styles';

type PaymentInfoProps = OrderPaymentInfo;

export const PaymentInfo: FC<PaymentInfoProps> = (info) => {
  const data = getPaymentInfoDisplayValues(info);
  const copyText = getPaymentInfoToCopy(data);

  return (
    <Box sx={{ ...styles.wrap }}>
      <PaymentInfoHeader />
      <Box sx={{ ...styles.subheader }}>
        <Typography sx={{ ...styles.description(true) }}>When you’re ready, send payment to:</Typography>
        <CopyButton copyText={copyText} />
      </Box>
      <InfoList data={data} />
    </Box>
  );
};
