import { SxProps } from '@mui/material';

import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  title: {
    marginBottom: '24px',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,
  },
  buttonsWrap: (isFormError: boolean): SxProps => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
    ...(isFormError && {
      marginTop: '32px',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '4px',
    },
  }),
  forgotPasswordButton: {
    marginRight: '-16px',
    color: customTheme.custom.black.B70,
    whiteSpace: 'nowrap',
  },
  loginButton: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '120px',
      minWidth: '88px',
      padding: '6px 10px',
    },
  },
};
