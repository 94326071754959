import { FC, ReactNode } from 'react';

import { MenuItem, SxProps, Typography } from '@mui/material';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { styles } from './styles';

interface UserMenuItemProps {
  text: string;
  icon: ReactNode;
  menuAction: () => void;
  wrapStyles?: SxProps;
  endIcon?: ReactNode;
}

export const UserMenuItem: FC<UserMenuItemProps> = ({ text, icon, wrapStyles, menuAction, endIcon }) => {
  return (
    <MenuItem sx={{ ...wrapStyles, ...styles.menuItemWrap } as SxPropsTypes} onClick={menuAction}>
      {icon}
      <Typography sx={{ ...styles.menuItemText }}>{text}</Typography>
      {endIcon}
    </MenuItem>
  );
};
