import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridRow: 1,
      position: 'relative',
    },
  },
  metaWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '2px',
  },
  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,
    whiteSpace: 'nowrap',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  date: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
    letterSpacing: '0.2px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      whiteSpace: 'nowrap',
    },
  },
  rightWrap: {
    display: 'flex',
    gap: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      alignItems: 'stretch',
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
  tag: {
    marginTop: '4px',
    padding: '6px',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.info,
    letterSpacing: '0.2px',
    background: customTheme.custom.blue.B50,
    borderRadius: '3px',
    whiteSpace: 'nowrap',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      margin: 0,
      textAlign: 'center',
      flexGrow: 1,
      maxWidth: '84px',
      maxHeight: '34px',
      alignSelf: 'center',
      whiteSpace: 'pre-wrap',
    },
  },
  chatButton: {
    display: 'none',
    gap: '4px',
    minWidth: 'fit-content',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'flex',
      padding: '4px 10px',
      height: 'fit-content',
      minHeight: '34px',
      boxSizing: 'border-box',
    },
    whiteSpace: 'nowrap',
    marginLeft: '8px',
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'start',
    gap: '20px',
  },
  trackingButtonWrap: {
    display: 'block',
    marginRight: '12px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
