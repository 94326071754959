import { customTheme } from '../../../../theme/MuiThemeProvider/theme';
import { SxPropsTypes } from '../../../../theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  content: {
    display: 'grid',
    gap: '12px',
    marginBottom: '32px',
  },
  title: {
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,
  },
  description: {
    fontSize: '14px',
    lineHeight: '21px',
    color: customTheme.custom.black.B70,
  },
  button: {
    padding: '6px 27px',
  },
};
