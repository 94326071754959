import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { selectIsAnonymousUser, setSignupModal } from 'state/slices/account';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { CommonTooltip } from 'components';

import { styles } from './styles';

interface KeyStatItemProps {
  name: string;
  value: string;
}

export const KeyStatItem: FC<KeyStatItemProps> = ({ name, value }) => {
  const isAnonymousUser = useAppSelector(selectIsAnonymousUser);
  const dispatch = useAppDispatch();
  const onClickUnlock = () => dispatch(setSignupModal({ isOpen: true, state: 'welcome', source: 'unlock product' }));
  return (
    <Box sx={{ ...styles.wrap }}>
      <Typography sx={{ ...styles.title }}>{name}</Typography>
      <Typography sx={{ ...styles.value }}>
        {isAnonymousUser && name.toLowerCase() === 'name' ? (
          <Box onClick={onClickUnlock}>
            <CommonTooltip sx={styles.tooltipWrap} title="Request free access to unlock" placement="bottom-start">
              <Box sx={styles.unlock}>
                Unlock
                <InfoIcon />
              </Box>
            </CommonTooltip>
          </Box>
        ) : (
          value
        )}
      </Typography>
    </Box>
  );
};
