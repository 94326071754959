import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  content: {
    maxWidth: '370px',
    marginBottom: '16px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,
  },

  actions: { display: 'flex', justifyContent: 'flex-end', margin: '0 -14px -8px 0' },
  close: {
    minWidth: '80px',
    marginRight: '8px',
  },
  confirm: {
    minWidth: '80px',
  },
};
