import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  content: {
    maxWidth: '370px',
    marginBottom: '28px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '16px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -14px -8px 0',
  },
  closeButton: {
    minWidth: '80px',
    marginRight: '8px',
    color: customTheme.custom.black.B70,
  },
  confirmButton: {
    minWidth: '80px',
    color: customTheme.custom.success,
  },
};
