import { ChangeEvent, FC } from 'react';

import { Control, Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { CommonCheckbox, FilterItem } from 'components';
import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import { styles } from './styles';

interface CheckboxOptionsWrapProps {
  data: CheckboxFilterItem[];
  defaultFilter: CheckboxFilterItem;
  control: Control;
  controlName: string;
  applyFilterControlName?: string;
  applyFilterLabel?: string;
  isApplyFilterCheckBox?: boolean;
  handleApplyFilterCheckboxChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  resetFilters: (isAll: boolean) => void;
}

export const CheckboxOptionsWrap: FC<CheckboxOptionsWrapProps> = ({
  data,
  defaultFilter,
  control,
  controlName,
  applyFilterControlName,
  applyFilterLabel,
  isApplyFilterCheckBox,
  handleApplyFilterCheckboxChange,
  resetFilters,
}) => {
  return (
    <Box sx={styles.wrap}>
      {data.map((item, index) => (
        <FilterItem
          key={item.label}
          control={control}
          controlName={`${controlName}[${index}]`}
          defaultValue={defaultFilter.value}
          label={item.label}
          value={item.value}
          resetFilters={resetFilters}
        />
      ))}
      {isApplyFilterCheckBox && applyFilterControlName && (
        <Controller
          name={applyFilterControlName}
          control={control}
          render={({ field: props }) => (
            <Box sx={styles.applyFilterWrap}>
              <CommonCheckbox
                {...props}
                ref={null}
                checked={!!props.value}
                onChange={handleApplyFilterCheckboxChange}
              />
              <Typography component="span" sx={styles.applyFilterLabel}>
                {applyFilterLabel}
              </Typography>
            </Box>
          )}
        />
      )}
    </Box>
  );
};
