import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isSelected: boolean) => ({
    padding: '14px',
    display: 'flex',
    gap: '20px',
    cursor: isSelected ? 'default' : 'pointer',

    background: isSelected ? customTheme.palette.secondary.S20 : customTheme.custom.mainBg,

    ':hover': {
      background: isSelected ? customTheme.palette.secondary.S20 : customTheme.custom.hover,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '12px 14px',
      gap: '16px',
      background: customTheme.custom.mainBg,
    },
  }),
  logoWrap: {
    width: '52px',
    height: '56px',
    display: 'flex',
    alignItems: 'center',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '44px',
      height: '48px',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    flexGrow: 1,
  },
  logo: {
    width: '52px',
    maxHeight: '56px',
    borderRadius: '6px',
    objectFit: 'contain',
  },
  logoPlaceholder: {
    borderRadius: '50%',
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: customTheme.custom.black.B10,

    img: {
      width: '26px',
      maxWidth: '26px',
    },
  },
  header: {
    maxHeight: '21px',
    display: 'flex',
    gap: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    flexGrow: 1,
  },
  name: (isMuted: boolean) => ({
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 700,
    color: isMuted ? customTheme.custom.black.B50 : customTheme.custom.black.B100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  dateWrap: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
  },
  date: (isGreen: boolean) => ({
    fontSize: '10px',
    lineHeight: '10px',
    color: isGreen ? customTheme.palette.secondary.S100 : customTheme.custom.black.B50,
    whiteSpace: 'nowrap',
  }),
  day: {
    fontWeight: 700,
  },
  time: {
    fontWeight: 400,
  },
  contentInner: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  message: (isBold: boolean, isMuted: boolean) => ({
    fontSize: '12px',
    fontWeight: isBold ? 700 : 400,
    lineHeight: '21px',
    color: isMuted ? customTheme.custom.black.B50 : customTheme.custom.black.B100,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
  }),
  badgeWrap: {
    minWidth: '18px',
    height: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  badge: (isMuted: boolean) => ({
    '&.MuiBadge-root': {
      display: 'block',
    },

    '.MuiBadge-badge': {
      minWidth: '12px',
      height: '16px',
      padding: '0 2px',
      boxSizing: 'content-box',
      fontFamily: 'inherit',
      fontWeight: 600,
      fontSize: '10px',
      color: customTheme.custom.white,
      border: `1.5px solid ${customTheme.custom.white}`,
      backgroundColor: isMuted ? customTheme.custom.gray.G100 : customTheme.palette.secondary.S100,
    },

    '.MuiBadge-invisible': {
      display: 'none',
    },
  }),
  iconsWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
};
