import { FC } from 'react';

import { Control } from 'react-hook-form';
import { Typography } from '@mui/material';

import { NumberFormField } from 'components/NumberFormField';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { QuoteTableFormData } from '../../types';
import { validationRules } from '../../utils/validationRules';
import { styles } from './styles';

export interface CounterOfferCellProps {
  isConfirm: boolean | undefined;
  isDisabled: boolean;
  control: Control<QuoteTableFormData>;
  index: number;
}

export const CounterOfferCell: FC<CounterOfferCellProps> = ({ isConfirm, isDisabled, control, index }): JSX.Element => {
  return (
    <>
      {isConfirm && <Typography sx={{ ...styles.priceConfirmed }}>Price Confirmed</Typography>}
      {!isConfirm && (
        <NumberFormField
          wrapStyles={{ ...styles.amountField } as SxPropsTypes}
          inputStyles={{ ...styles.amountFieldInput } as SxPropsTypes}
          control={control}
          decimalScale={2}
          name={`quotes.${index}.value`}
          placeholder="Amount"
          rules={{ ...(!isConfirm && { ...validationRules.price }) }}
          withoutHighlights
          disabled={isDisabled}
        />
      )}
    </>
  );
};
