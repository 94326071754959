import { FC } from 'react';

import { Box, CircularProgress, Typography } from '@mui/material';

import { ReactComponent as CleanIcon } from 'assets/icons/clean.svg';
import { styles } from './styles';

interface UploadedFilesListProps {
  isRemoveDisabled: boolean;
  uploadedFiles: Record<'id' | 'file', string | File>[];
  isMultiLines?: boolean;
  handleRemoveFile: (index: number) => void;
}

export const UploadedFilesList: FC<UploadedFilesListProps> = ({
  isRemoveDisabled,
  uploadedFiles,
  isMultiLines = false,
  handleRemoveFile,
}) => {
  const isSecondLine = isMultiLines && uploadedFiles.length > 3;

  return (
    <Box sx={{ ...styles.filesWrap(isMultiLines, isSecondLine) }}>
      {uploadedFiles.map((data, index) => (
        <Box sx={{ ...styles.fileBox }} key={(data.file as File).name}>
          <Typography sx={{ ...styles.fileName }}>{(data.file as File).name}</Typography>
          {isRemoveDisabled ? (
            <CircularProgress size={16} />
          ) : (
            <Box sx={{ ...styles.cleanIcon }} onClick={() => handleRemoveFile(index)}>
              <CleanIcon />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
