import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  title: (titleColor: string) => ({
    color: titleColor,
  }),
  subTitle: (titleColor: string) => ({
    margin: '-18px 0 18px',
    fontSize: '12px',
    lineHeight: '12px',
    color: titleColor,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '10px',
    },
  }),
  paperStyles: {
    minWidth: '588px',
    minHeight: '360px',
  },
  mobilePaperStyles: {
    minHeight: '360px',
    maxHeight: '75%',
  },
  filesList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 180px))',
    columnGap: '24px',
    rowGap: '34px',
    overflowY: 'auto',
    overflowX: 'hidden',
    ...scrollMixin,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '32px 20px',
      gridTemplateColumns: '1fr 1fr',
      marginRight: '-12px',
      paddingRight: '8px',
    },

    [`@media (max-width: 360px)`]: {
      columnGap: '12px',
    },
  },
  loader: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flex: '1',
  },
};
