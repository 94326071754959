import { FC } from 'react';

import { IconButton } from '@mui/material';

import { ReactComponent as CrossIcon } from 'assets/icons/close.svg';
import { styles } from './styles';

interface CloseDrawerButtonProps {
  position?: 'right' | 'left';
  handleClose: () => void;
}

export const CloseDrawerButton: FC<CloseDrawerButtonProps> = ({ position = 'right', handleClose }) => {
  const isLeftPosition = position === 'left';

  return (
    <IconButton sx={styles.closeButton(isLeftPosition)} onClick={handleClose}>
      <CrossIcon />
    </IconButton>
  );
};
