import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paper: {
    width: '588px',
    height: '396px',
  },
  mobilePaper: {
    width: '100%',
    height: '320px',
  },
  wrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 600,
  },
  titleMain: {
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  titleSmall: {
    color: customTheme.custom.black.B100,
    textAlign: 'center',
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      marginTop: '-24px',
    },
  },
  link: {
    color: customTheme.palette.primary.P100,

    ':hover': {
      color: customTheme.palette.primary.P70,
      cursor: 'pointer',
    },
  },
  subTitle: {
    color: customTheme.custom.black.B70,
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '0.2px',
  },
  draggableWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '14px',
  },
  fileInput: {
    display: 'none',
  },
};
