import { SxProps } from '@mui/material';
import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  title: {
    marginBottom: '24px',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '34px',
    color: customTheme.custom.black.B100,
  },
  button: (isFormError: boolean): SxProps => ({
    marginTop: '8px',
    ...(isFormError && {
      marginTop: '32px',
    }),
  }),
};
