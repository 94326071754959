import { scrollMixin } from 'theme/MuiThemeProvider/mixins';

export const styles = {
  listWrap: {
    height: '150px',
    maxHeight: '150px',
    padding: '0 12px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column-reverse',
    ...scrollMixin,
  },
  loaderWrap: (hasHeight: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0',
    height: `${hasHeight ? '36px' : 'auto'}`,
  }),
  mainLoaderWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
  },
  infinityScroll: {
    display: 'flex',
    flexDirection: 'column-reverse',
    overflow: 'hidden',
  },
};
