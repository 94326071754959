import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { useAuth, useModal } from 'hooks';
import { ReactComponent as EmptyIcon } from 'assets/icons/noResult.svg';
import { CommonButton } from 'components';
import { RequestProductModal } from 'components/RequestProductModal';
import { companyNames } from './companyNames';
import { styles } from './styles';

const messages = {
  search: {
    title: 'No products found',
    sub: "We didn't find any matches for your request.",
  },
  company: {
    title: 'Search by company unavailable',
    sub: 'Please login to view',
  },
  cantFind: "Can't find a product?",
  requestProduct: 'Request a product',
};

export const EmptyState: FC<{ query?: string }> = ({ query }) => {
  const { activeUser } = useAuth();
  const isLoggedIn = !!activeUser;

  const isCompanySearch = Boolean(!isLoggedIn && query && companyNames.has(query.toLowerCase()));
  const { open, handleOpenModal, handleCloseModal } = useModal();

  const onClickCantFind = () => {
    handleOpenModal();
    const nextfield = document.querySelector(`textarea[name=msgBody]`) as HTMLInputElement;
    if (nextfield !== null && 'focus' in nextfield) {
      nextfield.focus();
    }
  };
  const descriptionText = isCompanySearch ? messages.company : messages.search;

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.notFoundContainer}>
          <EmptyIcon />
          <Box sx={styles.description(isCompanySearch)}>
            <Typography sx={styles.title}>{descriptionText.title}</Typography>
            <Typography sx={styles.text}>{descriptionText.sub}</Typography>
          </Box>
        </Box>

        <Box sx={styles.bottomSection}>
          <Typography sx={styles.cantFind}>{messages.cantFind}</Typography>
          <CommonButton onClick={onClickCantFind} sx={styles.requestProductBtn}>
            {messages.requestProduct}
          </CommonButton>
          <RequestProductModal isOpen={open} handleClose={handleCloseModal} />
        </Box>
      </Box>
    </>
  );
};
