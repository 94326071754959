import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  slider: {
    padding: 0,
    width: 'calc(100% - 21px)',
  },
  styles: {
    track: {
      top: '50%',
      transform: 'translateY(-50%)',
      borderTop: 0,
      borderBottom: 0,
      backgroundColor: customTheme.palette.primary.P100,
      borderRadius: 0,
    },
    rail: {
      width: 'calc(100% + 21px)',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '2px',
      backgroundColor: customTheme.custom.black.B20,
      opacity: 1,
    },
    handle: {
      margin: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      width: '21px',
      height: '21px',
      border: 0,
      backgroundColor: customTheme.palette.primary.P100,
      opacity: 1,
    },
  },
};
