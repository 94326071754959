import { FC } from 'react';

import { Box } from '@mui/material';

import { useDeviceType } from 'hooks';
import { styles } from './styles';

interface MapWrapperProps {
  isImage: boolean;
  src: string;
  handleImageError: () => void;
}

export const MapWrapper: FC<MapWrapperProps> = ({ isImage, src, handleImageError }) => {
  const { isMobileSize } = useDeviceType();

  return (
    <>
      {isImage ? (
        <Box component="img" src={src} sx={styles.image} onError={handleImageError} />
      ) : (
        <iframe
          title={`${src}-datawrapper-chart`}
          id="datawrapper-chart-G7Pvw"
          src={src}
          data-external="1"
          style={styles.iframe(isMobileSize)}
          scrolling="no"
          frameBorder="0"
        />
      )}
    </>
  );
};
