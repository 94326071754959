import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { useDeviceType, useAuth } from 'hooks';

export const ProtectedMobileRoute = () => {
  const { isMobileSize } = useDeviceType();
  const { isBuyer } = useAuth();

  const defaultRoute = isBuyer ? ROUTES.buyer._ : ROUTES.seller.dashboard._;

  if (!isMobileSize) {
    return <Navigate to={defaultRoute} />;
  }

  return <Outlet />;
};
