import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paperStyles: {},
  text: {
    textAlign: 'left',
    padding: '2px 0px 16px',
    fontSize: '18px',
    fontWeight: 500,
    fontFamily: customTheme.typography.allVariants.fontFamily,
  },
  btns: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  confirm: {
    marginLeft: '8px',
  },
};
