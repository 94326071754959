import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { UnitsType } from 'app/api/quotes/types';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { currencyFormat } from 'utils/currencyFormat';
import { getVarietyValue } from 'utils/getVarietyValue';
import { styles } from './styles';

interface OrderCropsListItemProps {
  cropName: string;
  price: number | null | undefined;
  name: string | null | undefined;
  variety: string | undefined;
  amount: number;
  unitsType: UnitsType;
}

export const OrderCropsListItem: FC<OrderCropsListItemProps> = ({
  cropName,
  name,
  price,
  variety,
  amount,
  unitsType,
}): JSX.Element => {
  const varietyValue = getVarietyValue({
    crop: cropName,
    variety,
    name,
  });

  const unitsDisplayValue = getUnitDisplayValue(unitsType, true);

  const isThousandSeedsUnit = unitsType === UnitsType.THOUSAND_SEEDS;

  return (
    <Box sx={{ ...styles.listItemWrap }}>
      <Box sx={{ ...styles.listItemRow }}>
        <Typography sx={{ ...styles.info }}>{cropName}</Typography>
        <Typography sx={{ ...styles.info, ...styles.rightColumn(isThousandSeedsUnit) }}>
          {price ? `${currencyFormat(price)} per ${unitsDisplayValue}` : ''}
        </Typography>
      </Box>

      <Box sx={{ ...styles.listItemRow }}>
        <Typography sx={{ ...styles.variety, ...styles.product }}>
          {varietyValue}
          {variety && '\u00A0'}
          {name && (
            <Typography sx={() => ({ ...styles.variety, ...styles.name })} component="span">
              {name}
            </Typography>
          )}
        </Typography>
        <Typography sx={{ ...styles.variety, ...styles.rightColumn(isThousandSeedsUnit) }}>
          {Math.round(amount)}{' '}
          <Typography component="span" sx={{ ...styles.variety, ...styles.unit(isThousandSeedsUnit) }}>
            {unitsDisplayValue}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};
