import { useQuery } from 'react-query';

import { getNotificationsTotalCount } from 'app/api/notifications';
import { NOTIFICATIONS_COUNT_QUERY_KEY, UPDATE_NOTIFICATIONS_INTERVAL } from 'layout/Notifications/constants';

export const useNotificationsTotalCountQuery = () => {
  const { data } = useQuery([NOTIFICATIONS_COUNT_QUERY_KEY], () => getNotificationsTotalCount(), {
    retry: true,
    refetchInterval: UPDATE_NOTIFICATIONS_INTERVAL,
  });

  return {
    count: data?.totalCount ?? 0,
  };
};
