import { FC } from 'react';

import { AuthMainUi } from '../../components/AuthMainUi';
import { ResetPasswordPageInner } from './components';

export const ResetPasswordPage: FC = () => {
  return (
    <AuthMainUi>
      <ResetPasswordPageInner />
    </AuthMainUi>
  );
};

export default ResetPasswordPage;
