import { SxProps } from '@mui/material';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (sx?: SxProps) => ({
    '& .MuiBadge-badge': {
      minWidth: '16px',
      height: '16px',
      position: 'relative',
      left: '-12px',
      margin: 0,
      padding: '0 4px',
      fontFamily: 'inherit',
      fontWeight: 600,
      fontSize: '10px',
      lineHeight: '14px',
      top: 'calc(4px - 50%)',
      color: customTheme.custom.white,
      backgroundColor: customTheme.palette.secondary.S100,
      ...sx,
    },
    '& .MuiBadge-invisible': {
      display: 'none',
    },
  }),
};
