import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '32px',
  },
  paperStyles: {
    backgroundColor: customTheme.custom.mainBg,
    width: '900px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    gap: '16px',
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    marginRight: '16px',
  },
  topContainer: {
    display: 'flex',
    gap: '8px',
  },
  imageSection: {
    width: '340px',
    height: '410px',
    display: 'flex',
    flexDirection: 'column',
  },
  generalSection: {
    flex: 3,
  },
  updateImage: {
    border: '1px solid black',
    padding: '8px',
    marginTop: '8px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: '500',
  },
  editTitle: {
    fontSize: '32px',
    fontWeight: '600',
  },
  inputContainer: {
    marginBottom: '8px',
  },
  dropzone: {
    flex: 1,
    minHeight: '200px',
    margin: '16px 0px 8px',
    border: '1px solid #E8E8E8',
    borderRadius: '8px',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: customTheme.custom.black.B5,
    cursor: 'pointer',
  },
  uploadText: {
    fontWeight: '600',
    marginTop: '16px',
  },
  imagePreview: {
    borderRadius: '10px',
    maxWidth: '300px',
    maxHeight: '286px',
    objectFit: 'contain',
  },
  attributeHeader: {
    width: '100%',
    fontSize: '16px',
    color: customTheme.palette.primary.main,
    fontWeight: 500,
    margin: '8px 0px',
  },
};
