import { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { SxPropsTypes } from '../../theme/MuiThemeProvider/types';

import { styles } from './styles';

export const CommonButton: FC<ButtonProps> = ({ children, sx, ...props }) => (
  <Button variant="contained" sx={{ ...styles.button, ...(sx as SxPropsTypes) }} {...props}>
    {children}
  </Button>
);
