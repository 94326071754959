import { FC } from 'react';

import { DashboardMainTabs, QuoteRequestsListData } from 'app/api/quotes/types';
import { OrdersListData } from 'app/api/orders/types';
import { DashboardTab } from '../../types';
import { SampleRequestsPageInner } from '../SampleRequestsPageInner';
import { QuoteRequestsTabInner } from '../QuoteRequestsTabInner';
import { OrdersTabInner } from '../OrdersTabInner';

interface DashboardPageInnerProps {
  isBuyer: boolean;
  activeMainTab: DashboardTab;
  quoteRequestsData: {
    list: QuoteRequestsListData;
    canceledList: QuoteRequestsListData;
  };
  ordersData: {
    list: OrdersListData;
    canceledList: OrdersListData;
  };
}

export const DashboardPageInner: FC<DashboardPageInnerProps> = ({
  isBuyer,
  activeMainTab,
  quoteRequestsData,
  ordersData,
}) => {
  if (activeMainTab.path === DashboardMainTabs.SAMPLE_REQUESTS) {
    return <SampleRequestsPageInner isBuyer={isBuyer} quoteRequestsData={quoteRequestsData} ordersData={ordersData} />;
  }

  return (
    <>
      {activeMainTab.path === DashboardMainTabs.QUOTE_REQUESTS ? (
        <QuoteRequestsTabInner isBuyer={isBuyer} tab={activeMainTab.path} data={quoteRequestsData} />
      ) : (
        <OrdersTabInner isBuyer={isBuyer} tab={activeMainTab.path} data={ordersData} />
      )}
    </>
  );
};
