import { FC } from 'react';

import { Member } from '@sendbird/chat/lib/__definition';
import { Box } from '@mui/material';

import { ConversationInfoInner } from 'pages/MessagesPage/components/ConversationInfoInner';
import { CommonChatDrawer } from '../CommonChatDrawer';
import { styles } from './styles';

interface InfoPannelProps {
  isOpen: boolean;
  currentChannelId: string;
  currentCompanyMembers: Member[];
  membersCount: number;
  otherCompanyName: string;
  otherCompanyMembers: Member[];
  currentCompanyName?: string;
  handleClose: () => void;
}

export const InfoPannel: FC<InfoPannelProps> = ({
  isOpen,
  currentChannelId,
  currentCompanyMembers,
  membersCount,
  otherCompanyMembers,
  otherCompanyName,
  currentCompanyName,
  handleClose,
}) => {
  return (
    <CommonChatDrawer isOpen={isOpen} handleClose={handleClose}>
      <Box sx={styles.wrap}>
        <ConversationInfoInner
          collapsed={!isOpen}
          currentCompanyMembers={currentCompanyMembers}
          currentCompanyName={currentCompanyName}
          membersCount={membersCount}
          otherCompanyName={otherCompanyName}
          otherCompanyMembers={otherCompanyMembers}
          currentChannelId={currentChannelId}
          handleToggle={handleClose}
        />
      </Box>
    </CommonChatDrawer>
  );
};
