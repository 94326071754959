import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    outline: `1px solid ${customTheme.custom.black.B10}`,
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '10px',
  },
  quoteTerms: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  incoterms: {},
  incotermsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  incotermLabel: {
    fontSize: '16px',
    fontWeight: 500,
  },
  incotermBuyerVal: {
    fontSize: '16px',
    fontWeight: 500,
    color: customTheme.palette.primary.main,
  },
};
