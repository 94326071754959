import { useCallback, useEffect, useMemo, useState } from 'react';

import { FileMessage } from '@sendbird/chat/lib/__definition';

const ITEMS_PER_FIRST_PAGE = 4;
const ITEMS_PER_PAGE = 10;

interface UseFilesListProps {
  attachments: FileMessage[];
}

export const useFilesList = ({ attachments }: UseFilesListProps) => {
  const [visibleAttachments, setVisibleAttachments] = useState<FileMessage[]>([]);

  useEffect(() => {
    if (attachments.length) {
      setVisibleAttachments(attachments.slice(0, ITEMS_PER_FIRST_PAGE));
    }
  }, [attachments]);

  const isShowMoreTopButton = attachments.length > 4;
  const isHideButton = useMemo(() => visibleAttachments.length > 4, [visibleAttachments.length]);
  const isShowMoreBottomButton = visibleAttachments.length > 4 && visibleAttachments.length < attachments.length;

  const handleShowMore = useCallback(() => {
    const currentlyVisible = visibleAttachments.length;
    const nextVisibleItems = attachments.slice(0, currentlyVisible + ITEMS_PER_PAGE);

    setVisibleAttachments(nextVisibleItems);
  }, [attachments, visibleAttachments.length]);

  const handleAttachmentsView = useCallback(() => {
    if (isHideButton) {
      setVisibleAttachments(attachments.slice(0, ITEMS_PER_FIRST_PAGE));
    } else {
      handleShowMore();
    }
  }, [attachments, handleShowMore, isHideButton]);

  return {
    isHideButton,
    isShowMoreBottomButton,
    isShowMoreTopButton,
    visibleAttachments,
    handleAttachmentsView,
    handleShowMore,
  };
};
