import { useCallback } from 'react';

import { Control, useFieldArray } from 'react-hook-form';

import { QuoteTableFormData } from '../types';

export const useDiscussionTable = (control: Control<QuoteTableFormData>, decreaseConfirmedProducsCount: () => void) => {
  const {
    fields: choosedProducts,
    remove,
    append,
  } = useFieldArray<QuoteTableFormData, 'quotes', 'listId'>({
    control,
    name: 'quotes',
    keyName: 'listId',
  });

  const removeChoosedProducts = useCallback(
    (index: number) => {
      remove(index);
      decreaseConfirmedProducsCount();
    },
    [remove, decreaseConfirmedProducsCount],
  );

  return {
    choosedProducts,
    removeChoosedProducts,
    removeQuoteProductRow: remove,
    appendQuoteProductRow: append,
  };
};
