import { UserData } from '../app/api/user/types';

type StoragesKey = 'localStorage' | 'sessionStorage';

const Storages = {
  localStorage,
  sessionStorage,
};

interface PersistentStorage {
  setItem(key: string, value: unknown, storage?: StoragesKey): void;
  getItem<T = string>(key: string, storage?: StoragesKey): T | null;
  deleteItem(key: string, storage?: StoragesKey): void;
  deleteAll(storage?: StoragesKey): void;
}

export const storage: PersistentStorage = {
  setItem: (key: string, value: unknown, storage: StoragesKey = 'localStorage') => {
    if (value === undefined) {
      Storages[storage].removeItem(key);
    } else {
      Storages[storage].setItem(key, JSON.stringify(value));
    }
  },
  getItem: <T>(key: string, storage: StoragesKey = 'localStorage'): T | null => {
    const item = Storages[storage].getItem(key);

    if (item === null) return null;

    if (item === 'null') return null;
    if (item === 'undefined') return null;

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(item);
      // eslint-disable-next-line no-empty
    } catch {}

    return item as T;
  },
  deleteItem: (key: string, storage: StoragesKey = 'localStorage') => {
    Storages[storage].removeItem(key);
  },
  deleteAll: (storage: StoragesKey = 'localStorage') => Storages[storage].clear(),
};

export const updateSessionToken = (token: string): void => {
  storage.setItem('token', token);
};

export const updateSession = (activeUser: UserData | null): void => {
  storage.setItem('userRole', activeUser?.kind);
  storage.setItem('userId', activeUser?.id, 'sessionStorage');
};

const LOGIN_PATH_KEY = 'loginPath';

const keysToKeep = ['cookiePermissions', LOGIN_PATH_KEY];

export const removeSession = (): void => {
  const keepers = keysToKeep.reduce((acc, key) => {
    const val = storage.getItem(key);
    if (val !== null) {
      return { ...acc, key: val };
    }
    return acc;
  }, {});
  storage.deleteAll();
  storage.deleteAll('sessionStorage');
  Object.entries(keepers).forEach(([key, val]) => {
    storage.setItem(key, val);
  });
};

export const getAccessInfo = (key: string): string | null => storage.getItem(key);

export const getActiveUserId = (): number | null => {
  return storage.getItem<number | null>('userId', 'sessionStorage');
};

export const setSendbirdUserId = (userId: string) => {
  storage.setItem('sendbirdUserId', userId);
};

export const setSendbirdToken = (token: string) => {
  storage.setItem('sendbirdToken', token);
};

export const clearSendbirdConfig = () => {
  storage.deleteItem('sendbirdUserId');
  storage.deleteItem('sendbirdToken');
};

export const getLoginPath = (): string | null => {
  return storage.getItem<string | null>(LOGIN_PATH_KEY);
};

export const setLoginPath = (path: string) => {
  storage.setItem(LOGIN_PATH_KEY, path);
};

export const clearLoginPath = () => {
  storage.deleteItem(LOGIN_PATH_KEY);
};

/**
 * Request Access Data the data that user contact information filled in during the request access / products
 * it is stored in the local storage and is used to prefill the form when user comes back
 * fields include name, email, phone, company name, etc.
 */
const requestAccessFieldsToSave = new Set(['name', 'phone', 'email', 'hear', 'country', 'companyName', 'businessType']);
const REQUEST_ACCESS_DATA_KEY = 'requestAccessData';
export const getRequestAccessData = () => {
  try {
    return JSON.parse(storage.getItem<string | null>(REQUEST_ACCESS_DATA_KEY) || '{}') as object;
  } catch {
    return {};
  }
};

export const setRequestAccessData = (items: object) => {
  const filteredItems = Object.keys(items).reduce((acc, key) => {
    if (requestAccessFieldsToSave.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      return { ...acc, [key]: (items as any)[key] };
    }
    return acc;
  }, {});

  storage.setItem(REQUEST_ACCESS_DATA_KEY, JSON.stringify(filteredItems));
};

/**
 * User business data is the data that user fills in during the request products form
 * it is stored in the local storage and is used to prefill the form when user comes back
 * fields include, preferred delivery date, business type, annual purchase volume, etc.
 */
const USER_BUSINESS_DATA_KEY = 'userBusinessData';
export const getUserBusinessData = () => {
  try {
    return JSON.parse(storage.getItem<string | null>(USER_BUSINESS_DATA_KEY) || '{}') as object;
  } catch {
    return {};
  }
};

const fieldsToSave = new Set([
  'businessAddress',
  'links',
  'hasLicense',
  'purchaseVolume',
  'preferredPayment',

  'deliveryLocation',
  'deliveryDate',
  'packagingType',
]);
export const setUserBusinessData = (items: object) => {
  const filteredItems = Object.keys(items).reduce((acc, key) => {
    if (fieldsToSave.has(key)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      return { ...acc, [key]: (items as any)[key] };
    }
    return acc;
  }, {});
  storage.setItem(USER_BUSINESS_DATA_KEY, JSON.stringify(filteredItems));
};
