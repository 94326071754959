export const cardItemTitles = (isBuyer: boolean) => ({
  amount: {
    title: 'Amount',
  },
  units: {
    title: 'Units',
  },
  quotePrice: {
    title: 'Quote',
    subtitle: `($ per unit)`,
  },
  quoteSubtotals: {
    title: 'Quote',
    subtitle: '(subtotals)',
  },
  myLastOfferPrice: {
    title: isBuyer ? 'Requested Price' : 'Last Offer',
    subtitle: `($ per unit)`,
  },
  latestOfferPrice: {
    title: isBuyer ? 'Seller Offer' : 'Buyer Request',
    subtitle: `($ per unit)`,
  },
  latestOfferSubtotals: {
    title: 'Quote Subtotal',
  },
  counterOfferPrice: {
    title: 'Counter Offer',
    subtitle: `($ per unit)`,
  },
});
