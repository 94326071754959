import { FC, ReactNode } from 'react';
import { Control, RegisterOptions, useController } from 'react-hook-form';

import { Box, FormControl, InputAdornment, Tooltip, Typography } from '@mui/material';
import { getSeparator } from 'utils/formatText';
import { NumericFormat } from 'react-number-format';
import { ReactComponent as ErrorIcon } from '../../assets/icons/errorSmall.svg';
import { styles } from './styles';
import { SxPropsTypes } from '../../theme/MuiThemeProvider/types';
import { CommonInput } from '../CommonInput';

export interface NumberFormFieldProps {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules?: Omit<RegisterOptions<any, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  placeholder?: string;
  shouldUnregister?: boolean;
  withoutError?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  tooltipElem?: ReactNode;
  wrapStyles?: SxPropsTypes;
  isFormError?: boolean;
  withoutHighlights?: boolean;
  inputStyles?: SxPropsTypes;
  multiline?: boolean;
  inputWrapStyles?: SxPropsTypes;
  maxLength?: number;
  rows?: number;
  minRows?: number;
  maxRows?: number;
  autoComplete?: string;
  disabled?: boolean;
  decimalScale?: number;
  onChange?: (num: number) => void;
}

export const NumberFormField: FC<NumberFormFieldProps> = ({
  name,
  rules,
  control,
  wrapStyles,
  inputStyles,
  isFormError,
  placeholder,
  tooltipElem,
  withoutError,
  startAdornment,
  endAdornment,
  shouldUnregister,
  withoutHighlights,
  multiline,
  inputWrapStyles,
  maxLength,
  rows,
  minRows,
  maxRows,
  autoComplete = 'off',
  disabled = false,
  onChange,
  decimalScale = 0,
}) => {
  const {
    field: { ref, ...inputProps },
    fieldState: { error, invalid },
  } = useController({
    name,
    rules,
    control,
    shouldUnregister,
  });

  const errorMessage = error?.message;

  return (
    <Box sx={{ ...styles.wrap, ...wrapStyles }}>
      <FormControl variant="outlined" sx={{ ...styles.control }}>
        <Tooltip
          disableHoverListener
          title={tooltipElem}
          componentsProps={{
            tooltip: {
              sx: styles.tooltip,
            },
          }}
          placement="bottom-end"
          enterTouchDelay={0}
        >
          <Box sx={{ ...styles.tooltipHelper }}>
            <NumericFormat
              customInput={CommonInput}
              inputRef={ref}
              sx={{
                ...styles.inputWrap(withoutHighlights),
                ...(isFormError && styles.inputWrapFormError),
                ...((invalid || !!errorMessage) && styles.inputWrapError),
                input: { ...styles.input(!!startAdornment), ...inputStyles },
                adornedStart: { ...styles.startAdornment },
                adornedEnd: { ...styles.endAdornment },
                ...inputWrapStyles,
              }}
              inputProps={{ ...(maxLength && { maxLength }) }}
              placeholder={placeholder}
              multiline={multiline}
              value={inputProps.value === 0 ? '' : inputProps.value}
              name={inputProps.name}
              rows={rows}
              minRows={minRows}
              maxRows={maxRows}
              startAdornment={
                startAdornment && (
                  <InputAdornment position="start" sx={{ ...styles.startAdornment }}>
                    {startAdornment}
                  </InputAdornment>
                )
              }
              endAdornment={
                endAdornment && (
                  <InputAdornment position="end" sx={{ ...styles.endAdornment }}>
                    {endAdornment}
                  </InputAdornment>
                )
              }
              autoComplete={autoComplete}
              disabled={disabled}
              decimalSeparator={getSeparator('decimal')}
              thousandSeparator={getSeparator('group')}
              decimalScale={decimalScale}
              onValueChange={(value) => {
                inputProps.onChange(value.floatValue ?? 0);
                if (onChange) {
                  onChange(value.floatValue ?? 0);
                }
              }}
            />
          </Box>
        </Tooltip>
      </FormControl>
      {!withoutError && error?.message && (
        <Box sx={{ ...styles.errorBox }}>
          <Box sx={{ ...styles.errorIcon }}>
            <ErrorIcon />
          </Box>
          <Typography sx={{ ...styles.errorText }}>{error?.message}</Typography>
        </Box>
      )}
    </Box>
  );
};
