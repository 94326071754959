import { FC } from 'react';

import { Box } from '@mui/material';
import { snakeCase, startCase } from 'lodash';

import { ShipmentStatus } from 'app/api/orders';
import { EMPTY_CELL, shipmentStatusLabel } from '../../constants';
import { getShipmentDetailsDate } from '../../utils';
import { EventItem } from '../EventItem';
import { styles } from './styles';

type EventCardProps = ShipmentStatus;

export const EventCard: FC<EventCardProps> = ({ status, statusLocation, statusDate, statusVessel, statusRemarks }) => {
  const statusLabel = shipmentStatusLabel?.[status] ?? startCase(snakeCase(status));

  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.inner}>
        <EventItem label="Status" value={statusLabel} />
        <EventItem label="Location" value={statusLocation ?? EMPTY_CELL} />
        <EventItem label="Date" value={getShipmentDetailsDate(statusDate)} />
        <EventItem label="Vessel" value={statusVessel ?? EMPTY_CELL} />
      </Box>
      {statusRemarks && <EventItem label="Remarks" value={statusRemarks} />}
    </Box>
  );
};
