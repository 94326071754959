import { CSSProperties, FC, SyntheticEvent, useCallback } from 'react';

import { Link, generatePath } from 'react-router-dom';
import { Typography } from '@mui/material';

import { ROUTES } from 'app/routes/constants';
import { CompanyDetail } from 'app/api/company';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { styles } from './styles';

interface QuoteCompanyProps {
  company: Pick<CompanyDetail, 'id' | 'name' | 'address' | 'displayId'>;
}

export const QuoteCompany: FC<QuoteCompanyProps> = ({ company }) => {
  const companyPath = generatePath(ROUTES.common.company, { displayId: company.displayId });

  const { handleTrackLinkClickEvent } = useLinkClickedEvent();

  const handleCompanyLinkClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      handleTrackLinkClickEvent({ path: companyPath, text: company.name, type: AnalyticsLinkType.LINK });
    },
    [company.name, companyPath, handleTrackLinkClickEvent],
  );

  return (
    <>
      <Link to={companyPath} target="_blank" onClick={handleCompanyLinkClick} style={styles.link as CSSProperties}>
        <Typography component="h2" sx={{ ...styles.companyName, ...styles.companyLink }}>
          {company.name}
        </Typography>
      </Link>
      <Typography sx={{ ...styles.location }}>
        {company.address.city}, {company.address.country}
      </Typography>
    </>
  );
};
