import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '185px',
    padding: '10px',
    borderRadius: '8px',
    border: `1px solid ${customTheme.palette.primary.P100}`,
    cursor: 'pointer',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '144px',
    },
  },
  plusIconBox: {
    marginTop: '-8px',
  },
  addText: {
    marginTop: '8px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: customTheme.palette.primary.P100,
  },
};
