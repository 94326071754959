import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 185px)',
    gap: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridTemplateColumns: '1fr 1fr',
      gap: '14px 20px',
      gridRow: 7,
    },
  },
};
