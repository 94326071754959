import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  progressContainer: {
    width: '100%',
    margin: '16px 0px',
  },
  // Shared styles
  topWelcome: {
    margin: '0px 0px 8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  welcome: {
    color: '#B3B3B3',
  },
  welcomeLogo: {
    width: '300px',
  },

  //
  title: {},
  modalPaper: {
    width: '600px',
    transition: '1s ease-in-out',
    // customTheme.transition.default,
  },
  closeBtn: {
    marginBottom: '0px',
  },

  // Request modal
  requestContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0px 16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '0px ',
    },
  },
  businessContainer: {
    margin: '8px 0px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },

  note: {
    margin: '0px auto 8px',
    maxWidth: '360px',
    textAlign: 'center',
    color: customTheme.custom.gray.G700,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '100%',
      fontWeight: 400,
      fontSize: '14px',
    },
  },
  rowContainer: {
    display: 'flex',
    gap: '8px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexDirection: 'column',
      gap: '0px',
    },
  },
  rowItem: {
    flex: 1,
    marginBottom: '12px',
  },
  selectInput: {
    padding: '0px',
    fontSize: '12px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: '42px',
    },
  },
  emailInput: {},
  requestAccessConfirmBtn: {
    fontWeight: 500,
    fontSize: '16px',
    margin: '16px 0px',
  },
  form: {
    maxWidth: '100%',
    boxSizing: 'border-box',
    width: '100%',
    alignItems: 'stretch',
  },

  // Welcome styles
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '350px',
    margin: '0px auto 48px',
  },
  welcomeNote: {
    width: '100%',
    color: '#555',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
    margin: '0px 0px 16px',
  },
  welcomeBtn: {
    width: '100%',
  },
  requestAccessBtn: {
    marginBottom: '16px',
  },

  terms: {
    color: customTheme.custom.gray.G500,
    fontSize: '12px',
    textAlign: 'center',
  },
  addProductBtnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    margin: '16px 0px',
  },
  addProductBtn: {},
  additionalInfo: {},
  additionalInfoInput: {
    padding: '8px 12px',
  },

  productList: {
    display: 'flex',
    flexDirection: 'column',
  },

  productRow: (isNotLast: boolean) => ({
    borderBottom: isNotLast ? `1px solid ${customTheme.custom.gray.G200}` : 'none',
  }),
};
