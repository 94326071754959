import { FC, useCallback } from 'react';

import { FormControlLabel } from '@mui/material';
import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { styles } from './styles';
import { CommonCheckbox } from '../../../../components/CommonCheckbox';

interface FilterItemProps {
  control: Control;
  controlName: string;
  label: string;
  value: string;
}

export const DocumentItem: FC<FilterItemProps> = ({ control, label, value, controlName }) => {
  const onValueChange = useCallback(
    (checked: boolean, value: string, props: ControllerRenderProps<FieldValues, string>) => {
      const updateVal = checked ? value : checked;
      props.onChange(updateVal);
    },
    [],
  );

  return (
    <FormControlLabel
      sx={{ ...styles.menuItemWrap }}
      control={
        <Controller
          name={controlName}
          control={control}
          render={({ field: props }) => (
            <CommonCheckbox
              {...props}
              ref={null}
              checked={!!props.value}
              value={value}
              isBigIcon
              onChange={(e) => onValueChange(e?.target?.checked, e?.target?.value, props)}
            />
          )}
        />
      }
      label={label}
    />
  );
};
