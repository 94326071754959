import { useEffect, useState, useCallback, FC, useMemo } from 'react';

import { Box, Typography } from '@mui/material';

import { INotification } from 'app/common/types';
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorNotification.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { notificationObserver } from 'utils/observer';
import { CloseButton } from '../CloseButton';
import { styles } from './styles';

export const Notification: FC = () => {
  const [notification, setNotification] = useState<INotification | null>(null);

  const handleNotify = useCallback((data: INotification) => {
    setNotification(data);
  }, []);

  const handleNotificationClose = useCallback(() => {
    setNotification(null);
  }, []);

  useEffect(() => {
    notificationObserver.subscribe(handleNotify);
    return () => notificationObserver.unsubscribe(handleNotify);
  }, [handleNotify]);

  useEffect(() => {
    const clearNotification = setTimeout(() => notification && setNotification(null), 4 * 1000);
    return () => clearTimeout(clearNotification);
  }, [notification]);

  const notificationIcon = useMemo(() => {
    switch (notification?.type) {
      case 'warning':
        return <WarningIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'success':
        return <SuccessIcon />;
      default:
        return <InfoIcon />;
    }
  }, [notification]);

  return (
    <Box>
      {notification && (
        <Box
          sx={{
            ...styles.wrap,
            ...(notification?.type === 'warning' && styles.wrapWarning),
            ...(notification?.type === 'error' && styles.wrapError),
            ...(notification?.type === 'success' && styles.wrapSuccess),
            ...(notification?.type === 'info' && styles.wrapInfo),
          }}
        >
          {notificationIcon}

          <Box sx={{ ...styles.textWrap }}>
            <Typography sx={{ ...styles.title }}>{notification?.title}</Typography>
            {!!notification?.text && <Typography sx={{ ...styles.subTitle }}>{notification?.text}</Typography>}
          </Box>

          <CloseButton onClick={handleNotificationClose} sx={{ ...styles.closeButton }} />
        </Box>
      )}
    </Box>
  );
};
