import { useMemo } from 'react';

import { customTheme } from 'theme/MuiThemeProvider/theme';
import { OrderDocument, OrderDocumentStatus } from 'app/api/orders';

export const useViewFile = (file: OrderDocument) => {
  const fileColor = useMemo(() => {
    if (file.status === OrderDocumentStatus.ACCEPTED) {
      return customTheme.custom.success;
    }

    if (file.status === OrderDocumentStatus.REJECTED) {
      return customTheme.custom.error;
    }

    return customTheme.custom.awaiting;
  }, [file]);

  const fileStatus = useMemo(() => {
    if (file.status === OrderDocumentStatus.ACCEPTED) {
      return 'Approved';
    }

    if (file.status === OrderDocumentStatus.REJECTED) {
      return 'Rejected';
    }

    return 'Awaiting';
  }, [file]);

  return { fileColor, fileStatus };
};
