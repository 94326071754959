import { FC } from 'react';

import { AuthMainUi } from '../../components/AuthMainUi';
import { CreatePasswordForm } from '../CreatePasswordPage/components/CreatePasswordForm';

export const CreateNewPasswordPage: FC = () => {
  return (
    <AuthMainUi>
      <CreatePasswordForm isResetPassword />
    </AuthMainUi>
  );
};

export default CreateNewPasswordPage;
