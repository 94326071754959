import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  productsDrawer: {
    '& .MuiPaper-root': {
      paddingTop: '4px',
      top: '74px',
      boxShadow: 'none',
      height: 'calc(100% - 74px)',
    },
    '& .MuiBackdrop-root': {
      display: 'none',
    },
  },
  divider: {
    borderColor: customTheme.custom.black.B70,
  },
  buttonWrap: {
    padding: '0 12px',
  },
  backButton: {
    width: 'fit-content',
    padding: '12px',
    color: customTheme.custom.black.B70,
  },
  arrow: {
    marginRight: '10px',
  },
};
