import { useState } from 'react';
import { Input, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { styles } from './multiselectDropdownStyles';

const ITEM_HEIGHT = 36;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7,
      width: 250,
    },
  },
};

export const MultiDropdownSelect = ({
  options,
  onChange,
  value = new Set(),
  isRequired = false,
  label,
}: {
  value?: Set<string>;
  onChange: (values: string[]) => void;
  options: { id: string; label: string }[];
  label?: string;
  isRequired?: boolean;
}) => {
  const [selected, setSelected] = useState<string[]>([...value]);

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    const values = typeof value === 'string' ? value.split(',') : value;
    setSelected(values);
    onChange(values);
  };
  return (
    <>
      <Select
        multiple
        value={selected}
        onChange={handleChange}
        labelId="demo-select-small-label"
        id="demo-select-small"
        label={label}
        required={isRequired}
        input={<Input fullWidth disableUnderline style={styles.dropDownInput} />}
        MenuProps={MenuProps}
        displayEmpty
        sx={{
          '& .MuiSelect-select .notranslate::after': label
            ? {
                content: `"${label}"`,
                opacity: 0.42,
              }
            : {},
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id} sx={styles.menuItem}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
