import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'absolute',
    top: 0,
    left: '1px',
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    background: customTheme.custom.mainBg,
    padding: '24px',
    zIndex: 9,
    boxSizing: 'border-box',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '14px',
    },
  },
};
