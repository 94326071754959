import { FC, ReactElement } from 'react';

import { Box, Typography } from '@mui/material';

import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { CommonTooltip } from 'components';
import { styles } from './styles';

// eslint-disable-next-line max-len
const TOOLTIP_MESSAGE = `Once you send this quote counter back to the seller, they'll be able  to enter an initial offer for this product.`;
const LABEL_TEXT = 'Seller input needed';

export const SellerInputNeededLabel: FC = (): ReactElement => {
  return (
    <Box sx={styles.labelWrap} data-testid="seller-input-needed-label">
      <CommonTooltip sx={styles.tooltipWrap} title={TOOLTIP_MESSAGE} placement="bottom-start">
        <InfoIcon />
      </CommonTooltip>
      <Typography component="span" sx={styles.label}>
        {LABEL_TEXT}
      </Typography>
    </Box>
  );
};
