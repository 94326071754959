import { FC } from 'react';

import { Box, IconButton } from '@mui/material';

import { CommonButton, CommonTooltip } from 'components';

import { ReactComponent as ConfirmIcon } from 'assets/icons/confirmQuote.svg';
import { styles } from './styles';

export interface ConfirmActionsProps {
  isConfirm: boolean | undefined;
  isDisabled: boolean;
  isThousandSeeds: boolean;
  handleConfirm: () => void;
  handleUndo: () => void;
}

export const ConfirmActions: FC<ConfirmActionsProps> = ({
  isConfirm,
  isDisabled,
  isThousandSeeds,
  handleConfirm,
  handleUndo,
}): JSX.Element => {
  return (
    <>
      {!isConfirm && (
        <>
          <CommonTooltip title="Accept Price" placement="top">
            <IconButton
              aria-label="confirm"
              disabled={isDisabled}
              sx={{ ...styles.confirmIcon }}
              onClick={handleConfirm}
            >
              <ConfirmIcon />
            </IconButton>
          </CommonTooltip>
          <CommonButton disabled={isDisabled} sx={{ ...styles.acceptPriceButton }} onClick={handleConfirm}>
            Accept Price
          </CommonButton>
        </>
      )}
      {isConfirm && (
        <Box aria-label="undo" sx={styles.undoLabel(isThousandSeeds)} onClick={handleUndo}>
          Undo
        </Box>
      )}
    </>
  );
};
