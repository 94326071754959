import { useEffect } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';

import { MuteStatus, NotificationsSettingsForm, muteChannel } from 'app/api/SBChat';
import { notificationObserver } from 'utils/observer';
import { useMuteStatusHandlers } from './useMuteStatusHandlers';

interface UseMuteNotificationsSwitchProps {
  channelUrl: string;
  initialValues: NotificationsSettingsForm;
}

export const useMuteNotificationsSwitch = ({ channelUrl, initialValues }: UseMuteNotificationsSwitchProps) => {
  const queryClient = useQueryClient();
  const { control, setValue, watch, reset } = useForm<NotificationsSettingsForm>({ defaultValues: initialValues });

  const isAllMuted = watch('all');
  const isMessagesMuted = watch('messages');

  const { mutate: muteStatusMutation, isLoading } = useMutation(
    ({ muteStatus }: { muteStatus: MuteStatus }) => muteChannel({ id: channelUrl, params: { muteStatus } }),
    {
      onSuccess: async () => {
        notificationObserver.publish({
          type: 'success',
          title: 'Notifications settings changed successfully',
        });

        await queryClient.invalidateQueries({ queryKey: ['notifications-settings'] });
      },
      onError: () => {
        notificationObserver.publish({
          type: 'error',
          title: 'Something went wrong',
        });
      },
    },
  );

  const { onChange } = useMuteStatusHandlers({ isAllMuted, isMessagesMuted, muteStatusMutation, setValue });

  useEffect(() => {
    if (channelUrl && !isLoading) {
      reset({ ...initialValues });
    }
  }, [channelUrl, initialValues, isLoading, reset]);

  return {
    control,
    isAllMuted,
    isMessagesMuted,
    onChange,
  };
};
