import { FC } from 'react';

import { Box, List, Typography } from '@mui/material';

import { TooltipListItem } from './TooltipListItem';
import { TOOLTIP_LABELS } from './constants';
import { styles } from './styles';

export const PasswordTooltip: FC = (): JSX.Element => (
  <Box sx={{ ...styles.wrap }}>
    <Typography sx={{ ...styles.text }}>Password must include:</Typography>

    <List sx={{ ...styles.list }}>
      {TOOLTIP_LABELS.map((label) => (
        <TooltipListItem key={label} label={label} />
      ))}
    </List>
  </Box>
);
