import { FC } from 'react';

import { Box } from '@mui/material';

import { SBChatMode } from 'app/api/SBChat';
import { SBMessageHeader } from '../SBMessageHeader';
import { SBUserTextMessageBody } from '../SBUserTextMessageBody';
import { SBMessageDate } from '../SBMessageDate';
import { SBMessageContainer } from '../SBMessageContainer';
import { styles } from './styles';

interface SBUserMessageProps {
  body: string;
  date: string;
  isCurrentUser: boolean;
  isCurrentCompany: boolean;
  mode: SBChatMode;
  isHighlighted: boolean;
  userName?: string;
  avatarSrc?: string;
}

export const SBUserMessage: FC<SBUserMessageProps> = ({
  body,
  date,
  isCurrentUser,
  isCurrentCompany,
  isHighlighted,
  mode,
  avatarSrc,
  userName = '',
}) => {
  const isOrderChatMode = mode === SBChatMode.ORDER_DETAILS;

  return (
    <SBMessageContainer isCurrentUser={isCurrentUser}>
      <SBMessageHeader
        avatar={avatarSrc}
        isCurrentUser={isCurrentUser}
        isCurrentCompany={isCurrentCompany}
        userName={userName}
      />
      <Box sx={styles.wrap(isOrderChatMode)}>
        <SBUserTextMessageBody
          isCurrentUser={isCurrentUser}
          isCurrentCompany={isCurrentCompany}
          isHighlighted={isHighlighted}
          text={body}
        />
        <SBMessageDate date={date} />
      </Box>
    </SBMessageContainer>
  );
};
