import { FC } from 'react';

import { InputBase, InputBaseProps } from '@mui/material';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useCommonInputHandler } from './hooks';
import { styles } from './styles';

export const CommonInput: FC<InputBaseProps> = ({ sx, ...props }) => {
  useCommonInputHandler();

  return <InputBase sx={{ ...styles.input, ...(sx as SxPropsTypes) }} {...props} />;
};
