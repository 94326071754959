import { useQuery } from 'react-query';

import { getCountries } from 'app/api/country';
import { defaultQueryStaleTime } from 'app/api';

export const useCountries = () => {
  const { data, isLoading } = useQuery(['countries'], () => getCountries(), {
    refetchOnWindowFocus: false,
    retry: 1,
    enabled: true,
    staleTime: defaultQueryStaleTime,
  });

  return {
    data,
    isLoading,
  };
};
