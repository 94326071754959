import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    padding: '4px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  editable: {
    cursor: 'pointer',
  },
  label: {
    userSelect: 'none',
    fontSize: '14px',
    fontWeight: '500',
    minWidth: '18px',
    textAlign: 'center',
    color: '#358B67',
    backgroundColor: '#97EBC8',
    padding: '2px 4px',
    borderRadius: '4px',
  },
  tooltip: {
    marginBottom: '0px !important',
  },
  editButton: {
    maxHeight: '32px',
    maxWidth: '60px',
    minWidth: '24px',
    textAlign: 'left',
    backgroundColor: 'inherit',
    padding: '0px',
    borderRadius: '8px',
  },
  menu: {
    padding: '0px',
  },
  item: {
    width: '290px',
    fontSize: '14px',
    fontWeight: 500,
    height: '40px',
  },
  itemAbbr: {
    color: customTheme.custom.black.B1000,
    width: '48px',
  },
  itemLabel: {
    color: '#8F8F8F',
  },
};
