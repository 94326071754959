import { FC } from 'react';

import { Control, UseFormGetValues, UseFormReset } from 'react-hook-form';

import { ProductsFiltersForm } from 'app/api/products';
import { RadioGroupController, RadioGroupItem } from 'components';
import { FiltersNames } from '../../../../constants';
import { CommonFiltersDrawer } from '../CommonFiltersDrawer';
import { FilterListItem } from '../FilterListItem';
import { useTypeFilter } from './hooks';

interface TypeFilterProps {
  data: RadioGroupItem[];
  control: Control<ProductsFiltersForm>;
  getValues: UseFormGetValues<ProductsFiltersForm>;
  reset: UseFormReset<ProductsFiltersForm>;
}

export const TypeFilter: FC<TypeFilterProps> = ({ control, data, reset, getValues }) => {
  const defaultTitle = 'Hybrid/OP';
  const controlName = FiltersNames.GENETIC_TYPE;

  const { counter, isFilterChanged, filterTitle, open, handleCloseModal, handleOpenModal, handleConfirm } =
    useTypeFilter({
      control,
      controlName,
      getValues,
      reset,
    });

  return (
    <>
      <FilterListItem filterValue={counter} label={filterTitle} onClick={handleOpenModal} />
      <CommonFiltersDrawer
        disableConfirmButton={!isFilterChanged}
        isOpen={open}
        title={defaultTitle}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
      >
        <RadioGroupController controlName={controlName} control={control as unknown as Control} data={data} />
      </CommonFiltersDrawer>
    </>
  );
};
