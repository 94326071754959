import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'end',
      background: customTheme.custom.blue.B100,
      marginBottom: '16px',
    },
  },
  menuWrap: {
    '& .MuiPaper-root': {
      minWidth: '280px',
      padding: '4px 0',
      borderRadius: '3px',
      boxShadow: '0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31)',
    },
    '& .MuiMenu-list': {
      padding: 0,
    },
  },
  menuButton: (isBuyer: boolean) => ({
    minWidth: '64px',
    fontWeight: '400',
    color: customTheme.custom.black.B100,
    backgroundColor: customTheme.custom.black.B5,
    minHeight: '40px',
    height: 'fit-content',

    '&.MuiButton-containedPrimary': {
      ':hover': {
        backgroundColor: customTheme.palette.primary.P10,
      },
    },

    ...(isBuyer && {
      '@media (max-width: 1312px)': {
        maxWidth: '230px',
      },
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      borderRadius: '0 0 0 10px',
      maxWidth: 'unset',
      minHeight: '42px',
    },
  }),
  menuButtonOpened: {
    backgroundColor: customTheme.palette.primary.P10,
  },
  menuButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    color: customTheme.custom.black.B100,
  },
  menuButtonIconopened: {
    color: customTheme.palette.primary.P100,
  },
  sortTitleWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  sortTitle: {
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
  },
  sortLabel: {
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
    color: customTheme.custom.black.B100,
  },
  sortLabelOpened: {
    color: customTheme.palette.primary.P100,
  },
};
