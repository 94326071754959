// import { useCallback, useEffect, useMemo } from 'react';

// import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
// import { useMatch, useNavigate } from 'react-router-dom';

// import { FeatureFlags } from 'app/api/featureFlags';
// import { ROUTES } from 'app/routes/constants';

export const useMaintenanceMode = () => {
  // const navigate = useNavigate();
  // const postHog = usePostHog();
  // const isMaintenancePageRoute = useMatch(ROUTES.common.maintenance);
  // const isMaintenanceMode = useFeatureFlagEnabled(FeatureFlags.MAINTENANCE_MODE);
  // const isMaintenanceModeEnabled = useMemo(() => isMaintenanceMode, [isMaintenanceMode]);
  // const updateFeatureFlags = useCallback(() => {
  //   postHog.reloadFeatureFlags();
  // }, [postHog]);
  // useEffect(() => {
  //   updateFeatureFlags();
  // });
  // useEffect(() => {
  //   if (isMaintenanceModeEnabled) {
  //     navigate(ROUTES.common.maintenance);
  //   } else if (isMaintenancePageRoute) {
  //     navigate(ROUTES._);
  //   }
  // }, [isMaintenanceModeEnabled, isMaintenancePageRoute, navigate]);
};
