import { useMemo } from 'react';

import { format } from 'date-fns';

import { DocumentFolder, DocumentFolderStatus } from 'app/api/orders/types';
import { useAuth } from 'hooks';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const useOrderDocumentCard = (document: DocumentFolder) => {
  const { isBuyer } = useAuth();

  const isEmpty = useMemo(() => {
    return document.status === DocumentFolderStatus.NO_DOCUMENT_UPLOADED;
  }, [document]);

  const isCompleted = useMemo(() => {
    return document.status === DocumentFolderStatus.ALL_DOCUMENT_ACCEPTED;
  }, [document]);

  const isRejected = useMemo(() => {
    return document.status === DocumentFolderStatus.AT_LEAST_1_DOCUMENT_REJECTED;
  }, [document]);

  const cardColor = useMemo(() => {
    if (isEmpty) {
      return isBuyer ? customTheme.custom.black.B10 : customTheme.custom.info;
    }

    if (isCompleted) {
      return customTheme.custom.success;
    }

    if (isRejected && !isBuyer) {
      return customTheme.custom.reject;
    }

    return customTheme.custom.awaiting;
  }, [isBuyer, isCompleted, isRejected, isEmpty]);

  const subTitle = useMemo(() => {
    if (isEmpty) {
      return isBuyer ? 'Awaiting to upload' : 'Not complete';
    }

    if (isCompleted) {
      return `Completed - ${format(new Date(document.updatedAt), 'dd.MM.yyyy')}`;
    }

    if (isRejected) {
      return 'Rejected';
    }

    return 'Awaiting Confirmation';
  }, [document, isBuyer, isCompleted, isRejected, isEmpty]);

  return { cardColor, isEmpty, isBuyer, isCompleted, subTitle };
};
