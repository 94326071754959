import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    position: 'relative',
    minWidth: '10px',
    width: '10px',
    padding: '8px 16px',
    background: customTheme.custom.mainBg,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: 2,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  arrowButton: {
    width: '40px',
    height: '40px',
    color: customTheme.custom.neutralLight,
    position: 'absolute',
    right: '3px',
    top: '4px',
    transform: 'rotate(180deg)',
  },
  drawer: (hasQuotePanel: boolean) => ({
    width: '312px',
    ...(hasQuotePanel && {
      width: '392px',
      minWidth: '392px',
    }),

    flexShrink: 0,

    '& .MuiDrawer-paper': {
      position: 'absolute',
      width: '312px',
      ...(hasQuotePanel && {
        width: '392px',
      }),
      padding: 0,
      color: customTheme.custom.neutralLight,
      background: customTheme.custom.mainBg,
      overflowX: 'hidden',
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    },
  }),
  drawerInner: {
    padding: '8px 0',
    maxHeight: 'calc(100% - 20px)',
    display: 'flex',
    flexDirection: 'column',
  },
};
