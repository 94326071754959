import { OrderShipmentStatus } from 'app/api/orders';

export const shipmentStatusLabel: Record<OrderShipmentStatus, string> = {
  [OrderShipmentStatus.BOOKING_CONFIRMED]: 'Booking confirmed',
  [OrderShipmentStatus.LOADED_ON_A_TRANSPORT]: 'Loaded on transport',
  [OrderShipmentStatus.VESSEL_DEPARTED]: 'Vessel Departed',
  [OrderShipmentStatus.VESSEL_ARRIVES]: 'Vessel Arrives',
};

export const EMPTY_CELL = 'N/A';

export const shipmentDateFormat = 'MMMM dd, yyyy';
