import { ProductNested } from 'app/api/quotes/types';

export const formatNestedProductAnalyticsData = ({ id, sku, variety, name, geneticType, color }: ProductNested) => {
  return {
    product_id: id,
    sku,
    variety: variety ?? '',
    name: name ?? '',
    type: geneticType,
    color: color ?? '',
  };
};
