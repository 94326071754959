import { PaymentInfoItem } from '../types';

export const getPaymentInfoToCopy = (info: PaymentInfoItem[]): string => {
  let text = '';

  const data = info.map(({ label, value }) => `${label}: ${value}`);
  text = data.join('\n');

  return text;
};
