import { FC, ReactNode } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, CircularProgress } from '@mui/material';

import { styles } from './styles';

export const SCROLLABLE_LIST_ID = 'scrollableList';

interface InfinityListWrapperProps {
  children: ReactNode;
  dataLength: number;
  hasNextPage: boolean;
  isLoading: boolean;
  columnCount?: number;
  onLoadMore: () => void;
}

export const InfinityListWrapper: FC<InfinityListWrapperProps> = ({
  children,
  dataLength,
  hasNextPage,
  isLoading,
  columnCount,
  onLoadMore,
}) => {
  return (
    <InfiniteScroll
      dataLength={dataLength}
      next={onLoadMore}
      hasMore={hasNextPage}
      loader={
        <>
          {isLoading && (
            <Box sx={styles.loaderWrap(hasNextPage)}>
              <CircularProgress size={24} />
            </Box>
          )}
        </>
      }
      scrollableTarget={SCROLLABLE_LIST_ID}
    >
      <Box sx={styles.wrap(columnCount)}>{children}</Box>
    </InfiniteScroll>
  );
};
