import { ChangeEvent, useCallback, useState } from 'react';

import { Control, useFieldArray } from 'react-hook-form';

import { ChatFormData } from 'app/api/SBChat';
import { MAX_ATTACHMENT_SIZE, MAX_ATTACHMENT_NAME_LENGTH, ACCEPTED_ATTACHMENTS_FORMATS } from '../validation';
import { getValidationErrorMessage } from '../utils';

export const useFileUpload = (control: Control<ChatFormData>) => {
  const [fileError, setFileError] = useState('');

  const {
    fields: uploadedFiles,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'files',
  });

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFileError('');

      const files = e?.target?.files;
      const isFiles = !!files?.length;

      const isMaxSizeError = isFiles && files[0].size > MAX_ATTACHMENT_SIZE;
      const isMaxNameLengthError = isFiles && files[0].name.length > MAX_ATTACHMENT_NAME_LENGTH;
      const isInvalidFormatError = isFiles && !ACCEPTED_ATTACHMENTS_FORMATS.includes(files[0].type);
      const isError = isMaxSizeError || isMaxNameLengthError || isInvalidFormatError;

      if (isError) {
        const errorMessage = getValidationErrorMessage(isMaxSizeError, isMaxNameLengthError, isInvalidFormatError);

        setFileError(errorMessage);

        if (e?.target) {
          // eslint-disable-next-line no-param-reassign
          e.target.value = '';
        }
        return;
      }

      if (files) {
        append([...Array.from(files).map((item) => ({ file: item }))]);

        if (e?.target) {
          // eslint-disable-next-line no-param-reassign
          e.target.value = '';
        }
      }
    },
    [append],
  );

  const handleRemoveFile = useCallback(
    (index: number) => {
      remove(index);
      setFileError('');
    },
    [remove],
  );

  const onChange = useCallback(() => {
    if (fileError) {
      setFileError('');
    }
  }, [fileError]);

  return {
    uploadedFiles,
    handleFileUpload,
    handleRemoveFile,
    fileError,
    onChange,
    setFileError,
  };
};
