import { FC, MouseEvent, Ref, useState } from 'react';

import { Box, SxProps, Typography } from '@mui/material';

import { generatePath } from 'react-router-dom';
import { RESTRICTED_CROPS } from 'app/api/crops';
import { FarmingMethod, Product, ProductStatus } from 'app/api/products';
import imagePlaceholder from 'assets/images/imagePlaceholderSmall.png';
import { useImageHandlers } from 'hooks';
import { getFileExtension } from 'pages/CompanyDetailsPage/utils';
import { capitalizeFirstLetter } from 'utils/formatText';
import { getVarietyValue } from 'utils/getVarietyValue';
import { selectIsAnonymousUser, setSignupModal } from 'state/slices/account';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { AnalyticsLinkType } from 'analytics';
import { ROUTES } from 'app/routes/constants';
import { SaveButton } from 'components/SaveButton';
import { Link } from 'components/Link';
import useSaveProduct from 'hooks/useSaveProduct';
import { getUnitTypeLabel } from 'components/UnitsMenu';
import { UnitsType } from 'app/api/quotes';

import { geneticTypeLabel } from './constants';
import { styles } from './styles';

export interface ProductCardProps extends Product {
  isSliderCard?: boolean;
  hideMobileFooter?: boolean;
  mobileCardOrientation?: 'vertical' | 'horizontal';
  sx?: SxProps;
  size?: 'small' | 'large';
}

export const ProductCard: FC<ProductCardProps> = ({
  id,
  name,
  image,
  crop,
  variety,
  company,
  geneticType,
  qtyAvailable,
  status,
  thumbnail,
  inStock,
  publicPricingUnits,
  publicPricing,
  farmingMethod,
  isSliderCard = false,
  hideMobileFooter = false,
  mobileCardOrientation = 'vertical',
  sx,
  isSaved,
  size = 'small',
}) => {
  const { handleImageError } = useImageHandlers();

  const isAnonymousUser = useAppSelector(selectIsAnonymousUser);
  const varietyValue = getVarietyValue({ crop, variety, name });
  const isDraft = status === ProductStatus.DRAFT;
  const imageSrc = thumbnail || image || imagePlaceholder;
  const isLogo = !!company.logo?.length && getFileExtension(company.logo) !== 'pdf';
  const isMobileHorizontal = mobileCardOrientation === 'horizontal';
  const isRestricted = RESTRICTED_CROPS.some((name) => name === crop.toLowerCase());
  const path = generatePath(ROUTES.common.product, { id });
  const text = `${variety || ''} ${name || ''}`;
  const [isHover, setIsHover] = useState(false);
  const { save, unsave } = useSaveProduct();
  const [isProductSaved, setIsProductSaved] = useState(isSaved);
  const dispatch = useAppDispatch();
  const onClickSave = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (isAnonymousUser) {
      dispatch(setSignupModal({ isOpen: true, state: 'requestAccess', source: 'save' }));
      return;
    }
    if (isSaved) {
      unsave.mutate({ productId: id });
    } else {
      save.mutate({ productId: id });
    }
    setIsProductSaved((s) => !s);
  };
  const [imgHeight, setImageHeight] = useState(0);
  const setImgHeight: Ref<HTMLImageElement> = (img) =>
    !imgHeight && img && setImageHeight(img.getBoundingClientRect().height);
  const hasPublicPricing = publicPricingUnits && publicPricing;
  return (
    <Link text={text} path={isRestricted ? undefined : path} trackType={AnalyticsLinkType.CARD}>
      <Box
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        sx={{
          ...sx,
          container: styles.container,
          ...styles.wrap(isSliderCard, isMobileHorizontal, isRestricted, size),
        }}
        data-testid={size === 'small' ? 'card-small' : 'card-large'}
      >
        <Box sx={styles.imgContainer}>
          <Box
            component="img"
            loading="lazy"
            src={imageSrc}
            alt={text}
            ref={setImgHeight}
            sx={{ ...styles.image(isSliderCard, isMobileHorizontal) }}
            onError={handleImageError(imagePlaceholder)}
            data-testid="product-img"
          />
          {hasPublicPricing && (
            <Box sx={styles.publicPricingContainer(isMobileHorizontal)}>
              <Typography component="span" sx={styles.publicPrice(isMobileHorizontal)}>
                {`${publicPricing}  `}
              </Typography>
              <Typography
                component="span"
                sx={styles.publicPricingUnits(publicPricingUnits === UnitsType.THOUSAND_SEEDS)}
              >{`per ${getUnitTypeLabel(publicPricingUnits)}`}</Typography>
            </Box>
          )}
        </Box>
        {!inStock && <Box sx={{ ...styles.outOfStock(isMobileHorizontal) }}>Out of Stock</Box>}
        {farmingMethod?.toLowerCase() === FarmingMethod.ORGANIC.toLowerCase() && (
          <Box sx={{ ...styles.organicTag(isMobileHorizontal, inStock) }}>Organic</Box>
        )}
        {!inStock && <Box sx={{ ...styles.outOfStock(isMobileHorizontal) }}>Out of Stock</Box>}

        {isDraft && <Box sx={{ ...styles.draftLabel }}>Draft</Box>}
        {isHover && (
          <Box sx={styles.saveContainer(size === 'small', isSliderCard, imgHeight)}>
            <SaveButton isActive={isProductSaved} size="small" onClick={onClickSave} />
          </Box>
        )}
        <Box sx={{ ...styles.content }} data-testid={isSliderCard ? 'slider-card-content' : 'normal-card-content'}>
          <Box>
            <Typography component="h4" sx={{ ...styles.crop }}>
              {capitalizeFirstLetter(crop)}
            </Typography>
            <Box sx={{ ...styles.header }}>
              <Typography component="h4" sx={{ ...styles.variety(isMobileHorizontal) }}>
                {varietyValue}
                {variety && name && ' '}
                {!isAnonymousUser && name && (
                  <Typography sx={() => ({ ...styles.variety(isMobileHorizontal), ...styles.name })} component="span">
                    {name}
                  </Typography>
                )}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ ...styles.tagsWrap(isMobileHorizontal) }}>
              <Typography sx={{ ...styles.type }}>{geneticTypeLabel[geneticType]}</Typography>
            </Box>
            {!isAnonymousUser ? (
              <Box sx={{ ...styles.footer(hideMobileFooter) }}>
                <Typography sx={{ ...styles.company(isMobileHorizontal) }}>
                  {company.name}
                  <Typography component="span" sx={{ ...styles.company(isMobileHorizontal), ...styles.country }}>
                    • {company.country}
                  </Typography>
                </Typography>
                {isLogo && (
                  <Box component="img" src={company.logo ?? ''} sx={styles.logo(!!qtyAvailable, isMobileHorizontal)} />
                )}
              </Box>
            ) : (
              <Box sx={styles.suppliedFrom}>
                supplied from{' '}
                <Box component="span" sx={styles.suppliedFromCompany}>
                  {company.country}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
