import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  title: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '28px',
    },
  },
  legend: {
    img: {
      maxHeight: '400px',
      width: 'auto',
      maxWidth: '100%',
      objectFit: 'contain',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '100%',
    },
  },
  image: {
    width: 'auto',
    maxWidth: '100%',
  },
};
