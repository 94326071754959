export enum MessageType {
  FILE = 'file',
  USER = 'user',
  OTHER_USER = 'otherUser',
  SYSTEM = 'system',
}

export interface AddMessageData {
  channel: string;
  author?: number;
  quote?: number;
  image?: File;
  text?: string;
}

export interface MessageData {
  id: number;
  author: {
    firstName: string;
    lastName: string;
    id: number;
  };
  createdAt: string;
  image: string | null;
  isRead: boolean;
  isSystem: boolean;
  quote?: number;
  text: string;
}

export interface MessagesListRequest {
  limit?: number;
  offset?: number;
  quote?: number;
  msgType?: MessageType;
}

export interface MessagesListData {
  count: number;
  next: string | null;
  previos: string | null;
  results: MessageData[];
}

export interface UnreadMessagesIndicatorRequest {
  quoteId: number;
}

export interface UnreadMessagesIndicatorData {
  newMessages: boolean;
}
