import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  leftWrap: {
    position: 'relative',
    boxShadow: '4px 0 4px -4px rgba(0, 0, 0, 0.25)',
    height: 'calc(100vh - 150px)',
    display: 'flex',
    zIndex: 2,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      flexDirection: 'column',
      overflowX: 'hidden',
      height: 'fit-content',
    },
  },
  divider: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  selectorWrap: (isBuyer: boolean) => ({
    width: '376px',
    minWidth: '376px',
    height: 'calc(100vh - 288px)',
    maxHeight: '100%',
    marginTop: '88px',
    overflowX: 'hidden',
    overflowY: 'auto',
    background: customTheme.custom.white,
    display: 'flex',
    zIndex: 2,
    ...scrollMixin,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      minWidth: '100%',
      minHeight: `calc(100svh - ${isBuyer ? '220px' : '180px'})`,
      marginTop: 0,
      paddingTop: '68px',
      flexGrow: 1,
    },
  }),
  listWrap: {
    padding: '0 0 24px 0',
    width: '376px',
    minWidth: '376px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '100%',
      minWidth: '100%',
      paddingBottom: '14px',
      height: 'fit-content',
    },
  },
  chatWrap: {
    flexGrow: 1,
    width: '100%',
    zIndex: 1,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
