import { INotification } from '../app/common/types';

class Observer<T = unknown> {
  private subs: Subscriber<T>[] = [];

  subscribe(sub: Subscriber<T>) {
    this.subs.push(sub);
  }

  unsubscribe(sub: Subscriber<T>): void {
    this.subs = this.subs.filter((item) => item !== sub);
  }

  publish(...args: T[]): void {
    this.subs.forEach((sub) => {
      sub(...args);
    });
  }
}

type Subscriber<T = unknown> = (...args: T[]) => void;

export const sessionObserver = new Observer<boolean>();
export const notificationObserver = new Observer<INotification>();
