import { useCallback } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { createDocumentFolder } from 'app/api/orders/service';
import { CreateDocumentFolderRequest } from 'app/api/orders/types';
import { notificationObserver } from 'utils/observer';
import { AddDocumentsFormData } from './types';

interface CreateDocumentError {
  response?: {
    status?: number;
    data?: {
      kind?: string[];
    };
  };
}

export const usePlaceOrderPage = (handleClose: () => void) => {
  const { id: orderId } = useParams();
  const queryClient = useQueryClient();

  const { control, handleSubmit } = useForm<AddDocumentsFormData>();

  const { mutate: handleCreateDocuments, isLoading: isDocumentsLoading } = useMutation(
    (data: CreateDocumentFolderRequest[]) => {
      return Promise.all(data.map((dataItem) => createDocumentFolder(dataItem)));
    },
    {
      onSuccess: () => {
        handleClose();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['order-documents-folders'] });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['order'] });
      },
      onError: (error: CreateDocumentError) => {
        const { response } = error;

        if (response?.status === 400 && response?.data?.kind?.[0]) {
          notificationObserver.publish({
            type: 'error',
            title: response.data.kind[0],
          });
        }
      },
    },
  );

  const onSubmit = useCallback(
    (values: AddDocumentsFormData) => {
      const { documents, additionalDocuments } = values;
      const parsedDocuments = documents?.filter((val) => !!val) || [];
      const parsedAdditionalDocuments = additionalDocuments?.filter((val) => !!val.value).map((val) => val.value) || [];

      if (!parsedDocuments.length && !parsedAdditionalDocuments.length) {
        return;
      }

      const preparedDocuments = [...parsedDocuments, ...parsedAdditionalDocuments].map((document) => ({
        kind: document,
        order: Number(orderId),
      }));

      handleCreateDocuments(preparedDocuments);
    },
    [orderId, handleCreateDocuments],
  );

  return {
    control,
    onSubmit,
    handleSubmit,
    isDocumentsLoading,
  };
};
