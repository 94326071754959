import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: (isOrderChatMode: boolean) => ({
    maxWidth: isOrderChatMode ? '70%' : '50%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      maxWidth: '70%',
    },
  }),
};
