import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { UnitsType } from 'app/api/quotes';
import { formatNumber } from 'utils/formatText';
import { getUnitDisplayValue } from 'pages/QuoteRequestPage/utils';
import { styles } from './styles';

interface QuoteTotalProps {
  isSellerInputNeeded: boolean;
  totalAmounts: { unit: UnitsType; amount: number }[];
  totalPrice: string | number;
}

export const QuoteTotal: FC<QuoteTotalProps> = ({ totalPrice, totalAmounts, isSellerInputNeeded }) => {
  return (
    <Box sx={styles.wrap}>
      <Typography sx={styles.totals}>Latest Offer Totals</Typography>
      <Box sx={styles.items}>
        <Box sx={styles.itemWrap}>
          <Box sx={styles.title}>Total Weight</Box>
          <Box sx={styles.amount}>
            {totalAmounts
              .map(({ unit, amount }) => `${formatNumber(amount)} ${getUnitDisplayValue(unit, true)}`)
              .join(' + ')}
          </Box>
        </Box>
        <Box sx={styles.itemWrap}>
          <Box sx={styles.title}>Total Price</Box>
          <Box sx={styles.price}>{isSellerInputNeeded ? '' : totalPrice || '-'}</Box>
        </Box>
      </Box>
    </Box>
  );
};
