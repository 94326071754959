import { FC, ReactNode } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import '@sendbird/uikit-react/dist/index.css';
import 'simplebar-react/dist/simplebar.min.css';

import MUIThemeProvider from 'theme/MuiThemeProvider';

const queryClient = new QueryClient();

interface AppWrapperProps {
  children: ReactNode;
}

export const AppWrapper: FC<AppWrapperProps> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <MUIThemeProvider>{children}</MUIThemeProvider>
  </QueryClientProvider>
);
