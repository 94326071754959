import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Quote } from 'app/api/quotes';
import { RootState } from 'state/store';

// Define a type for the slice state
export interface QuotesState {
  [id: string]: Quote;
}

// Define the initial state using that type
const initialState: QuotesState = {};

export const counterSlice = createSlice({
  name: 'quotes',
  initialState,
  reducers: {
    setQuotes: (state, action: PayloadAction<Quote[]>) => {
      action.payload.forEach((quote) => {
        state[quote.id] = quote;
      });
    },
    setQuote: (state, action: PayloadAction<Quote>) => {
      const quoteId = action.payload.id;
      state[quoteId] = action.payload;
    },
  },
});

export const { setQuotes, setQuote } = counterSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
const selectQuotes = (state: RootState) => state.quotes;
export const selectActiveQuotes = createSelector(selectQuotes, (quotes) => {
  const activeQuotes = Object.values(quotes).filter((quote) => quote.status === 'OPEN');
  return activeQuotes;
});

export default counterSlice.reducer;
