import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonButton } from '../../../../components/CommonButton';
import { LoadingButton } from '../../../../components/LoaderButton';
import { TextFormField } from '../../../../components/TextFormField';
import { ResetPasswordFormValues } from '../../types';
import { useResetPasswordForm } from './hooks';
import { styles } from './styles';
import { resetPasswordFormValidation } from './validation';

interface ResetPasswordFormProps {
  isLoading: boolean;
  onSubmit: (values: ResetPasswordFormValues) => void;
  onCancelButtonClick: () => void;
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ isLoading, onSubmit, onCancelButtonClick }) => {
  const { control, handleSubmit } = useResetPasswordForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ ...styles.title }}>Reset Password</Typography>
      <Typography sx={{ ...styles.description }}>
        Forgot your password? No worries! Enter your email address below and we&apos;ll send you instructions on how to
        reset it.
      </Typography>
      <TextFormField
        control={control}
        name="email"
        rules={resetPasswordFormValidation.email}
        placeholder="Email"
        autoComplete="on"
      />
      <Box sx={{ ...styles.buttonsContainer }}>
        <LoadingButton loading={isLoading} sx={styles.button} type="submit">
          Send Request
        </LoadingButton>
        <CommonButton sx={styles.button} variant="outlined" onClick={onCancelButtonClick}>
          Cancel
        </CommonButton>
      </Box>
    </form>
  );
};
