import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'grid',
    gap: '8px',
    paddingRight: '8px',
    gridAutoRows: '1fr',
    maxHeight: '380px',
    overflowY: 'auto',
    ...scrollMixin,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '100%',
      minHeight: '100%',
      overflowX: 'hidden',
      marginRight: '-4px',
    },
  },
  loaderWrap: (hasHeight: boolean) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '8px 0',
    height: `${hasHeight ? '36px' : 'auto'}`,
  }),
  title: {
    display: 'none',
    paddingLeft: '8px',
    lineHeight: '28px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
    fontSize: '14px',
    marginBottom: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
    },
  },
};
