import { GeneticType, OrganicType } from 'app/api/products';
import { SortTypes } from '../types';

export const producttFilterAllValue = 'all';

export const geneticFilterValues = [
  {
    value: producttFilterAllValue,
    label: 'All',
  },
  {
    value: GeneticType.hybrid,
    label: 'Hybrid',
  },
  {
    value: GeneticType.openPollinated,
    label: 'Open-Pollinated',
  },
];

export const organicFilterValues = [
  {
    value: producttFilterAllValue,
    label: 'All',
  },
  {
    value: OrganicType.organicSeed,
    label: 'Organic Only',
  },
];

export enum FiltersNames {
  GENETIC_TYPE = 'geneticType',
  COLOR = 'color',
  CONTINENT = 'continent',
  SORT = 'sort',
  MIN_WEIGHT = 'minWeight',
  MAX_WEIGHT = 'maxWeight',
  APPLY_WEIGHT_FILTER = 'isWeight',
  MIN_MATURITY = 'minMaturity',
  MAX_MATURITY = 'maxMaturity',
  APPLY_MATURITY_FILTER = 'isMaturity',
  RESISTANCES = 'resistances',
  APPLY_RESISTANCES_FILTER = 'isResistances',
  ORGANIC = 'organic',
}

export enum AdditionalFiltersControlNames {
  WEIGHT = 'weight',
  MATURITY = 'maturity',
  INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT = 'includeProductsWithIncompleteWeight',
  INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY = 'includeProductsWithIncompleteMaturity',
  INCLUDE_PRODUCTS_WITH_INCOMPLETE_RESISTANCES = 'includeProductsWithIncompleteResistances',
}

export const SEARCH_TYPE = 'search';

export const SEARCH_KEY = 'search';

export const FEATURED_TYPE = 'Featured Products';

export const FEATURED_SELLERS_TYPE = 'Featured Sellers';

export const sortValues = [
  {
    value: SortTypes.RECENT,
    label: 'Most Relevant',
  },
  {
    value: SortTypes.STOCK,
    label: 'Stock Quantity (High To Low)',
  },
];

export const colorFilterAll = {
  value: 'all',
  label: 'All',
};

export const continentFilterAll = {
  value: 'all',
  label: 'All',
};

export const resistancesFilterAll = {
  value: 'all',
  label: 'All',
};

export const SLIDER_STEPS_COUNT = 20;

export const WEIGHT_SLIDER_STEP_ROUND_VALUE = 10;
export const MATURITY_SLIDER_STEP_ROUND_VALUE = 5;

export const INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT_LABEL = 'Include products with incomplete weight data';
export const INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY_LABEL = 'Include products with incomplete maturity data';
export const WEIGHT_UNIT = 'g';
export const MATURITY_UNIT = 'days';
