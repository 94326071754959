import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  label: (isDark: boolean) => ({
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 500,
    color: isDark ? customTheme.custom.black.B100 : customTheme.custom.black.B70,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  }),
  controlLabel: {
    margin: 0,
    padding: '2px 12px',
    alignItems: 'start',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      alignItems: 'center',
    },
  },
};
