import * as tracker from 'analytics/tracker';
import { AnalyticsName } from 'analytics';

import { apiRoutes } from '../apiRoutes';
import { customerApi, fileUploadApi } from '../index';
import {
  AddMessageData,
  MessagesListData,
  MessagesListRequest,
  UnreadMessagesIndicatorRequest,
  UnreadMessagesIndicatorData,
} from './types';

export const getMessagesData = async (params: MessagesListRequest) => {
  const response = await customerApi.get<MessagesListData>(apiRoutes.messages._, { params });

  return response?.data;
};

export const addMessage = async (data: AddMessageData) => {
  const res = await fileUploadApi.post<MessagesListData>(apiRoutes.messages._, data);

  const analyticsOptions = {
    channel: data.channel,
    author: data.author,
    quote: data.quote,
    text: data.text,
    has_image: !!data.image,
    has_email: /email/i.test(data.text || ''),
    has_per: /per/i.test(data.text || ''),
    has_currency: /$€¥£₹/i.test(data.text || ''),
  };
  tracker.track(AnalyticsName.SEND_MESSAGE, analyticsOptions);
  return res;
};

export const getUnreadMessagesIndicator = async ({ quoteId }: UnreadMessagesIndicatorRequest) => {
  const response = await customerApi.get<UnreadMessagesIndicatorData>(
    apiRoutes.messages.unreadMessagesIndicator(quoteId),
  );

  return response?.data;
};
