import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from '../../theme/MuiThemeProvider/theme';

export const styles = {
  navWrap: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flexWrap: 'wrap',
    },
  },
  navItem: {
    position: 'relative',
    padding: '0 8px',

    a: {
      fontSize: '12px',
      color: customTheme.custom.black.B70,
      textDecoration: 'none',
    },
    ':hover': {
      a: {
        color: customTheme.custom.black.B100,
      },
    },
  },
  divider: {
    width: '1px',
    height: '16px',
    margin: '0 8px',
    backgroundColor: customTheme.custom.black.B20,
  },
};
