import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isOrderChatMode: boolean) => ({
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 24px',
    borderRadius: '10px 10px 0 0',
    backgroundColor: customTheme.palette.primary.P5,
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,

    ...(isOrderChatMode && {
      width: '100%',
      paddingRight: '12px',
      borderBottom: 'none',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minHeight: '32px',
      maxHeight: '32px',
      padding: '4px 24px',
    },
  }),
  actionsWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  iconWrap: {
    width: '24px',
    height: '24px',
    color: customTheme.custom.black.B70,
    cursor: 'pointer',
  },
  title: (isOrderChatMode: boolean) => ({
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
    color: customTheme.custom.black.B100,

    ...(isOrderChatMode && {
      fontSize: '20px',
      lineHeight: '28px',
    }),
  }),
  arrowIconWrap: (expanded: boolean) => ({
    innerWidth: '24px',
    height: '24px',
    transform: expanded ? 'rotate(180deg)' : 'rotate(0)',
  }),
};
