import { createContext, FC, ReactNode, Suspense, useCallback, useEffect, useMemo, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import * as tracker from 'analytics/tracker';
import { sendbirdSelectors, useSendbirdStateContext } from '@sendbird/uikit-react';
import * as Sentry from '@sentry/react';

import { AUTH_PAGES } from 'app/api';
import { logIn, logOut } from 'app/api/auth/service';
import { LoginResponse, LoginData, UserRole, LogOutRequest } from 'app/api/auth/types';
import { PayloadError } from 'app/common/types';
import { getUserData, patchUser } from 'app/api/user/service';
import { PatchUser, UserData } from 'app/api/user/types';
import { useIsAuthRoute, useMaintenanceMode, useSendBirdWrap } from 'hooks';
import { AnalyticsName, useTrackPageViewedEvent } from 'analytics';
import { sessionObserver } from 'utils/observer';
import {
  storage,
  removeSession,
  getAccessInfo,
  updateSession,
  updateSessionToken,
  setSendbirdUserId,
  clearSendbirdConfig,
  setSendbirdToken,
  clearLoginPath,
  getLoginPath,
} from 'utils/storage';
import { createChatToken } from 'app/api/SBChat';
import { useAppDispatch } from 'state/hooks';
import { setOnboardingModal, setUserSignedIn } from 'state/slices/account';
import { NOTIFICATIONS_COUNT_QUERY_KEY } from 'layout/Notifications/constants';

import { ROUTES } from '../routes/constants';
import { getDefaultUserRoute } from './utils';

export const AuthContext = createContext<IAuth>({} as IAuth);

export type LoginMutation = UseMutationResult<LoginResponse, unknown, LoginData>;

export type LogoutMutation = UseMutationResult<unknown, PayloadError, LogOutRequest>;

interface IAuth {
  token: string | null;
  activeUser: UserData | null;
  sendbirdUserId: string;
  activeUserCompanyId: string;
  // If user has multiple "active" roles
  isMultiRole: boolean;
  // If user has multiple roles
  hasMultiRole: boolean;
  isBuyer: boolean;
  login: LoginMutation;
  onLogin: (data: LoginResponse) => void;
  logout: () => void;
  isLoginLoading: boolean;
  signOut: LogoutMutation;
  setUserData: (userData: UserData[]) => void;
  changeActiveUser: (customerId?: number) => void;
  users: UserData[];
  clearUserData: () => void;
}

interface ProviderProps {
  children: ReactNode;
}

const Loading = () => <div />;

export const AuthProvider: FC<ProviderProps> = ({ children }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [token, setToken] = useState<string | null>(storage.getItem('token'));
  const [activeUserRole, setActiveUserRole] = useState<string | null>(storage.getItem('userRole'));
  const [activeUser, setActiveUser] = useState<UserData | null>(null);
  const [users, setUsers] = useState<UserData[]>([]);

  const { setSbToken, setSbUserId } = useSendBirdWrap();
  const globalSBStore = useSendbirdStateContext();
  const disconnectSB = sendbirdSelectors.getDisconnect(globalSBStore);

  const { isAuthRoute } = useIsAuthRoute();

  const navigate = useNavigate();
  const location = useLocation();

  // Analytics for each new page route view
  useTrackPageViewedEvent();

  useMaintenanceMode();

  const sendbirdUserId = useMemo(() => activeUser?.user.chatId ?? '', [activeUser?.user.chatId]);
  const activeUserCompanyId = useMemo(() => activeUser?.company.id.toString() ?? '', [activeUser?.company.id]);
  const isBuyer = useMemo(() => activeUserRole === UserRole.BUYER, [activeUserRole]);

  const getUser = useQuery(['user-data'], () => getUserData(), {
    enabled: false,
  });

  const getChatToken = useQuery(['sendbird-token'], () => createChatToken(), {
    enabled: false,
  });

  const { mutate: handleUpdateUser } = useMutation(async (data: PatchUser & { id: number }) => {
    const { id, ...patchData } = data;
    await patchUser(id, patchData);
  }, {});

  const signOut = useMutation<unknown, PayloadError, LogOutRequest>(({ token }) => logOut(token), {
    onSuccess: () => {
      removeSession();
      setToken(null);
      setActiveUserRole(null);
      setSbToken(null);
      setSbUserId(null);
      setUsers([]);
      clearLoginPath();
      dispatch(setUserSignedIn({ isSignedIn: false, user: null }));

      disconnectSB()
        .then(() => {
          /* empty */
        })
        .catch(() => {
          /* empty */
        });

      tracker.reset();
      tracker.track(AnalyticsName.USER_LOGGED_OUT);
      navigate(ROUTES.buyer._, { replace: true });
      window.location.reload();
    },
  });

  const logout = useCallback(() => {
    const token = getAccessInfo('token');

    if (token) {
      clearSendbirdConfig();
      signOut.mutate({ token });
    } else {
      setToken(null);
      setActiveUserRole(null);
      setSbToken(null);
      setSbUserId(null);
      setUsers([]);
      dispatch(setUserSignedIn({ isSignedIn: false, user: null }));
    }
    storage.deleteItem('customerId');
  }, [signOut, setSbToken, setSbUserId, dispatch]);

  const logoutHandler = useCallback(
    (isAuth: boolean) => {
      if (!isAuth && !isAuthRoute) {
        logout();
      }
    },
    [isAuthRoute, logout],
  );

  const setUserData = useCallback(
    (userData: UserData[], isLogin = false) => {
      if (!userData) {
        dispatch(setUserSignedIn({ isSignedIn: false, user: null }));
        return undefined;
      }

      setUsers(userData);

      let activeUserSet = null;
      const currentCustomerId = storage.getItem('customerId');
      if (!activeUserRole || !userData.find((user) => user.kind === activeUserRole)) {
        const validCustomers = userData.filter((c) => c.isActive);
        if (validCustomers.length === 0) {
          dispatch(setUserSignedIn({ isSignedIn: false, user: null }));
          logout();
          return undefined;
        }
        let user =
          validCustomers?.length === 1
            ? validCustomers[0]
            : validCustomers.filter((u) => u.isActive).find((user) => user.kind === UserRole.SELLER) ||
              validCustomers[0];

        const currentUserCustomer = validCustomers.find(
          (user) => user.isActive && user.id === Number(currentCustomerId),
        );
        if (currentUserCustomer) {
          user = currentUserCustomer;
        } else {
          storage.deleteItem('customerId');
        }

        updateSession(user);
        setSendbirdUserId(user?.user.chatId);
        setSbUserId(user?.user.chatId);
        setToken(storage.getItem('token'));
        setSbToken(storage.getItem('sendbirdToken'));
        setActiveUserRole(user?.kind);
        setActiveUser(user);
        dispatch(setUserSignedIn({ isSignedIn: true, user }));
        activeUserSet = user;
      }

      if (activeUserRole) {
        let user = userData.find((user) => user.kind === activeUserRole);
        if (user && !user?.isActive) {
          user = userData.find((user) => user.isActive);
        }
        const currentUserCustomer = userData.find((user) => user.isActive && user.id === Number(currentCustomerId));
        if (currentUserCustomer) {
          user = currentUserCustomer;
        } else {
          storage.deleteItem('customerId');
        }

        if (user) {
          updateSession(user);
          setSendbirdUserId(user.user.chatId);
          setSbUserId(user.user.chatId);
          setActiveUserRole(user.kind);
          setActiveUser(user);
          dispatch(setUserSignedIn({ isSignedIn: true, user }));
          activeUserSet = user;
        }
      }
      if (activeUserSet) {
        const traits = {
          firstName: activeUserSet.user.firstName,
          lastName: activeUserSet.user.lastName,
          email: activeUserSet.user.email,
          kind: activeUserSet.kind,
          companyName: activeUserSet.company.name,
          companyId: activeUserSet.company.id,
        };
        tracker.identify(activeUserSet.user.id.toString(), traits);
        storage.setItem('customerId', activeUserSet.id.toString());
      }
      if (!isLogin) {
        const isUserBuyer = activeUserSet?.kind === 'BUYER';
        if (isUserBuyer && activeUserSet?.user.hasLoggedIn && !activeUserSet?.user.hasSeenOnboarding) {
          dispatch(setOnboardingModal({ isOpen: true }));
          handleUpdateUser({ id: activeUserSet.user.id, hasLoggedIn: true, hasSeenOnboarding: true });
        }
      }
      return activeUserSet;
    },
    [activeUserRole, handleUpdateUser, setSbToken, setSbUserId, dispatch, logout],
  );
  const queryClient = useQueryClient();

  const changeActiveUser = useCallback(
    (customerId?: number) => {
      let customer;
      if (!customerId) {
        customer = users.find((user) => user.kind !== activeUserRole) || activeUser;
      } else {
        customer = users.find((user) => user.id === customerId);
      }

      if (customer) {
        updateSession(customer);
        setSendbirdUserId(customer.user.chatId);
        setSbUserId(customer.user.chatId);
        setActiveUserRole(customer.kind);
        setActiveUser(customer);
        storage.setItem('customerId', customer.id.toString());
        const isUserBuyer = customer?.kind === 'BUYER';

        if (isUserBuyer && customer?.user.hasLoggedIn && !customer?.user.hasSeenOnboarding) {
          dispatch(setOnboardingModal({ isOpen: true }));
          handleUpdateUser({ id: customer.user.id, hasLoggedIn: true, hasSeenOnboarding: true });
        }
        // NOTIFICATIONS_COUNT_QUERY_KEY
        navigate(getDefaultUserRoute(isBuyer));
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: [NOTIFICATIONS_COUNT_QUERY_KEY] });
        if (!isBuyer) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          queryClient.invalidateQueries({ queryKey: ['quote-requests'] });
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          queryClient.invalidateQueries({ queryKey: ['orders'] });
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          queryClient.invalidateQueries({ queryKey: ['orders'] });
        }
      }
    },
    [
      queryClient,
      activeUserRole,
      dispatch,
      isBuyer,
      handleUpdateUser,
      setActiveUserRole,
      navigate,
      users,
      activeUser,
      setSbUserId,
    ],
  );

  const clearUserData = useCallback(() => {
    removeSession();
    setActiveUserRole(null);
    setUsers([]);
    storage.deleteItem('customerId');
  }, []);

  const onLogin = useCallback(
    async (data: LoginResponse) => {
      updateSessionToken(data.token);

      // NOTES: refresh chat token must come before setUserData to ensure proper sendbird token is set
      await getChatToken.refetch().then(({ data }) => {
        setSendbirdToken(data?.token ?? '');
      });

      const activeUser = await getUser.refetch().then(({ data }) => {
        if (data) {
          const activeUser = setUserData(data, true);
          tracker.track(AnalyticsName.USER_LOGGED_IN);
          return activeUser;
        }
        return null;
      });

      const loginPath = getLoginPath();
      const isUserBuyer = activeUser?.kind === 'BUYER';
      if (!activeUser?.user.hasLoggedIn) {
        const userId = activeUser?.user.id as number;
        navigate(ROUTES.common.onboarding._);
        handleUpdateUser({ id: userId, hasLoggedIn: true, hasSeenOnboarding: true });
      } else if (loginPath) {
        navigate(loginPath);
        clearLoginPath();
      } else {
        navigate(getDefaultUserRoute(isUserBuyer));
      }
    },
    [getChatToken, getUser, handleUpdateUser, navigate, setUserData],
  );

  const login = useMutation((userData: LoginData) => logIn(userData), {
    onSuccess: onLogin,
  });

  const isLoginLoading = useMemo(() => login.isLoading || getUser.isLoading, [login, getUser]);

  // Only allow staff to login to multiple accounts
  const isMultiRole = useMemo(() => users?.length > 1 && users?.filter((u) => u.isActive).length > 1, [users]);
  const hasMultiRole = useMemo(() => users?.length > 1, [users]);

  useEffect(() => {
    sessionObserver.subscribe(logoutHandler);

    return () => sessionObserver.unsubscribe(logoutHandler);
  }, [logoutHandler]);

  useEffect(() => {
    if (token && AUTH_PAGES.includes(location.pathname) && location.pathname !== ROUTES.auth.login) {
      navigate(getDefaultUserRoute(isBuyer));
    }
  }, [token, location, navigate, isBuyer]);

  useEffect(() => {
    Sentry.setUser({ id: activeUser?.id });
  }, [activeUser?.id]);

  const value = useMemo(
    () => ({
      token,
      activeUser,
      sendbirdUserId,
      activeUserCompanyId,
      hasMultiRole,
      isMultiRole,
      isBuyer,
      login,
      logout,
      isLoginLoading,
      onLogin,
      signOut,
      setUserData,
      changeActiveUser,
      users,
      clearUserData,
    }),
    [
      token,
      activeUser,
      sendbirdUserId,
      activeUserCompanyId,
      login,
      logout,
      isLoginLoading,
      onLogin,
      signOut,
      setUserData,
      changeActiveUser,
      hasMultiRole,
      isMultiRole,
      isBuyer,
      users,
      clearUserData,
    ],
  );
  return (
    <Suspense fallback={<Loading />}>
      <AuthContext.Provider value={value}>
        {children}
        <Outlet />
      </AuthContext.Provider>
    </Suspense>
  );
};
