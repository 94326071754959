import { format } from 'date-fns';

import { OrdersShipmentDetail } from 'app/api/orders';
import { shipmentDateFormat } from '../constants';
import { OceanFreightDataType, SummaryDataType, TimingDataType } from '../types';

export const getSummaryData = ({ origin, destination, transportMode }: OrdersShipmentDetail): SummaryDataType => ({
  origin,
  destination,
  transportMode,
});

export const getTimingData = ({
  departure,
  estimatedArrival,
  estimatedTimeToDestination,
}: OrdersShipmentDetail): TimingDataType => ({
  departure,
  estimatedArrival,
  estimatedTimeToDestination,
});

export const getOceanFreightData = ({
  departurePort,
  receivingPort,
  vesselName,
  containerNumber,
}: OrdersShipmentDetail): OceanFreightDataType => ({
  departurePort,
  receivingPort,
  vesselName,
  containerNumber,
});

export const getShipmentDetailsDate = (date?: string) => {
  let result = date ?? '';

  try {
    if (date) {
      result = format(new Date(date), shipmentDateFormat);
    }
  } catch (e) {
    /* empty */
  }

  return result;
};

export const getDaysLabel = (count: number) => {
  const label = count === 1 ? 'Day' : 'Days';
  return `${count.toString()} ${label}`;
};
