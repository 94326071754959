import { FC } from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrowLeftSmall.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { styles } from './styles';

interface ChatHeaderProps {
  channelLogo: string;
  channelName: string;
  isLogoPlaceholder: boolean;
  handleSearchButtonClick: () => void;
  handleBackButtonClick?: () => void;
  handleInfoButtonClick?: () => void;
}

export const ChatHeader: FC<ChatHeaderProps> = ({
  isLogoPlaceholder,
  channelLogo,
  channelName,
  handleSearchButtonClick,
  handleBackButtonClick,
  handleInfoButtonClick,
}) => {
  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.infoWrap}>
        <IconButton sx={styles.backButton} onClick={handleBackButtonClick}>
          <ArrowLeftIcon />
        </IconButton>
        {!isLogoPlaceholder && <Box component="img" loading="lazy" src={channelLogo} sx={styles.logo} />}
        <Typography sx={styles.name}>{channelName}</Typography>
      </Box>
      <Box sx={styles.actionsWrap}>
        <IconButton sx={styles.searchButton} onClick={handleSearchButtonClick}>
          <SearchIcon />
        </IconButton>
        <IconButton sx={styles.infoButton} onClick={handleInfoButtonClick}>
          <InfoIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
