import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      marginBottom: '24px',
    },
  },
  title: {
    width: 'fit-content',
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    color: customTheme.custom.black.B100,
  },
  inner: {
    display: 'grid',
    padding: '4px 0',
    borderRadius: '8px',
    background: customTheme.custom.white,
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.15)',
  },
};
