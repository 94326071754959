import { useCallback } from 'react';

import * as tracker from 'analytics/tracker';

import { AnalyticsLinkType, AnalyticsName } from 'analytics';

export interface LinkClickedEvenetProps {
  path: string;
  text: string;
  type: AnalyticsLinkType;
  isMailTo?: boolean;
}

export const useLinkClickedEvent = () => {
  const { origin } = window.location;

  const handleTrackLinkClickEvent = useCallback(
    ({ path, text, type, isMailTo = false }: LinkClickedEvenetProps) => {
      const analyticsOptions = {
        destination_url: isMailTo ? path : encodeURI(`${origin}${path}`),
        destination_path: isMailTo ? path : encodeURI(path),
        link_text: text,
        link_type: type,
      };

      tracker.track(AnalyticsName.LINK_CLICKED, analyticsOptions);
    },
    [origin],
  );

  return {
    handleTrackLinkClickEvent,
  };
};
