import { useQuery } from 'react-query';
import { getProductData } from 'app/api/products/service';

interface UseProductRowProps {
  productId: number;
  isOpen: boolean;
}

export const useEditProduct = ({ productId, isOpen }: UseProductRowProps) => {
  const { isLoading, data: productDetails } = useQuery(['company', productId], () => getProductData(productId), {
    enabled: isOpen,
    refetchOnWindowFocus: false,
    retry: false,
  });
  return {
    productDetails,
    isLoading,
  };
};
