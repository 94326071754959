import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  amountField: {
    margin: '0',
  },
  amountFieldInput: {
    height: '21px',
  },
  priceConfirmed: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
    color: customTheme.palette.secondary.S100,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  },
  undo: {
    fontSize: '12px',
    fontWeight: '400',
    color: customTheme.palette.info.main,
    textDecoration: 'underline',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
    },
  },
};
