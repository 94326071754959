import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  loaderWrap: {
    display: 'flex',
    justifyContent: 'center',
    margin: '16px 0 8px 0',
    height: '36px',
  },
  wrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gridRow: 5,
    },
  },
};
