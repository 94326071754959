const CHAT_PAGE_PATH = 'messages';
const DASHBOARD_PAGE_PATH = 'dashboard';
const QUOTE_REQUEST_PAGE_PATH = 'quote-request';

export const getUrlPathname = (link: string, isMobile: boolean): string => {
  const url = new URL(link);
  const { pathname, search, searchParams } = url;
  let path = '';

  const isChatRoute = isMobile && pathname.includes(CHAT_PAGE_PATH);
  const isQuoteRoute = isMobile && pathname.includes(DASHBOARD_PAGE_PATH);

  if (isChatRoute) {
    const chatId = searchParams.get('id') ?? '';
    path = `/${CHAT_PAGE_PATH}/${chatId}`;
  } else if (isQuoteRoute) {
    const quoteId = searchParams.get('quoteId') ?? '';
    path = `/${QUOTE_REQUEST_PAGE_PATH}/${quoteId}`;
  } else {
    path = `${pathname}${search}`;
  }

  return path;
};
