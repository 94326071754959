import { FC, useState } from 'react';

import { Control } from 'react-hook-form';
import { Accordion, AccordionSummary } from '@mui/material';

import { ChatFormData, SBChatMode } from 'app/api/SBChat';
import { SBConversation, SBChannelHeaderPlaceholder } from 'components';
import { styles } from './styles';

interface ChatModalProps {
  chatId: string;
  control: Control<ChatFormData>;
  disableSendButton: boolean;
  isLoading: boolean;
  otherCompanyName: string;
  handleSendMessage: () => void;
}

export const ChatModal: FC<ChatModalProps> = ({
  chatId,
  control,
  disableSendButton,
  isLoading,
  otherCompanyName,
  handleSendMessage,
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleChange = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion expanded={expanded} sx={styles.paper}>
      <AccordionSummary sx={styles.accordionSummary}>
        <SBChannelHeaderPlaceholder
          channelUrl={chatId}
          companyName={otherCompanyName}
          expanded={expanded}
          handleClose={handleChange}
          mode={SBChatMode.ORDER_DETAILS}
        />
      </AccordionSummary>
      <SBConversation
        otherCompanyName={otherCompanyName}
        channelUrl={chatId}
        control={control}
        disableSendButton={disableSendButton}
        isSendMessageLoading={isLoading}
        mode={SBChatMode.ORDER_DETAILS}
        handleSendMessage={handleSendMessage}
        handleClose={handleChange}
      />
    </Accordion>
  );
};
