import { FC, Fragment } from 'react';

import { Box, Typography } from '@mui/material';

import { QuoteCardProps } from './index';
import { styles } from './styles';

type QuoteProductsListProps = Pick<QuoteCardProps, 'products'>;

export const QuoteProductsList: FC<QuoteProductsListProps> = ({ products }) => {
  return (
    <Box sx={{ ...styles.productsList }}>
      {products.map(({ id, crop, variety }, index) => (
        <Fragment key={id}>
          <Typography component="span" sx={{ ...styles.productItem }} fontWeight={600}>
            {crop}
          </Typography>
          <Typography component="span" sx={{ ...styles.productItem }} fontWeight={400}>
            {variety && ` (${variety})`}
            {index !== products.length - 1 && `, `}
          </Typography>
        </Fragment>
      ))}
    </Box>
  );
};
