import { useMemo } from 'react';

import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { BAD_REQUSET_ERROR_CODE, NOT_FOUND_ERROR_CODE } from 'app/api/errors';
import { getOrderShipmentData, SHIPMENT_NOT_FOUND_ERROR } from 'app/api/orders';
import { ROUTES } from 'app/routes/constants';
import { notificationObserver } from 'utils/observer';
import { getOceanFreightData, getShipmentDetailsDate, getSummaryData, getTimingData } from '../utils';

export const useShipmentTrackingPage = () => {
  const { id = '' } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(['shipment-detail', id], () => getOrderShipmentData(Number(id)), {
    refetchOnWindowFocus: false,
    retry: false,
    onError: (error: AxiosError) => {
      const status = error.response?.status;
      const showNotFoundError = status === NOT_FOUND_ERROR_CODE || status === BAD_REQUSET_ERROR_CODE;
      const errorMessage = showNotFoundError ? SHIPMENT_NOT_FOUND_ERROR : error.message;

      notificationObserver.publish({
        type: 'error',
        title: errorMessage,
      });

      navigate(ROUTES._);
    },
  });

  const oceanFreightData = useMemo(() => (data ? getOceanFreightData(data) : null), [data]);
  const timingData = useMemo(() => (data ? getTimingData(data) : null), [data]);
  const summaryData = useMemo(() => (data ? getSummaryData(data) : null), [data]);
  const statusesData = useMemo(() => data?.shipmentStatuses ?? [], [data?.shipmentStatuses]);
  const src = useMemo(() => data?.iframeImg ?? '', [data?.iframeImg]);
  const lastUpdated = useMemo(() => getShipmentDetailsDate(data?.lastUpdate), [data?.lastUpdate]);

  return {
    isLoading,
    lastUpdated,
    oceanFreightData,
    src,
    statusesData,
    summaryData,
    timingData,
  };
};
