import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  link: {
    textDecoration: 'none',
  },
  varietyWrap: (fullWidth: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    width: fullWidth ? '100%' : '156px',
  }),
  productImage: {
    width: '32px',
    minWidth: '32px',
    height: '32px',
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '60px',
      minWidth: '60px',
      height: '60px',
    },
  },
  crop: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  variety: (fullWidth: boolean, isThousandSeeds: boolean) => ({
    fontSize: '10px',
    lineHeight: '12px',
    maxHeight: '12px',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: fullWidth ? '100%' : '162px',
    wordBreak: 'break-all',
    color: customTheme.custom.black.B100,
    ...(isThousandSeeds && {
      maxWidth: fullWidth ? '100%' : '152px',
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
  }),
  dataWrap: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'grid',
      gap: '2px',
    },
  },
};
