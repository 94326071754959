import { Quote, QuoteProduct, UnitsType } from 'app/api/quotes';
import { format } from 'date-fns';
import { getOrderSubmittedEventDocuments, getOrderSubmittedEventProducts } from 'analytics';

const dateFormat = 'yyyy-MM-dd';

export const getOrderSubmittedAnalyticOptions = (
  orderId: number,
  quoteData: Quote | undefined,
  activeQuoteProducts: QuoteProduct[],
  totalAmount: number | undefined,
  idealDeliveryDateStart: Date,
  idealDeliveryDateEnd: Date,
  documentsNeeded: {
    kind: string;
  }[],
) => {
  return {
    supplier_name: quoteData?.seller?.name ?? '',
    supplier_id: quoteData?.seller?.id ?? '',
    related_quote_id: quoteData?.id ?? '',
    order_id: orderId,
    total: quoteData?.totalPrice,
    number_of_products: activeQuoteProducts.length,
    products: getOrderSubmittedEventProducts({
      products: activeQuoteProducts,
      seller: quoteData?.seller,
    }),
    ideal_delivery_date_start: idealDeliveryDateStart ? format(idealDeliveryDateStart, dateFormat) : '',
    ideal_delivery_date_end: idealDeliveryDateEnd ? format(idealDeliveryDateEnd, dateFormat) : '',
    documents_needed: getOrderSubmittedEventDocuments({ documents: documentsNeeded ?? [] }),
    number_of_messages: quoteData?.totalMessagesNumber ?? 0,
    number_of_attachments: quoteData?.totalAttachmentsNumber ?? 0,
    number_of_cycles: quoteData?.dealInteractionsCounter ?? 0,
    unit: (quoteData?.unitsType ?? UnitsType.KG).toLowerCase(),
    total_amount: totalAmount,
    total_weight: quoteData?.unitsType === UnitsType.THOUSAND_SEEDS ? '' : totalAmount,
    is_sample_request: !!quoteData?.isSampleType,
  };
};
