import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '4px',
    },
  },
  acceptButton: {
    height: '32px',
    minWidth: 'none',
    flex: '1',
    color: customTheme.custom.white,
    backgroundColor: customTheme.custom.success,
    overflow: 'hidden',

    '&:hover': {
      backgroundColor: customTheme.custom.success,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '28px',
      minWidth: 'fit-content',
      padding: '6px 8px',
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  rejectButton: {
    height: '32px',
    minWidth: 'none',
    flex: '1',
    color: customTheme.custom.white,
    backgroundColor: customTheme.custom.error,

    '&:hover': {
      backgroundColor: customTheme.custom.error,
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '28px',
      minWidth: 'fit-content',
      padding: '6px 8px',
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  actionWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '14px',
  },
  statusWrap: (isAccepted: boolean) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
    color: isAccepted ? customTheme.custom.success : customTheme.custom.error,
  }),
  undoButton: {
    height: '18px',
    width: '32px',
    minWidth: '32px',
    padding: 0,
    fontSize: '12px',
    lineHeight: '18px',
    color: customTheme.custom.black.B70,
    textDecoration: 'underline',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
};
