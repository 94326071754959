import { FC, ChangeEvent, useEffect, useRef, useCallback } from 'react';

import { Box, Typography } from '@mui/material';

import { DocumentFolderStatus, OrderDocument } from 'app/api/orders/types';
import { CommonModal, PageLoader } from 'components';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { useDeviceType } from 'hooks';
import { useFilesDropzone } from '../../hooks';
import { ViewFile } from '../ViewFile';
import { AllCompleteBox } from '../AllCompleteBox';
import { OnDragPlaceholder } from '../OnDragPlaceholder';
import { useViewDocumentsPopup } from './hooks';
import { styles } from './styles';

export interface ViewDocumentsPopupProps {
  isBuyer: boolean;
  isLoading: boolean;
  name: string;
  files: OrderDocument[];
  isOpen: boolean;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  status: DocumentFolderStatus;
  isBuyerActionsAllowed: boolean;
  isSellerActionsAllowed: boolean;
  isDropzoneDisabled: boolean;
  isFileError: boolean;
  completeDate?: string;
  handleClose: () => void;
  handleClearErrors: () => void;
  handleOpenDocumentView: (file: OrderDocument) => void;
  handleFileUpload: (e: File[] | ChangeEvent<HTMLInputElement>, queryKey: string[]) => void;
}

export const ViewDocumentsPopup: FC<ViewDocumentsPopupProps> = ({
  isBuyer,
  isLoading,
  files,
  name,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  status,
  isBuyerActionsAllowed,
  isSellerActionsAllowed,
  isFileError,
  isDropzoneDisabled,
  isOpen = false,
  completeDate = '',
  handleClose,
  handleOpenDocumentView,
  handleFileUpload,
  handleClearErrors,
}) => {
  const { isMobileSize } = useDeviceType();
  const placeholderRef = useRef<HTMLDivElement | null>(null);

  const { cardColor, subTitle, isCompleted, handleCloseViewModal, setDocumentAsUpdated } = useViewDocumentsPopup({
    completeDate,
    status,
    isFileError,
    handleClose,
  });

  const handleScrollToPlaceholder = useCallback(() => {
    placeholderRef?.current?.scrollIntoView();
  }, []);

  const { getDropzoneRootProps, isDragActive } = useFilesDropzone({
    disabled: isDropzoneDisabled,
    isFileError,
    invalidateQueryKey: ['order-documents'],
    onDrop: handleFileUpload,
    handleClearErrors,
    onSelectedFileError: handleClose,
    onDragEnter: handleScrollToPlaceholder,
  });

  useEffect(() => {
    if (!isLoading && !files.length) {
      handleCloseViewModal();
    }
  }, [files.length, handleCloseViewModal, isLoading]);

  return (
    <CommonModal
      title={name}
      isOpen={isOpen}
      handleClose={handleCloseViewModal}
      titleStyles={styles.title(cardColor)}
      paperStyles={(isMobileSize ? styles.mobilePaperStyles : styles.paperStyles) as SxPropsTypes}
      data-testid="view-documents-modal"
    >
      {isLoading ? (
        <Box sx={styles.loader} data-testid="loading">
          <PageLoader size={60} />
        </Box>
      ) : (
        <>
          <Typography sx={styles.subTitle(cardColor)}>{subTitle}</Typography>

          <Box sx={styles.filesList} {...getDropzoneRootProps()} data-testid="file-list">
            {isDragActive && (
              <Box ref={placeholderRef} data-testid="dropzone">
                <OnDragPlaceholder isDocument />
              </Box>
            )}

            {files.map((file) => (
              <ViewFile
                key={file.document}
                file={file}
                folderId={folderId}
                orderId={orderId}
                quoteId={quoteId}
                isSampleRequest={isSampleRequest}
                kind={name}
                isCompleted={isCompleted}
                isBuyerActionsAllowed={isBuyerActionsAllowed}
                isSellerActionsAllowed={isSellerActionsAllowed}
                setDocumentAsUpdated={setDocumentAsUpdated}
                handleOpenDocumentView={handleOpenDocumentView}
              />
            ))}
          </Box>

          {isBuyer && (
            <AllCompleteBox
              isAllCompleted={isCompleted}
              isBuyerActionsAllowed={isBuyerActionsAllowed}
              folderId={folderId}
              orderId={orderId}
              quoteId={quoteId}
              isSampleRequest={isSampleRequest}
              setDocumentAsUpdated={setDocumentAsUpdated}
            />
          )}
        </>
      )}
    </CommonModal>
  );
};
