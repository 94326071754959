import { FC } from 'react';

import { Radio, RadioProps } from '@mui/material';
import { ReactComponent as RadioChecked } from '../../assets/icons/radioChecked.svg';
import { ReactComponent as RadioUnchecked } from '../../assets/icons/radioUnchecked.svg';

import { styles } from './styles';

export const CommonRadioButton: FC = (props: RadioProps) => {
  return (
    <Radio
      sx={{ ...styles.radioButton }}
      color="primary"
      icon={<RadioUnchecked />}
      checkedIcon={<RadioChecked />}
      {...props}
    />
  );
};
