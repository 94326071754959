import { useCallback } from 'react';

import { useModal } from 'hooks';

interface UseQuoteDiscussionPopupsProps {
  id: number;
  handleOpenEditAmountModal: (id: number) => void;
  setCurrentRemoveId: (id: number | null) => void;
}

export const useQuoteDiscussionPopups = ({
  id,
  handleOpenEditAmountModal,
  setCurrentRemoveId,
}: UseQuoteDiscussionPopupsProps) => {
  const {
    open: isModalOpen,
    handleOpenModal: handleOpenRemoveProductModal,
    handleCloseModal: handleCloseRemoveProductModal,
  } = useModal();

  const {
    open: isLastModalOpen,
    handleOpenModal: handleLastProductOpen,
    handleCloseModal: handlelastProductClose,
  } = useModal();

  const handleEditAmountButtonClick = useCallback(() => {
    handleOpenEditAmountModal(id);
  }, [id, handleOpenEditAmountModal]);

  const handleCloseRemovePopup = useCallback(() => {
    setCurrentRemoveId(null);
    handleCloseRemoveProductModal();
  }, [handleCloseRemoveProductModal, setCurrentRemoveId]);

  const handleCloseRemoveLastPopup = useCallback(() => {
    setCurrentRemoveId(null);
    handlelastProductClose();
  }, [handlelastProductClose, setCurrentRemoveId]);

  return {
    isModalOpen,
    isLastModalOpen,
    handleOpenRemoveProductModal,
    handleLastProductOpen,
    handleEditAmountButtonClick,
    handleCloseRemovePopup,
    handleCloseRemoveLastPopup,
  };
};
