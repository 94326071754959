import { FC } from 'react';

import { Typography, Box } from '@mui/material';
import { styles } from './styles';

interface PageHeaderProps {
  text: string;
  subText?: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ text, subText }) => {
  return (
    <Box sx={styles.container}>
      <Typography component="h2" sx={{ ...styles.text }}>
        {text}
      </Typography>
      <Typography component="p" sx={{ ...styles.subText }}>
        {subText}
      </Typography>
    </Box>
  );
};
