interface WrapStylesProps {
  isCurrentUser: boolean;
}

export const styles = {
  wrap: ({ isCurrentUser }: WrapStylesProps) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: '2px',
    marginTop: '8px',
    marginBottom: '8px',

    ...(isCurrentUser && {
      alignItems: 'end',
    }),
  }),
};
