import { FC } from 'react';
import { IconButtonProps, IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

import { styles } from './styles';

export const CloseButton: FC<IconButtonProps> = ({ sx, ...props }) => (
  <IconButton sx={{ ...styles.button, ...(sx as IconButtonProps) }} {...props}>
    <CloseIcon />
  </IconButton>
);
