import { useState } from 'react';
import { Control, FieldValues, UseFormReturn } from 'react-hook-form';
import { Box, LinearProgress, Typography } from '@mui/material';
import { SxPropsTypes } from 'theme/MuiThemeProvider/types';

import { CommonButton } from 'components/CommonButton';
import { CommonInput } from 'components/CommonInput';
import { TextFormField } from 'components/TextFormField';
import { SelectInput } from 'components/SelectInput';
import { purchaseVolumeOptions } from './form';

import { styles } from './secondPageStyles';

type MultipleChoiceProps = {
  title?: string;
  description?: string;
  options: string[];
  allowMultiple?: boolean;
  hasOther?: boolean;
  onSelect: (option: string | string[]) => void;
};
export const MultipleChoice = ({
  title,
  description,
  options,
  allowMultiple,
  hasOther,
  onSelect,
}: MultipleChoiceProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Set<string>>(new Set<string>());
  const onSelectOption = (option: string) => {
    if (allowMultiple) {
      if (selectedOptions.has(option)) {
        const updatedOptions = [...selectedOptions].filter((selected) => selected !== option);
        setSelectedOptions(new Set(updatedOptions));
        onSelect(updatedOptions);
      } else {
        const updatedOptions = [...selectedOptions, option];
        setSelectedOptions(new Set(updatedOptions));
        onSelect(updatedOptions);
      }
    } else {
      onSelect(option);
      setSelectedOptions(new Set([option]));
    }
  };
  const displayOptions = hasOther ? [...options, 'Other'] : options;
  const onChangeOther = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const validDisplayOptions = new Set(displayOptions);
    const updatedOptions = [...selectedOptions].filter((selected) => validDisplayOptions.has(selected));
    if (inputValue === '') {
      setSelectedOptions(new Set(updatedOptions));
    } else {
      setSelectedOptions(new Set([...updatedOptions, e.target.value]));
    }
  };
  return (
    <Box sx={styles.multipleChoiceContainer}>
      {title && <Typography sx={styles.title}>{title}</Typography>}
      {description && <Typography sx={styles.description}>{description}</Typography>}
      <Box sx={styles.multipleChoiceOptions}>
        {displayOptions.map((option) => (
          <Box
            key={option}
            sx={styles.multipleChoiceOption(selectedOptions.has(option))}
            onClick={() => onSelectOption(option)}
          >
            {option}
          </Box>
        ))}
        {hasOther && selectedOptions.has('Other') && (
          <Box>
            <CommonInput placeholder="Specify Other" onChange={onChangeOther} sx={styles.otherInput} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export type DeliveryProps = {
  onClickContinue: () => void;
  form: UseFormReturn;
};

export const Delivery = ({ onClickContinue, form }: DeliveryProps) => {
  const { control } = form;
  return (
    <Box component="form" sx={styles.form}>
      <TextFormField
        wrapStyles={styles.textFormField as SxPropsTypes}
        name="deliveryDate"
        placeholder="Preferred Delivery Date"
        control={control}
      />
      <TextFormField
        wrapStyles={styles.textFormField as SxPropsTypes}
        name="deliveryLocation"
        placeholder="Preferred Delivery Seaport & Airport"
        control={control}
        multiline
        rows={2}
        inputWrapStyles={styles.multilineInput as SxPropsTypes}
      />
      <TextFormField
        wrapStyles={styles.textFormField as SxPropsTypes}
        name="packagingType"
        placeholder="Preferred Packaging Type (ie. 50KG bags)"
        control={control}
      />
      <TextFormField
        wrapStyles={styles.textFormField as SxPropsTypes}
        name="preferredPayment"
        placeholder="Preferred Payment Terms (ie. Net 20, Advance Payment, CAD)"
        control={control}
      />

      <Box sx={styles.formButtons}>
        <CommonButton onClick={onClickContinue}>Continue</CommonButton>
      </Box>
    </Box>
  );
};

export const Business = ({
  onClickContinue,
  onClickBack,
  form,
}: {
  onClickContinue: () => void;
  onClickBack: () => void;
  form: UseFormReturn;
}) => {
  const { control, setValue } = form;
  return (
    <Box component="form" sx={styles.form}>
      <SelectInput
        data={purchaseVolumeOptions}
        control={control as unknown as Control}
        controlName="purchaseVolume"
        sx={styles.selectInput as SxPropsTypes}
        menuSx={styles.selectMenuInput as SxPropsTypes}
        placeHolder="Average annual purchasing volume"
      />

      <TextFormField
        name="businessAddress"
        placeholder="Business Address"
        control={control}
        multiline
        minRows={3}
        wrapStyles={styles.textFormField as SxPropsTypes}
        inputWrapStyles={styles.multilineInput as SxPropsTypes}
      />

      <TextFormField
        name="links"
        placeholder={`Links to your company website, facebook or linkedin.
Please send a summary of your business if you do not have these`}
        control={control}
        multiline
        minRows={3}
        wrapStyles={styles.textFormField as SxPropsTypes}
        inputWrapStyles={styles.multilineInput as SxPropsTypes}
      />
      <MultipleChoice
        description="Do you have an active import license for agricultural products?"
        options={['Yes', 'No']}
        onSelect={(option) => {
          setValue('hasLicense', option === 'Yes');
        }}
      />

      <Box sx={styles.formButtons}>
        <CommonButton variant="outlined" onClick={onClickBack}>
          Back
        </CommonButton>

        <CommonButton onClick={onClickContinue}>Continue</CommonButton>
      </Box>
    </Box>
  );
};

const messages = {
  header: 'Thank you for submitting your order request! ',
  thankYou:
    'Our team is working to source the highest quality products at the best possible prices for you. ' +
    'You can expect to receive an email and a WhatsApp message from a Sproutzo team member soon. ' +
    'We look forward to assisting you!',
};
export const ThankYouPage = ({
  handleClose,
  finishText = 'Close',
}: {
  handleClose: () => void;
  finishText?: string;
}) => {
  return (
    <Box>
      <Box sx={styles.sectionHeader}>{messages.header}</Box>
      <Box sx={styles.thankYou}>{messages.thankYou}</Box>
      <CommonButton sx={styles.closeButton} onClick={handleClose}>
        {finishText}
      </CommonButton>
    </Box>
  );
};

const TotalPages = 3;
interface SecondPageProps<T extends FieldValues> {
  onSubmit: (data: T) => void;
  form: UseFormReturn<T>;
  handleClose: () => void;
}

export const SecondPage = <T extends FieldValues>({ onSubmit, form, handleClose }: SecondPageProps<T>) => {
  const [page, setPage] = useState<number>(1);
  const [progress, setProgress] = useState((page / TotalPages) * 100);

  const onClickContinue = () => {
    setProgress(((page + 1) / TotalPages) * 100);
    setPage((pg) => pg + 1);
    if (page === TotalPages - 1) {
      onSubmit(form.getValues());
    }
  };
  const onClickBack = () => {
    setProgress(((page - 1) / TotalPages) * 100);
    setPage((pg) => pg - 1);
  };
  const onClose = () => {
    handleClose();
  };
  return (
    <Box sx={styles.requestContainer}>
      {page !== 3 && (
        <Typography sx={styles.thankYouText}>
          Thank you for your quote request! Can you provide us with additional information to help us prepare the offer?
        </Typography>
      )}
      <Box sx={styles.progressContainer}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      {page === 1 && <Delivery onClickContinue={onClickContinue} form={form as unknown as UseFormReturn} />}
      {page === 2 && (
        <Business onClickContinue={onClickContinue} onClickBack={onClickBack} form={form as unknown as UseFormReturn} />
      )}
      {page === 3 && <ThankYouPage handleClose={onClose} />}
    </Box>
  );
};
