import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useOrderDocumentsQuery } from '../../../hooks';
import { useDocumentsQuery } from '../../../hooks/useDocumentsQuery';

export const useOrderDocumentsCardData = (folder: number, isModalOpen: boolean) => {
  const { id } = useParams();

  const { data, isLoading } = useOrderDocumentsQuery({
    folder,
    enabled: isModalOpen,
  });

  const { data: documentsData, isLoading: isDocumentsLoading } = useDocumentsQuery(
    Number(id),
    'folder-details',
    isModalOpen,
  );

  const folderData = useMemo(() => {
    return documentsData?.find((item) => item.id === folder);
  }, [documentsData, folder]);

  return {
    data,
    folderData,
    isLoading: isLoading || isDocumentsLoading,
  };
};
