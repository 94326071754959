import { format, isToday, isYesterday } from 'date-fns';

export const getLastMessageDate = (value?: string | number | null) => {
  if (!value) return '';

  const date = new Date(value);

  const isLastMessageToday = isToday(date);
  const isLastMessageYesterday = isYesterday(date);
  const lastMessageDate = format(date, 'd MMM yyyy');

  if (isLastMessageToday) return 'Today';

  if (isLastMessageYesterday) return 'Yesterday';

  return lastMessageDate;
};
