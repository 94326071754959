import { FC } from 'react';

import { Typography } from '@mui/material';

import { styles } from './styles';

interface SectionTagProps {
  label: string;
  color?: 'primary' | 'secondary';
}

export const SectionTag: FC<SectionTagProps> = ({ label, color }) => {
  return (
    <Typography sx={styles.wrap(color)} component="span">
      {label}
    </Typography>
  );
};
