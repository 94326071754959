export const styles = {
  loaderWrap: (isBuyer: boolean) => ({
    height: `calc(100svh - ${isBuyer ? '102px' : '64px'})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  pageWrap: (isBuyer: boolean) => ({
    minHeight: `calc(100svh - ${isBuyer ? '230px' : '202px'})`,
  }),
  footerWrap: {
    margin: '0 -14px 112px -14px',
  },
};
