import { FC } from 'react';

import { Typography } from '@mui/material';

import { styles } from './styles';

export const FilesListEmptyState: FC = () => {
  return (
    <>
      <Typography sx={styles.title}>No files yet</Typography>
      <Typography sx={styles.subtitle}>Future files will appear here </Typography>
    </>
  );
};
