import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 16px',
    marginRight: '-8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '32px',
      padding: '12px 14px 8px 14px',
      gap: '8px',
      justifyContent: 'start',
      margin: 0,
    },
  },
  title: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      lineHeight: '24px',
    },
  },
  backButton: {
    display: 'none',
    color: customTheme.custom.neutralLight,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      padding: '4px',
      width: '32px',
      height: '32px',
      marginLeft: '-4px',
    },
  },
  arrowButton: {
    color: customTheme.custom.neutralLight,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
};
