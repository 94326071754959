import { useCallback, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useChannelListContext } from '@sendbird/uikit-react/ChannelList/context';

import { CHAT_NOT_FOUND_ERROR } from 'app/api/SBChat';
import { ROUTES } from 'app/routes/constants';
import { useAuth, useChannelMetaData, useCurrentChannelHandlers, useChannelMembers } from 'hooks';
import { notificationObserver } from 'utils/observer';
import { getCurrentChannel, getCurrentChannelLogo } from 'utils/chat';

interface UseChatPageInnerProps {
  channelUrl: string;
}

export const useChatPageInner = ({ channelUrl }: UseChatPageInnerProps) => {
  const navigate = useNavigate();
  const { activeUser, activeUserCompanyId, isBuyer, sendbirdUserId } = useAuth();

  const channelListState = useChannelListContext();
  const channelData = getCurrentChannel(channelListState, channelUrl);

  const {
    channelSBName,
    currentChannelMetaData,
    isChannelExisting,
    isChannelListLoading: isLoading,
  } = useCurrentChannelHandlers({
    currentChannelId: channelUrl,
    idSearchParam: channelUrl,
    isChatPage: true,
  });

  const { channelLogo, channelName: currentChannelName } = useChannelMetaData({
    channelSBName,
    isBuyer,
    metaData: currentChannelMetaData,
  });

  const { currentCompanyMembers, otherCompanyMembers } = useChannelMembers({
    activeUserCompanyId,
    currentChannel: channelData,
    sendbirdUserId,
  });

  const isLogoPlaceholder = !channelLogo?.length;
  const currentChannelLogo = getCurrentChannelLogo(isLogoPlaceholder, channelLogo);
  const currentCompanyName = activeUser?.company.name;
  const membersCount = channelData?.memberCount ?? 0;

  const handleNavigateToMessagesPage = useCallback(() => {
    navigate(ROUTES.common.messages);
  }, [navigate]);

  useEffect(() => {
    if (!isLoading && !isChannelExisting) {
      handleNavigateToMessagesPage();

      notificationObserver.publish({
        type: 'error',
        title: CHAT_NOT_FOUND_ERROR,
      });
    }
  }, [handleNavigateToMessagesPage, isChannelExisting, isLoading]);

  return {
    currentChannelLogo,
    currentChannelName,
    currentCompanyMembers,
    currentCompanyName,
    isBuyer,
    isLoading,
    isLogoPlaceholder,
    membersCount,
    otherCompanyMembers,
    handleNavigateToMessagesPage,
  };
};
