import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  label: {
    flex: '1',
    fontSize: '12px',
    lineHeight: '12px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      flex: 0,
      whiteSpace: 'nowrap',
    },
  },
  value: {
    flex: '1',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    textAlign: 'end',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listWrap: {
    columnCount: 2,
    columnGap: '56px',
    columnFill: 'auto',
    flexWrap: 'wrap',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      overflow: 'hidden',
      gap: '6px 24px',
    },
  },
  itemWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      gap: '4px',
      overflow: 'hidden',
    },
  },
};
