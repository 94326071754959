import { FC, SyntheticEvent } from 'react';

import { Tabs, Tab, SxProps } from '@mui/material';

import { sampleRequestsTabs } from '../../constants';
import { TabLabel } from '../TabLabel';
import { styles } from './styles';

interface SampleRequestsPageHeaderProps {
  activeTabId: number;
  onTabChange: (event: SyntheticEvent, newValue: number) => void;
}

export const SampleRequestsPageHeader: FC<SampleRequestsPageHeaderProps> = ({ activeTabId, onTabChange }) => {
  return (
    <Tabs sx={{ ...styles.tabsWrap }} value={activeTabId} onChange={onTabChange}>
      {sampleRequestsTabs.map(({ label, value }) => (
        <Tab
          sx={styles.tabWrap as SxProps}
          key={value}
          value={value}
          label={<TabLabel isActive={activeTabId === value} label={label} />}
        />
      ))}
    </Tabs>
  );
};
