import { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import { CropFamily } from 'app/api/crops';
import { useCropFamiliesQuery, usePathnameChange } from 'hooks';

interface UseMobileSideBarProps {
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => void;
}

export const useMobileSideBar = ({ isOpen, onClose, onToggle }: UseMobileSideBarProps) => {
  const { type, subType } = useParams();
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState<CropFamily | null>(null);

  const { cropFamiliesData, isCropFamiliesLoading } = useCropFamiliesQuery({
    enabled: true,
  });

  const handleOpenProducts = useCallback(
    (category: CropFamily) => () => {
      setIsProductsOpen(true);
      setCurrentCategory(category);
    },
    [],
  );

  const handleCloseProducts = useCallback(() => {
    setIsProductsOpen(false);
    setCurrentCategory(null);
  }, []);

  const handleClose = useCallback(() => {
    handleCloseProducts();
    onToggle();
  }, [handleCloseProducts, onToggle]);

  const handleOpenAccount = useCallback(() => {
    setIsAccountOpen(true);
    handleClose();
  }, [handleClose]);

  const handleCloseAccount = useCallback(() => {
    setIsAccountOpen(false);
  }, []);

  const handleCloseAllPannels = useCallback(() => {
    handleCloseAccount();
    handleCloseProducts();
    onClose();
  }, [handleCloseAccount, handleCloseProducts, onClose]);

  usePathnameChange({ handleAction: handleCloseAllPannels });

  return {
    currentCategory,
    data: cropFamiliesData,
    isAccountOpen,
    isLoading: isCropFamiliesLoading,
    isProductsOpen: isProductsOpen && isOpen,
    subType,
    type,
    handleClose,
    handleOpenAccount,
    handleCloseAccount,
    handleCloseProducts,
    handleOpenProducts,
  };
};
