import { FC, ReactNode } from 'react';

import { Box } from '@mui/material';

import { LoadingButton } from 'components';
import { useAuth } from 'hooks';
import { styles } from './styles';

interface BaseListWrapperProps {
  isLoading: boolean;
  isButton?: boolean;
  children: ReactNode;
  onLoadMore: () => void;
}

export const BaseListWrapper: FC<BaseListWrapperProps> = ({
  children,
  isLoading,
  isButton = true,
  onLoadMore,
}): JSX.Element => {
  const { isBuyer } = useAuth();

  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.listWrap(isBuyer)}>{children}</Box>

      {isButton && (
        <LoadingButton sx={styles.button} variant="outlined" loading={isLoading} onClick={onLoadMore}>
          More Products
        </LoadingButton>
      )}
    </Box>
  );
};
