import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    width: '100%',
    padding: '4px 0',
    display: 'grid',
    ...scrollMixin,
  },
  applyFilterWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 12px 2px 6px',
  },
  applyFilterLabel: {
    fontSize: '10px',
    lineHeight: '21px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
  },
};
