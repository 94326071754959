import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { PaymentInfoItem } from '../../types';
import { styles } from './styles';

interface InfoListProps {
  data: PaymentInfoItem[];
}

export const InfoList: FC<InfoListProps> = ({ data }) => {
  return (
    <Box sx={{ ...styles.list }}>
      {data.map(({ label, value }) => (
        <Box key={label}>
          <Typography sx={{ ...styles.label }}>{label}</Typography>
          <Typography sx={{ ...styles.value }}>{value}</Typography>
        </Box>
      ))}
    </Box>
  );
};
