import { FC, Fragment } from 'react';

import { Box, Typography } from '@mui/material';

import { Product } from 'app/api/products/types';
import { ProductsList } from 'components';
import { useDeviceType } from 'hooks';
import { styles } from './styles';

interface CompanyProductsProps {
  data: Product[];
  hasNextPage: boolean;
  isLoading: boolean;
  onLoadMore: () => void;
}

export const CompanyProducts: FC<CompanyProductsProps> = ({ data, hasNextPage, isLoading, onLoadMore }) => {
  const { isMobileSize } = useDeviceType();
  return (
    <>
      <Typography component="h3" sx={{ ...styles.title }}>
        Top Products
      </Typography>
      {isMobileSize ? (
        <Box sx={styles.wrap}>
          <ProductsList
            data={data}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            hideProductCardFooter={isMobileSize}
            loadMore={onLoadMore}
          />
        </Box>
      ) : (
        <ProductsList
          data={data}
          isLoading={isLoading}
          hasNextPage={hasNextPage}
          hideProductCardFooter={isMobileSize}
          loadMore={onLoadMore}
        />
      )}
    </>
  );
};
