import { QuoteRequestsListData } from '../../../app/api/quotes/types';
import { SortTypes } from '../types';

interface SortQuotesByDateParams {
  data: QuoteRequestsListData;
  key: 'createdAt';
  sortOrder: 'asc' | 'desc';
}

const sortQuotesByDate = ({ data, key, sortOrder }: SortQuotesByDateParams) => {
  return data.sort((q1, q2) => {
    let dif;
    if (!!q1[key] && !q2[key]) {
      dif = -1;
    } else if (!q1[key] && !!q2[key]) {
      dif = 1;
    } else {
      dif = new Date(q2[key] ?? 0).valueOf() - new Date(q1[key] ?? 0).valueOf();
    }

    return sortOrder === 'asc' ? -dif : dif;
  });
};

export const sortQuotes = (data: QuoteRequestsListData, sortValue: SortTypes) => {
  switch (sortValue) {
    case SortTypes.DEFAULT:
      return data;

    case SortTypes.DATE_CREATED_NEWEST:
      return sortQuotesByDate({ data, key: 'createdAt', sortOrder: 'desc' });

    case SortTypes.DATE_CREATED_OLDEST:
      return sortQuotesByDate({ data, key: 'createdAt', sortOrder: 'asc' });

    default:
      return data;
  }
};
