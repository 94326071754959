import { FC, useCallback } from 'react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import './sliderStyles.css';
import { styles } from './styles';

interface CommonSliderProps {
  max: number;
  min: number;
  value: number[];
  step: number;
  onChange: (value: number[]) => void;
  onDisplayRangeChange: (v: number[]) => void;
  onChangeCompleteAction?: () => void;
}

export const CommonSlider: FC<CommonSliderProps> = ({
  max,
  min,
  step,
  value,
  onChange,
  onDisplayRangeChange,
  onChangeCompleteAction,
}) => {
  const handleChange = useCallback(
    (value: number | number[]) => {
      if (Array.isArray(value)) {
        onDisplayRangeChange(value);
      }
    },
    [onDisplayRangeChange],
  );

  const handleCompleteChange = useCallback(
    (value: number | number[]) => {
      if (Array.isArray(value)) {
        onChange(value);
        onChangeCompleteAction?.();
      }
    },
    [onChange, onChangeCompleteAction],
  );

  return (
    <Slider
      className="custom-slider"
      defaultValue={value}
      max={max}
      min={min}
      step={step}
      range
      styles={styles.styles}
      style={styles.slider}
      draggableTrack
      onChange={handleChange}
      onChangeComplete={handleCompleteChange}
    />
  );
};
