import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'none',
    padding: '18px 14px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: customTheme.custom.white,
    boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.1)',
    zIndex: 11,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
    },
  },
};
