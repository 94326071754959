import { FC, Fragment } from 'react';

import { Typography } from '@mui/material';

import { styles } from './styles';

const urlRegex = /(https?:\/\/[^\s]+)/g;

interface ParsedLinkTextProps {
  text: string;
}

export const ParsedLinkText: FC<ParsedLinkTextProps> = ({ text }) => {
  return (
    <>
      {text.split(urlRegex).map((part) => {
        if (part.match(urlRegex)) {
          return (
            <Typography key={part} component="span" sx={{ ...styles.textLine, ...styles.link }}>
              {part}
            </Typography>
          );
        }
        return <Fragment key={part}>{part}</Fragment>;
      })}
    </>
  );
};
