import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  content: {
    marginBottom: '16px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,
    maxWidth: '480px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -14px -8px 0',
  },
  closeButton: {
    minWidth: '80px',
    marginRight: '8px',
  },
  confirmButton: {
    minWidth: '80px',
    color: customTheme.custom.success,
  },
  warningTextContainer: {
    maxWidth: '480px',
    display: 'inline-block',
    lineHeight: '16px',
  },
  warningText: {
    fontSize: '12px',
  },
  warningContainer: {
    backgroundColor: customTheme.palette.primary.main,
    color: customTheme.custom.white,
    margin: '16px -20px -14px',
    padding: '16px 20px',
  },
  warningTitle: {
    fontWeight: 600,
  },
};
