import { SxPropsTypes } from '../../theme/MuiThemeProvider/types';

export const styles: SxPropsTypes = {
  button: {
    width: '40px',
    height: '40px',
    minWidth: 'unset',
    padding: '8px',
    borderRadius: '50%',
    color: 'inherit',

    '& svg': {
      fill: 'currentColor',
      width: '32px',
      height: '32px',
    },
  },
};
