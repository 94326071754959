export const styles = {
  checkbox: {
    padding: '6px',
  },
  checkboxWrap: {
    display: 'flex',
    width: '12px',
    height: '12px',
  },
};
