import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';

export const styles = {
  wrap: (isBorderBottom: boolean) => ({
    position: 'relative',
    padding: '22px 16px 22px 22px',
    display: 'grid',
    gap: '6px',

    ...(isBorderBottom && {
      borderBottom: `1px solid rgba(161, 161, 170, 0.3)`,
    }),

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '22px',
    },
  }),
};
