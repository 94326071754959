import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: '-webkit-box',
    WebkitLineClamp: '4',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textLine: {
    display: 'block',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '0.2px',
    color: customTheme.custom.black.B100,
    wordBreak: 'break-all',
  },
};
