import { FC } from 'react';
import { Box } from '@mui/material';

import { styles } from './styles';
import { StatItem } from './StatItem';
import { TechStatData } from '../../../ProductDetailsPage/types';

interface StatsListProps {
  keyTechStats: TechStatData;
}

export const StatsList: FC<StatsListProps> = ({ keyTechStats }): JSX.Element => {
  return (
    <Box sx={{ ...styles.listWrap }} data-testid="stats-list">
      {keyTechStats.map((stat) => (
        <StatItem key={stat.name} label={stat.name} value={stat.value} />
      ))}
    </Box>
  );
};
