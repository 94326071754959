import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    height: '28px',
    minHeight: '28px',
    paddingLeft: '18px',
    display: 'flex',
    alignItems: 'center',
    background: customTheme.custom.black.B10,
  },
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    color: customTheme.custom.black.B70,
  },
};
