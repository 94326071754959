import { useMemo } from 'react';

import { matchPath, useLocation } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';

export const AUTH_ROUTES = [
  ROUTES.auth.login,
  ROUTES.auth.accountActivation,
  ROUTES.auth.createNewPassword,
  ROUTES.auth.resetPassword,
  // ROUTES.buyer._,
  // ROUTES.buyer.products,
  // ROUTES.buyer.productsExtended,
];

export const useIsAuthRoute = () => {
  const { pathname } = useLocation();

  const isAuthRoute = useMemo(() => AUTH_ROUTES.some((path) => matchPath(path, pathname)), [pathname]);

  return {
    isAuthRoute,
  };
};
