import { Navigate, Outlet } from 'react-router-dom';

import { ROUTES } from 'app/routes/constants';
import { useAuth } from 'hooks';

export const ProtectedSellerRoute = () => {
  const { isBuyer } = useAuth();

  if (isBuyer) {
    return <Navigate to={ROUTES.buyer._} />;
  }

  return <Outlet />;
};
