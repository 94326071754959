import { ChangeEvent, FC } from 'react';

import { Control, Controller } from 'react-hook-form';
import { Box, Typography } from '@mui/material';

import { ProductsFiltersForm } from 'app/api/products';
import { CommonCheckbox, ControlledSlider } from 'components';
import { AdditionalFiltersControlNames } from '../../../../constants';
import { styles } from './styles';

interface SliderInnerProps {
  defaultTitle: string;
  checkBoxControlName:
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_WEIGHT
    | AdditionalFiltersControlNames.INCLUDE_PRODUCTS_WITH_INCOMPLETE_MATURITY;
  checkboxLabel: string;
  control: Control<ProductsFiltersForm>;
  controlName: AdditionalFiltersControlNames.WEIGHT | AdditionalFiltersControlNames.MATURITY;
  isRange: boolean;
  max: number;
  min: number;
  step: number;
  unit: string;
  handleChangeCheckBox: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeSlider: (newValue: number[]) => void;
}

export const SliderInner: FC<SliderInnerProps> = ({
  control,
  checkBoxControlName,
  checkboxLabel,
  controlName,
  isRange,
  max,
  min,
  step,
  unit,
  handleChangeCheckBox,
  handleChangeSlider,
}) => {
  return (
    <Box sx={styles.wrap}>
      <ControlledSlider
        control={control as unknown as Control}
        controlName={controlName}
        isRange={isRange}
        max={max}
        min={min}
        step={step}
        unit={unit}
        handleChange={handleChangeSlider}
      />
      <Controller
        name={checkBoxControlName}
        control={control}
        render={({ field: props }) => (
          <Box sx={styles.checkboxWrap}>
            <CommonCheckbox {...props} ref={null} checked={!!props.value} onChange={handleChangeCheckBox} />
            <Typography component="span" sx={styles.checkboxLabel}>
              {checkboxLabel}
            </Typography>
          </Box>
        )}
      />
    </Box>
  );
};
