import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  inner: {
    maxWidth: '100%',
    height: '100vh',
    minHeight: '512px',
    boxSizing: 'border-box',
    padding: '0 28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '48px',

    '@media (max-width: 479px)': {
      padding: '0 16px',
    },
  },
  iconsWrap: {
    padding: 0,
    width: '100%',
    maxWidth: '424px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '32px',
  },
  iconWrap: {
    width: '165px',
    height: 'fit-content',

    img: {
      width: '100%',
      maxWidth: '165px',
    },
  },
  divider: {
    width: '4px',
    height: '158px',
    background: customTheme.custom.gray.G100,
    border: 'none',
    borderRadius: '8px',

    '@media (max-width: 371px)': {
      height: '140px',
    },
  },
  logoWrap: {
    position: 'relative',
    maxWidth: '100%',
  },
  logo: {
    maxWidth: '100%',
  },
  title: {
    position: 'absolute',
    fontSize: '25px',
    lineHeight: '28px',
    fontWeight: 500,
    color: customTheme.palette.primary.P100,
    whiteSpace: 'nowrap',

    '@media (max-width: 439px)': {
      fontSize: '23px',
      lineHeight: '26px',
    },

    '@media (max-width: 411px)': {
      fontSize: '21px',
      lineHeight: '23px',
    },

    '@media (max-width: 371px)': {
      fontSize: '18px',
      lineHeight: '21px',
    },
  },
  titleTop: {
    top: '-4px',
    left: '18px',
  },
  titleBottom: {
    bottom: '12px',
    right: 0,
  },
  subtitle: {
    maxWidth: '424px',
    fontSize: '17px',
    lineHeight: 'normal',
    fontWeight: 400,
    color: customTheme.palette.primary.P100,
  },
  subtitleBold: {
    fontWeight: 500,
    margin: 0,
  },
};
