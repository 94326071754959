import { PalletType, ShipmentType } from 'app/api/orders/types';

export const packingTypeLabel: Record<ShipmentType, string> = {
  [ShipmentType.PALLETS]: 'pallets',
  [ShipmentType.BOXES]: 'boxes/crates',
  [ShipmentType.CONTAINERS]: 'containers',
};

export const paymentInfoLabels = [
  {
    key: 'companyName',
    label: 'Company Name',
  },
  {
    key: 'companyRegistrationAddress',
    label: 'Company Registration Address',
  },
  {
    key: 'bankName',
    label: 'Bank Name',
  },
  {
    key: 'bankAddress',
    label: 'Bank Address',
  },
  {
    key: 'accountNumber',
    label: 'Account Number',
  },
  {
    key: 'swiftBic',
    label: 'SWIFT BIC',
  },
  {
    key: 'routingCode',
    label: 'Routing Code',
  },
];

export const palletTypeLabel: Record<PalletType, string> = {
  [PalletType.CUSTOM]: 'Custom size',
  [PalletType.EUR1]: 'EUR1',
  [PalletType.EUR2]: 'EUR2',
  [PalletType.GMA]: 'GMA',
};

export const palletSizeLabel: Record<PalletType, string> = {
  [PalletType.CUSTOM]: '',
  [PalletType.EUR1]: '120 cm/80 cm',
  [PalletType.EUR2]: '120 cm/100 cm',
  [PalletType.GMA]: `48''/40''`,
};

export const MAX_FILE_SIZE = 30 * 1024 * 1024;
export const MAX_FILE_NAME_LENGHT = 100;
export const SELECTED_FILES_NUMBER_LIMIT = 10;
export const ACCEPTED_FILE_TYPES = ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

export const FOLDER_NOT_FOUND_ERROR = 'This folder has already been deleted. Refresh the page to see the changes.';
export const DOCUMENT_NOT_FOUND_ERROR = 'This document has already been deleted. Refresh the page to see the changes.';
