import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { DocumentsPreview } from 'components';
import { useModal } from 'hooks';
import { styles } from './styles';

interface FileItemProps {
  name: string;
  url: string;
}

export const FileItem: FC<FileItemProps> = ({ name, url }) => {
  const { open, handleCloseModal, handleOpenModal } = useModal();

  return (
    <>
      <Box sx={styles.wrap} onClick={handleOpenModal}>
        <Box sx={styles.header}>
          <Box sx={styles.viewButton}>View</Box>
        </Box>
        <Box sx={styles.footer}>
          <Typography component="span" sx={styles.name}>
            {name}
          </Typography>
        </Box>
      </Box>
      {open && <DocumentsPreview file={{ document: url }} isOpen={open} handleClose={handleCloseModal} />}
    </>
  );
};
