import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { Address, CompanyDetail } from '../../../../app/api/company/types';
import { styles } from './styles';

interface AddressInfoProps {
  company: CompanyDetail;
  deliveryAddress?: Address;
}

export const AddressInfo: FC<AddressInfoProps> = ({ company, deliveryAddress }) => {
  return (
    <Box sx={{ ...styles.wrap }}>
      <Box>
        <Typography sx={{ ...styles.addressTitle }}>{company.name}</Typography>
        <Typography sx={{ ...styles.address }}>
          {company.address.city}, {company.address.country}
        </Typography>
        {company.address.address && (
          <Typography sx={{ ...styles.addressDescription }}>{company.address.address}</Typography>
        )}
      </Box>
      {deliveryAddress && (
        <Box sx={{ ...styles.addressWrap }}>
          <Typography sx={{ ...styles.addressTitle }}>Delivery Address</Typography>
          <Typography sx={{ ...styles.address }}>
            {deliveryAddress.city}, {deliveryAddress.country}
          </Typography>
          <Typography sx={{ ...styles.addressDescription }}>{deliveryAddress.address}</Typography>
        </Box>
      )}
    </Box>
  );
};
