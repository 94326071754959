import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { CommonButton, CommonModal, LoadingButton } from 'components';
import { styles } from './styles';

interface RemoveLastProductPopupProps {
  handleClose: () => void;
  isOpen: boolean;
  handleRemove: () => void;
  isLoading: boolean;
}

export const RemoveLastProductPopup: FC<RemoveLastProductPopupProps> = ({
  handleClose,
  handleRemove,
  isOpen = false,
  isLoading,
}) => {
  return (
    <CommonModal title="Remove product and cancel quote?" isOpen={isOpen} handleClose={handleClose}>
      <Typography sx={{ ...styles.content }}>
        You are about to remove the last product in the quote request. If you confirm the action the product will be
        removed and the quote request will be canceled. Would you like to proceed?
      </Typography>

      <Box sx={{ ...styles.actions }}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.closeButton }} onClick={handleClose}>
          Back
        </CommonButton>
        <LoadingButton
          loading={isLoading}
          variant="text"
          color="inherit"
          sx={{ ...styles.removeButton }}
          onClick={handleRemove}
        >
          Cancel quote
        </LoadingButton>
      </Box>
    </CommonModal>
  );
};
