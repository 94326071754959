import { useMemo } from 'react';

import { ProductDetail } from 'app/api/products/types';
import { TechStatData } from 'pages/ProductDetailsPage/types';
import { getKeyTechStats } from 'utils/productDetailsUtils';

export const useProductDetailsStats = (data: ProductDetail) => {
  const productMainAttributes = useMemo(
    () =>
      data
        ? Object.entries(data)
            .filter(([key]) => key !== 'other' && key !== 'qtyAvailable')
            .map(([key, value]) => ({ name: key, value }))
        : [],
    [data],
  );

  const productOtherAttributes = useMemo(
    () =>
      data?.other
        ? Object.entries(data.other)
            .filter(([key, value]) => !!key && !!value)
            .map(([key, value]) => ({ name: key, value }))
        : [],
    [data?.other],
  ) as TechStatData;

  const productAllAttributes = [...productMainAttributes, ...productOtherAttributes];
  const keyTechStats = getKeyTechStats(productAllAttributes);

  return {
    keyTechStats,
  };
};
