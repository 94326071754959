import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    '&.MuiPaper-root': {
      padding: 0,
      background: customTheme.custom.white,
      border: `1px solid ${customTheme.custom.black.B10}`,
      borderRadius: '10px',
      boxShadow: 'none',
    },

    '&.MuiPaper-root.MuiAccordion-root:before': {
      display: 'none',
    },

    '& .MuiButtonBase-root.MuiAccordionSummary-root': {
      padding: '12px 14px',
      minHeight: 'unset',

      '&.Mui-expanded': {
        borderBottom: `1px solid ${customTheme.custom.black.B10}`,
      },
    },

    '.MuiAccordionSummary-content': {
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      margin: 0,

      '&.Mui-expanded': {
        margin: 0,
      },
    },

    '.MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(90deg)',

      '&.Mui-expanded': {
        transform: 'rotate(-90deg)',
      },
    },

    '.MuiAccordionDetails-root': {
      padding: 0,
    },
  },
  details: {
    padding: '14px',
    display: 'grid',
    overflow: 'hidden',
  },
};
