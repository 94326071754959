import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import Avatar from '@sendbird/uikit-react/ui/Avatar';

import defaultPrimaryAvatar from 'assets/icons/avatarPlaceholderPrimary.svg';
import defaultSecondaryAvatar from 'assets/icons/avatarPlaceholderSecondary.svg';
import { styles } from './styles';

interface SBMessageHeaderProps {
  userName: string;
  avatar?: string;
  isCurrentUser: boolean;
  isCurrentCompany: boolean;
}

export const SBMessageHeader: FC<SBMessageHeaderProps> = ({ userName, avatar, isCurrentUser, isCurrentCompany }) => {
  const displayName = isCurrentUser ? 'You' : userName;
  const avatarPlaceholder = isCurrentUser || isCurrentCompany ? defaultPrimaryAvatar : defaultSecondaryAvatar;
  const avatarSrc = avatar || avatarPlaceholder;

  return (
    <Box sx={styles.wrap(isCurrentUser)}>
      <Avatar src={avatarSrc} width="18px" height="18px" />
      <Typography component="span" sx={styles.name}>
        {displayName}
      </Typography>
    </Box>
  );
};
