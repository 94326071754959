import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  flexWrap: {
    display: 'flex',
    gap: '16px',
  },
  title: {
    flex: '1 1 0',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 700,
    color: customTheme.custom.black.B100,
    wordBreak: 'break-word',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  list: {
    flex: '1 1 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
};
