import { useMutation } from 'react-query';

import { patchOrderDocuments, PatchOrderDocument } from 'app/api/orders';

interface UpdateBuyerFirstClickMutationI {
  id: number;
}

export const useUpdateBuyerFirstClick = ({ id }: UpdateBuyerFirstClickMutationI) => {
  const { mutate, isLoading } = useMutation((data: PatchOrderDocument) => patchOrderDocuments(id, { ...data }));

  return { mutate, isLoading };
};
