import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  menuItemWrap: {
    margin: 0,
    padding: '0 6px 4px 0',

    '& .MuiFormControlLabel-label': {
      marginLeft: '4px',
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: '18px',
      color: customTheme.custom.black.B100,

      svg: {
        width: '16px',
        height: '16px',
      },
    },
  },
};
