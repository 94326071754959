import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '16px',
    width: '250px',
  },
  inputContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  input: {
    fontWeight: '500',
    height: '32px',
    width: '60px',
    borderRadius: '8px',
    border: '1px solid var(--Black-10, #E8E8E8)',
    background: 'white',
    padding: '4px 6px',
    textAlign: 'left',
    boxSizing: 'border-box',
    backgroundColor: customTheme.custom.black.B5,
  },
  label: {
    fontWeight: '500',
    fontSize: '10px',
    color: customTheme.custom.black.B70,
    marginLeft: '4px',
  },
  unitContainer: {
    height: '32px',
  },
  nativeInput: {
    backgroundColor: 'inherit',
    '.MuiNativeSelect-select': {
      textAlign: 'right',
      '&:focus': {
        backgroundColor: 'inherit',
      },
    },
  },
};
