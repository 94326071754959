import { FC } from 'react';

import { ButtonProps, Button } from '@mui/material';

import { ReactComponent as DownloadIcon } from '../../assets/icons/downloadIcon.svg';
import { styles } from './styles';

export const DownloadButton: FC<ButtonProps> = (props) => (
  <Button sx={{ ...styles.button }} {...props}>
    <DownloadIcon />
  </Button>
);
