import { customerApi } from '..';
import { apiRoutes } from '../apiRoutes';
import {
  AddQuoteProduct,
  UpdateQuoteData,
  HistoryPrice,
  QuoteRequestData,
  UpdateQuoteProduct,
  QuoteRequestsListData,
  Quote,
  CreateQuoteResponse,
  QuotesListRequest,
  PriceHistoryListData,
  PriceHistoryListRequest,
} from './types';

export const sendQuoteRequest = async (data: QuoteRequestData) => {
  const response = await customerApi.post<CreateQuoteResponse>(apiRoutes.quote.quoteRequest, data);

  return response?.data;
};

export const getQuoteData = async (id: number) => {
  const response = await customerApi.get<Quote>(apiRoutes.quote.quoteData(id));

  return response?.data;
};

export const deleteQuoteProduct = async (id: number) => {
  await customerApi.delete(apiRoutes.quote.quoteProducts(id));
};

export const patchQuoteProduct = async (id: number, data: UpdateQuoteProduct) => {
  await customerApi.patch(apiRoutes.quote.quoteProducts(id), data);
};

export const addQuoteProduct = async (data: AddQuoteProduct) => {
  await customerApi.post(apiRoutes.quote.quoteProduct, data);
};

export const updatePrices = async (data: HistoryPrice) => {
  await customerApi.post(apiRoutes.quote.prices, data);
};

export const updateQuote = async (id: number, data: UpdateQuoteData) => {
  await customerApi.patch(apiRoutes.quote.quoteData(id), data);
};
export const getQuoteRequestsData = async (params: QuotesListRequest) => {
  const response = await customerApi.get<QuoteRequestsListData>(apiRoutes.quote.quoteRequest, {
    params,
    paramsSerializer: {
      indexes: null,
    },
  });

  return response?.data;
};

export const getPriceHistory = async (params: PriceHistoryListRequest) => {
  const response = await customerApi.get<PriceHistoryListData>(apiRoutes.quote.prices, {
    params,
  });

  return response?.data;
};
