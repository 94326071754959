import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: (isPrimary: boolean) => ({
    '& .sendbird-file-message-item-body.incoming': {
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      backgroundColor: 'transparent',
      pointerEvents: 'none',

      '.sendbird-textbutton': {
        display: 'block',
      },
    },

    '& .sendbird-textbutton, & span': {
      display: 'block',
      color: `${customTheme.custom.black.B100} !important`,
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '12px',
      letterSpacing: '0.2px',
    },

    '& .sendbird-textbutton': {
      textDecoration: 'none',
    },

    '& span': {
      textDecoration: 'underline',
      textDecorationThickness: '0.2px',
    },

    '& .sendbird-file-message-item-body .sendbird-file-message-item-body__file-name ': {
      top: 'unset',
      wordBreak: 'break-all',
    },

    '.sendbird-file-message-item-body__file-name__text': {
      fontSize: '10px',
      lineHeight: '12px',
      fontWeight: 400,
    },

    '& path': {
      fill: `${isPrimary ? customTheme.palette.primary.P70 : customTheme.palette.secondary.S70} !important`,
    },

    '& .sendbird-file-message-item-body__file-icon': {
      width: '20px !important',
      minWidth: '20px !important',
      height: '20px !important',
      minHeight: '20px !important',
      backgroundColor: '#F0F0F0 !important',
      borderRadius: '6px',
      marginRight: '4px',
      alignItems: 'baseline !important',
    },

    '& .sendbird-file-message-item-body__file-icon__icon': {
      width: '16px !important',
      minWidth: '12px !important',
      height: '16px !important',
      minHeight: '12px !important',
    },

    '& .sendbird-thumbnail-message-item-body': {
      height: '120px',
      minWidth: 'unset',
      borderRadius: '10px',
      backgroundColor: 'unset',

      '& .sendbird-thumbnail-message-item-body__image-cover': {
        display: 'unset',
      },
    },

    '& .sendbird-image-renderer': {
      height: '120px !important',
      minWidth: 'unset !important',
    },

    '& .sendbird-thumbnail-message-item-body .sendbird-thumbnail-message-item-body__thumbnail': {
      position: 'relative !important',
      display: 'block !important',
    },

    '& .sendbird-image-renderer__image, & .sendbird-thumbnail-message-item-body__image-cover': {
      display: 'none !important',
    },

    '& .sendbird-image-renderer__hidden-image-loader': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      maxHeight: '100%',
      borderRadius: '10px',
    },
  }),
};
