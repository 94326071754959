import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  title: {
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 600,
    color: customTheme.custom.black.B100,
  },
  subtitle: {
    fontSize: '10px',
    lineHeight: '14px',
    fontWeight: 400,
    color: customTheme.custom.black.B100,
  },
};
