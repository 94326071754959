import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      alignItems: 'flex-start',
    },
  },
  columnWrap: {
    width: 'calc(50% - 4px)',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: '240px',
    },
  },
  documentInputWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '12px',
    paddingLeft: '4px',

    ':nth-last-of-type(-n+1)': {
      marginBottom: '8px',
    },
  },
  documentField: {
    flex: '1',
    margin: '0',
  },
  closeButton: {
    width: '32px',
    height: '32px',
    marginLeft: '4px',
    color: customTheme.custom.black.B30,
  },
  addButton: {
    height: '18px',
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  addButtonText: {
    marginLeft: '4px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
  },
};
