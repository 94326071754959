import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import emptyChannelListImg from 'assets/images/emptyChannelList.png';

import { styles } from './styles';

export const SelectedChatEmptyState: FC = () => {
  return (
    <Box sx={styles.wrap}>
      <Box sx={styles.inner}>
        <Box sx={styles.content}>
          <Box component="img" src={emptyChannelListImg} loading="lazy" />
          <Typography sx={styles.title}>No chat selected</Typography>
          <Typography sx={styles.subtitle}>Click on a conversation to pick up where you left off </Typography>
        </Box>
      </Box>
    </Box>
  );
};
