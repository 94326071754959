import { ChangeEvent, useCallback, useState } from 'react';

import { useMutation, useQueryClient } from 'react-query';
import * as tracker from 'analytics/tracker';

import { CreateOrderDocument, createOrderDocument } from 'app/api/orders';
import { AnalyticsName } from 'analytics';
import { notificationObserver } from 'utils/observer';
import {
  ACCEPTED_FILE_TYPES,
  FOLDER_NOT_FOUND_ERROR,
  MAX_FILE_NAME_LENGHT,
  MAX_FILE_SIZE,
  SELECTED_FILES_NUMBER_LIMIT,
} from '../../../constants';
import {
  INVALID_FILE_NAME_LENGTH_ERROR,
  INVALID_FILE_SIZE_ERROR,
  INVALID_FILE_TYPE_ERROR,
  TOO_MANY_FILES_ERROR,
} from '../../../validation';

const getFilesWithAllowedSize = (files: File[]) => files.filter((file) => file.size <= MAX_FILE_SIZE);
const isFilesWithLongName = (files: File[]) => files.some((file) => file.name.length > MAX_FILE_NAME_LENGHT);
const isInvalidTypeFiles = (files: File[]) => files.some((file) => !ACCEPTED_FILE_TYPES.includes(file.type));
const isInvalidSizeFiles = (files: File[]) => files.some((file) => file.size > MAX_FILE_SIZE);

interface UploadDocumentError {
  response: {
    data: {
      folder: string[];
    };
  };
}

interface UploadFileMutationProps {
  data: CreateOrderDocument[];
  queryKey: string[];
}

interface UseFileUploadProps {
  kind: string;
  folderId: number;
  orderId: number;
  quoteId: number;
  isSampleRequest: boolean;
  handleCloseUploadFilesPopup: () => void;
}

export const useFileUpload = ({
  kind,
  folderId,
  orderId,
  quoteId,
  isSampleRequest,
  handleCloseUploadFilesPopup,
}: UseFileUploadProps) => {
  const queryClient = useQueryClient();
  const [fileError, setFileError] = useState('');

  const { mutate: uploadFileMutation, isLoading } = useMutation(
    async ({ data }: UploadFileMutationProps) => {
      const uploadSequentially = async (index: number) => {
        if (index < data.length) {
          await createOrderDocument(data[index]).then(() => {
            const analyticsOptions = {
              document_type: kind,
              document_id: folderId,
              order_id: orderId,
              quote_id: quoteId,
              is_sample_request: isSampleRequest,
            };

            tracker.track(AnalyticsName.DOCUMENT_UPLOADED, analyticsOptions);

            return uploadSequentially(index + 1);
          });
        }
      };

      await uploadSequentially(0);
    },
    {
      onSuccess: async (data, { queryKey }) => {
        await queryClient.invalidateQueries({ queryKey });
      },
      onError: (error: UploadDocumentError) => {
        if (error.response?.data.folder[0].includes('does not exist')) {
          notificationObserver.publish({
            type: 'error',
            title: FOLDER_NOT_FOUND_ERROR,
          });
        }
      },
    },
  );

  const validateFilesList = useCallback(
    (files: File[], queryKey: string[]) => {
      const filtratedArray = getFilesWithAllowedSize(files);
      const isSizeError = isInvalidSizeFiles(files);
      const isLongNameError = isFilesWithLongName(files);
      const isInvalidTypeError = isInvalidTypeFiles(files);
      const isNumberLimitError = files.length > SELECTED_FILES_NUMBER_LIMIT;

      handleCloseUploadFilesPopup();

      if (isNumberLimitError) {
        setFileError(TOO_MANY_FILES_ERROR);
        return;
      }

      if (isSizeError) {
        setFileError(INVALID_FILE_SIZE_ERROR);
        return;
      }

      if (isLongNameError) {
        setFileError(INVALID_FILE_NAME_LENGTH_ERROR);
        return;
      }

      if (isInvalidTypeError) {
        setFileError(INVALID_FILE_TYPE_ERROR);
        return;
      }

      const preparedFiles = filtratedArray.map((file) => ({ folder: folderId, document: file }));
      uploadFileMutation({ data: preparedFiles, queryKey });
    },
    [folderId, handleCloseUploadFilesPopup, uploadFileMutation],
  );

  const handleFileUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement> | File[], queryKey: string[]) => {
      if (Array.isArray(e)) {
        validateFilesList(e, queryKey);
      } else {
        const files = e?.target?.files;

        if (files && e?.target) {
          const filesArray = Array.from(files);
          validateFilesList(filesArray, queryKey);

          // eslint-disable-next-line no-param-reassign
          e.target.value = '';
        }
      }
    },
    [validateFilesList],
  );

  const handleClearErrors = useCallback(() => {
    if (fileError) {
      setFileError('');
    }
  }, [fileError]);

  return {
    isLoading,
    isFileError: !!fileError,
    fileError,
    handleFileUpload,
    handleClearErrors,
  };
};
