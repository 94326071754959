import { FC } from 'react';

import { Typography } from '@mui/material';

import { FormError, LoadingButton, MaskedTextFormField, PasswordTooltip } from 'components';
import { useCreatePasswordForm } from './hooks/useCreatePasswordForm';
import { validationRules } from './hooks/validation';
import { styles } from './styles';

interface CreatePasswordFormProps {
  isResetPassword?: boolean;
}

export const CreatePasswordForm: FC<CreatePasswordFormProps> = ({ isResetPassword = false }): JSX.Element => {
  const { control, onChange, onSubmit, formError, handleSubmit, isLoading } = useCreatePasswordForm({
    isResetPassword,
  });

  const isFormError = !!formError;
  const title = isResetPassword ? 'New Password' : 'Password';

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={onChange}>
      <Typography sx={{ ...styles.title }}>
        {isResetPassword ? 'Create New Password' : 'Ready to get started?'}
      </Typography>

      <MaskedTextFormField
        control={control}
        name="password"
        tooltipElem={<PasswordTooltip />}
        rules={validationRules.password}
        isFormError={isFormError}
        placeholder={title}
      />

      <MaskedTextFormField
        control={control}
        name="newPassword"
        rules={validationRules.newPassword}
        isFormError={isFormError}
        placeholder={`Confirm ${title}`}
      />

      {isFormError && <FormError text={formError} />}

      <LoadingButton type="submit" loading={isLoading} sx={{ ...styles.button(isFormError) }}>
        Create {title.toLocaleLowerCase()}
      </LoadingButton>
    </form>
  );
};
