import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  labelWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',

    '& svg': {
      width: '16px',
      minWidth: '16px',
      height: '16px',
      cursor: 'pointer',

      path: {
        fill: customTheme.custom.black.B100,
      },
    },
  },
  label: {
    fontSize: '10px',
    lineHeight: '12px',
    width: '64px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      width: 'unset',
      whiteSpace: 'pre-wrap',
    },
  },
  tooltipWrap: {
    width: '172px',
    padding: '2px 6px',
    letterSpacing: '0.2px',
  },
};
