import { useCallback, useState } from 'react';

export const usePannelHandlers = () => {
  const [isInfoPannel, setIsInfoPannel] = useState<boolean>(false);
  const [isSearchPanel, setIsSearchPanel] = useState<boolean>(false);

  const handleOpenInfoPannel = useCallback(() => {
    setIsInfoPannel(true);
  }, []);

  const handleCloseInfoPannel = useCallback(() => {
    setIsInfoPannel(false);
  }, []);

  const handleOpenSearchPanel = useCallback(() => {
    setIsSearchPanel(true);
  }, []);

  const handleCloseSearchPanel = useCallback(() => {
    setIsSearchPanel(false);
  }, []);

  return {
    isInfoPannel,
    isSearchPanel,
    handleCloseInfoPannel,
    handleOpenInfoPannel,
    handleCloseSearchPanel,
    handleOpenSearchPanel,
  };
};
