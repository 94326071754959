import { CSSProperties } from 'react';

import { QuoteCardStatuses, QuoteCardStatusesType } from 'app/api/quotes/types';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const getStatusTagStyles = (status: QuoteCardStatusesType): CSSProperties => {
  switch (status) {
    case QuoteCardStatuses.NEW:
      return {
        color: customTheme.custom.white,
        backgroundColor: customTheme.palette.primary.P100,
      };

    case QuoteCardStatuses.CANCELED:
      return {
        color: customTheme.custom.black.B100,
        backgroundColor: customTheme.custom.black.B10,
      };

    case QuoteCardStatuses.AWAITING_FREIGHT_QUOTE:
      return {
        color: customTheme.palette.secondary.S100,
        backgroundColor: customTheme.palette.secondary.S20,
      };

    case QuoteCardStatuses.DOCUMENTS_IN_PROGRESS:
      return {
        color: customTheme.custom.white,
        backgroundColor: customTheme.palette.secondary.S70,
      };

    case QuoteCardStatuses.PAYMENT_IN_PROGRESS:
      return {
        color: customTheme.custom.white,
        backgroundColor: customTheme.palette.secondary.S100,
      };
    case QuoteCardStatuses.WAITING_RESPONSE:
      return {
        color: customTheme.custom.white,
        backgroundColor: customTheme.palette.primary.P50,
      };

    case QuoteCardStatuses.NEW_REQUEST:
    case QuoteCardStatuses.NEW_RESPONSE:
      return {
        color: customTheme.custom.white,
        backgroundColor: customTheme.palette.secondary.S100,
      };

    case QuoteCardStatuses.READY_TO_SHIP:
    case QuoteCardStatuses.EN_ROUTE:
      return {
        color: customTheme.custom.info,
        backgroundColor: customTheme.custom.blue.B50,
      };

    case QuoteCardStatuses.DELIVERED:
      return {
        color: customTheme.custom.white,
        backgroundColor: customTheme.custom.info,
      };

    default:
      return {
        display: 'none',
      };
  }
};
