import { FC } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Box } from '@mui/material';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';

import { CustomInput } from './CustomInput';
import { CustomHeader } from './CustomHeader';
import { PlaceOrderFormData } from '../../hooks/types';
import { styles } from './styles';

interface DeliveryDatesProps {
  control: Control<PlaceOrderFormData>;
  errors: FieldErrors<PlaceOrderFormData>;
}

export const DeliveryDates: FC<DeliveryDatesProps> = ({ control, errors }): JSX.Element => {
  return (
    <Box sx={{ ...styles.controllerWrap }} data-testid="delivery-datepicker">
      <Controller
        control={control}
        name="dateRange"
        rules={{ required: true }}
        render={({ field }) => (
          <DatePicker
            calendarClassName="custom-date-picker"
            selectsRange
            startDate={field.value?.[0]}
            endDate={field.value?.[1]}
            minDate={new Date(Date.now() + 3600 * 1000 * 24)}
            dateFormat="dd.MM.yyyy"
            onChange={(date) => field.onChange(date)}
            useWeekdaysShort
            customInput={<CustomInput isError={!!errors.dateRange} />}
            renderCustomHeader={(props) => <CustomHeader {...props} />}
          />
        )}
      />
    </Box>
  );
};
