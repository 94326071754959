import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';
import fileBackdrop from 'assets/images/fileBackdrop.svg';

export const styles = {
  header: (oneRow: boolean) => ({
    display: 'grid',
    gridTemplateRows: oneRow ? '36px' : '36px 22px',
  }),
  fileNameWrap: {
    display: 'flex',
    alignItems: 'end',
    marginBottom: '4px',
  },
  fileName: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '18px',
    color: customTheme.custom.black.B100,
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    lineBreak: 'anywhere',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  status: (statusColor: string) => ({
    margin: '-4px 0 8px',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '18px',
    color: statusColor,
  }),
  fileWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '210px',
    marginBottom: '8px',
    borderRadius: '6px',
    backgroundImage: `url(${fileBackdrop})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      height: '170px',
      marginBottom: '4px',
    },
  },
  viewButton: {
    minWidth: '160px',
    borderColor: customTheme.custom.white,
    color: customTheme.custom.white,

    '&:hover': {
      borderColor: customTheme.custom.white,
      backgroundColor: 'transparent',
    },

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      minWidth: '100px',
    },
  },
  viewReasonButton: {
    position: 'absolute',
    top: '128px',
    fontSize: '10px',
    lineHeight: '12px',
    fontWeight: 600,
    letterSpacing: '0.2px',
    color: customTheme.custom.white,
    textDecoration: 'underline',
    textUnderlineOffset: '1.5px',
    textDecorationThickness: ' 0.5px',

    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      textUnderlineOffset: '1.5px',
      textDecorationThickness: ' 0.5px',
    },
  },
};
