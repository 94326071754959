import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  drawer: {
    display: 'block',
    position: 'relative',
  },
  paper: {
    overflow: 'hidden',
  },
  backButton: {
    position: 'absolute',
    left: '10px',
    top: '11px',
    padding: '4px',
    width: '32px',
    height: '32px',
    color: customTheme.custom.neutralLight,
    zIndex: '10',
  },
  wrap: (isBuyer: boolean) => ({
    height: '100%',
    display: 'grid',
    gridAutoRows: 'max-content',
    overflow: 'hidden',

    '& *': {
      fontFamily: customTheme.typography.allVariants.fontFamily,
    },

    '.sendbird-message-search-pannel': {
      width: '100%',
      height: `calc(100svh - ${isBuyer ? '102px' : '54px'})`,
      background: customTheme.custom.white,
    },

    '.sendbird-message-search-pannel__header': {
      height: '52px',
      minHeight: '52px',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 14px',
      borderBottom: 'none !important',
    },

    '.sendbird-message-search-pannel__message-search': {
      overflow: 'hidden',
    },

    '.sendbird-message-search': {
      height: 'calc(100% - 4px)',
      background: customTheme.custom.white,
      overflowX: 'hidden',
      overflowY: 'auto',

      ...scrollMixin,
    },

    '.sendbird-message-search-pannel__header__close-button': {
      display: 'none',
    },

    '.sendbird-message-search-pannel__header__title': {
      position: 'static !important',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      color: customTheme.custom.black.B100,
      overflow: 'hidden',
      marginLeft: '38px',
    },

    '.sendbird-message-search-pannel__input': {
      paddingTop: '4px !important',
    },

    '.sendbird-message-search-pannel__input__container': {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      borderRadius: '0 !important',
      height: 'fit-content !important',
      padding: 0,
      overflow: 'hidden',
      border: 'none !important',

      '&__search-icon': {
        right: '16px',
        left: 'unset !important',
      },

      '.sendbird-icon-search': {
        top: '8px',

        '& svg': {
          width: '22px',
          height: '22px',
          maxWidth: '22px',
          maxHeight: '22px',
        },

        '& path': {
          fill: customTheme.custom.black.B100,
          width: '18px',
          height: '18px',
          maxWidth: '18px',
          maxHeight: '18px',
        },
      },

      '.sendbird-message-search-pannel__input__container__spinner.sendbird-loader': {
        right: '42px !important',
        top: '11px !important',
      },

      '.sendbird-icon-remove': {
        right: '44px !important',
        background: customTheme.custom.neutralLight,
        borderRadius: '50%',
        width: '16px !important',
        minWidth: '16px !important',
        height: '16px !important',
        minHeight: '16px !important',
        overflow: 'visible',

        '& svg': {
          marginLeft: '-2px',
          marginTop: '-2px',
          width: '20px !important',
          minWidth: '20px !important',
          height: '20px !important',
          minHeight: '20px !important',
        },

        '& path': {
          width: '20px',
          height: '20px',
          fill: customTheme.custom.white,
        },
      },

      '&__input-area': {
        position: 'static !important',
        width: '100%',
        height: '40px !important',
        padding: '8px 56px 8px 16px',
        boxSizing: 'border-box',
        letterSpacing: '0.2px',
        fontSize: '14px',
        lineHeight: '20px',
        border: `1px solid ${customTheme.custom.black.B10} !important`,
        borderRadius: '25px !important',

        '&::placeholder': {
          color: customTheme.custom.black.B50,
          opacity: 1,
        },

        '&:focus': {
          background: 'transparent !important',
        },

        '&:hover + .sendbird-icon-remove, &:focus + .sendbird-icon-remove': {
          '& path': {
            fill: customTheme.custom.black.B5,
          },
        },
      },
    },
  }),
};
