import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    display: 'flex',
    marginBottom: '8px',

    ':first-of-type': {
      marginTop: '8px',
    },
  },
  leftSideWrap: {
    justifyContent: 'flex-start',
  },
  rightSideWrap: {
    justifyContent: 'flex-end',
  },
  messageWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flex: '1',
  },
  message: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '4px',
    maxWidth: '50%',
    padding: '10px',
    fontSize: '10px',
    lineHeight: '12px',
  },
  file: {
    borderRadius: '10px',
    backgroundColor: customTheme.custom.black.B5,
  },
  user: {
    borderRadius: '10px 10px 0 10px',
    backgroundColor: customTheme.custom.blue.B100,
  },
  otherUser: {
    display: 'inline-block',
    borderRadius: '10px 10px 10px 0',
    backgroundColor: customTheme.palette.secondary.S50,
  },
  system: {
    display: 'inline-block',
    borderRadius: '10px 10px 10px 0',
    backgroundColor: customTheme.custom.black.B10,
  },
  date: {
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B70,
  },
  fileIcon: {
    flex: '0 0 16px',
  },
  fileContent: {
    marginTop: '2px',
    fontSize: '10px',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,
    cursor: 'pointer',
    lineBreak: 'anywhere',
  },
  userName: {
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '12px',
    color: customTheme.custom.black.B100,
  },
  body: {
    wordBreak: 'break-word',
  },
};
