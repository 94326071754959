import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

export type ColorType = 'primary' | 'secondary';

interface InfoItemProps {
  label: string;
  value: string;
  color?: ColorType;
}

export const InfoItem: FC<InfoItemProps> = ({ label, value, color }) => {
  return (
    <Box sx={styles.wrap}>
      <Typography component="span" sx={styles.label(color)}>
        {label}
      </Typography>
      <Typography component="span" sx={styles.value(color)}>
        {value}
      </Typography>
    </Box>
  );
};
