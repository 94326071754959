import { FC } from 'react';

import { Box, IconButton, Typography } from '@mui/material';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrowRight.svg';
import { ReactComponent as BackIcon } from 'assets/icons/arrowLeftSmall.svg';
import { styles } from './styles';

interface ConversationInfoHeaderProps {
  handleToggle: () => void;
}

export const ConversationInfoHeader: FC<ConversationInfoHeaderProps> = ({ handleToggle }) => {
  return (
    <Box sx={styles.header}>
      <IconButton sx={styles.backButton} onClick={handleToggle}>
        <BackIcon />
      </IconButton>
      <Typography sx={styles.title}>Conversation Info</Typography>
      <IconButton sx={styles.arrowButton} onClick={handleToggle}>
        <ArrowIcon />
      </IconButton>
    </Box>
  );
};
