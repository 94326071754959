import { FC, MouseEvent } from 'react';

import { SxPropsTypes } from 'theme/MuiThemeProvider/types';
import { Box, Typography } from '@mui/material';

import { CommonButton, CommonModal } from 'components';
import { styles } from './styles';

interface ReopenQuoteModalProps {
  isOpen: boolean;
  handleClose: (e: MouseEvent) => void;
  handleConfirm: (e: MouseEvent) => void;
  isBuyer: boolean;
}

const messages = {
  title: 'Reopen Quote',
};

export const ReopenQuoteModal: FC<ReopenQuoteModalProps> = ({
  isBuyer,
  handleClose,
  handleConfirm,
  isOpen = false,
}) => {
  return (
    <CommonModal
      paperStyles={styles.modalPaper as SxPropsTypes}
      title={messages.title}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <Typography sx={{ ...styles.reopenContent }}>
        {`This will send the ${isBuyer ? 'seller' : 'buyer'} a notification that you’ve reopened the quote request. `}
      </Typography>

      <Box sx={styles.actions}>
        <CommonButton variant="text" color="inherit" sx={{ ...styles.cancelBtn }} onClick={handleClose}>
          Cancel
        </CommonButton>
        <CommonButton variant="text" color="secondary" sx={{ ...styles.confirmBtn }} onClick={handleConfirm}>
          Confirm
        </CommonButton>
      </Box>
    </CommonModal>
  );
};
