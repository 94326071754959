export const styles = {
  wrap: (gridRow?: number) => ({
    position: 'relative',
    width: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    boxShadow: '0px 3.52px 13.2px 0px rgba(0, 0, 0, 0.10)',
    borderRadius: '8px',
    marginBottom: '20px',

    ...(gridRow && {
      gridRow,
    }),
  }),
  arrowWrap: (opened: boolean, arrowTopPosition?: string) => ({
    width: '24px',
    height: '24px',
    position: 'absolute',
    right: '6px',
    top: arrowTopPosition ?? '14px',
    transform: opened ? 'rotate(90deg)' : 'rotate(-90deg)',
  }),
};
