import { ReactElement } from 'react';

import search from 'assets/images/search.png';
import shipBoat from 'assets/images/ship_boat.png';
import secureDocument from 'assets/images/secure_document.png';

import { Box, Typography } from '@mui/material';

import { styles } from './styles';

const messages = {
  main: 'Everything in one place.',
  sub: 'Procure. Verify. Ship.',
};
const images = [search, secureDocument, shipBoat];

export const AllInOnePlaceBanner = (): ReactElement => {
  return (
    <Box sx={{ ...styles.banner }}>
      <Box sx={styles.text}>
        <Typography sx={{ ...styles.contentTitle }}>{messages.main}</Typography>
        <Typography sx={{ ...styles.sub }}>{messages.sub}</Typography>
      </Box>
      <Box sx={styles.images}>
        {images.map((img) => (
          <Box key={img} sx={styles.imgContainer}>
            <Box component="img" src={img} sx={styles.img} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
