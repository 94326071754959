import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paperStyles: (isChatOpen: boolean) => ({
    minWidth: '862px',
    maxWidth: '2000px',
    minHeight: '236px',
    maxHeight: isChatOpen ? '512px' : 'unset',
    overflow: 'visible',
  }),
  loaderBox: {
    position: 'absolute',
    width: 'calc(100% - 28px)',
    height: 'calc(100% - 80px)',
  },
  title: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
  },
  sampleLabel: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px',
    color: customTheme.palette.primary.P100,
  },
  companyLink: {
    color: customTheme.custom.black.B1000,
  },
};
