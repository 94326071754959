import { FC, ReactNode } from 'react';

import { ThemeProvider } from '@mui/material';

import theme from './theme';

const MUIThemeProvider: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default MUIThemeProvider;
