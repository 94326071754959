import { FC } from 'react';

import { DashboardTabs, QuoteRequestsListData } from 'app/api/quotes/types';
import { QuoteRequestsList } from '../QuoteRequestsList';
import { CanceledSection } from '../CanceledSection';
import { TabEmptyState } from '../TabEmptyState';
import { useQuoteRequestsTabInner } from './hooks';

export interface QuoteRequestsTabInnerProps {
  isBuyer: boolean;
  tab: DashboardTabs | undefined;
  data: {
    list: QuoteRequestsListData;
    canceledList: QuoteRequestsListData;
  };
}

export const QuoteRequestsTabInner: FC<QuoteRequestsTabInnerProps> = ({ isBuyer, tab, data }) => {
  const { quoteIdParam, handleRemoveQuoteIdParam, handleSetQuoteIdParam } = useQuoteRequestsTabInner({
    quotes: [...data.list, ...data.canceledList],
  });

  return (
    <>
      {data.list.length ? (
        <QuoteRequestsList
          data={data.list}
          tab={tab}
          isBuyer={isBuyer}
          isCanceledSection={false}
          openedQuoteId={quoteIdParam}
          onCloseDetails={handleRemoveQuoteIdParam}
          onOpenDetails={handleSetQuoteIdParam}
        />
      ) : (
        <TabEmptyState tab={tab} />
      )}
      {!!data.canceledList.length && (
        <CanceledSection>
          <QuoteRequestsList
            isCanceledSection
            data={data.canceledList}
            tab={tab}
            isBuyer={isBuyer}
            openedQuoteId={quoteIdParam}
            onCloseDetails={handleRemoveQuoteIdParam}
            onOpenDetails={handleSetQuoteIdParam}
          />
        </CanceledSection>
      )}
    </>
  );
};
