import { FC } from 'react';

import { TimingDataType } from '../../types';
import { getDaysLabel, getShipmentDetailsDate } from '../../utils';
import { InfoItem } from '../InfoItem';
import { SectionItemWrap } from '../SectionItemWrap';
import { SectionTag } from '../SectionTag';

type TimingSectionProps = TimingDataType;

export const TimingSection: FC<TimingSectionProps> = ({ departure, estimatedArrival, estimatedTimeToDestination }) => {
  return (
    <SectionItemWrap isBorderBottom>
      <SectionTag label="Timing" color="secondary" />
      <InfoItem label="Departure" value={getShipmentDetailsDate(departure)} />
      <InfoItem label="Estimated Arrival" value={getShipmentDetailsDate(estimatedArrival)} />
      <InfoItem
        label="Estimated time to destination"
        value={getDaysLabel(Number(estimatedTimeToDestination))}
        color="secondary"
      />
    </SectionItemWrap>
  );
};
