import { SyntheticEvent, useCallback } from 'react';

interface ImageHandlers {
  handleImageError: (url: string) => (e: SyntheticEvent<HTMLImageElement>) => void;
}

export const useImageHandlers = (): ImageHandlers => {
  const handleImageError = useCallback(
    (url: string) =>
      ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
        const eventTarget = currentTarget;

        eventTarget.onerror = null;
        eventTarget.src = url;
      },
    [],
  );

  return { handleImageError };
};
