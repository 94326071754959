import { FC } from 'react';

import { Control, Controller } from 'react-hook-form';
import { RadioGroup } from '@mui/material';
import { RadioGroupItem } from './types';
import { ControllerItem } from './ControllerItem';

interface RadioGroupControllerProps {
  data: RadioGroupItem[];
  control: Control;
  controlName: string;
  handleClose?: () => void;
}

export const RadioGroupController: FC<RadioGroupControllerProps> = ({ data, control, controlName, handleClose }) => {
  return (
    <Controller
      control={control}
      name={controlName}
      render={({ field }) => (
        <RadioGroup {...field}>
          {data.map(({ value, label }) => (
            <ControllerItem key={label} value={value} label={label} handleClose={handleClose} />
          ))}
        </RadioGroup>
      )}
    />
  );
};
