import { OrderStatus } from 'app/api/orders/types';
import { DashboardTabs } from 'app/api/quotes/types';
import { getCurrentTabStatus } from './getCurrentTabStatus';

export const getOrdersStatusesByTab = (tab?: DashboardTabs) => {
  const { isOpenOrdersTab, isEnRouteOrdersTab, isPastOrdersTab } = getCurrentTabStatus(tab);

  switch (true) {
    case isOpenOrdersTab:
      return [
        OrderStatus.AWAITING_FREIGHT_QUOTE,
        OrderStatus.DOCUMENTS_IN_PROGRESS,
        OrderStatus.PAYMENT_IN_PROGRESS,
        OrderStatus.READY_TO_SHIP,
      ];

    case isEnRouteOrdersTab:
      return [OrderStatus.EN_ROUTE];

    case isPastOrdersTab:
      return [OrderStatus.DELIVERED, OrderStatus.CANCELED];

    default:
      return [];
  }
};
