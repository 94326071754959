import { FC, MouseEvent, useState } from 'react';
import { Box, Typography, Popper, Fade } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { ReactComponent as ColorsIcon } from 'assets/icons/colors.svg';
import { CommonTooltip } from 'components';

import { styles } from './styles';
import { BaseInput } from '../BaseInput';

export interface ColorInputProps {
  label: string;
  field: string;
  value?: string | number | undefined;
  description?: string;
  placeholder?: string;
  full?: boolean;
  setActive: (field: string) => void;
  onChange: (field: string, value: string) => void;
}

const ColorMap: { [color: string]: string } = {
  'Light Green': '#8DE586',
  'Light Yellow': '#F7F38E',
  'Light Orange': '#FFC18A',
  Pink: '#FF97B0',
  'Light Purple': '#D992E5',
  'Light Blue': '#6BA6FF',
  White: '#FFFFFF',
  Green: '#0DA600',
  Yellow: '#EFDA1B',
  Orange: '#EF7F1B',
  Red: '#BC0000',
  Purple: '#8F329E',
  Blue: '#2F6DC9',
  Grey: '#989898',
  'Dark Green': '#065100',
  'Dark Yellow': '#C4A500',
  'Dark Orange': '#BC5900',
  'Dark Red': '#820000',
  'Dark Purple': '#521E5B',
  'Dark Blue': '#1F4173',
  Black: '#000000',
  'Yellow-Green': '#DBDF00',
  Cream: '#E4DEBA',
  Brown: '#6B4700',
  'Red-Purple': '#9A4C7B',
  'Blue-Purple': '#574C9A',
  'Green to Red': 'linear-gradient(to right, #E0FFF2, #FFDDDD)',
  'Yellow to Red': 'linear-gradient(to right, #FFFDD6, #FFDDDD)',
  'Green to Yellow': 'linear-gradient(to right, #E0FFF2, #FFFDD7)',
  'Cream to Orange': 'linear-gradient(to right, #E4DEBA, #FFC18A)',
  'Green to Orange': 'linear-gradient(to right, #E0FFF2, #FFC18A)',
};

const colors = [
  'Light Green',
  'Light Yellow',
  'Light Orange',
  'Pink',
  'Light Purple',
  'Light Blue',
  'White',
  'Green',
  'Yellow',
  'Orange',
  'Red',
  'Purple',
  'Blue',
  'Grey',
  'Dark Green',
  'Dark Yellow',
  'Dark Orange',
  'Dark Red',
  'Dark Purple',
  'Dark Blue',
  'Black',
].map((c) => ({ label: c, color: ColorMap[c] }));

const otherColors = ['Yellow-Green', 'Cream', 'Brown', 'Red-Purple', 'Blue-Purple'].map((c) => ({
  label: c,
  color: ColorMap[c],
}));

const changingColors = ['Green to Red', 'Yellow to Red', 'Green to Yellow', 'Cream to Orange', 'Green to Orange'].map(
  (c) => ({ label: c, color: ColorMap[c] }),
);

export const ColorInput: FC<ColorInputProps> = ({ label, field, value, description, onChange, setActive }) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onClickColor = (e: MouseEvent) => {
    setAnchorEl(e.currentTarget as HTMLElement);
    setPopupOpen((open) => !open);
  };

  const onChoiceClick = (color: string) => {
    setPopupOpen(false);
    onChange(field, color);
  };

  const onClickMultiColor = () => {
    setPopupOpen(false);
    onChange(field, 'MultiColor');
    document.getElementById('color_other')?.focus();
    setActive('color_other');
  };

  const onClickOther = () => {
    setPopupOpen(false);
    onChange(field, 'Other');
    document.getElementById('color_other')?.focus();
    setActive('color_other');
  };

  const displayColor = ColorMap[value?.toString() ?? ''];
  return (
    <BaseInput label={label} description={description}>
      <Box sx={styles.colorContainer} onClick={onClickColor}>
        {value?.toString() === 'MultiColor' && (
          <Box sx={styles.customIcon(26)} onClick={onClickMultiColor}>
            <ColorsIcon height={26} width={26} />
          </Box>
        )}
        {value?.toString() !== 'MultiColor' && !displayColor && (
          <Box sx={{ ...styles.customIcon(26), ...styles.otherColors }} onClick={onClickOther}>
            +/?
          </Box>
        )}
        {displayColor && <Box sx={styles.colorBox(displayColor)} />}
        <Typography sx={styles.colorLabel}>{value}</Typography>
      </Box>
      {popupOpen && (
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => setPopupOpen(false)}>
          <Popper
            open={popupOpen}
            disablePortal
            anchorEl={anchorEl}
            sx={styles.popper}
            placement="bottom-start"
            transition
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box sx={styles.popperContainer}>
                  <Box sx={styles.header}>Color</Box>

                  <Box sx={styles.subHeader}>Primary</Box>
                  <Box sx={styles.colorBoxContainer()}>
                    {colors.map((c) => (
                      <CommonTooltip key={c.label} sx={styles.tooltip} title={c.label} placement="top-start">
                        <Box sx={styles.colorChoiceBox(c.color)} onClick={() => onChoiceClick(c.label)} />
                      </CommonTooltip>
                    ))}
                  </Box>
                  <Box sx={styles.subHeader}>Other</Box>
                  <Box sx={styles.colorBoxContainer()}>
                    {otherColors.map((c) => (
                      <CommonTooltip key={c.label} sx={styles.tooltip} title={c.label} placement="top-start">
                        <Box sx={styles.colorChoiceBox(c.color)} onClick={() => onChoiceClick(c.label)} />
                      </CommonTooltip>
                    ))}
                    <CommonTooltip sx={styles.tooltip} title="Multicolor" placement="top-start">
                      <Box sx={styles.customIcon(24)} onClick={onClickMultiColor}>
                        <ColorsIcon height={24} />
                      </Box>
                    </CommonTooltip>
                    <CommonTooltip sx={styles.tooltip} title="Other" placement="top-start">
                      <Box sx={{ ...styles.customIcon(24), ...styles.otherColors }} onClick={onClickOther}>
                        +/?
                      </Box>
                    </CommonTooltip>
                  </Box>

                  <Box sx={styles.subHeader}>Changing Color</Box>
                  <Box sx={styles.colorBoxContainer(true, true)}>
                    {changingColors.map((c) => (
                      <Box
                        key={c.label}
                        sx={styles.changingColorChoiceBox(c.color)}
                        onClick={() => onChoiceClick(c.label)}
                      >
                        {c.label}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </BaseInput>
  );
};
