import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { scrollMixin } from 'theme/MuiThemeProvider/mixins';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    marginBottom: '24px',
    padding: '24px 32px',
    borderRadius: '10px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: customTheme.custom.white,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '12px 0',
      marginBottom: '46px',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginTop: 0,
      padding: '12px 14px 0',
    },
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '32px',
    paddingBottom: '8px',
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingBottom: 0,
      marginBottom: '12px',
      borderBottom: 'none',
    },
  },
  columnTitle: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
  },
  sampleLabel: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: customTheme.palette.primary.P100,
  },
  productColumnTitle: {
    flex: '1 1 48%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '0 14px',
    },
  },
  countColumnTitle: {
    flex: '1 1 52%',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  errorBox: {
    marginTop: '8px',
  },
  rowsWrap: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '200px',
    ...scrollMixin,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '2px 0',
      maxHeight: '328px',
    },
  },
};
