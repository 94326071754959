import { FC, useState, useEffect, useCallback } from 'react';

import { generatePath, NavLink } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import { CropFamily } from 'app/api/crops/types';
import { ROUTES } from 'app/routes/constants';
import { SubCategoriesNavList } from 'components';
import { AnalyticsLinkType, useLinkClickedEvent } from 'analytics';
import { capitalizeFirstLetter } from 'utils/formatText';
import { styles } from './styles';

interface CategoryNavItemProps extends CropFamily {
  disableHover: boolean;
  isClosed: boolean;
}

export const CategoryNavItem: FC<CategoryNavItemProps> = ({ name, crops, disableHover, isClosed }) => {
  const [open, setOpen] = useState(false);
  const { handleTrackLinkClickEvent } = useLinkClickedEvent();
  const path = generatePath(ROUTES.buyer.products, {
    type: name,
  });

  useEffect(() => {
    if (isClosed) {
      setOpen(false);
    }
  }, [isClosed]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleNavClick = useCallback(() => {
    handleTrackLinkClickEvent({ path, text: capitalizeFirstLetter(name), type: AnalyticsLinkType.NAV });
  }, [handleTrackLinkClickEvent, name, path]);

  return (
    <Tooltip
      placement="bottom-start"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      enterTouchDelay={0}
      disableHoverListener={disableHover}
      componentsProps={{
        tooltip: {
          sx: styles.tooltip,
        },
        transition: {
          timeout: 0,
        },
      }}
      title={<SubCategoriesNavList categoryName={name} data={crops} oneColumn />}
    >
      <Box sx={{ ...styles.navItem }}>
        <NavLink to={path} onClick={handleNavClick}>
          {capitalizeFirstLetter(name)}
        </NavLink>
      </Box>
    </Tooltip>
  );
};
