import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  pageWrap: {
    boxSizing: 'border-box',
    margin: '40px -24px',
    padding: '0 76px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: 0,
      margin: '16px -14px 0px',
    },
  },
  title: {
    color: customTheme.custom.black.B70,
    fontSize: '64px',
    fontWeight: 600,
    marginBottom: '24px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '32px',
      marginBottom: '16px',
    },
  },
  // Question answer component
  expandIcon: (expanded: boolean) => ({
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.2s',
    svg: {
      width: '40px',
      height: '40px',
      [`@media (max-width: ${breakpoints.tablet}px)`]: {
        width: '32px',
        height: '32px',
      },
    },
  }),
  questionContainer: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: breakpoints.laptop,
  },
  questionAnswer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      padding: '8px 0',
    },
  },
  questionAnswerTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: customTheme.custom.black.B70,
    cursor: 'pointer',
    padding: '0px 16px',
  },
  questionAnswerTitle: {
    fontSize: '32px',
    fontWeight: 600,
    color: customTheme.custom.black.B70,
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '16px',
    },
  },
  questionAnswerText: {
    color: customTheme.custom.black.B70,
    padding: '0px 16px',
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      paddingTop: '16px',
    },
  },
};
