import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  paperStyles: {
    width: '508px',
  },
  mobilePaperStyles: {
    maxHeight: '75%',
  },
  content: {
    marginBottom: '16px',
    fontSize: '14px',
    color: customTheme.custom.black.B70,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 -14px -8px 0',
  },
  closeButton: {
    minWidth: '80px',
    marginRight: '8px',
    color: customTheme.custom.black.B70,
  },
  confirmButton: {
    minWidth: '80px',
    color: customTheme.custom.success,
  },
};
