const numberExp = /^(0|[1-9]\d*)$/;
const bigNumberExp = /^[0-9]{1,9}$/;

export const validationRules = {
  amount: {
    validate: {
      isAboveZero: (value: string) => (Number(value) <= 0 ? 'Please enter whole positive number greater than 0' : true),
      isNumber: (value: string) => (!numberExp.test(value) ? 'Please use only numbers.' : true),
      isBigNumber: (value: string) => (!bigNumberExp.test(value) ? 'The number is too big' : true),
    },
  },
};
