import { CheckboxFilterItem } from 'components/CheckboxFilter/types';
import { FiltersNames, SEARCH_KEY, SEARCH_TYPE } from '../constants';

export const parseSearchParams = (
  searchParams: URLSearchParams,
  filters: Map<string, CheckboxFilterItem[]>,
  type?: string,
): URLSearchParams => {
  const parsedSearchParams = searchParams;

  parsedSearchParams.forEach((value, key) => {
    if (type === SEARCH_TYPE && key === SEARCH_KEY) {
      if (!value) {
        searchParams.delete(key);
      }

      return;
    }

    if (
      key === FiltersNames.MIN_WEIGHT ||
      key === FiltersNames.MAX_WEIGHT ||
      key === FiltersNames.APPLY_WEIGHT_FILTER ||
      key === FiltersNames.MIN_MATURITY ||
      key === FiltersNames.MAX_MATURITY ||
      key === FiltersNames.APPLY_MATURITY_FILTER ||
      key === FiltersNames.APPLY_RESISTANCES_FILTER ||
      key === FiltersNames.ORGANIC
    ) {
      searchParams.set(key, value);
      return;
    }

    const filterValues = filters.get(key);

    if (!filterValues) {
      searchParams.delete(key);
    } else {
      const paramValues = value.split(',');

      const existedParamValues = paramValues.filter((paramVal) =>
        filterValues.find((filterValue) => filterValue.value === paramVal),
      );

      if (!existedParamValues.length) {
        searchParams.delete(key);
      } else {
        searchParams.set(key, [...new Set(existedParamValues)].join(','));
      }
    }
  });

  return parsedSearchParams;
};
