import { customTheme } from '../../../../theme/MuiThemeProvider/theme';

export const styles = {
  menuWrap: {
    '& .MuiPaper-root': {
      borderRadius: 'unset',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',

      '& .MuiList-root': {
        padding: 0,
      },
    },
  },
  moreButton: (isBuyerEmpty: boolean) => ({
    width: '24px',
    height: '24px',
    margin: '4px 0 0 6px',
    padding: '0',
    color: isBuyerEmpty ? customTheme.custom.black.B100 : customTheme.custom.white,
  }),
  menuItemWrap: {
    padding: '6px 12px',
  },
  menuItemText: {
    fontSize: '10px',
    lineHeight: '12px',
  },
};
