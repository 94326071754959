import { customTheme } from '../../../../../theme/MuiThemeProvider/theme';

export const styles = {
  footer: {
    borderRadius: '0 0 10px 10px',
  },
  cell: {
    boxSizing: 'border-box',
    height: '38px',
    display: 'table-cell',
    width: '222px',
    padding: '8px',
    borderBottom: 'none',
    textAlign: 'left',
    color: customTheme.custom.black.B100,

    ':first-of-type': {
      padding: '10px 8px 10px 24px',
    },

    ':last-of-type': {
      padding: '10px 24px 10px 8px',
    },
  },
  footerCell: {
    position: 'sticky',
    bottom: 0,
    borderTop: `1px solid ${customTheme.custom.black.B10}`,
    backgroundColor: customTheme.palette.secondary.S20,
    height: '38px',
    '&:first-of-type': {
      borderRadius: '0 0 0px 10px',
    },
    '&:last-of-type': {
      borderRadius: '0 0 10px 0px',
    },
  },
  totalCell: (width: string) => ({
    width,
    minWidth: `${width}`,
    fontSize: '12px',
    fontWeight: '600',
    lineHight: '16px',
  }),
  amountCell: (width: string) => ({
    width,
    minWidth: `${width}`,
    fontSize: '12px',
    lineHeight: '18px',
  }),
  quoteCell: {
    fontSize: '12px',
    fontWeight: '600',
    lineHight: '18px',
    color: customTheme.palette.secondary.S100,
    width: '208px',
    minWidth: '208px',
  },
  totalWrap: {},
  actionsCell: {
    width: '40px',
    minWidth: '40px',
  },
  quantity: {
    display: 'flex',
    gap: '0px 4px',
    flexWrap: 'wrap',
  },
  quantityLabel: {
    zIndex: 2,
    whiteSpace: 'nowrap',
  },
};
