import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { NOT_FOUND_ERROR_CODE } from 'app/api/errors';
import { patchDocumentFolderAsIncomplete } from 'app/api/orders/service';
import { notificationObserver } from 'utils/observer';
import { FOLDER_NOT_FOUND_ERROR } from '../constants';

interface MarkFolderAsIncompleteI {
  id: number;
  setDocumentAsUpdated: () => void;
  onSuccessAction?: () => void;
}

export const useMarkFolderAsIncompleteMutation = ({
  id,
  setDocumentAsUpdated,
  onSuccessAction,
}: MarkFolderAsIncompleteI) => {
  const queryClient = useQueryClient();

  const { mutate: handleMarkFolderIncomplete, isLoading: isMarkFolderIncompleteLoading } = useMutation(
    () => patchDocumentFolderAsIncomplete(id),
    {
      onSuccess: () => {
        setDocumentAsUpdated();
        onSuccessAction?.();
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['order-documents', id] });
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        queryClient.invalidateQueries({ queryKey: ['folder-details'] });
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === NOT_FOUND_ERROR_CODE) {
          notificationObserver.publish({
            type: 'error',
            title: FOLDER_NOT_FOUND_ERROR,
          });
        }
      },
    },
  );

  return { handleMarkFolderIncomplete, isMarkFolderIncompleteLoading };
};
