import { FC } from 'react';

import { FormGroup } from '@mui/material';

import { NotificationsSettingsForm } from 'app/api/SBChat';
import { MuteNotificationsFormControlLabel } from '../MuteNotificationsFormControlLabel';
import { useMuteNotificationsSwitch } from './hooks';
import { styles } from './styles';

interface MuteNotificationsSwitchProps {
  channelUrl: string;
  companyName: string;
  initialValues: NotificationsSettingsForm;
  isLabelDark?: boolean;
}

export const MuteNotificationsSwitch: FC<MuteNotificationsSwitchProps> = ({
  channelUrl,
  companyName,
  initialValues,
  isLabelDark = false,
}) => {
  const { control, isAllMuted, isMessagesMuted, onChange } = useMuteNotificationsSwitch({ initialValues, channelUrl });

  return (
    <FormGroup sx={styles.formGroup}>
      <MuteNotificationsFormControlLabel
        control={control}
        isLabelDark={isLabelDark}
        label={`Mute all notifications from ${companyName}`}
        name="all"
        value={isAllMuted}
        onChange={onChange('all')}
      />
      <MuteNotificationsFormControlLabel
        control={control}
        isLabelDark={isLabelDark}
        label="Mute new message notifications"
        name="messages"
        value={isMessagesMuted}
        onChange={onChange('messages')}
      />
    </FormGroup>
  );
};
