import { requiredFieldValidation } from '../../../../../app/validation/validation';

export const validationRules = {
  username: {
    required: requiredFieldValidation,
  },
  password: {
    required: requiredFieldValidation,
  },
};
