export const styles = {
  container: {
    borderRadius: '10px',
    background: '#E3E3E3',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#A1A1A1',

    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 600,
    padding: '6px',
  },
};
