import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import Avatar from '@sendbird/uikit-react/ui/Avatar';

import defaultPrimaryAvatar from 'assets/icons/avatarPlaceholderPrimary.svg';
import defaultSecondaryAvatar from 'assets/icons/avatarPlaceholderSecondary.svg';
import { styles } from './styles';

interface InfoMemberItemProps {
  userName: string;
  avatar?: string;
  isCurrentCompany?: boolean;
  isCurrentUser?: boolean;
}

export const InfoMemberItem: FC<InfoMemberItemProps> = ({
  userName,
  avatar,
  isCurrentCompany = false,
  isCurrentUser = false,
}) => {
  const displayName = isCurrentUser ? 'You' : userName;
  const avatarPlaceholder = isCurrentUser || isCurrentCompany ? defaultPrimaryAvatar : defaultSecondaryAvatar;
  const avatarSrc = avatar || avatarPlaceholder;

  return (
    <Box sx={styles.wrap}>
      <Avatar src={avatarSrc} width="18px" height="18px" />
      <Typography component="span" sx={styles.name(isCurrentCompany)}>
        {displayName}
      </Typography>
    </Box>
  );
};
