import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  // Multiple Choice
  multipleChoiceContainer: {},
  title: {
    fontWeight: 600,
    fontSize: '20px',
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    color: customTheme.custom.neutralLight,
  },
  multipleChoiceOptions: {
    margin: '16px 0px',
  },
  multipleChoiceOption: (isSelected: boolean) => ({
    padding: '8px 16px',
    borderRadius: '8px',
    border: `1px solid ${isSelected ? customTheme.palette.primary.main : '#E0E0E0'}`,
    color: isSelected ? customTheme.palette.primary.main : '#4F4F4F',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F5F5F5',
    },
    marginBottom: '8px',
  }),
  otherInput: {
    borderRadius: '8px',
    border: '1px solid var(--Black-10, #E8E8E8)',
    background: customTheme.custom.white,
    padding: '4px 6px',
    width: '100%',
  },
  multilineInput: {
    padding: '8px 16px',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  textFormField: {
    marginBottom: '8px',
  },
  formButtons: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
  },
  selectInput: {
    width: '100%',
    marginBottom: '8px',
  },
  selectMenuInput: {
    width: '100%',
  },
  requestContainer: {},
  thankYouText: {
    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      fontSize: '14px',
    },
  },
  progressContainer: {
    width: '100%',
    margin: '16px 0px',
  },

  //
  sectionHeader: {
    fontWeight: 600,
    fontSize: '20px',
    margin: '0px 0px 8px',
  },
  thankYou: {
    marginBottom: '8px',
  },
  closeButton: {
    float: 'right',
  },
};
