import { breakpoints } from 'theme/MuiThemeProvider/breakpoints';
import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  title: {
    marginBottom: '16px',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: customTheme.custom.black.B100,

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      marginBottom: '12px',
    },
  },
  link: {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '17px',
    color: customTheme.palette.primary.P100,
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'all 0.3s ease-out',
    ':hover': {
      color: customTheme.palette.primary.P70,
    },
  },
  table: {
    width: '100%',
    outline: `1px solid ${customTheme.custom.black.B10}`,
    borderRadius: '10px',
    marginBottom: '25px',
    overflow: 'hidden',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'none',
    },
  },
  headerRow: {
    backgroundColor: customTheme.palette.primary.P5,
  },
  cell: {
    width: '125px',
    padding: '16px',
    borderBottom: `1px solid ${customTheme.custom.black.B10}`,
    color: customTheme.custom.black.B100,

    ':first-of-type': {
      padding: '16px 12px 16px 24px',
      width: '60px',
      maxWidth: '60px',
    },

    ':last-of-type': {
      padding: '16px 24px 16px 12px',
      width: '295px',
    },
  },
  headerCell: {
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '16px',
  },
  tableBody: {
    backgroundColor: customTheme.custom.white,
  },
  accordionWrap: {
    display: 'none',

    [`@media (max-width: ${breakpoints.tablet}px)`]: {
      display: 'block',
      marginBottom: '20px',
    },
  },
};
