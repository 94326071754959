import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { SBChatMode } from 'app/api/SBChat';
import { SBMessageContainer } from '../SBMessageContainer';
import { SBMessageDate } from '../SBMessageDate';
import { styles } from './styles';

interface SBAdminMessageProps {
  body: string;
  date: string;
  isHighlighted: boolean;
  mode: SBChatMode;
}

export const SBAdminMessage: FC<SBAdminMessageProps> = ({ body, date, isHighlighted, mode }) => {
  const isOrderChatMode = mode === SBChatMode.ORDER_DETAILS;
  const splittedMessage = body.split('\n');
  const joinedMessage = splittedMessage.splice(1).join(' ').trim();

  return (
    <SBMessageContainer>
      <Box sx={styles.wrap(isOrderChatMode)}>
        <Box sx={styles.message(isHighlighted)}>
          <Typography component="span" sx={styles.userName}>
            {splittedMessage[0]}
          </Typography>{' '}
          {joinedMessage}
        </Box>
        <SBMessageDate date={date} />
      </Box>
    </SBMessageContainer>
  );
};
