import { useEffect } from 'react';

import { useDeviceType } from 'hooks';

const DISABLE_TOUCH_HANDLER_TYPES = ['checkbox', 'range', 'radio', 'file'];

export const useCommonInputHandler = () => {
  const { isMobileSize } = useDeviceType();

  useEffect(() => {
    const handleTouchMove = (event: TouchEvent) => {
      event.stopPropagation();

      const focusedInput = document.activeElement as HTMLElement;
      const inputType = focusedInput.getAttribute('type');
      const isHandlerDisabled = DISABLE_TOUCH_HANDLER_TYPES.some((type) => inputType === type);

      if (isHandlerDisabled) {
        return;
      }

      if (focusedInput && ['INPUT', 'TEXTAREA'].includes(focusedInput.tagName)) {
        focusedInput.blur();
      }
    };

    if (isMobileSize) {
      window.addEventListener('touchmove', handleTouchMove);
    }

    return () => {
      if (isMobileSize) {
        window.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [isMobileSize]);
};
