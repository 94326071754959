import { useEffect } from 'react';

import { useForm, Control } from 'react-hook-form';

import { DashboardMainTabs, QuoteRequestStatus, QuoteRequestsListData, SampleRequestsTabs } from 'app/api/quotes/types';
import { Counter, OrdersListData } from 'app/api/orders/types';
import { DashboardTab, QuotesSortingFormValues, SortValue } from '../types';
import { getOrdersStatusesByTab, getSortOptions } from '../utils';
import { useQuoteRequestsQuery } from './useQuoteRequestsQuery';
import { useOrdersQuery } from './useOrdersQuery';
import { useOrdersCounterQuery } from './useOrdersCounterQuery';

const SAMPLE_REQUESTS_QUERY_KEY = 'sample-requests';

interface DashboardPageHandlersI {
  isSampleRequestsTab: boolean;
  activeMainTab?: DashboardTab;
  activeTab?: DashboardTab;
}

interface DashboardPageHandlers {
  isLoading: boolean;
  quoteRequestsData: {
    list: QuoteRequestsListData;
    canceledList: QuoteRequestsListData;
  };
  ordersData: {
    list: OrdersListData;
    canceledList: OrdersListData;
  };
  ordersCounter?: Counter;
  control: Control;
  sortOptions: SortValue[];
}

export const useDashboardPageHandlers = ({
  activeMainTab,
  activeTab,
  isSampleRequestsTab,
}: DashboardPageHandlersI): DashboardPageHandlers => {
  const sortOptions = getSortOptions(activeTab?.path);

  const { control, watch, setValue } = useForm<QuotesSortingFormValues>({
    defaultValues: { sort: sortOptions[0].value },
  });

  const currentSortValue = watch('sort');
  const isQuoteRequestsTab = activeTab?.path === DashboardMainTabs.QUOTE_REQUESTS;
  const isSampleRequestsMainTab = activeTab?.path === SampleRequestsTabs.SAMPLE_REQUESTS;
  const isQuoteRequestsQueryEnabled = isQuoteRequestsTab || isSampleRequestsMainTab;

  useEffect(() => {
    if (activeMainTab) {
      setValue('sort', sortOptions[0].value);
    }
  }, [activeMainTab, setValue, sortOptions]);

  const {
    isLoading: isQuoteRequestsLoading,
    isSuccess: isQuotesListSuccess,
    data: quoteRequestsList,
  } = useQuoteRequestsQuery({
    enabled: isQuoteRequestsQueryEnabled,
    sortValue: currentSortValue,
    requestSample: isSampleRequestsMainTab,
    status: [QuoteRequestStatus.OPEN],
    ...(isSampleRequestsTab && { key: SAMPLE_REQUESTS_QUERY_KEY }),
  });

  const { data: canceledQuotesList } = useQuoteRequestsQuery({
    enabled: isQuotesListSuccess && isQuoteRequestsQueryEnabled,
    sortValue: currentSortValue,
    requestSample: isSampleRequestsMainTab,
    status: [QuoteRequestStatus.CANCELED],
    ...(isSampleRequestsTab && { key: SAMPLE_REQUESTS_QUERY_KEY }),
  });

  const { isLoading: isOrdersLoading, data: ordersData } = useOrdersQuery({
    enabled: !isQuoteRequestsQueryEnabled,
    sortValue: currentSortValue,
    status: getOrdersStatusesByTab(activeTab?.path),
    requestSample: isSampleRequestsTab,
  });

  const { ordersCounter, isOrdersCounterLoading } = useOrdersCounterQuery();

  return {
    isLoading: isQuoteRequestsLoading || isOrdersLoading || isOrdersCounterLoading,
    quoteRequestsData: {
      list: quoteRequestsList,
      canceledList: canceledQuotesList,
    },
    ordersData,
    ordersCounter,
    sortOptions,
    control: control as unknown as Control,
  };
};
