import { FC, useCallback } from 'react';

import { Control, Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { FormControlLabel } from '@mui/material';

import { CommonCheckbox } from '../CommonCheckbox';
import { styles } from './styles';

interface FilterItemProps {
  control: Control;
  controlName: string;
  label: string;
  value: string;
  defaultValue: string;
  resetFilters: (isAll: boolean) => void;
  handleClose?: () => void;
}

export const FilterItem: FC<FilterItemProps> = ({
  control,
  label,
  value,
  controlName,
  defaultValue,
  resetFilters,
  handleClose,
}) => {
  const onValueChange = useCallback(
    (checked: boolean, value: string, props: ControllerRenderProps<FieldValues, string>) => {
      const updateVal = checked ? value : checked;
      resetFilters(updateVal === defaultValue);
      handleClose?.();
      props.onChange(updateVal);
    },
    [resetFilters, handleClose, defaultValue],
  );

  return (
    <FormControlLabel
      sx={{ ...styles.menuItemWrap }}
      control={
        <Controller
          name={controlName}
          control={control}
          render={({ field: props }) => (
            <CommonCheckbox
              {...props}
              ref={null}
              disabled={props.value === defaultValue}
              checked={!!props.value}
              value={value}
              onChange={(e) => onValueChange(e?.target?.checked, e?.target?.value, props)}
            />
          )}
        />
      }
      label={label}
    />
  );
};
