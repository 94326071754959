import { customTheme } from 'theme/MuiThemeProvider/theme';

export const styles = {
  wrap: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inner: {
    width: '250px',
    height: '250px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: customTheme.custom.hover,
    borderRadius: '50%',
  },
  content: {
    maxWidth: '128px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    gap: '4px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 700,
    color: customTheme.custom.black.B30,
  },
  subtitle: {
    maxWidth: '144px',
    fontSize: '9px',
    lineHeight: '10px',
    fontWeight: 400,
    color: customTheme.custom.black.B50,
  },
};
